import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import classnames from 'classnames';
import style from './Tooltip.css';

export default function Tooltip (props) {
  const randomId = Math.floor(Math.random() * 1000000).toString();
  const Element = props.isInline ? 'span' : 'div';

  return (
    <Element className={classnames(props.className, props.isInline && style.isInline)}>
      <ReactTooltip
        id={randomId}
        className={style.tooltip}
        effect='solid'
        multiline
        place={props.place}
        html={props.html}
        wrapper='span'
      />
      <Element className={style.content} data-tip={props.message} data-for={randomId}>
        {props.children}
      </Element>
    </Element>
  );
}

Tooltip.propTypes = {
  message: PropTypes.string
};
