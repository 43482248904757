import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import authActions from '../../actions/auth';
import Header from './Header';

const mapStateToProps = () => {
  return createSelector(
    (appState) => appState.currentUser,
    (appState) => appState.auth,
    (appState) => appState.stack,
    (currentUser, auth, stack) => {
      const email = currentUser.email ? currentUser.email.slice(0, currentUser.email.indexOf('@')) : currentUser.email;
      return {
        username: currentUser.name || email,
        hasLinkedAws: currentUser.hasLinkedAws,
        currentUser,
        isImpersonating: !!auth.impersonate,
        isAuthenticated: auth.token
      };
    }
  );
};

const mapDispatchToProps = {
  signOut: authActions.signOut
};

class HeaderContainer extends Component {
  render () {
    return (
      <Header {...this.props} />
    );
  }
}

HeaderContainer.propTypes = {
  currentUser: PropTypes.object,
  getCurrentUser: PropTypes.func
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderContainer));
