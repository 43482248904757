import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import highlightSubstring from '../../utils/highlightSubstring';
import Table from '../core/Table';
import ActionHeader from '../core/ActionHeader';
import SearchFilter from '../core/SearchFilter';
import Button from '../core/Button';
import Spinner from '../core/Spinner';
import Link from '../core/Link';

const Tokens = props => {
  const {
    filteredTokens,
    searchTerm,
    timestampFormat,
    isEmpty,
    isLoading,
    onSearch,
    onCreateClick,
    onDeleteClick
  } = props;

  return (
    <div>
      <ActionHeader
        id='tokens-header'
        headerText='CLI Tokens'
        buttonText='Add a Token'
        buttonIcon='plus'
        onSearch={onSearch}
        onClick={onCreateClick}
      >
        <SearchFilter
          searchName='searchTerm'
          filterName='filterTerm'
          onSearchChange={onSearch}
        />
      </ActionHeader>
      <Table id='stackery-stacks' isStriped isEmpty={isEmpty}>
        <thead>
          <tr>
            <th>ID</th>
            <th>Description</th>
            <th>Created At</th>
          </tr>
        </thead>

        {isLoading &&
          <tbody>
            <tr>
              <td colSpan='4'><Spinner position='static' /></td>
            </tr>
          </tbody>
        }

        {!isLoading && isEmpty &&
          <tbody>
            <tr>
              <td colSpan='4'>No Tokens have been created. <Link onClick={onCreateClick}>Add a Token</Link> to get started.</td>
            </tr>
          </tbody>
        }

        {!isLoading && !isEmpty &&
          <tbody>
            {filteredTokens.length > 0 && filteredTokens.map((token, i) => (
              <tr key={token.id}>
                <td>{highlightSubstring(searchTerm, token.id)}</td>
                <td>{highlightSubstring(searchTerm, token.description)}</td>
                <td>{highlightSubstring(searchTerm, moment(token.createdAt).format(timestampFormat))}</td>
                <td>
                  <Button modifier='warning' text='Delete...' isDisabled={token.isDeleting} onClick={() => onDeleteClick(token)} />
                </td>
              </tr>
            ))}
            {!filteredTokens.length &&
              <tr>
                <td colSpan='4'>No tokens found that match <strong>{searchTerm}</strong></td>
              </tr>
            }
          </tbody>
        }
      </Table>
    </div>
  );
};

Tokens.propTypes = {
  filteredTokens: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isEmpty: PropTypes.bool.isRequired
};

export default Tokens;
