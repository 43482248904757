import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import apiActions from '../../actions/api';
import * as states from '../../constants/states';
import DeleteDeploymentPipeline from './DeleteDeploymentPipeline';

const mapStateToProps = (state, ownProps) => {
  return createSelector(
    (appState) => appState.account,
    (appState) => appState.currentUser,
    (appState) => appState.environments,
    (appState) => appState.stacks,
    (appState) => appState.deploymentPipelines,
    (account, currentUser, environments, stacks, deploymentPipelines) => {
      const deploymentPipeline = deploymentPipelines.data.find(pipeline => pipeline.id === ownProps.pipelineId) || {};

      return {
        deploymentPipeline,
        owner: currentUser.org,
        isSaving: deploymentPipeline.savingState === states.LOADING
      };
    }
  );
};

const mapDispatchToProps = {
  deleteDeploymentPipeline: apiActions.deleteDeploymentPipeline
};

class CreateDeploymentPipelineContainer extends Component {
  constructor (props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount () {
  }

  handleSubmit (event) {
    event.preventDefault();
    this.props.deleteDeploymentPipeline(this.props.owner, this.props.deploymentPipeline.id);
  }

  render () {
    return (
      <DeleteDeploymentPipeline
        {...this.props}
        {...this.state}
        onSubmit={this.handleSubmit}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateDeploymentPipelineContainer);
