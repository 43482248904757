import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from './Icon';
import Tooltip from './Tooltip';
import Spinner from './Spinner';
import style from './LinkProviderButton.css';

const LinkProviderButton = props => {
  const {
    provider,
    label,
    href,
    tooltip,
    hasLinked,
    isLoading,
    hasFailed,
    isGroup,
    onClick
  } = props;

  let text;
  let Elem = 'span';

  if (hasLinked) {
    text = `Linked ${label}!`;
  } else if (isLoading) {
    text = `Linking your ${label} account, please wait...`;
  } else if (hasFailed) {
    text = `Linking your ${label} account failed, please try again`;
  } else {
    text = `Link my ${label} account`;
    Elem = 'a';
  }

  const attrs = href ? {
    href: href,
    target: '_blank',
    onClick
  } : null;

  const content = (
    <Fragment>
      <Icon name={provider} className={style.iconProvider} />
      {text}
      {isLoading && <Spinner position='static' />}
    </Fragment>
  );

  if (tooltip) {
    return (
      <Tooltip className={classnames(style.container, hasLinked && style.isLinked, isGroup && style.isGroup)} message={tooltip} place='top'>
        <Elem
          className={style.content}
          {...attrs}
        >
          {content}
        </Elem>
      </Tooltip>
    );
  }

  return (
    <Elem
      className={classnames(style.container, hasLinked && style.isLinked, isGroup && style.isGroup)}
      {...attrs}
    >
      {content}
    </Elem>
  );
};

LinkProviderButton.defaultProps = {
  isGroup: false
};

LinkProviderButton.propTypes = {
  label: PropTypes.string,
  href: PropTypes.string,
  tooltip: PropTypes.string,
  hasLinked: PropTypes.bool,
  hasFailed: PropTypes.bool
};

export default LinkProviderButton;
