import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Select from '../core/Select';
import style from './RangeSelector.css';

const START_YEAR = 2018;
let years = [];

for (let i = 0; i <= moment().year() - START_YEAR; i++) {
  years.push(START_YEAR + i);
}

const RangeSelector = props => {
  const {
    onChange,
    selectedMonth,
    selectedYear
  } = props;

  return (
    <div className={style.container}>
      <Select
        name='selectedMonth'
        required
        onChange={onChange}
        value={selectedMonth}
      >
        {moment.months().map((month, index) => <option key={month} value={index}>{month}</option>)}
      </Select>
      <Select
        name='selectedYear'
        required
        onChange={onChange}
        value={selectedYear}
      >
        {years.map(year => <option key={year} value={year}>{year}</option>)}
      </Select>
    </div>
  );
};

RangeSelector.propTypes = {
  selectedMonth: PropTypes.number,
  selectedYear: PropTypes.number,
  onChange: PropTypes.func.isRequired
};

export default RangeSelector;
