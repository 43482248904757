import * as localStorage from '../utils/localStorage';
import { types } from '../actions/cms';
import * as states from '../constants/states';

const initialSectionState = {
  state: states.NEW,
  config: undefined,
  content: undefined
};

export const initialState = {
  state: states.NEW,
  sections: []
};

export default (state = { ...initialState }, action) => {
  let sections = [...state.sections];
  let section = action.config && sections.find(section => section.config.file === action.config.file);

  switch (action.type) {
    case types.GET_INDEX.REQUEST:
      return {
        ...state,
        state: states.LOADING
      };

    case types.GET_INDEX.SUCCESS:
      return {
        ...state,
        state: states.OKAY
      };

    case types.GET_INDEX.FAILURE:
      return {
        ...state,
        state: states.FAILED
      };

    case types.GET_SECTION.REQUEST:
      if (!section) {
        sections.push({...initialSectionState});
        section = sections[sections.length - 1];
      }

      section.state = states.LOADING;
      section.config = action.config;

      return {
        ...state,
        sections
      };

    case types.GET_SECTION.SUCCESS:
      section.state = states.OKAY;
      section.content = action.data;

      return {
        ...state,
        sections
      };

    case types.GET_SECTION.FAILURE:
      section.state = states.FAILED;

      return {
        ...state,
        sections
      };

    case types.DISMISS_SECTION:
      sections = sections.filter(section => section.config.file !== action.config.file);

      let dismissedSections = JSON.parse(localStorage.getItem('stackeryCMSDismissedSections') || '{}');
      dismissedSections[action.config.file] = 1;
      localStorage.setItem('stackeryCMSDismissedSections', JSON.stringify(dismissedSections));

      return {
        ...state,
        sections
      };

    default:
      return state;
  }
};
