import { types as appTypes } from '../actions/app';
import { types as apiTypes } from '../actions/api';
import * as states from '../constants/states';
import shortid from 'shortid';

export const initialState = {
  state: states.NEW,
  alerts: [],
  data: [],
  nextToken: undefined,
  unreadCount: 0,
  totalCount: 0,
  error: undefined
};

export default (state = {...initialState}, action) => {
  switch (action.type) {
    case appTypes.NOTIFY_USER:
      return {
        ...state,
        alerts: [
          {
            id: action.metadata && action.metadata.key ? action.metadata.key : shortid.generate(),
            level: action.level,
            message: action.message,
            ...action.metadata
          }
        ].concat(state.alerts)
      };

    case apiTypes.SAVE_USER_NOTIFICATION.SUCCESS:
      return {
        ...state,
        state: states.OKAY,
        totalCount: state.totalCount + 1,
        data: [
          {
            ...action.data,
            ...action.notification,
            owner: action.owner
          }
        ].concat(state.data)
      };

    case apiTypes.GET_USER_NOTIFICATIONS.REQUEST:
      return {
        ...state,
        state: states.LOADING
      };

    case apiTypes.GET_USER_NOTIFICATIONS.SUCCESS:
      return {
        ...state,
        state: states.OKAY,
        data: state.data.concat(action.data.notifications),
        nextToken: action.data.nextToken,
        unreadCount: action.data.unreadCount,
        totalCount: action.data.totalCount
      };

    case apiTypes.GET_USER_NOTIFICATIONS.FAILURE:
    case apiTypes.SAVE_USER_NOTIFICATION.FAILURE:
      return {
        ...state,
        state: states.FAILED,
        error: action.error
      };

    case appTypes.DISMISS_NOTIFICATION:
      return {
        ...state,
        alerts: state.alerts.filter(notification => notification.id !== action.id)
      };

    case apiTypes.DELETE_USER_NOTIFICATION.REQUEST:
      return {
        ...state,
        totalCount: state.totalCount - 1,
        data: state.data.filter(notification => notification.key !== action.notification.key || notification.timestamp !== action.notification.timestamp)
      };

    case apiTypes.DELETE_ALL_USER_NOTIFICATIONS.SUCCESS:
      return {
        ...state,
        data: [],
        nextToken: undefined,
        unreadCount: 0,
        totalCount: 0
      };

    default:
      return state;
  }
};
