import { types as apiTypes } from '../actions/api';
import * as states from '../constants/states';

export const initialState = {
  state: states.NEW,
  savingState: states.OKAY,
  data: {}
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case apiTypes.GET_BUILD_ENVIRONMENT.REQUEST:
      return {
        ...state,
        state: states.LOADING
      };

    case apiTypes.GET_BUILD_ENVIRONMENT.SUCCESS:
      return {
        ...state,
        state: states.OKAY,
        data: action.data.values.environment
      };

    case apiTypes.GET_BUILD_ENVIRONMENT.FAILURE:
      return {
        ...state,
        state: states.FAILED,
        error: action.error
      };

    case apiTypes.SAVE_BUILD_ENVIRONMENT.REQUEST:
      return {
        ...state,
        savingState: states.LOADING
      };

    case apiTypes.SAVE_BUILD_ENVIRONMENT.SUCCESS:
      return {
        ...state,
        savingState: states.OKAY,
        data: action.environment
      };

    case apiTypes.SAVE_BUILD_ENVIRONMENT.FAILURE:
      return {
        ...state,
        savingState: states.FAILED,
        error: action.error
      };

    default:
      return state;
  }
};
