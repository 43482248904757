import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { onTransition } from '../../utils/transition';
import apiActions from '../../actions/api';
import appActions from '../../actions/app';
import * as states from '../../constants/states';
import VerifyEmail from './VerifyEmail';

const mapStateToProps = () => {
  return createSelector(
    (appState) => appState.currentUser,
    (currentUser) => {
      return {
        currentUser
      };
    }
  );
};

const mapDispatchToProps = {
  verifyUserEmail: apiActions.verifyUserEmail,
  resendVerifyEmail: apiActions.resendVerifyEmail,
  notifyUser: appActions.notifyUser
};

class VerifyEmailContainer extends Component {
  constructor (props) {
    super(props);

    this.state = {
      hmac: null
    };
  }

  // If there's an hmac, call the verifyUserEmail function to verify the hmac with the BE
  componentDidUpdate (prevProps, prevState) {
    const {
      hmac
    } = this.state;

    const {
      email
    } = this.props.currentUser;

    if (hmac && prevState.hmac !== hmac && this.props.currentUser.state === states.OKAY) {
      this.props.verifyUserEmail(hmac);
    }

    onTransition(prevProps, this.props, 'currentUser', {
      [states.OKAY]: {
        notify: {
          message: `Successfully sent verification email to ${email}!`,
          level: 'success'
        }
      },
      [states.FAILED]: {
        notify: {
          message: `Failed to send verification email to ${email}`,
          level: 'error'
        }
      }
    }, 'resendVerifyEmailState');
  }

  componentDidMount () {
    const {
      search
    } = this.props.location;

    const hasHmac = search.includes('?hmac');

    // hasHmac will be true if coming from the email link, but will be false if coming from the initial sign-up
    if (hasHmac) {
      this.setState({
        hmac: search.slice(search.indexOf('=') + 1)
      });
    }
  }

  render () {
    return (
      <VerifyEmail {...this.props} {...this.state} />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmailContainer);
