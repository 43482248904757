import * as states from '../constants/states';
import { types as appTypes } from '../actions/app';
import { types as apiTypes } from '../actions/api';
import { types as authTypes } from '../actions/auth';

export const initialState = {
  state: states.NEW,
  type: null,
  childType: null,
  error: null,
  isChild: false,
  isOpen: false
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case appTypes.SHOW_CREATE_STACK_NEW_REPO_MODAL:
    case appTypes.SHOW_CREATE_STACK_EXISTING_REPO_MODAL:
    case appTypes.SHOW_ACTIVE_STACKS_LIMIT_MODAL:
    case appTypes.SHOW_UPGRADE_PROMPT_MODAL:
    case appTypes.SHOW_DEMO_MODAL:
    case appTypes.SHOW_EXIT_SETUP_MODAL:
    case appTypes.SHOW_RENAME_STACK_MODAL:
    case appTypes.SHOW_DELETE_STACK_MODAL:
    case appTypes.SHOW_DELETE_ENVIRONMENT_MODAL:
    case appTypes.SHOW_CREATE_ENVIRONMENT_MODAL:
    case appTypes.SHOW_COMMIT_STACK_MODAL:
    case appTypes.SHOW_CREATE_REFERENCE_NODE_MODAL:
    case appTypes.SHOW_CREATE_BRANCH_MODAL:
    case appTypes.SHOW_CREATE_REPO_MODAL:
    case appTypes.SHOW_EDIT_REPO_MODAL:
    case appTypes.SHOW_CREATE_STACK_BLUEPRINT_MODAL:
    case appTypes.SHOW_CANVAS_ERRORS_MODAL:
    case appTypes.SHOW_DEPLOYMENT_ERRORS_MODAL:
    case appTypes.SHOW_INVITE_USER_MODAL:
    case appTypes.SHOW_REMOVE_USER_MODAL:
    case appTypes.SHOW_INSTALL_CLI_MODAL:
    case appTypes.SHOW_IMPORT_GITHUB_REPOS_MODAL:
    case appTypes.SHOW_IMPORT_GITLAB_REPOS_MODAL:
    case appTypes.SHOW_CREDIT_CARD_MODAL:
    case appTypes.SHOW_SWITCH_PLAN_MODAL:
    case appTypes.SHOW_LINK_AWS_MODAL:
    case appTypes.SHOW_UNLINK_AWS_MODAL:
    case appTypes.SHOW_UPDATE_STACKERY_ROLE_MODAL:
    case appTypes.SHOW_UPDATE_PIPELINE_DEPLOYMENTS_MODAL:
    case appTypes.SHOW_ENTER_SETUP_MODAL:
    case appTypes.SHOW_CREATE_ENVIRONMENT_SECRET_MODAL:
    case appTypes.SHOW_DELETE_ENVIRONMENT_SECRET_MODAL:
    case appTypes.SHOW_EDIT_ENVIRONMENT_SECRET_DESCRIPTION_MODAL:
    case appTypes.SHOW_UPDATE_ENVIRONMENT_SECRET_VALUE_MODAL:
    case appTypes.SHOW_CREATE_TOKEN_MODAL:
    case appTypes.SHOW_DELETE_TOKEN_MODAL:
    case appTypes.SHOW_CONFIRM_FILE_TASK_MODAL:
    case appTypes.SHOW_CONFIRM_PATH_IN_USE_MODAL:
    case appTypes.SHOW_CONFIRM_PATH_EXISTS_MODAL:
    case appTypes.SHOW_CONFIRM_PATH_REMOVE_MODAL:
    case appTypes.SHOW_CONFIRM_PATH_RUNTIME_MODAL:
    case appTypes.SHOW_CREATE_DEPLOYMENT_PIPELINE_MODAL:
    case appTypes.SHOW_DELETE_DEPLOYMENT_PIPELINE_MODAL:
    case appTypes.SHOW_ENVIRONMENT_GIT_DIFF_MODAL:
      return {
        ...state,
        ...action,
        type: action.isChild ? state.type : action.type,
        childType: action.isChild ? action.type : null,
        isOpen: true,
        state: states.NEW
      };

    case apiTypes.DELETE_STACK.REQUEST:
    case apiTypes.RENAME_STACK.REQUEST:
    case apiTypes.DELETE_ENVIRONMENT.REQUEST:
    case apiTypes.SAVE_STACK_BLUEPRINT.REQUEST:
    case apiTypes.SAVE_NODES.REQUEST:
    case apiTypes.SAVE_RESOURCES.REQUEST:
    case apiTypes.CREATE_BRANCH.REQUEST:
    case apiTypes.SET_STACK_REPO.REQUEST:
    case apiTypes.CREATE_BRANCH_AND_SAVE_NODES.REQUEST:
    case apiTypes.CREATE_BRANCH_AND_SAVE_RESOURCES.REQUEST:
    case apiTypes.CREATE_ENVIRONMENT.REQUEST:
    case apiTypes.CREATE_SAME_ORGANIZATION_INVITE.REQUEST:
    case apiTypes.REMOVE_USER.REQUEST:
    case apiTypes.REMOVE_INVITED_USER.REQUEST:
    case apiTypes.CREATE_ENVIRONMENT_SECRET.REQUEST:
    case apiTypes.DELETE_ENVIRONMENT_SECRET.REQUEST:
    case apiTypes.SAVE_ENVIRONMENT_SECRET_DESCRIPTION.REQUEST:
    case apiTypes.SAVE_ENVIRONMENT_SECRET_VALUE.REQUEST:
    case apiTypes.SWITCH_PLAN.REQUEST:
    case apiTypes.DELETE_CLI_TOKEN.REQUEST:
      return {
        ...state,
        state: states.LOADING
      };

    case apiTypes.CREATE_BRANCH.SUCCESS:
      if (state.type === appTypes.SHOW_COMMIT_STACK_MODAL) {
        return state;
      }
      return { ...initialState };

    case apiTypes.CREATE_STACK.SUCCESS:
    case apiTypes.DELETE_STACK.SUCCESS:
    case apiTypes.RENAME_STACK.SUCCESS:
    case apiTypes.SAVE_STACK_BLUEPRINT.SUCCESS:
    case apiTypes.DELETE_ENVIRONMENT.SUCCESS:
    case apiTypes.SAVE_NODES.SUCCESS:
    case apiTypes.SET_STACK_REPO.SUCCESS:
    case apiTypes.CREATE_ENVIRONMENT.SUCCESS:
    case apiTypes.CREATE_DEPLOYMENT_PIPELINE.SUCCESS:
    case apiTypes.DELETE_DEPLOYMENT_PIPELINE.SUCCESS:
    case apiTypes.CREATE_SAME_ORGANIZATION_INVITE.SUCCESS:
    case apiTypes.REMOVE_USER.SUCCESS:
    case apiTypes.REMOVE_INVITED_USER.SUCCESS:
    case apiTypes.CREATE_ENVIRONMENT_SECRET.SUCCESS:
    case apiTypes.DELETE_ENVIRONMENT_SECRET.SUCCESS:
    case apiTypes.SAVE_ENVIRONMENT_SECRET_DESCRIPTION.SUCCESS:
    case apiTypes.SAVE_ENVIRONMENT_SECRET_VALUE.SUCCESS:
    case apiTypes.UPDATE_DEPLOYMENT_PIPELINE_SETTINGS.SUCCESS:
    case apiTypes.SWITCH_PLAN.SUCCESS:
    case authTypes.SIGN_IN.SUCCESS:
    case apiTypes.DELETE_CLI_TOKEN.SUCCESS:
    case appTypes.CONFIRM_MODAL:
    case appTypes.HIDE_MODAL:
      return state.isChild ? {
        ...state,
        childType: null,
        isChild: false
      } : { ...initialState };

    case apiTypes.DELETE_STACK.FAILURE:
    case apiTypes.RENAME_STACK.FAILURE:
    case apiTypes.SAVE_STACK_BLUEPRINT.FAILURE:
    case apiTypes.SAVE_NODES.FAILURE:
    case apiTypes.CREATE_BRANCH.FAILURE:
    case apiTypes.SET_STACK_REPO.FAILURE:
    case apiTypes.CREATE_ENVIRONMENT.FAILURE:
    case apiTypes.CREATE_SAME_ORGANIZATION_INVITE.FAILURE:
    case apiTypes.REMOVE_USER.FAILURE:
    case apiTypes.REMOVE_INVITED_USER.FAILURE:
    case apiTypes.CREATE_ENVIRONMENT_SECRET.FAILURE:
    case apiTypes.DELETE_ENVIRONMENT_SECRET.FAILURE:
    case apiTypes.SAVE_ENVIRONMENT_SECRET_DESCRIPTION.FAILURE:
    case apiTypes.SWITCH_PLAN.FAILURE:
    case apiTypes.SAVE_ENVIRONMENT_SECRET_VALUE.FAILURE:
    case apiTypes.DELETE_CLI_TOKEN.FAILURE:
      return {
        ...state,
        error: action.error.message.message,
        state: states.FAILED
      };
    default:
      return state;
  }
};
