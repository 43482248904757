import * as states from '../constants/states';
import { types as apiTypes } from '../actions/api';
import { types as appTypes } from '../actions/app';
import { types as iotNotificationTypes } from '../actions/iotNotifications';
import * as deploymentStates from '../constants/deploymentStates';
import * as verificationStates from '../constants/verificationStates';
import * as jobStates from '../constants/jobStates';

export const initialState = {
  state: states.NEW,
  savingState: states.NEW,
  data: []
};

const initialStateStack = {
  stackId: undefined,
  stackName: undefined,
  stackLink: undefined,
  stages: [],
  steps: [] // Array of verification step names
};

const initialStateStage = {
  deployed: {
    deploymentHistoryId: undefined,
    version: undefined,
    deploymentMethod: undefined,
    deploymentTimestamp: undefined,
    user: undefined,
    steps: []
  },
  preparing: {},
  queued: []
};

const initialStatePipeline = {
  state: states.NEW,
  statusState: states.NEW,
  settingsState: states.NEW,
  savingState: states.NEW,
  id: undefined,
  name: undefined,
  status: [
    /* Contains an array of deployment pipeline stack objects. Stack objects contain the following properites:
      {
        stackId,
        stackName,
        stackLink,
        stages: [
          {
            deployed: {
              deploymentHistoryId,
              version,
              deploymentMethod,
              deploymentTimestamp,
              user,
              steps: []
            },
            preparing: {},
            queued: []
          }
        ],
        steps: [] // Array of verification step names
      }
    */
  ],
  settings: {
    stages: [
      /* Contains an array of deployment pipeline stages objects. Stages objects contain the following properties:
        {
          environment: {
            id: '1234',
            name: 'development',
            region: 'us-west-2',
            accountId: '792019384456'
          },
          autoPromote: bool
        }
      */
    ],
    stacks: [
      // Contains an array of stackIds for which deployment pipelines are enabled
    ]
  }
};

const getStage = (stages, stageIndex) => {
  return stages[stageIndex] ? { ...stages[stageIndex] } : { ...initialStateStage };
};

const updateDeployment = (pipeline, action, stack) => {
  // find stack id in state.status[]
  // if steps array is incomplete, supplement with stack.steps
  // if status===deployed, set the deployed object
  // if status!==deployed, set the preparing object

  const stageIndex = action.environmentName ? pipeline.settings.stages.findIndex(({ environment }) => action.environmentName === environment.name) : action.stageIndex; // TODO: Check on `findIndex` babel & IE polyfill req't
  let stages = [...stack.stages];
  let stage = getStage(stages, stageIndex);

  const stageSettings = pipeline.settings.stages[stageIndex];

  const isDeployed = stage.deployed && stage.deployed.deploymentHistoryId === action.deploymentHistoryId;
  const isManualApprovalPending = action.deploymentMethod === 'pipeline' && !stageSettings.autoPromote && stack.steps.length === 0 && stageIndex !== (stages.length - 1);

  if (isDeployed || action.status === deploymentStates.DEPLOYED) {
    stage.deployed = isDeployed ? { ...stage.deployed, ...action } : { steps: [], isManualApprovalPending, ...action };

    if (!action.isPromoting && stack.steps.length > 0 && stage.deployed.steps && stage.deployed.steps.length === 0) {
      stage.deployed.steps = stack.steps.map(step => ({ name: step, status: verificationStates.STARTED }));
    }
  } else {
    // Check the deploymentMethod so prepare sequences outside of the pipeline will not affect the stage.preparing status updates
    stage.preparing = action.deploymentMethod === 'pipeline' ? { ...stage.preparing, ...action } : { ...stage.preparing };
  }

  stages[stageIndex] = {
    ...stage,
    deployed: { ...stage.deployed },
    preparing: { ...stage.preparing },
    queued: stage.queued.filter(queue => queue.version !== action.version)
  };

  return {
    ...stack,
    stages
  };
};

const updateStep = (pipeline, data, stack) => {
  const stageIndex = stack.stages.findIndex(({ deployed }) => data.deploymentHistoryId === deployed.deploymentHistoryId);
  let stages = [...stack.stages];
  const stage = getStage(stages, stageIndex);

  let steps = [...stage.deployed.steps];
  const stepIndex = steps.findIndex(({ name }) => data.name === name);

  if (stepIndex === -1) {
    steps = steps.concat({ ...data });
  } else {
    steps[stepIndex] = { ...data };
  }

  stages[stageIndex] = {
    ...stage,
    deployed: {
      ...stage.deployed,
      steps
    }
  };

  return {
    ...stack,
    stages
  };
};

const updateQueued = (pipeline, data, stack) => {
  const stageIndex = pipeline.settings.stages.findIndex(({ environment }) => data.environmentName === environment.name);

  let stages = [...stack.stages];
  const stage = getStage(stages, stageIndex);

  stages[stageIndex] = {
    ...stage,
    queued: data.version === stage.preparing.version ? [] : [].concat(data) // TODO: Handle skipped versions?
  };

  return {
    ...stack,
    stages
  };
};

const updateJob = (pipeline, data, stack) => {
  const stageIndex = stack.stages.findIndex(({ deployed }) => data.deploymentHistoryId === deployed.deploymentHistoryId);

  let stages = [...stack.stages];
  const stage = getStage(stages, stageIndex);

  stages[stageIndex] = {
    ...stage,
    deployed: {
      ...stage.deployed,
      ...data,
      isManualApprovalPending: data.status === jobStates.MANUAL_APPROVAL_PENDING,
      isPromoting: data.status === jobStates.STAGE_PROMOTED && stage.deployed.isPromoting ? false : stage.deployed.isPromoting
    }
  };

  return {
    ...stack,
    stages
  };
};

// Finds the stack that corresponds to the latest action and invokes a function to update its data
// Returns an array of stacks used to update state.status (which is an array of stacks within a pipeline object)
const updateStatus = (pipeline, action, fn) => {
  const stacks = [...pipeline.status];
  const stackIndex = stacks.findIndex((stack, index) => stack.stackId === action.stackId);
  const stack = stacks[stackIndex];

  stacks[stackIndex] = stack ? fn.apply(this, [pipeline, action, stack]) : { ...initialStateStack };

  return stacks;
};

// Assigns new data to (state.data[]) pipeline object if action.id matches the pipeline.id (of current state.data.map iteration)
const mapActionToPipeline = (pipeline, action) => {
  if (action.id && action.id !== pipeline.id) {
    return pipeline;
  }

  return {
    ...pipeline,
    ...action
  };
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case appTypes.ROUTE_CHANGED:
      return {
        ...initialState
      };

    case apiTypes.CREATE_DEPLOYMENT_PIPELINE.REQUEST:
      return {
        ...state,
        savingState: states.LOADING,
        error: undefined
      };

    case apiTypes.CREATE_DEPLOYMENT_PIPELINE.SUCCESS:
      return {
        ...state,
        savingState: states.OKAY,
        data: state.data.concat({
          ...initialStatePipeline,
          id: action.data.id,
          name: action.data.name,
          settings: {
            stages: action.data.stages,
            stacks: action.data.stacks
          }
        })
      };

    case apiTypes.DELETE_DEPLOYMENT_PIPELINE.REQUEST:
      return {
        ...state,
        data: state.data.map(pipeline => mapActionToPipeline(pipeline, {
          id: action.pipelineId,
          savingState: states.LOADING
        }))
      };

    case apiTypes.DELETE_DEPLOYMENT_PIPELINE.SUCCESS:
      return {
        ...state,
        data: state.data.filter(pipeline => pipeline.id !== action.pipelineId)
      };

    case apiTypes.GET_DEPLOYMENT_PIPELINES.REQUEST:
      return {
        ...state,
        state: states.LOADING
      };

    case apiTypes.GET_DEPLOYMENT_PIPELINES.SUCCESS:
      return {
        ...state,
        state: states.OKAY,
        data: action.data.map(pipeline => mapActionToPipeline({ ...initialStatePipeline, id: pipeline.id }, {
          id: pipeline.id,
          name: pipeline.name,
          settings: {
            stages: pipeline.stages,
            stacks: pipeline.stacks
          }
        }))
      };

    case apiTypes.GET_DEPLOYMENT_PIPELINES.FAILURE:
      return {
        ...state,
        state: states.FAILED
      };

    case apiTypes.GET_DEPLOYMENT_PIPELINE_SETTINGS.REQUEST:
      return {
        ...state,
        data: state.data.map(pipeline => mapActionToPipeline(pipeline, {
          id: action.pipelineId,
          settingsState: states.LOADING
        }))
      };

    case apiTypes.GET_DEPLOYMENT_PIPELINE_SETTINGS.SUCCESS:
      return {
        ...state,
        data: state.data.map(pipeline => mapActionToPipeline(pipeline, {
          id: action.pipelineId,
          settingsState: states.OKAY
        }))
      };

    case apiTypes.UPDATE_DEPLOYMENT_PIPELINE_SETTINGS.REQUEST:
      return {
        ...state,
        data: state.data.map(pipeline => mapActionToPipeline(pipeline, {
          id: action.pipelineId,
          savingState: states.LOADING
        }))
      };

    case apiTypes.UPDATE_DEPLOYMENT_PIPELINE_SETTINGS.SUCCESS:
      return {
        ...state,
        data: state.data.map(pipeline => mapActionToPipeline(pipeline, {
          savingState: states.OKAY,
          name: action.data.name,
          id: action.pipelineId,
          settings: {
            stages: action.data.stages,
            stacks: action.data.stacks
          },
          status: pipeline.status.filter(stack => action.data.stacks.includes(stack.id))
        }))
      };

    case apiTypes.GET_DEPLOYMENT_PIPELINE_STATUS.REQUEST:
      return {
        ...state,
        data: state.data.map(pipeline => mapActionToPipeline(pipeline, {
          id: action.pipelineId,
          statusState: states.LOADING
        }))
      };

    case apiTypes.GET_DEPLOYMENT_PIPELINE_STATUS.SUCCESS:
      return {
        ...state,
        data: state.data.map(pipeline => mapActionToPipeline(pipeline, {
          id: action.pipelineId,
          statusState: states.OKAY,
          status: action.data.status
        }))
      };

    case apiTypes.GET_DEPLOYMENT_PIPELINE_STATUS.FAILURE:
      return {
        ...state,
        data: state.data.map(pipeline => mapActionToPipeline(pipeline, {
          id: action.pipelineId,
          statusState: states.OKAY,
          error: action.error.message.message
        }))
      };

    case apiTypes.PROMOTE_DEPLOYMENT_PIPELINE.REQUEST:
      return {
        ...state,
        data: state.data.map(pipeline => mapActionToPipeline(pipeline, {
          id: action.pipelineId,
          status: updateStatus(pipeline, {
            ...action,
            status: deploymentStates.DEPLOYED,
            deploymentTimestamp: new Date().toISOString(),
            isPromoting: true,
            isManualApprovalPending: false
          }, updateDeployment)
        }))
      };

    case apiTypes.PROMOTE_DEPLOYMENT_PIPELINE.FAILURE:
      return {
        ...state,
        data: state.data.map(pipeline => mapActionToPipeline(pipeline, {
          id: action.pipelineId,
          status: updateStatus(pipeline, {
            ...action,
            status: deploymentStates.DEPLOYED,
            isPromoting: false
          }, updateDeployment)
        }))
      };

    case apiTypes.RETRY_DEPLOYMENT_PIPELINE.REQUEST:
      return {
        ...state,
        data: state.data.map(pipeline => mapActionToPipeline(pipeline, {
          id: action.pipelineId,
          status: updateStatus(pipeline, {
            ...action,
            status: deploymentStates.QUEUED,
            timestamp: new Date().toISOString(),
            deploymentMethod: 'pipeline',
            isRetried: true,
            isRetrying: true
          }, updateDeployment)
        }))
      };

    case apiTypes.RETRY_DEPLOYMENT_PIPELINE.SUCCESS:
      return {
        ...state,
        data: state.data.map(pipeline => mapActionToPipeline(pipeline, {
          id: action.pipelineId,
          status: updateStatus(pipeline, {
            ...action,
            deploymentMethod: 'pipeline',
            isRetrying: false
          }, updateDeployment)
        }))
      };

    case apiTypes.RETRY_DEPLOYMENT_PIPELINE.FAILURE:
      return {
        ...state,
        data: state.data.map(pipeline => mapActionToPipeline(pipeline, {
          id: action.pipelineId,
          status: updateStatus(pipeline, {
            ...action,
            deploymentMethod: 'pipeline',
            isRetried: false,
            isRetrying: false
          }, updateDeployment)
        }))
      };

    // NOTE: The following 4 actions do not contain a pipelineId so the iteration value (pipeline.id) is sent to preserve pipeline state (otherwise id will become undefined)
    // The actions are updating a specific status (stack) so pipelineId isn't critical but doing it this way means that each pipeline in the array will be analyzed
    // If the pipeline event step contained pipelineId, the redundancy with pipeline.id below could be avoided
    case iotNotificationTypes.DEPLOYMENT:
      return {
        ...state,
        data: state.data.map(pipeline => mapActionToPipeline(pipeline, {
          id: pipeline.id,
          status: updateStatus(pipeline, {...action, id: pipeline.id}, updateDeployment)
        }))
      };

    case iotNotificationTypes.DEPLOYMENT_PIPELINE_STEP:
      return {
        ...state,
        data: state.data.map(pipeline => mapActionToPipeline(pipeline, {
          id: pipeline.id,
          status: updateStatus(pipeline, {...action, id: pipeline.id}, updateStep)
        }))
      };

    case iotNotificationTypes.DEPLOYMENT_PIPELINE_JOB:
      return {
        ...state,
        data: state.data.map(pipeline => mapActionToPipeline(pipeline, {
          id: pipeline.id,
          status: updateStatus(pipeline, {...action, id: pipeline.id}, updateJob)
        }))
      };

    case iotNotificationTypes.DEPLOYMENT_PIPELINE_QUEUE:
      return {
        ...state,
        data: state.data.map(pipeline => mapActionToPipeline(pipeline, {
          id: pipeline.id,
          status: updateStatus(pipeline, {...action, id: pipeline.id}, updateQueued)
        }))
      };

    default:
      return state;
  }
};
