import React from 'react';
import Table from '../core/Table';
import ActionHeader from '../core/ActionHeader';
import Button from '../core/Button';
import Spinner from '../core/Spinner';
import UpgradePrompt from '../core/UpgradePrompt';
import style from './Settings.css';

const StackBlueprints = props => {
  const {
    blueprints,
    isLoaded,
    isEmpty,
    isDevPlan,
    onAddBlueprint,
    onRemoveBlueprint
  } = props;

  if (isDevPlan) {
    return (
      <div className={style.section}>
        <UpgradePrompt feature='blueprints' />
      </div>
    );
  }

  return (
    <div className={style.section}>
      <ActionHeader
        headerText='Stack Blueprints'
        buttonText='Add a Stack Blueprint'
        buttonIcon='plus'
        onClick={onAddBlueprint}
      />
      <p>Stack blueprints can be used as a boilerplate when creating a new stack. Add or remove blueprints to customize the create stack workflow.</p>
      <Table isStriped isEmpty={isEmpty}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Repository Url</th>
            <th>Reference</th>
            <th />
          </tr>
        </thead>

        {!isLoaded &&
          <tbody>
            <tr>
              <td colSpan='4'><Spinner position='static' /></td>
            </tr>
          </tbody>
        }

        {isLoaded && isEmpty &&
          <tbody>
            <tr>
              <td colSpan='4'>No blueprints have been created.</td>
            </tr>
          </tbody>
        }

        {isLoaded && !isEmpty &&
          <tbody>
            {blueprints.map(blueprint => (
              <tr key={blueprint.name}>
                <td>{blueprint.name}</td>
                <td><a href={blueprint.source.repository} target='_blank'>{blueprint.source.repository}</a></td>
                <td>{blueprint.source.reference}</td>
                <td>
                  <Button modifier='warning' text='Delete' onClick={() => onRemoveBlueprint(blueprint.name)} />
                </td>
              </tr>
            ))}
          </tbody>
        }
      </Table>
    </div>
  );
};

StackBlueprints.propTypes = {
};

export default StackBlueprints;
