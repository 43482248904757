import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../core/Icon';
import Link from '../core/Link';
import style from './ScrollNav.css';

const ScrollNav = props => {
  const {
    currScrollLeft,
    maxScrollLeft
  } = props;

  const isAtStart = currScrollLeft <= 0;
  const isAtEnd = currScrollLeft >= maxScrollLeft;

  return (
    <div className={style.container}>
      <Link className={isAtStart ? style.inactiveLink : undefined} extraClassName={style.link} onClick={props.onMovePrev}>
        <Icon name='chevron-left' className={style.iconChevron} />
        <span>Prev</span>
      </Link>
      <Link className={isAtEnd ? style.inactiveLink : undefined} extraClassName={style.link} onClick={props.onMoveNext}>
        <span>Next</span>
        <Icon name='chevron-right' className={style.iconChevron} />
      </Link>
    </div>
  );
};

ScrollNav.propTypes = {
  currScrollLeft: PropTypes.number.isRequired,
  maxScrollLeft: PropTypes.number.isRequired,
  onMoveNext: PropTypes.func.isRequired,
  onMovePrev: PropTypes.func.isRequired
};

export default ScrollNav;
