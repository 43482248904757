import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import actions from '../../actions/canvas';
import {
  nodeDefaultHeight,
  nodeOutlineWidth,
  labelOffset
} from '../../constants/canvas';
import Port from './Port';
import {
  calculateIcon,
  calculateName,
  calculateCustomLabel,
  calculateResourceLabel,
  calculatePortLocation,
  calculateNodeOutputPorts
} from '../../utils/calculateNode';
import * as definitions from '../../resources/definitions';
import Icon from '../core/Icon';
import NodeMetric from './NodeMetric';
import style from './Node.css';

class Node extends Component {
  constructor (props) {
    super(props);

    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleResizeMouseDown = this.handleResizeMouseDown.bind(this);
  }

  get node () {
    return this.props.node;
  }

  get type () {
    return this.props.nodeTypes[this.node.type];
  }

  get outputs () {
    return calculateNodeOutputPorts(this.node, this.type);
  }

  get labelClass () {
    let className = style.name;

    if (this.type.align === 'right') className = style.nameRight;

    if (typeof this.type.labelStyle === 'function') {
      try {
        className += ` ${this.type.labelStyle.call(this.node)}`;
      } catch (err) {
        console.warn(`Definition error: ${this.node.type}.labelStyle`, err);
      }
    } else if (this.type.labelStyle) {
      className += this.type.labelStyle;
    }

    return className;
  }

  componentDidMount () {
    // Workaround for waiting until addResource() completes before managing virtual network placement
    if (this.props.node.unsaved) {
      setTimeout(() => {
        if (this.props.resources[this.props.node.id]) {
          this.handleMouseUp();
        }
      }, 0);
    }
  }

  handleMouseUp (event) {
    if (this.node.type === 'facet') {
      return;
    }

    const isParent = type => type === 'virtualNetwork';
    const isChild = type => this.props.format !== 'stackery' && type in definitions[this.props.format].VirtualNetworkPlacements;

    const nodes = this.props.nodes;
    const id = this.props.node.id;
    const isActiveNodeParent = isParent(this.node.type);
    const isActiveNodeChild = isChild(this.node.type);

    const activeNode = document.getElementById(id);
    /** TODO: figure out the root cause of why activeNode is null or undefined
     * when adding a resource in edit and switching to view
     */
    if (!activeNode) {
      return;
    }
    const activeNodeRect = activeNode.getBoundingClientRect();
    let children = [];
    let parent = null;

    let withinX;
    let withinY;
    let width;
    let height;
    let left;
    let right;
    let top;
    let bottom;

    if (isActiveNodeChild) {
      width = (activeNodeRect.width / 2);
      height = (activeNodeRect.height / 2);
      left = activeNodeRect.left + width;
      right = activeNodeRect.right - width;
      top = activeNodeRect.top + height;
      bottom = activeNodeRect.bottom - height;
    }

    for (const node of nodes) {
      const currentNode = document.getElementById(node.id);
      const currentNodeRect = currentNode.getBoundingClientRect();

      const existingChildIndex = children.indexOf(node.id);
      const isExistingChild = existingChildIndex > -1;

      const isCurrentNodeParent = isParent(node.type);
      const isCurrentNodeChild = isChild(node.type);

      if (isActiveNodeParent && isCurrentNodeChild) {
        width = (currentNodeRect.width / 2);
        height = (currentNodeRect.height / 2);
        left = currentNodeRect.left + width;
        right = currentNodeRect.right - width;
        top = currentNodeRect.top + height;
        bottom = currentNodeRect.bottom - height;

        withinX = activeNodeRect.left <= left && activeNodeRect.right >= right;
        withinY = activeNodeRect.top <= top && activeNodeRect.bottom >= bottom;

        if (withinX && withinY && !isExistingChild) {
          children.push(node.id);
        } else if (!(withinX && withinY) && isExistingChild) {
          children.slice(existingChildIndex, existingChildIndex);
        }
      } else if (isActiveNodeChild && isCurrentNodeParent) {
        withinX = currentNodeRect.left <= left && currentNodeRect.right >= right;
        withinY = currentNodeRect.top <= top && currentNodeRect.bottom >= bottom;

        if (withinX && withinY) {
          parent = node.id;
          break;
        } else {
          parent = null;
        }
      }
    }

    this.props.nodeMouseUp(
      id,
      isActiveNodeParent,
      children,
      isActiveNodeChild,
      parent
    );
  }

  handleMouseDown (event) {
    event.stopPropagation();

    const canvasRect = document.getElementById('canvas').getBoundingClientRect();
    const x = event.clientX - canvasRect.left;
    const y = event.clientY - canvasRect.top;

    this.props.nodeMouseDown(
      this.props.node.id,
      x,
      y,
      event.metaKey || event.ctrlKey
    );
  }

  handleResizeMouseDown (side, event) {
    if (!this.props.editable) return;

    // The user is probably trying to select if the meta keys are pressed
    if (event.metaKey || event.ctrlKey) return;

    event.stopPropagation();

    const canvasRect = document.getElementById('canvas').getBoundingClientRect();
    const x = event.clientX - canvasRect.left;
    const y = event.clientY - canvasRect.top;

    this.props.nodeResizeMouseDown(this.props.node.id, x, y, side);
  }

  render () {
    const {
      nodes,
      format,
      resources,
      nodeTypes,
      isSelected,
      editable
    } = this.props;

    let icon = calculateIcon(this.type, format, this.node, resources);
    const customLabel = calculateCustomLabel(nodeTypes, this.node);
    const resourceLabel = calculateResourceLabel(nodeTypes, this.node, resources);
    const name = calculateName(this.node, resources);
    const isReference = this.node.arn || this.node.reference;
    const isCustom = this.node.type === 'custom';

    try {
      require.resolve(`../../icons/${icon}`);
    } catch (err) {
      // Don't crash the app if the node icon isn't available
      // Use the custom resource icon as a fallback
      window.Rollbar.error(new Error(`Unable to find icon file: ${icon}`));
      icon = 'custom';
    }

    return (
      <g
        id={this.node.id}
        transform={`translate(${this.node.left}, ${this.node.top})`}
        onMouseDown={this.handleMouseDown}
        onMouseUp={this.handleMouseUp}
        className={classNames(
          isSelected && style.isSelected,
          this.node.errors && style.hasErrors,
          (this.node.metrics && this.node.metrics.errors) && style.hasErrors,
          this.node.dirty && style.isDirty,
          (isReference || isCustom) && style.isCustom
        )}
        tabIndex='0'
      >
        <rect
          className={style.well}
          width={this.node.width}
          height={this.node.height}
          strokeWidth={`${nodeOutlineWidth}px`}
        />

        <rect
          className={style.titleBar}
          width={this.node.width - nodeOutlineWidth + 1}
          height={this.node.titleBarHeight - nodeOutlineWidth + 1}
          x={nodeOutlineWidth / 2 - 0.5}
          y={nodeOutlineWidth / 2 - 0.5}
        />
        {isReference &&
          <text
            className={style.label}
            x={this.type.align === 'right' ? this.node.width - labelOffset : labelOffset}
            y={(this.node.titleBarHeight / 2 - 1) - 7}
            dy='.35em'
          >
            Referenced Resource
          </text>
        }
        {customLabel &&
          <text
            className={style.label}
            x={this.type.align === 'right' ? this.node.width - labelOffset : labelOffset}
            y={(this.node.titleBarHeight / 2 - 1) - 7}
            dy='.35em'
          >
            Custom {customLabel}
          </text>
        }
        {resourceLabel &&
          <text
            className={style.label}
            x={this.type.align === 'right' ? this.node.width - labelOffset : labelOffset}
            y={(this.node.titleBarHeight / 2 - 1) - 7}
            dy='.35em'
          >
            {resourceLabel}
          </text>
        }
        <text
          className={this.labelClass}
          x={this.type.align === 'right' ? this.node.width - labelOffset : labelOffset}
          y={(isReference || customLabel || resourceLabel) ? (this.node.titleBarHeight / 2 - 1) + 7 : this.node.titleBarHeight / 2 - 1}
          dy='.35em'
        >
          {name}
        </text>

        {this.type.sizable &&
          <g>
            <rect
              className={style.resizeVertical}
              x='2'
              y='-2'
              width={this.node.width - 4}
              height='4'
              onMouseDown={this.handleResizeMouseDown.bind(this, 'top')}
            />
            <rect
              className={style.resizeVertical}
              x='2'
              y={this.node.height - 2}
              width={this.node.width - 4}
              height='4'
              onMouseDown={this.handleResizeMouseDown.bind(this, 'bottom')}
            />
            <rect
              className={style.resizeHorizontal}
              x='-2'
              y='2'
              width='4'
              height={this.node.height - 4}
              onMouseDown={this.handleResizeMouseDown.bind(this, 'left')}
            />
            <rect
              className={style.resizeHorizontal}
              x={this.node.width - 2}
              y='2'
              width='4'
              height={this.node.height - 4}
              onMouseDown={this.handleResizeMouseDown.bind(this, 'right')}
            />
          </g>}

        <rect
          className={style.iconBackground}
          x={this.type.align === 'right' ? this.node.width - nodeDefaultHeight : 2}
          y='2'
          width={nodeDefaultHeight}
          height={this.node.titleBarHeight - nodeOutlineWidth - 1}
        />

        <g x='0' y='0'>
          <Icon
            name={icon}
            className={style.iconNode}
            x={this.type.align === 'right' ? this.node.width - (nodeDefaultHeight - 4) : 6}
            y='4'
            width={nodeDefaultHeight - 4 * 2}
            height={nodeDefaultHeight - 4 * 2}
          />
        </g>

        {this.node.dirty && !this.node.errors &&
          <Icon
            name='asterisk'
            className={style.iconAsterisk}
            x={this.node.width - 16}
            y='10'
            width='13'
            height='8'
          />
        }

        {this.node.errors &&
          <Icon
            name='alert'
            className={style.iconAlert}
            x={this.node.width - 20}
            y='6'
            width='13'
            height='16'
          />
        }

        {this.node.metrics &&
          <g transform={`translate(0, ${this.node.titleBarHeight + 12})`} className={style.metricsGroup}>
            {this.node.metrics.dataVisible.map((metric, i) => <NodeMetric node={this.node} metric={metric} key={i} i={i} />)}
          </g>
        }

        {this.type.inputs &&
          <Port
            nodes={nodes}
            node={this.node}
            nodeTypes={nodeTypes}
            location={calculatePortLocation(this.node, nodeTypes, undefined, resources)}
            editable={editable}
            format={format}
          />
        }

        {[...Array(this.outputs)].map((_, i) =>
          <Port
            key={i}
            nodes={nodes}
            node={this.node}
            nodeTypes={nodeTypes}
            location={calculatePortLocation(this.node, nodeTypes, i, resources)}
            port={i}
            editable={editable}
            format={format}
          />
        )}
      </g>
    );
  }
}

export default connect(
  (state, props) => ({
    isSelected: state.canvas.selectedNodes.includes(props.node.id)
  }),
  {
    nodeMouseUp: actions.nodeMouseUp,
    nodeMouseDown: actions.nodeMouseDown,
    nodeResizeMouseDown: actions.nodeResizeMouseDown
  }
)(Node);
