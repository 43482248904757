import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { onTransition } from '../../utils/transition';
import apiActions from '../../actions/api';
import appActions from '../../actions/app';
import * as states from '../../constants/states';
import ManageUsers from './ManageUsers';

const mapStateToProps = () => {
  return createSelector(
    (appState) => appState.account,
    (appState) => appState.currentUser,
    (appState) => appState.users,
    (appState) => appState.usersInvited,
    (appState) => appState.inviteUser,
    (account, currentUser, users, usersInvited, inviteUser) => {
      return {
        currentUser,
        plan: account.plan,
        users: users.users,
        userLimit: account.plan.userLimit,
        usersInvited: usersInvited.users,
        isCurrentUserAdmin: currentUser.isCurrentUserAdmin,
        inviteUser,
        isLoading: account.plan.state === states.LOADING || users.state === states.LOADING || usersInvited === states.LOADING,
        isUserLimitMaxed: account.plan.userLimit !== null && ((users.users.length + usersInvited.users.length) >= account.plan.userLimit)
      };
    }
  );
};

const mapDispatchToProps = {
  getOrganizationUsers: apiActions.getOrganizationUsers,
  getOrganizationInvitedUsers: apiActions.getOrganizationInvitedUsers,
  removeUser: apiActions.removeUser,
  removeInvitedUser: apiActions.removeInvitedUser,
  updateUserRole: apiActions.updateUserRole,
  createSameOrganizationInvite: apiActions.createSameOrganizationInvite,
  notifyUser: appActions.notifyUser,
  showInviteUserModal: appActions.showInviteUserModal,
  showRemoveUserModal: appActions.showRemoveUserModal,
  showUpgradePromptModal: appActions.showUpgradePromptModal
};

class ManageUsersContainer extends Component {
  constructor (props) {
    super(props);

    this.handleAdminClick = this.handleAdminClick.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
    this.handleRemoveInvitedClick = this.handleRemoveInvitedClick.bind(this);
    this.handleInviteClick = this.handleInviteClick.bind(this);
  }

  componentDidMount () {
    this.props.getOrganizationUsers();
    this.props.getOrganizationInvitedUsers();
  }

  componentDidUpdate (prevProps) {
    onTransition(prevProps, this.props, 'inviteUser', {
      [states.OKAY]: {
        notify: {
          message: `Successfully sent invitation to ${this.props.inviteUser.email}`,
          level: 'success'
        }
      },
      [states.FAILED]: {
        notify: {
          message: `Failed to invite ${this.props.inviteUser.email}`,
          level: 'error'
        }
      }
    });
    onTransition(prevProps, this.props, 'updateUserRole', {
      [states.FAILED]: {
        notify: {
          message: `Failed to update role: ${this.props.updateUserRole.error}`,
          level: 'error'
        }
      }
    });
  }

  handleAdminClick (event) {
    const email = event.target.value;
    const isAdmin = event.target.checked;

    this.props.updateUserRole(email, isAdmin);
  }

  handleInviteClick () {
    if (this.props.isUserLimitMaxed) {
      this.props.showUpgradePromptModal({ prompt: 'userLimitReached', userLimit: this.props.userLimit, plan: this.props.plan.displayName });
    } else {
      this.props.showInviteUserModal({ currentUser: this.props.currentUser, isCurrentUserAdmin: this.props.isCurrentUserAdmin, onSubmit: this.props.createSameOrganizationInvite });
    }
  }

  handleRemoveClick (email) {
    this.props.showRemoveUserModal({ email, onSubmit: this.props.removeUser });
  }

  handleRemoveInvitedClick (email) {
    this.props.showRemoveUserModal({ email, onSubmit: this.props.removeInvitedUser });
  }

  render () {
    return (
      <ManageUsers
        {...this.props}
        onAdminClick={this.handleAdminClick}
        onInviteClick={this.handleInviteClick}
        onRemoveClick={this.handleRemoveClick}
        onRemoveInvitedClick={this.handleRemoveInvitedClick}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageUsersContainer);
