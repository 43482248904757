import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import apiActions from '../../actions/api';
import appActions from '../../actions/app';
import * as states from '../../constants/states';
import BuildEnvironment from './BuildEnvironment';

const mapStateToProps = () => {
  return createSelector(
    (appState) => appState.buildEnvironment,
    (buildEnvironment) => ({
      buildEnvironment,
      isEmpty: Object.keys(buildEnvironment.data).length === 0,
      isLoading: buildEnvironment.state === states.NEW || buildEnvironment.state === states.LOADING,
      isSaving: buildEnvironment.savingState === states.LOADING
    })
  );
};

const mapDispatchToProps = {
  getBuildEnvironment: apiActions.getBuildEnvironment,
  saveBuildEnvironment: apiActions.saveBuildEnvironment,
  notifyUser: appActions.notifyUser
};

function sortByKey (environment) {
  return environment.sort((a, b) => {
    if (a.key < b.key) {
      return -1;
    }
    if (a.key > b.key) {
      return 1;
    }
    return 0;
  });
}

class BuildEnvironmentContainer extends Component {
  constructor (props) {
    super(props);

    this.state = {
      fields: []
    };

    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount () {
    this.props.getBuildEnvironment();
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.buildEnvironment.data !== prevProps.buildEnvironment.data && this.props.buildEnvironment.state === states.OKAY) {
      let fields = Object.entries(this.props.buildEnvironment.data).map((row) => {
        return { key: row[0], value: row[1] };
      });
      fields = sortByKey(fields);
      fields.push({ key: '', value: '' });

      this.setState({ fields });
    }

    if (prevProps.isSaving && this.props.buildEnvironment.savingState === states.OKAY) {
      this.props.notifyUser('Build environment saved', 'success');
    }

    if (prevProps.isSaving && this.props.buildEnvironment.savingState === states.FAILED) {
      this.props.notifyUser('Failed to save', 'error');
    }
  }

  handleDeleteClick (index) {
    const fields = [...this.state.fields];
    fields.splice(index, 1);
    this.setState({ fields });
  }

  handleInputChange (event) {
    let [, index, category] = event.target.id.match(/(.+)-(key|value)/);
    index = parseInt(index, 10);

    const fields = [...this.state.fields];
    const entry = fields[index];

    if (category === 'key') {
      entry.key = event.target.value;
    } else if (category === 'value') {
      entry.value = event.target.value;
    }
    if (entry.key && entry.value && index === fields.length - 1) {
      fields.push({ key: '', value: '' });
    }
    this.setState({ fields });
  }

  handleSave (event) {
    event.preventDefault();

    const environment = {};
    for (const environmentEntry of this.state.fields) {
      if (environmentEntry.key !== '') {
        environment[environmentEntry.key] = environmentEntry.value;
      }
    }

    this.props.saveBuildEnvironment(environment);
  }

  render () {
    return (
      <BuildEnvironment
        {...this.props}
        {...this.state}
        onCreateClick={this.handleCreateClick}
        onDeleteClick={this.handleDeleteClick}
        onInputChange={this.handleInputChange}
        onSaveClick={this.handleSave}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BuildEnvironmentContainer);
