import React, { Component } from 'react';
import Spinner from '../core/Spinner';
import * as localStorage from '../../utils/localStorage';

class Demo extends Component {
  componentDidMount () {
    const status = this.props.match.params.status;
    const path = status === 'start' ? '/stacks' : '/';

    localStorage.setItem('userInDemoMode', status === 'start');

    // Need to trigger a hard reload
    window.location.replace(`${window.location.origin}${path}`);
  }
  render () {
    return <Spinner />;
  }
}

export default Demo;
