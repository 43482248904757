import { all, select, takeEvery } from 'redux-saga/effects';
import registerUser from '../utils/registerUser';
import { types as appTypes } from '../actions/app';
import { types as apiTypes } from '../actions/api';

function * setUser (action) {
  const plan = yield select(state => state.account.plan);

  registerUser(action.data.user, plan);
}

function * updateLocation (action) {}

export default function * route () {
  yield all([
    takeEvery(apiTypes.CURRENT_USER.SUCCESS, setUser),
    takeEvery(appTypes.ROUTE_CHANGED, updateLocation)
  ]);
}
