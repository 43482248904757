import * as Victor from 'victor';
import cloneDeep from 'clone-deep';
import { canvasPadding, overlapPadding, layoutSpreadWeight, spaceHeight, spaceWidth } from '../constants/canvas';
import { getNodeType } from './calculateNode';

export const layoutNodes = (nodes) => {
  let root = createTree(nodes);
  spreadTree(root);
  updateStackNodePositions(root, { x: 0, y: 0 });
};

// Take the relative positions from the treeNode
// and convert them to absolute positions in the canvasNodes
function updateStackNodePositions (node, parentTopLeftPosition) {
  // The root of the tree will not have a stackNode associated with it
  if (node.stackNode) {
    node.stackNode.left = parentTopLeftPosition.x + node.edge.left;
    node.stackNode.top = parentTopLeftPosition.y + node.edge.top;
    node.stackNode.x = parentTopLeftPosition.x + node.center.x;
    node.stackNode.y = parentTopLeftPosition.y + node.center.y;
    node.stackNode.height = node.height;
    node.stackNode.width = node.width;
  }

  let topLeft = {
    x: parentTopLeftPosition.x + node.edge.left,
    y: parentTopLeftPosition.y + node.edge.top
  };

  node.children.forEach(child => updateStackNodePositions(child, topLeft));
}

function spreadTree (node) {
  if (node.children.length === 0) {
    return;
  }

  node.children.forEach(child => spreadTree(child));

  // 1. Spread out children
  spread(node.children);

  // 2. Resize container to fit children
  resizeContainerToFitChildren(node);
}

function spread (nodes) {
  const repelDecayCoefficient = 1;
  let _step = true;
  let i;
  let j;
  const count = nodes.length;

  do {
    _step = true;
    for (i = 0; i < count; i += 1) {
      const nodeA = nodes[i];
      const nodeACenter = new Victor(nodeA.center.x, nodeA.center.y);
      const velocity = new Victor();

      for (j = 0; j < count; j += 1) {
        if (i === j) {
          continue;
        }

        const nodeB = nodes[j];

        if (!areOverlapping(nodeA, nodeB)) {
          continue;
        }

        // If nodeA and nodeB are overlapping, repel nodeA away from nodeB
        const nodeBCenter = new Victor(nodeB.center.x, nodeB.center.y);
        let diff = nodeACenter.subtract(nodeBCenter);

        if (diff.length() > 0) {
          const scale = repelDecayCoefficient / diff.lengthSq();
          diff.normalize();

          diff.x = diff.x * scale;
          diff.y = diff.y * scale;
          velocity.add(diff);
        }
      }

      if (velocity.length() > 0) {
        _step = false;
        velocity.normalize();
        updateNodePosition(nodeA, velocity);
      }
    }
  } while (!_step);
}

function areOverlapping (nodeA, nodeB) {
  // If one node's right edge is further left than the other's left edge
  if (
    (nodeA.edge.right + overlapPadding.x < nodeB.edge.left) ||
    (nodeB.edge.right + overlapPadding.x < nodeA.edge.left)
  ) {
    return false;
  }

  // If one node's bottom edge is above the other's top edge
  if (
    (nodeA.edge.bottom + overlapPadding.y < nodeB.edge.top) ||
    (nodeB.edge.bottom + overlapPadding.y < nodeA.edge.top)
  ) {
    return false;
  }

  return true;
}

function updateNodePosition (node, velocity) {
  node.center.x += velocity.x * layoutSpreadWeight.x;
  node.center.y += velocity.y * layoutSpreadWeight.y;
  node.edge.top = node.center.y - (node.height / 2);
  node.edge.bottom = node.center.y + (node.height / 2);
  node.edge.left = node.center.x - (node.width / 2);
  node.edge.right = node.center.x + (node.width / 2);
}

function resizeContainerToFitChildren (node) {
  let bounds = calculateBounds(node.children);

  // Add padding to bounds.top to factor in title bar height
  if (node.stackNode) {
    bounds.top -= node.stackNode.titleBarHeight;
  }

  let delta = {
    x: Math.abs(Math.min(0, bounds.left - canvasPadding.x)),
    y: Math.abs(Math.min(0, bounds.top - canvasPadding.y))
  };

  // Shift bounds by delta as well
  bounds.top += delta.y;
  bounds.bottom += delta.y;
  bounds.left += delta.x;
  bounds.right += delta.x;

  shiftNodes(delta, node.children);

  // Only grow the container, don't shrink it
  node.height = Math.max(node.height, bounds.bottom - bounds.top + canvasPadding.x * 2);
  node.width = Math.max(node.width, bounds.right - bounds.left + canvasPadding.y * 2);

  node.edge.bottom = node.edge.top + node.height;
  node.edge.right = node.edge.left + node.width;

  // Update center due to new bounds
  node.center.x = node.edge.left + (node.width / 2);
  node.center.y = node.edge.top + (node.height / 2);

  // Shift nodes into container if overflowing on the right or bottom
  // This can happen if the center of the bounds is below or to the right
  // of the center of the container
  delta = {
    x: Math.min(0, node.width - bounds.right - canvasPadding.x),
    y: Math.min(0, node.height - bounds.bottom - canvasPadding.y)
  };
  shiftNodes(delta, node.children);
}

function calculateBounds (nodes) {
  let bounds = cloneDeep(nodes[0].edge);
  nodes.forEach(node => {
    bounds.top = Math.min(bounds.top, node.edge.top);
    bounds.bottom = Math.max(bounds.bottom, node.edge.bottom);
    bounds.left = Math.min(bounds.left, node.edge.left);
    bounds.right = Math.max(bounds.right, node.edge.right);
  });

  bounds.top -= overlapPadding.y;
  bounds.bottom += overlapPadding.y;
  bounds.left -= overlapPadding.x;
  bounds.right += overlapPadding.x;

  return bounds;
}

function shiftNodes (delta, nodes) {
  nodes.forEach(node => shiftNode(delta, node));
}

function shiftNode (delta, node) {
  node.center.x += delta.x;
  node.center.y += delta.y;
  node.edge.top = node.center.y - (node.height / 2);
  node.edge.bottom = node.center.y + (node.height / 2);
  node.edge.left = node.center.x - (node.width / 2);
  node.edge.right = node.center.x + (node.width / 2);
}

function createTree (stackNodes) {
  let nodes = [];

  stackNodes.forEach(stackNode => nodes.push(createTreeNode(stackNode)));

  let root = createTreeNode();

  nodes.forEach(node => {
    let parent = getParent(node, nodes) || root;

    parent.children.push(node);
    node.parent = parent;
  });

  convertToRelativePositioning(root);

  return root;
}

function getParent (node, nodes) {
  let type = getNodeType(node.stackNode);
  let parentType = window.RED.nodes.type(type).parentType;
  if (!parentType) {
    // If a non-VPC node doesn't have an actual parent type, allow it to
    // still stay inside of a VPC to help reduce visual discrepencies
    // between the edit and deploy views
    if (node.stackNode.type === 'virtualNetwork') {
      return;
    }
    parentType = 'virtualNetwork';
  }

  let parent = node.stackNode.parent(parentType);
  if (!parent) {
    return;
  }

  return nodes.find(n => n.stackNode.id === parent.id);
}

function createTreeNode (stackNode) {
  let treeNode = {
    stackNode: stackNode,
    parent: undefined,
    children: [],
    isContainer: true,
    edge: {
      top: 0,
      bottom: spaceHeight,
      left: 0,
      right: spaceWidth
    },
    center: {
      x: spaceWidth / 2,
      y: spaceHeight / 2
    },
    height: spaceHeight,
    width: spaceWidth
  };

  if (stackNode) {
    treeNode.isContainer = stackNode.isContainer;
    treeNode.edge = {
      top: stackNode.y - (stackNode.height / 2),
      bottom: stackNode.y + (stackNode.height / 2),
      left: stackNode.x - (stackNode.width / 2),
      right: stackNode.x + (stackNode.width / 2)
    };
    treeNode.center = {
      x: stackNode.x,
      y: stackNode.y
    };
    treeNode.height = stackNode.height;
    treeNode.width = stackNode.width;
  }

  return treeNode;
}

// Convert the nodes edges and center to relative to it's parents position
// edges are based off parent's top edge and left edge
// center is based off parent's top left corner
function convertToRelativePositioning (node) {
  node.children.forEach(child => convertToRelativePositioning(child));

  if (node.parent) {
    let parent = node.parent;

    node.edge.top = node.edge.top - parent.edge.top;
    node.edge.bottom = node.edge.top + node.height;
    node.edge.left = node.edge.left - parent.edge.left;
    node.edge.right = node.edge.left + node.width;

    node.center.x = node.edge.left + (node.width / 2);
    node.center.y = node.edge.top + (node.height / 2);
  }
}

export function getTopLeftPosition (node) {
  return {
    left: node.x - (node.width / 2),
    top: node.y - (node.height / 2)
  };
}
