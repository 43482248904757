import React from 'react';
import PropTypes from 'prop-types';
import * as gitProviderStates from '../../constants/gitProviderStates';
import errorCodes from '../../constants/errorCodes';
import LinkButton from '../core/LinkButton';
import style from './LoadingFailed.css';

const LoadingFailed = props => {
  const {
    gitProvider,
    error
  } = props;

  if (gitProvider) {
    const linkButton =
      <LinkButton
        key={`${gitProvider.name}OAuth`}
        name='OauthLinkButton'
        href={gitProvider.authUrl}
        target='_new'
        text={`Link to ${gitProvider.displayName}`}
      />;

    if (error.code === errorCodes.GIT_NO_TOKEN || error.code === errorCodes.GIT_BAD_TOKEN) {
      return (
        <div className={style.container}>
          <p>Your Stackery and {gitProvider.displayName} accounts must be linked in order to edit the contents of this stack.</p>
          {error.code === errorCodes.GIT_BAD_TOKEN && <p>{error.message}</p>}
          {gitProvider.state === gitProviderStates.URL_ACQUIRED && linkButton}
        </div>
      );
    }

    if (error.code === errorCodes.GIT_AUTH_READ_FAILURE) {
      return (
        <div className={style.container}>
          <p>Unable to load stack contents.</p>
          <p>There was an authorization error that prevents READ access.</p>
          {gitProvider.state === gitProviderStates.URL_ACQUIRED && linkButton}
        </div>
      );
    }

    if (error.code === errorCodes.GIT_AUTH_WRITE_FAILURE) {
      return (
        <div className={style.container}>
          <p>There was an authorization error that prevents WRITE access.</p>
          <p>Relinking your Stackery and {gitProvider.displayName} accounts may resolve the issue.</p>

          {gitProvider.state === gitProviderStates.URL_ACQUIRED && linkButton}
        </div>
      );
    }
  }

  return (
    <div className={style.container}>
      <p>Unable to load stack contents.</p>
      <p>{error.message}.</p>
    </div>
  );
};

LoadingFailed.propTypes = {
  error: PropTypes.object.isRequired
};

export default LoadingFailed;
