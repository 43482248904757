export default ({id, link}, gitProviders, version, codeDir) => {
  let gitLink = {
    url: null,
    type: null,
    version: version,
    codeDir
  };

  let suffix;
  let match = link && link.match(/^(\S+):\/\/.*/);
  if (match) {
    let provider = match[1];

    if (provider === 'codecommit') {
      const splits = link.split('/');
      const region = splits[3];
      const repoName = splits[4];

      suffix = version ? `/${version}/--/` : '/HEAD/--/';
      gitLink.url = `//${region}.console.aws.amazon.com/codecommit/home?region=${region}#/repository/${repoName}/browse${suffix}`;
      gitLink.type = 'CodeCommit';
    } else if (gitProviders && gitProviders[provider]) {
      if (gitProviders[provider].type === 'bitbucket' && provider !== 'bitbucket') {
        match = link.match(/:\/\/(.*)\/(.*)/);
        if (match) {
          let owner = match[1].replace('/~', '~');
          let repo = match[2];
          let suffix = version ? `/commits/${version}` : '';
          gitLink.url = `//${gitProviders[provider].url}projects/${owner}/repos/${repo}/${suffix}`;
        }
      } else {
        if (gitProviders[provider].type === 'bitbucket') {
          suffix = version ? `/src/${version}/` : '/';
        } else {
          suffix = version ? `/tree/${version}/` : '/';
        }
        gitLink.url = `${link.replace(`${provider}://`, gitProviders[provider].url)}${suffix}`;
      }
      gitLink.type = gitProviders[provider].displayName; // TODO: GitLink.type is being used as a Display Name rather than type. Ideally we should rename the gitLink.type to gitLink.displayName
    }
  }

  if (codeDir) {
    gitLink.url += codeDir;
  }

  return gitLink;
};
