import { types as apiTypes } from '../actions/api';
import { types as appTypes } from '../actions/app';
import * as states from '../constants/states';

export const initialState = {
  state: states.NEW,
  savingState: states.NEW,
  name: undefined,
  templatePath: undefined,
  deploymentStrategy: undefined,
  deploymentHooksDirectory: undefined,
  buildImage: undefined,
  ephemeralEnvironmentId: null,
  verificationPipeline: {
    vulnerabilityTestsEnabled: false,
    functionalTestsEnabled: false,
    deploymentPreviewEnabled: false
  },
  error: undefined
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case appTypes.DESELECT_STACK:
      return {
        ...initialState
      };

    case apiTypes.GET_STACK_SETTINGS.REQUEST:
      return {
        ...state,
        state: states.LOADING,
        error: undefined
      };

    case apiTypes.GET_STACK_SETTINGS.SUCCESS:
      const { ephemeralEnvironmentId, ...verificationPipeline } = action.data.verificationPipeline;
      return {
        ...state,
        state: states.OKAY,
        name: action.data.name,
        templatePath: action.data.templatePath,
        deploymentStrategy: action.data.deploymentStrategy,
        deploymentHooksDirectory: action.data.deploymentHooksDirectory || '',
        buildImage: action.data.buildImage,
        verificationEnvironmentId: ephemeralEnvironmentId,
        verificationPipeline
      };

    case apiTypes.GET_STACK_SETTINGS.FAILURE:
      return {
        ...state,
        state: states.FAILED,
        error: action.error
      };

    case apiTypes.SAVE_STACK_SETTINGS.REQUEST:
      return {
        ...state,
        savingState: states.LOADING,
        error: undefined
      };

    case apiTypes.SAVE_STACK_SETTINGS.SUCCESS:
      return {
        ...state,
        savingState: states.OKAY,
        name: action.newName,
        templatePath: action.templatePath,
        deploymentStrategy: action.deploymentStrategy,
        deploymentHooksDirectory: action.deploymentHooksDirectory || '',
        buildImage: action.buildImage,
        verificationPipeline: action.verificationPipeline
      };

    case apiTypes.SAVE_STACK_SETTINGS.FAILURE:
      return {
        ...state,
        savingState: states.FAILED,
        error: action.error.error.message.message
      };

    case apiTypes.GET_STACK.SUCCESS:
      return {
        ...state,
        name: action.data.name,
        templatePath: action.data.templatePath,
        deploymentStrategy: action.data.deploymentStrategy,
        deploymentHooksDirectory: action.data.deploymentHooksDirectory || '',
        buildImage: action.data.buildImage
      };

    default:
      return state;
  }
};
