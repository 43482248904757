import { types as apiTypes } from '../actions/api';
import * as states from '../constants/states';

export const initialState = {
  state: states.NEW,
  owner: undefined,
  stack: undefined,
  environmentId1: undefined,
  environmentId2: undefined,
  commits: []
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case apiTypes.GET_ENVIRONMENT_GIT_DIFF.REQUEST:
      return {
        ...initialState,
        state: states.LOADING,
        owner: action.owner,
        stack: action.stack,
        environmentId1: action.environmentId1,
        environmentId2: action.environmentId2
      };

    case apiTypes.GET_ENVIRONMENT_GIT_DIFF.SUCCESS:
      if (action.owner !== state.owner || action.stack !== state.stack || action.environmentId1 !== state.environmentId1 || action.environmentId2 !== state.environmentId2) {
        return state;
      }

      return {
        ...state,
        state: states.OKAY,
        commits: action.data.commits
      };

    case apiTypes.GET_ENVIRONMENT_GIT_DIFF.FAILURE:
      return {
        ...state,
        state: states.FAILED
      };

    default:
      return state;
  }
};
