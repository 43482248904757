
import React from 'react';
import PropTypes from 'prop-types';
import FieldSet from '../core/FieldSet';
import Input from '../core/Input';

const RepoVisibility = props => {
  return (
    <FieldSet legend='Repo visibility'>
      <Input
        name='isPrivate'
        type='radio'
        value='false'
        label='Public'
        checked={props.isPrivate === false}
        inlineField
        onChange={props.onInputChange}
      />
      <Input
        name='isPrivate'
        type='radio'
        value='true'
        label='Private'
        checked={props.isPrivate === true}
        inlineField
        onChange={props.onInputChange}
      />
    </FieldSet>
  );
};

RepoVisibility.propTypes = {
  isPrivate: PropTypes.bool,
  onInputChange: PropTypes.func.isRequired
};

export default RepoVisibility;
