import { types as apiTypes } from '../actions/api';
import { types as appTypes } from '../actions/app';
import * as states from '../constants/gitProviderStates';

const initialProviderState = {
  state: states.NEW,
  importedState: states.NEW,
  reposState: states.NEW,
  authUrl: undefined,
  repos: [],
  imported: [],
  destinations: undefined,
  code: undefined,
  error: undefined,
  name: undefined,
  displayName: undefined,
  type: undefined,
  needsPersonalAccessToken: undefined
};

export const initialState = {
  state: states.NEW,
  codecommit: {
    ...initialProviderState,
    name: 'codecommit',
    displayName: 'CodeCommit',
    type: 'codecommit'
  },
  github: {
    ...initialProviderState,
    name: 'github',
    displayName: 'GitHub',
    type: 'github',
    url: '//github.com/'
  },
  gitlab: {
    ...initialProviderState,
    name: 'gitlab',
    displayName: 'GitLab',
    type: 'gitlab',
    url: '//gitlab.com/'
  },
  bitbucket: {
    ...initialProviderState,
    name: 'bitbucket',
    displayName: 'Bitbucket',
    type: 'bitbucket',
    url: '//bitbucket.org/'
  }
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case apiTypes.GET_GIT_DESTINATIONS.REQUEST:
    case apiTypes.GET_GIT_AUTH_URL.REQUEST:
      return {
        ...state,
        [action.provider]: {
          ...state[action.provider],
          state: states.LOADING
        }
      };

    case apiTypes.GET_GIT_DESTINATIONS.SUCCESS:
      const isAuthorized = action.provider !== 'codecommit' || action.data.destinations.length > 0;
      return {
        ...state,
        [action.provider]: {
          ...state[action.provider],
          state: isAuthorized ? states.AUTHORIZED : states.NEW,
          destinations: action.data.destinations
        }
      };

    case apiTypes.GET_ACCOUNT_INFO.SUCCESS:
      const isAwsLinked = action.data.default && action.data.default.accountId;
      const destinations = state.codecommit.destinations || [];
      return {
        ...state,
        codecommit: {
          ...state.codecommit,
          state: isAwsLinked ? states.AUTHORIZED : states.NEW,
          destinations: isAwsLinked ? destinations.concat({ id: action.data.default.accountId, name: action.data.default.accountId }) : destinations
        }
      };

    case apiTypes.GET_GIT_AUTH_URL.SUCCESS:
      if (action.data.authType === 'PersonalAccessToken') {
        return {
          ...state,
          [action.provider]: {
            ...state[action.provider],
            state: states.PERSONAL_ACCESS_TOKEN_NEEDED,
            authType: action.data.authType,
            needsPersonalAccessToken: true
          }
        };
      }

      return {
        ...state,
        [action.provider]: {
          ...state[action.provider],
          state: states.URL_ACQUIRED,
          authUrl: action.data.authorizeUrl
        }
      };

    case apiTypes.GET_GIT_REPOS.REQUEST:
      return {
        ...state,
        [action.provider]: {
          ...state[action.provider],
          reposState: states.LOADING
        }
      };

    case apiTypes.GET_GIT_REPOS.SUCCESS:
      return {
        ...state,
        [action.provider]: {
          ...state[action.provider],
          state: states.AUTHORIZED,
          reposState: states.OKAY,
          repos: action.data.repositories
        }
      };

    case apiTypes.GET_GIT_AUTH_TOKEN.REQUEST:
      return {
        ...state,
        [action.provider]: {
          ...state[action.provider],
          state: states.FETCHING_TOKEN
        }
      };

    case apiTypes.GET_GIT_AUTH_TOKEN.SUCCESS:
      return {
        ...state,
        [action.provider]: {
          ...state[action.provider],
          state: states.AUTHORIZED
        }
      };

    case apiTypes.SAVE_GIT_PERSONAL_ACCESS_TOKEN.REQUEST:
      return {
        ...state,
        [action.provider]: {
          ...state[action.provider],
          state: states.LOADING
        }
      };

    case apiTypes.SAVE_GIT_PERSONAL_ACCESS_TOKEN.SUCCESS:
      return {
        ...state,
        [action.provider]: {
          ...state[action.provider],
          state: states.AUTHORIZED,
          needsPersonalAccessToken: false
        }
      };

    case apiTypes.GET_GIT_AUTH_URL.FAILURE:
    case apiTypes.GET_GIT_REPOS.FAILURE:
    case apiTypes.GET_GIT_AUTH_TOKEN.FAILURE:
    case apiTypes.SAVE_GIT_PERSONAL_ACCESS_TOKEN.FAILURE:
      const errorMessage = (action.error && action.error.message.message)
        ? action.error.message.message
        : 'Issue connecting to API. Please refresh and try again';
      return {
        ...state,
        [action.provider]: {
          ...state[action.provider],
          state: states.FAILED,
          error: errorMessage
        }
      };

    case apiTypes.GET_GIT_PROJECTS.REQUEST:
      return {
        ...state,
        [action.provider]: {
          ...state[action.provider],
          importedState: states.LOADING
        }
      };

    case apiTypes.GET_GIT_PROJECTS.SUCCESS:
      return {
        ...state,
        [action.provider]: {
          ...state[action.provider],
          importedState: states.OKAY,
          imported: action.data.imported
        }
      };

    case apiTypes.GET_GIT_PROJECTS.FAILURE:
      return {
        ...state,
        [action.provider]: {
          ...state[action.provider],
          importedState: states.FAILED,
          error: action.error.message.message
        }
      };

    case appTypes.NOTIFY_USER:
      if (action.message !== states.NOTIFY_AUTHORIZATION_SUCCESS) {
        return state;
      }

      const providers = {};

      Object.entries(state).forEach(([name, provider]) => {
        if (typeof provider === 'object' && provider.hasOwnProperty('authUrl')) {
          providers[provider] = {
            ...state[provider],
            authUrl: undefined
          };
        }
      });

      return {
        ...state,
        ...providers
      };

    case appTypes.ADD_GIT_PROVIDER:
      if (action.provider.name in state) {
        return state;
      }

      return {
        ...state,
        [action.provider.name]: {
          ...initialProviderState,
          name: action.provider.name,
          displayName: action.provider.displayName,
          type: action.provider.type,
          url: action.provider.url
        }
      };

    case apiTypes.GET_ACCOUNT_SETTINGS.REQUEST:
      return {
        ...state,
        state: states.LOADING
      };

    case apiTypes.GET_ACCOUNT_SETTINGS.FAILURE:
      return {
        ...state,
        state: states.FAILED
      };

    case appTypes.GIT_PROVIDERS_UPDATED:
      return {
        ...state,
        state: states.OKAY
      };

    default:
      return state;
  }
};
