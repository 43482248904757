import makeActions from '../utils/makeActions';

export const types = {
  START_WORKSPACE: 'WORKSPACE::START_WORKSPACE',
  STOP_WORKSPACE: 'WORKSPACE::STOP_WORKSPACE',
  CONNECT: 'WORKSPACE::CONNECT',
  DISCONNECT: 'WORKSPACE::DISCONNECT',
  CONNECT_FAILURE: 'WORKSPACE::CONNECT_FAILURE',
  RECEIVE_MESSAGE: 'WORKSPACE::RECEIVE_MESSAGE',
  IGNORE_MESSAGE: 'WORKSPACE::IGNORE_MESSAGE',
  READ: makeActions('WORKSPACE', 'READ'),
  SAVE: makeActions('WORKSPACE', 'SAVE'),
  REMOVE: makeActions('WORKSPACE', 'REMOVE'),
  RENAME: makeActions('WORKSPACE', 'RENAME'),
  LOAD: 'WORKSPACE::LOAD',
  LOAD_EXTERNAL_FILE: makeActions('WORKSPACE', 'LOAD_EXTERNAL_FILE')
};

const actions = {
  startWorkspace: (port, templateDir, templatePath, secret, host) => ({
    type: types.START_WORKSPACE,
    port,
    templateDir,
    templatePath,
    secret,
    host
  }),
  stopWorkspace: () => ({
    type: types.STOP_WORKSPACE
  }),
  connect: () => ({
    type: types.CONNECT
  }),
  disconnect: () => ({
    type: types.DISCONNECT
  }),
  connectFailure: (error) => ({
    type: types.CONNECT_FAILURE,
    error
  }),
  receiveMessage: (message) => ({
    type: types.RECEIVE_MESSAGE,
    message
  }),
  ignoreMessage: () => ({
    type: types.IGNORE_MESSAGE
  }),
  read: (file) => ({
    type: types.READ.REQUEST,
    file
  }),
  save: (file, contents) => ({
    type: types.SAVE.REQUEST,
    file,
    contents
  }),
  remove: (file) => ({
    type: types.REMOVE.REQUEST,
    file
  }),
  rename: (from, to) => ({
    type: types.RENAME.REQUEST,
    from,
    to
  }),
  load: (contents, format) => ({
    type: types.LOAD,
    contents,
    format
  })
};

export default actions;
