import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as states from '../../constants/states';
import StatusMessage from '../core/StatusMessage';
import Form from '../core/Form';
import FormRow from '../core/FormRow';
import Input from '../core/Input';
import ButtonSet from '../core/ButtonSet';

class InviteUser extends Component {
  constructor (props) {
    super(props);

    this.state = {
      inviteEmail: null,
      isAdmin: false
    };

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleAdminChange = this.handleAdminChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleEmailChange (event) {
    this.setState({ inviteEmail: event.currentTarget.value });
  }

  handleAdminChange (event) {
    this.setState({ isAdmin: event.currentTarget.checked });
  }

  handleSubmit (event) {
    event.preventDefault();

    const {
      org,
      email
    } = this.props.currentUser;

    this.props.onSubmit(this.state.inviteEmail, this.state.isAdmin, org, email);
  }

  render () {
    return (
      <Form onSubmit={this.handleSubmit}>
        <h1>Invite New User</h1>
        <p>Enter an email address below to send an invite to a team member.</p>
        {this.props.error && (
          <StatusMessage type='error' text={this.props.error.message} />
        )}
        <FormRow label='Recipient Email'>
          <Input
            type='text'
            id='input-inviteEmail'
            name='inviteEmail'
            required
            onChange={this.handleEmailChange}
          />
        </FormRow>
        <FormRow label='Grant Admin Privileges'>
          <Input
            type='checkbox'
            id='input-adminCheckbox'
            name='isAdmin'
            disabled={!this.props.isCurrentUserAdmin} // Do not allow non-admins to invite admins
            value={this.state.isAdmin}
            checked={this.state.isAdmin}
            onChange={this.handleAdminChange}
          />
        </FormRow>
        <ButtonSet
          primaryButton={{
            text: 'Send Invite',
            type: 'submit',
            isLoading: this.props.state === states.LOADING
          }}
          secondaryButton={{
            text: 'Cancel',
            onClick: this.props.onClickCancel
          }}
        />
      </Form>
    );
  }
}

InviteUser.propTypes = {
  isCurrentUserAdmin: PropTypes.bool
};

export default InviteUser;
