import React from 'react';
import PropTypes from 'prop-types';
import highlightSubstring from '../../utils/highlightSubstring';
import Spinner from '../core/Spinner';
import Link from '../core/Link';
import Icon from '../core/Icon';
import Tooltip from '../core/Tooltip';
import SearchFilter from '../core/SearchFilter';
import Timestamp from '../deployments/Timestamp';
import style from './EnvironmentGitDiff.css';

const getEmailSubstring = (string) => string.substring((string.indexOf('<') + 1), string.indexOf('>'));
const getNameSubstring = (string) => string.substring(0, string.indexOf(' <'));

const filterFieldsetOptions = [
  {
    name: 'show',
    label: 'All commits',
    value: 'all'
  },
  {
    name: 'show',
    label: 'Merge commits',
    value: 'merge'
  },
  {
    name: 'show',
    label: 'Non-merge commits',
    value: 'nonmerge'
  }
];

const EnvironmentGitDiff = props => {
  const hasEmptyDiff = props.commits.length === 0;
  const hasEmptyResults = !hasEmptyDiff && props.filteredCommits.length === 0;

  return (
    <div>
      <header className={style.header}>
        <h1>
          New commits from {props.environment1.name}
          <Tooltip isInline message={`Compare ${props.environment2.name} to ${props.environment1.name}`} place='top'><Link extraClassName={style.switchLink} onClick={props.onSwitch}><Icon name='double-arrow' className={style.iconDoubleArrow} /></Link></Tooltip>
          {props.environment2.name}
        </h1>
        {!props.isLoading && !hasEmptyDiff &&
          <SearchFilter
            placeholder='Search SHA, label, message, or author'
            searchName='searchTerm'
            filterName='filterCommitType'
            filterFieldset={props.hasMergeCommits ? filterFieldsetOptions : undefined}
            filterFieldsetType='radio'
            filterFieldsetDefaultChecked={props.filterCommitType}
            onSearchChange={props.onInputChange}
            onFilterChange={props.onInputChange}
          />
        }
      </header>

      {props.isLoading &&
        <Spinner position='static' />
      }

      {!props.isLoading && hasEmptyDiff &&
        <p>The {props.environment1.name} environment is <strong>0 commits</strong> ahead of the {props.environment2.name} environment.</p>
      }

      {!props.isLoading && hasEmptyResults &&
        <p>No commits found that match <strong>{props.searchTerm}</strong></p>
      }

      {!props.isLoading &&
        <ul className={style.list}>
          {props.filteredCommits.map(commit =>
            <li key={`diff-${commit.sha}`} className={style.item}>
              <header className={style.itemHeader}>
                <Link
                  extraClassName={style.version}
                  href={commit.link}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {highlightSubstring(props.searchTerm, commit.sha.substring(0, 7))}
                </Link>
                <Timestamp status='Committed' timestamp={commit.date} />
                {commit.author &&
                  <Tooltip className={style.author} isInline message={getEmailSubstring(commit.author)} place='top'><span className={style.authorPre}>by </span>{highlightSubstring(props.searchTerm, getNameSubstring(commit.author))}</Tooltip>
                }
              </header>
              <div className={style.itemContent}>
                <p className={style.message}>{highlightSubstring(props.searchTerm, commit.message)}</p>
                {commit.description &&
                  <p className={style.description}>{highlightSubstring(props.searchTerm, commit.description)}</p>
                }
                {commit.labels && commit.labels.length > 0 &&
                  <ul className={style.list}>
                    {commit.labels.map(label => <li className={style.label}>{highlightSubstring(props.searchTerm, label)}</li>)}
                  </ul>
                }
              </div>
            </li>
          )}
        </ul>
      }
    </div>
  );
};

EnvironmentGitDiff.propTypes = {
  environment1: PropTypes.object.isRequired,
  environment2: PropTypes.object.isRequired,
  commits: PropTypes.array.isRequired,
  filteredCommits: PropTypes.array.isRequired,
  filterCommitType: PropTypes.string.isRequired,
  searchTerm: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  onSwitch: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired
};

export default EnvironmentGitDiff;
