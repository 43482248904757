import React from 'react';
import PropTypes from 'prop-types';
import ContextMenu from '../core/ContextMenu';
import EnvironmentDetails from './EnvironmentDetails';
import style from './EnvironmentHeader.css';

const EnvironmentHeader = (props) => {
  const {
    environment,
    owner
  } = props.environment;

  return (
    <header className={style.container}>
      <div>
        <h1 className={style.title}>
          <span>{environment}</span>
          <ContextMenu
            align='right'
            items={[
              {
                label: 'Delete',
                onClick: props.onDeleteClick,
                params: {
                  name: environment,
                  owner: owner
                }
              }
            ]}
          />
        </h1>
      </div>
      <EnvironmentDetails {...props.environment} />
    </header>
  );
};

EnvironmentHeader.propTypes = {
  environment: PropTypes.object.isRequired,
  onDeleteClick: PropTypes.func.isRequired
};

export default EnvironmentHeader;
