import cloneDeep from 'clone-deep';

import { types as canvasTypes } from '../actions/canvas';
import { types } from '../actions/nodeEditor';
import { types as apiTypes } from '../actions/api';
import { types as appTypes } from '../actions/api';
import * as states from '../constants/states';

export const initialState = {
  node: undefined,
  isAutoSaved: false,
  template: {
    state: states.NEW,
    data: undefined,
    variables: undefined,
    error: undefined
  }
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case types.EDIT_NODE:
      return {
        ...state,
        node: cloneDeep(action.node),
        isAutoSaved: false
      };

    case types.AUTO_SAVE:
      // Do not autosave if state.node has been unset
      if (!state.node) {
        return state;
      }

      return {
        ...state,
        node: cloneDeep(action.node),
        isAutoSaved: true
      };

    case types.CANCEL:
    case canvasTypes.CLEAR_SELECTION:
    case canvasTypes.DESELECT_NODE:
    case canvasTypes.SELECT_NODES:
    case canvasTypes.UPDATE_NODE:
    case appTypes.DESELECT_STACK_RESOURCE:
      return initialState;

    case apiTypes.GET_NODE_TEMPLATE.REQUEST:
      return {
        ...state,
        template: {
          state: states.LOADING,
          data: undefined,
          variables: undefined,
          error: undefined
        }
      };

    case apiTypes.GET_NODE_TEMPLATE.SUCCESS:
      // TODO: Make sure template is for this node
      return {
        ...state,
        template: {
          state: states.OKAY,
          data: action.data.template,
          variables: action.data.variables,
          error: undefined
        }
      };

    case apiTypes.GET_NODE_TEMPLATE.FAILURE:
      return {
        ...state,
        template: {
          state: states.FAILED,
          data: undefined,
          variables: undefined,
          error: action.error.message.message
        }
      };

    default:
      return state;
  }
};
