import moment from 'moment';

// Converts ISO timestamp into human-readable format
export default (timestamp, usePreposition = false) => {
  const date = new Date(timestamp).toLocaleDateString();
  const today = new Date().toLocaleDateString();
  const dateString = today === date ? 'Today' : `${usePreposition ? 'On' : ''} ${moment(timestamp).format('MM/DD/YYYY')}`;
  const timestampString = moment(timestamp).format('h:mma');

  return `${dateString} at ${timestampString}`;
};
