import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { types as appTypes } from '../../actions/app';
import CreateStackNewRepo from './CreateStackNewRepo';
import CreateStackExistingRepo from './CreateStackExistingRepo';
import ActiveStacksLimit from './ActiveStacksLimit';
import UpgradePrompt from './UpgradePrompt';
import Demo from './Demo';
import ExitSetup from './ExitSetup';
import DeleteStack from './DeleteStack';
import RenameStack from './RenameStack';
import DeleteEnvironment from './DeleteEnvironment';
import CreateEnvironmentContainer from './CreateEnvironmentContainer';
import CommitStack from './CommitStack';
import CreateBranch from './CreateBranch';
import CreateRepo from './CreateRepo';
import EditRepo from './EditRepo';
import CreateReferenceNode from './CreateReferenceNode';
import CreateStackBlueprintContainer from './CreateStackBlueprintContainer';
import CanvasErrors from './CanvasErrors';
import DeploymentErrors from './DeploymentErrors';
import InviteUser from './InviteUser';
import RemoveUser from './RemoveUser';
import InstallCli from './InstallCli';
import LinkAws from './LinkAws';
import UnlinkAws from './UnlinkAws';
import UpdateStackeryRole from './UpdateStackeryRole';
import UpdatePipelineDeployments from './UpdatePipelineDeployments';
import EnterSetup from './EnterSetup';
import ImportBitbucketReposContainer from './ImportBitbucketReposContainer';
import ImportGithubReposContainer from './ImportGithubReposContainer';
import ImportGitlabReposContainer from './ImportGitlabReposContainer';
import CreateEnvrionmentSecret from './CreateEnvironmentSecret';
import DeleteEnvrionmentSecret from './DeleteEnvironmentSecret';
import EditEnvironmentSecretDescription from './EditEnvironmnetSecretDescription';
import UpdateEnvironmentSecretValue from './UpdateEnvironmentSecretValue';
import CreateDeploymentPipelineContainer from './CreateDeploymentPipelineContainer';
import DeleteDeploymentPipelineContainer from './DeleteDeploymentPipelineContainer';
import EnvironmentGitDiffContainer from './EnvironmentGitDiffContainer';
import CloseButton from '../core/CloseButton';
import SwitchPlanContainer from './SwitchPlanContainer';
import CreateTokenContainer from './CreateTokenContainer';
import DeleteToken from './DeleteToken';
import ConfirmPathInUse from './ConfirmPathInUse';
import ConfirmPathExists from './ConfirmPathExists';
import ConfirmPathRemove from './ConfirmPathRemove';
import ConfirmPathRuntime from './ConfirmPathRuntime';
import style from './Modal.css';

const applyMode = (Component, isDemoMode) => isDemoMode ? Demo : Component;

const mapTypeToComponent = (type, isDemoMode) => {
  return {
    [appTypes.SHOW_DEMO_MODAL]: Demo,
    [appTypes.SHOW_EXIT_SETUP_MODAL]: ExitSetup,
    [appTypes.SHOW_CREATE_STACK_NEW_REPO_MODAL]: applyMode(CreateStackNewRepo, isDemoMode),
    [appTypes.SHOW_CREATE_STACK_EXISTING_REPO_MODAL]: applyMode(CreateStackExistingRepo, isDemoMode),
    [appTypes.SHOW_DELETE_STACK_MODAL]: applyMode(DeleteStack, isDemoMode),
    [appTypes.SHOW_RENAME_STACK_MODAL]: applyMode(RenameStack, isDemoMode),
    [appTypes.SHOW_DELETE_ENVIRONMENT_MODAL]: applyMode(DeleteEnvironment, isDemoMode),
    [appTypes.SHOW_CREATE_ENVIRONMENT_MODAL]: applyMode(CreateEnvironmentContainer, isDemoMode),
    [appTypes.SHOW_COMMIT_STACK_MODAL]: applyMode(CommitStack, isDemoMode),
    [appTypes.SHOW_CREATE_REFERENCE_NODE_MODAL]: applyMode(CreateReferenceNode, isDemoMode),
    [appTypes.SHOW_CREATE_BRANCH_MODAL]: applyMode(CreateBranch, isDemoMode),
    [appTypes.SHOW_CREATE_REPO_MODAL]: CreateRepo,
    [appTypes.SHOW_EDIT_REPO_MODAL]: applyMode(EditRepo, isDemoMode),
    [appTypes.SHOW_CREATE_STACK_BLUEPRINT_MODAL]: applyMode(CreateStackBlueprintContainer, isDemoMode),
    [appTypes.SHOW_CANVAS_ERRORS_MODAL]: CanvasErrors,
    [appTypes.SHOW_DEPLOYMENT_ERRORS_MODAL]: DeploymentErrors,
    [appTypes.SHOW_INVITE_USER_MODAL]: applyMode(InviteUser, isDemoMode),
    [appTypes.SHOW_REMOVE_USER_MODAL]: applyMode(RemoveUser, isDemoMode),
    [appTypes.SHOW_INSTALL_CLI_MODAL]: InstallCli,
    [appTypes.SHOW_ENTER_SETUP_MODAL]: EnterSetup,
    [appTypes.SHOW_IMPORT_BITBUCKET_REPOS_MODAL]: applyMode(ImportBitbucketReposContainer, isDemoMode),
    [appTypes.SHOW_IMPORT_GITHUB_REPOS_MODAL]: applyMode(ImportGithubReposContainer, isDemoMode),
    [appTypes.SHOW_IMPORT_GITLAB_REPOS_MODAL]: applyMode(ImportGitlabReposContainer, isDemoMode),
    [appTypes.SHOW_LINK_AWS_MODAL]: LinkAws,
    [appTypes.SHOW_UNLINK_AWS_MODAL]: UnlinkAws,
    [appTypes.SHOW_UPDATE_STACKERY_ROLE_MODAL]: UpdateStackeryRole,
    [appTypes.SHOW_UPDATE_PIPELINE_DEPLOYMENTS_MODAL]: UpdatePipelineDeployments,
    [appTypes.SHOW_ACTIVE_STACKS_LIMIT_MODAL]: ActiveStacksLimit,
    [appTypes.SHOW_UPGRADE_PROMPT_MODAL]: UpgradePrompt,
    [appTypes.SHOW_SWITCH_PLAN_MODAL]: SwitchPlanContainer,
    [appTypes.SHOW_CREATE_ENVIRONMENT_SECRET_MODAL]: applyMode(CreateEnvrionmentSecret, isDemoMode),
    [appTypes.SHOW_DELETE_ENVIRONMENT_SECRET_MODAL]: applyMode(DeleteEnvrionmentSecret, isDemoMode),
    [appTypes.SHOW_EDIT_ENVIRONMENT_SECRET_DESCRIPTION_MODAL]: applyMode(EditEnvironmentSecretDescription, isDemoMode),
    [appTypes.SHOW_UPDATE_ENVIRONMENT_SECRET_VALUE_MODAL]: applyMode(UpdateEnvironmentSecretValue, isDemoMode),
    [appTypes.SHOW_CREATE_TOKEN_MODAL]: CreateTokenContainer,
    [appTypes.SHOW_DELETE_TOKEN_MODAL]: DeleteToken,
    [appTypes.SHOW_CONFIRM_PATH_IN_USE_MODAL]: ConfirmPathInUse,
    [appTypes.SHOW_CONFIRM_PATH_EXISTS_MODAL]: ConfirmPathExists,
    [appTypes.SHOW_CONFIRM_PATH_REMOVE_MODAL]: ConfirmPathRemove,
    [appTypes.SHOW_CONFIRM_PATH_RUNTIME_MODAL]: ConfirmPathRuntime,
    [appTypes.SHOW_CREATE_DEPLOYMENT_PIPELINE_MODAL]: CreateDeploymentPipelineContainer,
    [appTypes.SHOW_DELETE_DEPLOYMENT_PIPELINE_MODAL]: DeleteDeploymentPipelineContainer,
    [appTypes.SHOW_ENVIRONMENT_GIT_DIFF_MODAL]: EnvironmentGitDiffContainer
  }[type];
};

const stopPropagation = (event) => event.stopPropagation();

const Modal = props => {
  const ModalComponent = mapTypeToComponent(props.type, props.isDemoMode);
  const ChildModalComponent = props.isChild ? mapTypeToComponent(props.childType, props.isDemoMode) : null;

  return (
    <div className={style.container} onMouseDown={props.isCloseable ? props.hideModal : null}>
      <div id={props.modalId} className={classnames(style.modal, props.isChild && style.isParent)} onClick={stopPropagation} onMouseDown={stopPropagation} ref={props.setRef}>
        {props.isCloseable &&
          <CloseButton size='medium' onClick={props.hideModal} />
        }
        <ModalComponent style={style} {...props} onClickConfirm={props.confirmModal} onClickCancel={props.hideModal} />
        {ChildModalComponent &&
          // TODO: Determine if modalId and ref are needed
          <div className={classnames(style.modal, style.isChild)} onClick={stopPropagation} onMouseDown={stopPropagation}>
            {props.isCloseable &&
              <CloseButton size='medium' onClick={props.hideModal} />
            }
            <ChildModalComponent style={style} {...props} onClickConfirm={props.confirmModal} onClickCancel={props.hideModal} />
          </div>
        }
      </div>
    </div>
  );
};

Modal.defaultProps = {
  isChild: false,
  isCloseable: true
};

Modal.propTypes = {
  type: PropTypes.string.isRequired
};

export default Modal;
