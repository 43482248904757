import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { hints, patterns } from '../../constants/patternHintText';
import ButtonSet from '../core/ButtonSet';
import FieldSet from '../core/FieldSet';
import Form from '../core/Form';
import FormRow from '../core/FormRow';
import Input from '../core/Input';
import Select from '../core/Select';
import Spinner from '../core/Spinner';
import UpgradePrompt from '../core/UpgradePrompt';
import ToggleField from './ToggleField';
import InputGate from './InputGate';
import style from './StackSettings.css';

const CODEBUILD_IMAGES = [
  'aws/codebuild/amazonlinux2-x86_64-standard:3.0',
  'aws/codebuild/amazonlinux2-x86_64-standard:2.0',
  'aws/codebuild/standard:4.0',
  'aws/codebuild/standard:3.0',
  'aws/codebuild/standard:2.0'
];

const StackSettings = props => {
  const {
    stack,
    stackSettings,
    gitProvider,
    isDevPlan,
    isLoading,
    isSaving,
    isAccountLinked,
    isVerificationPipelineEnabled,
    isDeployerRoleEnabled,
    isPrWebhookAuthorized,
    environments,
    deploymentStrategy,
    verificationEnvironmentId,
    verificationEnvironments,
    verificationPipeline,
    onSelect,
    onToggle,
    onInputChange,
    onSubmit
  } = props;

  const hasEnvironments = environments.data.length > 0;
  const hasVerificationEnvironments = verificationEnvironments.length > 0;

  if (isLoading) {
    return (
      <div className={style.container}>
        <Spinner />
      </div>
    );
  }

  return (
    <div className={style.container}>
      <Form onSubmit={onSubmit}>
        <FormRow label='Stack Name' hint={hints['[a-zA-Z0-9_-]*']}>
          <Input
            id='input-stackName'
            name='name'
            required
            pattern='[a-zA-Z0-9_-]*'
            onChange={onInputChange}
            defaultValue={stackSettings.name}
          />
        </FormRow>
        {!stack.isGitless &&
          <Fragment>
            <FormRow label='Stack Template Path' hint={hints.relativePath}>
              <Input
                id='input-stackTemplatePath'
                name='templatePath'
                required
                pattern={patterns.relativePath}
                onChange={onInputChange}
                defaultValue={stackSettings.templatePath}
              />
            </FormRow>
            {stackSettings.deploymentStrategy === 'legacy' &&
              <FieldSet legend='Deployment Strategy' hint={'The pre-configured strategy for deploying this stack'}>
                <Input
                  name='deploymentStrategy'
                  type='radio'
                  label='CodeBuild'
                  value='codebuild'
                  checked={deploymentStrategy === 'codebuild'}
                  onChange={onInputChange}
                  inlineField
                />
                <Input
                  name='deploymentStrategy'
                  type='radio'
                  label='Legacy'
                  value='legacy'
                  checked={deploymentStrategy === 'legacy'}
                  onChange={onInputChange}
                  inlineField
                />
              </FieldSet>
            }
            <FormRow label='Deployment Hooks Directory Path' hint={hints.relativePath}>
              <Input
                id='input-deploymentHooksDir'
                name='deploymentHooksDirectory'
                pattern={patterns.relativePath}
                onChange={onInputChange}
                defaultValue={stackSettings.deploymentHooksDirectory}
              />
            </FormRow>
            {deploymentStrategy === 'codebuild' &&
              <FormRow label='Build Image' hint='The CodeBuild image used to prepare deployments'>
                <Input
                  id='input-buildImage'
                  name='buildImage'
                  pattern={patterns.dockerImage}
                  onChange={onInputChange}
                  suggestions={CODEBUILD_IMAGES}
                  defaultValue={stackSettings.buildImage}
                />
              </FormRow>
            }
            {gitProvider.name !== 'codecommit' &&
              <FieldSet legend='Verification Options'>
                <ToggleField
                  name='vulnerabilityTestsEnabled'
                  type='custom'
                  label='Dependency Vulnerability Checks'
                  hint='Check installed package dependencies for known security vulnerabilities.'
                  isChecked={verificationPipeline.vulnerabilityTestsEnabled}
                  onToggle={onToggle}
                />
                <ToggleField
                  name='functionalTestsEnabled'
                  type='custom'
                  label='Functional Tests'
                  hint='Run standard test scripts to detect defects within or between individual modules of the application.'
                  isChecked={verificationPipeline.functionalTestsEnabled}
                  onToggle={onToggle}
                />
                <ToggleField
                  name='deploymentPreviewEnabled'
                  type='custom'
                  label='Deployment Preview'
                  hint='Generate a deployment preview that is synced with changes to the pull request.'
                  isChecked={verificationPipeline.deploymentPreviewEnabled}
                  onToggle={onToggle}
                />

                {!isAccountLinked &&
                  <InputGate {...props} type='needsLinkedAccount' />
                }
                {isAccountLinked && !isDeployerRoleEnabled &&
                  <InputGate {...props} type='needsEnabledAccount' />
                }
                {isDeployerRoleEnabled && !hasEnvironments &&
                  <InputGate {...props} type='needsEnabledEnvironment' />
                }
                {isDeployerRoleEnabled && hasEnvironments && !hasVerificationEnvironments &&
                  <InputGate {...props} type='needsEnabledAccount' />
                }

                {!isPrWebhookAuthorized &&
                  <InputGate {...props} type='needsGitAuth' />
                }
              </FieldSet>
            }
            {isVerificationPipelineEnabled && hasVerificationEnvironments &&
              <FormRow label='Verification Environment' hint='Environment used as the basis for test deployments during verification stage'>
                <Select
                  name='ephemeralEnvironmentId'
                  required
                  onChange={onSelect}
                  value={verificationEnvironmentId || ''}
                >
                  <option disabled value={''}>Choose Environment</option>
                  {verificationEnvironments.map(environment => <option key={environment.id} value={environment.id}>{environment.name}</option>)}
                </Select>
              </FormRow>
            }
          </Fragment>
        }
        <ButtonSet
          primaryButton={{
            text: 'Save',
            type: 'submit',
            isLoading: isSaving
          }}
        />
      </Form>
      {isDevPlan &&
        <UpgradePrompt
          feature='stackSettings'
        />
      }
    </div>
  );
};

StackSettings.propTypes = {
  stackSettings: PropTypes.object.isRequired,
  deploymentStrategy: PropTypes.string,
  verificationPipeline: PropTypes.object,
  verificationEnvironmentId: PropTypes.number,
  verificationEnvironments: PropTypes.array,
  isDevPlan: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired
};

export default StackSettings;
