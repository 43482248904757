import * as path from 'path';
import { put, call, take, race } from 'redux-saga/effects';
import dockerfileContents from '../constants/dockerfileContents';
import runtimeContents from '../constants/runtimeContents';
import runtimeHooks, { deployHooksReadme } from '../constants/runtimeHooks';
import { types as appTypes } from '../actions/app';
import { read, save, rename, remove } from './workspace';

function * confirmFileTask (type, props) {
  yield put({ type, ...props });

  const { confirm } = yield race({
    confirm: take(appTypes.CONFIRM_MODAL),
    reject: take(appTypes.HIDE_MODAL)
  });

  return confirm !== undefined;
}

function * isExistingPath (file) {
  const contents = yield call(read, { file });

  return !(contents instanceof Error);
}

export function * renameSafely (resources, resourceId, settingId, value, prevValue, options = { }) {
  const resourceType = resources[resourceId].Type;
  let isRenameOk = !(yield call(isExistingPath, value));
  let isCreateNeeded = false;

  for (const key in resources) {
    if (key === resourceId || !(settingId in resources[key].Settings)) { continue; }

    // Does sourcePath (prevValue or value) conflict with the sourcePath of another resource
    if (resources[key].Settings[settingId] === prevValue) {
      isCreateNeeded = true;
    } else if (resources[key].Settings[settingId] === value) {
      isRenameOk = yield call(confirmFileTask, appTypes.SHOW_CONFIRM_PATH_IN_USE_MODAL, { ...options, path: value, resourceId, settingId, existingResourceId: key });
    }
  }

  if (isCreateNeeded) {
    switch (resourceType) {
      case 'function':
      case 'edgeFunction':
        if (settingId === 'SourcePath') {
          yield call(scaffoldFunction, value, resources[resourceId].Settings.Runtime, resourceId);
        } else if (settingId === 'ImageDockerfile') {
          const dockerfile = resources[resourceId].Settings.ImageDockerfile;
          const dockerContext = resources[resourceId].Settings.ImageDockerContext;
          yield call(save, { file: path.join(dockerContext, dockerfile), contents: dockerfileContents });
        }
        break;

      case 'layer':
        yield call(scaffoldLayer, value, resourceId);
        break;

      default:
        // GraphQL resources don't need to be scaffolded
        break;
    }
  } else if (isRenameOk) {
    yield call(rename, { from: prevValue, to: value });
  } else if (!isRenameOk) {
    isRenameOk = yield call(confirmFileTask, appTypes.SHOW_CONFIRM_PATH_IN_USE_MODAL, { ...options, path: value, resourceId, settingId });
    if (isRenameOk) {
      yield call(rename, { from: prevValue, to: value });
    }
  }
}

export function * removeSafely (settingId, file, options = {}) {
  let isRemoveOk = false;

  if (yield call(isExistingPath, file)) {
    isRemoveOk = yield call(confirmFileTask, appTypes.SHOW_CONFIRM_PATH_REMOVE_MODAL, { ...options, path: file, settingId });
  }

  if (isRemoveOk) {
    yield call(remove, { file });
  }
}

export function * saveSafely (settingId, file, contents) {
  let isSaveNeeded = true;

  if (yield call(isExistingPath, file)) {
    isSaveNeeded = yield call(confirmFileTask, appTypes.SHOW_CONFIRM_PATH_EXISTS_MODAL, { path: file, settingId });
  }

  if (isSaveNeeded) {
    yield call(save, { file, contents });
  }
}

function buildInfo (packageFileInfo) {
  if (packageFileInfo) {
    return `# Dependencies
You can add dependencies on other files and packages.
Within this directory, local dependencies can be added as individual files and
package dependencies can be added to ${packageFileInfo}.
Package dependencies will be installed when the stack is deployed.`;
  } else {
    return `# Build Information
Files in this directory will be zipped to generate the code package for this function.`;
  }
}

function generateReadme (runtime) {
  let packageFileInfo;

  if (runtime.startsWith('nodejs') || runtime === 'typescript') {
    packageFileInfo = '[package.json](https://docs.npmjs.com/files/package.json)';
  } else if (runtime.startsWith('python')) {
    packageFileInfo = '[requirements.txt](https://pip.readthedocs.io/en/stable/user_guide/#requirements-files)';
  } else if (runtime.startsWith('dotnet')) {
    packageFileInfo = '[Function.csproj](https://docs.microsoft.com/en-us/dotnet/articles/core/tools/csproj#packagereference)';
  } else if (runtime.startsWith('java')) {
    packageFileInfo = '[build.gradle](https://docs.gradle.org/current/userguide/artifact_dependencies_tutorial.html)';
  } else if (runtime.startsWith('ruby')) {
    packageFileInfo = '[Gemfile](https://bundler.io/gemfile.html)';
  } else if (runtime.startsWith('go')) {
    packageFileInfo = '[go.mod](https://blog.golang.org/using-go-modules)';
  }

  return `
This Function was generated by [Stackery.io](https://www.stackery.io).

We recommend updating this readme with your own function specific documentation.

${buildInfo(packageFileInfo)}

# Stackery Documentation
Documentation for Function resources can be found at [https://docs.stackery.io/docs/api/nodes/Function](https://docs.stackery.io/docs/api/nodes/Function/).
`;
}

function generateLayerReadme () {
  return `
This directory was created by [Stackery.io](https://www.stackery.io).

We recommend updating this readme with your own layer-specific documentation.

# Stackery Documentation
Documentation for Layer resources can be found at [https://docs.stackery.io/docs/api/nodes/Layer](https://docs.stackery.io/docs/api/nodes/Layer/).

`;
}

// In `.stackery-config.yaml` on Function directory level; `function-id` set to Logical ID
function generateStackeryConfig (logicalId) {
  return `function-id: ${logicalId}
template-path: ../../template.yaml`;
}

export function * updateStackeryConfig (sourcePath, logicalId, resourceId) {
  const file = `${sourcePath}/.stackery-config.yaml`;
  const contents = yield call(read, { file });

  if (!(contents instanceof Error)) {
    const newContents = contents.replace(/function-id: ([A-Za-z][A-Za-z0-9])\w+/, `function-id: ${logicalId}`);
    yield call(save, { file, contents: newContents });
  }
}

export function * updateRuntime (sourcePath, value, resourceId) {
  let isReplaceOk = true;

  if (yield call(isExistingPath, sourcePath)) {
    isReplaceOk = yield call(confirmFileTask, appTypes.SHOW_CONFIRM_PATH_RUNTIME_MODAL, { path: sourcePath, resourceId, runtime: value });
  }

  if (isReplaceOk) {
    yield call(remove, { file: sourcePath });
    yield call(scaffoldFunction, sourcePath, value, resourceId);
  }
}

export function * updatePackageType (dockerfile, packageType, resourceId) {
  const isSaveNeeded = packageType === 'Image';
  let isReplaceOk = true;

  if (yield call(isExistingPath, dockerfile)) {
    if (isSaveNeeded) {
      isReplaceOk = yield call(confirmFileTask, appTypes.SHOW_CONFIRM_PATH_EXISTS_MODAL, { path: dockerfile, resourceId, settingId: '', isFile: true });
    } else {
      yield call(removeSafely, '', dockerfile, { isFile: true });
    }
  }

  if (isSaveNeeded && isReplaceOk) {
    yield call(save, { file: dockerfile, contents: dockerfileContents });
  }
}

function * scaffoldDeployHooks (sourcePath, runtime) {
  const buildFunction = runtimeHooks[runtime] && runtimeHooks[runtime](sourcePath);
  if (!buildFunction) {
    return;
  }

  if (!(yield call(isExistingPath, 'deployHooks'))) {
    yield call(save, { file: path.join('deployHooks', 'README.md'), contents: deployHooksReadme });
  }

  let existingScript;

  switch (runtime) {
    case 'nodejs10.x (typescript)':
    case 'nodejs12.x (typescript)':
    case 'nodejs14.x (typescript)':
      existingScript = yield call(read, { file: path.join('deployHooks', 'stackery.prebuild.sh') });
      if (existingScript instanceof Error) {
        yield call(save, { file: path.join('deployHooks', 'stackery.prebuild.sh'), contents: buildFunction });
      }
      break;
    default:
      // Currently only typescript has deploy hooks
      break;
  }
}

export function * scaffoldFunction (sourcePath, runtime, logicalId) {
  const contents = typeof runtimeContents[runtime] === 'function' ? runtimeContents[runtime](logicalId) : [];
  let isScaffoldNeeded = true;

  if (yield call(isExistingPath, sourcePath)) {
    isScaffoldNeeded = yield call(confirmFileTask, appTypes.SHOW_CONFIRM_PATH_EXISTS_MODAL, { path: sourcePath, resourceId: logicalId, settingId: 'SourcePath' });
  }

  if (isScaffoldNeeded) {
    for (const filename in contents) {
      yield call(save, { file: path.join(sourcePath, filename), contents: contents[filename] });
    }

    yield call(save, { file: path.join(sourcePath, 'README.md'), contents: generateReadme(runtime) });
    yield call(save, { file: path.join(sourcePath, '.stackery-config.yaml'), contents: generateStackeryConfig(logicalId) });
    yield call(scaffoldDeployHooks, sourcePath, runtime);
  }
}

export function * scaffoldLayer (sourcePath, logicalId) {
  let isScaffoldNeeded = true;

  if (yield call(isExistingPath, sourcePath)) {
    isScaffoldNeeded = yield call(confirmFileTask, appTypes.SHOW_CONFIRM_PATH_EXISTS_MODAL, { path: sourcePath, resourceId: logicalId, settingId: 'SourcePath' });
  }
  if (isScaffoldNeeded) {
    yield call(save, { file: path.join(sourcePath, 'README.md'), contents: generateLayerReadme() });
  }
}
