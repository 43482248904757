import React from 'react';

const escapeHrefName = name => {
  return encodeURIComponent(name)
    .replace(/!/g, '%21') // '!' is fine in URIs, but not for CloudWatch...
    .replace(/\)/g, '%29') // Same for ')'
    .replace(/%/g, '*'); // CloudWatch escapes names using '*' instead of '%'
};

const CloudwatchChartLink = (props) => {
  var components = [
    'https://',
    props.region,
    '.console.aws.amazon.com/cloudwatch/home?region=',
    props.region,
    "#metricsV2:graph=~(view~'timeSeries~stacked~",
    props.stacked || false,
    '~metrics~('
  ];

  for (var i in props.metrics) {
    var metric = props.metrics[i];

    if ('namespace' in metric) {
      components.push("~(~'");
      components.push(escapeHrefName(metric.namespace));
      components.push("~'");
      components.push(escapeHrefName(metric.name));

      for (var dimension in metric.dimensions) {
        components.push("~'");
        components.push(escapeHrefName(dimension));
        components.push("~'");
        components.push(escapeHrefName(metric.dimensions[dimension]));
      }

      // Period is specified in number of minutes
      if (metric.period) {
        components.push('~(period~' + metric.period * 60);
      } else {
        components.push('~(period~60');
      }

      if (metric.statistic && metric.statistic !== 'Average') {
        components.push("~stat~'" + metric.statistic);
      }

      components.push("~label~'");
      components.push(escapeHrefName(metric.label));
      components.push('))');
    } else if ('expression' in metric) {
      components.push("~(expression~'");
      components.push(escapeHrefName(metric.expression));

      components.push("~label~'");
      components.push(escapeHrefName(metric.label));
      components.push(')');
    }
  }

  components.push(")~region~'");
  components.push(props.region);
  components.push("~title~'");
  components.push(escapeHrefName(props.title));
  components.push('~yAxis~(left~(min~0');

  if (props.yMax) {
    components.push('~max~' + props.yMax);
  }

  components.push(')));namespace=');
  components.push('~yAxis~(left~(min~0)));namespace=');
  components.push(props.namespace);
  components.push(';dimensions=');
  components.push(props.dimensions.join(','));

  return (
    <a href={components.join('')} target='_blank' data-logs>{props.label}</a>
  );
};

export default CloudwatchChartLink;
