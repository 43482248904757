import { types as apiTypes } from '../actions/api';
import { types as appTypes } from '../actions/app';
import * as states from '../constants/stackCreatorStates';

export const initialState = {
  state: states.NEW,
  path: undefined,
  error: undefined,
  gitProvider: undefined
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case apiTypes.CREATE_STACK.REQUEST:
      return {
        ...state,
        state: states.LOADING,
        path: undefined,
        error: undefined
      };

    case apiTypes.CREATE_STACK.SUCCESS:
      // If webhook_create_failed, the stack is created but the payload structure changes
      const stack = action.data.stackInfo || action.data;
      return {
        ...state,
        state: states.CREATED,
        path: `${stack.owner}/${stack.name}`,
        error: undefined
      };

    case apiTypes.CREATE_STACK.FAILURE:
      return {
        ...state,
        state: states.FAILED,
        error: action.error.message.message
      };

    case appTypes.SET_STACK_GIT_PROVIDER:
      return {
        ...state,
        gitProvider: action.gitProvider
      };

    case appTypes.HIDE_MODAL:
      return {
        ...initialState
      };

    default:
      return state;
  }
};
