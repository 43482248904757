import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import formatTimestamp from '../../utils/formatTimestamp';
import * as usageEventLabels from '../../constants/usageEventLabels';
import Table from '../core/Table';
import Link from '../core/Link';
import Spinner from '../core/Spinner';
import EnvironmentCreatedEvent from './EnvironmentCreatedEvent';
import EnvironmentDeletedEvent from './EnvironmentDeletedEvent';
import ParamsModifiedEvent from './ParamsModifiedEvent';
import SecretCreatedEvent from './SecretCreatedEvent';
import SecretModifiedEvent from './SecretModifiedEvent';
import SecretDeletedEvent from './SecretDeletedEvent';
import StackCreatedEvent from './StackCreatedEvent';
import StackDeactivatedEvent from './StackDeactivatedEvent';
import StackDeletedEvent from './StackDeletedEvent';
import StackDeployedEvent from './StackDeployedEvent';
import StackUndeployedEvent from './StackUndeployedEvent';
import PlanUpdatedEvent from './PlanUpdatedEvent';
import PipelineEnabledEvent from './PipelineEnabledEvent';
import PipelineDisabledEvent from './PipelineDisabledEvent';
import PipelineStepEnabledEvent from './PipelineStepEnabledEvent';
import PipelineStepDisabledEvent from './PipelineStepDisabledEvent';
import PipelineEnvironmentUpdated from './PipelineEnvironmentUpdated';
import DeploymentPipelineCreated from './DeploymentPipelineCreated';
import DeploymentPipelineUpdated from './DeploymentPipelineUpdated';
import DeploymentPipelineDeleted from './DeploymentPipelineDeleted';
import UserAddedEvent from './UserAddedEvent';
import UserRemovedEvent from './UserRemovedEvent';

import style from './Activity.css';

const mapEventToComponent = {
  'deployed': StackDeployedEvent,
  'undeployed': StackUndeployedEvent,
  'deleted': StackDeactivatedEvent,
  'stackCreated': StackCreatedEvent,
  'stackDeleted': StackDeletedEvent,
  'environmentCreated': EnvironmentCreatedEvent,
  'environmentDeleted': EnvironmentDeletedEvent,
  'paramsModified': ParamsModifiedEvent,
  'secretCreated': SecretCreatedEvent,
  'secretModified': SecretModifiedEvent,
  'secretDeleted': SecretDeletedEvent,
  'planUpdated': PlanUpdatedEvent,
  'pipelineEnabled': PipelineEnabledEvent,
  'pipelineDisabled': PipelineDisabledEvent,
  'pipelineStepEnabled': PipelineStepEnabledEvent,
  'pipelineStepDisabled': PipelineStepDisabledEvent,
  'pipelineEnvironmentUpdated': PipelineEnvironmentUpdated,
  'deploymentPipelineCreated': DeploymentPipelineCreated,
  'deploymentPipelineUpdated': DeploymentPipelineUpdated,
  'deploymentPipelineDeleted': DeploymentPipelineDeleted,
  'userAdded': UserAddedEvent,
  'userRemoved': UserRemovedEvent
};

const Activity = props => {
  const {
    activity,
    context,
    filterBy,
    isLoading
  } = props;

  const isEmpty = activity.data.length === 0;
  return (
    <section className={style.container}>
      <h2>Recent Activity</h2>
      <Table isStriped isEmpty={isEmpty}>
        <tbody>
          {isLoading &&
            <tr>
              <td colSpan='4'><Spinner position='static' /></td>
            </tr>
          }

          {!isLoading && !isEmpty &&
            activity.data.map((event) => {
              const Component = mapEventToComponent[event.event];
              return (
                <tr key={`${event.stackName}-${event.environmentName}-${event.timestamp}`}>
                  <td>
                    <strong className={style.header}>
                      <span className={style.event}>{usageEventLabels[event.event]}</span>
                      <span className={style.timestamp}>{formatTimestamp(event.timestamp)}</span>
                    </strong>
                    <Component {...event} context={context} />
                  </td>
                </tr>
              );
            })
          }

          {!isLoading && isEmpty &&
            <tr>
              <td>No recent activity. {' '}
                {!filterBy &&
                  <Fragment>
                    <Link to='/stacks'>Add a Stack</Link> to get started.
                  </Fragment>
                }
              </td>
            </tr>
          }
        </tbody>
      </Table>
    </section>
  );
};

Activity.propTypes = {
  activity: PropTypes.object.isRequired,
  context: PropTypes.string,
  isLoading: PropTypes.bool.isRequired
};

export default Activity;
