import { types as appTypes } from '../actions/app';
import { types as apiTypes } from '../actions/api';
import { types as canvasTypes } from '../actions/canvas';
import { types as resourceEditorTypes } from '../actions/resourceEditor';
import { types as workspaceTypes } from '../actions/workspace';
import * as states from '../constants/states';

export const initialState = {
  state: states.NEW,
  error: undefined,

  /* Node definitions. Definitions may have additional temporary properties from
   * settings editor that will be filtered out when saved. */
  nodes: [],

  // Node IDs that have been deleted
  nodesDeleted: [],

  isLegacy: true,
  isValidated: false,
  needsLayout: false
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case appTypes.SELECT_STACK_BRANCH:
    case appTypes.DESELECT_STACK:
      return {
        ...initialState
      };

    case apiTypes.GET_STACK_CONTENTS.REQUEST:
      return {
        ...state,
        state: states.LOADING,
        isValidated: false,
        nodes: [],
        nodesDeleted: [],
        error: undefined
      };

    case apiTypes.GET_STACK_CONTENTS.FAILURE:
      return {
        ...state,
        state: states.FAILED,
        error: action.error.message
      };

    case apiTypes.SAVE_NODES.SUCCESS:
    case workspaceTypes.SAVE.SUCCESS:
      return {
        ...state,
        state: states.OKAY,
        nodes: state.nodes.map(node => {
          delete node.dirty;
          delete node.unsaved;
          return node;
        }),
        nodesDeleted: [],
        error: undefined
      };

    case apiTypes.GET_STACK_CONTENTS.SUCCESS:
      if (action.data.format === 'stackery') {
        return {
          ...state,
          state: states.OKAY,
          nodes: action.data.stack.nodes.map(node => {
            node._cache = {};
            return node;
          }),
          isLegacy: true
        };
      }

      return {
        ...state,
        isLegacy: false,
        needsLayout: action.data.format === 'SAM'
      };

    case canvasTypes.LAYOUT_NODES.SUCCESS:
      if (action.deploymentId) { return state; }

      return {
        ...state,
        state: states.OKAY,
        needsLayout: false,
        nodes: action.nodes.map(node => {
          const nodeInState = state.nodes.find(stateNode => stateNode.id === node.id);

          if (!nodeInState) return node;

          return {
            ...node,
            dirty: nodeInState.dirty,
            errors: nodeInState.errors
          };
        })
      };

    case canvasTypes.MOVE_NODES:
      return {
        ...state,
        nodes: state.nodes.map(node => {
          if (
            !action.nodes.includes(node.id) &&
            !action.nodes.includes(node.sourceId)
          ) return node;

          return {
            ...node,
            x: node.x + action.dx,
            y: node.y + action.dy,
            dirty: state.isLegacy || node.dirty
          };
        })
      };

    case canvasTypes.RESIZE_NODE:
      return {
        ...state,
        isValidated: false,
        nodes: state.nodes.map(node => {
          if (node.id !== action.node) return node;

          return {
            ...node,
            x: action.x,
            y: action.y,
            width: action.width,
            height: action.height,
            dirty: true
          };
        })
      };

    case canvasTypes.WIRE_NODES:
      return {
        ...state,
        isValidated: false,
        nodes: state.nodes.map(node => {
          if (node.id !== action.source) return node;

          const wires = node.wires ? [...node.wires] : [];

          wires[action.port] = wires[action.port]
            ? [...wires[action.port], action.target]
            : [action.target];

          return {
            ...node,
            wires,
            dirty: true
          };
        })
      };

    case canvasTypes.DRAG_NEW_NODES: {
      const newNodes = action.nodes.map(node => ({
        ...node,
        dirty: true,
        _cache: {}
      }));

      return {
        ...state,
        isValidated: false,
        needsLayout: true,
        nodes: [...state.nodes, ...newNodes]
      };
    }

    case canvasTypes.INVALIDATE_NODES:
      return {
        ...state,
        isValidated: false
      };

    case appTypes.PARSE_STACK_CONTENTS.TRY:
    case appTypes.PARSE_STACK_TEMPLATE.TRY:
      return {
        ...state,
        state: states.LOADING,
        needsLayout: false
      };

    case appTypes.PARSE_STACK_CONTENTS.SUCCESS:
    case appTypes.PARSE_STACK_TEMPLATE.SUCCESS:
    case appTypes.RESET_STACK_TEMPLATE.SUCCESS:
    case workspaceTypes.LOAD_EXTERNAL_FILE.SUCCESS:
      return {
        ...state,
        isValidated: false,
        needsLayout: true
      };

    case appTypes.PARSE_STACK_CONTENTS.FAILURE:
    case appTypes.PARSE_STACK_TEMPLATE.FAILURE:
      return {
        ...state,
        state: states.OKAY // Allow view to render
      };

    case resourceEditorTypes.SAVE_RESOURCE:
      const setting = action.settings.find(setting => setting.settingId === 'Name');

      return {
        ...state,
        isValidated: false,
        needsLayout: true,
        nodes: state.nodes.map(node => {
          return node.id === action.resourceId ? {
            ...node,
            name: setting ? setting.value : node.name,
            dirty: true,
            _cache: {}
          } : node;
        })
      };

    case canvasTypes.UPDATE_NODE:
      return {
        ...state,
        isValidated: false,
        nodes: state.nodes.map(
          node =>
            node.id === action.node.id ? { ...action.node, dirty: true, _cache: {} } : node
        )
      };

    case resourceEditorTypes.UPDATE_RESOURCE_ID:
      return {
        ...state,
        nodes: state.nodes.map(node => {
          if (node.id === action.oldResourceId) {
            node = { ...node, id: action.newResourceId, dirty: true };
          }

          if (node.wires) {
            node.wires = node.wires.map(wire => {
              return wire.map(port => {
                if (port === action.oldResourceId) {
                  port = action.newResourceId;
                }
                return port;
              });
            });
          }
          return node;
        })
      };

    case canvasTypes.DELETE_SELECTION:
      const nodesDeleted = state.nodes.filter(node => action.nodes.includes(node.id) && !node.unsaved);

      return {
        ...state,
        isValidated: false,
        nodesDeleted: state.nodesDeleted.concat(nodesDeleted),
        nodes: state.nodes
          // Filter out selected nodes
          .filter(node => !action.nodes.includes(node.id))
          // Filter out facets of selected nodes
          .filter(node => !action.nodes.includes(node.sourceId))
          // Filter out selected wire or wires connected to deleted nodes
          .map(node => {
            let wires;
            if (node.wires) {
              wires = node.wires.map((port, portNum) => {
                if (!port) return undefined;

                const updatedPort = port.filter(
                  target =>
                    (!action.wire ||
                      node.id !== action.wire.source ||
                      portNum !== action.wire.port ||
                      target !== action.wire.target) &&
                    !action.nodes.includes(target)
                );

                if (updatedPort.length !== port.length) node.dirty = true;

                return updatedPort;
              });
            }

            return {
              ...node,
              wires
            };
          })
      };

    case canvasTypes.UPDATE_VALIDATION:
      return {
        ...state,
        isValidated: true,
        nodes: state.nodes.map(node => {
          let resource = action.errors && Array.isArray(action.errors) ? action.errors.find(resource => node.id === resource.resourceId) : undefined;
          if (action.resources && resource) { // Resources
            return {
              ...node,
              errors: resource.errors || []
            };
          } else if (!action.resources && (node.id in action.errors)) {
            return {
              ...node,
              errors: action.errors[node.id]
            };
          } else if (node.errors) {
            const newNode = { ...node };
            delete newNode.errors;
            return newNode;
          } else {
            return node;
          }
        })
      };

    default:
      return state;
  }
};
