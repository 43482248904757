import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import * as deploymentStates from '../../constants/deploymentStates';
import makeCodeBuildLogsLink from '../../utils/makeCodeBuildLogsLink';
import Link from '../core/Link';
import JobItemHeader from './JobItemHeader';
import JobItemActions from './JobItemActions';
import style from './JobItem.css';

const JobItemPreparing = React.forwardRef((props, ref) => {
  const {
    pipelineId,
    stack,
    preparing,
    stages,
    stageIndex,
    gitProviders
  } = props;

  const isFailed = preparing.status === deploymentStates.FAILED;

  let status;

  if (isFailed) {
    status = 'Failed';
  } else {
    status = 'Started';
  }

  return (
    <div className={classnames(style.container, props.rowIndex === 1 && style.isSiblingRow)} ref={ref}>
      <div className={style.content}>
        <JobItemHeader
          link={stack.stackLink}
          status={status}
          version={preparing.version}
          timestamp={preparing.timestamp}
          gitProviders={gitProviders}
        />
        {isFailed && preparing.codebuildId &&
          <p>Changeset failed to deploy. <Link href={makeCodeBuildLogsLink(preparing.codebuildId, stages[stageIndex].environment.region, 'StackeryPipelineRunner')} text='View logs' target='_blank' /></p>
        }
        <JobItemActions
          {...props}
          isPreparing={!isFailed}
          isFailed={isFailed}
          isRetrying={preparing.isRetrying}
          isRetried={preparing.isRetried}
          handlePromote={() => props.promoteDeploymentPipeline(props.owner, pipelineId, stack.stackId, preparing.deploymentHistoryId, preparing.version, stageIndex)}
          handleRetry={() => props.retryDeploymentPipeline(props.owner, pipelineId, stack.stackId, preparing.deploymentHistoryId, preparing.version, stageIndex)}
        />
      </div>
    </div>
  );
});

JobItemPreparing.propTypes = {
  stack: PropTypes.object.isRequired,
  preparing: PropTypes.object.isRequired
};

export default JobItemPreparing;
