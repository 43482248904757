import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { push } from 'connected-react-router';
import * as states from '../../constants/states';
import * as stackCreatorStates from '../../constants/stackCreatorStates';
import * as planTypes from '../../constants/planTypes';
import apiActions from '../../actions/api';
import appActions from '../../actions/app';
import { onTransition } from '../../utils/transition';
import Stacks from './Stacks';

const mapStateToProps = () => {
  return createSelector(
    (appState) => appState.stacks,
    (appState) => appState.stackCreator,
    (appState) => appState.gitProviders,
    (appState) => appState.currentUser,
    (appState) => appState.account,
    (stacks, stackCreator, gitProviders, currentUser, account) => {
      return {
        stacks,
        stackCreator,
        gitProviders,
        isCreatingStack: stackCreator.state === states.LOADING,
        isDevPlan: account.plan.type === planTypes.DEVELOPER,
        isDemoMode: currentUser.isDemoMode
      };
    }
  );
};

const mapDispatchToProps = {
  push,
  notifyUser: appActions.notifyUser,
  getStacks: apiActions.getStacks,
  createStack: apiActions.createStack,
  deleteStack: apiActions.deleteStack,
  renameStack: apiActions.renameStack,
  setStackRepo: apiActions.setStackRepo,
  showCreateStackNewRepoModal: appActions.showCreateStackNewRepoModal,
  showCreateStackExistingRepoModal: appActions.showCreateStackExistingRepoModal,
  showDeleteStackModal: appActions.showDeleteStackModal,
  showRenameStackModal: appActions.showRenameStackModal,
  showEditRepoModal: appActions.showEditRepoModal,
  showDemoModal: appActions.showDemoModal
};

class StacksContainer extends Component {
  constructor (props) {
    super(props);

    this.state = {
      searchTerm: null,
      filteredStacks: props.stacks.data
    };

    this.filterStacks = this.filterStacks.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleFilterType = this.handleFilterType.bind(this);
    this.handleFilterStack = this.handleSearch.bind(this);
    this.handleCreateClick = this.handleCreateClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleRenameClick = this.handleRenameClick.bind(this);
    this.handleRepoClick = this.handleRepoClick.bind(this);
  }

  componentDidMount () {
    this.props.getStacks();
  }

  componentWillUnmount () {
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.searchTerm !== prevState.searchTerm) {
      this.filterStacks();
    }

    if (
      this.props.stacks.data !== prevProps.stacks.data &&
      this.props.stacks.state === states.OKAY
    ) {
      this.setState({ filteredStacks: this.props.stacks.data }, this.filterStacks);
    }

    onTransition(prevProps, this.props, 'stacks', {
      [states.FAILED]: {
        notify: {
          message: `Sorry, there was an issue connecting to Stackery. Please try again shortly.`,
          level: 'error'
        }
      }
    });

    onTransition(prevProps, this.props, 'stackCreator', {
      [stackCreatorStates.CREATED]: {
        push: `/stacks/${this.props.stackCreator.path}/edit`
      },
      [stackCreatorStates.FAILED]: {
        notify: {
          message: `Failed to create stack: ${this.props.stackCreator.error}`,
          level: 'error'
        }
      }
    });
  }

  filterStacks () {
    const searchTerm = this.state.searchTerm ? this.state.searchTerm.toLowerCase() : '';

    let stacks = this.props.stacks.data;
    let filteredStacks = 'filteredStacks';

    this.setState({
      [filteredStacks]: stacks.filter((stack) => {
        const matchesTag = Object.keys(stack.tags || []).filter((key) => {
          key = key.toLowerCase();
          const matchesKey = key.includes(searchTerm);
          const matchesValue = stack.tags[key] && stack.tags[key].includes(searchTerm);

          return matchesKey || matchesValue;
        });
        const matchesName = stack.name.toLowerCase().includes(searchTerm);
        const matchesRegion = stack.region ? stack.region.toLowerCase().includes(searchTerm) : false;

        return matchesName || matchesTag.length > 0 || matchesRegion;
      })
    });
  }

  handleSearch (event) {
    this.setState({ searchTerm: event.currentTarget.value });
  }

  handleCreateClick () {
    this.props.createStack();
  }

  handleDeleteClick (stack) {
    this.props.showDeleteStackModal({ stack, onSubmit: this.props.deleteStack });
  }

  handleRenameClick (stack) {
    this.props.showRenameStackModal({ stack, onSubmit: this.props.renameStack });
  }

  handleRepoClick (stack) {
    this.props.showEditRepoModal({ stack, onSubmit: this.props.setStackRepo });
  }

  handleFilterType (event) {
    this.setState({ stackType: event.currentTarget.value });
  }

  render () {
    return (
      <Stacks
        {...this.props}
        filteredStacks={this.state.filteredStacks}
        gitProviders={this.props.gitProviders}
        searchTerm={this.state.searchTerm}
        isCreatingStack={this.props.isCreatingStack}
        onFilterType={this.handleFilterType}
        onSearch={this.handleSearch}
        onCreateClick={this.handleCreateClick}
        onDeleteClick={this.handleDeleteClick}
        onRenameClick={this.handleRenameClick}
        onRepoClick={this.handleRepoClick}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StacksContainer);
