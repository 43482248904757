import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Icon from './Icon';
import Link from './Link';
import style from './Button.css';

const Button = props => {
  let Elem;
  const attrs = props.isDisabled ? { 'aria-disabled': true } : { to: props.to, href: props.href, target: props.target };

  if (props.href || props.to) {
    Elem = Link;
  } else {
    Elem = 'button';
  }

  return (
    <Elem
      className={classNames(
        props.className,
        style[props.modifier],
        props.layoutClassName,
        props.icon && style.hasIcon,
        props.isLoading && props.loadingClassName,
        props.isDisabled && props.disabledClassName
      )}
      onClick={props.onClick}
      disabled={props.isDisabled}
      data-hid={props.hid}
      data-tip={props['data-tip']}
      data-for={props['data-for']}
      type={props.type}
      ref={props.buttonRef}
      id={props.id}
      tabIndex='0'
      {...attrs}
    >
      <div className={style.innerContainer}>
        {!props.children && props.text && props.icon &&
          <div className={style.childrenContainer}>
            <Icon className={style.icon} name={props.icon} />
            <span className={style.text}>{props.text}</span>
          </div>
        }

        {!props.children && props.text && !props.icon &&
          <div className={style.childrenContainer}>
            <span className={style.text}>{props.text}</span>
          </div>
        }

        {props.children &&
          <div className={style.childrenContainer}>
            {props.children}
          </div>
        }
        <Icon name='spinner' className={style.spinner} width='24' height='18' />
      </div>
    </Elem>
  );
};

Button.defaultProps = {
  className: style.container,
  layoutClassName: null,
  modifier: 'primary',
  type: 'button',
  icon: null,
  loadingClassName: style.isLoading,
  disabledClassName: style.isDisabled,
  isLoading: false,
  isDisabled: false
};

Button.propTypes = {
  className: PropTypes.string,
  layoutClassName: PropTypes.string,
  loadingClassName: PropTypes.string,
  disabledClassName: PropTypes.string,
  modifier: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.string,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool
};

export default Button;
