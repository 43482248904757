import { putResolve, all, takeLatest, race, call, take, delay } from 'redux-saga/effects';
import { types as setupTypes } from '../actions/setup';
import { types as apiTypes } from '../actions/api';

function * fetchCurrentUser (args) {
  try {
    while (true) {
      yield delay(5000);
      yield putResolve({ type: apiTypes.CURRENT_USER.REQUEST });
    }
  } finally { }
}

function * startSetup () {
  yield race({
    task: call(fetchCurrentUser),
    cancel: take(setupTypes.STOP_SETUP)
  });
}

export default function * setup () {
  yield all([
    takeLatest(setupTypes.START_SETUP, startSetup)
  ]);
}
