import React from 'react';

const UserAddedEvent = (props) => {
  if (props.targetUserEmail) {
    return <span>User {props.targetUserEmail} added to the organization by {props.userEmail}</span>;
  }
  return <span>User {props.userEmail} joined the organization</span>;
};

export default UserAddedEvent;
