import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Popper from '@material-ui/core/Popper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useCombinedRefs from '../../utils/useCombinedRefs';
import Spinner from '../core/Spinner';
import Checkbox from './Checkbox';
import style from './AutoCompleteCheckboxes.css';

const AutoCompleteCheckboxes = React.forwardRef((props, ref) => {
  const {
    id,
    containerId,
    label,
    name,
    options,
    onChange,
    placeholder,
    value,
    isLoading
  } = props;

  const ownRef = useCombinedRefs(ref, React.useRef(null));

  return (
    <Autocomplete
      classes={{ root: style.container, input: style.input, inputRoot: style.inputRoot, focused: style.focused, inputFocused: style.inputFocused, option: style.option, noOptions: style.noOptions, paper: style.paper, popper: style.popper, tag: style.tag, listbox: style.listbox, endAdornment: style.endAdornment }}
      ref={ownRef}
      ChipProps={{ variant: 'outlined', color: 'primary', colorprimary: style.colorPrimary }}
      PopperComponent={(props) => (
        <Popper
          {...props}
          container={document.getElementById(containerId)}
          anchorEl={ownRef.current}
          style={{ width: ownRef.current.getBoundingClientRect().width }}
          popperOptions={{
            arrowElement: null,
            scrollableContainer: document.getElementById(containerId)
          }}
        />
      )}
      multiple
      color={'primary'}
      name={name}
      id={id}
      options={options}
      autoHighlight
      selectOnFocus
      openOnFocus
      disableCloseOnSelect
      onChange={onChange}
      value={value}
      loading={isLoading}
      loadingText={<Spinner position='static' />}
      noOptionsText={props.noOptionsText}
      popupIcon={false}
      getOptionLabel={option => option.name}
      renderOption={(option, { selected }) => (
        <Fragment>
          <Checkbox
            id={`${option.id}`}
            checked={selected}
            value={option.name}
            disabled={props.disabled}
            aria-disabled={props.disabled}
          />
          {option.name}
        </Fragment>
      )}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          variant='outlined'
          InputLabelProps={{ shrink: false, colorPrimary: style.colorPrimary }}
        />
      )}
    />
  );
});

AutoCompleteCheckboxes.defaultProps = {
  required: false
};

AutoCompleteCheckboxes.propTypes = {
  id: PropTypes.any,
  containerId: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  inputRef: PropTypes.func,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool
};

export default AutoCompleteCheckboxes;
