import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import url from 'url';
import apiActions from '../../actions/api';
import authActions from '../../actions/auth';
import * as states from '../../constants/signUpStates';
import SignUp from './SignUp';

const mapStateToProps = () => {
  return createSelector(
    (appState) => appState.signUp,
    (signUp) => {
      return {
        isLoading: signUp.state === states.LOADING,
        error: signUp.errorMessage,
        signUp: signUp
      };
    }
  );
};

const mapDispatchToProps = {
  createAccount: apiActions.createAccount,
  signIn: authActions.signIn
};

class SignUpContainer extends Component {
  constructor (props) {
    super(props);

    this.state = {
      email: null,
      password: null,
      plan: null,
      promo: null,
      awsMarketplaceToken: null
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSignUp = this.handleSignUp.bind(this);
  }

  componentDidMount () {
    const parsed = url.parse(window.location.href, true);
    const awsMarketplaceToken = parsed.query['x-amzn-marketplace-token'];

    if (awsMarketplaceToken) {
      this.setState({ awsMarketplaceToken });
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (
      this.props.signUp.state !== prevProps.signUp.state &&
      this.props.signUp.state === states.SUCCESS
    ) {
      this.props.signIn(this.props.signUp.email, this.props.signUp.password);
    }
  }

  handleSignUp (evt) {
    evt.preventDefault();
    this.props.createAccount(
      this.state.email,
      this.state.password,
      this.state.plan ? this.state.plan : 'developer',
      this.state.promo ? this.state.promo : undefined,
      this.state.awsMarketplaceToken
    );
  }

  handleInputChange (event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  render () {
    return (
      <SignUp
        isLoading={this.props.isLoading}
        error={this.props.error}
        handleInputChange={this.handleInputChange}
        handleSubmit={this.handleSignUp}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUpContainer);
