import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from './Icon';
import style from './MessageBox.css';

const MessageBox = (props) => {
  return (
    <div className={classnames(
      style.container,
      props.alignCenter && style.alignCenter,
      props.icon && style.hasIcon
    )}>
      {props.icon && <Icon name={props.icon} className={style.icon} />}
      {props.children}
    </div>
  );
};

MessageBox.propTypes = {
  alignCenter: PropTypes.bool,
  icon: PropTypes.string
};

export default MessageBox;
