// Reference https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#feature-detecting_localstorage
let _isSupported;
export const isSupported = () => {
  if (typeof _isSupported !== 'boolean') {
    const test = '__storage_test__';
    try {
      window.localStorage.setItem(test, test);
      window.localStorage.removeItem(test);
      _isSupported = true;
    } catch (error) {
      _isSupported = false;
    }
  }

  return _isSupported;
};

export const getItem = (key) => {
  return isSupported() ? window.localStorage.getItem(key) : false;
};

export const setItem = (key, value) => {
  return isSupported() ? window.localStorage.setItem(key, value) : false;
};

export const removeItem = (key) => {
  return isSupported() ? window.localStorage.removeItem(key) : false;
};
