import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import LinkAws from './LinkAws';

const mapStateToProps = () => {
  return createSelector(
    (appState) => appState.currentUser,
    (currentUser) => {
      return {
        hasLinkedAws: currentUser.hasLinkedAws
      };
    }
  );
};

class LinkAwsContainer extends Component {
  render () {
    return (
      <LinkAws {...this.props} />
    );
  }
}

export default connect(mapStateToProps, null)(LinkAwsContainer);
