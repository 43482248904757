import React from 'react';
import PropTypes from 'prop-types';
import Button from '../core/Button';
import style from './AddNodeButton.css';

const AddNodeButton = (props) => {
  const {
    isDisabled,
    onClick,
    id
  } = props;

  return (
    <div className={style.container}>
      <Button icon='plus' text='Add Resource' isDisabled={isDisabled} onClick={onClick} id={id} />
    </div>
  );
};

AddNodeButton.propTypes = {
  isPalatteOpen: PropTypes.bool,
  onClick: PropTypes.func
};

export default AddNodeButton;
