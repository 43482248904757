import React, { Component } from 'react';
import * as states from '../../constants/states';
import hints from '../../constants/patternHintText';
import Form from '../core/Form';
import FormRow from '../core/FormRow';
import Input from '../core/Input';
import ButtonSet from '../core/ButtonSet';

class RenameStack extends Component {
  constructor (props) {
    super(props);

    this.state = {
      name: null
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange (event) {
    this.setState({ name: event.currentTarget.value });
  }

  handleSubmit (event) {
    event.preventDefault();

    const stack = this.props.stack;
    this.props.onSubmit(stack.id, stack.owner, stack.name, this.state.name);
  }

  render () {
    return (
      <Form onSubmit={this.handleSubmit}>
        <h1>Rename Stack</h1>
        <p>Rename <strong>{this.props.stack.name}</strong> to: </p>
        <FormRow label='Stack Name' hint={hints['[a-zA-Z0-9_-]*']}>
          <Input
            id='input-stackName'
            name='stackName'
            required
            pattern='[a-zA-Z0-9_-]*'
            onChange={this.handleInputChange}
          />
        </FormRow>
        <ButtonSet
          primaryButton={{
            text: 'Update',
            type: 'submit',
            isLoading: this.props.state === states.LOADING
          }}
          secondaryButton={{
            text: 'Cancel',
            onClick: this.props.onClickCancel
          }}
        />
      </Form>
    );
  }
}

RenameStack.propTypes = {
};

export default RenameStack;
