import makeActions from '../utils/makeActions';
import { push } from 'connected-react-router';
import { types as apiTypes } from './api';

export const types = {
  SIGN_IN: makeActions('COGNITO', 'SIGN_IN'),
  SIGN_OUT: makeActions('COGNITO', 'SIGN_OUT'),
  GET_TOKEN: makeActions('COGNITO', 'GET_TOKEN'),
  REFRESH_TOKEN: makeActions('COGNITO', 'REFRESH_TOKEN'),
  DELETE_TOKEN: makeActions('COGNITO', 'DELETE_TOKEN'),
  FORGOT_PASSWORD: makeActions('COGNITO', 'FORGOT_PASSWORD'),
  RESET_PASSWORD: makeActions('COGNITO', 'RESET_PASSWORD'),
  CHANGE_PASSWORD: makeActions('COGNITO', 'CHANGE_PASSWORD'),
  RESET_AUTH_INFO: 'AUTH::RESET_INFO',
  IMPERSONATE: 'COGNITO::IMPERSONATE',
  GET_COGNITO_USER: makeActions('COGNITO', 'GET_COGNITO_USER'),
  UPDATE_COGNITO_USER: makeActions('COGNITO', 'UPDATE_COGNITO_USER')
};

const actions = {
  push,
  resetAuthInfo: () => ({
    type: types.RESET_AUTH_INFO
  }),
  getAuthInfo: (email) => ({
    type: apiTypes.GET_AUTH_INFO.REQUEST,
    email
  }),
  signIn: (email, password) => ({
    type: types.SIGN_IN.REQUEST,
    data: { email, password }
  }),
  signOut: () => ({ type: types.SIGN_OUT.REQUEST }),
  getToken: (code) => ({
    type: types.GET_TOKEN.REQUEST,
    code: code
  }),
  refreshToken: () => ({ type: types.REFRESH_TOKEN.REQUEST }),
  deleteToken: () => ({ type: types.DELETE_TOKEN.REQUEST }),
  forgotPassword: email => ({
    type: types.FORGOT_PASSWORD.REQUEST,
    email
  }),
  resetPassword: (email, verificationCode, newPassword) => ({
    type: types.RESET_PASSWORD.REQUEST,
    email,
    verificationCode,
    newPassword
  }),
  changePassword: (oldPassword, newPassword) => ({
    type: types.CHANGE_PASSWORD.REQUEST,
    oldPassword,
    newPassword
  }),
  impersonate: email => ({
    type: types.IMPERSONATE,
    email
  }),
  getCognitoUser: () => ({
    type: types.GET_COGNITO_USER.REQUEST
  }),
  updateCognitoUser: (name, value) => ({
    type: types.UPDATE_COGNITO_USER.REQUEST,
    name,
    value
  })

};

export default actions;
