import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import debounce from '../../utils/debounce';
import ContextMenu from '../core/ContextMenu';
import Spinner from '../core/Spinner';
import Link from '../core/Link';
import EnvironmentDetails from '../environment/EnvironmentDetails';
import ScrollNav from './ScrollNav';
import StageCell from './StageCell';
import style from './DeploymentPipeline.css';

const scrollTo = (element, x, y) => {
  if (element.scrollTo) {
    element.scrollTo({
      top: y,
      left: x,
      behavior: 'smooth'
    });
  } else {
    element.scrollLeft = x;
    element.scrollTop = y;
  }
};

class DeploymentPipeline extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      isScrollable: false,
      columnsTotal: 0,
      columnsVisible: 0,
      columnWidth: 0,
      viewportWidth: 0,
      maxScrollLeft: 0
    };

    this.scrollArea = React.createRef();

    this.load = this.load.bind(this);
    this.moveNext = this.moveNext.bind(this);
    this.movePrev = this.movePrev.bind(this);
    this.setDimensions = this.setDimensions.bind(this);
    this.handleResize = debounce(this.handleResize.bind(this), 200);
    this.handleScroll = debounce(this.handleScroll.bind(this), 200);

    window.addEventListener('resize', this.handleResize);
  }

  componentDidMount (prevProps, prevState) {
    this.load();
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.isSaving && !this.props.isSaving) {
      this.load();
    }
  }

  load () {
    this.setDimensions();
    this.props.getDeploymentPipelineStatus(this.props.owner, this.props.deploymentPipeline.id);
  }

  handleResize () {
    setTimeout(this.setDimensions, 0);
  }

  handleScroll () {
    this.setState({
      currScrollLeft: this.scrollArea.current.scrollLeft
    });
  }

  setDimensions () {
    const scrollRef = this.scrollArea.current;

    if (!scrollRef) { return; }

    const {
      stages
    } = this.props.deploymentPipeline.settings;

    let columnsVisible;

    if (window.outerWidth > 2500) {
      columnsVisible = 5;
    } else if (window.outerWidth > 2000) {
      columnsVisible = 4;
    } else {
      columnsVisible = 3;
    }

    const viewportWidth = scrollRef.clientWidth;
    const columnsTotal = stages.length;
    const isScrollable = columnsTotal > columnsVisible;
    const columnWidth = isScrollable ? Math.ceil(viewportWidth / columnsVisible) : Math.floor(viewportWidth / columnsTotal);

    this.setState({
      isScrollable,
      columnsTotal,
      columnsVisible,
      columnWidth,
      viewportWidth,
      currScrollLeft: this.scrollArea.current.scrollLeft,
      maxScrollLeft: (columnsTotal - columnsVisible) * columnWidth
    });
  }

  movePrev () {
    const {
      columnWidth,
      maxScrollLeft
    } = this.state;

    const currScrollLeft = this.scrollArea.current.scrollLeft;
    const currColumn = Math.floor(currScrollLeft / columnWidth);
    const newScrollLeft = (currScrollLeft % columnWidth === 0 || currScrollLeft >= maxScrollLeft) ? (currColumn - 1) * columnWidth : currColumn * columnWidth;

    scrollTo(this.scrollArea.current, newScrollLeft);
  }

  moveNext () {
    const {
      columnWidth,
      maxScrollLeft
    } = this.state;

    const currScrollLeft = this.scrollArea.current.scrollLeft;
    const currColumn = Math.floor(currScrollLeft / columnWidth);
    const newScrollLeft = (currColumn + 1) * columnWidth;

    if (newScrollLeft <= maxScrollLeft) {
      scrollTo(this.scrollArea.current, newScrollLeft);
    }
  }

  render () {
    const {
      owner,
      deploymentPipeline,
      isLoading
    } = this.props;

    const {
      settings,
      status: stacks
    } = deploymentPipeline;

    const {
      columnsTotal,
      isScrollable,
      currScrollLeft,
      maxScrollLeft
    } = this.state;

    return (
      <section className={style.container}>
        <header className={style.header} id={`pipeline-${deploymentPipeline.id}`}>
          <h1 className={style.title}>
            <span>{deploymentPipeline.name || 'Deployment Pipeline'}</span>
            <ContextMenu
              align='right'
              items={[
                {
                  label: 'Edit',
                  onClick: () => this.props.showCreateDeploymentPipelineModal({ pipelineId: deploymentPipeline.id })
                },
                {
                  label: 'Delete',
                  onClick: () => this.props.showDeleteDeploymentPipelineModal({ pipelineId: deploymentPipeline.id })
                }
              ]}
            />
          </h1>
          {isScrollable &&
            <ScrollNav currScrollLeft={currScrollLeft} maxScrollLeft={maxScrollLeft} onMoveNext={this.moveNext} onMovePrev={this.movePrev} />
          }
        </header>

        <div ref={this.scrollArea} className={style.scrollArea} onScroll={this.handleScroll}>
          <table className={style.table}>
            <thead className={style.tableHeader}>
              <tr>
                {settings.stages.map(({ environment }, stageIndex) => (
                  <th key={`stage-${stageIndex}-${environment.id}`} className={style.stageHeader} style={{ width: `${this.state.columnWidth}px` }}>
                    <h2><Link to={`environments/${owner}/${environment.name}`} text={environment.name} /></h2>
                    <EnvironmentDetails {...environment} />
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className={style.tableBody}>
              {isLoading &&
                <tr>
                  <td colSpan={columnsTotal}><Spinner position='static' /></td>
                </tr>
              }

              {!isLoading && stacks.length === 0 &&
                <tr>
                  <td colSpan={columnsTotal}>No stacks were found in the pipeline. <Link onClick={() => this.props.showCreateDeploymentPipelineModal({ pipelineId: deploymentPipeline.id })}>Add a stack</Link> to get started.</td>
                </tr>
              }

              {!isLoading && stacks.sort((a, b) => a.stackName.localeCompare(b.stackName)).map(stack => (
                <Fragment key={`${stack.stackName}-stage-group`}>
                  <tr id={`pipeline-${deploymentPipeline.id}-${stack.stackName}`} className={style.stackRowHeader}>
                    <th colSpan={columnsTotal} className={classnames(style.stackHeader, isScrollable && style.isFixed)}>
                      <div style={{ maxWidth: `${this.state.viewportWidth}px` }}>
                        <Link to={`/stacks/${owner}/${stack.stackName}`}><h3>{stack.stackName}</h3></Link>
                      </div>
                    </th>
                  </tr>

                  <tr className={style.stackRowBody}>
                    {stack.stages.map((stage, stageIndex) => {
                      return (
                        <StageCell
                          {...this.props}
                          key={`${stack.stackName}-stage-${stageIndex}-deployed`}
                          rowIndex={0}
                          pipelineId={deploymentPipeline.id}
                          stack={stack}
                          stage={stage}
                          stageIndex={stageIndex}
                          stages={deploymentPipeline.settings.stages}
                          promoteDeploymentPipeline={this.props.promoteDeploymentPipeline}
                          retryDeploymentPipeline={this.props.retryDeploymentPipeline}
                        />
                      );
                    })}
                  </tr>

                  <tr className={style.stackRowBody}>
                    {stack.stages.map((stage, stageIndex) => {
                      return (
                        <StageCell
                          {...this.props}
                          key={`${stack.stackName}-stage-${stageIndex}-preparing-queued`}
                          rowIndex={1}
                          pipelineId={deploymentPipeline.id}
                          stack={stack}
                          stage={stage}
                          stageIndex={stageIndex}
                          stages={deploymentPipeline.settings.stages}
                          promoteDeploymentPipeline={this.props.promoteDeploymentPipeline}
                          retryDeploymentPipeline={this.props.retryDeploymentPipeline}
                        />
                      );
                    })}
                  </tr>
                </Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </section>
    );
  }
}

DeploymentPipeline.propTypes = {
  deploymentPipeline: PropTypes.object.isRequired
};

export default DeploymentPipeline;
