import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import url from 'url';
import * as states from '../../constants/states';
import authActions from '../../actions/auth';
import ResetPassword from './ResetPassword';

const mapStateToProps = () => {
  return createSelector(
    (appState) => appState.resetPassword,
    (resetPassword) => {
      return {
        state: resetPassword.state,
        error: resetPassword.error
      };
    }
  );
};

const mapDispatchToProps = {
  resetPassword: authActions.resetPassword
};

class ResetPasswordContainer extends Component {
  constructor (props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleInputChange (event) {
    this[event.target.name] = event.target.value;
  }

  componentDidMount () {
    this.email = url.parse(window.location.href, true).query.email;
  }

  handleFormSubmit (evt) {
    evt.preventDefault();
    this.props.resetPassword(
      this.email,
      this.passwordResetVerificationToken,
      this.newPassword
    );
  }

  render () {
    return (
      <ResetPassword
        error={this.props.error}
        isLoading={this.props.state === states.LOADING}
        didReset={this.props.state === states.OKAY}
        onInputChange={this.handleInputChange}
        onFormSubmit={this.handleFormSubmit}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordContainer);
