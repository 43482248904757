import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import apiActions from '../../actions/api';
import appActions from '../../actions/app';
import * as states from '../../constants/states';
import * as providerStates from '../../constants/providerStates';
import * as planTypes from '../../constants/planTypes';
import makeChangesetLink from '../../utils/makeChangesetLink';
import selectors from '../../selectors';
import StackDeploy from './StackDeploy';

const errorsSelector = createSelector(
  (appState) => appState.editorNodes,
  selectors.nodeTypes,
  (editorNodes, nodeTypes) => {
    let errors = [];

    editorNodes.nodes.filter(node => node.errors && Array.isArray(node.errors))
      .forEach(node => {
        let nodeType = nodeTypes.types[node.type];
        let label = nodeType.label;
        let name = (typeof label === 'function') ? label.call(node) : label;
        node.errors.forEach(error => {
          errors.push(`Node '${name}': ${error}`);
        });
      });

    return errors;
  }
);

const mapStateToProps = () => {
  return createSelector(
    (appState) => appState.stack,
    (appState) => appState.stackBranches,
    (appState) => appState.stackEnvironments,
    (appState) => appState.gitProviders,
    (appState) => appState.currentUser,
    (appState) => appState.account,
    errorsSelector,
    (stack, stackBranches, stackEnvironments, gitProviders, currentUser, account, errors) => {
      const hasEnvironments = stackEnvironments.state === states.OKAY && stackEnvironments.data.length > 0;

      return {
        stack,
        stackBranches,
        previewEnvironment: hasEnvironments ? undefined : stackEnvironments.previewEnvironment,
        environments: {
          ...stackEnvironments,
          data: hasEnvironments ? stackEnvironments.data : [stackEnvironments.previewEnvironment]
        },
        gitProviders,
        currentUser,
        hasEnvironments,
        accountProviderStatus: account.provider.deploymentStatus,
        hasLinkedAws: currentUser.hasLinkedAws,
        isLinkingAwsAccount: !currentUser.hasLinkedAws && account.provider.deploymentStatus === providerStates.IN_PROGRESS,
        isLoadingEnvironments: stackEnvironments.state === states.NEW || stackEnvironments.state === states.LOADING,
        isEnvironmentsFailed: stackEnvironments.state === states.FAILED,
        isLoadingStackBranches: stackBranches.state === states.NEW || stackBranches.state === states.LOADING,
        isStackLoaded: stack.id !== undefined,
        isGitless: stack.isGitless,
        isDevPlan: account.plan.type === planTypes.DEVELOPER,
        errors
      };
    }
  );
};

const mapDispatchToProps = {
  setStackMode: appActions.setStackMode,
  getStackContents: apiActions.getStack,
  getStackBranches: apiActions.getStackBranches,
  getStackEnvironments: apiActions.getStackEnvironments,
  createEnvironment: apiActions.createEnvironment,
  showCanvasErrorsModal: appActions.showCanvasErrorsModal,
  showDeploymentErrorsModal: appActions.showDeploymentErrorsModal,
  showLinkAwsModal: appActions.showLinkAwsModal,
  showDemoModal: appActions.showDemoModal,
  prepare: apiActions.prepare
};

class StackDeployContainer extends Component {
  constructor (props) {
    super(props);

    this.state = {
      searchTerm: null,
      filterTerms: [],
      filteredEnvironments: props.environments.data
    };

    this.filterEnvironments = this.filterEnvironments.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handlePrepareClick = this.handlePrepareClick.bind(this);
    this.handleDeployClick = this.handleDeployClick.bind(this);
  }

  componentDidMount () {
    const {
      stack,
      isStackLoaded,
      isGitless
    } = this.props;

    this.props.getStackEnvironments(stack.owner, stack.name);

    if (isStackLoaded && !isGitless) {
      this.props.getStackBranches(stack.owner, stack.name);
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const {
      stack,
      environments,
      previewEnvironment,
      isStackLoaded,
      isGitless
    } = this.props;

    const {
      stack: prevStack,
      environments: prevEnvironments,
      isStackLoaded: prevIsStackLoaded
    } = prevProps;

    const {
      preparePending
    } = this.state;

    if (this.state.searchTerm !== prevState.searchTerm || this.state.filterTerms !== prevState.filterTerms) {
      this.filterEnvironments();
    }

    if (
      environments.data !== prevEnvironments.data &&
      environments.state === states.OKAY
    ) {
      this.setState({ filteredEnvironments: environments.data }, this.filterEnvironments);
    }

    if (stack.branch !== prevStack.branch && stack.state === states.NEW) {
      this.props.getStackContents(stack.owner, stack.name, stack.branch);
    }

    if (!prevIsStackLoaded && isStackLoaded && !isGitless) {
      this.props.getStackBranches(stack.owner, stack.name);
    }

    if (prevProps.accountProviderStatus !== this.props.accountProviderStatus && this.props.accountProviderStatus === providerStates.SUCCESSFUL && previewEnvironment) {
      this.props.createEnvironment(previewEnvironment.name, previewEnvironment.region, previewEnvironment.accountId);
    }

    if (this.props.hasLinkedAws && !prevProps.hasEnvironments && this.props.hasEnvironments && preparePending) {
      this.props.prepare(preparePending.owner, preparePending.name, preparePending.reference, preparePending.environment);
      this.setState({ preparePending: undefined });
    }
  }

  filterEnvironments () {
    const searchTerm = this.state.searchTerm ? this.state.searchTerm.toLowerCase() : '';
    const filterTerms = this.state.filterTerms;

    const isSearchEmpty = searchTerm === '';
    const isFilterEmpty = filterTerms.length === 0;

    const environments = this.props.environments.data;

    this.setState({
      filteredEnvironments: environments.filter((environment) => {
        const matchesName = environment.name.toLowerCase().includes(searchTerm);
        const matchesRegion = environment.region && environment.region.toLowerCase().includes(searchTerm);
        const matchesAccountId = environment.accountId && environment.accountId.includes(searchTerm);
        const matchesAccountAlias = environment.accountAlias && environment.accountAlias.toLowerCase().includes(searchTerm);
        const matchesStatus = filterTerms.filter(term => {
          return (
            (environment.latestDeployment && term.includes(environment.latestDeployment.status)) ||
            (environment.latestPrepare && term.includes(environment.latestPrepare.status))
          );
        });

        const isSearchFound = matchesName || matchesRegion || matchesAccountId || matchesAccountAlias;
        const isFilterFound = isFilterEmpty || matchesStatus.length > 0;

        return (isSearchEmpty && isFilterEmpty) || (isSearchFound && isFilterFound);
      })
    });
  }

  handleSearchChange (event) {
    this.setState({[event.target.name]: event.target.value});
  }

  handleFilterChange (event) {
    if (event.target.checked) {
      this.setState({[event.target.name]: [...this.state[event.target.name], event.target.value]});
    } else {
      this.setState({[event.target.name]: this.state[event.target.name].filter(v => v !== event.target.value)});
    }
  }

  handlePrepareClick (reference, environment, callback) {
    const {
      stack,
      currentUser,
      errors,
      previewEnvironment,
      hasEnvironments,
      hasLinkedAws
    } = this.props;

    const {
      owner,
      name
    } = stack;

    if (currentUser.isDemoMode) {
      this.props.showDemoModal({ action: 'prepare a stack' });
      return;
    }

    if (errors.length > 0) {
      this.props.showCanvasErrorsModal({ errors });
      return;
    }

    if (window.location.origin.includes('localhost:') && stack.deploymentStrategy === 'legacy') {
      console.log('Running in localhost, Prepare probably will not work');
      return;
    }

    if (callback && typeof callback === 'function') {
      callback.call();
    }

    if (hasEnvironments) {
      this.props.prepare(owner, name, reference, environment);
    } else {
      this.setState({ preparePending: { owner, name, reference, environment } });

      if (hasLinkedAws) {
        this.props.createEnvironment(previewEnvironment.name, previewEnvironment.region, previewEnvironment.accountId);
      } else {
        this.props.showLinkAwsModal();
      }
    }
  }

  handleDeployClick (stackName, changeSet, region) {
    const {
      currentUser
    } = this.props;

    if (currentUser.isDemoMode) {
      this.props.showDemoModal({ action: 'deploy a stack' });
      return;
    }

    window.open(makeChangesetLink(stackName, changeSet, region), 'CloudFormation');
  }

  render () {
    return (
      <StackDeploy
        {...this.props}
        environments={this.state.filteredEnvironments}
        searchTerm={this.state.searchTerm}
        filterTerms={this.state.filterTerms}
        onSearch={this.handleSearchChange}
        onFilter={this.handleFilterChange}
        onPrepareClick={this.handlePrepareClick}
        onDeployClick={this.handleDeployClick}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StackDeployContainer);
