import { types } from '../actions/api';
import * as states from '../constants/states';

export const initialState = {
  state: states.NEW,
  integrations: {
    /* Schema:
     * epsagon: {
     *   defaultToken: '81473714-6459-4e31-8be3-e8ca2c659630'
     * }
     */
  },
  saveState: states.NEW
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case types.GET_INTEGRATIONS.REQUEST:
      return {
        ...state,
        state: states.LOADING,
        integrations: {}
      };

    case types.GET_INTEGRATIONS.FAILURE:
      return {
        ...state,
        state: states.FAILED
      };

    case types.GET_INTEGRATIONS.SUCCESS:
      return {
        ...state,
        state: states.OKAY,
        integrations: action.data.integrations
      };

    case types.SAVE_INTEGRATIONS.REQUEST:
      return {
        ...state,
        saveState: states.LOADING
      };

    case types.SAVE_INTEGRATIONS.FAILURE:
      return {
        ...state,
        saveState: states.FAILED
      };

    case types.SAVE_INTEGRATIONS.SUCCESS:
      return {
        ...state,
        saveState: states.OKAY,
        integrations: action.integrations
      };

    default:
      return state;
  }
};
