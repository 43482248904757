
import React from 'react';
import Link from '../core/Link';

const StackLink = (props) => {
  if (!props.stackExists) {
    return <em>{props.stackName}</em>;
  }

  return <Link to={`/stacks/${props.owner}/${props.stackName}`}>{props.stackName}</Link>;
};

export default StackLink;
