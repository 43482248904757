if (require('../../utils/isVSCode').default) {
  // We're in a VSCode extension, load hacks
  require('./vsCodeHacks');
}

// This might not be necessary anymore with Webpack 2 treeshaking
if (process.env.NODE_ENV === 'production') {
  module.exports = require('./Root.prod');
} else {
  module.exports = require('./Root.dev');
}
