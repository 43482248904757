export const DEVELOPER = 'developer';
export const DEVELOPER2 = 'developer2';
export const PROFESSIONAL = 'professional';
export const ENTERPRISE = 'enterprise';
export const STACKERY = 'stackery';
export const PROFESSIONAL_PROMO = 'professionalPromo';
export const TEAM = 'team';
export const TEAM_TRIAL = 'teamTrial';
export const TEAM_MONTHLY = 'team_monthly';
export const PROFESSIONAL_TRIAL = 'professionalTrial';
