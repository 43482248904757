import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactSwitch from 'react-switch';
import Icon from '../core/Icon';
import style from './Switch.css';

class Switch extends Component {
  constructor (props) {
    super(props);

    this.state = {
      isChecked: props.isChecked
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange (checked, event, id) {
    this.setState(prevState => {
      return {
        isChecked: !prevState.isChecked
      };
    });

    this.props.onToggle(id, checked);
  }

  componentDidUpdate (prevProps) {
    if (prevProps.isChecked !== this.props.isChecked) {
      this.setState({ isChecked: this.props.isChecked });
    }
  }

  render () {
    return (
      <ReactSwitch
        id={this.props.id}
        checkedIcon={<Icon name='checkmark' className={style.iconChecked} />}
        uncheckedIcon={<Icon name='clear' className={style.iconUnchecked} />}
        height={22}
        onColor='#81cbb5'
        offColor='#9FADB5'
        onChange={this.handleChange}
        checked={this.state.isChecked}
      />
    );
  }
}

Switch.propTypes = {
  id: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired
};

export default Switch;
