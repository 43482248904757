export const spaceWidth = 8000;
export const spaceHeight = 8000;
export const gridSize = 20;
export const gridSnapSize = gridSize / 4;
export const nodeDefaultWidth = 100;
export const nodeDefaultHeight = 35;
export const nodeWidthMin = 100;
export const nodeHeightMin = 130;
export const nodeMetricHeight = 16;
export const labelMargin = 25;
export const portRadius = 5;
export const portPitch = 13;
export const portsMargin = 4;
export const nodeOutlineWidth = 2;
export const labelOffset = nodeDefaultHeight - nodeOutlineWidth + 8;
export const lineCurveScale = 0.5;

/* Forces the nodes to be farther apart by adding padding
 * on the x and y boundaries */
export const overlapPadding = {
  x: 15,
  y: 10
};

/* Weight determines how much we want the vector spreading
 * to favor the x or y axis. Since we want nodes to lay out more vertically
 * than horizontally (flows tend to go from left to right) we add more weight
 * on to the y axis. */
export const layoutSpreadWeight = {
  x: 10,
  y: 20
};

/* Forces the nodes to stay within <num> padding away
   from the edges of the canvas on the x and y axes */
export const canvasPadding = {
  x: 15,
  y: 15
};
