import { types } from '../actions/aws';
import * as states from '../constants/states';

export const initialState = {
  state: states.NEW,
  accessKeyId: undefined,
  secretAccessKey: undefined,
  sessionToken: undefined,
  expiration: undefined
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case types.REFRESH_TOKEN.REQUEST:
      return {
        ...state,
        state: states.LOADING
      };
    case types.REFRESH_TOKEN.SUCCESS:
      const {
        expiration,
        ...credentials
      } = action.data.credentials;

      return {
        ...state,
        ...credentials,
        expiration: (new Date(expiration) - (60 * 1000)),
        state: states.OKAY
      };
    default:
      return state;
  }
};
