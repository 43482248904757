import React from 'react';
import ButtonSet from '../core/ButtonSet';

const ConfirmPathRuntime = (props) => {
  return (
    <div>
      <h1>Replace {props.path} contents?</h1>
      <p>Changing {props.resourceId} `Runtime` to <strong>{props.runtime}</strong> will generate new boilerplate that replaces the contents of <strong>{props.path}</strong>.</p>
      <p>How would you like to proceed?</p>
      <ButtonSet
        primaryButton={{
          text: `Replace contents`,
          onClick: props.onClickConfirm
        }}
        secondaryButton={{
          text: 'Do nothing',
          onClick: props.onClickCancel
        }}
      />
    </div>
  );
};

export default ConfirmPathRuntime;
