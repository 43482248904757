import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Spinner from '../core/Spinner';
import Link from '../core/Link';
import Icon from '../core/Icon';
import style from './Total.css';

const Total = props => {
  const {
    type,
    total,
    to,
    children,
    isLoading
  } = props;

  const header = total > 1 || total === 0 ? `${type}s` : type;
  const link = total > 0 ? `View ${header}` : `Add ${type}`;

  return (
    <div className={classnames(style.container, isLoading && style.isLoading)}>
      {isLoading &&
        <Spinner position='static' />
      }

      {!isLoading &&
        <Fragment>
          <h1><span>{total}</span><span>{header}</span></h1>
          {to &&
            <Link to={to}>{link} <Icon name='chevron-right' className={style.iconChevron} /></Link>
          }
          {children && children}
        </Fragment>
      }
    </div>
  );
};

Total.propTypes = {
  type: PropTypes.string,
  total: PropTypes.number,
  to: PropTypes.string,
  isLoading: PropTypes.bool
};

export default Total;
