import React from 'react';
import Link from '../core/Link';
import StatusMessage from '../core/StatusMessage';
import style from './ResourceHeader.css';

const ResourceHeader = (props) => {
  const {
    title,
    resourceType,
    resourceDocsLink,
    description,
    errors
  } = props;

  return (
    <header>
      <h1 className={style.title}>{title}</h1>
      <h2 className={style.subtitle}>
        {resourceType}
        {resourceDocsLink &&
          <Link href={resourceDocsLink} text='Docs' target='_blank' />
        }
      </h2>
      {description &&
        <p>{description}</p>
      }
      {errors &&
        <ul className={style.errorList}>
          {errors.map((error, i) =>
            <StatusMessage key={i} type='error' text={error.message} element='li' />
          )}
        </ul>
      }
    </header>
  );
};

export default ResourceHeader;
