import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import style from './Form.css';

const Form = (props) => {
  return (
    <form
      className={classNames(
        props.className,
        props.alignCenter && style.alignCenter
      )}
      onSubmit={props.onSubmit}
    >
      {props.children}
    </form>
  );
};

Form.defaultProps = {
  className: style.container
};

Form.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func
};

export default Form;
