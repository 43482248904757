import React from 'react';
import Button from '../core/Button';

const EnterSetup = (props) => {
  const userName = props.userName ? props.userName.split(' ')[0] : null;
  const enterSetupVideo = null; // will be replaced with link to video from S3

  return (
    <div>
      <h1>Thanks for signing up{userName && `, ${userName}`}!</h1>
      <p>Our setup process should take no more than a few minutes, and completing it will allow you to create and deploy serverless applications right away. If you feel like skipping a step right now, you can always complete it later.</p>
      <p>Watch the video below to get familiar with the Stackery workflow, or click the button if you're ready to get started.</p>
      <video width='100%' height='100%' playsInline autoPlay controls muted loop>
        <source src={enterSetupVideo} type='video/mp4' /> Your browser does not support the video tag.
      </video>
      <Button
        text='I&quot;m ready!'
        onClick={props.hideModal}
        hid='get started'
      />
    </div>
  );
};

export default EnterSetup;
