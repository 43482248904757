import React, { Component, Fragment } from 'react';
import * as states from '../../constants/states';
import Form from '../core/Form';
import ButtonSet from '../core/ButtonSet';

class DeleteEnvironment extends Component {
  constructor (props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit (event) {
    event.preventDefault();

    const environment = this.props.environment;
    this.props.onSubmit(environment.owner, environment.name);
  }

  render () {
    const {
      isPipelineEnvironment
     } = this.props;

    return (
      <Fragment>
        {!isPipelineEnvironment &&
          <Form onSubmit={this.handleSubmit}>
            <h1>Delete Environment</h1>
            <p>Are you sure you want to delete environment <strong>{this.props.environment.name}</strong>?</p>
            <p>Deleting an environment means it will no longer be accessible from your Stackery dashboard. Deployed CloudFormation stacks will remain in your AWS account.</p>
            <ButtonSet
              primaryButton={{
                text: 'Delete',
                type: 'submit',
                modifier: 'warning',
                isLoading: this.props.state === states.LOADING
              }}
              secondaryButton={{
                text: 'Cancel',
                onClick: this.props.onClickCancel
              }}
            />
          </Form>
        }
        {isPipelineEnvironment &&
          <Form>
            <h1>Delete Environment</h1>
            <p>The <strong>{this.props.environment.name}</strong> environment is a dependency of a pipeline and cannot be deleted.</p>
            <ButtonSet
              primaryButton={{
                text: 'Go back',
                onClick: this.props.onClickCancel
              }}
            />
          </Form>
        }
      </Fragment>
    );
  }
}

DeleteEnvironment.propTypes = {
};

export default DeleteEnvironment;
