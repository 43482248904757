import React, { Fragment } from 'react';
import * as path from 'path';
import ButtonSet from '../core/ButtonSet';

const ConfirmPathInUse = (props) => {
  const isFile = props.isFile === undefined ? path.extname(props.path) !== '' : props.isFile;
  const type = isFile ? 'file' : 'directory';

  return (
    <div>
      <h1>Replace {props.path} contents?</h1>
      <p>
        The {props.settingId} {type} <strong>{props.path}</strong> already exists
        {props.existingResourceId &&
          <Fragment> and is used by <strong>{props.existingResourceId}</strong></Fragment>
        }.
      </p>
      <p>How would you like to proceed?</p>
      <ButtonSet
        primaryButton={{
          text: 'Replace contents',
          onClick: props.onClickConfirm
        }}
        secondaryButton={{
          text: 'Do nothing',
          onClick: props.onClickCancel
        }}
      />
    </div>
  );
};

export default ConfirmPathInUse;
