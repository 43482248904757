import { types as tourTypes } from '../actions/tour';
import { types as cognitoTypes } from '../actions/auth';
import * as tourConfig from '../constants/tourConfig';

export const initialState = {
  run: false,
  steps: [],
  currentTour: '',
  history: {}
};

export default (state = { ...initialState }, action) => {
  let history;

  switch (action.type) {
    case tourTypes.START_TOUR:
      const currentTour = action.component.toUpperCase();
      let steps = tourConfig[currentTour].steps;
      history = state.history[currentTour]; // array within history object

      if (history) {
        for (let step in steps) {
          if (history[history.length - 1] === steps[step].target) {
            steps.splice(0, step + 1);
          }
        }
      }

      return {
        ...state,
        run: true,
        steps,
        currentTour
      };
    case tourTypes.END_TOUR:
      history = state.history[state.currentTour] ? Array.from(state.history[state.currentTour]) : [];

      if (action.isComplete) {
        for (let i in state.steps) {
          const target = state.steps[i].target;
          if (!history.includes(target)) {
            history.push(target);
          }
        }
      }

      return {
        ...state,
        run: false,
        steps: [],
        history: {
          ...state.history,
          [state.currentTour]: history
        }
      };
    case cognitoTypes.GET_COGNITO_USER.SUCCESS:
      if (!action.cognitoUserAttributes['custom:tourHistory']) {
        return;
      }

      return {
        ...state,
        history: JSON.parse(action.cognitoUserAttributes['custom:tourHistory'])
      };
    case tourTypes.ADVANCE_TOUR:
      const currentTourHistory = state.history[state.currentTour] || [];

      if (!currentTourHistory.includes(action.step)) {
        return {
          ...state,
          history: {
            ...state.history,
            [state.currentTour]: currentTourHistory.concat(action.step)
          }
        };
      }

      return {
        ...state
      };
    default:
      return state;
  }
};
