import { all } from 'redux-saga/effects';

import cognito from './cognito';
import api from './api';
import iotNotifications from './iotNotifications';
import userNotifications from './userNotifications';
import deployments from './deployments';
import analytics from './analytics';
import aws from './aws';
import cloudwatch from './cloudwatch';
import auth from './auth';
import setup from './setup';
import publicEditor from './publicEditor';
import stack from './stack';
import resources from './resources';
import canvas from './canvas';
import cms from './cms';
import tour from './tour';
import workspace from './workspace';

export default function * rootSaga () {
  try {
    yield all([
      cognito(),
      api(),
      iotNotifications(),
      userNotifications(),
      deployments(),
      analytics(),
      aws(),
      cloudwatch(),
      auth(),
      setup(),
      publicEditor(),
      stack(),
      resources(),
      canvas(),
      cms(),
      tour(),
      workspace()
    ]);
  } catch (err) {
    console.error(err);
  }
}
