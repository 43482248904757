import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import EnvironmentOverview from './EnvironmentOverview';

const mapStateToProps = () => {
  return createSelector(
    (appState) => appState.environment,
    (environment) => {
      return {
        environment
      };
    }
  );
};

const mapDispatchToProps = {
};

class EnvironmentOverviewContainer extends Component {
  render () {
    return (
      <EnvironmentOverview {...this.props} />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EnvironmentOverviewContainer);
