import React from 'react';
import Link from '../core/Link';
import style from './Activity.css';

const PipelineLink = (props) => {
  if (!props.stackExists) {
    return <em>{props.pipelineName} Pipeline</em>;
  }

  return <Link to={`/stacks/${props.owner}/${props.stackName}/settings`}><span className={style.capitalize}>{props.pipelineName} Pipeline</span></Link>;
};

export default PipelineLink;
