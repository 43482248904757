import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import style from './HeadingGroup.css';

const HeadingGroup = (props) => {
  const Primary = 'h' + props.level;
  const Secondary = 'h' + (props.level + 1);

  return (
    <hgroup
      className={classNames(
        props.className,
        props.alignCenter && style.alignCenter,
        props.displayInline && style.displayInline
      )}
    >
      <Primary className={style.primary}>{props.primaryText}</Primary>
      <Secondary className={style.secondary}>{props.secondaryText}</Secondary>
      {props.children}
    </hgroup>
  );
};

HeadingGroup.defaultProps = {
  className: style.container,
  alignCenter: false,
  displayInline: false,
  level: 1
};

HeadingGroup.propTypes = {
  className: PropTypes.string,
  primaryText: PropTypes.string,
  secondaryText: PropTypes.string,
  level: PropTypes.number,
  alignCenter: PropTypes.bool,
  displayInline: PropTypes.bool
};

export default HeadingGroup;
