import React from 'react';
import PropTypes from 'prop-types';
import getQueryValue from '../../utils/getQueryValue';
import Spinner from '../core/Spinner';
import AuthorizedApp from './AuthorizedApp';
import UnauthorizedApp from './UnauthorizedApp';

const redirectWhitelist = [
  /^https:\/\/(?:www\.)?github.com(?:\/.*)?$/,
  /^https:\/\/(?:www\.)?stackery\.io(?:\/.*)?$/
];

if (process.env.REACT_APP_ENV === 'local') {
  redirectWhitelist.push(/^.*$/);
}

const App = props => {
  const {
    pathname,
    search
  } = props.location;

  // Redirect Oauth routes that originated on www.stackery.io back to it & do not proceed into the app
  if (pathname.includes('oauth/github') && search.includes('redirect')) {
    const redirectAllowed = document.referrer && !!redirectWhitelist.find(regex => document.referrer.match(regex));
    if (redirectAllowed) {
      const redirect = getQueryValue(search, 'redirect');
      const params = `?code=${getQueryValue(search, 'code')}&state=${getQueryValue(search, 'state')}`;
      window.location.replace(`${decodeURIComponent(redirect)}${params}`);
      return <Spinner />;
    } else {
      console.log(`Invalid redirect from ${document.referrer}- ignoring`);
    }
  }

  return props.auth.token ? <AuthorizedApp {...props} /> : <UnauthorizedApp {...props} />;
};

App.propTypes = {
  auth: PropTypes.object.isRequired,
  currentUser: PropTypes.object,
  modal: PropTypes.object
};

export default App;
