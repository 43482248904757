import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push, replace } from 'connected-react-router';
import { createSelector } from 'reselect';
import appActions from '../../actions/app';
import apiActions from '../../actions/api';
import Visual from './Visual';

const mapStateToProps = () => {
  return createSelector(
    (appState) => appState.stack,
    (appState) => appState.canvas,
    (appState) => appState.templateEditor,
    (appState) => appState.nodeEditor,
    (appState) => appState.resourceEditor,
    (stack, canvas, templateEditor, nodeEditor, resourceEditor) => {
      return {
        stack,
        defaultTemplate: templateEditor.defaultTemplate,
        isVisualEditingSupported: stack.isVisualEditingSupported,
        isTemplateValid: templateEditor.isTemplateValid,
        showNodePalette: canvas.paletteOpen,
        showNodeEditor: !!nodeEditor.node,
        showResourceEditor: !!resourceEditor.id
      };
    }
  );
};

const mapDispatchToProps = {
  push,
  replace,
  selectStackResource: appActions.selectStackResource,
  deselectStackResource: appActions.deselectStackResource,
  getDefaultBlueprint: apiActions.getDefaultBlueprint
};

class VisualContainer extends Component {
  componentDidMount () {
    const {
      url,
      params
    } = this.props.match;

    if (params.resourceId) {
      if (this.props.stack.isGitless) {
        this.props.replace(url.replace(`/visual/${params.resourceId}`, '/visual'));
      } else {
        this.props.selectStackResource(params.resourceId);
      }
    }

    if (!this.props.defaultTemplate) {
      this.props.getDefaultBlueprint('SAM');
    }
  }

  componentDidUpdate (prevProps) {
    const {
      stack,
      match,
      selectStackResource,
      deselectStackResource,
      push
    } = this.props;

    const {
      stack: prevStack,
      match: prevMatch
    } = prevProps;

    // Append stackResourceId to route if stack.selectedResourceId is defined
    if (!prevMatch.params.resourceId && !match.params.resourceId && stack.selectedResourceId) {
      push(`${match.url}/${encodeURIComponent(stack.selectedResourceId)}${window.location.search}`);
    }

    // Select stackResourceId if it's in the route and not selected
    if (!prevMatch.params.resourceId && !stack.selectedResourceId && match.params.resourceId) {
      selectStackResource(match.params.resourceId);
    }

    // Remove stackResourceId from route if nextStack.selectedResourceId is not defined
    if (prevStack.selectedResourceId && !stack.selectedResourceId && prevMatch.params.resourceId) {
      const route = window.location.pathname.split('/').slice(0, -1).join('/');
      push(`${route}${window.location.search}`);
    }

    // Unset stack.selectedResourceId if not defined in route params
    if (prevStack.selectedResourceId && stack.selectedResourceId && !match.params.resourceId) {
      deselectStackResource();
    }

    // Unset stack.selectedResourceId if switching branches
    if (stack.selectedResourceId && prevStack.branch && stack.branch !== prevStack.branch) {
      this.props.deselectStackResource();
    }
  }

  componentWillUnmount () {
    if (this.props.stack.selectedResourceId) {
      this.props.deselectStackResource();
    }
  }

  render () {
    return (
      <Visual
        {...this.props}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VisualContainer);
