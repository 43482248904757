const TYPESCRIPT_BUILD_HOOK = () => (
  `#!/bin/bash

set -e

for i in src/*; do
  echo transpiling $i function
  (cd $i; npm install; npm run build; npm prune --production)
done
`
);

export const deployHooksReadme = `This directory was generated by [Stackery.io](https://www.stackery.io).

We recommend updating this readme with your own deployment phase hooks specific documentation.

# Stackery Documentation
Documentation for deployment phase hooks scripts can be found at [https://docs.stackery.io/docs/workflow/deploying-serverless-stacks/#deployment-phase-hooks](https://docs.stackery.io/docs/workflow/deploying-serverless-stacks/#deployment-phase-hooks).
`;

export default {
  'nodejs14.x (typescript)': TYPESCRIPT_BUILD_HOOK,
  'nodejs12.x (typescript)': TYPESCRIPT_BUILD_HOOK,
  'nodejs10.x (typescript)': TYPESCRIPT_BUILD_HOOK
};
