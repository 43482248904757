import { types as appTypes } from '../actions/app';
import { types as apiTypes } from '../actions/api';
import { types as iotNotificationsTypes } from '../actions/iotNotifications';
import * as states from '../constants/states';

const isTemplateEditingSupported = (format) => format && format !== 'stackery';
const isVisualEditingSupported = (format) => format && (format === 'SAM' || format === 'stackery');

export const initialState = {
  state: states.NEW,
  savingState: states.NEW,
  mode: undefined,
  isMultiFile: false,
  id: undefined,
  name: undefined,
  owner: undefined,
  environment: undefined,
  branch: undefined,
  link: undefined,
  region: undefined, // used to target metrics for ingested stacks
  tags: undefined,
  selectedResourceId: undefined,
  hasTemplatePathChanged: false,
  isTemplateEditingSupported: true,
  isVisualEditingSupported: true,
  version: undefined,
  format: 'SAM',
  error: undefined,
  isDefaultTemplate: false,
  isGitless: false,
  wasGitless: false,
  git: {},

  // Extra properties of the stack to be passed through from open to save
  metadata: {}
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case appTypes.UNMOUNT_COMPONENT:
      return {
        ...state,
        wasGitless: false
      };

    case appTypes.SET_STACK_MODE:
      return {
        ...state,
        mode: action.mode
      };

    case appTypes.SET_STACK_IS_MULTI_FILE:
      return {
        ...state,
        isMultiFile: action.isMultiFile
      };

    case appTypes.SELECT_STACK:
      return {
        ...state,
        name: action.name,
        owner: action.owner
      };

    case appTypes.DESELECT_STACK:
      return {
        ...initialState
      };

    case appTypes.SET_STACK_FORMAT:
      return {
        ...state,
        format: action.format,
        isTemplateEditingSupported: isTemplateEditingSupported(action.format),
        isVisualEditingSupported: isVisualEditingSupported(action.format)
      };

    case apiTypes.GET_STACK.SUCCESS:
      return {
        ...state,
        ...action.data,
        isGitless: action.data.link === null
      };

    case apiTypes.GET_STACK.FAILURE:
      return {
        ...state,
        state: states.FAILED,
        error: {
          ...action.error.message
        }
      };

    case apiTypes.SAVE_STACK_TEMPLATE_PATH.SUCCESS:
      return {
        ...state,
        hasTemplatePathChanged: true,
        templatePath: action.templatePath
      };

    case apiTypes.SAVE_STACK_DEPLOYMENT_HOOKS_DIRECTORY.SUCCESS:
      return {
        ...state,
        deploymentHooksDirectory: action.deploymentHooksDirectory
      };

    case apiTypes.SAVE_STACK_DEPLOYMENT_STRATEGY.SUCCESS:
      return {
        ...state,
        deploymentStrategy: action.deploymentStrategy
      };

    case appTypes.SELECT_STACK_BRANCH:
      return {
        ...state,
        branch: action.branch,
        version: undefined,
        wasGitless: false,
        git: {}
      };

    case appTypes.SELECT_STACK_ENVIRONMENT:
      return {
        ...state,
        environment: action.environment
      };

    case appTypes.SELECT_STACK_RESOURCE:
      return {
        ...state,
        selectedResourceId: action.resourceId
      };

    case appTypes.DESELECT_STACK_RESOURCE:
      return {
        ...state,
        selectedResourceId: undefined
      };

    case appTypes.PARSE_STACK_TEMPLATE.SUCCESS: // TODO add PARSE_STACK_TEMPLATE.FAILURE
    case appTypes.RESET_STACK_TEMPLATE.SUCCESS:
      return {
        ...state,
        format: action.format,
        isTemplateEditingSupported: isTemplateEditingSupported(action.format),
        isVisualEditingSupported: isVisualEditingSupported(action.format)
      };

    case apiTypes.SET_STACK_REPO.REQUEST:
      return {
        ...state,
        savingState: states.LOADING
      };

    case apiTypes.SET_STACK_REPO.SUCCESS:
      return {
        ...state,
        ...action.data,
        savingState: states.OKAY,
        isGitless: false,
        branch: undefined
      };

    case apiTypes.SET_STACK_REPO.FAILURE:
      return {
        ...state,
        savingState: states.FAILED
      };

    case apiTypes.RENAME_STACK.SUCCESS:
      if (!state.name) { return state; }
      return {
        ...state,
        name: action.newName
      };

    case apiTypes.DELETE_STACK.SUCCESS:
      return {
        ...initialState
      };

    case apiTypes.SET_STACK_TAGS.SUCCESS:
      if (!state.name) { return state; }
      return {
        ...state,
        tags: action.tags
      };

    case apiTypes.GET_STACK_CONTENTS.REQUEST:
      return {
        ...state,
        state: states.LOADING
      };

    case apiTypes.GET_STACK_CONTENTS.SUCCESS:
      let metadata;

      if (action.data.format === 'stackery') {
        metadata = { ...action.data.stack };
        delete metadata.nodes;
      }

      return {
        ...state,
        state: states.OKAY,
        version: action.data.version,
        format: action.data.format,
        hasTemplatePathChanged: false,
        isDefaultTemplate: action.data.isDefaultTemplate,
        git: {},
        isTemplateEditingSupported: isTemplateEditingSupported(action.data.format),
        isVisualEditingSupported: isVisualEditingSupported(action.data.format),
        metadata
      };

    case apiTypes.GET_STACK_CONTENTS.FAILURE:
      return {
        ...state,
        state: states.FAILED,
        format: undefined,
        isTemplateEditingSupported: false,
        isVisualEditingSupported: false,
        error: action.error
      };

    case apiTypes.SAVE_RESOURCES.REQUEST:
      return {
        ...state,
        savingState: states.LOADING,
        wasGitless: false
      };

    case apiTypes.SAVE_NODES.SUCCESS:
      return {
        ...state,
        ...action.data.stackInfo,
        state: states.OKAY,
        branch: action.branch,
        savingState: states.OKAY,
        version: action.data.version,
        isGitless: false,
        wasGitless: state.isGitless,
        git: {}
      };

    case apiTypes.SAVE_NODES.FAILURE:
      return {
        ...state,
        savingState: states.FAILED
      };

    case apiTypes.CREATE_BRANCH.SUCCESS:
      return {
        ...state,
        branch: action.branch,
        version: action.data.version,
        wasGitless: false,
        git: {}
      };

    case iotNotificationsTypes.GIT:
      const {
        status,
        message
      } = action;

      if (
        state.version &&
        message.sha !== state.version &&
        (
          message.stack === state.id ||
          message.stack === state.name
        ) &&
        message.owner === state.owner &&
        message.reference === state.branch
      ) {
        return {
          ...state,
          git: {
            status,
            ...message
          }
        };
      }

      return state;

    case apiTypes.GET_DEPLOYMENT_STATE.SUCCESS:
      return {
        ...state,
        format: action.data.format,
        isTemplateEditingSupported: isTemplateEditingSupported(action.data.format),
        isVisualEditingSupported: isVisualEditingSupported(action.data.format)
      };

    default:
      return state;
  }
};
