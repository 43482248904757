import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ENTER } from '../../constants/keyCodes';
import Icon from './Icon';
import style from './ContextMenuItem.css';

class ContextMenuItem extends Component {
  constructor (props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick (event) {
    event.preventDefault();
    event.stopPropagation();

    if (this.props.isOpen && event && (event.type === 'click' || event.keyCode === ENTER)) {
      this.props.hide();
      this.props.onClick(this.props.params);
    }
  }

  render () {
    return (
      <li
        id={this.props.id}
        className={classNames(style.container, this.props.isActive && style.isActive)}
        tabIndex='-1'
        onClick={this.handleClick}
        onKeyUp={this.handleClick}
      >
        {this.props.label}
        {this.props.isActive &&
          <Icon
            className={style.iconChevron}
            name='chevron-right'
          />
        }
      </li>
    );
  }
}

ContextMenuItem.propTypes = {
  label: PropTypes.string.isRequired
};

export default ContextMenuItem;
