export const deployed = 'Stack deployed';
export const undeployed = 'Stack undeployed';
export const deleted = 'Stack deactivated';
export const stackCreated = 'Stack created';
export const stackDeleted = 'Stack deleted';
export const environmentCreated = 'Environment created';
export const environmentDeleted = 'Environment deleted';
export const paramsModified = 'Environment parameters modified';
export const secretCreated = 'Secret created';
export const secretModified = 'Secret modified';
export const secretDeleted = 'Secret deleted';
export const planUpdated = 'Plan updated';
export const pipelineEnabled = 'Stack pipeline enabled';
export const pipelineDisabled = 'Stack pipeline disabled';
export const pipelineStepEnabled = 'Stack pipeline step enabled';
export const pipelineStepDisabled = 'Stack pipeline step disabled';
export const pipelineEnvironmentUpdated = 'Stack pipeline environment updated';
export const deploymentPipelineCreated = 'Deployment Pipeline created';
export const deploymentPipelineUpdated = 'Deployment Pipeline updated';
export const deploymentPipelineDeleted = 'Deployment Pipeline deleted';
