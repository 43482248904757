import merge from 'deepmerge';
import cloneDeep from 'clone-deep';
import { intrinsicFunctionType } from '../manageCFResources';

import functionResource from './function.yaml';
import lambda from './lambda.yaml';
import timer from './timer.yaml';
import topic from './topic.yaml';
import virtualNetwork from './virtualNetwork.yaml';
import simpleTable from './simpleTable.yaml';
import api from './api.yaml';
import httpApi from './httpApi.yaml';
import implicitApi from './implicitApi.yaml';
import objectStore from './objectStore.yaml';
import table from './table.yaml';
import stream from './stream.yaml';
import bastion from './bastion.yaml';
import cdn from './cdn.yaml';
import dockerTask from './dockerTask.yaml';
import database from './database.yaml';
import queue from './queue.yaml';
import graphdb from './graphdb.yaml';
import graphql from './graphql.yaml';
import httpProxy from './httpProxy.yaml';
import edgeFunction from './edgeFunction.yaml';
import custom from './custom.yaml';
import graphqlToTable from './graphqlIntegrations/table.yaml';
import graphqlToFunction from './graphqlIntegrations/function.yaml';
import graphqlToHttpProxy from './graphqlIntegrations/httpProxy.yaml';
import secrets from './secrets.yaml';
import userPool from './userPool.yaml';
import userPoolClient from './userPoolClient.yaml';
import website from './website.yaml';
import websocket from './websocket.yaml';
import eventRule from './eventRule.yaml';
import layer from './layer.yaml';
import stateMachine from './stateMachine.yaml';
import cdnFunction from './cdnFunction.yaml';

const definitions = [
  functionResource,
  lambda,
  timer,
  topic,
  virtualNetwork,
  simpleTable,
  api,
  httpApi,
  implicitApi,
  objectStore,
  table,
  stream,
  bastion,
  cdn,
  dockerTask,
  database,
  queue,
  graphdb,
  graphql,
  httpProxy,
  edgeFunction,
  graphqlToTable,
  graphqlToFunction,
  graphqlToHttpProxy,
  custom,
  secrets,
  userPool,
  userPoolClient,
  websocket,
  website,
  eventRule,
  layer,
  stateMachine,
  cdnFunction
].reduce((definitions, definition) => merge(definitions || {}, definition));

const SERVERLESS_RESOURCES_RE = /^\$\.(Metadata[.[]|Resources[.[]|Conditions[.[])/;
const SERVERLESS_FN_SUB_RE = /\$\{([^}]*)\}/g;
const formatResolver = (definition, format) => {
  if (definition && typeof definition === 'object') {
    if (Object.keys(definition).length === 1 && 'Format' in definition) {
      return formatResolver(definition.Format[format], format);
    }

    for (const key in definition) {
      const subDefinition = definition[key];

      if (subDefinition && typeof subDefinition === 'object' && 'OnlyFormats' in subDefinition) {
        if (subDefinition.OnlyFormats.includes(format)) {
          definition[key] = formatResolver(subDefinition, format);
          delete definition[key].OnlyFormats;
        } else {
          delete definition[key];
        }
      } else {
        definition[key] = formatResolver(subDefinition, format);
      }
    }
  } else if (format === 'serverless' && typeof definition === 'string') {
    /* Serverless CF resources are defined under the `resources` key. Rather
     * than parameterize every definition, simply update them here. */
    definition = definition.replace(SERVERLESS_RESOURCES_RE, '$.resources.$1');
  }

  if (format === 'serverless') {
    const type = intrinsicFunctionType(definition);
    if (type === 'Fn::Sub') {
      if (Array.isArray(definition['Fn::Sub'])) {
        definition['Fn::Sub'][0] = definition['Fn::Sub'][0].replace(SERVERLESS_FN_SUB_RE, '#{$1}');
      } else {
        definition['Fn::Sub'] = definition['Fn::Sub'].replace(SERVERLESS_FN_SUB_RE, '#{$1}');
      }
    }
  }

  return definition;
};

export const SAM = formatResolver(cloneDeep(definitions), 'SAM');
export const serverless = formatResolver(cloneDeep(definitions), 'serverless');
