import { dirname, join } from 'path';
import { select, take, putResolve, takeLatest, all, call, race } from 'redux-saga/effects';
import cfYamlParser from 'cf-yaml-parser';
import canvasActions, { types as canvasTypes } from '../actions/canvas';
import { types as resourceEditorTypes } from '../actions/resourceEditor';
import appActions, { types as appTypes } from '../actions/app';
import * as modes from '../constants/modes';
import { calculateNodeWidth, calculateNodeHeight } from '../utils/calculateNode';
import { typesDashboardProperties } from '../selectors/nodeTypes';
import { gridSize, nodeDefaultHeight } from '../constants/canvas';
import isComputeResource from '../utils/isComputeResource';
import { save } from './workspace';
import { scaffoldFunction, scaffoldLayer, updatePackageType, updateStackeryConfig, updateRuntime, saveSafely, renameSafely, removeSafely } from './workspaceFiles';

let FILE_TASKS = [];

function getResourcePath (templateDir, sourcePath) {
  return templateDir && templateDir !== dirname(sourcePath) ? join(templateDir, sourcePath) : sourcePath;
}

function * handleVirtualNetworkPlacements ({ id, isParent, children, isChild, parent }) {
  const isEditing = yield select(state => state.stack.mode !== modes.READ_ONLY);

  if (!isEditing) {
    return;
  }

  const state = yield select(state => state.formation.resources);
  const existingPlacements = state.virtualNetworkPlacements;

  const updatedResourceIds = [];

  if (isParent) {
    Object.keys(existingPlacements)
      .filter(resourceId => !children.includes(resourceId) && existingPlacements[resourceId].VirtualNetworkId.ResourceId === id)
      .forEach(resourceId => {
        state.deleteVirtualNetworkPlacement(resourceId);
        updatedResourceIds.push(resourceId);
      });

    children
      .filter(resourceId => !(resourceId in existingPlacements))
      .forEach(resourceId => {
        state.putVirtualNetworkPlacement(resourceId, id);
        updatedResourceIds.push(resourceId);
      });
  } else if (isChild) {
    if (existingPlacements[id] && !parent) {
      state.deleteVirtualNetworkPlacement(id);
      updatedResourceIds.push(id);
    } else if (
      (!existingPlacements[id] && parent) ||
      (existingPlacements[id] && existingPlacements[id].VirtualNetworkId && existingPlacements[id].VirtualNetworkId.ResourceId !== parent)
    ) {
      state.putVirtualNetworkPlacement(id, parent);
      updatedResourceIds.push(id);
    }
  }

  const nodes = yield select(state => state.editorNodes.nodes);
  for (let i = 0; i < updatedResourceIds.length; i++) {
    const node = nodes.find(node => node.id === updatedResourceIds[i]);

    if (node) {
      yield putResolve(canvasActions.updateNode(node));
    }
  }

  if (updatedResourceIds.length > 0) {
    yield putResolve(canvasActions.updateResources(state));
  }
}

function * handleIntegrations ({ source, target }) {
  const state = yield select(state => state.formation.resources);
  const nodes = yield select(state => state.editorNodes.nodes);
  const node = nodes.find(node => node.id === source);
  let facetId;
  let facetType;

  if (node.type === 'facet') {
    facetId = node.id;
    facetType = node.facetType;
    source = node.sourceId;
  }

  const sourceType = state.resources[source].Type;

  if (isComputeResource(sourceType) && (node.type !== 'facet' || facetType === 'references')) {
    state.addReference(source, target);
  } else {
    state.addIntegration(source, target, facetType, facetId);
  }

  yield putResolve(canvasActions.updateResources(state));
}

function * handleCreateResource (args) {
  // If template is blank, reset to default template before creating resource
  if (yield select(state => state.templateEditor.isTemplateEmpty)) {
    yield putResolve(appActions.resetStackTemplate());
    yield take([appTypes.RESET_STACK_TEMPLATE.SUCCESS, appTypes.RESET_STACK_TEMPLATE.FAILURE]);
    yield call(createResource, args);
  } else {
    yield call(createResource, args);
  }
}

function * createResource ({ node: primaryNode, isClick }) {
  const state = yield select(state => state.formation.resources);
  const templateDir = yield select(state => state.workspace.templateDir);
  const nodeTypes = typesDashboardProperties(state.format);

  primaryNode.id = state.addResource(primaryNode.type);
  primaryNode.name = state.getResourceSetting(primaryNode.id, null, null, 'Name');

  const nodes = [ primaryNode ];
  const resource = state.resources[primaryNode.id];

  if ('Facets' in resource) {
    const primaryNodeOriginalWidth = primaryNode.width;
    const primaryNodeOriginalHeight = primaryNode.height;

    primaryNode.width = calculateNodeWidth(primaryNode, nodeTypes, state.resources);
    primaryNode.height = nodeDefaultHeight + gridSize;

    for (const facetType in resource.Facets) {
      for (const facet of resource.Facets[facetType]) {
        const node = {
          id: facet.Id,
          type: 'facet',
          sourceType: resource.Type,
          facetType: facetType,
          sourceId: primaryNode.id,
          facet,
          wires: [],
          _cache: {}
        };

        node.width = calculateNodeWidth(node, nodeTypes, state.resources);
        node.height = calculateNodeHeight(node, nodeTypes);

        primaryNode.width = Math.max(primaryNode.width, node.width + gridSize * 2);
        primaryNode.height += node.height + gridSize;

        nodes.push(node);
      }
    }

    // Leave extra visual space if there are no facets
    if (nodes.length === 1) {
      primaryNode.height += nodeDefaultHeight + gridSize;
    }

    const primaryNodeLeft = primaryNode.x - primaryNodeOriginalWidth / 2;
    const primaryNodeTop = primaryNode.y - primaryNodeOriginalHeight / 2;

    primaryNode.x = primaryNodeLeft + primaryNode.width / 2;
    primaryNode.y = primaryNodeTop + primaryNode.height / 2;

    let nextYTop = primaryNodeTop + nodeDefaultHeight + gridSize;
    for (let i = 1; i < nodes.length; i++) {
      const facetNode = nodes[i];

      facetNode.x = primaryNode.x;
      facetNode.width = primaryNode.width - 2 * gridSize;
      facetNode.y = nextYTop + facetNode.height / 2;

      nextYTop += facetNode.height + gridSize;
    }
  }

  if (state.format === 'SAM') {
    if (primaryNode.type === 'function' || primaryNode.type === 'edgeFunction') {
      const runtime = state.getResourceSetting(primaryNode.id, null, null, 'Runtime');

      FILE_TASKS.push(call(scaffoldFunction, getResourcePath(templateDir, state.getResourceSetting(primaryNode.id, null, null, 'SourcePath')), runtime, primaryNode.id));
    }

    if (primaryNode.type === 'graphql') {
      FILE_TASKS.push(call(saveSafely, 'SchemaLocation', getResourcePath(templateDir, state.getResourceSetting(primaryNode.id, null, null, 'SchemaLocation')), state.getResourceSetting(primaryNode.id, null, null, 'Schema')));
    }

    if (primaryNode.type === 'layer') {
      FILE_TASKS.push(call(scaffoldLayer, getResourcePath(templateDir, state.getResourceSetting(primaryNode.id, null, null, 'SourcePath')), primaryNode.id));
    }

    if (primaryNode.type === 'stateMachine') {
      const definitionLocation = state.getResourceSetting(primaryNode.id, null, null, 'DefinitionLocation');
      const definition = state.getResourceSetting(primaryNode.id, null, null, 'Definition');

      let definitionString;
      if (definitionLocation.endsWith('.json')) {
        definitionString = JSON.stringify(definition, null, 2);
      } else {
        definitionString = cfYamlParser.toString(definition);
      }

      FILE_TASKS.push(call(saveSafely, 'DefinitionLocation', getResourcePath(templateDir, definitionLocation), definitionString));
    }
  }

  yield putResolve(canvasActions.dragNewNodes(nodes, primaryNode.x, primaryNode.y, isClick));
  yield putResolve(canvasActions.updateResources(state));
}

function * deleteResource ({ nodes, wire }) {
  const state = yield select(state => state.formation.resources);
  const templateDir = yield select(state => state.workspace.templateDir);

  for (const resourceId of nodes) {
    const resource = state.resources[resourceId];

    if (resource.Type === 'function' || resource.Type === 'edgeFunction' || resource.Type === 'layer') {
      FILE_TASKS.push(call(removeSafely, 'SourcePath', getResourcePath(templateDir, state.getResourceSetting(resourceId, null, null, 'SourcePath'))));
    }

    if (resource.Type === 'graphql' && state.getResourceSetting(resourceId, null, null, 'SaveSchemaInFile')) {
      FILE_TASKS.push(call(removeSafely, 'SchemaLocation', getResourcePath(templateDir, state.getResourceSetting(resourceId, null, null, 'SchemaLocation'))));

      for (const facet of resource.Facets.field) {
        if (state.getResourceSetting(resourceId, 'field', facet.Id, 'SaveRequestMappingTemplateInFile')) {
          FILE_TASKS.push(call(removeSafely, 'RequestMappingTemplateLocation', getResourcePath(templateDir, state.getResourceSetting(resourceId, 'field', facet.Id, 'RequestMappingTemplateLocation'))));
        }

        if (state.getResourceSetting(resourceId, 'field', facet.Id, 'SaveResponseMappingTemplateInFile')) {
          FILE_TASKS.push(call(removeSafely, 'ResponseMappingTemplateLocation', getResourcePath(templateDir, state.getResourceSetting(resourceId, 'field', facet.Id, 'ResponseMappingTemplateLocation'))));
        }
      }
    }

    if (resource.Type === 'stateMachine' && state.getResourceSetting(resourceId, null, null, 'SaveDefinitionInFile')) {
      FILE_TASKS.push(call(removeSafely, 'DefinitionLocation', getResourcePath(templateDir, state.getResourceSetting(resourceId, null, null, 'DefinitionLocation'))));
    }

    state.deleteResource(resourceId);
  }

  if (wire) {
    nodes = yield select(state => state.editorNodes.nodes);
    const node = nodes.find(node => node.id === wire.source);
    const sourceId = node.type === 'facet' ? node.sourceId : wire.source;
    const sourceType = state.resources[sourceId].Type;

    if (isComputeResource(sourceType) && (node.type !== 'facet' || node.facetType === 'references')) {
      state.deleteReference(sourceId, wire.target);
    } else {
      state.deleteIntegration(sourceId, wire.target, node.facetType, node.facet && node.facet.Id);
    }
  }

  yield putResolve(canvasActions.updateResources(state));
}

const PATH_SETTINGS = {
  function: [
    'SourcePath'
  ],
  edgeFunction: [
    'SourcePath'
  ],
  layer: [
    'SourcePath'
  ],
  graphql: [
    'SchemaLocation'
  ],
  graphql_field: [
    'RequestMappingTemplateLocation',
    'ResponseMappingTemplateLocation'
  ],
  stateMachine: [
    'DefinitionLocation'
  ]
};

function * saveResourceSetting (setting) {
  let { resourceId, settingId, facetType, facetId, value } = setting;
  const state = yield select(state => state.formation.resources);
  const resource = state.resources[resourceId];
  const prevValue = state.getResourceSetting(resourceId, facetType, facetId, settingId);
  const templateDir = yield select(state => state.workspace.templateDir);

  if (resource.Type === 'function' || resource.Type === 'edgeFunction') {
    const sourcePath = getResourcePath(templateDir, state.getResourceSetting(resourceId, null, null, 'SourcePath'));

    if (
      settingId === 'Runtime' &&
      (
        (value.startsWith('nodejs') && !prevValue.startsWith('nodejs')) ||
        (value.includes('typescript') && !prevValue.includes('typescript')) ||
        (!value.includes('typescript') && prevValue.includes('typescript')) ||
        (value.startsWith('python') && !prevValue.startsWith('python')) ||
        (value.startsWith('java') && !prevValue.startsWith('java')) ||
        (value.startsWith('dotnetcore') && !prevValue.startsWith('dotnetcore')) ||
        (value.startsWith('ruby') && !prevValue.startsWith('ruby')) ||
        (value.startsWith('go') && !prevValue.startsWith('go'))
      )
    ) {
      FILE_TASKS.push(call(updateRuntime, sourcePath, value, resourceId));
    }

    if (settingId === 'LogicalId' && prevValue !== value) {
      FILE_TASKS.push(call(updateStackeryConfig, sourcePath, value, resourceId));
    }
    if (settingId === 'PackageType' && prevValue !== value) {
      const imageDockerContext = state.getResourceSetting(resourceId, null, null, 'ImageDockerContext');
      const imageDockerfile = state.getResourceSetting(resourceId, null, null, 'ImageDockerfile');
      FILE_TASKS.push(call(updatePackageType, join(imageDockerContext, imageDockerfile), value, resourceId));
    }
    if (settingId === 'ImageDockerfile' && prevValue !== value) {
      const imageDockerContext = state.getResourceSetting(resourceId, null, null, 'ImageDockerContext');
      FILE_TASKS.push(call(renameSafely, state.resources, resourceId, settingId, getResourcePath(templateDir, join(imageDockerContext, value)), getResourcePath(templateDir, join(imageDockerContext, prevValue)), { isFile: true }));
    }
    if (settingId === 'ImageDockerContext' && prevValue !== value) {
      const imageDockerfile = state.getResourceSetting(resourceId, null, null, 'ImageDockerfile');
      FILE_TASKS.push(call(renameSafely, state.resources, resourceId, settingId, getResourcePath(templateDir, join(value, imageDockerfile)), getResourcePath(templateDir, join(prevValue, imageDockerfile)), { isFile: true }));
    }
  } else if (resource.Type === 'graphql') {
    const schemaLocation = getResourcePath(templateDir, state.getResourceSetting(resourceId, null, null, 'SchemaLocation'));
    const saveSchemaInFile = state.getResourceSetting(resourceId, null, null, 'SaveSchemaInFile');
    const saveRequestMappingTemplateInFile = state.getResourceSetting(resourceId, facetType, facetId, 'SaveRequestMappingTemplateInFile');
    const saveResponseMappingTemplateInFile = state.getResourceSetting(resourceId, facetType, facetId, 'SaveResponseMappingTemplateInFile');
    const requestMappingTemplateLocation = getResourcePath(templateDir, state.getResourceSetting(resourceId, facetType, facetId, 'RequestMappingTemplateLocation'));
    const responseMappingTemplateLocation = getResourcePath(templateDir, state.getResourceSetting(resourceId, facetType, facetId, 'ResponseMappingTemplateLocation'));
    const requestTemplateContents = `{
  "version": "2017-02-28",
  "payload": $util.parseJson($context.arguments.body)
}`;
    // https://docs.aws.amazon.com/appsync/latest/devguide/resolver-util-reference.html
    const responseTemplateContents = '$util.toJson($context.result)';

    if (settingId === 'Resolvers') {
      for (const facet of resource.Facets.field) {
        if (!value.find(resolver => resolver.Type === facet.Properties.Type && resolver.Field === facet.Properties.Field)) {
          if (state.getResourceSetting(resourceId, 'field', facet.Id, 'RequestMappingTemplateLocation')) {
            FILE_TASKS.push(call(removeSafely, 'RequestMappingTemplateLocation', getResourcePath(templateDir, state.getResourceSetting(resourceId, 'field', facet.Id, 'RequestMappingTemplateLocation'))));
          }

          if (state.getResourceSetting(resourceId, 'field', facet.Id, 'ResponseMappingTemplateLocation')) {
            FILE_TASKS.push(call(removeSafely, 'ResponseMappingTemplateLocation', getResourcePath(templateDir, state.getResourceSetting(resourceId, 'field', facet.Id, 'ResponseMappingTemplateLocation'))));
          }
        }
      }

      for (const resolver of value) {
        if (!prevValue.find(prevResolver => prevResolver.Type === resolver.Type && prevResolver.Field === resolver.Field)) {
          FILE_TASKS.push(call(
            saveSafely,
            'RequestMappingTemplateLocation',
            `${dirname(schemaLocation)}/${resolver.Type}-${resolver.Field}-request.vm`,
            requestTemplateContents
          ));
          FILE_TASKS.push(call(
            saveSafely,
            'ResponseMappingTemplateLocation',
            `${dirname(schemaLocation)}/${resolver.Type}-${resolver.Field}-response.vm`,
            responseTemplateContents
          ));
        }
      }
    }

    if (!setting.facetType) {
      if (settingId === 'Schema' && saveSchemaInFile) {
        FILE_TASKS.push(call(saveSafely, 'SchemaLocation', schemaLocation, value));
      } else if (settingId === 'SaveSchemaInFile' && value === false && prevValue === true) {
        FILE_TASKS.push(call(removeSafely, settingId, schemaLocation));
      }
    }

    if (facetType === 'field') {
      if (settingId === 'RequestMappingTemplate' && saveRequestMappingTemplateInFile) {
        FILE_TASKS.push(call(save, { file: requestMappingTemplateLocation, contents: value }));
      } else if (settingId === 'ResponseMappingTemplate' && saveResponseMappingTemplateInFile) {
        FILE_TASKS.push(call(save, { file: responseMappingTemplateLocation, contents: value }));
      } else if (settingId === 'SaveRequestMappingTemplateInFile' && value === false && prevValue === true) {
        FILE_TASKS.push(call(removeSafely, settingId, requestMappingTemplateLocation));
      } else if (settingId === 'SaveResponseMappingTemplateInFile' && setting.value === false && prevValue === true) {
        FILE_TASKS.push(call(removeSafely, settingId, responseMappingTemplateLocation));
      }
    }
  } else if (resource.Type === 'stateMachine') {
    const definitionLocation = getResourcePath(templateDir, state.getResourceSetting(resourceId, null, null, 'DefinitionLocation'));
    const saveDefinitionInFile = state.getResourceSetting(resourceId, null, null, 'SaveDefinitionInFile');

    if (settingId === 'Definition' && saveDefinitionInFile) {
      let stringValue;
      if (definitionLocation.endsWith('.json')) {
        stringValue = JSON.stringify(value, null, 2);
      } else {
        stringValue = cfYamlParser.toString(value);
      }
      FILE_TASKS.push(call(saveSafely, 'DefinitionLocation', definitionLocation, stringValue));
    } else if (settingId === 'SaveDefinitionInFile' && value === false && prevValue === true) {
      FILE_TASKS.push(call(removeSafely, settingId, definitionLocation));
    }
  }

  // Push rename task last, so other file tasks don't lose the original source directory
  const resourceDescriptor = facetType ? `${resource.Type}_${facetType}` : resource.Type;
  if (
    resourceDescriptor in PATH_SETTINGS &&
    PATH_SETTINGS[resourceDescriptor].includes(settingId) &&
    value &&
    value !== prevValue
  ) {
    FILE_TASKS.push(call(renameSafely, state.resources, resourceId, settingId, getResourcePath(templateDir, value), getResourcePath(templateDir, prevValue)));
  }

  if (facetType) {
    state.updateFacetSetting(resourceId, facetType, facetId, settingId, value);
  } else {
    state.updateResourceSetting(resourceId, settingId, value);
  }
}

function * validateResources () {
  const state = yield select(state => state.formation.resources);
  let allErrors = [];

  /* It's possible for this saga to fire in response to a stack being loaded but
   * after the user selects a different branch. state.resources will then be
   * reset to undefined. */
  if (!state.resources) {
    return;
  }

  Object.keys(state.resources).forEach(function (resourceId) {
    try {
      state.validateResourceSettings(resourceId);
    } catch (errors) {
      allErrors.push({ resourceId, errors });
    }

    try {
      state.validateResourceIntegrations(resourceId);
    } catch (errors) {
      allErrors.push({ resourceId, errors });
    }
  });

  yield putResolve({ type: canvasTypes.UPDATE_VALIDATION, errors: allErrors, resources: state });
}

function * saveResource ({ settings }) {
  const state = yield select(state => state.formation.resources);
  yield all(settings.map(setting => call(saveResourceSetting, setting)));
  yield putResolve(canvasActions.updateResources(state));
}

function * runFileTasks () {
  const isLocalMode = yield select(state => state.currentUser.isLocalMode);

  if (isLocalMode) {
    /*
    * Array iteration methods _for some reason...babel?_ disrupt the tasks.
    * Specifically, the rename won't complete using fileTasks.map or fileTasks.forEach
    */
    for (let i = 0; i < FILE_TASKS.length; i++) {
      yield FILE_TASKS[i];
    }
    FILE_TASKS = [];
  }
}

function * bindHandlers () {
  try {
    yield all([
      takeLatest(canvasTypes.NODE_MOUSE_UP, handleVirtualNetworkPlacements),
      takeLatest(canvasTypes.WIRE_NODES, handleIntegrations),
      takeLatest(canvasTypes.CREATE_RESOURCE, handleCreateResource),
      takeLatest(canvasTypes.DELETE_SELECTION, deleteResource),
      takeLatest(canvasTypes.VALIDATE_RESOURCES, validateResources),
      takeLatest(resourceEditorTypes.SAVE_RESOURCE, saveResource),
      takeLatest(canvasTypes.UPDATE_RESOURCES, runFileTasks)
    ]);
  } finally {}
}

function * startResources () {
  yield race({
    task: call(bindHandlers),
    cancel: take(canvasTypes.STOP_RESOURCES)
  });
}

export default function * resources () {
  yield all([
    takeLatest(canvasTypes.START_RESOURCES, startResources)
  ]);
}
