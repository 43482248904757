import { putResolve, all, takeLatest, takeEvery, race, call, take, delay } from 'redux-saga/effects';
import { request } from '../utils/api';
import * as localStorage from '../utils/localStorage';
import { types } from '../actions/cms';

function * fetchContent (args) {
  try {
    while (true) {
      // Refresh sidebar content every minute
      yield putResolve({ type: types.GET_INDEX.REQUEST });
      yield delay(1 * 60 * 1000);
    }
  } finally { }
}

function * getIndex () {
  yield request(
    types.GET_INDEX,
    'GET',
    `https://${getHostname()}/cms/index.json`
  );
}

function * processIndex (action) {
  try {
    const data = typeof action.data === 'string' ? JSON.parse(action.data) : action.data;
    const contentSections = data.sections;
    const dismissedSections = JSON.parse(localStorage.getItem('stackeryCMSDismissedSections') || '{}');

    for (const sectionConfig of contentSections) {
      const isDev = sectionConfig.status === 'dev' && localStorage.getItem('featureFlag:showDevContent') === 'true';
      let isLive = sectionConfig.status !== 'dev';
      if (sectionConfig.liveAt && Date.parse(sectionConfig.liveAt)) {
        isLive = isLive && Date.now() > Date.parse(sectionConfig.liveAt);
      }

      let isDismissed = sectionConfig.dismissible && dismissedSections[sectionConfig.file];

      if (sectionConfig.file && (isDev || isLive) && !isDismissed) {
        yield putResolve({ type: types.GET_SECTION.REQUEST, config: sectionConfig });
      }
    }
  } catch (err) {
    // Error processing CMS
    console.error(err);
  }
}

function * getSection (action) {
  yield request(
    types.GET_SECTION,
    'GET',
    `https://${getHostname()}/cms/${action.config.file}`,
    null,
    { config: action.config }
  );
}

function getHostname () {
  let hostname;

  if (process.env.REACT_APP_ENV === 'prod') {
    hostname = 'app.stackery.io';
  } else if (process.env.CMS_SOURCE_OVERRIDE) {
    hostname = `${process.env.CMS_SOURCE_OVERRIDE}-app.stackery.io`;
  } else {
    hostname = `stg1-app.stackery.io`;
  }

  return hostname;
}

function * startCms () {
  yield race({
    task: call(fetchContent),
    cancel: take(types.STOP_CMS)
  });
}

export default function * cms () {
  yield all([
    takeLatest(types.START_CMS, startCms),
    takeLatest(types.GET_INDEX.REQUEST, getIndex),
    takeLatest(types.GET_INDEX.SUCCESS, processIndex),
    takeEvery(types.GET_SECTION.REQUEST, getSection)
  ]);
}
