import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import * as states from '../../constants/states';
import * as planTypes from '../../constants/planTypes';
import Link from '../core/Link';
import ButtonSet from '../core/ButtonSet';
import Spinner from '../core/Spinner';

const SwitchPlan = (props) => {
  const {
    requestedType,
    currentType,
    currentlyActive,
    features,
    totalProviders,
    totalUsers,
    paymentMechanism,
    onClick,
    onClickCancel,
    isLoading,
    isTrialEligible
  } = props;

  const planLimits =
    {
      [planTypes.DEVELOPER]: {
        stackLimit: 6,
        providersLimit: null,
        userLimit: 3
      },
      [planTypes.DEVELOPER2]: {
        stackLimit: 6,
        providersLimit: 1,
        userLimit: 1
      },
      [planTypes.PROFESSIONAL]: {
        stackLimit: null,
        providersLimit: null,
        userLimit: null
      },
      [planTypes.TEAM]: {
        stackLimit: null,
        providersLimit: 3,
        userLimit: null
      },
      [planTypes.ENTERPRISE]: {
        stackLimit: null,
        providersLimit: null,
        userLimit: null
      },
      [planTypes.PROFESSIONAL_PROMO]: {
        stackLimit: null,
        providersLimit: null,
        userLimit: null
      }
    };

  const isPaidPlan = requestedType === planTypes.PROFESSIONAL || requestedType === planTypes.TEAM;
  const isOverActiveStackLimit = planLimits[requestedType].stackLimit != null && currentlyActive > planLimits[requestedType].stackLimit;
  const isOverProviderLimit = planLimits[requestedType].providersLimit != null && totalProviders > planLimits[requestedType].providersLimit;
  const isOverUserLimit = planLimits[requestedType].userLimit != null && totalUsers > planLimits[requestedType].userLimit;

  const isOverLimit = isOverActiveStackLimit || isOverUserLimit || isOverProviderLimit;

  if (isLoading) {
    return <Spinner />;
  }

  if (requestedType === planTypes.ENTERPRISE) {
    return (
      <div>
        <h1>Switch to {features.displayName}</h1>
        <p>Contact us at <Link href='mailto:support@stackery.io'>support@stackery.io</Link> to switch to the <strong>Enterprise Plan</strong></p>
      </div>
    );
  }

  if (currentType === planTypes.ENTERPRISE) {
    return (
      <div>
        <h1>Switch from {features.displayName}</h1>
        <p>Contact us at <Link href='mailto:support@stackery.io'>support@stackery.io</Link> to switch from the <strong>Enterprise Plan</strong></p>
      </div>
    );
  }

  if (paymentMechanism === 'marketplace') {
    return (
      <div>
        <h1>Switch Current Plan</h1>
        <p>You can unsubscribe from your plan from the <Link href='https://console.aws.amazon.com/marketplace/home/subscriptions' target='_blank'>AWS Marketplace.</Link></p>
      </div>
    );
  }

  // Payment is required if no cc is linked
  if (isPaidPlan && !isTrialEligible) {
    return (
      <div>
        <div>
          <h1>Upgrade to Stackery {features.displayName} Plan</h1>
          <p>Enter a credit card to upgrade your plan
          {requestedType === planTypes.PROFESSIONAL &&
            <Fragment>
              {' '}or <Link href='https://aws.amazon.com/marketplace/pp/B07VG9YGTY' target='_blank'>subscribe through the AWS Marketplace</Link>
            </Fragment>
          }
          .</p>
        </div>
      </div>
    );
  }

  // Start trial
  if (isPaidPlan && isTrialEligible) {
    return (
      <div>
        <h1>Switch to {features.displayName} Trial</h1>
        <div>
          <p>Thanks for selecting the {features.displayName} Trial Plan! Once activated, your trial will expire in 7 days.</p>
          <p>To continue on this plan beyond the trial, come back here to add payment. Otherwise you'll be automatically downgraded to the Developer Plan.</p>
          <p>
            <strong>Each team is eligible for one trial.</strong> Do you want to continue switching to the {features.displayName} Trial plan?
          </p>
          <ButtonSet
            primaryButton={{
              text: 'Confirm',
              type: 'submit',
              onClick: onClick,
              isLoading: props.state === states.LOADING
            }}
            secondaryButton={{
              text: 'Cancel',
              onClick: onClickCancel
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <div>
      <h1>Switch to {features.displayName}</h1>
      <Fragment>
        {isOverLimit &&
          <div>
            <h2>Exceeded Plan Limits</h2>
            <p>The following issues require your attention before switching to the {features.displayName} plan.</p>
            {isOverActiveStackLimit &&
              <p>Active Stacks: <strong>{currentlyActive}</strong> (Limit: {planLimits[requestedType].stackLimit})</p>
            }
            {isOverProviderLimit &&
              <p>Linked AWS Accounts: <strong>{totalProviders}</strong> (Limit: {planLimits[requestedType].providersLimit})</p>
            }
            {isOverUserLimit &&
              <p>Shared Account Users: <strong>{totalUsers}</strong> (Limit: {planLimits[requestedType].userLimit})</p>
            }

            {!isOverActiveStackLimit && !isOverProviderLimit && isOverUserLimit
              ? <Fragment>
                <p>Some users will be deactivated if you downgrade. Do you want to continue?</p>
                <ButtonSet
                  primaryButton={{
                    text: 'Confirm',
                    type: 'submit',
                    onClick: onClick,
                    isLoading: props.state === states.LOADING
                  }}
                  secondaryButton={{
                    text: 'Cancel',
                    onClick: onClickCancel
                  }}
                />
              </Fragment>
              : <ButtonSet
                primaryButton={{
                  text: 'Okay',
                  onClick: props.onClickCancel
                }}
                secondaryButton={{
                  text: 'Cancel',
                  onClick: onClickCancel
                }}
                />
            }
          </div>
        }
        {!isOverLimit &&
          <div>
            <p>Certain aspects of your account will be affected when switching to the {features.displayName} plan.</p>
            <p>Are you sure you want to continue?</p>
            <ButtonSet
              primaryButton={{
                text: 'Confirm',
                type: 'submit',
                onClick: onClick,
                isLoading: props.state === states.LOADING
              }}
              secondaryButton={{
                text: 'Cancel',
                onClick: onClickCancel
              }}
            />
          </div>
        }
      </Fragment>
    </div>
  );
};

SwitchPlan.propTypes = {
  requestedType: PropTypes.string.isRequired,
  currentType: PropTypes.string.isRequired,
  currentlyActive: PropTypes.number,
  features: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onClickCancel: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isTrialEligible: PropTypes.bool.isRequired
};

export default SwitchPlan;
