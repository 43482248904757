import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import moment from 'moment';
import apiActions from '../../actions/api';
import appActions from '../../actions/app';
import * as states from '../../constants/states';
import Tokens from './Tokens';

const mapStateToProps = () => {
  return createSelector(
    (appState) => appState.tokens,
    (tokens) => ({
      tokens,
      timestampFormat: 'MM/DD/YY hh:mm A',
      isLoading: tokens.state === states.NEW || tokens.state === states.LOADING,
      isEmpty: tokens.state === states.OKAY && tokens.data.length === 0
    })
  );
};

const mapDispatchToProps = {
  getCliTokens: apiActions.getCliTokens,
  deleteCliToken: apiActions.deleteCliToken,
  showCreateTokenModal: appActions.showCreateTokenModal,
  showDeleteTokenModal: appActions.showDeleteTokenModal
};

class TokensContainer extends Component {
  constructor (props) {
    super(props);

    this.state = {
      searchTerm: null,
      filteredTokens: props.tokens.data
    };

    this.handleCreateClick = this.handleCreateClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.filterTokens = this.filterTokens.bind(this);
  }

  componentDidMount () {
    this.props.getCliTokens();
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.searchTerm !== prevState.searchTerm) {
      this.filterTokens();
    }

    if (this.props.tokens.data !== prevProps.tokens.data && this.props.tokens.state === states.OKAY) {
      this.setState({ filteredTokens: this.props.tokens.data }, this.filterTokens);
    }
  }

  filterTokens () {
    const searchTerm = this.state.searchTerm ? this.state.searchTerm.toLowerCase() : '';

    this.setState({
      filteredTokens: this.props.tokens.data.filter((token) => {
        const matchesId = token.id.toLowerCase().includes(searchTerm);
        const matchesDescription = token.description.toLowerCase().includes(searchTerm);
        const matchesCreatedAt = moment(token.createdAt).format(this.props.timestampFormat).includes(searchTerm);

        return matchesId || matchesDescription || matchesCreatedAt;
      })
    });
  }

  handleSearch (event) {
    this.setState({ searchTerm: event.currentTarget.value });
  }

  handleCreateClick () {
    this.props.showCreateTokenModal();
  }

  handleDeleteClick (token) {
    this.props.showDeleteTokenModal({ token, onSubmit: this.props.deleteCliToken });
  }

  render () {
    return (
      <Tokens
        {...this.props}
        {...this.state}
        className={this.props.className}
        onSearch={this.handleSearch}
        onCreateClick={this.handleCreateClick}
        onDeleteClick={this.handleDeleteClick}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TokensContainer);
