import errorCodes from '../constants/errorCodes';

export default (error) => {
  let message = error.message;
  let code;

  // check if error has an error object as a message
  let errorObject;
  try {
    errorObject = error.message && JSON.parse(error.message);
  } catch (err) {}

  if (errorObject) {
    // if custom code is present, map to custom message
    code = errorObject.code || null;
    if (code) {
      const resource = errorObject.resource ? ` ${errorObject.resource.split('@').pop()}` : '';
      const provider = errorObject.provider ? `, ${errorObject.provider}` : '';
      switch (code) {
        case errorCodes.GIT_AUTH_READ_FAILURE:
          message = `Git token authorization read failure, you do not have access to read this repo.${resource}`;
          break;
        case errorCodes.GIT_AUTH_WRITE_FAILURE:
          message = `Git token authorization write failure, you do not have access to write to this repo.${resource}`;
          break;
        case errorCodes.GIT_BAD_TOKEN:
          message = errorObject.message || `Bad git token. Re-authorize Stackery with your git provider${provider}`;
          break;
        case errorCodes.GIT_NO_TOKEN:
          message = `No git token was found for your Stackery account. Authorize Stackery with your git provider${provider}.`;
          break;
        case errorCodes.GIT_REPO_NOT_FOUND:
          message = `Failed to get ${resource} from your git provider${provider}`;
          break;
        default:
      }
    } else {
      message = errorObject.message;
    }
  }

  return {
    code,
    message
  };
};
