import makeActions from '../utils/makeActions';

export const types = {
  GET_REPO_CONTENTS: makeActions('API', 'GET_REPO_CONTENTS')
};

const actions = {
  getRepoContents: (owner, repo, file, ref) => ({
    type: types.GET_REPO_CONTENTS.REQUEST,
    owner,
    repo,
    file,
    ref
  })
};

export default actions;
