import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import cfYamlParser from 'cf-yaml-parser';
import { push } from 'connected-react-router';
import selectors from '../../selectors';
import * as file from '../../constants/file';
import * as states from '../../constants/states';
import * as modes from '../../constants/modes';
import getQueryValue from '../../utils/getQueryValue';
import getStackFormat from '../../utils/getStackFormat';
import * as localStorage from '../../utils/localStorage';
import appActions from '../../actions/app';
import workspaceActions from '../../actions/workspace';
import canvasActions from '../../actions/canvas';
import Workspace from './Workspace';

const mapStateToProps = () => {
  return createSelector(
    selectors.nodeTypes,
    (appState) => appState.auth,
    (appState) => appState.workspace,
    (appState) => appState.formation,
    (appState) => appState.stack,
    (appState) => appState.currentUser,
    (nodeTypes, auth, workspace, formation, stack, currentUser) => {
      return {
        nodeTypes,
        auth,
        workspace,
        formation,
        stack
      };
    }
  );
};

const mapDispatchToProps = {
  push,
  setStackMode: appActions.setStackMode,
  setStackIsMultiFile: appActions.setStackIsMultiFile,
  startResources: canvasActions.startResources,
  stopResources: canvasActions.stopResources,
  startWorkspace: workspaceActions.startWorkspace,
  stopWorkspace: workspaceActions.stopWorkspace,
  parseStackTemplate: appActions.parseStackTemplate,
  notifyUser: appActions.notifyUser,
  read: workspaceActions.read,
  save: workspaceActions.save,
  load: workspaceActions.load,
  ignoreMessage: workspaceActions.ignoreMessage,
  parseStackContents: appActions.parseStackContents
};

class WorkspaceContainer extends Component {
  componentDidMount () {
    this.props.setStackMode(modes.LOCAL);
    this.props.setStackIsMultiFile(true);
    this.props.startWorkspace(
      getQueryValue(window.location.search, 'port'),
      getQueryValue(window.location.search, 'templatedir'),
      getQueryValue(window.location.search, 'templatepath'),
      getQueryValue(window.location.search, 'secret'),
      getQueryValue(window.location.search, 'host')
    );

    this.props.startResources();
  }

  componentWillUnmount () {
    this.props.stopWorkspace();
    this.props.stopResources();
  }

  componentDidUpdate (prevProps) {
    const {
      workspace,
      formation,
      stack
    } = this.props;

    const {
      workspace: prevWorkspace
    } = prevProps;

    // Get initial template from local file
    if (prevWorkspace.state === states.NEW && workspace.state === states.OKAY) {
      this.props.read(workspace.templatePath);
    }

    if (!prevWorkspace.message.type && workspace.message.type &&
      workspace.message.type !== file.REMOVE && workspace.message.type !== file.REMOVE_RENAME) {
      this.props.read(workspace.message.file);
    } else if (prevWorkspace.message.type) {
      // Reset message.type since read() won't be doing that.
      this.props.ignoreMessage();
    }

    // Save stack template to local file
    if (formation.state === states.OKAY && !prevWorkspace.isDiverged && workspace.isDiverged && workspace.isSaveNeeded) {
      this.props.save(workspace.templatePath, cfYamlParser.toString(formation.resources.getTemplate(stack.isMultiFile)));
    }

    // Update stack template from local file
    if (prevWorkspace.state === states.LOADING && workspace.state === states.OKAY && workspace.isDiverged) {
      this.props.load(workspace.contents, getStackFormat(workspace.contents));
    }

    // Store local editing query params in localStorage
    if (stack.name && stack.owner && workspace.port && workspace.port !== prevWorkspace.port) {
      localStorage.setItem(`stackery-edit-${stack.owner}${stack.name}`, `?port=${workspace.port}&secret=${workspace.secret}&templatepath=${workspace.templatePath}`);
    }

    // Remove local editing query params from localStorage & notify user if connection was lost
    if (prevWorkspace.state !== workspace.state && workspace.state === states.DISCONNECTED) {
      localStorage.removeItem(`stackery-edit-${stack.owner}${stack.name}`);
      this.props.notifyUser(workspace.error, 'error');
      this.props.push(window.location.pathname);
    }
  }

  render () {
    return (
      <Workspace {...this.props} />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceContainer);
