import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import * as states from '../../constants/states';
import AddButton from '../core/AddButton';
import Spinner from '../core/Spinner';
import DeploymentPipeline from './DeploymentPipeline';

const DeploymentPipelines = props => {
  const {
    deploymentPipelines,
    history,
    isPipelinesLoading,
    isAddPipelineEnabled
  } = props;

  // Check for a hash and if an element with that id exists then scroll to it
  React.useEffect(() => {
    const hash = history.location.hash;
    const hashElement = hash ? document.getElementById(hash.substr(1)) : undefined;

    if (hashElement) {
      hashElement.scrollIntoView();
      history.replace(history.location.pathname);
    }
  }, [history.location.hash, deploymentPipelines.data]);

  return (
    <Fragment>
      {props.isPipelinesLoading &&
        <Spinner position='static' />
      }

      <TransitionGroup component={null}>
        {!isPipelinesLoading && deploymentPipelines.data.sort((a, b) => a.name.localeCompare(b.name)).map(deploymentPipeline => (
          <CSSTransition
            key={deploymentPipeline.id}
            classNames={{
              enter: 'enter',
              enterActive: 'enterActive',
              exit: 'exit',
              exitActive: 'exitActive'
            }}
            timeout={250}
          >
            <DeploymentPipeline
              deploymentPipeline={deploymentPipeline}
              isLoading={deploymentPipeline.settingsState === states.LOADING || deploymentPipeline.statusState === states.LOADING}
              isSaving={deploymentPipeline.savingState === states.LOADING}
              {...props}
            />
          </CSSTransition>
        ))}
      </TransitionGroup>

      {!isPipelinesLoading && isAddPipelineEnabled &&
        <AddButton text='Add Deployment Pipeline' onClick={props.onCreateClick} />
      }
    </Fragment>
  );
};

DeploymentPipelines.propTypes = {
  deploymentPipelines: PropTypes.object,
  isPipelinesLoading: PropTypes.bool,
  isAddPipelineEnabled: PropTypes.bool
};

export default DeploymentPipelines;
