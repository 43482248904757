export const makeOldUrl = (stackName, changeSet, region) => {
  return changeSet
    ? `https://${region}.console.aws.amazon.com/cloudformation/home?region=${region}#/changeset/detail?changeSetId=${encodeURIComponent(changeSet)}&stackId=${encodeURIComponent(stackName)}`
    : `https://${region}.console.aws.amazon.com/cloudformation/home?region=${region}#/stack/detail?stackId=${stackName}`;
};

export const makeNewUrl = (stackName, changeSet, region) => {
  return `https://${region}.console.aws.amazon.com/cloudformation/home?region=${region}#/stacks/${stackName}/changesets/${encodeURIComponent(changeSet)}/changes`;
};

export default (stackName, changeSet, region) => makeOldUrl(stackName, changeSet, region);

/* NOTE:
using CF Console "old" w/ "new" url will redirect to your list of stacks - FIXED by adding `&stackId={stackName}
using CF Console "new" w/ "old" url will redirect to the changeset detail page but the surrounding stack (context/breadcrumb) will be `undefined`
*/
