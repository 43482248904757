import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { getStartOfCurrentMonth, getEndOfCurrentMonth } from '../../utils/getDate';
import sortStackEvents from '../../utils/sortStackEvents';
import apiActions from '../../actions/api';
import appActions from '../../actions/app';
import * as states from '../../constants/states';
import Deployments from './Deployments';

const mapStateToProps = () => {
  return createSelector(
    (appState) => appState.account,
    (appState) => appState.currentUser,
    (appState) => appState.deploymentPipelines,
    (appState) => appState.gitProviders,
    (account, currentUser, deploymentPipelines, gitProviders) => {
      return {
        owner: currentUser.org,
        gitProviders,
        deploymentPipelines,
        activeStacks: sortStackEvents(account.activeStacks.data),
        isAddPipelineEnabled: account.plan.pipelineLimit === null || deploymentPipelines.data.length < account.plan.pipelineLimit,
        isPipelinesLoading: deploymentPipelines.state === states.NEW || deploymentPipelines.state === states.LOADING,
        isActivityLoading: account.activeStacks.state === states.NEW || account.activeStacks.state === states.LOADING,
        isActivityLoaded: account.activeStacks.state === states.OKAY,
        isActivityEmpty: account.activeStacks.data.length === 0
      };
    }
  );
};

const mapDispatchToProps = {
  getActiveStacks: apiActions.getActiveStacks,
  getDeploymentPipelines: apiActions.getDeploymentPipelines,
  getDeploymentPipelineSettings: apiActions.getDeploymentPipelineSettings,
  getDeploymentPipelineStatus: apiActions.getDeploymentPipelineStatus,
  promoteDeploymentPipeline: apiActions.promoteDeploymentPipeline,
  retryDeploymentPipeline: apiActions.retryDeploymentPipeline,
  showCreateDeploymentPipelineModal: appActions.showCreateDeploymentPipelineModal,
  showDeleteDeploymentPipelineModal: appActions.showDeleteDeploymentPipelineModal,
  showEnvironmentGitDiffModal: appActions.showEnvironmentGitDiffModal
};

class DeploymentsContainer extends Component {
  constructor (props) {
    super(props);

    this.state = {
    };

    this.handleDiffClick = this.handleDiffClick.bind(this);
    this.handleCreateClick = this.handleCreateClick.bind(this);
  }

  componentDidMount () {
    this.props.getDeploymentPipelines();
    this.props.getActiveStacks(getStartOfCurrentMonth(), getEndOfCurrentMonth());
  }

  handleDiffClick (pipelineId, stack, stageIndex) {
    const deploymentPipeline = this.props.deploymentPipelines.data.find(pipeline => pipeline.id === pipelineId);

    if (!deploymentPipeline) { return; }

    const stages = deploymentPipeline.settings.stages;

    this.props.showEnvironmentGitDiffModal({ owner: this.props.owner, stack, environment1: stages[stageIndex].environment, environment2: stages[stageIndex + 1].environment });
  }

  handleCreateClick (event) {
    this.props.showCreateDeploymentPipelineModal();
  }

  render () {
    return (
      <Deployments
        {...this.props}
        {...this.state}
        onDiffClick={this.handleDiffClick}
        onCreateClick={this.handleCreateClick}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeploymentsContainer);
