import React from 'react';
import PropTypes from 'prop-types';
import * as deploymentStates from '../../constants/deploymentStates';
import Tooltip from '../core/Tooltip';
import Button from '../core/Button';

const getTooltip = (props) => {
  let tooltip;

  if (!props.deploymentStatus && props.isLinkingAwsAccount) {
    return 'Please wait while your AWS account is linked...';
  }

  if (props.isLoading) {
    return 'Please wait while deployment is prepared...';
  }

  switch (props.deploymentStatus) {
    case deploymentStates.QUEUED:
    case deploymentStates.PREPARING:
    case deploymentStates.CREATING_CHANGE_SET:
      tooltip = props.isLegacyDeploy ? 'Please wait until currently preparing deployment finishes...' : null;
      break;

    case deploymentStates.DEPLOYING:
      tooltip = 'Please wait until currently prepared deployment finishes deploying...';
      break;

    case deploymentStates.FAILING:
      tooltip = 'Please wait until currently prepared deployment finishes rolling Back...';
      break;

    default:
      tooltip = 'Package Function code<br />and prepare AWS<br />CloudFormation templates';
      break;
  }

  return tooltip;
};

const isDisabled = (props) => {
  return (
    props.isLinkingAwsAccount ||
    (props.isLegacyDeploy &&
      (
        props.deploymentStatus === deploymentStates.QUEUED ||
        props.deploymentStatus === deploymentStates.PREPARING ||
        props.deploymentStatus === deploymentStates.CREATING_CHANGE_SET ||
        props.deploymentStatus === deploymentStates.DEPLOYING ||
        props.deploymentStatus === deploymentStates.FAILING
      )
    )
  );
};

const PrepareButton = props => {
  return (
    <Tooltip message={getTooltip(props)} place='right'>
      <Button
        id={props.id}
        onClick={props.onPrepareClick}
        isLoading={props.isLoading || props.isLinkingAwsAccount}
        isDisabled={isDisabled(props)}
      >
        Prepare Deployment
      </Button>
    </Tooltip>
  );
};

PrepareButton.propTypes = {
  deploymentStatus: PropTypes.string,
  onPrepareClick: PropTypes.func.isRequired
};

export default PrepareButton;
