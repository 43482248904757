import { types as appTypes } from '../actions/app';
import { types as apiTypes } from '../actions/api';
import { types as iotNotificationsTypes } from '../actions/iotNotifications';
import * as states from '../constants/states';
import * as deploymentStates from '../constants/deploymentStates';

export const initialState = {
  state: states.NEW,
  owner: undefined,
  stackName: undefined,
  environment: undefined,
  stackEnvironmentId: undefined,
  preparedDeployment: undefined,
  currentDeployment: undefined,
  selectedDeploymentId: undefined,
  selectedEphemeralId: undefined,
  previousDeployments: [],
  deployments: [],
  hasNewDeployment: false,
  prepareError: undefined,
  didPrepareFail: false
};

const addOrUpdateDeployment = (deployments, newDeployment) => {
  return deployments.map(deployment => {
    if (deployment.id === newDeployment.id) {
      deployment = {
        ...deployment,
        ...newDeployment
      };
    } else if (newDeployment.status === deploymentStates.DEPLOYED && deployment.status === deploymentStates.DEPLOYED) {
      // Set outgoing deployment status to 'undeployed' if newDeployment status === 'deployed'
      deployment = {
        ...deployment,
        status: deploymentStates.UNDEPLOYED
      };
    }

    return deployment;
  });
};

const getPreviousDeployments = (deployments, currentDeployment = {}, selectedEphemeralId) => {
  return deployments.filter((deployment) => {
    const deploymentConditions = selectedEphemeralId ? deployment.ephemeralId === selectedEphemeralId : !deployment.ephemeralId;
    return (deployment.id !== currentDeployment.id) &&
    (deployment.status === deploymentStates.DEPLOYED || deployment.status === deploymentStates.UNDEPLOYED) &&
    deploymentConditions;
  });
};

const getCurrentDeployment = (deployments, selectedEphemeralId) => {
  if (selectedEphemeralId) {
    return deployments.find(deployment => deployment.status === deploymentStates.DEPLOYED && deployment.ephemeralId === selectedEphemeralId);
  }

  return deployments.find(deployment => deployment.status === deploymentStates.DEPLOYED && !deployment.ephemeralId);
};

export default (state = { ...initialState }, action) => {
  let deployments;
  let currentDeployment;
  let preparedDeployment;
  let previousDeployments;

  switch (action.type) {
    case appTypes.SET_EPHEMERAL_ID:
      return {
        ...state,
        selectedEphemeralId: action.ephemeralId
      };
    case appTypes.SELECT_STACK:
      if (action.owner === state.owner && action.stackName === state.stackName) { return state; }
      return {
        ...state,
        state: states.NEW,
        owner: action.owner,
        stackName: action.name
      };
    case appTypes.DESELECT_STACK:
      return {
        ...initialState
      };
    case appTypes.SELECT_STACK_ENVIRONMENT:
      if (action.environment === state.environment) return state;
      return {
        ...state,
        state: states.NEW,
        environment: action.environment,
        deployments: []
      };
    case apiTypes.GET_DEPLOYMENTS.REQUEST:
      return {
        ...initialState,
        selectedEphemeralId: state.selectedEphemeralId,
        owner: state.owner,
        environment: state.environment,
        stackName: state.stackName,
        state: states.LOADING
      };
    case apiTypes.GET_DEPLOYMENTS.SUCCESS:
      // TODO: Using the most recent history works in most cases, but the better way to do this
      //       would be to have the backend provide this, as there are cases where the user
      //       manually un-deploys a stack where this heuristic will end up displaying the
      //       wrong information.
      deployments = action.data.deployments.concat(action.data.ephemeral);
      preparedDeployment = action.data.prepared;
      currentDeployment = getCurrentDeployment(deployments, state.selectedEphemeralId);
      previousDeployments = getPreviousDeployments(deployments, currentDeployment, state.selectedEphemeralId);

      return {
        ...state,
        state: states.OKAY,
        stackEnvironmentId: action.data.stackEnvironmentId,
        preparedDeployment: preparedDeployment,
        currentDeployment: currentDeployment,
        previousDeployments: previousDeployments,
        deployments: [...state.deployments, ...deployments]
      };
    case apiTypes.GET_DEPLOYMENTS.FAILURE:
      return {
        ...state,
        state: states.FAILED
      };
    case appTypes.SELECT_DEPLOYMENT:
      if (action.id === state.selectedDeploymentId) { return state; }
      return {
        ...state,
        selectedDeploymentId: action.id
      };
    case appTypes.DESELECT_DEPLOYMENT:
      return {
        ...state,
        selectedDeploymentId: undefined,
        selectedEphemeralId: undefined
      };
    case apiTypes.PREPARE.REQUEST:
      return {
        ...state,
        didPrepareFail: false,
        prepareError: undefined
      };
    case apiTypes.PREPARE.SUCCESS:
      return {
        ...state,
        preparedDeployment: action.data,
        deployments: [action.data, ...state.deployments]
      };
    case apiTypes.PREPARE.FAILURE:
      return {
        ...state,
        didPrepareFail: true,
        prepareError: action.error.message.message
      };
    case appTypes.VIEW_NEW_DEPLOYMENT:
      return {
        ...state,
        hasNewDeployment: false
      };
    case iotNotificationsTypes.DEPLOYMENT:
      if (
        action.owner !== state.owner ||
        action.stackName !== state.stackName ||
        action.environmentName !== state.environment
      ) { return state; }

      deployments = addOrUpdateDeployment(state.deployments, action);
      preparedDeployment = action.status === deploymentStates.DEPLOYED ? undefined : action;
      currentDeployment = getCurrentDeployment(deployments);
      previousDeployments = getPreviousDeployments(deployments, currentDeployment);

      return {
        ...state,
        hasNewDeployment: state.hasNewDeployment || currentDeployment !== state.currentDeployment,
        preparedDeployment: preparedDeployment,
        previousDeployments: previousDeployments,
        currentDeployment: currentDeployment,
        deployments: [action, ...state.deployments],
        didPrepareFail: (action.status === deploymentStates.FAILED && state.preparedDeployment && state.preparedDeployment.status !== deploymentStates.FAILING)
      };
    default:
      return state;
  }
};
