import React from 'react';
import CodeBlock from '../core/CodeBlock';
import Link from '../core/Link';

const UpdatePipelineDeployments = (props) => {
  const deployerRoleAction = props.isDeployerRoleEnabled ? 'disable' : 'enable';
  return (
    <div>
      <h1>Stackery Pipeline Deployments</h1>
      <p>To {deployerRoleAction} Pipeline Deployments on this AWS account, use the <a href='https://docs.stackery.io/docs/using-stackery/cli/' target='_blank' title='Stackery CLI documentation' rel='noopener noreferrer'>Stackery CLI</a> command:</p>
      <CodeBlock
        lines={[
          `stackery aws update-role --aws-profile <profile> --deployments ${deployerRoleAction}`
        ]}
      />
      {props.onReload &&
        <p>After enabling Pipeline Deployments with Stackery CLI, <Link onClick={props.onReload}>click here to refresh</Link>.</p>
      }
    </div>
  );
};

export default UpdatePipelineDeployments;
