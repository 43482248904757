import { types } from '../actions/resourceEditor';
import { types as canvasTypes } from '../actions/canvas';
import { types as appTypes } from '../actions/app';
import { types as apiTypes } from '../actions/api';
import * as definitions from '../resources/definitions';

export const initialState = {
  id: null,
  facetType: null,
  facetId: null,
  name: null,
  settings: null,
  settingTypes: null,
  resourceType: null
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case types.EDIT_RESOURCE:
      let resourceDefinition = definitions[action.format].ResourceTypes[action.resource.Type];

      /* If this is an implicit resource that should be upgraded to an explicit
       * resource, use the explicit resource definition */
      if (resourceDefinition.ExplicitType) {
        resourceDefinition = definitions[action.format].ResourceTypes[resourceDefinition.ExplicitType];
      }

      let settings = action.resource.Settings;
      let settingTypes = resourceDefinition.Settings;

      if (action.facetType) {
        const facet = action.resource.Facets[action.facetType].find(facet => facet.Id === action.facetId);

        settings = facet.Settings || {};

        if (resourceDefinition.FacetSettings && action.facetType in resourceDefinition.FacetSettings) {
          settingTypes = resourceDefinition.FacetSettings[action.facetType];
        } else {
          settingTypes = {};
        }
      }

      return {
        ...initialState,
        id: action.resource.Id,
        facetType: action.facetType,
        facetId: action.facetId,
        name: action.resource.Name,
        settings: { ...settings },
        settingTypes: settingTypes,
        resourceType: action.resource.Type
      };

    // TODO: rename UPDATE_RESOURCE_SETTING
    case types.UPDATE_SETTING:
      return {
        ...state,
        settings: {
          ...state.settings,
          [action.settingId]: action.value
        }
      };

    case types.CANCEL:
    case canvasTypes.CLEAR_SELECTION:
    case canvasTypes.DESELECT_NODE:
    case canvasTypes.SELECT_NODES:
    case canvasTypes.UPDATE_NODE:
    case appTypes.DESELECT_STACK:
    case appTypes.DESELECT_STACK_RESOURCE:
    case apiTypes.GET_STACK_CONTENTS.REQUEST:
      return initialState;

    default:
      return state;
  }
};
