import React from 'react';
import Link from '../core/Link';
import PropTypes from 'prop-types';

const RowHeader = (props) => {
  const {
    stack,
    owner
  } = props;

  if (!stack.active) {
    return <span>{stack.stackName}-{stack.environmentName}</span>;
  }

  return (
    <Link to={`/stacks/${owner}/${stack.stackName}/view/${stack.environmentName}`}>{stack.stackName}-{stack.environmentName}</Link> 
  )
};

RowHeader.propTypes = {
  stack: PropTypes.object.isRequired,
  owner: PropTypes.string.isRequired,
  context: PropTypes.string
}

export default RowHeader;