import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import FieldSet from '../core/FieldSet';
import Input from '../core/Input';
import Select from '../core/Select';
import style from './SearchFilter.css';

const SearchFilter = props => {
  const {
    selectName,
    searchName,
    filterName,
    defaultFilterValue,
    filterOptions,
    filterFieldset,
    onSearchChange,
    onFilterChange
  } = props;

  return (
    <div className={classnames(style.container, filterOptions && style.hasFilter, filterFieldset && style.hasFieldset)} role='search'>
      <Input name={searchName} placeholder={props.placeholder} className={style.input} onChange={onSearchChange} />
      {filterOptions &&
        <Select name={selectName} className={style.select} onChange={onFilterChange} defaultValue={defaultFilterValue}>
          {filterOptions.map(option => {
            return <option key={option.value} value={option.value}>{option.label}</option>;
          })}
        </Select>
      }
      {filterFieldset &&
        <FieldSet className={style.fieldset}>
          {filterFieldset.map((option, i) => {
            return (
              <Input
                key={`${option.value}-${i}`}
                name={filterName}
                type={props.filterFieldsetType}
                value={option.value}
                label={option.label}
                inlineField
                checked={props.filterFieldsetDefaultChecked ? props.filterFieldsetDefaultChecked === option.value : undefined}
                onChange={onFilterChange}
              />
            );
          })}
        </FieldSet>
      }
    </div>
  );
};

SearchFilter.defaultProps = {
  placeholder: 'Search',
  filterFieldsetType: 'checkbox'
};

SearchFilter.propTypes = {
  placeholder: PropTypes.string,
  selectName: PropTypes.string,
  searchName: PropTypes.string,
  defaultFilterValue: PropTypes.string,
  filterOptions: PropTypes.array,
  filterFieldset: PropTypes.array,
  filterFieldsetType: PropTypes.oneOf(['checkbox', 'radio']),
  filterFieldsetDefaultChecked: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  onSearchChange: PropTypes.func,
  onFilterChange: PropTypes.func
};

export default SearchFilter;
