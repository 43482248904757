import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  spaceWidth,
  spaceHeight,
  gridSize
} from '../../constants/canvas';
import Nodes from './Nodes';
import AddNodeButton from './AddNodeButton';
import MetricsToolbar from './MetricsToolbar';
import RenderError from './RenderError';
import Spinner from '../core/Spinner';
import style from './Canvas.css';

const Canvas = (props) => {
  const {
    canvas,
    nodes,
    resources,
    templateEditor,
    cloudwatch,
    wiring,
    isEditing,
    isLoaded,
    isFailed,
    showMetricsToolbar,
    connectDropTarget,
    getMetrics,
    resetStackTemplate,
    onKeyDown,
    onKeyUp,
    onMouseMove,
    onMouseUp,
    onMouseDown,
    nodeTypes,
    format
  } = props;

  return connectDropTarget(
    <div className={style.container} onKeyDown={onKeyDown} onKeyUp={onKeyUp} id='canvasContainer'>
      <Spinner loaded={isLoaded || isFailed} backgroundColor='transparent' />
      <div id='canvas' className={style.scrollArea} tabIndex='0'>
        <svg
          className={style.svg}
          width={spaceWidth * canvas.scale}
          height={spaceHeight * canvas.scale}
        >
          <g
            transform={`scale(${canvas.scale})`}
            onMouseDown={onMouseDown}
            onMouseMove={onMouseMove}
            onMouseUp={onMouseUp}
          >
            <rect
              className={classNames(style.canvas, { [style.readOnly]: !isEditing })}
              width={spaceWidth}
              height={spaceHeight}
            />
            <g>
              {[...Array(spaceWidth / gridSize + 1)].map((_, i) =>
                <line
                  key={i}
                  className={classNames({ [style.grid]: isEditing })}
                  x1='0'
                  x2={spaceWidth}
                  y1={(i + 1) * gridSize}
                  y2={(i + 1) * gridSize}
                  strokeWidth='1px'
                />
              )}
              {[...Array(spaceHeight / gridSize + 1)].map((_, i) =>
                <line
                  key={i}
                  className={classNames({ [style.grid]: isEditing })}
                  x1={(i + 1) * gridSize}
                  x2={(i + 1) * gridSize}
                  y1='0'
                  y2={spaceHeight}
                  strokeWidth='1px'
                />
              )}
            </g>
            {isLoaded &&
              <Nodes
                nodes={nodes}
                resources={resources}
                format={format}
                editable={isEditing}
                nodeTypes={nodeTypes.types}
                wiring={wiring}
              />
            }
          </g>
        </svg>
        {isLoaded && isEditing && !isFailed &&
          <AddNodeButton
            onClick={props.openPalette}
            isPaletteOpen={canvas.paletteOpen}
            isDisabled={!templateEditor.isTemplateValid}
            id={'stack-add-resource'}
          />
        }
        {showMetricsToolbar &&
          <MetricsToolbar
            cloudwatch={cloudwatch}
            onRangeClick={getMetrics}
          />
        }
      </div>

      {isEditing && templateEditor.error &&
        <RenderError
          templateError={templateEditor.error}
          onResetTemplate={templateEditor.validTemplate !== undefined ? resetStackTemplate : null}
        />
      }
    </div>
  );
};

Canvas.propTypes = {
  canvas: PropTypes.object.isRequired,
  nodes: PropTypes.array.isRequired,
  resources: PropTypes.object.isRequired,
  cloudwatch: PropTypes.object.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isLoaded: PropTypes.bool.isRequired,
  isFailed: PropTypes.bool.isRequired,
  showMetricsToolbar: PropTypes.bool.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  getMetrics: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  onMouseMove: PropTypes.func.isRequired,
  onMouseUp: PropTypes.func.isRequired,
  onMouseDown: PropTypes.func.isRequired,
  nodeTypes: PropTypes.object.isRequired
};

export default Canvas;
