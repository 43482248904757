import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Link from '../core/Link';
import Icon from '../core/Icon';
import Nav from './Nav';
import UserNav from './UserNav';
import UserNotificationsMenuContainer from '../userNotifications/UserNotificationsMenuContainer';
import style from './Header.css';

const Header = props => {
  return (
    <header className={classnames(
      style.container,
      props.isImpersonating && style.isImpersonating,
      props.isAuthenticated && style.isAuthenticated
    )}>
      <Link className={style.logoLink} to='/'>
        <Icon name='stackery-horizontal' className={style.logo} />
      </Link>
      {props.isAuthenticated &&
        <Fragment>
          <Nav />
          <UserNotificationsMenuContainer />
          <UserNav
            currentUser={props.currentUser}
            onSignOut={props.signOut}
          />
        </Fragment>
      }
      <Link extraClassName={style.docsLink} href='//docs.stackery.io/' text='Docs' target='_blank' />
    </header>
  );
};

Header.propTypes = {
  isImpersonating: PropTypes.bool.isRequired
};

export default Header;
