import React from 'react';
import PipelineLink from './PipelineLink';
import StackLink from './StackLink';
import style from './Activity.css';

const PipelineEnabledEvent = (props) => {
  const stack = props.context === 'stacks' ? <em>{props.stackName}</em> : <StackLink {...props} />;
  const pipeline = props.context === 'stacks' ? <em>{props.pipelineName} Pipeline</em> : <PipelineLink {...props} />;

  return <span><span className={style.capitalize}>{pipeline}</span> enabled on {stack} by {props.userEmail}</span>;
};

export default PipelineEnabledEvent;
