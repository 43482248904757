import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { all, putResolve, select, delay } from 'redux-saga/effects';
import { types as authTypes } from '../actions/auth';
import * as states from '../constants/states';

const Pool = new CognitoUserPool(
  JSON.parse(process.env.REACT_APP_USER_POOL_DATA)
);

function * pollUserSession () {
  while (true) {
    yield delay(10000);
    const auth = yield select(state => state.auth);

    // If app is in an authorized state but the user auth data
    // in local storage is no longer available, the user has signed
    // out from another window
    if (auth.state === states.OKAY && auth.token) {
      const user = Pool.getCurrentUser();
      if (!user) {
        yield putResolve({ type: authTypes.SIGN_OUT.SUCCESS });
      }
    }
  }
}

export default function * auth () {
  yield all([pollUserSession()]);
}
