import { types } from '../actions/auth';
import * as states from '../constants/states';

export const initialState = {
  state: states.NEW
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case types.FORGOT_PASSWORD.FAILURE:
      return { ...state, error: action.error, state: states.FAILED };
    case types.FORGOT_PASSWORD.SUCCESS:
      return { ...state, state: states.OKAY };
    default:
      return state;
  }
};
