import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import style from './CenteredContainer.css';

const CenteredContainer = (props) => {
  return (
    <div className={classNames(
      style.container,
      props.className
    )}
    >
      <div className={classNames(
        style.content,
        props.contentClassName
      )}>
        {props.children}
      </div>
    </div>
  );
};

CenteredContainer.propTypes = {
  className: PropTypes.string,
  contentClassName: PropTypes.string
};

export default CenteredContainer;
