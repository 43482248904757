import React, { Component } from 'react';
import * as states from '../../constants/states';
import Form from '../core/Form';
import ButtonSet from '../core/ButtonSet';

class RemoveUser extends Component {
  constructor (props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit (event) {
    event.preventDefault();
    this.props.onSubmit(this.props.email);
  }

  render () {
    return (
      <Form onSubmit={this.handleSubmit}>
        <h1>Delete User</h1>
        <p>Are you sure you want to delete <strong>{this.props.email}</strong> from the group?</p>
        <p>Deleting a user means they will no longer have access to the Stackery dashboard for this organization.</p>
        <ButtonSet
          primaryButton={{
            text: 'Delete',
            type: 'submit',
            modifier: 'warning',
            isLoading: this.props.state === states.LOADING
          }}
          secondaryButton={{
            text: 'Cancel',
            onClick: this.props.onClickCancel
          }}
        />
      </Form>
    );
  }
}

RemoveUser.propTypes = {
};

export default RemoveUser;
