import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '../core/Icon';
import CodeBlock from '../core/CodeBlock';
import LinkButton from '../core/LinkButton';
import style from './UpgradePrompt.css';

const prompts = {
  git: {
    header: 'Need Git integration?',
    message: 'Configure, collaborate, and save your architecture right here in the Stackery dashboard.  Stackery integrates with all major Git providers. Upgrade to Professional to link your project\'s Git repo.',
    icon: 'git'
  },
  stackSettings: {
    header: 'Looking for more stack settings?',
    message: 'Upgrade to the Pro Plan to configure settings from this dashboard.',
    icon: 'settings'
  },
  deploy: {
    header: 'Looking for CI/CD integration?',
    message: 'Configure deployment strategies that run in your AWS Account. Deploy new versions directly from your Git version control and trigger deployments from the Stackery dashboard or CLI.',
    icon: 'infinity'
  },
  blueprints: {
    header: 'Looking for stack template standardization?',
    message: 'Stack blueprints can be used as a boilerplate when creating a new stack.',
    icon: 'map'
  }
};

const cli = (stackName = 'stack-name', environmentName = 'environment-name') => ({
  create: [
    `stackery create --stack-name ${stackName}`
  ],
  init: [
    `stackery init --stack-name ${stackName}`
  ],
  edit: [
    `stackery edit`
  ],
  deploy: [
    `stackery deploy --strategy local --stack-name ${stackName} --env-name ${environmentName}`
  ]
});

const UpgradePrompt = props => {
  const prompt = props.feature && prompts[props.feature];
  const commands = props.commands && [].concat(...props.commands.map(command => cli(props.stackName, props.environmentName)[command]));

  return (
    <div className={classnames(style.container, props.className)}>
      {commands &&
        <CodeBlock
          hasPrompt
          lines={commands}
        />
      }
      {prompt &&
        <div className={style.cta}>
          {prompt.icon &&
            <Icon name={prompt.icon} className={style.icon} />
          }
          <div>
            {prompt.header &&
              <p className={style.header}>{prompt.header}</p>
            }
            <p>{prompt.message}</p>
            <LinkButton to='/settings/billing' text='Start Professional Free Trial' />
          </div>
        </div>
      }
    </div>
  );
};

UpgradePrompt.propTypes = {
  className: PropTypes.string,
  feature: PropTypes.string,
  command: PropTypes.string,
  stackName: PropTypes.string,
  environmentName: PropTypes.string
};

export default UpgradePrompt;
