import makeActions from '../utils/makeActions';

export const types = {
  GET_METRICS: makeActions('CLOUDWATCH', 'GET_METRICS'),
  DISABLE_METRICS: 'CLOUDWATCH::DISABLE_METRICS'
};

const actions = {
  getMetrics: (range) => ({
    type: types.GET_METRICS.REQUEST,
    range
  })
};

export default actions;
