import React from 'react';
import ReactDOM from 'react-dom';
import { StylesProvider } from '@material-ui/core/styles';
import * as localStorage from './utils/localStorage';
import Root from './components/root/Root';
import 'sanitize.css/sanitize.css';
import './index.css';

ReactDOM.render(
  <StylesProvider injectFirst>
    <Root />
  </StylesProvider>, document.getElementById('root')
);

window.impersonate = email => {
  if (email) {
    localStorage.setItem('impersonate', email);
  } else {
    localStorage.removeItem('impersonate');
  }
  window.location = `${window.location.protocol}//${window.location.host}`;
};
