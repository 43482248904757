import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import setupActions from '../../actions/setup';
import apiActions from '../../actions/api';
import Setup from './Setup';

const mapStateToProps = () => {
  return createSelector(
    (appState) => appState.currentUser,
    (currentUser) => {
      return {
        hasVerifiedEmail: currentUser.hasVerifiedEmail,
        hasLinkedAws: currentUser.hasLinkedAws,
        isSetupRequired: currentUser.isSetupRequired,
        userName: currentUser.name
      };
    }
  );
};

const mapDispatchToProps = {
  startSetup: setupActions.startSetup,
  stopSetup: setupActions.stopSetup,
  removeSetupRequirement: apiActions.removeSetupRequirement
};

class SetupContainer extends Component {
  componentDidMount () {
    this.props.startSetup();

    if (this.props.isSetupRequired) {
      this.props.removeSetupRequirement();
    }
  }

  componentWillUnmount () {
    this.props.stopSetup();
  }

  render () {
    return (
      <Setup {...this.props} />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SetupContainer);
