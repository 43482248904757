import React from 'react';
import Link from '../core/Link';
import LinkAwsButtonContainer from '../linkAwsButton/LinkAwsButtonContainer';

const LinkAws = (props) => {
  return (
    <div>
      <h1>Link AWS Account</h1>
      <p>Linking an AWS account enables deployments of stacks through <Link href='https://docs.stackery.io/docs/using-stackery/environments/' text='environments with Stackery' target='_blank' rel='noopener noreferrer' />.</p>
      <p><em>After logging in to the AWS Console, approve the limited IAM role permissions, then click "Create Stack". It may take a few minutes for the process to complete.</em></p>
      <LinkAwsButtonContainer />
    </div>
  );
};

export default LinkAws;
