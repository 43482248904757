import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import appActions from '../../actions/app';
import UserNotifications from './UserNotifications';

const mapStateToProps = () => {
  return createSelector(
    (appState) => appState.userNotifications,
    (userNotifications) => {
      return {
        hasNotifications: userNotifications.alerts.length > 0,
        notificationCount: userNotifications.alerts.length,
        notifications: userNotifications.alerts // TODO: sync new naming convention across components
      };
    }
  );
};

const mapDispatchToProps = {
  dismissNotification: appActions.dismissNotification
};

class UserNotificationsContainer extends Component {
  render () {
    return (
      <UserNotifications {...this.props} />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserNotificationsContainer);
