import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import JobItemHeader from './JobItemHeader';
import JobItemActions from './JobItemActions';
import style from './JobItem.css';

const JobItemQueued = React.forwardRef((props, ref) => {
  const {
    stack,
    queued,
    gitProviders
  } = props;

  return (
    <div className={classnames(style.container, props.rowIndex === 1 && style.isSiblingRow)} ref={ref}>
      <div className={style.content}>
        {queued.map((queue, queueIndex) => (
          <JobItemHeader
            key={`${stack.stackName}-queued-${queue.version}`}
            link={stack.stackLink}
            status='Queued'
            version={queue.version}
            timestamp={queue.timestamp}
            gitProviders={gitProviders}
          />
        ))}
        <JobItemActions
          {...props}
          isQueued
        />
      </div>
    </div>
  );
});

JobItemQueued.propTypes = {
  stack: PropTypes.object.isRequired,
  stageIndex: PropTypes.number.isRequired,
  queued: PropTypes.array.isRequired
};

export default JobItemQueued;
