import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ActiveStacksContainer from '../activeStacks/ActiveStacksContainer';
import ActivityContainer from '../activity/ActivityContainer';
import style from './StackOverview.css';

const StackOverview = props => {
  const { stack } = props;

  return (
    <div className={style.container}>
      {stack.id &&
        <Fragment>
          <ActiveStacksContainer filterBy={[`stack=${stack.id}`]} header='Active Deployments' />
          <ActivityContainer filterBy={[`stack=${stack.id}`]} />
        </Fragment>
      }
    </div>
  );
};

StackOverview.propTypes = {
  stack: PropTypes.object.isRequired,
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object
};

export default StackOverview;
