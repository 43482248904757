import React from 'react';

const CloudwatchLogsLink = (props) => {
  const components = [
    'https://console.aws.amazon.com/cloudwatch/home?region=',
    props.region,
    '#logEventViewer:group=',
    props.logGroup
  ];

  return (
    <a href={components.join('')} target='_blank' data-logs>{props.label}</a>
  );
};

export default CloudwatchLogsLink;
