import { putResolve, all, takeLatest, select, delay } from 'redux-saga/effects';
import cfYamlParser from 'cf-yaml-parser';
import { read, save } from './workspace';
import parse from '../utils/parse';
import { types as appTypes } from '../actions/app';
import { types as apiTypes } from '../actions/api';
import { types as canvasTypes } from '../actions/canvas';
import { types as workspaceTypes } from '../actions/workspace';

function * parseStackTemplate ({ template, format }) {
  try {
    const resources = parse(template, format);
    yield putResolve({ type: appTypes.PARSE_STACK_TEMPLATE.SUCCESS, template, format, resources });
  } catch (error) {
    yield putResolve({ type: appTypes.PARSE_STACK_TEMPLATE.FAILURE, template, format, error });
  }
}

function * resetStackTemplate () {
  const stack = yield select(state => state.stack);
  const templateEditor = yield select(state => state.templateEditor);

  let template = templateEditor.validTemplate;
  let format = stack.format;

  if (templateEditor.isTemplateEmpty) {
    template = templateEditor.defaultTemplate;
    format = 'SAM';
  }

  try {
    const resources = parse(template, format);
    yield putResolve({ type: appTypes.RESET_STACK_TEMPLATE.SUCCESS, template, format, resources });
  } catch (error) {
    yield putResolve({ type: appTypes.RESET_STACK_TEMPLATE.FAILURE, template, format, error });
  }
}

function * syncStackTemplate ({ resources }) {
  const template = cfYamlParser.toString(resources.getTemplate(), resources.format);
  yield putResolve({ type: appTypes.PARSE_STACK_TEMPLATE.SUCCESS, template, format: resources.format, resources });
}

function * parseStackContents ({ data }) {
  if (data.format === 'stackery') {
    return;
  }

  yield putResolve({ type: appTypes.PARSE_STACK_CONTENTS.TRY });

  yield delay(0);

  try {
    const resources = parse(data.stack, data.format);

    const isMultiFile = yield select(state => state.stack.isMultiFile);
    if (isMultiFile) {
      yield fetchExternalFiles(resources);
    }

    yield putResolve({ type: appTypes.PARSE_STACK_CONTENTS.SUCCESS, data, resources });
  } catch (err) {
    const error = data.format === 'none' ? new Error('Template path in git repo not found') : err;
    yield putResolve({ type: appTypes.PARSE_STACK_CONTENTS.FAILURE, data, error });
  }
}

/* List of external files loadable from the workspace. Schema:
 * {
 *   resourceType: '<type of resource with external file>',
 *   [facetType: '<type of facet with external file (optional)>',]
 *   setting: '<name of setting in resource or facet that holds the content>',
 *   saveInFileSetting: '<name of setting in resource or facet that determines if content is saved in external file>',
 *   fileLocationSetting: '<name of setting in resource or facet that determines external file location>',
 *   [valueType: 'object'|'string' (default string); if 'object', the file contents are parsed from yaml]
 * }
 */
const externalFiles = [
  {
    resourceType: 'graphql',
    setting: 'Schema',
    saveInFileSetting: 'SaveSchemaInFile',
    fileLocationSetting: 'SchemaLocation'
  },
  {
    resourceType: 'graphql',
    facetType: 'field',
    setting: 'RequestMappingTemplate',
    saveInFileSetting: 'SaveRequestMappingTemplateInFile',
    fileLocationSetting: 'RequestMappingTemplateLocation'
  },
  {
    resourceType: 'graphql',
    facetType: 'field',
    setting: 'ResponseMappingTemplate',
    saveInFileSetting: 'SaveResponseMappingTemplateInFile',
    fileLocationSetting: 'ResponseMappingTemplateLocation'
  },
  {
    resourceType: 'stateMachine',
    setting: 'Definition',
    saveInFileSetting: 'SaveDefinitionInFile',
    fileLocationSetting: 'DefinitionLocation',
    valueType: 'object'
  }
];

function * fetchExternalFiles (state) {
  for (const resource of Object.values(state.resources)) {
    for (const externalFile of externalFiles) {
      if (resource.Type === externalFile.resourceType) {
        if (!('facetType' in externalFile)) {
          if (resource.Settings[externalFile.saveInFileSetting]) {
            const file = resource.Settings[externalFile.fileLocationSetting];
            let contents = yield read({ file });

            if (!(contents instanceof Error)) {
              // When editing locally and the external file is json, contents will have been parsed already.
              if (externalFile.valueType === 'object' && typeof contents === 'string') {
                contents = cfYamlParser(contents);
              }
              state.updateResourceSetting(resource.Id, externalFile.setting, contents);
            }
          }
        } else {
          const facets = resource.Facets[externalFile.facetType];

          for (const facet of facets) {
            if (facet.Settings[externalFile.saveInFileSetting]) {
              const contents = yield read({ file: facet.Settings[externalFile.fileLocationSetting] });
              if (!(contents instanceof Error)) {
                state.updateFacetSetting(resource.Id, externalFile.facetType, facet.Id, externalFile.setting, contents);
              }
            }
          }
        }
      }
    }
  }
}

function * saveExternalFiles ({ resources }) {
  for (const resource of Object.values(resources.resources)) {
    for (const externalFile of externalFiles) {
      if (resource.Type === externalFile.resourceType) {
        if (!('facetType' in externalFile)) {
          if (resource.Settings[externalFile.saveInFileSetting]) {
            const file = resource.Settings[externalFile.fileLocationSetting];
            let contents = resources.getResourceSetting(resource.Id, null, null, externalFile.setting);
            if (externalFile.valueType === 'object') {
              // The setting is an object in memory, which must be converted to a string for saving.
              if (file.endsWith('.json')) {
                contents = JSON.stringify(contents, null, 2);
              } else {
                contents = cfYamlParser.toString(contents);
              }
            }
            yield save({ file, contents });
          }
        } else {
          // No resource types currently require code here.
        }
      }
    }
  }
}

// Responds to the successful read of a workspace non-template file by updating resource and facet
// settings based on the file contents.
function * handleUpdatedExternalFile ({ file, data }) {
  const resources = yield select(state => state.formation.resources);
  let isSyncNeeded = false;

  for (const resource of Object.values(resources.resources)) {
    for (const externalFile of externalFiles) {
      if (resource.Type === externalFile.resourceType) {
        if (!('facetType' in externalFile)) {
          if (resource.Settings[externalFile.saveInFileSetting] && resource.Settings[externalFile.fileLocationSetting] === file) {
            // When editing locally and the external file is json, 'data' will have been parsed already.
            if (externalFile.valueType === 'object' && typeof data === 'string') {
              try {
                data = cfYamlParser(data);
              } catch (err) {
                console.log(`Error parsing ${file}: `, err);
              }
            }
            resources.updateResourceSetting(resource.Id, externalFile.setting, data);
            isSyncNeeded = true;
          }
        } else {
          const facets = resource.Facets[externalFile.facetType];

          for (const facet of facets) {
            if (facet.Settings[externalFile.saveInFileSetting] && facet.Settings[externalFile.fileLocationSetting] === file) {
              resources.updateFacetSetting(resource.Id, externalFile.facetType, facet.Id, externalFile.setting, data);
              isSyncNeeded = true;
            }
          }
        }
      }
    }
  }

  if (isSyncNeeded) {
    // Initiate a layout operation to reflect changes from the external file.
    yield putResolve({ type: workspaceTypes.LOAD_EXTERNAL_FILE.SUCCESS });
  }
}

export default function * stack () {
  yield all([
    takeLatest(appTypes.PARSE_STACK_TEMPLATE.TRY, parseStackTemplate),
    takeLatest(appTypes.RESET_STACK_TEMPLATE.TRY, resetStackTemplate),
    takeLatest(apiTypes.GET_STACK_CONTENTS.SUCCESS, parseStackContents),
    takeLatest(canvasTypes.UPDATE_RESOURCES, syncStackTemplate),
    takeLatest(workspaceTypes.READ.SUCCESS, handleUpdatedExternalFile),
    takeLatest(appTypes.PARSE_STACK_TEMPLATE.SUCCESS, saveExternalFiles)
  ]);
}
