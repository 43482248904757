import React from 'react';
import PropTypes from 'prop-types';
import * as states from '../../constants/states';
import Editor from '../core/Editor';
import Button from '../core/Button';
import Tooltip from '../core/Tooltip';
import Link from '../core/Link';
import style from './EnvironmentParameters.css';

const EnvironmentParameters = props => {
  const {
    environment,
    message,
    onRef,
    onSave
  } = props;

  return (
    <div className={style.container}>
      <h2>Environment Parameters</h2>
      <p>Parameters are used to separate environment specific configuration from your code and are stored in <Link href='https://docs.aws.amazon.com/systems-manager/latest/userguide/systems-manager-paramstore.html' target='_blank'>AWS Systems Manager Parameter Store</Link> in your AWS account. Parameters can be used to set Function & Docker Task environment variables and to set cloud resource configuration values.</p>
      <p>For usage and examples of Environment Parameters refer to <Link href='https://docs.stackery.io/docs/using-stackery/environments/' target='_blank'>Stackery Environment Parameters</Link>.</p>
      <div className={style.editorContainer}>
        <div className={style.editor}>
          <Editor
            isLoading={environment.parametersState === states.LOADING}
            onRef={onRef}
          />
        </div>
        <div className={style.saveButton}>
          <Tooltip className={style.tooltip} message={message} place='left'>
            <Button
              onClick={onSave}
              isDisabled={!environment.valid}
              isLoading={environment.parametersSaveState === states.LOADING}
            >Save</Button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

EnvironmentParameters.propTypes = {
  environment: PropTypes.object.isRequired,
  message: PropTypes.string,
  onRef: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};

export default EnvironmentParameters;
