import React from 'react';
import PropTypes from 'prop-types';
import ButtonMenu from '../core/ButtonMenu';
import Skeleton from '../core/Skeleton';
import Icon from '../core/Icon';
import Link from '../core/Link';
import style from './UserNav.css';

const UserNav = (props) => {
  const userName = props.currentUser.name || props.currentUser.email;

  return (
    <div className={style.container}>
      <ButtonMenu
        id='user-nav-button-menu'
        iconSvg='people_outline'
        isIconButton
      >
        {({ isOpen, isEntered, onClose }) => {
          return (
            <div className={style.content}>
              <span className={style.userName}>Signed in as: {userName && <strong>{userName}</strong>} {!userName && <Skeleton width={100} />}</span>
              <hr className={style.divider} />
              <Link
                className={style.navLink}
                to='/settings'
                onClick={onClose}
                tabIndex='0'
              >
                <Icon name='settings' className={style.iconPower} />
              Settings
            </Link>
              <a
                className={style.navLink}
                tabIndex='0'
                onClick={props.onSignOut}
              >
                <Icon name='power' className={style.iconPower} />
                Sign Out
              </a>
            </div>
          );
        }}
      </ButtonMenu>
    </div>
  );
};

UserNav.propTypes = {
  currentUser: PropTypes.object.isRequired,
  onSignOut: PropTypes.func.isRequired
};

export default UserNav;
