import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import shortid from 'shortid';
import { ESC } from '../../constants/keyCodes';
import * as states from '../../constants/states';
import apiActions from '../../actions/api';
import appActions from '../../actions/app';
import Modal from './Modal';

const mapStateToProps = () => {
  return createSelector(
    (appState) => appState.modal,
    (appState) => appState.currentUser,
    (appState) => appState.gitProviders,
    (modal, currentUser, gitProviders) => {
      return {
        ...modal,
        isLoading: modal.state === states.LOADING,
        isDemoMode: currentUser.isDemoMode,
        gitProviders
      };
    }
  );
};

const mapDispatchToProps = {
  getGitDestinations: apiActions.getGitDestinations,
  getGitRepos: apiActions.getGitRepos,
  hideModal: appActions.hideModal,
  confirmModal: appActions.confirmModal,
  notifyUser: appActions.notifyUser
};

class ModalContainer extends Component {
  constructor (props) {
    super(props);

    this.state = {
      activeElement: null
    };

    this.handleKeyUp = this.handleKeyUp.bind(this);
  }

  componentDidMount () {
    const {
      stack,
      getGitDestinations,
      getGitRepos
    } = this.props;

    // Sometimes stack is a string and other times it's an object
    if (stack && stack.hasOwnProperty('link') && stack.link !== null && stack.link.startsWith('codecommit:')) {
      getGitDestinations('codecommit');
      getGitRepos('codecommit');
    }

    this.setState({ activeElement: document.activeElement });

    document.addEventListener('keyup', this.handleKeyUp);
  }

  componentDidUpdate (prevProps, prevState) {
    if (!prevProps.error && this.props.error) {
      this.props.notifyUser(this.props.error, 'error');
    }

    if (this.state.activeElement && !prevState.activeElement) {
      this.container.querySelector('[tabindex]').focus();
    }
  }

  componentWillUnmount () {
    document.removeEventListener('keyup', this.handleKeyUp);
    this.state.activeElement.focus();
  }

  handleKeyUp (event) {
    if (event.keyCode === ESC) {
      this.props.hideModal();
    }
  }

  render () {
    return (
      <Modal
        setRef={elem => { this.container = elem; }}
        modalId={shortid.generate()}
        {...this.props}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer);
