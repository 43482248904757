
import React from 'react';
import PropTypes from 'prop-types';
import { hints } from '../../constants/patternHintText';
import * as states from '../../constants/states';
import FormRow from '../core/FormRow';
import Input from '../core/Input';

const RepoUrl = props => {
  const {
    gitProviders,
    selectedGitProvider
  } = props;

  const gitProvider = selectedGitProvider ? gitProviders[selectedGitProvider] : {};

  return (
    <FormRow label='Remote URL' hint='Remote repository clone URL'>
      <Input
        name='sourceRepo'
        type='text'
        required
        defaultValue={props.repoUrl}
        placeholder={hints.cloneUrl}
        suggestions={gitProvider.repos}
        isLoading={gitProvider.reposState === states.LOADING}
        onChange={props.onInputChange}
      />
    </FormRow>
  );
};

RepoUrl.propTypes = {
  repoUrl: PropTypes.string,
  selectedGitProvider: PropTypes.string,
  gitProviders: PropTypes.object,
  onInputChange: PropTypes.func.isRequired
};

export default RepoUrl;
