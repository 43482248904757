import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import * as deploymentStates from '../../constants/deploymentStates';
import Spinner from '../core/Spinner';
import SearchFilter from '../core/SearchFilter';
import UpgradePrompt from '../core/UpgradePrompt';
import Environment from './Environment';
import style from './StackDeploy.css';

const filterFieldsetOptions = [
  {
    name: 'isDeployed',
    label: 'Deployed',
    value: deploymentStates.DEPLOYED
  },
  {
    name: 'isDeploying',
    label: 'Deploying',
    value: deploymentStates.DEPLOYING
  },
  {
    name: 'isPrepared',
    label: 'Prepared',
    value: deploymentStates.CHANGE_SET_CREATED
  },
  {
    name: 'isPreparing',
    label: 'Preparing',
    value: [deploymentStates.QUEUED, deploymentStates.PREPARING, deploymentStates.CREATING_CHANGE_SET].join(',')
  },
  {
    name: 'isFailed',
    label: 'Failed',
    value: deploymentStates.FAILED
  }
];

const StackDeploy = props => {
  const {
    environments,
    searchTerm,
    filterTerms,
    isLoadingEnvironments,
    isEnvironmentsFailed,
    isDevPlan,
    onSearch,
    onFilter
  } = props;

  const filterTermLabels = filterTerms.map(term => {
    const filterOption = filterFieldsetOptions.find(option => option.value === term);
    if (filterOption) {
      return filterOption.label;
    }
    return null;
  });

  const emptyMessage = searchTerm ? 'that match' : 'with status';

  return (
    <div className={style.container}>
      {isLoadingEnvironments &&
        <Spinner />
      }

      {!isLoadingEnvironments &&
        <Fragment>
          <header className={style.header}>
            <SearchFilter
              searchName='searchTerm'
              filterName='filterTerms'
              filterFieldset={filterFieldsetOptions}
              onSearchChange={onSearch}
              onFilterChange={onFilter}
            />
          </header>
          {environments.map(env =>
            <Environment
              {...props}
              key={env.name}
              environment={env}
              isPreparing={
                env.latestPrepare &&
                env.latestPrepare.status &&
                env.latestPrepare.status !== deploymentStates.CHANGE_SET_CREATED &&
                env.latestPrepare.status !== deploymentStates.FAILED
              }
            />
          )}
        </Fragment>
      }

      {!isLoadingEnvironments && environments.length === 0 &&
        <p>No environments found {emptyMessage} <strong>{searchTerm || filterTermLabels.join(', ')}</strong></p>
      }

      {!isLoadingEnvironments && isEnvironmentsFailed &&
        <p>Unable to load environments.</p>
      }

      {!isLoadingEnvironments && isDevPlan &&
        <UpgradePrompt className={style.footer} feature='deploy' />
      }
    </div>
  );
};

StackDeploy.propTypes = {
  environments: PropTypes.array,
  searchTerm: PropTypes.string,
  filterTerms: PropTypes.array,
  isLoadingEnvironments: PropTypes.bool,
  onSearch: PropTypes.func,
  onFilter: PropTypes.func,
  onPrepareClick: PropTypes.func,
  onDeployClick: PropTypes.func
};

export default StackDeploy;
