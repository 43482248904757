export default (parameter) => {
  let queryString = window.location.search;

  if (queryString) {
    // Remove first '?'
    queryString = queryString.substr(1);
    const prefix = encodeURIComponent(parameter) + '=';
    let parts = queryString.split(/[&;]/g);

    // Reverse iteration as may be destructive
    for (var i = parts.length; i-- > 0;) {
      // Idiom for string.startsWith
      if (parts[i].lastIndexOf(prefix, 0) !== -1) {
        parts.splice(i, 1);
      }
    }

    queryString = parts.join('&');
    if (queryString) {
      queryString = '?' + queryString;
    }

    window.history.pushState(window.history.state, '', window.location.pathname + queryString);
  }
};
