import jp from 'jsonpath';
import { injectContext } from './manageCFResources';

export const nodes = (query, root, currentObject, context = {}) => {
  const data = mangleQuery(query, root, currentObject, context);

  return jp.nodes(data.object, data.query);
};

export const value = (query, root, currentObject, context = {}) => {
  const nodesResult = nodes(query, root, currentObject, context);

  if (nodesResult.length > 0) {
    return nodesResult[0].value;
  }
};

export const update = (query, root, currentObject, value, context = {}) => {
  const data = mangleQuery(query, root, currentObject, context);

  const parsedQuery = jp.parse(data.query);
  const leafElement = parsedQuery.pop();

  if (leafElement.expression.type === 'filter_expression') {
    jp.value(data.object, data.query, value);
  } else {
    // Create leaf value for all possible locations
    const parentQuery = parsedQuery
      .map(part => {
        switch (part.expression.type) {
          case 'root':
            return '$';

          case 'numeric_literal':
          case 'filter_expression':
            return `[${part.expression.value}]`;

          default:
            return `['${part.expression.value}']`;
        }
      })
      .join('');

    const nodes = jp.nodes(data.object, parentQuery);

    if (nodes.length > 0) {
      nodes.forEach(node => {
        node.value[leafElement.expression.value] = value;
      });
    } else {
      jp.value(data.object, data.query, value);
    }
  }
};

export const move = (query, root, currentObject, fromContext, toContext) => {
  const fromData = mangleQuery(query, root, currentObject, fromContext);
  const toData = mangleQuery(query, root, currentObject, toContext);

  if (fromData.query === toData.query) {
    return;
  }

  const nodes = jp.nodes(fromData.object, fromData.query, 1);

  if (nodes.length === 0) {
    return;
  }

  const fromValue = nodes[0].value;
  const fromPath = nodes[0].path.slice(1);

  jp.value(toData.object, toData.query, fromValue);

  trim(fromData.object, fromPath);
};

const trim = (object, path, index = 0) => {
  if (index < path.length - 1) {
    trim(object[path[index]], path, index + 1);
  }

  if (index === path.length - 1 || Object.keys(object[path[index]]).length === 0) {
    delete object[path[index]];
  }
};

const delTraverse = (root, path) => {
  if (path.length === 1) {
    if (Array.isArray(root)) {
      root.splice(path[0], 1);
    } else {
      delete root[path[0]];
    }
  } else {
    const child = root[path[0]];
    const subpath = path.slice(1);
    delTraverse(child, subpath);

    if ((Array.isArray(child) ? child.length : Object.keys(child).length) === 0) {
      if (Array.isArray(root)) {
        root.splice(path[0]);
      } else {
        delete root[path[0]];
      }
    }
  }
};

const del = (query, root, currentObject, context = {}) => {
  const data = mangleQuery(query, root, currentObject, context);

  const nodes = jp.nodes(data.object, data.query);

  for (const node of nodes) {
    node.path.shift();

    delTraverse(data.object, node.path);
  }
};
export { del as delete };

const mangleQuery = (query, root, currentObject, context) => {
  const data = {
    object: root,
    query
  };

  data.query = injectContext(data.query, context);

  if (data.query[0] === '@') {
    data.query = '$' + data.query.substr(1);
    data.object = currentObject;
  }

  return data;
};
