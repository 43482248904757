import { types } from '../actions/api';
import * as states from '../constants/states';

export const initialState = {
  state: states.NEW
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case types.UPDATE_USER_ROLE.REQUEST:
      return {
        ...state,
        state: states.LOADING
      };

    case types.UPDATE_USER_ROLE.FAILED:
      return {
        ...state,
        error: action.error,
        state: states.FAILED
      };

    case types.UPDATE_USER_ROLE.SUCCESS:
      return {
        ...state,
        state: states.OKAY,
        ...action.data
      };

    default:
      return state;
  }
};
