import Parameter from './parameter';

const LOGICAL_ID_FILTER_RE = /[^a-zA-Z0-9]/g;
const SSM_KEY_FILTER_RE = /[^a-zA-Z0-9_/-]/g;
const SSM_KEY_HIEARACHY_RE = /\./g;

export default class EnvConfigParameter extends Parameter {
  constructor (format, key, parameterId) {
    if (!parameterId) {
      const keyPart = key.replace(LOGICAL_ID_FILTER_RE, '');
      const formatPart = format.replace(LOGICAL_ID_FILTER_RE, '');

      parameterId = `EnvConfig${keyPart}As${formatPart}`;
    }

    super(parameterId, format);

    this.Key = key;
  }

  insertIntoTemplate (state) {
    super.insertIntoTemplate(state);

    let ssmKey = this.Key.replace(SSM_KEY_HIEARACHY_RE, '/').replace(SSM_KEY_FILTER_RE, '');

    if (this.Format.startsWith('List<')) {
      ssmKey += '/AsList';
    }

    const cfTemplate = state.cfTemplate();

    const parameter = cfTemplate.Parameters[this.ParameterId];

    parameter.Default = `/<EnvironmentName>/${ssmKey}`;
    parameter.Type = `AWS::SSM::Parameter::Value<${this.Format}>`;

    cfTemplate.Metadata = cfTemplate.Metadata || {};

    // Convert StackeryEnvConfigParameters to EnvConfigParameters
    if ('StackeryEnvConfigParameters' in cfTemplate.Metadata) {
      cfTemplate.Metadata.EnvConfigParameters = cfTemplate.Metadata.StackeryEnvConfigParameters;
      delete cfTemplate.Metadata.StackeryEnvConfigParameters;
    }

    cfTemplate.Metadata.EnvConfigParameters = cfTemplate.Metadata.EnvConfigParameters || {};

    const paramMetadata = cfTemplate.Metadata.EnvConfigParameters;
    paramMetadata[this.ParameterId] = this.Key;
  }
}
