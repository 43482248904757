import React from 'react';
import Link from '../core/Link';

const PipelineStepLink = (props) => {
  if (!props.stackExists) {
    return <em>{props.pipelineStep}</em>;
  }

  return <Link to={`/stacks/${props.owner}/${props.stackName}/settings`}>{props.pipelineStep}</Link>;
};

export default PipelineStepLink;
