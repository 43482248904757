import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import copyToClipboard from '../../utils/copyToClipboard';
import style from './CodeBlock.css';

class CodeBlock extends Component {
  constructor (props) {
    super(props);

    this.state = {
      tooltip: null
    };

    this.handleMouseOverLine = this.handleMouseOverLine.bind(this);
    this.handleMouseOutLine = this.handleMouseOutLine.bind(this);
    this.handleClickLine = this.handleClickLine.bind(this);
  }

  handleClickLine (line) {
    this.setState({ tooltip: 'click' });
    clearTimeout(this.timer);
    this.timer = setTimeout(() => this.setState({ tooltip: null }), 1500);
    copyToClipboard(line);
  }

  handleMouseOverLine () {
    this.setState({ tooltip: 'hover' });
  }

  handleMouseOutLine () {
    this.setState({ tooltip: null });
  }

  componentWillUnmount () {
    clearTimeout(this.timer);
  }

  render () {
    const {
      theme,
      lines
    } = this.props;

    const isLightTheme = theme === 'light';
    const isDarkTheme = theme === 'dark';
    const isDefaultTheme = theme === 'default';

    let ContainerElem = 'ul';
    let ItemElem = 'li';

    if (lines.length === 1) {
      ContainerElem = 'div';
      ItemElem = 'div';
    }

    return (
      <ContainerElem
        className={classNames(
          style.container,
          isLightTheme && style.light,
          isDarkTheme && style.dark,
          isDefaultTheme && style.default,
          this.props.className,
          this.props.hasChrome && style.hasChrome,
          this.state.tooltip === 'hover' && style.isHovered,
          this.state.tooltip === 'click' && style.isClicked
        )}
        data-hid={this.props.hid}
      >
        {this.props.lines !== undefined && this.props.lines.map((line, i) => {
          return (
            <ItemElem
              key={`line-${i}`}
              className={classNames(style.line, (this.props.hasPrompt || line.hasPrompt) && style.hasPrompt)}
              onMouseOver={() => { this.handleMouseOverLine(); }}
              onMouseOut={() => { this.handleMouseOutLine(); }}
              onClick={() => { this.handleClickLine(line); }}
            >
              <pre>{line.text || line}</pre>
            </ItemElem>
          );
        })}
      </ContainerElem>
    );
  }
}

CodeBlock.defaultProps = {
  theme: 'dark' // TODO: Update instances of CodeBlock with `theme='dark'` and set the defaultProp to 'default'
};

CodeBlock.propTypes = {
  className: PropTypes.string,
  lines: PropTypes.array.isRequired,
  theme: PropTypes.oneOf(['default', 'light', 'dark']),
  hasChrome: PropTypes.bool
};

export default CodeBlock;
