import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import apiActions from '../../actions/api';
import appActions from '../../actions/app';
import * as states from '../../constants/states';
import * as planTypes from '../../constants/planTypes';
import StackBlueprints from './StackBlueprints';

const mapStateToProps = () => {
  return createSelector(
    (appState) => appState.account,
    (appState) => appState.currentUser,
    (account, currentUser) => {
      return {
        blueprints: account.blueprints,
        currentUser,
        isDevPlan: account.plan.type === planTypes.DEVELOPER
      };
    }
  );
};

const mapDispatchToProps = {
  getStackBlueprints: apiActions.getStackBlueprints,
  deleteStackBlueprint: apiActions.deleteStackBlueprint,
  showDemoModal: appActions.showDemoModal,
  showCreateStackBlueprintModal: appActions.showCreateStackBlueprintModal
};

class StackBlueprintsContainer extends Component {
  constructor (props) {
    super(props);

    this.state = {
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleAddBlueprint = this.handleAddBlueprint.bind(this);
    this.handleRemoveBlueprint = this.handleRemoveBlueprint.bind(this);
  }

  handleInputChange (event) {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value });
  }

  handleRemoveBlueprint (name) {
    if (this.props.currentUser.isDemoMode) {
      this.props.showDemoModal({ action: `delete an existing blueprint` });
      return;
    }

    this.props.deleteStackBlueprint(this.props.currentUser.org, name);
  }

  handleAddBlueprint (event) {
    this.props.showCreateStackBlueprintModal();
  }

  componentDidUpdate (prevProps, prevState) {
    const {
      blueprints,
      currentUser
    } = this.props;

    if (
      blueprints.state === states.NEW &&
      currentUser.org !== prevProps.currentUser.org &&
      currentUser.state === states.OKAY
    ) {
      this.props.getStackBlueprints(currentUser.org);
    }
  }

  componentDidMount () {
    const {
      blueprints,
      currentUser
    } = this.props;

    if (
      blueprints.state === states.NEW &&
      currentUser.org
    ) {
      this.props.getStackBlueprints(currentUser.org);
    }
  }

  render () {
    return (
      <StackBlueprints
        className={this.props.className}
        blueprints={this.props.blueprints.data}
        isDevPlan={this.props.isDevPlan}
        isEmpty={this.props.blueprints.data.length === 0}
        isLoading={this.props.blueprints.state === states.LOADING}
        isLoaded={this.props.blueprints.state === states.OKAY}
        onAddBlueprint={this.handleAddBlueprint}
        onRemoveBlueprint={this.handleRemoveBlueprint}
        onInputChange={this.handleInputChange}
        onSubmit={this.handleSubmit}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StackBlueprintsContainer);
