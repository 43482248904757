
import React from 'react';
import PropTypes from 'prop-types';
import regions from '../../constants/regions';
import FormRow from '../core/FormRow';
import Select from '../core/Select';

const RepoRegion = props => {
  return (
    <FormRow label='Region'>
      <Select
        name='region'
        value={props.region}
        required
        onChange={props.onInputChange}
      >
        <option disabled value={''}>Choose Region</option>
        {regions.map((entry) => {
          return <option key={entry.region} value={entry.region}>{entry.name}</option>;
        })}
      </Select>
    </FormRow>
  );
};

RepoRegion.propTypes = {
  region: PropTypes.string,
  onInputChange: PropTypes.func.isRequired
};

export default RepoRegion;
