import React from 'react';
import PropTypes from 'prop-types';
import hints from '../../constants/patternHintText';
import * as gitProviderStates from '../../constants/gitProviderStates';
import Form from '../core/Form';
import FormRow from '../core/FormRow';
import LinkProviderButton from '../core/LinkProviderButton';
import Select from '../core/Select';
import ButtonSet from '../core/ButtonSet';
import Input from '../core/Input';
import Spinner from '../core/Spinner';

const CreateStackBlueprint = (props) => {
  return (
    <Form onSubmit={props.onSubmit}>
      <h1>Add a Stack Blueprint</h1>
      <p>Add a stack blueprint by providing a URL to a remote Git repository (containing a valid AWS SAM template or serverless.yml) and reference (branch or tag). Blueprints can be used as boilerplate when creating stacks.</p>
      <FormRow label='Git Hosting Provider'>
        <Select
          name='gitProvider'
          required
          onChange={props.onInputChange}
          defaultValue={''}
        >
          <option disabled value={''}>Choose Git Provider</option>
          {Object.entries(props.gitProviders)
            .filter(([name, gitProvider]) => {
              return (
                name !== props.gitProviders.codecommit.name &&
                (
                  !('bitbucketserver' in props.gitProviders) ||
                  name !== props.gitProviders.bitbucketserver.name
                )
              );
            }).map(([name, gitProvider]) => {
              return <option key={name} value={gitProvider.name}>{gitProvider.displayName}</option>;
            })}
        </Select>
      </FormRow>
      {props.gitProvider && (props.gitProviders[props.gitProvider].state === gitProviderStates.URL_ACQUIRED || props.gitProviders[props.gitProvider].needsPersonalAccessToken) &&
        <LinkProviderButton
          provider={props.gitProviders[props.gitProvider].name}
          label={props.gitProviders[props.gitProvider].displayName}
          href={props.gitProviders[props.gitProvider].authUrl}
          hasLinked={false}
        />
      }
      {props.gitProvider && props.gitProviders[props.gitProvider].state === gitProviderStates.AUTHORIZED &&
        <div>
          <FormRow label='Blueprint Name' hint={hints['[a-zA-Z0-9_-]*']}>
            <Input
              name='name'
              required
              onChange={props.onInputChange}
            />
          </FormRow>
          <FormRow label='Repository URL' hint='Existing remote Git repository URL'>
            <Input
              name='repository'
              required
              onChange={props.onInputChange}
              type='url'
            />
          </FormRow>
          <FormRow label='Reference' hint='Reference to branch or tag (default: master)'>
            <Input
              name='reference'
              placeholder='master'
              defaultValue={props.reference}
              required
              onChange={props.onInputChange}
            />
          </FormRow>
          <ButtonSet
            primaryButton={{
              text: 'Save',
              type: 'submit',
              isLoading: props.isLoading,
              isDisabled: props.gitProviders[props.gitProvider].state === gitProviderStates.URL_ACQUIRED
            }}
            secondaryButton={{
              text: 'Cancel',
              onClick: props.onClickCancel
            }}
          />
        </div>
      }
      {props.gitProvider && props.gitProviders[props.gitProvider].state === gitProviderStates.LOADING &&
        <Spinner position='static' />
      }
    </Form>
  );
};

CreateStackBlueprint.propTypes = {
  owner: PropTypes.string.isRequired,
  gitProviders: PropTypes.object.isRequired
};

export default CreateStackBlueprint;
