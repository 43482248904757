import React, { Fragment } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import PropTypes from 'prop-types';
import Button from '../core/Button';
import ButtonSet from '../core/ButtonSet';
import AutoCompleteCheckboxes from '../core/AutoCompleteCheckboxes';
import FieldSet from '../core/FieldSet';
import Form from '../core/Form';
import FormRow from '../core/FormRow';
import Input from '../core/Input';
import Select from '../core/Select';
import Spinner from '../core/Spinner';
import AddButton from '../core/AddButton';
import CloseButton from '../core/CloseButton';

const CreateDeploymentPipeline = (props) => {
  const {
    isEditing,
    modalId,
    stacks,
    stages,
    stageEnvironments,
    selectedStacks,
    isAddStageEnabled,
    isDeployerRoleEnabled,
    isLoading,
    isSaving,
    isLoadingStacks,
    onSelectStack,
    onClickCancel,
    onInputChange,
    onSubmit
  } = props;

  const hasStages = stages.filter(stage => stage.environmentId).length > 1;

  return (
    <div>
      <h1>
        {isEditing && `Edit Deployment Pipeline`}
        {!isEditing && `Add Deployment Pipeline`}
      </h1>
      <p>Deploy stack repository changes through pipeline stages when changes are pushed or merged into the default branch.</p>

      {isLoading &&
        <Spinner position='static' />
      }

      {!isLoading && !isDeployerRoleEnabled &&
        <Fragment>
          <p>A linked AWS account with permission to deploy is required to enable the Deployment Pipeline.</p>
          <Button
            text='Grant permission to a linked AWS account'
            onClick={(event) => {
              event.preventDefault();
              props.showUpdatePipelineDeploymentsModal({ isChild: true, onReload: props.onReload });
            }}
          />
        </Fragment>
      }

      <Form onSubmit={onSubmit} disabled={isLoading}>
        {!isLoading && isDeployerRoleEnabled &&
          <Fragment>
            <FormRow label='Name'>
              <Input name='name' defaultValue={props.name} onChange={onInputChange} />
            </FormRow>
            <TransitionGroup component={null}>
              {stages.map((stage, stageIndex) => {
                const isRequired = isAddStageEnabled && stageIndex < 2;

                return (
                  <CSSTransition
                    key={`stage-${stageIndex}-fieldset`}
                    classNames={{
                      enter: 'enter',
                      enterActive: 'enterActive',
                      exit: 'exit',
                      exitActive: 'exitActive'
                    }}
                    timeout={250}
                  >
                    <FieldSet legend={`Stage ${stageIndex + 1}`} hint={`${isRequired ? '(required)' : ''}`} inlineHint={isRequired} isShaded>
                      <Select
                        label='Environment'
                        isAfterLabel
                        name='environmentId'
                        required
                        value={stage.environmentId || ''}
                        onChange={(event) => onInputChange(event, 'stages', stageIndex)}
                      >
                        <option disabled value={''}>Choose Environment</option>
                        {stageEnvironments.map(environment => (
                          environment.stageIndex === undefined || environment.stageIndex === -1 || environment.stageIndex === stageIndex) && <option key={environment.id} value={environment.id}>{environment.name}</option>
                        )}
                        <option value='addNewEnvironment'>Add new environment...</option>
                      </Select>
                      {(stageIndex + 1) < stages.length &&
                        <Input
                          name='autoPromote'
                          type='checkbox'
                          label='Auto promote on success'
                          defaultChecked={stage.autoPromote}
                          value={!stage.autoPromote}
                          onChange={(event) => onInputChange(event, 'stages', stageIndex)}
                        />
                      }
                      {stageIndex > 1 &&
                        <CloseButton size='small' onClick={(event) => props.onRemoveStage(event, stageIndex)} />
                      }
                    </FieldSet>
                  </CSSTransition>
                );
              })}
            </TransitionGroup>

            {isAddStageEnabled &&
              <AddButton text='Add another stage' onClick={props.onAddStage} />
            }

            <FormRow label='Enable deployment pipeline for stacks' hint='Stacks must be connected to a Git repository'>
              <AutoCompleteCheckboxes
                containerId={modalId}
                id='selectedStacks'
                name='selectedStacks'
                value={selectedStacks}
                options={stacks}
                noOptionsText='No stacks were found that are connected to a Git repository'
                onChange={onSelectStack}
                isLoading={isLoadingStacks}
              />
            </FormRow>
          </Fragment>
        }
        <ButtonSet
          primaryButton={{
            text: isEditing ? 'Save' : 'Add Deployment Pipeline',
            type: 'submit',
            isLoading: isSaving,
            isDisabled: isLoading || !hasStages || !isDeployerRoleEnabled
          }}
          secondaryButton={{
            text: 'Cancel',
            onClick: onClickCancel
          }}
        />
      </Form>
    </div>
  );
};

CreateDeploymentPipeline.propTypes = {
  modalId: PropTypes.string,
  stages: PropTypes.array.isRequired,
  stageEnvironments: PropTypes.array.isRequired,
  stacks: PropTypes.array.isRequired,
  selectedStacks: PropTypes.array.isRequired,
  isAddStageEnabled: PropTypes.bool.isRequired,
  isDeployerRoleEnabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSaving: PropTypes.bool,
  isLoadingStacks: PropTypes.bool,
  onClickCancel: PropTypes.func,
  onInputChange: PropTypes.func,
  onSelectStack: PropTypes.func,
  onAddStage: PropTypes.func,
  onRemoveStage: PropTypes.func,
  onSubmit: PropTypes.func
};

export default CreateDeploymentPipeline;
