import React from 'react';
import { Route, Redirect, Switch } from 'react-router';
import DocumentTitle from 'react-document-title';
import SettingsNav from './SettingsNav';
import BuildEnvironmentContainer from './BuildEnvironmentContainer';
import ChangePasswordContainer from './ChangePasswordContainer';
import ManageUsersContainer from './ManageUsersContainer';
import LinkedAccountsContainer from './LinkedAccountsContainer';
import TokensContainer from './TokensContainer';
import IntegrationsContainer from './IntegrationsContainer';
import StackBlueprintsContainer from './StackBlueprintsContainer';
import Usage from './Usage';
import style from './Settings.css';

const Settings = props => {
  return (
    <div className={style.container}>
      <DocumentTitle title={`Stackery Settings`} />
      <SettingsNav {...props} />
      <main className={style.main}>
        <Switch>
          <Route path='/settings/usage' component={Usage} />
          <Route path='/settings/build-environment' component={BuildEnvironmentContainer} />
          <Route path='/settings/stack-blueprints' component={StackBlueprintsContainer} />
          <Route path='/settings/linked-accounts' component={LinkedAccountsContainer} />
          <Route path='/settings/cli-tokens' component={TokensContainer} />
          <Route path='/settings/integrations' component={IntegrationsContainer} />
          <Route path='/settings/users' component={ManageUsersContainer} />
          <Route path='/settings/password' component={ChangePasswordContainer} />
          <Redirect to='/settings/usage' />
        </Switch>
      </main>
    </div>
  );
};

export default Settings;
