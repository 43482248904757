import React, { Component } from 'react';
import FormRow from '../core/FormRow';
import Input from '../core/Input';
import ConfigField from './ConfigField';
import ListField from './ListField';
import EditorField from './EditorField';
import ChoicesField from './ChoicesField';
import FacetResourcesField from './FacetResourcesField';
import style from './Properties.css';
import isSettingHidden from '../../utils/isSettingHidden';
import transformations from '../../../src/resources/transformations';

const valueToPatternMap = {
  number: '[0-9]*',
  logicalId: '^[A-Za-z][A-Za-z0-9]*',
  slsS3BucketLogicalId: '^S3Bucket[A-Za-z0-9-.]+$'
};

class Properties extends Component {
  render () {
    const {
      resource,
      getResourceSetting,
      configKeys,
      onChange,
      autocomplete
    } = this.props;

    const settingSchemas = resource.settingTypes;

    return (
      <div className={style.container}>
        {settingSchemas && Object.keys(settingSchemas).map(key => {
          const settingSchema = settingSchemas[key];
          const inputType = settingSchema.ValueType === 'number' ? 'number' : settingSchema.InputType;
          const defaultValue = settingSchema.AwsDefault || settingSchema.Default || settingSchema.GlobalDefault;
          const currentSettingValue = getResourceSetting(resource.id, resource.facetType, resource.facetId, key);
          const currentValue = currentSettingValue != null ? currentSettingValue : defaultValue;
          const suggestions = settingSchema.Autocomplete && autocomplete[settingSchema.Autocomplete];
          const isRequired = !isSettingHidden(settingSchema, resource, getResourceSetting) &&
                             settingSchema.InputType !== 'checkbox' &&
                             key !== 'Name' && key !== 'Role' &&
                             !settingSchema.IsOptional;

          if (settingSchema.Type === 'map') {
            return (
              <ListField
                key={key}
                settingId={key}
                resource={resource}
                getResourceSetting={getResourceSetting}
                settingSchema={settingSchema}
                configKeys={configKeys}
                onChange={onChange}
                autocomplete={autocomplete}
                isHidden={isSettingHidden(settingSchema, resource, getResourceSetting)}
              />
            );
          }

          if (settingSchema.Type === 'FacetResources') {
            return (
              <FacetResourcesField
                key={key}
                settingId={key}
                resource={resource}
                settingSchema={settingSchema}
                facets={resolveFacets(settingSchema, resource)}
                onChange={onChange}
              />
            );
          }

          return (
            <FormRow
              key={key}
              label={settingSchema.Label}
              hint={settingSchema.Description}
              isHidden={isSettingHidden(settingSchema, resource, getResourceSetting)}
            >
              {!settingSchema.IsConfigurable && (settingSchema.InputType === 'input' || settingSchema.InputType === 'checkbox') &&
                <Input
                  type={inputType}
                  defaultValue={currentValue}
                  defaultChecked={settingSchema.InputType === 'checkbox' && currentValue}
                  required={isRequired}
                  pattern={valueToPatternMap[settingSchema.ValueType]}
                  min={settingSchema.Min}
                  max={settingSchema.Max}
                  onChange={(event) => onChange(key, settingSchema.InputType === 'input' ? event.target.value : event.target.checked, event.target)}
                  suggestions={suggestions}
                />
              }

              {!settingSchema.IsConfigurable && settingSchema.InputType === 'select' &&
                <ChoicesField
                  required
                  resource={resource}
                  getResourceSetting={getResourceSetting}
                  settingId={key}
                  settingSchema={settingSchema}
                  onChange={(event) => onChange(key, event.target.value, event.target)}
                />
              }

              {!settingSchema.IsConfigurable && settingSchema.InputType !== 'input' && settingSchema.InputType !== 'checkbox' && settingSchema.InputType !== 'select' &&
                <EditorField
                  textFormat={settingSchema.InputType}
                  settingId={key}
                  defaultValue={currentValue}
                  onChange={onChange}
                />
              }

              {settingSchema.IsConfigurable &&
                <ConfigField
                  settingId={key}
                  resource={resource}
                  getResourceSetting={getResourceSetting}
                  settingSchema={settingSchema}
                  currentValue={currentValue}
                  defaultValue={defaultValue}
                  configKeys={configKeys}
                  required={isRequired}
                  pattern={valueToPatternMap[settingSchema.ValueType]}
                  min={settingSchema.Min}
                  max={settingSchema.Max}
                  onChange={onChange}
                  suggestions={suggestions}
                />
              }
            </FormRow>
          );
        })}
      </div>
    );
  }
}

const resolveFacets = (settingSchema, resource) => {
  const facetsSchema = settingSchema.Facets;
  let facets = settingSchema.Facets;

  if (typeof settingSchema.Facets === 'object' && 'Setting' in settingSchema.Facets) {
    facets = resource.settings[facetsSchema.Setting];

    if ('Transformations' in facetsSchema) {
      facets = facetsSchema.Transformations.reduce((choices, transform) => transformations[transform](choices), facets);
    }
  }

  return facets;
};

export default Properties;
