import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import formatTimestamp from '../../utils/formatTimestamp';
import * as usageEventLabels from '../../constants/usageEventLabels';
import RangeSelector from './RangeSelector';
import ActionHeader from '../core/ActionHeader';
import Tooltip from '../core/Tooltip';
import Drawer from '../core/Drawer';
import Table from '../core/Table';
import Spinner from '../core/Spinner';
import RowHeader from './RowHeader';
import style from './ActiveStacks.css';

const getHeaderText = (month, year, isCurrentMonth) => {
  if (isCurrentMonth) {
    return `Current Month Summary`;
  }

  // Month is 0-indexed, needs to be 1-indexed for parsing
  return `Summary for ${moment(`${month + 1} ${year}`, 'MM YYYY').format('MMMM YYYY')}`;
};

const formatEventName = (event) => {
  return usageEventLabels[event] || event;
};

const getEmptyMessage = (context) => {
  // Return messaging based on page context
  switch (context) {
    case 'stacks':
      return 'This stack has not been deployed';
    case 'environments':
      return 'No stacks have been deployed to this environment';
    default:
      return 'No active stacks';
  }
};

const ActiveStacks = props => {
  const {
    stacks,
    header,
    selectedMonth,
    selectedYear,
    isCurrentMonth,
    isInTheFuture,
    isLoading,
    isLoaded,
    isEmpty,
    showRangeSelector,
    onChange
  } = props;

  const emptyMessage = showRangeSelector ? `${getEmptyMessage(props.context)} in the current period.` : `${getEmptyMessage(props.context)}.`;

  return (
    <section className={style.container}>
      {showRangeSelector &&
        <ActionHeader headerText={getHeaderText(selectedMonth, selectedYear, isCurrentMonth)}>
          <RangeSelector
            stacks={stacks}
            onChange={onChange}
            selectedMonth={selectedMonth}
            selectedYear={selectedYear}
          />
        </ActionHeader>
      }

      {header &&
        <h2>{header}</h2>
      }

      {!header &&
        <h2>{isLoaded ? stacks.length : `...`} <Tooltip className={style.tooltip} isInline message='An active stack is a stack deployed to an environment.' place='top'>Active Stacks</Tooltip></h2>
      }

      <Table isStriped isEmpty={stacks.length === 0}>
        {isLoading &&
          <tbody>
            <tr>
              <td><Spinner position='static' /></td>
            </tr>
          </tbody>
        }

        {isLoaded && isInTheFuture &&
          <tbody>
            <tr>
              <td>{emptyMessage} Please select a date before {moment().add(1, 'M').format('MMMM YYYY')}.</td>
            </tr>
          </tbody>
        }

        {isLoaded && isEmpty && !isInTheFuture &&
          <tbody>
            <tr>
              <td>{emptyMessage}</td>
            </tr>
          </tbody>
        }

        {isLoaded && !isEmpty && !isInTheFuture &&
          <tbody>
            {stacks && stacks.map((stack, index) => {
              return (
                <tr key={stack.id}>
                  <Drawer
                    element='td'
                    label={<RowHeader {...props} stack={stack} />}
                  >
                    {stack.events.map((event, index) => (
                      <div key={`${stack.id}-event-${index}`} className={style.event}>
                        {formatEventName(event.event)} by {event.userEmail} <span className={style.timestamp}>{formatTimestamp(event.timestamp)}</span>
                      </div>
                    ))}
                  </Drawer>
                </tr>
              );
            })}
          </tbody>
        }
      </Table>
    </section>
  );
};

ActiveStacks.propTypes = {
  stacks: PropTypes.array,
  owner: PropTypes.string,
  header: PropTypes.string,
  selectedMonth: PropTypes.number,
  selectedYear: PropTypes.number,
  showRangeSelector: PropTypes.bool,
  isLoading: PropTypes.bool,
  isLoaded: PropTypes.bool,
  isEmpty: PropTypes.bool,
  isCurrentMonth: PropTypes.bool,
  isInTheFuture: PropTypes.bool,
  onChange: PropTypes.func
};

export default ActiveStacks;
