import { LOCATION_CHANGE } from 'connected-react-router';

export const initialState = {
  pathname: undefined
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case LOCATION_CHANGE:
      return { pathname: action.payload.location.pathname };
    default:
      return state;
  }
};
