import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as localStorage from '../../utils/localStorage';
import Spinner from '../core/Spinner';
import Icon from '../core/Icon';
import Link from '../core/Link';
import CloseButton from '../core/CloseButton';
import style from './Completion.css';

class Completion extends Component {
  constructor (props) {
    super(props);

    this.state = {
      completed: 0,
      isComplete: false,
      isHidden: false
    };

    this.calculateProgress = this.calculateProgress.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount () {
    this.calculateProgress();
  }

  componentDidUpdate (prevProps) {
    const {
      hasCompletedSetup,
      hasCreatedStack,
      hasCreatedEnvironment,
      hasTriggeredDeployment
    } = this.props.currentUser;

    const {
      hasCompletedSetup: prevHasCompletedSetup,
      hasCreatedStack: prevHasCreatedStack,
      hasCreatedEnvironment: prevHasCreatedEnvironment,
      hasTriggeredDeployment: prevHasTriggeredDeployment
    } = prevProps.currentUser;

    if (
      hasCompletedSetup !== prevHasCompletedSetup ||
      hasCreatedStack !== prevHasCreatedStack ||
      hasCreatedEnvironment !== prevHasCreatedEnvironment ||
      hasTriggeredDeployment !== prevHasTriggeredDeployment
    ) {
      this.calculateProgress();
    }
  }

  calculateProgress () {
    const {
      isSetupRequired,
      hasCreatedStack,
      hasCreatedEnvironment,
      hasTriggeredDeployment
    } = this.props.currentUser;

    const steps = [!isSetupRequired, hasCreatedStack, hasCreatedEnvironment, hasTriggeredDeployment];
    const completed = steps.filter(step => step).length;
    const percent = Math.round((completed / steps.length) * 100);

    this.setState({ completed: percent, isComplete: percent === 100 });
  }

  handleClose () {
    this.setState({ isHidden: true });
    localStorage.setItem('hideCompletion', true);
  }

  render () {
    const {
      stacks,
      currentUser,
      isLoadingStacks,
      isLoadingEnvironments
    } = this.props;

    const {
      hasCreatedStack,
      hasCreatedEnvironment,
      hasTriggeredDeployment
    } = currentUser;

    const stack = stacks.data.length > 0 ? stacks.data[0] : null;
    const canDeploy = stack && hasCreatedEnvironment;

    if (isLoadingStacks || isLoadingEnvironments) {
      return (
        <section className={style.container}>
          <Spinner />
        </section>
      );
    }

    return (
      <section className={classnames(style.container, this.state.isHidden && style.isHidden)}>
        <div className={style.meter}>
          <p className={style.caption}><span className={style.percent}>{this.state.completed}%</span>Setup Complete</p>
          <CircularProgress
            classes={{
              root: style.progressMeter,
              svg: style.progressCircle
            }}
            size={175}
            thickness={1}
            variant='determinate'
            value={this.state.completed}
          />
        </div>
        <ul className={style.todoList}>
          <li className={classnames(style.todoItem, style.isComplete)}>
            <h2 className={style.todoItemHeader}>
              <Icon name='checkmark-circle' className={classnames(style.icon, style.iconCheckmark)} />Create account
            </h2>
          </li>
          <li className={classnames(style.todoItem, hasCreatedStack && style.isComplete)}>
            <h2 className={style.todoItemHeader}>
              <Icon name='checkmark-circle' className={classnames(style.icon, style.iconCheckmark)} />
              {hasCreatedStack &&
                'Add a Stack'
              }
              {!hasCreatedStack &&
                <Link to='/stacks'>Add a stack <Icon name='chevron-right' className={classnames(style.icon, style.iconChevron)} /></Link>
              }
            </h2>
          </li>
          <li className={classnames(style.todoItem, hasCreatedEnvironment && style.isComplete)}>
            <h2 className={style.todoItemHeader}>
              <Icon name='checkmark-circle' className={classnames(style.icon, style.iconCheckmark)} />
              {hasCreatedEnvironment &&
                'Add an environment'
              }
              {!hasCreatedEnvironment &&
                <Link to='/environments'>Add an environment <Icon name='chevron-right' className={classnames(style.icon, style.iconChevron)} /></Link>
              }
            </h2>
          </li>
          <li className={classnames(style.todoItem, hasTriggeredDeployment && style.isComplete, !canDeploy && style.isDisabled)}>
            <h2 className={style.todoItemHeader}>
              <Icon name='checkmark-circle' className={classnames(style.icon, style.iconCheckmark)} />
              {!hasTriggeredDeployment && canDeploy &&
                <Link to={`/stacks/${stack.owner}/${stack.name}/deploy`}>Deploy a stack <Icon name='chevron-right' className={classnames(style.icon, style.iconChevron)} /></Link>
              }
              {((!hasTriggeredDeployment && !canDeploy) || (hasTriggeredDeployment && canDeploy)) &&
                'Deploy a stack'
              }
            </h2>
          </li>
        </ul>
        <div className={style.aside}>
          {!hasCreatedStack &&
            <Fragment>
              <p>Welcome! Get started by adding your first application stack.</p>
              <p>You will need to link to a Git provider to store your source code and your infrastructure-as-code templates.</p>
            </Fragment>
          }
          {hasCreatedStack && !hasCreatedEnvironment &&
            <Fragment>
              <p>Add an environment to deploy your stack.</p>
              <p>Stackery environments are tied to a linked AWS account and region, and it's common to have multiple environments such as dev, staging, and prod.</p>
            </Fragment>
          }
          {!hasTriggeredDeployment && canDeploy &&
            <Fragment>
              <p>Deploy a stack to an environment linked to your AWS account and selected region.</p>
              <p>Your environment parameters are injected by Stackery during the deployment.</p>
            </Fragment>
          }
          {this.state.isComplete &&
            <Fragment>
              <p>Setup complete, you're awesome!</p>
              <p>Keep up the momentum and go build something great.</p>
            </Fragment>
          }
        </div>
        {this.state.isComplete &&
          <CloseButton onClick={this.handleClose} />
        }
      </section>
    );
  }
}

Completion.propTypes = {
  currentUser: PropTypes.object.isRequired
};

export default Completion;
