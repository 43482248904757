import React from 'react';
import PropTypes from 'prop-types';
import Differ from 'react-differ';
import Drawer from './Drawer';
import style from './Diff.css';

const Diff = (props) => {
  return (
    <Drawer label='Template Diff' isRounded containerClassname={style.container} contentClassname={style.content} labelClassname={style.label}>
      <div className={style.diff}>
        <Differ from={props.originalTemplate || ''} to={props.updatedTemplate} />
      </div>
    </Drawer>
  );
};

Diff.defaultProps = {
  element: 'div',
  containerClassname: style.container,
  contentClassname: style.content,
  isRounded: false
};

Diff.propTypes = {
  originalTemplate: PropTypes.string.isRequired,
  updatedTemplate: PropTypes.string.isRequired
};

export default Diff;
