import React from 'react';
import UAParser from 'ua-parser-js';
import Tabs from '../core/Tabs';
import CodeBlock from '../core/CodeBlock';

const OSX = () => {
  return (
    <CodeBlock
      hasPrompt
      hid='osx install copied'
      lines={[
        'curl -Ls --compressed https://ga.cli.stackery.io/osx/stackery > /usr/local/bin/stackery && chmod a+x /usr/local/bin/stackery'
      ]}
    />
  );
};

const Linux = () => {
  return (
    <CodeBlock
      hasPrompt
      hid='linux install copied'
      lines={[
        'curl -Ls --compressed https://ga.cli.stackery.io/linux/stackery > /usr/local/bin/stackery && chmod a+x /usr/local/bin/stackery'
      ]}
    />
  );
};

const Windows = () => {
  return (
    <CodeBlock
      hid='windows install copied'
      lines={[
        'Download https://ga.cli.stackery.io/windows/stackery.exe',
        'Move stackery.exe to your preferred installation location.'
      ]}
    />
  );
};

const InstallCli = (props) => {
  let initialIndex;

  switch (UAParser(navigator.userAgent).os.name) {
    case 'Mac OS':
      initialIndex = 0;
      break;

    case 'Windows':
      initialIndex = 2;
      break;

    // Default to Linux as different distros will have different OS names
    default:
      initialIndex = 1;
      break;
  }

  return (
    <div>
      <h1>Install Stackery CLI</h1>
      <Tabs
        items={[
          {
            tab: 'OSX',
            panel: OSX
          },
          {
            tab: 'Linux',
            panel: Linux
          },
          {
            tab: 'Windows',
            panel: Windows
          }
        ]}
        initialIndex={initialIndex}
      />
      <h2>Complete the installation by running:</h2>
      <CodeBlock
        hasPrompt
        hid='stackery login copied'
        lines={[
          'stackery login'
        ]}
      />
    </div>
  );
};

export default InstallCli;
