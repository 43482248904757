import React from 'react';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';
import { patterns, hints } from '../../constants/patternHintText';
import CenteredContainer from '../core/CenteredContainer';
import MessageBox from '../core/MessageBox';
import Link from '../core/Link';
import Form from '../core/Form';
import FormRow from '../core/FormRow';
import Input from '../core/Input';
import Button from '../core/Button';
import Icon from '../core/Icon';
import style from './InviteSignUp.css';

const InviteSignUp = props => {
  return (
    <CenteredContainer className={style.container}>
      <DocumentTitle title='Stackery Sign Up' />
      <Icon name='stackery-logo' className={style.logo} />
      <MessageBox alignCenter>
        <p>You've been invited to <strong>{props.inviterEmail}</strong>'s account. <br /> Set up your account now to get started with Stackery.</p>
      </MessageBox>
      <Form onSubmit={props.onFormSubmit}>
        <FormRow label='Set Your Password' hint={hints.password}>
          <Input
            type='password'
            name='password'
            required
            pattern={patterns.password}
            onChange={props.onInputChange}
          />
        </FormRow>
        <Button
          type='submit'
          text='Create Account'
          isLoading={props.isLoading}
        />
        <MessageBox alignCenter>
          <p>Already have an account?{' '} <Link to='/sign-in'>Sign In</Link></p>
        </MessageBox>
      </Form>
    </CenteredContainer>
  );
};

InviteSignUp.propTypes = {
  inviterEmail: PropTypes.string.isRequired,
  error: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired
};

export default InviteSignUp;
