import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Spinner from '../core/Spinner';
import UserNotificationsListItem from './UserNotificationsListItem';
import style from './UserNotificationsList.css';

const UserNotificationsList = props => {
  const listRef = React.useRef(null);
  const isLoading = props.isLoadingNext || props.isLoadingPrev || props.isDeletingAll;

  React.useEffect(() => {
    if (props.isOpen) {
      props.onSetup();
    } else {
      props.onReset();
    }
  }, [props.isOpen]);

  React.useEffect(() => {
    if (listRef.current !== null && listRef.current.scrollTop > 0 && !props.isLoadingNext && !props.isLoadingPrev) {
      listRef.current.scrollTop = 0;
    }
  }, [props.isLoadingNext, props.isLoadingPrev]);

  return (
    <Fragment>
      <ul ref={listRef} className={classnames(style.container, { [style.isLoading]: isLoading, [style.isPending]: props.isDeletingAllPending })}>
        {props.userNotifications.map((notification, i) => (
          <UserNotificationsListItem
            key={`${notification.key}${notification.timestamp}`}
            notification={notification}
            owner={props.currentUser.org}
            regions={props.regions}
            onClose={props.onClose}
            deleteUserNotification={props.deleteUserNotification}
            showDeploymentErrorsModal={props.showDeploymentErrorsModal}
          />
        ))}
      </ul>
      {isLoading &&
        <Spinner />
      }
    </Fragment>
  );
};

UserNotificationsList.propTypes = {
  currentUser: PropTypes.object.isRequired,
  regions: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isLoadingNext: PropTypes.bool.isRequired,
  isLoadingPrev: PropTypes.bool.isRequired,
  isDeletingAll: PropTypes.bool.isRequired,
  onSetup: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  deleteUserNotification: PropTypes.func.isRequired,
  showDeploymentErrorsModal: PropTypes.func.isRequired
};

export default UserNotificationsList;
