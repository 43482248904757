import React from 'react';
import UAParser from 'ua-parser-js';
import Tabs from '../core/Tabs';
import CodeBlock from '../core/CodeBlock';

const macOS = () => {
  return (
    <CodeBlock
      lines={[
        'Using Homebrew, run the following commands:',
        '$ brew tap stackery/tap',
        '$ brew install stackery-cli'
      ]}
    />
  );
};

const Linux = () => {
  return (
    <CodeBlock
      hasPrompt
      lines={[
        `curl -Ls --compressed https://ga.cli.stackery.io/linux/stackery > /usr/local/bin/stackery \
        && chmod a+x /usr/local/bin/stackery`
      ]}
    />
  );
};

const Windows = () => {
  return (
    <CodeBlock
      lines={[
        '# Create C:\\Tools directory and add it to the system PATH',
        `New-Item -ItemType Directory -Force -Path C:\\Tools
[Environment]::SetEnvironmentVariable("Path", $env:Path + ";C:\\Tools", "Machine")`,
        '# Download the stackery CLI to C:\\Tools',
        `Invoke-WebRequest -Uri https://ga.cli.stackery.io/windows/stackery.exe -OutFile C:\\Tools\\stackery.exe`
      ]}
    />
  );
};

const InstallCli = () => {
  let initialIndex;

  switch (UAParser(navigator.userAgent).os.name) {
    case 'Mac OS':
      initialIndex = 0;
      break;

    case 'Windows':
      initialIndex = 2;
      break;

    // Default to Linux as different distros will have different OS names
    default:
      initialIndex = 1;
      break;
  }

  return (
    <div>
      <Tabs
        items={[
          {
            tab: 'macOS',
            panel: macOS
          },
          {
            tab: 'Linux',
            panel: Linux
          },
          {
            tab: 'Windows',
            panel: Windows
          }
        ]}
        initialIndex={initialIndex}
      />
    </div>
  );
};

InstallCli.propTypes = {
};

export default InstallCli;
