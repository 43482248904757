import React, { Component } from 'react';
import cloneDeep from 'clone-deep';
import cfYamlParser from 'cf-yaml-parser';
import { isDarkMode } from '../../utils/prefersColorScheme';
import Editor from '../core/Editor';
import Parameter from '../../resources/parameter';

function substituteParameters (editorContent) {
  if (typeof editorContent !== 'object') {
    return editorContent;
  }
  if (Array.isArray(editorContent)) {
    for (let i = 0; i < editorContent.length; ++i) {
      editorContent[i] = substituteParameters(editorContent[i]);
    }
    return editorContent;
  }
  if (editorContent instanceof Parameter) {
    return { Ref: `${editorContent.ParameterId}` };
  }
  for (const k of Object.keys(editorContent)) {
    editorContent[k] = substituteParameters(editorContent[k]);
  }
  return editorContent;
}

class EditorField extends Component {
  constructor (props) {
    super(props);

    this.state = {
      hasError: false
    };

    this.editorContainer = null;
    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.setupEditor = this.setupEditor.bind(this);
  }

  componentDidMount () {
    this.setupEditor(this.props.defaultValue);
  }

  setupEditor (value) {
    this.editor = window.ace.edit(this.editorContainer);

    this.editor.setOptions({
      tabSize: 2,
      displayIndentGuides: false,
      enableBasicAutocompletion: true,
      enableSnippets: true,
      highlightActiveLine: false,
      showGutter: false,
      showPrintMargin: false,
      theme: isDarkMode() ? 'ace/theme/tomorrow-night' : 'ace/theme/chrome',
      mode: `ace/mode/${this.props.textFormat}`,
      minLines: 2,
      maxLines: Infinity
    });

    if (value !== undefined) {
      if (this.props.textFormat === 'yaml') {
        value = cfYamlParser.toString(substituteParameters(cloneDeep(value)));
        this.editor.session.setValue(value, -1);
      } else {
        if (typeof value === 'string') {
          this.editor.session.setValue(value, -1);
        } else if (value !== undefined) {
          // CF YAML values
          this.editor.session.setValue(cfYamlParser.toString(value), -1);
          this.editor.session.setMode('ace/mode/yaml');
        }
      }
    }

    this.editor.$blockScrolling = Infinity;
    this.editor.renderer.setScrollMargin(5, 5);
    this.editor.session.setUseWrapMode(true);
    this.editor.on('change', this.handleEditorChange);
  }

  handleEditorChange (event) {
    const value = this.editor.getValue();

    if (this.props.textFormat === 'yaml') {
      if (value) {
        try {
          this.props.onChange(this.props.settingId, cfYamlParser(value), event.target);
          this.setState({ hasError: false });
        } catch (err) {
          this.setState({ hasError: true });
        }
      }
    } else {
      // Check if it's valid CF YAML first, treat it as literal if invalid
      try {
        const cfValue = cfYamlParser(value);
        if (
          typeof cfValue === 'object' &&
          Object.keys(cfValue).length === 1 &&
          (Object.keys(cfValue)[0] === 'Ref' || Object.keys(cfValue)[0].startsWith('Fn::'))
        ) {
          this.props.onChange(this.props.settingId, cfYamlParser(value), event.target);
          this.editor.session.setMode('ace/mode/yaml');
        } else {
          this.props.onChange(this.props.settingId, value, event.target);
          this.editor.session.setMode(`ace/mode/${this.props.textFormat}`);
        }
      } catch (err) {
        this.props.onChange(this.props.settingId, value, event.target);
        this.editor.session.setMode(`ace/mode/${this.props.textFormat}`);
      }
    }
  }

  render () {
    return <Editor hasError={this.state.hasError} onRef={ref => (this.editorContainer = ref)} />;
  }
}

export default EditorField;
