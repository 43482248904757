import React from 'react';
import PropTypes from 'prop-types';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import style from './Menu.css';

const Menu = props => {
  const [isEntered, setIsEntered] = React.useState(false);

  const handleMenuItemClick = (event, index, callback) => {
    props.onClose(event, index);

    if (callback && typeof callback === 'function') {
      callback.call();
    }
  };

  const handleEntered = () => {
    setIsEntered(true);
  };

  const handleExited = () => {
    setIsEntered(false);
  };

  return (
    <Popper
      id={`${props.id}-menu`}
      style={props.isOpen ? { zIndex: 1 } : { width: 0, height: 0, overflow: 'hidden' }}
      open={props.isOpen}
      anchorEl={props.anchorRef.current}
      placement='bottom-end'
      role={undefined}
      transition
      disablePortal
    >
      {({ TransitionProps }) => (
        <ClickAwayListener onClickAway={props.onClose}>
          <Grow {...TransitionProps} onEntered={handleEntered} onExited={handleExited} timeout={200}>
            <Paper classes={{ root: style.container }} square>
              {props.options &&
                <MenuList classes={{ root: style.list }} autoFocusItem={props.autoFocus} onKeyDown={undefined}>
                  {props.options.map((option, index) => (
                    <MenuItem
                      key={`${option.id}-${index}`}
                      id={`${props.id}-option-${option.id}`}
                      classes={{ root: style.item }}
                      disableRipple
                      onClick={(event) => handleMenuItemClick(event, index, option.onClick)}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              }
              {props.children && typeof (props.children) === 'function' && props.children({ isOpen: props.isOpen, isEntered: isEntered, onClose: props.onClose })}
            </Paper>
          </Grow>
        </ClickAwayListener>
      )}
    </Popper>
  );
};

Menu.defaultProps = {
  autoFocus: false,
  isOpen: false
};

Menu.propTypes = {
  id: PropTypes.string,
  options: PropTypes.array,
  autoFocus: PropTypes.bool,
  anchorRef: PropTypes.object.isRequired,
  children: PropTypes.func, // This is a render prop function used to pass Menu props (specifically onClose) to arbitrary children
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default Menu;
