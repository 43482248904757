import React from 'react';
import { NavLink } from 'react-router-dom';
import Icon from '../core/Icon';
import Link from '../core/Link';
import style from './SettingsNav.css';

const Sidebar = props => {
  return (
    <nav className={style.container}>
      <div className={style.content}>
        <NavLink className={style.navLink} activeClassName={style.activeNavLink} to='/settings/usage'>Usage <Icon name='chevron-right' className={style.iconChevron} /></NavLink>
        <NavLink className={style.navLink} activeClassName={style.activeNavLink} to='/settings/users'>Users <Icon name='chevron-right' className={style.iconChevron} /></NavLink>
        <NavLink className={style.navLink} activeClassName={style.activeNavLink} to='/settings/password'>Password <Icon name='chevron-right' className={style.iconChevron} /></NavLink>
        <NavLink className={style.navLink} activeClassName={style.activeNavLink} to='/settings/linked-accounts'>Linked AWS Accounts <Icon name='chevron-right' className={style.iconChevron} /></NavLink>
        <NavLink className={style.navLink} activeClassName={style.activeNavLink} to='/settings/build-environment'>Build Environment <Icon name='chevron-right' className={style.iconChevron} /></NavLink>
        <NavLink className={style.navLink} activeClassName={style.activeNavLink} to='/settings/cli-tokens'>CLI Tokens <Icon name='chevron-right' className={style.iconChevron} /></NavLink>
        <NavLink className={style.navLink} activeClassName={style.activeNavLink} to='/settings/integrations'>Integrations <Icon name='chevron-right' className={style.iconChevron} /></NavLink>
        <NavLink className={style.navLink} activeClassName={style.activeNavLink} to='/settings/stack-blueprints'>Stack Blueprints <Icon name='chevron-right' className={style.iconChevron} /></NavLink>
      </div>

      <Link extraClassName={style.licensesLink} text='Acknowledgements' href={`${process.env.PUBLIC_URL}/licenses.txt`} target='_blank' />
    </nav>
  );
};

export default Sidebar;
