import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import LinkButton from '../core/LinkButton';
import LinkProviderButton from '../core/LinkProviderButton';
import style from './InputGate.css';

const InputGate = props => {
  return (
    <div className={style.container}>
      {props.type === 'needsGitAuth' &&
        <Fragment>
          <p>Enable these options by re-authorizing {props.gitProvider.displayName}</p>
          <LinkProviderButton
            provider={props.gitProvider.name}
            label={props.gitProvider.displayName}
            href={props.gitProvider.authUrl}
          />
        </Fragment>
      }
      {props.type === 'needsLinkedAccount' &&
        <Fragment>
          <p>A linked AWS account with permission to deploy is required to enable verification checks.</p>
          <LinkButton
            text='Link an AWS account'
            onClick={(event) => {
              event.preventDefault();
              props.showLinkAwsModal();
            }}
          />
        </Fragment>
      }
      {props.type === 'needsEnabledAccount' &&
        <Fragment>
          <p>A linked AWS account with permission to deploy is required to enable verification checks.</p>
          <LinkButton
            text='Grant permission to a linked AWS account'
            onClick={(event) => {
              event.preventDefault();
              props.showUpdatePipelineDeploymentsModal({ onReload: props.onReload });
            }}
          />
        </Fragment>
      }
      {props.type === 'needsEnabledEnvironment' &&
        <Fragment>
          <p>An environment linked to an AWS account with permission to deploy is required to enable verification checks.</p>
          <LinkButton
            text='Add an environment'
            onClick={(event) => {
              event.preventDefault();
              props.showCreateEnvironmentModal();
            }}
          />
        </Fragment>
      }
    </div>
  );
};

InputGate.propTypes = {
  type: PropTypes.string.isRequired
};

export default InputGate;
