import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import Spinner from '../core/Spinner';
import AppContainer from '../app/AppContainer';
import store, { history } from '../../store';

export default class Root extends Component {
  constructor (props) {
    super(props);

    this.state = {
      store: undefined
    };
  }

  async componentDidMount () {
    this.setState({ store: await store() });
  }

  render () {
    if (this.state.store === undefined) {
      return <Spinner />;
    }

    return (
      <Provider store={this.state.store}>
        <ConnectedRouter history={history}>
          <AppContainer />
        </ConnectedRouter>
      </Provider>
    );
  }
}