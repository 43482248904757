import React from 'react';
import Table from '../core/Table';
import ActionHeader from '../core/ActionHeader';
import Icon from '../core/Icon';
import Input from '../core/Input';
import Spinner from '../core/Spinner';
import Tooltip from '../core/Tooltip';
import RemoveUser from './RemoveUser';
import style from './Settings.css';

const ManageUsers = props => {
  const {
    currentUser,
    users,
    usersInvited,
    isCurrentUserAdmin,
    isLoading,
    onAdminClick,
    onRemoveClick,
    onRemoveInvitedClick
  } = props;

  return (
    <div>
      <ActionHeader
        headerText='Account Users'
        buttonText='Invite New User'
        buttonIcon='invite'
        onClick={props.onInviteClick}
      />
      <Table isStriped isEmpty={isLoading}>
        <thead>
          <tr>
            <th>Email</th>
            <th>Status</th>
            <th>Admin
              <Tooltip className={style.tooltip} isInline message='Admins have permission to invite and delete users and handle billing changes.' place='top'>
                <Icon name='questionCircle' className={style.iconCircle} />
              </Tooltip>
            </th>
            <th />
          </tr>
        </thead>

        {isLoading &&
          <tbody>
            <tr>
              <td colSpan='4'><Spinner position='static' /></td>
            </tr>
          </tbody>
        }

        {!isLoading &&
          <tbody>
            {users && users.map((user, i) => (
              <tr key={i}>
                <td>{user.email}</td>
                <td>{user.status}</td>
                <td>
                  <Input
                    type='checkbox'
                    name='adminCheckbox'
                    id='input-adminCheckbox'
                    disabled={currentUser.email === user.email || !isCurrentUserAdmin}
                    defaultChecked={user.isAdmin}
                    value={user.email}
                    onChange={onAdminClick}
                  />
                </td>
                <td style={{ textAlign: 'right' }}>
                  <RemoveUser
                    currentUser={currentUser}
                    email={user.email}
                    isDisabled={!isCurrentUserAdmin}
                    onRemoveUser={onRemoveClick}
                  />
                </td>
              </tr>
            ))}
            {usersInvited && usersInvited.map((user, i) => (
              <tr key={i}>
                <td>{user.email}</td>
                <td>Invited</td>
                <td>
                  <Input
                    type='checkbox'
                    name='adminCheckbox'
                    id='input-adminCheckbox'
                    disabled
                    defaultChecked={user.isAdmin}
                    value={user.email}
                    onChange={onAdminClick}
                  />
                </td>
                <td style={{ textAlign: 'right' }}>
                  <RemoveUser
                    currentUser={currentUser}
                    email={user.email}
                    isDisabled={!isCurrentUserAdmin}
                    onRemoveUser={onRemoveInvitedClick}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        }
      </Table>
    </div>
  );
};

ManageUsers.propTypes = {
};

export default ManageUsers;
