export default {
  'lambda': (arn) => ({
    type: 'function',
    name: arn.split(':')[6],
    arn
  }),
  'rds': (arn) => ({
    name: arn.split(':')[6],
    arn,
    type: 'database'
  }),
  'dynamodb': (arn) => ({
    name: arn.split('/')[1],
    arn,
    type: 'table'
  }),
  's3': (arn) => ({
    name: arn.split(':')[5],
    arn,
    type: 'objectStore'
  }),
  'kinesis': (arn) => ({
    name: arn.split('/')[1],
    arn,
    type: 'stream'
  }),
  'sns': (arn) => ({
    name: arn.split(':')[5],
    arn,
    type: 'topic'
  }),
  'vpc': (arn) => ({
    name: arn.split('/')[1],
    arn,
    type: 'virtualNetwork'
  })
};
