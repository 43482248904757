import moment from 'moment';

const currentMonth = moment().month();
const currentYear = moment().year();

export const getStartOfMonth = (month, year) => {
  // month is 0-indexed, needs to be 1-indexed for parsing
  return moment(`${year}-${month + 1}`, 'YYYY-M').startOf('month').toISOString();
};

export const getEndOfMonth = (month, year) => {
  // month is 0-indexed, needs to be 1-indexed for parsing
  return moment(`${year}-${month + 1}`, 'YYYY-M').endOf('month').toISOString();
};

export const getStartOfCurrentMonth = () => {
  return getStartOfMonth(currentMonth, currentYear);
};

export const getEndOfCurrentMonth = () => {
  return getEndOfMonth(currentMonth, currentYear);
};
