import React from 'react';
import PropTypes from 'prop-types';
import MuiRadio from '@material-ui/core/Radio';
import style from './Radio.css';

const Radio = (props) => {
  return (
    <MuiRadio
      id={props.id}
      name={props.name}
      classes={{ root: style.container, colorPrimary: style.colorPrimary, checked: style.isSelected, disabled: style.isDisabled }}
      defaultChecked={props.defaultChecked}
      checked={props.checked}
      onChange={props.onChange}
      value={props.value}
      color='primary'
      disabled={props.disabled}
      aria-disabled={props.disabled}
    />
  );
};

Radio.defaultProps = {
  className: style.container,
  required: false
};

Radio.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  onChange: PropTypes.func,
  inputRef: PropTypes.func,
  disabled: PropTypes.bool
};

export default Radio;
