import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import actions from '../../actions/canvas';
import isComputeResource from '../../utils/isComputeResource';
import {
  calculateNodeWidth,
  calculatePortLocation
} from '../../utils/calculateNode';
import {
  nodeDefaultWidth,
  nodeDefaultHeight,
  lineCurveScale
} from '../../constants/canvas';
import style from './Wire.css';

class Wire extends Component {
  constructor (props) {
    super(props);

    this.handleMouseDown = this.handleMouseDown.bind(this);
  }

  handleMouseDown (event) {
    // Prevent mouseDown from bubbling to CanvasContainer and clearing selection
    // Selection will be cleared by wireMouseUp() in CanvasContainer if needed
    event.stopPropagation();

    if (!this.props.editable || !this.props.target) return;

    const canvasRect = document.getElementById('canvas').getBoundingClientRect();
    const x = event.clientX - canvasRect.left;
    const y = event.clientY - canvasRect.top;

    this.props.wireMouseDown(
      this.props.source.id,
      this.props.port,
      this.props.target.id,
      x,
      y
    );
  }

  render () {
    const source = this.props.source;
    const target = this.props.target;
    const sourceType = source && this.props.nodeTypes[source.type];
    const targetType = target && this.props.nodeTypes[target.type];

    if (source && !sourceType) {
      console.warn(
        `Failed to find source node type '${source.type}' for wire from ${source.id}`
      );
      return <g />;
    }
    if (target && !targetType) {
      console.warn(
        `Failed to find target node type '${target.type}' for wire to ${target.id}`
      );
      return <g />;
    }

    let sourceLocation;
    if (source) {
      sourceLocation = calculatePortLocation(
        source,
        this.props.nodeTypes,
        this.props.port,
        this.props.resources
      );
      sourceLocation.x += source.x - calculateNodeWidth(source, this.props.nodeTypes, this.props.resources) / 2;
      sourceLocation.y += source.y - source.height / 2;
    } else {
      sourceLocation = this.props.mouse;
    }

    let targetLocation;
    if (target) {
      targetLocation = calculatePortLocation(target, this.props.nodeTypes, undefined, this.props.resources);
      targetLocation.x += target.x - calculateNodeWidth(target, this.props.nodeTypes, this.props.resources) / 2;
      targetLocation.y += target.y - target.height / 2;
    } else {
      targetLocation = this.props.mouse;
    }

    const dx = targetLocation.x - sourceLocation.x;
    const dy = targetLocation.y - sourceLocation.y;
    const delta = Math.sqrt(dx * dx + dy * dy);

    let scaleX = lineCurveScale;
    let scaleY = 0;

    if (delta < nodeDefaultWidth) { scaleX -= lineCurveScale * (nodeDefaultWidth - delta) / nodeDefaultWidth; }

    if (dx < 0) {
      scaleX +=
        2 *
        Math.min(5 * nodeDefaultWidth, Math.abs(dx)) /
        (5 * nodeDefaultWidth);
      scaleY = (dy > 0 ? 1 : -1) * Math.min((20 * nodeDefaultHeight - Math.abs(dy)) / (3 * Math.abs(dy)), 10) * Math.min(Math.abs(dx) / 50, 1);
    }

    let d =
      `M ${sourceLocation.x} ${sourceLocation.y} ` +
      `C ${sourceLocation.x +
        scaleX * nodeDefaultWidth} ${sourceLocation.y +
        scaleY * nodeDefaultHeight} ` +
      `${targetLocation.x - scaleX * nodeDefaultWidth} ${targetLocation.y -
        scaleY * nodeDefaultHeight} ` +
      `${targetLocation.x} ${targetLocation.y}`;

    if (target && source) {
      const edges = this.props.source.edges;
      let edge;
      if (edges) {
        for (let i = 0; i < edges.length; i++) {
          const element = edges[i];
          if (element.sources.includes(this.props.source.id) && element.targets.includes(this.props.target.id)) {
            edge = element;
            break;
          }
        }
      }

      if (edge && target && source) {
        const sp = edge.sections[0].startPoint;
        const ep = edge.sections[0].endPoint;
        const bps = edge.sections[0].bendPoints;

        /* Elk fails to give endpoints for container targets have the typical
          * margin before the edge turns toward the port. Add that margin in
          * here. */
        const endPointX = ep.x - (target.isContainer ? 10 : 0);

        const points = [];
        points.push(`M ${sourceLocation.x} ${sourceLocation.y}`);
        points.push(`L ${sp.x} ${sp.y}`);
        if (bps) {
          for (let i = 0; i < bps.length; i++) {
            points.push(`L${bps[i].x} ${bps[i].y}`);
          }
        }
        points.push(`L ${endPointX} ${ep.y}`);
        points.push(`L ${targetLocation.x} ${targetLocation.y}`);
        d = points.join(' ');
      }
    }

    const isReference = source && (isComputeResource(source.type) || source.type === 'custom' || (source.type === 'facet' && source.facetType === 'references'));

    return (
      <g
        className={classnames(
          isReference && style.isReference,
          this.props.isSelected && style.isSelected
        )}
        onMouseDown={this.handleMouseDown}
      >
        <path className={style.wire} d={d} />
        <path className={style.background} d={d} />
      </g>
    );
  }
}

export default connect(
  (state, props) => ({
    isSelected:
      props.source &&
      props.target &&
      (
        (
          state.canvas.selectedWire &&
          state.canvas.selectedWire.source === props.source.id &&
          state.canvas.selectedWire.port === props.port &&
          state.canvas.selectedWire.target === props.target.id
        ) ||
        (
          state.canvas.selectedNodes &&
          state.canvas.selectedNodes.some(nodeId => {
            return props.source.id === nodeId || props.target.id === nodeId;
          })
        )
      ),
    mouse:
      !props.source || !props.target ? state.canvas.wiring.mouse : undefined
  }),
  {
    wireMouseUp: actions.wireMouseUp,
    wireMouseDown: actions.wireMouseDown
  }
)(Wire);
