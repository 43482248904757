import { types } from '../actions/api';
import { types as appTypes } from '../actions/app';
import * as states from '../constants/states';

export const initialState = {
  state: states.NEW,
  owner: undefined,
  stackName: undefined,
  data: [],
  hasNewBranch: false,
  defaultBranch: undefined,
  error: undefined
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case appTypes.SELECT_STACK:
      if (action.owner === state.owner && action.stackName === state.stackName) { return state; }
      return {
        ...initialState,
        owner: action.owner,
        stackName: action.name
      };
    case appTypes.DESELECT_STACK:
      return {
        ...initialState
      };
    case types.GET_STACK_BRANCHES.REQUEST:
      return {
        ...state,
        state: states.LOADING
      };
    case types.GET_STACK_BRANCHES.FAILURE:
      return {
        ...state,
        error: action.error.message,
        state: states.FAILED
      };
    case types.GET_STACK_BRANCHES.SUCCESS:
      return {
        ...state,
        state: states.OKAY,
        data: action.data.branches,
        defaultBranch: action.data.defaultBranch,
        error: undefined
      };
    case types.CREATE_BRANCH.SUCCESS:
      if (action.owner !== state.owner && action.stack !== state.stackName) {
        return state;
      }
      return {
        ...state,
        data: state.data.concat({
          name: action.branch,
          reference: action.branch
        }),
        hasNewBranch: true
      };
    case appTypes.SELECT_STACK_BRANCH:
      return {
        ...state,
        hasNewBranch: false
      };
    default:
      return state;
  }
};
