import React from 'react';
import PropTypes from 'prop-types';
import IconButton from './IconButton';
import style from './CloseButton.css';

const CloseButton = props => {
  return (
    <IconButton {...props} iconFont='clear' className={style.container} />
  );
};

CloseButton.defaultProps = {
  size: 'medium'
};

CloseButton.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  onClick: PropTypes.func
};

export default CloseButton;
