import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import apiActions from '../../actions/api';
import appActions from '../../actions/app';
import * as states from '../../constants/states';
import { onTransition } from '../../utils/transition';
import BranchSelector from './BranchSelector';

const mapStateToProps = () => {
  return createSelector(
    (appState) => appState.stack,
    (appState) => appState.stackBranches,
    (appState) => appState.editorNodes,
    (stack, stackBranches, editorNodes) => {
      return {
        stack,
        stackBranches,
        isBranchesFailed: stackBranches.state === states.FAILED,
        isStackDirty: !!(editorNodes.nodesDeleted.length || editorNodes.nodes.some(node => node.dirty))
      };
    }
  );
};

const mapDispatchToProps = {
  notifyUser: appActions.notifyUser,
  selectStackBranch: appActions.selectStackBranch,
  getStackBranches: apiActions.getStackBranches,
  showCreateBranchModal: appActions.showCreateBranchModal,
  createBranch: apiActions.createBranch
};

class BranchSelectorContainer extends Component {
  constructor (props) {
    super(props);

    this.handleBranchChange = this.handleBranchChange.bind(this);
    this.handleCreateBranch = this.handleCreateBranch.bind(this);
  }

  componentDidMount () {
    const {
      stack,
      stackBranches
    } = this.props;

    if (stackBranches.state === states.NEW && stack.name && stack.owner) {
      this.props.getStackBranches(stack.owner, stack.name);
    }

    // Select and set branch if not defined in stack
    if (
      !stack.branch &&
      stackBranches.state === states.OKAY
    ) {
      this.props.selectStackBranch(stackBranches.defaultBranch);
    }
  }

  componentDidUpdate (prevProps) {
    const {
      stack,
      stackBranches
    } = this.props;

    if (stackBranches.state === states.NEW && stack.name && stack.owner) {
      this.props.getStackBranches(stack.owner, stack.name);
    }

    onTransition(prevProps, this.props, 'stackBranches', {
      [states.FAILED]: {
        notify: {
          message: this.props.stackBranches.error,
          level: 'error'
        }
      }
    });

    // Select and set branch if not defined in stack
    if (
      !stack.branch &&
      stackBranches.state === states.OKAY
    ) {
      this.props.selectStackBranch(stackBranches.defaultBranch);
    }
  }

  handleBranchChange (event, name) {
    const branch = name || event.target.value;
    const warning = 'Warning: you will lose any uncommitted work. Are you sure you want to switch branches?';

    if (branch === 'Create Branch...') {
      this.handleCreateBranch();
    } else if (!this.props.isStackDirty || (this.props.isStackDirty && window.confirm(warning))) {
      this.props.selectStackBranch(branch);
    }
  }

  handleCreateBranch () {
    this.props.showCreateBranchModal({ stack: this.props.stack, onSubmit: this.props.createBranch });
  }

  render () {
    return (
      <BranchSelector
        {...this.props}
        onBranchChange={this.handleBranchChange}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BranchSelectorContainer);
