import React from 'react';
import * as localStorage from '../../utils/localStorage';
import isVSCode from '../../utils/isVSCode';
import Panel from '../core/Panel';
import NodeType from './NodeType';
import NodeTypeRef from './NodeTypeRef';
import NodeTypeDragLayer from './NodeTypeDragLayer';
import SearchFilter from '../core/SearchFilter';
import style from './NodePalette.css';

const NodePalette = (props) => {
  const isDraggable = !isVSCode;

  const filteredNodeTypes = Object.keys(props.filteredNodeTypes).filter(type => {
    // Facet resources are managed as properties of parent resources
    if (type === 'facet') {
      return false;
    }

    const definition = props.filteredNodeTypes[type];

    if (definition.hideFromPalette) {
      return false;
    }

    // Hide deprecated nodes unless current stack is using it
    const isDeprecated = props.filteredNodeTypes[type].deprecated;
    const isNodeTypeInUse = props.editorNodes.some(node => node.type === type);
    if (isDeprecated && !isNodeTypeInUse) {
      return false;
    }

    // Some resource types (e.g. errors) can only appear once in a stack
    if (definition.exclusive && isNodeTypeInUse) {
      return false;
    }

    // Hide feature flagged nodes unless a local storage key for the flag is truthy
    const featureFlag = definition.featureFlag;
    if (featureFlag && !localStorage.getItem(`featureFlag:${featureFlag}`)) {
      return false;
    }

    return true;
  }).sort((a, b) => {
    const first = props.filteredNodeTypes[a].paletteLabel;
    const second = props.filteredNodeTypes[b].paletteLabel;

    if (first < second) {
      return -1;
    }

    if (first > second) {
      return 1;
    }

    return 0;
  });

  return (
    <Panel onShadeClick={props.onClose} showShade={false}>
      <h2 className={style.title}>Add Resource</h2>
      <div className={style.search}>
        <SearchFilter searchName='searchName' onSearchChange={props.onSearch} />
      </div>
      <div className={style.container}>
        {filteredNodeTypes.map(type => <NodeType key={type} typeName={type} type={props.filteredNodeTypes[type]} format={props.format} searchTerm={props.searchTerm} isDraggable={isDraggable} />)}
        {props.format === 'stackery' &&
          <NodeTypeRef key='reference' nodeTypes={props.filteredNodeTypes} searchTerm={props.searchTerm} />
        }
        {filteredNodeTypes.length === 0 &&
          <p>No resources found that match <strong>{props.searchTerm}</strong></p>
        }
      </div>
      {isDraggable &&
        <NodeTypeDragLayer nodeTypes={props.filteredNodeTypes} format={props.format} />
      }
    </Panel>
  );
};

export default NodePalette;
