import React from 'react';
import PropTypes from 'prop-types';
import Button from '../core/Button';
import ButtonSet from '../core/ButtonSet';
import LinkButton from '../core/LinkButton';
import style from './JobItemActions.css';

const JobItemActions = props => {
  const {
    stack,
    viewLink,
    isQueued,
    isPreparing,
    isVerifying,
    isFailed,
    isPromoted,
    isPromoting,
    isPromotable,
    isFinished
  } = props;

  const isViewable = viewLink !== undefined;

  return (
    <div className={style.container}>
      {isQueued &&
        <Button id={`${stack.stackName}-queued-cta`} modifier='outline' text='Queued' icon='schedule' isDisabled />
      }
      {isPreparing &&
        <Button id={`${stack.stackName}-deploying-cta`} modifier='outline' text='Deploying...' icon='spinner' isDisabled />
      }
      {isVerifying &&
        <Button id={`${stack.stackName}-deployed-cta`} modifier='outline' text='Verifying...' icon='spinner' isDisabled />
      }
      {isFinished && isViewable &&
        <LinkButton id={`${stack.stackName}-deployed-cta`} modifier='outline' to={viewLink} text='View' icon='eye' />
      }
      {isPromoting &&
        <ButtonSet
          hasBorder={false}
          primaryButton={{
            text: 'Promoting...',
            icon: 'spinner',
            modifier: 'outline',
            isDisabled: true
          }}
          secondaryButton={{
            text: 'View',
            icon: 'eye',
            modifier: 'outline',
            to: viewLink
          }}
        />
      }
      {isPromoted &&
        <ButtonSet
          hasBorder={false}
          primaryButton={{
            text: 'Promoted',
            icon: 'checkmark-circle-outline',
            modifier: 'outline',
            isDisabled: true
          }}
          secondaryButton={{
            text: 'View',
            icon: 'eye',
            modifier: 'outline',
            to: viewLink
          }}
        />
      }
      {isPromotable && !isFailed &&
        <ButtonSet
          hasBorder={false}
          primaryButton={{
            text: 'Promote',
            icon: 'checkmark-circle-outline',
            onClick: props.handlePromote
          }}
          secondaryButton={{
            text: 'View',
            icon: 'eye',
            modifier: 'outline',
            to: viewLink
          }}
        />
      }
      {isPromotable && isFailed &&
        <ButtonSet
          hasBorder={false}
          primaryButton={{
            text: 'Promote',
            icon: 'clear-circle-outline',
            modifier: 'warning',
            onClick: props.handlePromote
          }}
          secondaryButton={{
            text: 'Retry',
            icon: 'replay',
            modifier: 'outline',
            isLoading: props.isRetrying,
            isDisabled: props.isRetried, // using isRetried prevents the `Retry` button from being clickable while a new deployment starts
            onClick: props.handleRetry
          }}
          tertiaryButton={{
            text: 'View',
            icon: 'eye',
            modifier: 'outline',
            to: viewLink
          }}
        />
      }
      {!isPromotable && !isPromoted && isFailed && isViewable &&
        <ButtonSet
          hasBorder={false}
          primaryButton={{
            text: 'Retry',
            icon: 'replay',
            modifier: 'outline',
            isLoading: props.isRetrying,
            isDisabled: props.isRetried,
            onClick: props.handleRetry
          }}
          secondaryButton={{
            text: 'View',
            icon: 'eye',
            modifier: 'outline',
            to: viewLink
          }}
        />
      }
      {!isPromotable && !isPromoted && isFailed && !isViewable &&
        <Button id={`${stack.stackName}-deployed-cta`} modifier='outline' text='Retry' icon='replay' isLoading={props.isRetrying} isDisabled={props.isRetrying} onClick={props.handleRetry} />
      }
    </div>
  );
};

JobItemActions.defaultProps = {
  isQueued: false,
  isStarted: false,
  isFailed: false,
  isSucceeded: false,
  isComplete: false,
  isPreparing: false,
  isPromoted: false,
  isDeployed: false,
  isPrepared: false,
  isFinished: false
};

JobItemActions.propTypes = {
  stack: PropTypes.object.isRequired,
  viewLink: PropTypes.string,
  isQueued: PropTypes.bool.isRequired, // Is deployment queued to prepare
  isPreparing: PropTypes.bool.isRequired, // Is deployment being prepared
  isStarted: PropTypes.bool.isRequired, // Is step activity started
  isFailed: PropTypes.bool.isRequired, // Is step activity failed
  isSucceeded: PropTypes.bool.isRequired, // Did step activity succeed
  isComplete: PropTypes.bool.isRequired, // Did deployment or step activity complete (no steps, success, or failure)
  isPromoted: PropTypes.bool.isRequired, // Is deployment promoted to next stage
  isDeployed: PropTypes.bool.isRequired, // Is deployed on stage
  isPrepared: PropTypes.bool.isRequired, // Is deployment prepared (will deploy automatically if prepare succeeds)
  isFinished: PropTypes.bool.isRequired // Has deployment finished moving through the stages
};

export default JobItemActions;
