export default (settingSchema, resource, getResourceSetting) => {
  const settings = resource.settings;
  const dependsOnSettings = Object.keys(settingSchema.DependsOn || {});

  if (settingSchema.IsHidden) {
    return true;
  }

  for (const dependsOnSetting of dependsOnSettings) {
    const localDependsOnSettingSet = settings[dependsOnSetting] !== null && settings[dependsOnSetting] !== undefined;
    const globalDependsOnSetting = dependsOnSetting ? getResourceSetting(resource.id, resource.facetType, resource.facetId, dependsOnSetting, true) : undefined;
    const dependsOnSettingValue = localDependsOnSettingSet ? settings[dependsOnSetting] : globalDependsOnSetting;

    if (dependsOnSettingValue !== settingSchema.DependsOn[dependsOnSetting]) {
      return true;
    }
  }

  return false;
};
