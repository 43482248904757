import React, { Component } from 'react';
import Wires from './Wires';
import Node from './Node';
import { layoutNodes, getTopLeftPosition } from '../../utils/layoutNodes';
import {
  isContainer,
  calculateLabel,
  calculateNodeHeight,
  calculateNodeTitleBarHeight,
  calculateNodeWidth
} from '../../utils/calculateNode';

class Nodes extends Component {
  /* Container nodes must appear beneath regular nodes. Their types have a
   * zIndex property that specifies how they should be ordered. Regular nodes
   * have a default zIndex value of 0. Container nodes go negative. */
  sort (a, b) {
    const aType = this.props.nodeTypes[a.type];
    const bType = this.props.nodeTypes[b.type];

    return ((aType && aType.zIndex) || 0) - ((bType && bType.zIndex) || 0);
  }

  calculateDisplayProperties (nodes) {
    nodes.forEach(node => {
      node.isContainer = isContainer(node, this.props.nodeTypes, this.props.format);
      node.titleBarHeight = calculateNodeTitleBarHeight(node, this.props.nodeTypes[node.type]);
      node.label = calculateLabel(node, this.props.nodeTypes);
      node.height = calculateNodeHeight(node, this.props.nodeTypes);
      node.width = calculateNodeWidth(node, this.props.nodeTypes, this.props.resources);
      let pos = getTopLeftPosition(node);
      node.top = pos.top;
      node.left = pos.left;
    });
  }

  render () {
    if (this.props.nodes.length === 0) {
      return null;
    }

    // Can't deep copy nodes due to React
    let nodes = this.props.nodes.map(node => Object.assign({}, node));
    this.calculateDisplayProperties(nodes);

    if (!this.props.editable && this.props.format === 'stackery') {
      layoutNodes(nodes);
    }

    return (
      <g>
        {nodes
          .filter(node => isContainer(node, this.props.nodeTypes, this.props.format))
          .sort(this.sort.bind(this))
          .map((node) => {
            return (
              <Node
                key={node.id}
                nodes={this.props.nodes}
                resources={this.props.resources}
                node={node}
                editable={this.props.editable}
                format={this.props.format}
                nodeTypes={this.props.nodeTypes}
              />
            );
          })}

        <Wires
          nodeTypes={this.props.nodeTypes}
          nodes={nodes}
          editable={this.props.editable}
          wiring={this.props.wiring}
          resources={this.props.resources}
        />

        {nodes
          .filter(node => !isContainer(node, this.props.nodeTypes, this.props.format))
          .map((node) => {
            return (<Node
              key={node.id}
              nodes={this.props.nodes}
              resources={this.props.resources}
              node={node}
              editable={this.props.editable}
              format={this.props.format}
              nodeTypes={this.props.nodeTypes}
            />);
          })}
      </g>
    );
  }
}

export default Nodes;
