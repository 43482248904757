import AWS from 'aws-sdk';
import { call, select, putResolve, takeLatest, all, delay } from 'redux-saga/effects';
import actions, { types } from '../actions/cloudwatch';
import * as states from '../constants/states';
import * as formatMetricsTypes from '../constants/metricsTypes';
import { getNodeType } from '../utils/calculateNode';
import { injectContext } from '../resources/manageCFResources';
import contextAddResourceData from '../utils/contextAddResourceData';

function * request () {
  // assign credentials
  const accessKeyId = yield select(state => state.aws.accessKeyId);
  const secretAccessKey = yield select(state => state.aws.secretAccessKey);
  const sessionToken = yield select(state => state.aws.sessionToken);

  const region = yield select(state => {
    return state.stack.environment ? state.environments.data.find(env => env.name === state.stack.environment).region : state.stack.region;
  });

  const cloudwatch = new AWS.CloudWatch({ accessKeyId, secretAccessKey, sessionToken, region });

  // select deployment nodes
  const stackEnvironmentId = yield select(state => state.deployments.stackEnvironmentId);
  const selectedDeploymentId = yield select(state => state.deployments.selectedDeploymentId);
  const format = yield select(state => state.stack.format);
  const { nodes = [], resourceData, resources } = yield select(state => state.deploymentNodes.deployments[selectedDeploymentId]);
  const metricsTypes = formatMetricsTypes[format];
  const range = yield select(state => state.cloudwatch.range);

  const now = new Date();
  const startTime = new Date((now.getTime() - (range * 60 * 1000)) - (60 * 1000));
  const endTime = new Date(now.getTime() - (60 * 1000));

  let requestResults = [];

  // StartTime 1hr 1 minute ago
  // EndTime 1 minute ago
  // Period 1hr in seconds

  const params = {
    StartTime: startTime,
    EndTime: endTime,
    Period: 3600
  };

  yield all(metricsTypes.filter(type => !!type.metrics).map(function * (type) {
    yield all(nodes.filter(node => (getNodeType(node) === type.nodeType)).map(function * (node) {
      params.Namespace = type.namespace;
      params.Dimensions = type.dimensions.map(dimension => {
        let Value;
        let Name;
        if (dimension.getValue) {
          Name = dimension.name;
          Value = dimension.getValue(node, stackEnvironmentId);
        } else if (dimension.value) {
          const logicalId = (node.type === 'implicitApi') ? 'ServerlessRestApi' : node.id;

          // If we can't find the resource, assume this is an existing resource
          const resourceDataId = (logicalId in (resourceData || {})) ? logicalId : `${logicalId}ExistingResource`;

          // If we still can't find the resource, skip it.
          if (!(resourceDataId in resourceData)) {
            return null;
          }

          const context = {
            resourceId: node.id,
            physicalId: resourceData[resourceDataId].physicalId
          };

          /* Hack for Api Gateway, where we need the backend to give us the api
           * name */
          if (node.type === 'api' || node.type === 'implicitApi') {
            context.ApiName = resourceData[resourceDataId].restApi.name;
          }

          // Hack for RDS to know if a db is a cluster or an instance
          if (node.type === 'database') {
            context['SETTING:Type'] = node.Type;
          }

          contextAddResourceData(resourceData[resourceDataId], context);

          const template = resources.cfTemplate();

          if (template.Resources && node.id in template.Resources) {
            const properties = template.Resources[node.id].Properties || {};

            for (const propertyName in properties) {
              context[`PROPERTY:${propertyName}`] = properties[propertyName];
            }
          }

          Value = injectContext(dimension.value, context);
          Name = injectContext(dimension.name, context);
        } else {
          throw new Error(`Node type ${type} dimension ${dimension.name} missing value definition`);
        }

        return {
          Name,
          Value
        };
      });

      try {
        const results = yield all(
          type.metrics.filter(metric => !metric.isDerived).map((metric) =>
            call(() => cloudwatch.getMetricStatistics({
              ...params,
              MetricName: metric.type,
              Unit: metric.unit,
              Statistics: metric.statistics || ['Sum']
            }).promise())
          )
        );

        requestResults.push({
          node,
          data: results.map(result => {
            const metric = type.metrics.filter((metric) => metric.type === result.Label)[0];
            return {
              type: metric.type,
              label: metric.label || result.Label,
              datapoints: result.Datapoints,
              statistics: metric.statistics || ['Sum'],
              isHidden: metric.isHidden,
              factors: metric.factors
            };
          }),
          startTime,
          endTime,
          derivedMetrics: type.metrics.filter(metric => metric.isDerived)
        });
      } catch (error) {
        yield putResolve({ type: types.GET_METRICS.FAILURE, error });
      }
    }));
  }));

  /* After asynchronous work above, check that we are still in the health
   * dashboard on the same deployment. The user may have navigated away since we
   * started requesting metrics. */
  const currentSelectedDeploymentId = yield select(state => state.deployments.selectedDeploymentId);
  if (currentSelectedDeploymentId !== selectedDeploymentId) {
    return;
  }

  if (nodes.length > 0 && requestResults.length === 0) {
    yield putResolve({ type: types.DISABLE_METRICS });
  }

  yield putResolve({
    type: types.GET_METRICS.SUCCESS,
    results: requestResults
  });
}

function * getMetrics () {
  yield request();
}

function * pollMetrics () {
  const _shouldPoll = (state) => {
    const deployments = state.deployments;
    const selectedDeploymentId = deployments.selectedDeploymentId;
    const deploymentNodes = state.deploymentNodes.deployments;
    const isCurrentDeploymentSelected = deployments.currentDeployment && deployments.currentDeployment.id === deployments.selectedDeploymentId;

    return (
      isCurrentDeploymentSelected &&
      state.aws.state === states.OKAY &&
      selectedDeploymentId &&
      !!deploymentNodes[selectedDeploymentId] &&
      deploymentNodes[selectedDeploymentId].state === states.OKAY &&
      deploymentNodes[selectedDeploymentId].resourceData
    );
  };

  while (true) {
    yield delay(60000);
    let shouldPoll = yield select(_shouldPoll);
    if (shouldPoll) {
      yield putResolve(actions.getMetrics());
    }
  }
}

export default function * cloudwatch () {
  yield all([
    takeLatest(types.GET_METRICS.REQUEST, getMetrics),
    pollMetrics()
  ]);
}
