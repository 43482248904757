import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Icon from './Icon';
import Link from './Link';
import style from './LinkButton.css';

const LinkButton = props => {
  let Elem;
  const attrs = props.isDisabled ? { 'aria-disabled': true } : { to: props.to, href: props.href, target: props.target, onClick: props.onClick };
  const isDisplayInline = props.display === 'inline';
  const isDisplayInlineBlock = props.display === 'inline-block';
  const hasIcon = props.icon !== undefined || props.iconFont !== undefined;

  if (props.isDisabled) {
    Elem = 'span';
  } else if (props.href || props.to) {
    Elem = Link;
  } else {
    Elem = 'button';
  }

  return (
    <Elem
      className={classnames(
        props.className,
        props.modifier && style[props.modifier],
        props.layoutClassName,
        hasIcon && style.hasIcon,
        props.isDisabled && style.isDisabled,
        isDisplayInline && style.isInline,
        isDisplayInlineBlock && style.isInlineBlock
      )}
      style={{ display: props.display }}
      data-hid={props.hid}
      {...attrs}
    >
      {hasIcon &&
        <span className={style.hasIcon}>
          {props.iconPlacement === 'start' &&
            <Icon className={style.icon} name={props.icon} font={props.iconFont} />
          }
          <span className={style.text}>{props.text}</span>
          {props.iconPlacement === 'end' &&
            <Icon className={style.icon} name={props.icon} font={props.iconFont} />
          }
        </span>
      }

      {!hasIcon &&
        <span className={style.text}>{props.text}</span>
      }
    </Elem>
  );
};

LinkButton.defaultProps = {
  className: style.container,
  layoutClassName: null, // TODO: refactor prop name as `className`, applied in addition to private className (e.g. classnames(style.container, props.className))
  modifier: 'primary',
  icon: null,
  iconFont: null, // TODO: Refactor prop names so this component accepts `iconSvg` and `iconFont`; then Icon should accept `iconSvg` and `iconFont`
  iconPlacement: 'start',
  display: null,
  onClick: null
};

LinkButton.propTypes = {
  className: PropTypes.string,
  layoutClassName: PropTypes.string,
  modifier: PropTypes.string,
  display: PropTypes.string,
  onClick: PropTypes.func
};

export default LinkButton;
