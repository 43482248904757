import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { DragSource } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { nodeDefaultHeight } from '../../constants/canvas';
import highlightSubstring from '../../utils/highlightSubstring';
import actions from '../../actions/canvas';
import {
  calculateIcon,
  calculateInitialNodeHeight,
  calculateInitialNodeWidth
} from '../../utils/calculateNode';
import * as dragTypes from '../../constants/dragTypes';
import Tooltip from '../core/Tooltip';
import Icon from '../core/Icon';
import style from './NodeType.css';

// Implements the drag source contract
const dragSource = {
  beginDrag (props, monitor, component) {
    return {
      node: {
        type: props.typeName,
        width: props.type.initialWidth,
        height: props.type.initialHeight
      },
      onDrop: component.handleDrop
    };
  }
};

// Specifies the props to inject into the component
function collect (connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview()
  };
}

const mapStateToProps = (state, props) => {
  return {
    newNodes: state.canvas.newNodes
  };
};

const mapDispatchToProps = {
  dragNewNodes: actions.dragNewNodes,
  createResource: actions.createResource
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  DragSource(dragTypes.NODE_TYPE, dragSource, collect)
);

class NodeType extends Component {
  constructor (props) {
    super(props);

    this.isSupported = (!('UnavailableInFormats' in props.type) || !props.type.UnavailableInFormats.includes(props.format));

    this.handleClick = this.handleClick.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
  }

  getNewNode () {
    return {
      type: this.props.typeName,
      unsaved: true
    };
  }

  handleClick (event) {
    event.stopPropagation();

    const node = this.getNewNode();

    if (this.props.format === 'stackery') {
      for (const name in this.props.type.defaults) {
        const def = this.props.type.defaults[name];

        if ('value' in def) {
          node[name] = def.value;
        }
      }
    }

    const canvas = document.getElementById('canvasContainer');
    const canvasRect = canvas.getBoundingClientRect();

    const width = this.props.type.initialWidth || calculateInitialNodeWidth(node, this.props.type);
    const height = this.props.type.initialHeight || calculateInitialNodeHeight(node, this.props.type);

    const marginTopStart = 80;
    const marginLeftStart = 40;
    const padding = 20;
    const left = marginLeftStart + (width / 2);
    const top = marginTopStart + ((this.props.newNodes.length) * (padding + nodeDefaultHeight));

    const x = left + canvas.scrollLeft;
    const y = Math.floor((top) + height / 2) + Math.abs(canvas.scrollTop - canvasRect.top);

    node.x = x;
    node.y = y;
    node.width = width;
    node.height = height;
    node._cache = {};

    if (this.props.format === 'stackery') {
      this.props.dragNewNodes([ node ], x, y, true);
    } else {
      this.props.createResource(node, true);
    }
  }

  handleDrop (position) {
    const node = this.getNewNode();

    if (this.props.format === 'stackery') {
      for (const name in this.props.type.defaults) {
        const def = this.props.type.defaults[name];

        if ('value' in def) {
          node[name] = def.value;
        }
      }
    }

    const width = this.props.type.initialWidth || calculateInitialNodeWidth(node, this.props.type);
    const height = this.props.type.initialHeight || calculateInitialNodeHeight(node, this.props.type);

    const x = Math.floor(position.x + width / 2);
    const y = Math.floor(position.y + height / 2);

    node.x = x;
    node.y = y;
    node.width = width;
    node.height = height;
    node._cache = {};

    if (this.props.format === 'stackery') {
      this.props.dragNewNodes([ node ], x, y, true);
    } else {
      this.props.createResource(node, false);
    }
  }

  componentDidMount () {
    this.props.connectDragPreview(getEmptyImage(), {
      captureDraggingState: true
    });
  }

  render () {
    const {
      type,
      showHint,
      connectDragSource,
      format,
      searchTerm,
      isDraggable
    } = this.props;

    let iconName = calculateIcon(type);

    let nodeType = (
      <button
        className={classnames(style.node, !this.isSupported && style.nodeUnavailable, isDraggable && style.isDraggable)}
        onClick={this.handleClick}
      >
        <span className={
          type.align === 'right' ? style.iconRight : style.icon
        }>
          <Icon name={iconName} />
        </span>
        {type.paletteResource &&
          <p className={style.label}>{type.paletteResource}</p>
        }
        <p className={style.name}>{highlightSubstring(searchTerm, type.paletteLabel)}</p>
      </button>
    );

    if (this.isSupported) {
      nodeType = isDraggable ? connectDragSource(nodeType) : nodeType;
    } else {
      nodeType = (
        <Tooltip message={`${type.paletteLabel} resources are unavailable in ${format} format stacks`}>
          {nodeType}
        </Tooltip>
      );
    }

    return (
      <div id={`node-palette-item-${this.props.typeName}`} className={classnames(style.container)}>
        {nodeType}
        {showHint && type.paletteHint &&
          <p className={style.hint}>{highlightSubstring(searchTerm, type.paletteHint)}</p>
        }
      </div>
    );
  }
}

NodeType.defaultProps = {
  showHint: true
};

export default enhance(NodeType);
