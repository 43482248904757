import React from 'react';
import PropTypes from 'prop-types';
import * as gitProviderStates from '../../constants/gitProviderStates';
import Button from '../core/Button';
import LinkButton from '../core/LinkButton';
import Link from '../core/Link';
import Spinner from '../core/Spinner';
import style from './Modal.css';

const importError = 'If the request timed out, imports will continue to process on the server.';

const ImportBitbucketRepos = props => {
  const bitbucket = props.gitProviders.bitbucket;

  return (
    <div>
      <h1>Import Bitbucket Projects</h1>
      <p>Quickly import existing serverless Bitbucket projects as Stackery stacks. Repos that contain a valid template.yaml file will be imported as a stack with the same name.</p>

      <ul className={style.providerList}>
        {bitbucket.state === gitProviderStates.URL_ACQUIRED &&
          <li className={style.providerListItem}>
            <p>To import your projects from Bitbucket, you need to link your Stackery and Bitbucket accounts.</p>
            <LinkButton
              key='bitbucketOAuth'
              name='bitbucketOAuth'
              href={bitbucket.authUrl}
              target='_new'
              text='Link to Bitbucket'
              icon='bitbucket'
              hid='link to bitbucket'
            />
          </li>
        }

        {bitbucket.state === gitProviderStates.AUTHORIZED &&
          <li className={style.providerListItem}>
            <Button
              text='Import Bitbucket Projects'
              icon='bitbucket'
              onClick={() => props.getGitProjects('bitbucket')}
              isLoading={bitbucket.importedState === gitProviderStates.LOADING}
              hid='import bitbucket projects'
            />
            {bitbucket.importedState === gitProviderStates.LOADING &&
              <p>Fetching Bitbucket projects...</p>
            }
            {bitbucket.importedState === gitProviderStates.OKAY && bitbucket.imported.length > 0 &&
              <p>Successfully imported {bitbucket.imported.length} projects! <Link to='/stacks' onClick={props.hideModal}>View Stacks</Link></p>
            }
            {bitbucket.importedState === gitProviderStates.OKAY && bitbucket.imported.length === 0 &&
              <p>No projects with a valid template.yaml were found or they were already imported. <Link onClick={props.hideModal} to='/stacks'>View Stacks</Link></p>
            }
            {bitbucket.error &&
              <p>Error importing: {bitbucket.error}</p>
            }
            {bitbucket.error === 'Failed to fetch' &&
              <p>{importError}</p>
            }
          </li>
        }
      </ul>

      {bitbucket.state === gitProviderStates.NEW &&
        <Spinner position='static' />
      }
    </div>
  );
};

ImportBitbucketRepos.propTypes = {
  gitProviders: PropTypes.object
};

export default ImportBitbucketRepos;
