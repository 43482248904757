import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ESC } from '../../constants/keyCodes';
import CloseButton from './CloseButton';
import style from './Panel.css';

class Panel extends Component {
  constructor (props) {
    super(props);

    this.state = {
      openTimeout: 100, // delay before opening
      closeTimeout: 300, // delay before enabling close functionality (debounce)
      isOpen: false,
      canClose: false,
      style: {
        top: 0,
        left: 'auto'
      }
    };

    this.handleKeyUp = this.handleKeyUp.bind(this);
  }

  handleKeyUp (event) {
    if (event.keyCode === ESC && this.state.canClose) {
      this.props.onShadeClick();
    }
  }

  componentDidMount () {
    const parentRect = this.container.parentNode.getBoundingClientRect();

    this.setState({
      style: {
        top: parentRect.top,
        left: this.props.showShade ? parentRect.left : 'auto'
      },
      activeElement: document.activeElement
    });

    setTimeout(() => this.setState({ isOpen: true }), this.state.openTimeout);
    setTimeout(() => this.setState({ canClose: true }), this.state.closeTimeout);

    document.addEventListener('keyup', this.handleKeyUp);
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.isOpen && !prevState.isOpen) {
      this.content.querySelector('[tabindex]').focus();
    }
  }

  componentWillUnmount () {
    this.state.activeElement.focus();
    document.removeEventListener('keyup', this.handleKeyUp);
  }

  render () {
    return (
      <aside className={classnames(style.container, this.state.isOpen && style.isOpen)} style={this.state.style} ref={ref => { this.container = ref; }}>
        {this.props.showShade &&
          <div className={style.shade} onClick={this.state.canClose ? this.props.onShadeClick : undefined} />
        }
        <div className={style.panel} ref={ref => { this.content = ref; }}>
          <CloseButton size='medium' onClick={this.state.canClose ? this.props.onShadeClick : undefined} />
          {this.props.children}
        </div>
      </aside>
    );
  }
}

Panel.defaultProps = {
  showShade: true
};

Panel.propTypes = {
  showShade: PropTypes.bool,
  onShadeClick: PropTypes.func
};

export default Panel;
