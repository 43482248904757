import React from 'react';
import classNames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import Icon from './Icon';
import style from './Link.css';
import isVSCode from '../../utils/isVSCode';
import openExternal from '../../utils/openExternal';

const Link = (props) => {
  let Elem;
  let attrs = {};

  const isExternal = props.target && props.target === '_blank';

  if (props.href) {
    Elem = 'a';
    attrs = { href: props.href, target: props.target };
  } else if (props.to) {
    Elem = RouterLink;
    attrs = { to: props.to };
  } else if (props.onClick) {
    Elem = 'button';
  } else {
    Elem = 'span';
  }

  // Open links intended for new tab in external browser when in VSCode
  let onClick = props.onClick;
  if (isVSCode && attrs.href && attrs.target === '_blank' && !props.onClick) {
    onClick = () => openExternal(props.href);
    attrs.href = undefined;
    attrs.target = undefined;
  }

  return (
    <Elem
      key={props.key}
      className={classNames(
        props.className,
        props.type,
        props.extraClassName
      )}
      id={props.id}
      title={props.title}
      data-hid={props.hid}
      onClick={onClick}
      onFocus={props.onFocus}
      {...attrs}
    >
      {props.text && props.text}
      {props.children && props.children}
      {isExternal &&
        <Icon className={style.iconNewWindow} name='new-window' />
      }
    </Elem>
  );
};

Link.defaultProps = {
  className: style.container,
  type: null,
  onClick: null
};

Link.propTypes = {
  className: PropTypes.string,
  extraClassName: PropTypes.string,
  type: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func
};

export default Link;
