import React from 'react';
import PropTypes from 'prop-types';
import ActiveStacksContainer from '../activeStacks/ActiveStacksContainer';

const Usage = props => {
  return (
    <div className={props.className}>
      <ActiveStacksContainer showRangeSelector />
    </div>
  );
};

Usage.propTypes = {
  className: PropTypes.string
};

export default Usage;
