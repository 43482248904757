import React, { Component } from 'react';
import * as states from '../../constants/states';
import Form from '../core/Form';
import FormRow from '../core/FormRow';
import Input from '../core/Input';
import ButtonSet from '../core/ButtonSet';

class EditEnvironmentSecretDescription extends Component {
  constructor (props) {
    super(props);

    this.state = {
      description: props.description
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange (event) {
    this.setState({ description: event.currentTarget.value });
  }

  handleSubmit (event) {
    event.preventDefault();
    this.props.onSubmit(
      this.props.owner,
      this.props.environment,
      this.props.secret,
      this.state.description
    );
  }

  render () {
    return (
      <Form onSubmit={this.handleSubmit}>
        <h1>Edit Secret Description</h1>
        <p>Update description for <strong>{this.props.secret}</strong>.</p>
        <FormRow label='Secret Description'>
          <Input
            id='input-secretDescription'
            name='secretDescription'
            required
            defaultValue={this.state.description}
            inputRef={input => input && input.focus()}
            onChange={this.handleInputChange}
          />
        </FormRow>
        <ButtonSet
          primaryButton={{
            text: 'Update',
            type: 'submit',
            isLoading: this.props.state === states.LOADING
          }}
          secondaryButton={{
            text: 'Cancel',
            onClick: this.props.onClickCancel
          }}
        />
      </Form>
    );
  }
}

EditEnvironmentSecretDescription.propTypes = {
};

export default EditEnvironmentSecretDescription;
