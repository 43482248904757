import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import * as states from '../../constants/providerStates';
import LinkProviderButton from '../core/LinkProviderButton';
import FormRow from '../core/FormRow';
import Input from '../core/Input';
import HelpLink from '../core/HelpLink';

const LinkAws = (props) => {
  return (
    <Fragment>
      <FormRow label='Enable Pipeline Deployments'>
        <Input
          id='input-pipelineDeploymentsCheckbox'
          type='checkbox'
          checked={props.isDeployerRoleEnabled}
          onChange={props.onDeployerRoleChange}
        />
        <HelpLink id='deployer-role-help-link' link='https://docs.stackery.io/docs/using-stackery/verification-pipeline' title='Stackery Verification Pipeline documentation' />
      </FormRow>
      <LinkProviderButton
        provider='aws'
        href={props.consoleUrl}
        label='AWS'
        hasLinked={props.provider.deploymentStatus === states.SUCCESSFUL}
        status={props.provider.deploymentStatus}
        onClick={() => props.monitorNewAccountProviderStatus(props.provider.externalId, props.isDeployerRoleEnabled, props.email)}
        isLoading={props.provider.deploymentStatus === states.IN_PROGRESS}
        hasFailed={props.provider.deploymentStatus === states.FAILED}
      />
      <p><em>Please note: If AWS IoT Core is not supported in the region in which you are trying to link Stackery, the linking process will fail.</em></p>
    </Fragment>
  );
};

LinkAws.propTypes = {
  provider: PropTypes.object.isRequired,
  monitorNewAccountProviderStatus: PropTypes.func.isRequired
};

export default LinkAws;
