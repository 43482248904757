import React from 'react';
import { patterns, hints } from '../../constants/patternHintText';
import MessageBox from '../core/MessageBox';
import StatusMessage from '../core/StatusMessage';
import Form from '../core/Form';
import FormRow from '../core/FormRow';
import Input from '../core/Input';
import Button from '../core/Button';

const ChangePassword = props => {
  return (
    <div className={props.className}>
      <h1>Change Password</h1>
      {props.error &&
        <MessageBox>
          <StatusMessage type='error' text={props.error.message} />
        </MessageBox>
      }
      <Form onSubmit={props.onSubmit}>
        <FormRow label={'Current Password'}>
          <Input
            id='input-oldPassword'
            type='password'
            name='oldPassword'
            required
            onChange={props.onInputChange}
          />
        </FormRow>
        <FormRow label={'New Password'} hint={hints.password}>
          <Input
            id='input-newPassword'
            type='password'
            name='newPassword'
            required
            pattern={patterns.password}
            onChange={props.onInputChange}
          />
        </FormRow>
        <Button
          type='submit'
          text='Change Password'
          isLoading={props.isLoading}
        />
      </Form>
    </div>
  );
};

ChangePassword.propTypes = {
};

export default ChangePassword;
