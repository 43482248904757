import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router';
import * as states from '../../constants/states';
import apiActions from '../../actions/api';
import appActions from '../../actions/app';
import UserNotificationsMenu from './UserNotificationsMenu';

const mapStateToProps = () => {
  return createSelector(
    (appState) => appState.currentUser,
    (appState) => appState.userNotifications,
    (appState) => appState.environments,
    (currentUser, userNotifications, environments) => {
      return {
        currentUser,
        userNotifications,
        regions: environments.data.map(environment => ({ id: environment.id, region: environment.region })),
        hasUserNotifications: userNotifications.data.length > 0,
        unreadCount: userNotifications.unreadCount,
        totalCount: userNotifications.totalCount,
        nextToken: userNotifications.nextToken,
        hasNextPage: userNotifications.nextToken !== null,
        isLoading: userNotifications.state === states.NEW || userNotifications.state === states.LOADING
      };
    }
  );
};

const mapDispatchToProps = {
  getUserNotifications: apiActions.getUserNotifications,
  deleteUserNotification: apiActions.deleteUserNotification,
  deleteAllUserNotifications: apiActions.deleteAllUserNotifications,
  getEnvironments: apiActions.getEnvironments,
  showDeploymentErrorsModal: appActions.showDeploymentErrorsModal
};

const initialState = {
  userNotifications: [],
  currentPage: 0,
  prevPage: undefined,
  nextPage: undefined,
  pageSize: 20,
  pagesLoaded: 0,
  totalPages: 0,
  isLoadingNext: false,
  isLoadingPrev: false,
  isDeletingAllPending: false,
  isDeletingAll: false
};

class UserNotificationsMenuContainer extends Component {
  constructor (props) {
    super(props);

    this.state = {...initialState};

    this.handleLoadNext = this.handleLoadNext.bind(this);
    this.handleLoadPrev = this.handleLoadPrev.bind(this);
    this.handleDeleteAll = this.handleDeleteAll.bind(this);
    this.handleDeleteAllConfirm = this.handleDeleteAllConfirm.bind(this);
    this.handleDeleteAllCancel = this.handleDeleteAllCancel.bind(this);
    this.handleSetup = this.handleSetup.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  componentDidMount () {
    if (this.props.currentUser.org) {
      this.props.getUserNotifications(this.props.currentUser.org, this.state.pageSize * 2);
    }

    // Fetch environments so region is available to error notifications with makeCodebuildLink
    this.props.getEnvironments();

    this.handleSetup();
  }

  componentDidUpdate (prevProps, prevState) {
    if (!prevProps.currentUser.org && this.props.currentUser.org) {
      this.props.getUserNotifications(this.props.currentUser.org, this.state.pageSize);
    }

    if (this.state.currentPage > prevState.currentPage && this.state.currentPage === this.state.pagesLoaded && this.props.hasNextPage) {
      this.props.getUserNotifications(this.props.currentUser.org, this.state.pageSize, this.props.userNotifications.nextToken);
    }

    if (this.props.userNotifications.data.length === 0 && this.state.isDeletingAll) {
      this.handleReset();
    }

    if (
      (this.state.currentPage !== prevState.currentPage && this.state.currentPage < this.state.pagesLoaded) ||
      (this.props.userNotifications.data.length !== prevProps.userNotifications.data.length)
    ) {
      const totalPages = Math.ceil(this.props.userNotifications.totalCount / this.state.pageSize);
      const pagesLoaded = Math.ceil(this.props.userNotifications.data.length / this.state.pageSize);
      const currentPage = totalPages < prevState.totalPages && this.state.currentPage + 1 === prevState.totalPages ? this.state.currentPage - 1 : this.state.currentPage;

      this.setState({
        userNotifications: this.props.userNotifications.data.slice(currentPage * this.state.pageSize, (currentPage + 1) * this.state.pageSize),
        pagesLoaded,
        totalPages,
        currentPage,
        prevPage: currentPage < this.state.currentPage ? currentPage - 1 : this.state.prevPage,
        nextPage: (this.props.hasNextPage || currentPage + 1 < totalPages) ? currentPage + 1 : undefined
      }, () => {
        setTimeout(() => {
          this.setState({
            isLoadingNext: false,
            isLoadingPrev: false
          });
        }, 0);
      });
    }
  }

  handleReset () {
    this.setState({...initialState});
  }

  handleSetup () {
    if (this.props.userNotifications.data.length > 0) {
      const totalPages = Math.ceil(this.props.userNotifications.totalCount / this.state.pageSize);

      this.setState({
        userNotifications: this.props.userNotifications.data.slice(this.state.currentPage, (this.state.currentPage + 1) * this.state.pageSize),
        nextPage: (this.props.hasNextPage || totalPages > 1) ? 1 : undefined,
        pagesLoaded: Math.ceil(this.props.userNotifications.data.length / this.state.pageSize),
        totalPages
      });
    }
  }

  handleLoadPrev () {
    if (this.state.isLoadingPrev) { return; }

    this.setState({
      isLoadingNext: false,
      isLoadingPrev: true,
      prevPage: this.state.prevPage > 0 ? this.state.prevPage - 1 : undefined,
      currentPage: this.state.prevPage,
      nextPage: this.state.currentPage
    });
  }

  handleLoadNext () {
    if (this.state.isLoadingNext) { return; }

    this.setState({
      isLoadingNext: true,
      isLoadingPrev: false,
      prevPage: this.state.currentPage,
      currentPage: this.state.nextPage,
      nextPage: this.state.currentPage < (this.props.pagesLoaded - 1) || this.props.hasNextPage ? this.state.nextPage + 1 : undefined
    });
  }

  handleDeleteAll () {
    this.setState({ isDeletingAllPending: true });
  }

  handleDeleteAllConfirm () {
    this.setState({ isDeletingAll: true }, () => {
      setTimeout(() => {
        this.props.deleteAllUserNotifications(this.props.currentUser.org);
      }, 0);
    });
  }

  handleDeleteAllCancel () {
    this.setState({ isDeletingAllPending: false });
  }

  render () {
    return (
      <UserNotificationsMenu
        {...this.props}
        {...this.state}
        onLoadPrev={this.handleLoadPrev}
        onLoadNext={this.handleLoadNext}
        onDeleteAll={this.handleDeleteAll}
        onDeleteAllConfirm={this.handleDeleteAllConfirm}
        onDeleteAllCancel={this.handleDeleteAllCancel}
        onSetup={this.handleSetup}
        onReset={this.handleReset}
      />
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserNotificationsMenuContainer));
