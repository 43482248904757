export default [
 {name: 'US East (Ohio)', region: 'us-east-2'},
 {name: 'US East (N. Virginia)', region: 'us-east-1'},
 {name: 'US West (N. California)', region: 'us-west-1'},
 {name: 'US West (Oregon)', region: 'us-west-2'},
 {name: 'Asia Pacific (Mumbai)', region: 'ap-south-1'},
 {name: 'Asia Pacific (Seoul)', region: 'ap-northeast-2'},
 {name: 'Asia Pacific (Singapore)', region: 'ap-southeast-1'},
 {name: 'Asia Pacific (Sydney)', region: 'ap-southeast-2'},
 {name: 'Asia Pacific (Tokyo)', region: 'ap-northeast-1'},
 {name: 'Canada (Central)', region: 'ca-central-1'},
 {name: 'EU (Frankfurt)', region: 'eu-central-1'},
 {name: 'EU (Ireland)', region: 'eu-west-1'},
 {name: 'EU (London)', region: 'eu-west-2'},
 {name: 'EU (Paris)', region: 'eu-west-3'},
 {name: 'EU (Stockholm)', region: 'eu-north-1'},
 {name: 'South America (São Paulo)', region: 'sa-east-1'}
];
