import React from 'react';
import ButtonSet from '../core/ButtonSet';

const ExitSetup = (props) => {
  return (
    <div>
      <h1>Congratulations, you're ready to get started!</h1>
      <p>Welcome to your brand new <em>Stackery Dashboard</em>, where you can add, edit, deploy, and manage serverless stacks across environments.</p>
      <iframe width='100%' height='400px' src='https://www.youtube.com/embed/y_8ODIoOEZs' title='Stackery Welcome Video' frameBorder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope' allowFullScreen />
      <p>If you need help or have any questions, we're friendly! Reach out to one of our engineers via the chat widget at the bottom of your screen.</p>

      <ButtonSet
        primaryButton={{
          text: 'Get Started',
          onClick: props.onClickCancel
        }}
      />
    </div>
  );
};

export default ExitSetup;
