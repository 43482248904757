import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push, replace } from 'connected-react-router';
import { createSelector } from 'reselect';
import * as states from '../../constants/states';
import * as modes from '../../constants/modes';
import debounce from '../../utils/debounce';
import getStackFormat from '../../utils/getStackFormat';
import { isDarkMode } from '../../utils/prefersColorScheme';
import appActions from '../../actions/app';
import canvasActions from '../../actions/canvas';
import selectors from '../../selectors';
import Template from './Template';

const mapStateToProps = () => {
  return createSelector(
    (appState) => appState.stack,
    (appState) => appState.editorNodes,
    (appState) => appState.templateEditor,
    selectors.nodeTypes,
    (stack, editorNodes, templateEditor, nodeTypes) => {
      return {
        stack,
        editorNodes,
        templateEditor,
        nodeTypes,
        isTemplateEditingSupported: stack.isTemplateEditingSupported,
        isLoading: stack.mode !== modes.PUBLIC && !stack.isGitless && stack.state !== states.OKAY && (templateEditor.state === states.NEW || templateEditor.state === states.LOADING)
      };
    }
  );
};

const mapDispatchToProps = {
  parseStackTemplate: appActions.parseStackTemplate,
  setStackFormat: appActions.setStackFormat,
  setStackTemplate: appActions.setStackTemplate,
  layoutNodes: canvasActions.layoutNodes,
  invalidateNodes: canvasActions.invalidateNodes,
  deselectStackResource: appActions.deselectStackResource,
  push,
  replace
};

class TemplateContainer extends Component {
  constructor (props) {
    super(props);

    this.state = {
      isLoading: true,
      value: props.editorNodes.remoteTemplate
    };

    this.editorContainer = null;
    this.setupEditor = this.setupEditor.bind(this);
    this.setEditorValue = this.setEditorValue.bind(this);
    this.handleEditorChange = debounce(this.handleEditorChange.bind(this), 500);
  }

  componentDidMount () {
    const {
      stack
    } = this.props;

    if (stack.format && stack.format !== 'stackery') {
      this.setupEditor();
    }

    if (stack.selectedResourceId) {
      this.props.deselectStackResource();
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const {
      stack,
      templateEditor
    } = this.props;

    const {
      stack: prevStack,
      templateEditor: prevTemplateEditor
    } = prevProps;

    if (stack.format && stack.format === 'stackery') {
      return;
    }

    if (!prevStack.format && stack.format && stack.format !== 'stackery') {
      this.setupEditor();
    }

    if (templateEditor.invalidTemplate && templateEditor.invalidTemplate !== templateEditor.invalidTemplate) {
      this.props.invalidateNodes();
    }

    // Update editor value using parsed template when navigating to /template directly or when template was reset by user
    if (
      (templateEditor.isTemplateValid && templateEditor.validTemplate !== prevTemplateEditor.validTemplate && templateEditor.validTemplate !== this.state.value) ||
      (templateEditor.state !== prevTemplateEditor.state && templateEditor.state === states.OKAY) ||
      (templateEditor.isTemplateReset && templateEditor.rawTemplate !== prevTemplateEditor.rawTemplate && templateEditor.rawTemplate !== this.state.value)
     ) {
      this.setEditorValue();
    }

    if (templateEditor.isTemplateValid && templateEditor.validTemplate !== prevTemplateEditor.validTemplate) {
      this.props.setStackFormat(getStackFormat(templateEditor.validTemplate));
    }

    if (stack.version === prevStack.version && prevState.value !== this.state.value && this.state.value !== templateEditor.rawTemplate) {
      this.props.setStackTemplate(this.state.value);
    }
  }

  componentWillUnmount () {
    const {
      rawTemplate,
      validTemplate,
      invalidTemplate
    } = this.props.templateEditor;

    if (rawTemplate !== validTemplate || (invalidTemplate && rawTemplate !== invalidTemplate)) {
      this.props.parseStackTemplate(rawTemplate, getStackFormat(rawTemplate));
    }
  }

  setupEditor () {
    const { templateEditor } = this.props;

    this.editor = window.ace.edit(this.editorContainer);

    this.editor.setOptions({
      tabSize: 2,
      displayIndentGuides: false,
      enableBasicAutocompletion: true,
      enableSnippets: true,
      highlightActiveLine: false,
      showGutter: true,
      showPrintMargin: false,
      theme: isDarkMode() ? 'ace/theme/tomorrow-night' : 'ace/theme/chrome',
      mode: 'ace/mode/yaml'
    });

    if (templateEditor.state === states.OKAY) {
      this.setEditorValue();
    }

    this.editor.$blockScrolling = Infinity;
    this.editor.renderer.setScrollMargin(5, 5);
    this.editor.session.setUseWrapMode(true);
    this.editor.on('change', this.handleEditorChange);
  }

  setEditorValue () {
    const value = this.props.templateEditor.rawTemplate;

    if (value) {
      this.editor.session.setValue(value, -1);
      this.editor.resize();
    } else {
      this.editor.session.setValue(this.props.templateEditor.error ? this.props.templateEditor.error.message : '');
    }

    this.setState({ isLoading: false });
  }

  handleEditorChange (event) {
    this.setState({ value: this.editor.getValue() });
  }

  render () {
    return (
      <Template
        {...this.props}
        isLoading={this.props.isLoading || this.state.isLoading}
        onRef={ref => (this.editorContainer = ref)}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TemplateContainer);
