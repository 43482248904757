import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Link from './Link';
import style from './GitLink.css';

const GitLink = (props) => {
  const {
    url,
    type,
    version,
    codeDir
  } = props.gitLink;

  if (!url) {
    return (
      <span className={props.className}>{props.label || type}</span>
    );
  }

  const versionPart = version ? version.substring(0, 7) : undefined;

  return (
    <Link
      id={props.id}
      extraClassName={classnames(style[props.modifier], props.className)}
      href={url}
      target='_blank'
      text={props.label || type}
      title={`View ${codeDir || versionPart || 'repo'} in ${type}`}
      onClick={(event) => { event.stopPropagation(); }}
    />
  );
};

GitLink.propTypes = {
  className: PropTypes.string,
  modifier: PropTypes.string,
  label: PropTypes.string,
  gitLink: PropTypes.object
};

export default GitLink;
