import { select, all, takeLatest, putResolve, delay } from 'redux-saga/effects';
import { types as appTypes } from '../actions/app';
import { types as tourTypes } from '../actions/tour';

function * startTour ({ component }) {
  const isTourMode = yield select(state => state.currentUser.isTourMode);
  if (isTourMode) {
    yield delay(0);
    yield putResolve({ type: tourTypes.START_TOUR, component });
  }
}

function * endTour () {
  yield putResolve({ type: tourTypes.END_TOUR });
}

export default function * tour () {
  yield all([
    takeLatest(appTypes.MOUNT_COMPONENT, startTour),
    takeLatest(appTypes.UNMOUNT_COMPONENT, endTour)
  ]);
}
