import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { createSelector } from 'reselect';
import url from 'url';
import apiActions from '../../actions/api';
import appActions from '../../actions/app';
import * as states from '../../constants/states';
import { onTransition } from '../../utils/transition';
import InviteSignUp from './InviteSignUp';

const mapStateToProps = () => {
  return createSelector(
    (appState) => appState.inviteSignUp,
    (inviteSignUp) => {
      return {
        inviteSignUp
      };
    }
  );
};

const mapDispatchToProps = {
  push,
  signUp: apiActions.inviteSignUp,
  notifyUser: appActions.notifyUser
};

class InviteSignUpContainer extends Component {
  constructor (props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentDidMount () {
    const parsed = url.parse(window.location.href, true);
    this.email = parsed.query.email;
    this.organization = parsed.query.organization;
    this.token = parsed.query.token;
    this.inviterEmail = parsed.query.inviterEmail;
  }

  componentDidUpdate (prevProps) {
    onTransition(prevProps, this.props, 'inviteSignUp', {
      [states.OKAY]: {
        notify: {
          message: 'Successfully created account',
          level: 'success'
        },
        push: '/signIn'
      },
      [states.FAILED]: {
        notify: {
          message: `Failed to create account: ${this.props.inviteSignUp.error}`,
          level: 'error'
        }
      }
    });
  }

  handleInputChange (event) {
    this[event.target.name] = event.target.value;
  }

  handleFormSubmit (evt) {
    evt.preventDefault();
    this.props.signUp(
      this.email,
      this.organization,
      this.firstName,
      this.lastName,
      this.password,
      this.token
    );
  }

  render () {
    return (
      <InviteSignUp
        inviterEmail={this.inviterEmail}
        error={this.props.inviteSignUp.error}
        isLoading={this.props.inviteSignUp.state === states.LOADING}
        onInputChange={this.handleInputChange}
        onFormSubmit={this.handleFormSubmit}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InviteSignUpContainer);
