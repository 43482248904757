import React from 'react';
import StackLink from './StackLink';

const StackCreatedEvent = (props) => {
  const context = props.context === 'stacks' ? <em>{props.stackName}</em> : <StackLink {...props} />;

  return <span>Project Stack {context} added by {props.userEmail}</span>;
};

export default StackCreatedEvent;
