import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { push, replace } from 'connected-react-router';
import * as localStorage from '../../utils/localStorage';
import appActions from '../../actions/app';
import apiActions from '../../actions/api';
import awsActions from '../../actions/aws';
import * as states from '../../constants/states';
import * as planTypes from '../../constants/planTypes';
import selectors from '../../selectors';
import Stack from './Stack';

const getTitle = (stack) => {
  let title = 'Stackery';

  if (stack.name) {
    title += ` ${stack.name}`;
  }

  if (stack.environment) {
    title += ` (${stack.environment})`;
  }

  return title;
};

const mapStateToProps = (state, props) => {
  return createSelector(
    (appState) => appState.stack,
    selectors.nodeTypes,
    (appState) => appState.deployments,
    (appState) => appState.gitProviders,
    (appState) => appState.editorNodes,
    (appState) => appState.templateEditor,
    (appState) => appState.account,
    (appState) => appState.currentUser,
    (stack, nodeTypes, deployments, gitProviders, editorNodes, templateEditor, account, currentUser) => {
      return {
        stack,
        title: getTitle(stack),
        nodeTypes,
        deployments,
        prepareError: deployments.prepareError,
        gitProviders,
        queryParams: localStorage.getItem(`stackery-edit-${stack.owner}${stack.name}`) || '',
        isLoaded: !!stack.id,
        isStackDirty: !!(editorNodes.nodesDeleted.length || editorNodes.nodes.some(node => node.dirty) || templateEditor.isTemplateDirty),
        isGitless: account.plan.type === planTypes.DEVELOPER || stack.isGitless,
        isDevPlan: account.plan.type === planTypes.DEVELOPER,
        isLocalMode: currentUser.isLocalMode
      };
    }
  );
};

const mapDispatchToProps = {
  push,
  replace,
  selectStack: appActions.selectStack,
  deselectStack: appActions.deselectStack,
  setStackMode: appActions.setStackMode,
  getStack: apiActions.getStack,
  getDeployments: apiActions.getDeployments,
  deleteStack: apiActions.deleteStack,
  renameStack: apiActions.renameStack,
  setStackRepo: apiActions.setStackRepo,
  showActiveStacksLimitModal: appActions.showActiveStacksLimitModal,
  showDeleteStackModal: appActions.showDeleteStackModal,
  showRenameStackModal: appActions.showRenameStackModal,
  showEditRepoModal: appActions.showEditRepoModal,
  refreshToken: awsActions.refreshToken,
  getConfigKeys: apiActions.getConfigKeys,
  notifyUser: appActions.notifyUser
};

class StackContainer extends Component {
  constructor (props) {
    super(props);

    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleRenameClick = this.handleRenameClick.bind(this);
    this.handleRepoClick = this.handleRepoClick.bind(this);
  }

  componentDidMount () {
    const {
      owner,
      stack: name
    } = this.props.match.params;

    this.props.getStack(owner, name);
    this.props.selectStack(owner, name);
  }

  componentDidUpdate (prevProps) {
    const {
      stack: prevStack,
      prepareError: prevPrepareError,
      isLoaded: prevIsLoaded
    } = prevProps;

    const {
      deployments,
      stack,
      prepareError,
      isLoaded
    } = this.props;

    if (!stack.owner || !stack.name) {
      this.props.push(`/stacks`);
    }

    if (prevStack.error === undefined && stack.error !== undefined && (stack.error.statusCode === 404 || stack.error.statusCode === 500)) {
      this.props.notifyUser(stack.error.message, 'error');
      this.props.replace(`/stacks`);
    }

    if (isLoaded && !prevIsLoaded && !stack.isGitless) {
      this.props.getConfigKeys(stack.owner, stack.name);
    }

    if (
      stack.environment &&
      (prevStack.environment !== stack.environment || deployments.state === states.NEW)
    ) {
      this.props.refreshToken(stack.environment);
      this.props.getDeployments(stack.owner, stack.name, stack.environment);
    }

    // Replace route if stack is renamed
    if (prevStack.name && prevStack.name !== stack.name) {
      const newPath = window.location.pathname.replace(prevStack.name, stack.name);

      this.props.push(newPath);
    }

    if (prepareError && prevPrepareError !== prepareError) {
      if (prepareError.includes('active stacks deployed')) {
        this.props.showActiveStacksLimitModal({ prepareError });
      } else {
        this.props.notifyUser(prepareError, 'error');
      }
    }
  }

  componentWillUnmount () {
    this.props.deselectStack();
  }

  handleDeleteClick (stack) {
    this.props.showDeleteStackModal({ stack, onSubmit: this.props.deleteStack });
  }

  handleRenameClick (stack) {
    this.props.showRenameStackModal({ stack, onSubmit: this.props.renameStack });
  }

  handleRepoClick () {
    this.props.showEditRepoModal({ stack: this.props.stack, onSubmit: this.props.setStackRepo });
  }

  render () {
    return (
      <Stack
        {...this.props}
        onDeleteClick={this.handleDeleteClick}
        onRenameClick={this.handleRenameClick}
        onRepoClick={this.props.stack.isGitless ? undefined : this.handleRepoClick}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StackContainer);
