import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Link from '../core/Link';
import Spinner from '../core/Spinner';
import ActionHeader from '../core/ActionHeader';
import Table from '../core/Table';
import Icon from '../core/Icon';
import ContextMenu from '../core/ContextMenu';
import HelpLink from '../core/HelpLink';
import style from './Settings.css';

const isUpToDate = (templateVersion, providerVersion) => templateVersion === providerVersion;

const consoleUrl = (region, templateUrl) => `https://${region}.console.aws.amazon.com/cloudformation/home?region=${region}#/stacks/update/template?templateURL=${templateUrl}&stackId=stackery`;

const LinkedAccounts = props => {
  const {
    providers,
    template
  } = props.account;

  const {
    isLoading,
    showLinkAwsModal,
    showUnlinkAwsModal,
    showUpdateStackeryRoleModal,
    showUpdatePipelineDeploymentsModal
  } = props;

  const hasProviders = providers.length > 0;

  return (
    <div>
      <ActionHeader
        headerText='Linked AWS Accounts'
        buttonText='Link New AWS Account'
        buttonIcon='plus'
        onClick={props.onLinkAccountClick}
      />

      <p>Linked AWS accounts are used for creating environments. To unlink an AWS account login to the AWS console and delete the stack named stackery or use the <Link href='https://docs.stackery.io/docs/api/cli/stackery_aws_unlink/' target='_blank' rel='noopener noreferrer'>CLI</Link>.</p>

      <Table isStriped isEmpty={!hasProviders}>
        <thead>
          <tr>
            <th>Provider</th>
            <th>Account</th>
            <th><span>Stackery Role Version</span> <HelpLink link='https://docs.stackery.io/docs/using-stackery/stackery-role/' title='Stackery Role documentation' /></th>
            <th><span>Pipeline Deployments</span> <HelpLink link='https://docs.stackery.io/docs/using-stackery/verification-pipeline' title='Stackery Verification Pipeline documentation' /></th>
            <th />
          </tr>
        </thead>

        {isLoading &&
          <tbody>
            <tr>
              <td colSpan='5'><Spinner position='static' /></td>
            </tr>
          </tbody>
        }

        {!isLoading && !hasProviders &&
          <tbody>
            <tr>
              <td colSpan='5'>There are no AWS accounts linked yet. <Link onClick={() => showLinkAwsModal()}>Link an AWS account</Link> to get started.</td>
            </tr>
          </tbody>
        }

        {!isLoading && hasProviders &&
          <tbody>
            {providers.map((provider, i) => (
              <tr key={provider.accountId}>
                <td>{provider.type}</td>
                <td>
                  {provider.accountAlias &&
                    <Fragment>
                      {provider.accountAlias} <span className={style.muted}>({provider.accountId})</span>
                    </Fragment>
                  }
                  {!provider.accountAlias && provider.accountId}
                </td>
                <td>
                  {isUpToDate(template.version, provider.version) &&
                    <span>{provider.version} is up to date <Icon name='checkmark' className={style.iconCheckmark} /></span>
                  }
                  {!isUpToDate(template.version, provider.version) &&
                    <span>{provider.version || 'version'} is out of date</span>
                  }
                </td>
                <td>
                  {provider.isDeployerRoleEnabled &&
                    <span>enabled</span>
                  }
                  {!provider.isDeployerRoleEnabled &&
                    <span>disabled</span>
                  }
                </td>
                <td>
                  <ContextMenu
                    items={[
                      !isUpToDate(template.version, provider.version) &&
                        {
                          label: 'Update Stackery Role',
                          onClick: showUpdateStackeryRoleModal,
                          params: {
                            href: consoleUrl(provider.region, template.url),
                            latest: template.version
                          }
                        },
                      {
                        label: (!provider.isDeployerRoleEnabled ? 'Enable' : 'Disable') + ' Pipeline Deployments',
                        onClick: showUpdatePipelineDeploymentsModal
                      },
                      {
                        label: 'Unlink AWS Account',
                        onClick: showUnlinkAwsModal
                      }
                    ]}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        }
      </Table>
    </div>
  );
};

LinkedAccounts.propTypes = {
  account: PropTypes.shape({
    providers: PropTypes.array.isRequired,
    template: PropTypes.object.isRequired
  }).isRequired,
  showLinkAwsModal: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default LinkedAccounts;
