import React from 'react';
import ButtonSet from '../core/ButtonSet';
import Link from '../core/Link';
import InstallCli from './InstallCli';
import style from './GetTools.css';

const GetTools = () => {
  return (
    <div className={style.container}>
      <h2>Get Stackery Development Tools</h2>
      <p>Set up the Stackery CLI and Stackery VS Code Extension to try the smoothest workflow for developing serverless applications.</p>
      <h3>Install Stackery CLI</h3>
      <p>Use the Stackery CLI to create, locally edit, and deploy stacks from the command line.</p>
      <InstallCli />

      <h3>Install Stackery Serverless Tools VS Code Extension</h3>
      <p><Link href='https://marketplace.visualstudio.com/items?itemName=stackery.stackery' text='Stackery Serverless Tools VS Code extension' target='_blank' rel='noopener noreferrer' /> lets you visually edit your architecture right in VS Code.  Check it out in the <Link href='https://marketplace.visualstudio.com/items?itemName=stackery.stackery' text='VS Code Marketplace' target='_blank' rel='noopener noreferrer' /> to add Stackery to VS Code.</p>
      <ButtonSet
        primaryButton={{
          text: 'Finish Setup',
          to: '/?setup=true'
        }}
        secondaryButton={{
          text: 'Skip Step',
          to: '/?setup=true'
        }}
      />
    </div>
  );
};

GetTools.propTypes = {
};

export default GetTools;
