import React, { Fragment } from 'react';
import EnvironmentLink from './EnvironmentLink';

const SecretModifiedEvent = (props) => {
  const context = props.context === 'environments' ? '' : <Fragment>in <EnvironmentLink {...props} /></Fragment>;

  return <Fragment>Secret <em>{props.secretName}</em> modified {context} by {props.userEmail}</Fragment>;
};

export default SecretModifiedEvent;
