import React from 'react';
import * as path from 'path';
import ButtonSet from '../core/ButtonSet';

const ConfirmPathRemove = (props) => {
  const isFile = props.isFile === undefined ? path.extname(props.path) !== '' : props.isFile;
  const type = isFile ? 'file' : 'directory';

  return (
    <div>
      <h1>Remove {props.path} {type}?</h1>
      <p>The {props.settingId} {type} <strong>{props.path}</strong> will be deleted from the project.</p>
      <p>How would you like to proceed?</p>
      <ButtonSet
        primaryButton={{
          text: `Remove ${type}`,
          onClick: props.onClickConfirm
        }}
        secondaryButton={{
          text: 'Do nothing',
          onClick: props.onClickCancel
        }}
      />
    </div>
  );
};

export default ConfirmPathRemove;
