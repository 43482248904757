import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { ESC } from '../../constants/keyCodes';
import useEventListener from '../../utils/useEventListener';
import Menu from './Menu';
import Icon from './Icon';
import IconButton from './IconButton';
import Spinner from './Spinner';
import style from './ButtonMenu.css';

const ButtonMenu = props => {
  const [isOpen, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === ESC) {
      setOpen(false);
    }
  };

  useEventListener('keydown', handleKeyDown);

  const containerClassNames = classnames(style.container, isOpen && style.isOpen);

  return (
    <Grid container direction='column' alignItems='flex-end'>
      {props.isIconButton &&
        <IconButton {...props} className={containerClassNames} anchorRef={anchorRef} onClick={handleToggle} />
      }
      {!props.isIconButton &&
        <ButtonGroup
          id={props.id}
          classes={{ contained: containerClassNames, groupedContainedPrimary: style.group }}
          variant='contained'
          color='primary'
          ref={anchorRef}
          disableRipple
          aria-label={props.label}
        >
          <Button
            id={`${props.id}-primary`}
            classes={{ contained: style.primary, label: style.label }}
            startIcon={<Icon className={style.icon} name={props.icon} />}
            onClick={props.onClick}
          >
            <span>{props.label}</span>
            {props.isLoading &&
              <Spinner className={style.spinner} height='20px' />
            }
          </Button>
          <Button
            id={`${props.id}-toggle`}
            classes={{ contained: style.toggle }}
            aria-controls={isOpen ? `${props.id}-menu` : undefined}
            aria-expanded={isOpen ? 'true' : undefined}
            aria-label={`${props.label} options`}
            aria-haspopup
            onClick={handleToggle}
          >
            <Icon className={style.icon} name='chevron-down' />
          </Button>
        </ButtonGroup>
      }
      <Menu
        {...props}
        anchorRef={anchorRef}
        isOpen={isOpen}
        onClose={handleClose}
      />
    </Grid>
  );
};

ButtonMenu.defaultProps = {
  autoFocus: false,
  isIconButton: false
};

ButtonMenu.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.string,
  options: PropTypes.array,
  autoFocus: PropTypes.bool,
  isIconButton: PropTypes.bool.isRequired
};

export default ButtonMenu;
