import cloneDeep from 'clone-deep';
import parse from './parse';
import addResource from './addResource';
import addReference from './addReference';
import deleteReference from './deleteReference';
import updateResourceSetting from './updateResourceSetting';
import getResourceSetting from './getResourceSetting';
import updateFacetSetting from './updateFacetSetting';
import deleteResource from './deleteResource';
import addIntegration from './addIntegration';
import updateIntegrationSetting from './updateIntegrationSetting';
import deleteIntegration from './deleteIntegration';
import addPermission from './addPermission';
import updatePermission from './updatePermission';
import deletePermission from './deletePermission';
import putVirtualNetworkPlacement from './putVirtualNetworkPlacement';
import deleteVirtualNetworkPlacement from './deleteVirtualNetworkPlacement';
import validateResourceSettings from './validateResourceSettings';
import validateResourceIntegrations from './validateResourceIntegrations';

export { default as Parameter } from './parameter';
export { default as EnvConfigParameter } from './envConfigParameter';

/**
 * Class representing state of CF/SAM template
 *
 * @property {Object} template - CF SAM template
 * @property {Object.<String, Resource>} resources - Stackery resources where the key is the main resource CF/SAM logical Id
 * @property {Integration[]} integrations - Stackery integrations
 * @property {Object.<String, VirtualNetworkPlacement>} virtualNetworkPlacements - Stackery virtual network placements where the key is the main resource CF/SAM logical Id of the Stackery resource placed inside a virtual network
 * @property {Object.<String, Object.<String, Id>>} references - Stackery environment value references where the first key dimension is the resource CF/SAM logical Id of the Stackery compute resource, the second key dimension is the environment variable name, and the value is the resource Id info for the referenced resource
 * @property {Object.<String, Permission[]} permissions - Stackery permissions where the key is the resource CF/SAM logical Id of the Stackery compute resource and the value is an array of permissions granted to the resource
 * @property {Object.<String, Parameter} parameters - Stackery parameters where the key is the CF/SAM parameter Id
 */
export default class State {
  /**
   * Create a State instance from a CF/SAM template
   *
   * @param {*} template - CF/SAM template in JSON format
   * @param {String} format - Template format ('SAM' or 'serverless')
   */
  constructor (template, format, isDeployView = false) {
    // Make sure the template is a valid type
    if (!template || typeof template !== 'object') {
      template = {};
    }

    /* Clear out invalid template sections (this can occur if someone clears
     * out a section but leaves the key there, in which case its value becomes
     * `null` instead of an empty object) */
    for (const section of [ 'Resources', 'Conditions', 'Parameters', 'Metadata' ]) {
      if (section in template && (!template[section] || typeof template[section] !== 'object')) {
        delete template[section];
      }
    }

    this.parseAndSetInstanceState(template, format, isDeployView);
  }

  /**
   * Add a new Stackery resource
   *
   * @param {String} type - Resource type
   * @param {String} [generatedId] - CloudFormation logical Id for primary resource
   * @returns {String} - CloudFormation logical Id for primary resource
   */
  addResource (type, generatedId) {
    logCall('addResource', [ ...arguments ]);

    return addResource.apply(this, arguments);
  }

  /**
   * Add a default references and permissions from one resource to another
   *
   * @param {String} sourceId - Source's resource Id
   * @param {String} targetId - Target's resource Id
   */
  addReference (sourceId, targetId) {
    logCall('addReference', [...arguments]);

    addReference.apply(this, arguments);
  }

  /**
   * From source resource, delete all references and permissions to target resource
   *
   * @param {String} sourceId - Source's resource Id
   * @param {String} targetId - Target's resource Id
   */
  deleteReference (sourceId, targetId) {
    logCall('deleteReference', [...arguments]);

    deleteReference.apply(this, arguments);
  }

  /**
   * Validate resource settings
   * returns a list of errors with corresponding settingIds.
   *
   * @param {String} resourceId - Resource Id for resource to validate
   */
  validateResourceSettings (resourceId) {
    validateResourceSettings.apply(this, arguments);
  }

  /**
   * Validate resource integrations
   * returns a list of errors with corresponding settingIds.
   *
   * @param {String} resourceId - Resource Id for resource to validate
   */
  validateResourceIntegrations (resourceId) {
    validateResourceIntegrations.apply(this, arguments);
  }

  /**
   * Update resource setting
   *
   * @param {String} resourceId - Resource Id for resource to update
   * @param {String} settingName - Name of setting to update
   * @param {*} value - New value
   */
  updateResourceSetting (resourceId, settingName, value) {
    logCall('updateResourceSetting', [ ...arguments ]);

    updateResourceSetting.apply(this, arguments);

    if (this.reparseRequired) {
      this.reparseTemplate();
    }
  }

  /**
   * Get resource setting value
   *
   * @param {String} resourceId - Resource Id for resource to get setting value for
   * @param {String} facetType - Facet type for facet to get setting value for, or undefined
   * @param {String} facetId - Facet Id for facet to get setting value for, or undefined
   * @param {String} settingName - Name of setting to retrieve
   * @param {String} defaultOnly - Only return the value of the global default for the setting, if any
   *
   * @returns {String} - Value of resource setting
   */
  getResourceSetting (resourceId, facetType, facetId, settingName, defaultOnly) {
    return getResourceSetting.apply(this, arguments);
  }

  /**
   * Update facet setting
   *
   * @param {String} resourceId - Resource Id for facet to update
   * @param {String} facetType - Facet type for facet to update
   * @param {String} facetId - Facet Id for facet to update
   * @param {String} settingName - Name of setting to update
   * @param {*} value - New value
   */
  updateFacetSetting (resourceId, facetType, facetId, settingName, value) {
    logCall('updateFacetSetting', [ ...arguments ]);

    updateFacetSetting.apply(this, arguments);

    if (this.reparseRequired) {
      this.reparseTemplate();
    }
  }

  /**
   * Delete a resource
   *
   * @param {String} resourceId - Resource Id for resource to delete
   */
  deleteResource (resourceId) {
    logCall('deleteResource', [ ...arguments ]);

    deleteResource.apply(this, arguments);
  }

  /**
   * Add an integration from a source resource to a target resource
   *
   * @param {String} sourceResourceId - Resource Id for source resource
   * @param {String} targetResourceId - Resource Id for target resource
   * @param {String} [facetType] - Facet type of source resource
   * @param {Object} [facetId] - Facet Id of source resource
   */
  addIntegration (sourceResourceId, targetResourceId, facetType, facetId) {
    logCall('addIntegration', [ ...arguments ]);

    addIntegration.apply(this, arguments);

    if (this.reparseRequired) {
      this.reparseTemplate();
    }
  }

  /**
   * Update integration setting
   *
   * @param {String} sourceResourceId - Resource Id for source resource
   * @param {String} targetResourceId - Resource Id for target resource
   * @param {String} settingName - Name of setting to update
   * @param {*} value - New value
   */
  updateIntegrationSetting (sourceResourceId, targetResourceId, settingName, value) {
    logCall('updateIntegrationSetting', [ ...arguments ]);

    updateIntegrationSetting.apply(this, arguments);
  }

  /**
   * Delete an integration
   *
   * @param {String} sourceResourceId - Resource Id for source resource
   * @param {String} targetResourceId - Resource Id for target resource
   * @param {String} [facetType] - Facet type of source resource
   * @param {Object} [facetId] - Facet Id of source resource
   */
  deleteIntegration (sourceResourceId, targetResourceId, facetType, facetId) {
    logCall('deleteIntegration', [ ...arguments ]);

    deleteIntegration.apply(this, arguments);

    if (this.reparseRequired) {
      this.reparseTemplate();
    }
  }

  /**
   * Add a permission to a compute resource
   *
   * Options for SAM_POLICY permission type:
   * * Required: options.policyName
   * * Required: Either options.targetId or options.parameters
   * * Optional: options.WithDependency
   *
   * Options for IAM_STATEMENT permission type:
   * * Required: options.actions
   * * Required: Either options.targetId or options.resources
   * * Optional: options.WithDependency
   *
   * Options for IAM_POLICY permission type:
   * * Required: options.policyName
   *
   * @param {Resource} resourceId - Resource Id of resource to grant permission
   * @param {Object} options
   * @param {Permission.type} options.PermissionType - Type of permission
   * @param {String} options.PolicyName - SAM or IAM policy name
   * @param {String} options.TargetId - Target Id of resource to grant permission to
   * @param {Boolean} [options.WithDependency=true] - Whether to add a dependency on the target resource
   * @param {Object} options.Parameters - Manually specify SAM policy parameters
   * @param {String[]} options.Actions - IAM actions for a custom IAM statement permission
   * @param {*|*[]} options.Resources - Resources for custom IAM statement permission
   * @param {Number} options.InsertBefore - Index to insert the permission before in the state
   */
  addPermission (resourceId, options) {
    logCall('addPermission', [ ...arguments ]);

    addPermission.apply(this, arguments);
  }

  /**
   * Replace a permission on a compute resource
   *
   * Options for SAM_POLICY permission type:
   * * Required: options.policyName
   * * Required: Either options.targetId or options.parameters
   * * Optional: options.WithDependency
   *
   * Options for IAM_STATEMENT permission type:
   * * Required: options.actions
   * * Required: Either options.targetId or options.resources
   * * Optional: options.WithDependency
   *
   * Options for IAM_POLICY permission type:
   * * Required: options.policyName
   *
   * @param {Resource} resourceId - Resource Id of resource to grant permission
   * @param {Number} permissionIndex - Index of existing permission to replace
   * @param {Object} options
   * @param {Permission.type} options.PermissionType - Type of permission
   * @param {String} options.PolicyName - SAM or IAM policy name
   * @param {String} options.TargetId - Target Id of resource to grant permission to
   * @param {Boolean} [options.WithDependency=true] - Whether to add a dependency on the target resource
   * @param {Object} options.Parameters - Manually specify SAM policy parameters
   * @param {String[]} options.Actions - IAM actions for a custom IAM statement permission
   * @param {*|*[]} options.Resources - Resources for custom IAM statement permission
   */
  updatePermission (resourceId, permissionIndex, options) {
    logCall('updatePermission', [ ...arguments ]);

    updatePermission.apply(this, arguments);
  }

  /**
   * Delete a permission on a compute resource
   *
   * Either options.PermissionIndex may be specified or the options for the
   * permission to find and delete may be specified.
   *
   * @param {String} resourceId - Resource Id of resource to remove permission from
   * @param {Object} options
   * @param {Number} options.PermissionIndex - Index of existing permission to remove
   */
  deletePermission (resourceId, options) {
    logCall('deletePermission', [ ...arguments ]);

    deletePermission.apply(this, arguments);
  }

  /**
   * Place a resource into a virtual network, overriding existing placement
   *
   * @param {String} resourceId - Resource Id of resource to place in virtual network
   * @param {String} virtualNetworkId - Resource Id of virtual network to place resource into
   */
  putVirtualNetworkPlacement (resourceId, virtualNetworkId) {
    logCall('putVirtualNetworkPlacement', [ ...arguments ]);

    putVirtualNetworkPlacement.apply(this, arguments);
  }

  /**
   * Remove a resource from a virtual network
   *
   * @param {String} resourceId - Resource Id of resource to remove from virtual network
   */
  deleteVirtualNetworkPlacement (resourceId) {
    logCall('deleteVirtualNetworkPlacement', [ ...arguments ]);

    deleteVirtualNetworkPlacement.apply(this, arguments);
  }

  /**
   * Retrieve template
   *
   * @param {Boolean} multiFileMode - When in multiFileMode, file contents like GraphQL schemas saved in files are removed from templates
   *
   * @returns {Object} SAM template object
   */
  getTemplate (multiFileMode) {
    const template = cloneDeep(this.template, true);

    if (multiFileMode) {
      for (const resource of Object.values(template.Resources || {})) {
        if (resource.Type === 'AWS::AppSync::GraphQLSchema' && 'DefinitionS3Location' in resource.Properties) {
          delete resource.Properties.Definition;
        }

        if (resource.Type === 'AWS::AppSync::Resolver') {
          if ('RequestMappingTemplateS3Location' in resource.Properties) {
            delete resource.Properties.RequestMappingTemplate;
          }

          if ('ResponseMappingTemplateS3Location' in resource.Properties) {
            delete resource.Properties.ResponseMappingTemplate;
          }
        }

        if (resource.Type === 'AWS::Serverless::StateMachine' && 'DefinitionUri' in resource.Properties) {
          delete resource.Properties.Definition;
        }
      }
    }

    return template;
  }

  cfTemplate () {
    return (this.format === 'SAM' ? this.template : {});
  }

  // private
  reparseTemplate () {
    this.parseAndSetInstanceState(this.template, this.format);
  }

  // private
  parseAndSetInstanceState (template, format, isDeployView = false) {
    const parsed = parse(template, format, isDeployView);

    this.format = format;
    this.template = parsed.template;
    this.resources = parsed.resources;
    this.integrations = parsed.integrations;
    this.virtualNetworkPlacements = parsed.virtualNetworkPlacements;
    this.references = parsed.references;
    this.customResourceReferences = parsed.customResourceReferences;
    this.permissions = parsed.permissions;
    this.parameters = parsed.parameters;
    this.reparseRequired = false;
  }
}

const logCall = (name, args) => {
  if (console.debug) {
    console.debug(`State.${name}(${args.map(arg => JSON.stringify(arg)).join(', ')})`);
  }
};
