import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from '../core/Button';
import ButtonMenu from '../core/ButtonMenu';
import style from './ActionHeader.css';

const ActionHeader = props => {
  const {
    id,
    headerType,
    headerText,
    buttonText,
    buttonIcon,
    buttonOptions,
    isLoading,
    onClick
  } = props;

  const HeaderElement = headerType;

  return (
    <header id={id} className={classnames(style.container, props.children && style.hasSearch, headerText && style.hasHeader, buttonText && style.hasButton)}>
      {headerText &&
        <HeaderElement>{headerText}</HeaderElement>
      }
      {props.children &&
        props.children
      }
      {buttonOptions && buttonText &&
        <ButtonMenu id={`${id}-button-menu`} options={buttonOptions} label={buttonText} icon={buttonIcon} isLoading={isLoading} onClick={onClick} />
      }
      {!buttonOptions && buttonText &&
        <Button id={`${id}-link-button`} onClick={onClick} text={buttonText} icon={buttonIcon} isLoading={isLoading} />
      }
    </header>
  );
};

ActionHeader.defaultProps = {
  headerType: 'h1'
};

ActionHeader.propTypes = {
  headerType: PropTypes.string,
  headerText: PropTypes.string,
  buttonText: PropTypes.string,
  buttonIcon: PropTypes.string,
  onClick: PropTypes.func
};

export default ActionHeader;
