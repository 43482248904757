import { types as apiTypes } from '../actions/api';
import { types as appTypes } from '../actions/app';
import { types as iotNotificationsTypes } from '../actions/iotNotifications';
import * as states from '../constants/states';
import * as deploymentStates from '../constants/deploymentStates';

export const initialState = {
  state: states.NEW,

  /* Contains a list of stack objects. Stack objects contain the following
   * properties:
   *
   * id: Stack ID
   * name: Stack name
   * owner: Stack owner
   * region: Stack region
   * link: Link to GitHub repo
   * isGitless: True if stack not backed by a git repository
   */
  data: [],

  // Stores an stack id in a list-view operation (e.g. deleteStack)
  selectedId: null
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case apiTypes.GET_STACKS.REQUEST:
      return { ...state, state: states.LOADING };
    case apiTypes.GET_STACKS.SUCCESS:
      const stacks = action.data.map(stack => {
        return {
          ...stack,
          isGitless: stack.link === null
        };
      });
      return {
        ...state,
        state: states.OKAY,
        data: stacks
      };
    case apiTypes.GET_STACKS.FAILURE:
      return {
        ...initialState,
        state: states.FAILED
      };
    case apiTypes.CREATE_STACK.SUCCESS:
      // If webhook_create_failed, the stack is created but the payload structure changes
      const stack = action.data.stackInfo || action.data;
      return {
        ...state,
        data: state.data.concat({
          ...stack,
          isGitless: stack.link === null
        })
      };
    case appTypes.SHOW_RENAME_STACK_MODAL:
    case appTypes.SHOW_DELETE_STACK_MODAL:
    case appTypes.SHOW_EDIT_TAGS_MODAL:
      return {
        ...state,
        selectedId: action.stack.id
      };
    case apiTypes.DELETE_STACK.SUCCESS:
      return {
        ...state,
        data: state.data.filter((stack) => stack.id !== action.id),
        selectedId: null
      };
    case apiTypes.RENAME_STACK.SUCCESS:
      return {
        ...state,
        data: state.data.map((stack) => (stack.id === action.id) ? { ...stack, name: action.newName } : stack),
        selectedId: null
      };
    case apiTypes.SET_STACK_REPO.SUCCESS:
    case apiTypes.SET_STACK_TAGS.SUCCESS:
      return {
        ...state,
        selectedId: null,
        data: state.data.map((stack) => {
          if (action.id === stack.id) {
            stack.tags = action.tags;
          }

          if (action.data.id === stack.id) {
            stack = {
              ...stack,
              ...action.data
            };
          }

          return stack;
        })
      };
    case apiTypes.RENAME_STACK.FAILURE:
    case apiTypes.DELETE_STACK.FAILURE:
    case apiTypes.SET_STACK_TAGS.FAILURE:
    case appTypes.HIDE_MODAL:
      return {
        ...state,
        selectedId: null
      };
    case iotNotificationsTypes.DEPLOYMENT:
      return {
        ...state,
        data: state.data.map((stack) => {
          if (
            stack.name === action.stackName &&
            stack.owner === action.owner &&
            action.status === deploymentStates.DEPLOYED
          ) {
            stack.latestDeployedEnvironment = action.environmentName;
          }
          return stack;
        })
      };
    default:
      return state;
  }
};
