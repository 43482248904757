import React from 'react';
import jp from 'jsonpath';
import validateResourceSelectField from '../../utils/validateResourceSelectField';
import Select from '../core/Select';

const optionTypes = {
  nullOption: 'null'
};

const getOption = (option) => {
  let value = option;
  let label = option;

  if (typeof option === 'object') {
    value = option.Value;
    label = option.Label;
  }

  return {
    value,
    label
  };
};

const ChoicesField = props => {
  const {
    resource,
    getResourceSetting,
    settingId,
    settingSchema,
    required,
    onChange
  } = props;

  const defaultValue = settingSchema.AwsDefault || settingSchema.Default;
  const value = getResourceSetting(resource.id, resource.facetType, resource.facetId, settingId) || defaultValue;
  const isValid = validateResourceSelectField(value, settingSchema);

  let choices = settingSchema.Choices;

  if ('ResourceSetting' in choices) {
    choices = getResourceSetting(resource.id, null, null, choices.ResourceSetting);
  } else if ('FacetSetting' in choices) {
    choices = getResourceSetting(resource.id, resource.facetType, resource.facetId, choices.ResourceSetting);
  }

  if ('LabelPath' in settingSchema.Choices && 'ValuePath' in settingSchema.Choices) {
    const labelPath = settingSchema.Choices.LabelPath.replace(/^@/, '$');
    const valuePath = settingSchema.Choices.ValuePath.replace(/^@/, '$');

    choices = choices.map(choice => ({
      Label: jp.value(choice, labelPath),
      Value: jp.value(choice, valuePath)
    }));
  }

  return (
    <Select
      required={required}
      hasError={!isValid}
      defaultValue={isValid ? value : optionTypes.nullOption}
      onChange={onChange}>
      <option disabled key={`${settingId}${optionTypes.nullOption}`} value={optionTypes.nullOption}>
        Choose {settingSchema.Label}
      </option>
      {choices.map(choice => {
        const option = getOption(choice);
        return <option key={`${settingId}${option.value}`} value={option.value}>{option.label}</option>;
      })}
    </Select>
  );
};

ChoicesField.propTypes = {
};

export default ChoicesField;
