import { types as appTypes } from '../actions/app';
import { types as apiTypes } from '../actions/api';
import * as states from '../constants/states';

export const initialState = {
  state: states.NEW,
  owner: undefined,
  environment: undefined,
  parametersState: states.NEW,
  parametersError: undefined,
  parameters: undefined,
  currentVersion: undefined,
  valid: false,
  secretsState: states.NEW,
  secrets: undefined,
  secretsError: undefined,
  parametersSaveState: states.OKAY,
  parametersSaveError: undefined,
  metadataState: states.NEW,
  stacks: [],
  error: undefined
};

export default (state = { ...initialState }, action) => {
  let newState;

  switch (action.type) {
    case appTypes.SELECT_ENVIRONMENT:
      return {
        ...initialState,
        state: states.LOADING,
        owner: action.owner,
        environment: action.environment
      };

    case appTypes.DESELECT_ENVIRONMENT:
      return {
        ...initialState
      };

    case apiTypes.GET_ENVIRONMENT.REQUEST:
      return {
        ...state,
        state: states.LOADING
      };

    case apiTypes.GET_ENVIRONMENT.SUCCESS:
      return {
        ...state,
        state: states.OKAY,
        ...action.data
      };

    case apiTypes.GET_ENVIRONMENT.FAILURE:
      return {
        ...state,
        state: states.FAILED,
        error: {
          ...action.error.message
        }
      };

    case apiTypes.GET_ENVIRONMENT_PARAMETERS.REQUEST:
      return {
        ...state,
        parametersState: states.LOADING,
        parameters: undefined,
        valid: false
      };

    case apiTypes.GET_ENVIRONMENT_PARAMETERS.FAILURE:
      if (
        action.owner !== state.owner ||
        action.environment !== state.environment
      ) { return state; }

      return {
        ...state,
        parametersState: states.FAILED,
        parametersError: action.error.message.message
      };

    case apiTypes.GET_ENVIRONMENT_PARAMETERS.SUCCESS:
      if (
        action.owner !== state.owner ||
        action.environment !== state.environment
      ) { return state; }

      return {
        ...state,
        parametersState: states.OKAY,
        parameters: action.data,
        currentVersion: action.headers['x-stackery-parameters-current-version']
      };

    case apiTypes.GET_ENVIRONMENT_SECRETS.REQUEST:
      return {
        ...state,
        secretsState: states.LOADING
      };

    case apiTypes.GET_ENVIRONMENT_SECRETS.FAILURE:
    case apiTypes.CREATE_ENVIRONMENT_SECRET.FAILURE:
    case apiTypes.DELETE_ENVIRONMENT_SECRET.FAILURE:
    case apiTypes.SAVE_ENVIRONMENT_SECRET_DESCRIPTION.FAILURE:
    case apiTypes.SAVE_ENVIRONMENT_SECRET_VALUE.FAILURE:
      if (
        action.owner !== state.owner ||
        action.environment !== state.environment
      ) { return state; }

      return {
        ...state,
        secretsState: states.FAILED,
        secretsError: action.error.message.message
      };

    case apiTypes.DELETE_ENVIRONMENT_SECRET.SUCCESS:
      newState = { ...state };
      delete newState.secrets[action.secret];
      return newState;

    case apiTypes.SAVE_ENVIRONMENT_SECRET_DESCRIPTION.SUCCESS:
      newState = { ...state };
      newState.secrets[action.secret].description = action.description;

      return newState;

    case apiTypes.GET_ENVIRONMENT_SECRETS.SUCCESS:
      if (
        action.owner !== state.owner ||
        action.environment !== state.environment
      ) {
        return state;
      }

      return {
        ...state,
        secretsState: states.OKAY,
        secrets: action.data.secrets
      };

    case appTypes.SET_ENVIRONMENT_VALIDITY:
      return {
        ...state,
        valid: action.valid
      };

    case apiTypes.SAVE_ENVIRONMENT_PARAMETERS.REQUEST:
      return {
        ...state,
        parametersSaveState: states.LOADING
      };

    case apiTypes.SAVE_ENVIRONMENT_PARAMETERS.FAILURE:
      if (
        action.owner !== state.owner ||
        action.environment !== state.environment
      ) { return state; }

      return {
        ...state,
        parametersSaveState: states.FAILED,
        parametersSaveError: action.error.message.message
      };

    case apiTypes.SAVE_ENVIRONMENT_PARAMETERS.SUCCESS:
      if (
        action.owner !== state.owner ||
        action.environment !== state.environment
      ) { return state; }

      return {
        ...state,
        parameters: action.parameters,
        parametersSaveState: states.OKAY,
        currentVersion: action.headers['x-stackery-parameters-current-version']
      };

    case apiTypes.DELETE_ENVIRONMENT.SUCCESS:
      return {
        ...initialState
      };

    case apiTypes.GET_ENVIRONMENT_DEPLOYMENTS.REQUEST:
      return {
        ...state,
        metadataState: states.LOADING
      };

    case apiTypes.GET_ENVIRONMENT_DEPLOYMENTS.FAILURE:
      return {
        ...state,
        metadataState: states.FAILED
      };

    case apiTypes.GET_ENVIRONMENT_DEPLOYMENTS.SUCCESS:
      return {
        ...state,
        metadataState: states.OKAY,
        stacks: action.data.deployments
      };

    default:
      return state;
  }
};
