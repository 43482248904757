import React, { Fragment } from 'react';
import EnvironmentLink from './EnvironmentLink';

const StackDeactivatedEvent = (props) => {
  const context = props.context === 'environments' ? null : <Fragment>in <EnvironmentLink {...props} /></Fragment>;

  return <span>Active Stack <em>{props.stackName}</em> {context} deleted by {props.userEmail}</span>;
};

export default StackDeactivatedEvent;
