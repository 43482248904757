import React from 'react';
import PropTypes from 'prop-types';
import * as states from '../../constants/states';
import ActionHeader from '../core/ActionHeader';
import SearchFilter from '../core/SearchFilter';
import StacksList from './StacksList';
import style from './Stacks.css';

const Stacks = props => {
  const {
    stacks,
    filteredStacks,
    gitProviders,
    searchTerm,
    isCreatingStack,
    onSearch,
    onFilterType,
    onCreateClick,
    onDeleteClick,
    onRenameClick,
    onRepoClick
  } = props;

  return (
    <div className={style.container}>
      <ActionHeader
        id='stacks-header'
        buttonText='Add a Stack'
        buttonIcon='plus'
        buttonOptions={[
          {
            id: 'quick',
            label: 'Quick create',
            onClick: onCreateClick
          },
          {
            id: 'new-repo',
            label: 'With a new repo',
            onClick: () => { props.showCreateStackNewRepoModal(); }
          },
          {
            id: 'existing-repo',
            label: 'With an existing repo',
            onClick: () => { props.showCreateStackExistingRepoModal(); }
          }
        ]}
        isLoading={isCreatingStack}
        onFilterType={onFilterType}
        onSearch={onSearch}
        onFilter={onFilterType}
        onClick={onCreateClick}
      >
        <SearchFilter
          searchName='searchTerm'
          filterName='filterTerm'
          onSearchChange={onSearch}
        />
      </ActionHeader>
      <StacksList
        stacks={filteredStacks}
        gitProviders={gitProviders}
        searchTerm={searchTerm}
        isLoaded={stacks.state === states.OKAY}
        isEmpty={stacks.data.length === 0}
        onRenameClick={onRenameClick}
        onCreateClick={onCreateClick}
        onDeleteClick={onDeleteClick}
        onRepoClick={onRepoClick}
      />
    </div>
  );
};

Stacks.propTypes = {
  stacks: PropTypes.object,
  filteredStacks: PropTypes.array,
  gitProviders: PropTypes.object,
  searchTerm: PropTypes.string,
  onSearch: PropTypes.func,
  onCreateClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onRenameClick: PropTypes.func,
  onRepoClick: PropTypes.func,
  isDemoMode: PropTypes.bool
};

export default Stacks;
