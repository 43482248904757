import React from 'react';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import Icon from '../core/Icon';
import style from './Nav.css';

const Nav = (props) => {
  return (
    <nav className={style.container}>
      <NavLink
        className={style.navLink}
        activeClassName={style.activeNavLink}
        to='/stacks'
      >
        <Icon
          className={classnames(style.icon, style.iconLayers)}
          name='layers'
        />
        <span>Stacks</span>
      </NavLink>
      <NavLink
        className={style.navLink}
        activeClassName={style.activeNavLink}
        to='/environments'
      >
        <Icon
          className={classnames(style.icon, style.iconGlobe)}
          name='globe'
        />
        <span>Environments</span>
      </NavLink>
      <NavLink
        className={style.navLink}
        activeClassName={style.activeNavLink}
        to='/deployments'
      >
        <Icon
          className={classnames(style.icon, style.iconSend)}
          name='send'
        />
        <span>Deployments</span>
      </NavLink>
    </nav>
  );
};

Nav.propTypes = {
};

export default Nav;
