import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { onTransition } from '../../utils/transition';
import authActions from '../../actions/auth';
import appActions from '../../actions/app';
import * as states from '../../constants/states';
import ChangePassword from './ChangePassword';

const mapStateToProps = () => {
  return createSelector(
    (appState) => appState.changePassword,
    (appState) => appState.currentUser,
    (changePassword, currentUser) => {
      return {
        password: changePassword,
        isDemoMode: currentUser.isDemoMode
      };
    }
  );
};

const mapDispatchToProps = {
  changePassword: authActions.changePassword,
  notifyUser: appActions.notifyUser,
  showDemoModal: appActions.showDemoModal
};

class ChangePasswordContainer extends Component {
  constructor (props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentDidUpdate (prevProps) {
    onTransition(prevProps, this.props, 'password', {
      [states.OKAY]: {
        notify: {
          message: 'Your password has been changed',
          level: 'success'
        }
      }
    });
  }

  handleInputChange (event) {
    this[event.target.name] = event.target.value;
  }

  handleFormSubmit (event) {
    event.preventDefault();

    if (this.props.isDemoMode) {
      this.props.showDemoModal({ action: `change your password` });
      return;
    }

    this.props.changePassword(this.oldPassword, this.newPassword);
  }

  render () {
    return (
      <ChangePassword
        className={this.props.className}
        error={this.props.password.error}
        isLoading={this.props.password.state === states.LOADING}
        onInputChange={this.handleInputChange}
        onSubmit={this.handleFormSubmit}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordContainer);
