import React from 'react';
import makeGitLink from '../../utils/makeGitLink';
import Skeleton from '../core/Skeleton';
import GitLink from '../core/GitLink';
import ContextMenu from '../core/ContextMenu';
import Icon from '../core/Icon';
import style from './StackHeader.css';

const replaceSlashes = (value) => {
  return value.replace(/^\/+|\/+$/g, '');
};

const getIconName = (gitProvider) => {
  switch (gitProvider) {
    case 'bitbucket':
    case 'gitlab':
    case 'github':
      return gitProvider;
    default:
      return 'git';
  }
};

const StackHeader = (props) => {
  const {
    stack,
    gitProviders,
    isGitless,
    isLocalMode,
    onRenameClick,
    onRepoClick,
    onDeleteClick
  } = props;

  const gitLink = makeGitLink(stack, gitProviders);
  const showContextMenu = !!(onRenameClick || onRepoClick || onDeleteClick);

  return (
    <header className={style.container}>
      <div>
        <h1 className={style.title}>
          <span>{stack.name}</span>
          {showContextMenu &&
            <ContextMenu
              align='right'
              items={[
                {
                  label: 'Rename',
                  onClick: onRenameClick,
                  params: {
                    ...stack
                  }
                },
                {
                  label: 'Repository',
                  onClick: isGitless ? undefined : onRepoClick,
                  params: {
                    ...stack
                  }
                },
                {
                  label: 'Delete',
                  onClick: onDeleteClick,
                  params: {
                    ...stack
                  }
                }
              ]}
            />
          }
        </h1>
      </div>
      {!isGitless && !isLocalMode &&
        <div className={style.repo}>
          {gitLink.type &&
            <Icon name={getIconName(gitLink.type.toLowerCase())} />
          }

          {gitLink.url &&
            <GitLink gitLink={gitLink} label={replaceSlashes(gitLink.url)} />
          }

          {!gitLink.url && <Skeleton width={100} animation='wave' /> }
        </div>
      }
      {isLocalMode &&
        <p><em>Currently in local editing mode</em></p>
      }
    </header>
  );
};

export default StackHeader;
