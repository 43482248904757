import React from 'react';
import PropTypes from 'prop-types';
import ActionHeader from '../core/ActionHeader';
import Link from '../core/Link';
import SecretsTable from './SecretsTable';
import style from './EnvironmentSecrets.css';

const EnvironmentSecrets = props => (
  <div className={style.container}>
    <ActionHeader
      id='environment-secrets-header'
      headerType='h2'
      headerText='Environment Secrets'
      buttonText='Add New Secret'
      buttonIcon='plus'
      onClick={props.onAddSecretClick}
    />
    <p>Environment Secrets store any sensitive information that an application may need to access or configure resources within a stack. Environment Secrets replace hard-coded credentials and configurations with secure API calls to AWS Secrets Manager; a service that encrypts, stores, and retrieves your sensitive information.</p>
    <p>AWS Secrets Manager Pricing applies to each Environment Secret created.</p>
    <p>Secrets are namespaced with the environment name '<strong>/{props.environment.environment}/</strong>'. This is available in your Functions and Docker Tasks via the environment variable <strong>SECRETS_NAMESPACE</strong>. For usage and examples of Environment Secrets, refer to <Link href='https://docs.stackery.io/docs/using-stackery/environment-secrets/' target='_blank'>Stackery Environment Secrets</Link>.</p>
    <SecretsTable
      environment={props.environment}
      isLoaded={props.isLoaded}
      isLoading={props.isLoading}
      isEmpty={props.isEmpty}
      isFailed={props.isFailed}
      onAddSecretClick={props.onAddSecretClick}
      onEditSecretDescriptionClick={props.onEditSecretDescriptionClick}
      onSetSecretValueClick={props.onSetSecretValueClick}
      onDeleteSecretClick={props.onDeleteSecretClick}
    />
  </div>
);

EnvironmentSecrets.propTypes = {
  environment: PropTypes.object.isRequired,
  isLoaded: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isEmpty: PropTypes.bool.isRequired,
  isFailed: PropTypes.bool.isRequired,
  onAddSecretClick: PropTypes.func.isRequired,
  onEditSecretDescriptionClick: PropTypes.func.isRequired,
  onSetSecretValueClick: PropTypes.func.isRequired,
  onDeleteSecretClick: PropTypes.func.isRequired,
  showCreateEnvironmentSecretModal: PropTypes.func.isRequired,
  showEditEnvironmentSecretDescriptionModal: PropTypes.func.isRequired,
  showUpdateEnvironmentSecretValueModal: PropTypes.func.isRequired,
  showDeleteEnvironmentSecretModal: PropTypes.func.isRequired,
  createEnvironmentSecret: PropTypes.func.isRequired,
  saveEnvrionmentSecretDescription: PropTypes.func.isRequired,
  saveEnvironmentSecretValue: PropTypes.func.isRequired,
  deleteEnvironmentSecret: PropTypes.func.isRequired
};

export default EnvironmentSecrets;
