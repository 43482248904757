import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import * as states from '../../constants/states';
import makeGitLink from '../../utils/makeGitLink';
import Skeleton from '../core/Skeleton';
import GitLink from '../core/GitLink';
import Link from '../core/Link';
import Button from '../core/Button';
import Tooltip from '../core/Tooltip';
import LoadingFailed from './LoadingFailed';
import BranchSelectorContainer from './BranchSelectorContainer';
import style from './StackEditNav.css';

const StackNav = props => {
  const {
    stack,
    loadingError,
    gitProviders,
    gitLink,
    version,
    match,
    isGitless,
    isStackDirty,
    onCreateBranchClick,
    onRefreshClick,
    onSave
  } = props;

  const tooltip = isStackDirty ? 'Commit to Git' : 'Nothing to Save';
  const hasRemoteChanges = stack.git.status && stack.git.status === states.DIVERGED && stack.branch === stack.git.reference;

  if (loadingError) {
    const match = stack.link && stack.link.match(/^(\S+):\/\/.*/);
    const provider = match && match[1];

    return (
      <div className={style.container}>
        <LoadingFailed
          gitProvider={gitProviders[provider]}
          error={loadingError}
        />
      </div>
    );
  }

  return (
    <div className={style.container}>
      <div>
        <strong className={style.label}>Edit mode</strong>
        <div className={style.nav} id='stack-edit-mode'>
          <NavLink
            className={style.navLink}
            activeClassName={style.activeNavLink}
            to={`${match.url}/visual`}
          >
            <span>Visual</span>
          </NavLink>
          <NavLink
            className={style.navLink}
            activeClassName={style.activeNavLink}
            to={`${match.url}/template`}
          >
            <span>Template</span>
          </NavLink>
        </div>
      </div>

      {isGitless &&
        <div className={style.cta}>
          <Tooltip message='Create a new repository with your Git provider to save changes' place='right' >
            <Button id='stack-save-gitless-cta-button' onClick={onSave}>
              Save with Git...
            </Button>
          </Tooltip>
        </div>
      }

      {!isGitless &&
        <Fragment>
          <BranchSelectorContainer />
          <p>
            <strong className={style.label}>Revision</strong>
            {gitLink && gitLink.url && version &&
              <GitLink gitLink={gitLink} label={version} modifier='version' />
            }
            {!version && <Skeleton width={60} animation='wave' /> }
          </p>
          {hasRemoteChanges &&
            <div className={style.message}>
              <p>Remote changes detected on {''} <GitLink gitLink={makeGitLink(stack, gitProviders, stack.branch)} label={stack.branch} /> branch.</p>
              <p><Link onClick={onCreateBranchClick}>Create a new branch</Link> to continue editing or <Link onClick={onRefreshClick}>refresh</Link> for latest changes.</p>
            </div>
          }
          {isStackDirty && stack.hasTemplatePathChanged &&
            <div className={style.message}>
              <p>Template path change detected.</p>
              <p><Link onClick={onCreateBranchClick}>Create a new branch</Link> to continue editing or <Link onClick={onRefreshClick}>refresh</Link> for latest changes.</p>
            </div>
          }
          {stack.isRepoOwner &&
            <div className={style.cta}>
              <Tooltip message={tooltip} place='right'>
                <Button id='stack-save-commit-cta-button' onClick={onSave} isDisabled={!isStackDirty}>
                  Commit...
                </Button>
              </Tooltip>
            </div>
          }
          {!stack.isRepoOwner &&
            <div className={style.cta}>
              <Tooltip message='Clone this repository with your Git provider to save changes' place='right'>
                <Button id='stack-save-clone-cta-button' onClick={onSave}>
                  Save with Git...
                </Button>
              </Tooltip>
            </div>
          }
        </Fragment>
      }
    </div>
  );
};

StackNav.propTypes = {
  isStackDirty: PropTypes.bool.isRequired,
  gitProviders: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  stack: PropTypes.object.isRequired,
  onCreateBranchClick: PropTypes.func.isRequired,
  onRefreshClick: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};

export default StackNav;
