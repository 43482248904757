import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import * as states from '../../constants/states';
import { isDarkMode } from '../../utils/prefersColorScheme';
import apiActions from '../../actions/api';
import appActions from '../../actions/app';
import EnvironmentParameters from './EnvironmentParameters';

const mapStateToProps = () => {
  return createSelector(
    (appState) => appState.environment,
    (appState) => appState.currentUser,
    (environment, currentUser) => {
      return {
        environment,
        isDemoMode: currentUser.isDemoMode
      };
    }
  );
};

const mapDispatchToProps = {
  getEnvironmentParameters: apiActions.getEnvironmentParameters,
  saveEnvironmentParameters: apiActions.saveEnvironmentParameters,
  setEnvironmentValidity: appActions.setEnvironmentValidity,
  notifyUser: appActions.notifyUser,
  showDemoModal: appActions.showDemoModal
};

class EnvironmentParametersContainer extends Component {
  constructor (props) {
    super(props);

    this.editor = null;

    this.state = {
      isInitialLoad: true,
      parameters: null,
      message: ''
    };

    this.setupEditor = this.setupEditor.bind(this);
    this.setEditorValue = this.setEditorValue.bind(this);
    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
  }

  componentDidMount () {
    const {
      owner,
      environment
    } = this.props.match.params;

    this.props.getEnvironmentParameters(owner, environment);
    this.setupEditor();
  }

  componentDidUpdate (prevProps) {
    const { environment: prevEnvironment } = prevProps;
    const { environment } = this.props;

    if (this.editor && (prevEnvironment.parameters === undefined && environment.parameters)) {
      this.setEditorValue();
    }

    if (this.editor && environment.parametersState === states.FAILED) {
      this.editor.getSession().setValue('', -1);
      this.props.notifyUser(environment.parametersError, 'error');
    }

    if (prevEnvironment.parametersSaveState !== states.FAILED && environment.parametersSaveState === states.FAILED) {
      // Most likely due to a parameter store rate limit
      // Keep the button enabled, so they can resubmit
      this.props.setEnvironmentValidity(true);
      this.props.notifyUser(environment.parametersSaveError, 'error');
    }

    if (prevEnvironment.parametersSaveState === states.LOADING && environment.parametersSaveState === states.OKAY) {
      this.props.notifyUser('Environment parameters saved', 'success');
    }
  }

  setupEditor () {
    const {
      environment
    } = this.props;

    this.editor = window.ace.edit(this.editorContainer);

    const session = this.editor.getSession();

    session.setMode('ace/mode/json');
    session.setFoldStyle('markbeginend');

    this.editor.setOptions({
      tabSize: 2,
      displayIndentGuides: false,
      enableBasicAutocompletion: true,
      enableSnippets: true,
      highlightActiveLine: false,
      showGutter: false,
      showPrintMargin: false,
      theme: isDarkMode() ? 'ace/theme/tomorrow-night' : 'ace/theme/chrome',
      mode: 'ace/mode/json'
    });

    this.editor.$blockScrolling = Infinity;
    this.editor.renderer.setScrollMargin(5, 5);

    if (environment.parametersState === states.OKAY) {
      this.setEditorValue();
    }

    this.editor.on('change', this.handleEditorChange);
  }

  setEditorValue () {
    const {
      environment
    } = this.props;

    const initialValue = {
      'parameterKey': 'parameterValue'
    };

    const isEmpty = typeof environment.parameters === 'object' && Object.keys(environment.parameters).length === 0;

    const value = isEmpty ? initialValue : environment.parameters;

    this.editor.getSession().setValue(JSON.stringify(value, null, 2), -1);
  }

  handleEditorChange () {
    // The state changes after the initial api fetch
    // Don't do the error check/auto save on the first state change
    if (this.state.isInitialLoad) {
      return this.setState({ isInitialLoad: false });
    }

    if (this.state.message) {
      this.setState({ message: '' });
    }

    let isValid = true;
    let parameters;

    try {
      parameters = JSON.parse(this.editor.getValue());
      this.setState({ parameters });
    } catch (err) {
      isValid = false;
    }

    if (this.props.environment.valid !== isValid) {
      this.props.setEnvironmentValidity(isValid);
    }

    if (!this.props.environment.valid && this.props.environment.parametersSaveState !== states.LOADING) {
      this.setState({ message: 'An error occurred, please check your JSON formatting' });
    }
  }

  handleSaveClick () {
    this.props.saveEnvironmentParameters(
      this.props.environment.owner,
      this.props.environment.environment,
      this.state.parameters,
      this.props.environment.currentVersion
    );
    // Disable the save button
    this.props.setEnvironmentValidity(false);
  }

  render () {
    return (
      <EnvironmentParameters
        {...this.props}
        {...this.state}
        onRef={ref => (this.editorContainer = ref)}
        onSave={this.handleSaveClick}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EnvironmentParametersContainer);
