import { types } from '../actions/auth';
import * as states from '../constants/states';

export const initialState = {
  state: states.NEW,
  error: null
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case types.CHANGE_PASSWORD.REQUEST:
      return {
        ...state,
        state: states.LOADING
      };
    case types.CHANGE_PASSWORD.FAILURE:
      return {
        error: action.error,
        state: states.FAILED
      };
    case types.CHANGE_PASSWORD.SUCCESS:
      return {
        state: states.OKAY
      };
    default:
      return state;
  }
};
