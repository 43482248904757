import { putResolve, all, takeLatest, select } from 'redux-saga/effects';
import layoutNodesYaml from '../utils/layoutNodesYaml';
import { types as canvasTypes } from '../actions/canvas';
import selectors from '../selectors';

function * layoutNodes ({ resources, dimensions, isWrapEnabled, deploymentId, nodes }) {
  const nodeTypes = yield select(selectors.nodeTypes);

  try {
    const layoutNodes = yield layoutNodesYaml(resources, nodeTypes.types, nodes, dimensions, !!deploymentId, isWrapEnabled);
    yield putResolve({ type: canvasTypes.LAYOUT_NODES.SUCCESS, nodes: layoutNodes, deploymentId });
  } catch (error) {
    console.error(error);
    yield putResolve({ type: canvasTypes.LAYOUT_NODES.FAILURE, error });
  }
}

export default function * canvas () {
  yield all([
    takeLatest(canvasTypes.LAYOUT_NODES.TRY, layoutNodes)
  ]);
}
