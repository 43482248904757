import React from 'react';
import PropTypes from 'prop-types';
import { Snackbar, Slide } from '@material-ui/core';
import UserNotification from './UserNotification';
import style from './UserNotifications.css';

function SlideTransition (props) {
  return <Slide {...props} timeout={{ enter: 350, exit: 200 }} direction='up' />;
}

class UserNotifications extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      TransitionComponent: SlideTransition,
      notifications: [],
      activeIds: [],
      closedId: undefined,
      pinnedId: undefined,
      numVisible: 2,
      isExiting: false,
      isQueued: false
    };

    this.handleToggle = this.handleToggle.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleExiting = this.handleExiting.bind(this);
    this.handleExited = this.handleExited.bind(this);
  }

  componentDidMount () {
    if (this.props.notifications.length > 0) {
      this.setState({ notifications: this.props.notifications, activeIds: this.props.notifications.map(notification => notification.id) });
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (!this.state.isExiting && (
      (this.props.notificationCount !== prevProps.notificationCount) ||
      (prevState.isExiting && this.state.isQueued)
    )) {
      this.setState({
        isQueued: false,
        activeIds: [...this.props.notifications.map(notification => notification.id)],
        notifications: [
          this.state.notifications.find(notification => notification.id === this.state.pinnedId),
          ...this.props.notifications
        ].filter((notification, i) => notification !== undefined).slice(0, (this.state.numVisible * 2))
      });
    }

    if (this.state.isExiting && this.props.notificationCount !== prevProps.notificationCount) {
      this.setState({ isQueued: true });
    }
  }

  handleToggle (id, isOpen) {
    // This will allow the first item position to be preserved if it's expanded
    this.setState({ pinnedId: isOpen ? id : undefined });
  }

  handleClose (event, reason, id) {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ isExiting: true, pinnedId: undefined, closedId: id, activeIds: this.state.activeIds.filter(activeId => activeId !== id) });
  }

  handleExiting (event, reason) {
    if (reason === 'clickaway') {
      return;
    }
  }

  handleExited (event, reason) {
    if (reason === 'clickaway') {
      return;
    }

    this.props.dismissNotification(this.state.closedId);
    this.setState({ isExiting: false, closedId: undefined });
  }

  render () {
    const {
      TransitionComponent,
      notifications,
      numVisible,
      isExiting
    } = this.state;

    return (
      <div className={style.container}>
        {notifications.map((notification, i) => {
          const index = isExiting && i > 0 ? i - 1 : i;
          const isVisible = index === 0;
          const zIndex = (this.state.notifications.length - 1) - i;
          const inlineStyle = index < numVisible
          ? {
            opacity: isVisible ? 1 : 1 - (index * 0.25),
            zIndex,
            transform: `translateX(-50%) matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, ${-(index) * 30}, 1)`
          }
          : {
            zIndex
          };

          return (
            <Snackbar
              key={notification.id}
              classes={{ root: style.item }}
              style={inlineStyle}
              open={index <= numVisible && this.state.activeIds.includes(notification.id)}
              TransitionComponent={TransitionComponent}
              onClose={this.handleClose}
              onExiting={this.handleExiting}
              onExited={this.handleExited}
            >
              <div className={style.content}>
                <UserNotification
                  notification={notification}
                  isVisible={isVisible}
                  onToggle={this.handleToggle}
                  onClose={this.handleClose}
                />
              </div>
            </Snackbar>
          );
        })}
      </div>
    );
  }
}

UserNotifications.propTypes = {
  notifications: PropTypes.array.isRequired
};

export default UserNotifications;
