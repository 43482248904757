import { types as appTypes } from '../actions/app';
import { types as apiTypes } from '../actions/api';
import * as states from '../constants/states';

export const initialState = {
  state: states.NEW,
  error: undefined,
  owner: undefined,
  name: undefined
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case appTypes.RESET_NEW_ENVIRONMENT_STATE:
      return { ...initialState };
    case apiTypes.CREATE_ENVIRONMENT.REQUEST:
      return { ...state, state: states.LOADING };
    case apiTypes.CREATE_ENVIRONMENT.FAILURE:
      return {
        ...state,
        errorMessage: action.error.message.message,
        state: states.FAILED
      };
    case apiTypes.CREATE_ENVIRONMENT.SUCCESS:
      return {
        ...state,
        state: states.OKAY,
        error: undefined,
        owner: action.data.owner,
        name: action.data.name
      };
    default:
      return state;
  }
};
