import React from 'react';
import PropTypes from 'prop-types';
import Editor from '../core/Editor';
import style from './Template.css';

const Template = props => {
  const {
    isLoading,
    isTemplateEditingSupported,
    onRef
  } = props;

  return (
    <div className={style.container}>
      <div className={style.editor}>
        {!isTemplateEditingSupported &&
          <p className={style.message}>Template editing is only supported for stacks using the <strong>AWS SAM</strong> template format.</p>
        }
        {isTemplateEditingSupported &&
          <Editor hasBorder={false} isLoading={isLoading} onRef={onRef} />
        }
      </div>
    </div>
  );
};

Template.propTypes = {
  stack: PropTypes.object,
  onRef: PropTypes.func.isRequired
};

export default Template;
