import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { push, replace } from 'connected-react-router';
import { createSelector } from 'reselect';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TAB } from '../../constants/keyCodes';
import * as localStorage from '../../utils/localStorage';
import getQueryValue from '../../utils/getQueryValue';
import * as authStates from '../../constants/authStates';
import * as states from '../../constants/states';
import * as providerStates from '../../constants/providerStates';
import apiActions from '../../actions/api';
import appActions from '../../actions/app';
import authActions from '../../actions/auth';
import iotNotificationsActions from '../../actions/iotNotifications';
import App from './App';

const mapStateToProps = (state, props) => {
  return createSelector(
    (appState) => appState.account,
    (appState) => appState.auth,
    (appState) => appState.currentUser,
    (appState) => appState.gitProviders,
    (appState) => appState.modal,
    (appState) => appState.route,
    (account, auth, currentUser, gitProviders, modal, route) => {
      return {
        account,
        auth,
        currentUser,
        plan: account.plan,
        route,
        isModalOpen: modal.isOpen,
        isLocalMode: currentUser.isLocalMode,
        isChromeless: props.location.pathname.includes('/editor') || (currentUser.isLocalMode && (props.location.pathname.includes('/local') || !auth.token)),
        isLoaded: currentUser.isLocalMode || (
          currentUser.state === states.OKAY &&
          gitProviders.state === states.OKAY &&
          account.settings.state === states.OKAY &&
          account.plan.state === states.OKAY
        )
      };
    }
  );
};

const mapDispatchToProps = {
  push,
  replace,
  getAccountInfo: apiActions.getAccountInfo,
  getCurrentUser: apiActions.getCurrentUser,
  getAccountSettings: apiActions.getAccountSettings,
  getOrganizationUsers: apiActions.getOrganizationUsers,
  toggleTourMode: appActions.toggleTourMode,
  getCognitoUser: authActions.getCognitoUser,
  getPlan: apiActions.getPlan,
  notifyUser: appActions.notifyUser,
  routeChanged: appActions.routeChanged,
  setLocalMode: appActions.setLocalMode,
  startIotNotifications: iotNotificationsActions.startIotNotifications,
  stopIotNotifications: iotNotificationsActions.stopIotNotifications
};

class AppContainer extends Component {
  constructor (props) {
    super(props);

    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount () {
    window.addEventListener('keydown', this.handleKeyDown);
  }

  componentDidUpdate (prevProps) {
    const {
      account,
      auth,
      currentUser,
      route
    } = this.props;

    const redirect = getQueryValue(window.location.search, 'redirect');

    if (auth.state === authStates.LOGGED_OUT) {
      if (window.location.pathname !== '/sign-in') {
        this.props.push('/sign-in?redirect=' + encodeURIComponent(`${window.location.pathname}${window.location.search || ''}${window.location.query || ''}`));
      }
    }

    // Ensure we are redirecting to a relative path to prevent redirect hijack
    if (auth.token && redirect && redirect[0] === '/') {
      this.props.replace(redirect);
    }

    if (prevProps.currentUser.state !== currentUser.state && currentUser.state === states.OKAY && !currentUser.hasCompletedEval && !localStorage.getItem('hasSeenTour')) {
      this.props.toggleTourMode();
    }

    if (prevProps.account.provider.deploymentStatus === providerStates.IN_PROGRESS && account.provider.deploymentStatus === providerStates.SUCCESSFUL) {
      this.props.notifyUser(`Successfully linked AWS account ${account.provider.accountId}`, 'success');
    }

    if (prevProps.account.provider.deploymentStatus === providerStates.IN_PROGRESS && account.provider.deploymentStatus === providerStates.FAILED) {
      this.props.notifyUser(`Failed to link AWS account ${account.provider.accountId}`, 'error');
    }

    if (prevProps.route.pathname !== route.pathname) {
      this.props.routeChanged();
    }
  }

  handleKeyDown (event) {
    if (event.keyCode === TAB) {
      document.body.classList.add('isTabbing');
      window.removeEventListener('keydown', this.handleKeyDown);
      window.addEventListener('click', this.handleClick);
    }
  }

  handleClick (event) {
    document.body.classList.remove('isTabbing');
    window.addEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('click', this.handleClick);
  }

  render () {
    return (
      <DndProvider backend={HTML5Backend}>
        <App {...this.props} />
      </DndProvider>
    );
  }
}

/* withRouter is needed to ensure re-rendering is not blocked when the location
* changes. See
* https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/guides/blocked-updates.md.
*/
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppContainer));
