import React from 'react';
import PropTypes from 'prop-types';
import CodeBlock from '../core/CodeBlock';
import ButtonSet from '../core/ButtonSet';
import Form from '../core/Form';
import FormRow from '../core/FormRow';
import Input from '../core/Input';

const CreateToken = (props) => {
  const {
    id,
    secret,
    isLoading,
    onChange,
    onSubmit,
    onClickCancel
  } = props;

  const toml = `[stackery]
    key = "${id}"
    secret = "${secret}"`;

  if (secret) {
    return (
      <div>
        <h1>Token added successfully!</h1>
        <p>Paste the text below into the <strong>~/.stackery.toml</strong> config file to authenticate Stackery CLI. <br /> It won't be visible again.</p>
        <CodeBlock lines={[toml]} hasPrompt={false} theme='light' />
        <ButtonSet
          primaryButton={{
            text: 'Done',
            onClick: onClickCancel
          }}
        />
      </div>
    );
  }

  return (
    <Form onSubmit={onSubmit}>
      <h1>Add a Token</h1>
      <FormRow label='Description' hint='What is this token for'>
        <Input name='description' onChange={onChange} required />
      </FormRow>
      <ButtonSet
        primaryButton={{
          text: 'Add Token',
          type: 'submit',
          isLoading
        }}
        secondaryButton={{
          text: 'Cancel',
          onClick: onClickCancel
        }}
      />
    </Form>
  );
};

CreateToken.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClickCancel: PropTypes.func.isRequired
};

export default CreateToken;
