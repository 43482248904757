import React, { Component } from 'react';
import getGitProvider from '../../utils/getGitProvider';
import Form from '../core/Form';
import RepoUrl from '../stackRepoForm/RepoUrl';
import RepoOwner from '../stackRepoForm/RepoOwner';
import ButtonSet from '../core/ButtonSet';

class EditRepo extends Component {
  constructor (props) {
    super(props);

    this.state = {
      owner: null,
      sourceRepo: props.stack.remoteLink
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange (event) {
    const {
      name,
      value
    } = event.currentTarget;

    this.setState({ [name]: value });
  }

  handleSubmit (event) {
    event.preventDefault();
    this.props.onSubmit(
      this.props.stack.owner,
      this.props.stack.name,
      this.state.sourceRepo,
      this.state.owner
    );
  }

  render () {
    const {
      stack,
      sourceRepo,
      gitProviders,
      isLoading
    } = this.props;

    const gitProvider = getGitProvider(stack.link);
    const isCodeCommit = gitProvider === 'codecommit' || (sourceRepo && sourceRepo.indexOf('://git-codecommit') > -1);

    return (
      <Form onSubmit={this.handleSubmit}>
        <h1>Change Remote Repository</h1>
        <p>Please enter the remote repository for <strong>{stack.name}</strong>.</p>
        <RepoUrl
          repoUrl={stack.remoteLink}
          onInputChange={this.handleInputChange}
        />
        {isCodeCommit &&
          <RepoOwner
            selectedGitProvider={gitProvider}
            destinations={gitProviders[gitProvider].destinations}
            onInputChange={this.handleInputChange}
          />
        }
        <ButtonSet
          primaryButton={{
            text: 'Update',
            type: 'submit',
            isLoading
          }}
          secondaryButton={{
            text: 'Cancel',
            onClick: this.props.onClickCancel
          }}
        />
      </Form>
    );
  }
}

EditRepo.propTypes = {
};

export default EditRepo;
