import { takeEvery, putResolve, select } from 'redux-saga/effects';
import { types } from '../actions/iotNotifications';
import appActions from '../actions/app';
import * as deploymentStates from '../constants/deploymentStates';
import * as jobStates from '../constants/jobStates';

// Make `action` a passable argument by removing `type`
// The presence of `type` will cause it to be dispatched as a new action, if not removed
function * handleNotification ({ type, ...action }) {
  const didPrepareFail = yield select(state => state.deployments.didPrepareFail);
  const isPipelineDeployment = action.deploymentMethod === 'pipeline';

  switch (action.status) {
    case deploymentStates.PREPARING:
      yield putResolve(
        appActions.notifyUser(
          `${isPipelineDeployment ? 'Preparing changeset to deploy ' : 'Started deploying '}  ${action.stackName} to ${action.environmentName}`,
          'info',
          {
            ...action,
            eventType: 'deployment',
            title: `deployment ${isPipelineDeployment ? 'started' : 'preparing'}`,
            timestamp: action.deploymentTimestamp
          }
        )
      );
      break;

    case deploymentStates.CHANGE_SET_CREATED:
      if (isPipelineDeployment) { return; }

      yield putResolve(
        appActions.notifyUser(
          `Successfully prepared ${action.stackName} for ${action.environmentName}`,
          'success',
          {
            ...action,
            eventType: 'deployment',
            title: 'deployment prepared',
            timestamp: action.deploymentTimestamp
          }
        )
      );
      break;

    case deploymentStates.DEPLOYED:
      yield putResolve(
        appActions.notifyUser(
          `Successfully deployed ${action.stackName} to ${action.environmentName}`,
          'success',
          {
            ...action,
            eventType: 'deployment',
            title: 'stack deployed',
            timestamp: action.deploymentTimestamp
          }
        )
      );
      break;

    case deploymentStates.FAILING:
      yield putResolve(
        appActions.notifyUser(
          action.message,
          'error',
          {
            ...action,
            eventType: 'deployment',
            title: 'deployment error',
            timestamp: action.deploymentTimestamp
          }
        )
      );
      break;

    case deploymentStates.FAILED:
      yield putResolve(
        appActions.notifyUser(
          action.message,
          'error',
          {
            ...action,
            eventType: 'deployment',
            title: 'deployment error',
            timestamp: action.deploymentTimestamp,
            didPrepareFail
          }
        )
      );
      break;

    case jobStates.STAGE_PROMOTED:
      yield putResolve(
        appActions.notifyUser(
          `Successfully promoted ${action.stackName} to ${action.promotedTo}`,
          'success',
          {
            ...action,
            eventType: 'deploymentPipelineJob',
            title: 'pipeline stage promoted'
          }
        )
      );
      break;

    case jobStates.MANUAL_APPROVAL_PENDING:
      yield putResolve(
        appActions.notifyUser(
          `Ready to promote ${action.stackName}`,
          'info',
          {
            ...action,
            eventType: 'deploymentPipelineJob',
            title: 'pipeline stage ready'
          }
        )
      );
      break;

    default:
      break;
  }
}

export default function * route () {
  yield takeEvery(types.DEPLOYMENT, handleNotification);
  yield takeEvery(types.DEPLOYMENT_PIPELINE_JOB, handleNotification);
}
