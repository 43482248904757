import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import DocumentTitle from 'react-document-title';
import classnames from 'classnames';
import HeaderContainer from './HeaderContainer';
import SignInContainer from '../signIn/SignInContainer';
import SignUpContainer from '../signUp/SignUpContainer';
import ForgotPasswordContainer from '../forgotPassword/ForgotPasswordContainer';
import ResetPasswordContainer from '../resetPassword/ResetPasswordContainer';
import InviteSignUpContainer from '../inviteSignUp/InviteSignUpContainer';
import UserNotificationsContainer from '../userNotifications/UserNotificationsContainer';
import WorkspaceContainer from '../workspace/WorkspaceContainer';
import PublicEditorContainer from '../publicEditor/PublicEditorContainer';
import ModalContainer from '../modal/ModalContainer';
import style from './UnauthorizedApp.css';

const redirectRoute = (props) => (
  props.isLocalMode && window.location.pathname.includes('/local')
    ? `/local${window.location.search}`
    : '/sign-in?redirect=' + encodeURIComponent(`${window.location.pathname}${window.location.search || ''}${window.location.query || ''}`)
);

const UnauthorizedApp = (props) => {
  const {
    isModalOpen,
    isLocalMode,
    isChromeless
  } = props;

  return (
    <div className={classnames(style.container, isChromeless && 'isChromeless')}>
      <DocumentTitle title='Stackery' />
      <UserNotificationsContainer />

      {!isChromeless &&
        <HeaderContainer />
      }

      <div className={style.content}>
        <Switch>
          <Route exact path='/sign-in' component={SignInContainer} />
          <Route exact path='/forgot-password' component={ForgotPasswordContainer} />
          <Route exact path='/reset-password' component={ResetPasswordContainer} />
          <Route path='/organization-invite.html' component={InviteSignUpContainer} />

          <Route path='/editor' component={PublicEditorContainer} />

          {isLocalMode &&
            <Route exact path='/local' component={WorkspaceContainer} />
          }

          {isLocalMode &&
            <Route path='/local/:resourceId' component={WorkspaceContainer} />
          }

          {/* Allow in-app signup for non-prod environments because normal
            * sign-up workflow is prod-only */}
          {process.env.REACT_APP_ENV !== 'prod' &&
            <Route path='/sign-up' component={SignUpContainer} />
          }

          {/* Redirect for any stale links to app.stackery.io/sign-up
            * as we're now using the sign-up forms in stackery-io */}
          {process.env.REACT_APP_ENV === 'prod' &&
            <Route
              path='/sign-up'
              render={() => {
                window && (window.location.href = 'https://www.stackery.io/sign-up');
              }}
            />
          }

          {/* Redirect for any stale links to app.stackery.io/invite-sign-up
            * as we're now using the sign-up forms in stackery-io */}
          {process.env.REACT_APP_ENV === 'prod' &&
            <Route
              path='/invite-sign-up'
              render={() => {
                window && (window.location.href = `https://www.stackery.io/invite-sign-up/${window.location.search}`);
              }}
            />
          }

          <Redirect to={redirectRoute(props)} />
        </Switch>
      </div>

      {isModalOpen &&
        <ModalContainer />
      }
    </div>
  );
};

export default UnauthorizedApp;
