import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import url from 'url';
import authActions from '../../actions/auth';
import appActions from '../../actions/app';
import convertStringToObject from '../../utils/convertStringToObject';
import * as states from '../../constants/states';
import * as signupStates from '../../constants/signUpStates';
import SignIn from './SignIn';

const mapStateToProps = () => {
  return createSelector(
    (appState) => appState.auth,
    (appState) => appState.signUp,
    (appState) => appState.currentUser,
    (auth, signUp, currentUser) => {
      return {
        isLoading: auth.state === states.LOADING || auth.authType === 'sso',
        error: auth.error,
        signupSuccess: signUp.state === signupStates.SUCCESS,
        authType: auth.authType,
        authProvider: auth.authProvider,
        isLocalMode: currentUser.isLocalMode
      };
    }
  );
};

const mapDispatchToProps = {
  signIn: authActions.signIn,
  resetAuthInfo: authActions.resetAuthInfo,
  getAuthInfo: authActions.getAuthInfo,
  getToken: authActions.getToken,
  hideModal: appActions.hideModal
};

class SignInContainer extends Component {
  constructor (props) {
    super(props);
    this.state = {
      email: null,
      password: null,
      error: null,
      isProcessing: false,
      awsMarketplaceQuery: null
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate (prevProps) {
    if (this.props.authType === 'sso') {
      this.setState({ isProcessing: true });
      let app = process.env.REACT_APP_ENV === 'prod' ? 'stackery' : `${process.env.REACT_APP_ENV}-stackery`;
      let region = (process.env.REACT_APP_ENV === 'prod2' || process.env.REACT_APP_ENV === 'stg3') ? 'us-east-1' : 'us-west-2';
      let clientID = JSON.parse(process.env.REACT_APP_USER_POOL_DATA).ClientId;
      let uri = `${encodeURIComponent(window.location.origin)}/sign-in`;
      let provider = encodeURIComponent(this.props.authProvider);
      window.location = `https://${app}.auth.${region}.amazoncognito.com/oauth2/authorize?response_type=code&client_id=${clientID}&redirect_uri=${uri}&identity_provider=${provider}`;
    }
  }

  componentDidMount () {
    let locationHash = convertStringToObject(this.props.location.hash);

    if (locationHash.error) {
      let error = {
        message: `${locationHash.error}: ${decodeURIComponent(locationHash.error_description)}` // TODO: Generate better message
      };
      this.setState({error});
    }

    let locationQuery = convertStringToObject(this.props.location.search);

    if (locationQuery && locationQuery.redirect && locationQuery.redirect.includes('x-amzn-marketplace-token')) {
      const redirectUrl = decodeURIComponent(locationQuery.redirect);
      const parsed = url.parse(redirectUrl);
      this.setState({ awsMarketplaceQuery: parsed.query });
    }

    if (locationQuery.code) {
      this.setState({ isProcessing: true });
      this.props.getToken(locationQuery.code);
    }
  }

  handleInputChange (event) {
    if (event.target.name === 'email') {
      this.props.resetAuthInfo();
    }
    this.setState({[event.target.name]: event.target.value});
  }

  handleSubmit (event) {
    event.preventDefault();
    if (!this.props.authType) {
      if (!this.state.email) {
        this.setState({error: {message: 'Please enter your email address'}});
      } else {
        this.props.getAuthInfo(this.state.email);
      }
    } else if (this.props.authType === 'password') {
      this.props.signIn(this.state.email, this.state.password);
    }
  }

  render () {
    return (
      <SignIn
        isProcessing={this.state.isProcessing}
        isLoading={this.props.isLoading}
        isLocalMode={this.props.isLocalMode}
        error={this.props.error || this.state.error}
        signupSuccess={this.props.signupSuccess}
        handleInputChange={this.handleInputChange}
        handleSubmit={this.handleSubmit}
        email={this.state.email}
        authType={this.props.authType}
        showPasswordField={this.props.authType === 'password'}
        awsMarketplaceQuery={this.state.awsMarketplaceQuery}
        hideModal={this.props.hideModal}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignInContainer);
