import { types } from '../actions/api';
import * as states from '../constants/states';

export const initialState = {
  state: states.NEW,
  email: null,
  error: null
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case types.CREATE_SAME_ORGANIZATION_INVITE.REQUEST:
      return {
        ...state,
        state: states.LOADING,
        email: action.email
      };
    case types.CREATE_SAME_ORGANIZATION_INVITE.FAILURE:
      return {
        ...state,
        error: {...action.error.message},
        state: states.FAILED
      };
    case types.CREATE_SAME_ORGANIZATION_INVITE.SUCCESS:
      return {
        ...state,
        state: states.OKAY,
        error: null,
        ...action.data
      };
    default:
      return state;
  }
};
