import { putResolve, all, takeLatest } from 'redux-saga/effects';
import { request } from '../utils/api';
import getStackFormat from '../utils/getStackFormat';
import { types as apiTypes } from '../actions/api';
import { types as publicEditorTypes } from '../actions/publicEditor';

function * getRepoContents ({ owner, repo, file = 'template.yaml', ref = 'master' }) {
  yield request(
    publicEditorTypes.GET_REPO_CONTENTS,
    'GET',
    `https://api.github.com/repos/${owner}/${repo}/contents/${file}?ref=${ref}`
  );
}

function * parseRepoContents ({ data }) {
  const stack = Buffer.from(data.content, 'base64').toString();
  yield putResolve({ type: apiTypes.GET_STACK_CONTENTS.SUCCESS, data: { stack, format: getStackFormat(stack) } });
}

export default function * publicEditor () {
  yield all([
    takeLatest(publicEditorTypes.GET_REPO_CONTENTS.REQUEST, getRepoContents),
    takeLatest(publicEditorTypes.GET_REPO_CONTENTS.SUCCESS, parseRepoContents)
  ]);
}
