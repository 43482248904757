import { request, sequenceSagas } from '../utils/api';
import removeQueryParameter from '../utils/removeQueryParameter';
import { types } from '../actions/api';
import { types as appTypes } from '../actions/app';
import { call, select, putResolve, takeEvery, takeLatest, all, delay } from 'redux-saga/effects';
import * as states from '../constants/states';
import * as gitProviderStates from '../constants/gitProviderStates';
import * as providerStates from '../constants/providerStates';
import { getGitProviders } from '../constants/gitProviders';

const getStacks = request.bind(null, types.GET_STACKS, 'GET', '/stacks');

function * getStack ({ owner, name }) {
  yield request(
    types.GET_STACK,
    'GET',
    `/stacks/${owner}/${name}`,
    null,
    { owner, name }
  );
}

function * getStackSettings ({ owner, stack }) {
  yield request(
    types.GET_STACK_SETTINGS,
    'GET',
    `/stacks/${owner}/${stack}/settings`
  );
}

function * saveStackSettings ({ stack, newName, templatePath, deploymentStrategy, deploymentHooksDirectory, buildImage, verificationPipeline }) {
  let tasks = [
    {
      type: types.RENAME_STACK,
      params: { id: stack.id, owner: stack.owner, stack: stack.name, newName }
    }
  ];

  if (!stack.isGitless) {
    tasks = tasks.concat([
      {
        type: types.SAVE_STACK_TEMPLATE_PATH,
        params: { owner: stack.owner, stack: newName, templatePath }
      },
      {
        type: types.SAVE_STACK_DEPLOYMENT_STRATEGY,
        params: { owner: stack.owner, stack: newName, deploymentStrategy }
      },
      {
        type: types.SAVE_STACK_DEPLOYMENT_HOOKS_DIRECTORY,
        params: { owner: stack.owner, stack: newName, deploymentHooksDirectory }
      },
      {
        type: types.SAVE_STACK_BUILD_IMAGE,
        params: { owner: stack.owner, stack: newName, buildImage }
      },
      {
        type: types.SAVE_STACK_VERIFICATION_PIPELINE,
        params: { owner: stack.owner, stack: newName, verificationPipeline }
      }
    ]);
  }

  yield sequenceSagas(types.SAVE_STACK_SETTINGS, tasks);
}

function * saveStackTemplatePath ({ owner, stack, templatePath }) {
  yield request(
    types.SAVE_STACK_TEMPLATE_PATH,
    'PUT',
    `/stacks/${owner}/${stack}`,
    { templatePath },
    { templatePath }
  );
}

function * saveStackDeploymentStrategy ({ owner, stack, deploymentStrategy }) {
  yield request(
    types.SAVE_STACK_DEPLOYMENT_STRATEGY,
    'PUT',
    `/stacks/${owner}/${stack}`,
    { deploymentStrategy },
    { deploymentStrategy }
  );
}

function * saveStackDeploymentHooksDirectory ({ owner, stack, deploymentHooksDirectory }) {
  yield request(
    types.SAVE_STACK_DEPLOYMENT_HOOKS_DIRECTORY,
    'PUT',
    `/stacks/${owner}/${stack}`,
    { deploymentHooksDirectory },
    { deploymentHooksDirectory }
  );
}

function * saveStackBuildImage ({ owner, stack, buildImage }) {
  yield request(
    types.SAVE_STACK_BUILD_IMAGE,
    'PUT',
    `/stacks/${owner}/${stack}`,
    { buildImage },
    { buildImage }
  );
}

function * saveStackVerificationPipeline ({ owner, stack, verificationPipeline }) {
  yield request(
    types.SAVE_STACK_VERIFICATION_PIPELINE,
    'PUT',
    `/stacks/${owner}/${stack}/pipeline/verification`,
    { verificationPipeline },
    { verificationPipeline }
  );
}

function * getStackBranches ({ owner, name }) {
  yield request(
    types.GET_STACK_BRANCHES,
    'GET',
    `/stacks/${owner}/${name}/branches`,
    null,
    currentState =>
      currentState.stack.name === name && currentState.stack.owner === owner
  );
}

function * getStackEnvironments ({ owner, name }) {
  yield request(
    types.GET_STACK_ENVIRONMENTS,
    'GET',
    `/stacks/${owner}/${name}/environments/deployments`,
    null,
    currentState =>
      currentState.stack.name === name && currentState.stack.owner === owner
  );
}

function * getIotNotificationsChannelInfo ({ owner }) {
  yield request(
    types.GET_IOT_NOTIFICATIONS_CHANNEL_INFO,
    'GET',
    `/notifications/${owner}`
  );
}

function * saveUserNotification ({ owner, notification }) {
  yield request(
    types.SAVE_USER_NOTIFICATION,
    'POST',
    `/notifications/${owner}/user`,
    { ...notification, isRead: false },
    { owner, notification }
  );
}

function * getUserNotifications ({ owner, itemsPerPage, nextToken }) {
  const query = nextToken ? `?per_page=${itemsPerPage || 20}&token=${encodeURIComponent(nextToken)}` : `?per_page=${itemsPerPage || 20}`;
  yield request(
    types.GET_USER_NOTIFICATIONS,
    'GET',
    `/notifications/${owner}/user${query}`,
    { owner }
  );
}

function * updateUserNotification ({ owner, notification }) {
  yield request(
    types.UPDATE_USER_NOTIFICATION,
    'PUT',
    `/notifications/${owner}/user/${notification.timestampAndKey}`,
    { isRead: true },
    { owner, notification }
  );
}

function * deleteUserNotification ({ owner, notification }) {
  yield request(
    types.DELETE_USER_NOTIFICATION,
    'DELETE',
    `/notifications/${owner}/user/${notification.timestamp}:${notification.key}`,
    null,
    { owner, notification }
  );
}

function * deleteAllUserNotifications ({ owner }) {
  yield request(
    types.DELETE_ALL_USER_NOTIFICATIONS,
    'DELETE',
    `/notifications/${owner}/user`
  );
}

function * getStackContents ({ owner, name, branch }) {
  yield request(
    types.GET_STACK_CONTENTS,
    'GET',
    `/stacks/${owner}/${name}/branches/${encodeURIComponent(branch)}/contents`,
    null,
    currentState =>
      currentState.stack.name === name &&
      currentState.stack.owner === owner &&
      currentState.stack.branch === branch
  );
}

function * getNodeTemplate ({ owner, stack, node }) {
  yield request(
    types.GET_NODE_TEMPLATE,
    'POST',
    `/stacks/${owner}/${stack.name}/node/template`,
    { stack, node }
  );
}

function * getConfigKeys ({ owner, stack }) {
  yield request(
    types.GET_CONFIG_KEYS,
    'GET',
    `/stacks/${owner}/${stack}/configkeys`,
    null,
    currentState =>
      currentState.stack.name === stack &&
      currentState.stack.owner === owner
  );
}

function * createDeploymentPipeline ({ name, stages, stacks }) {
  yield request(
    types.CREATE_DEPLOYMENT_PIPELINE,
    'POST',
    `/deployment-pipelines`,
    { name, stages, stacks }
  );
}

function * deleteDeploymentPipeline ({ owner, pipelineId }) {
  yield request(
    types.DELETE_DEPLOYMENT_PIPELINE,
    'DELETE',
    `/deployment-pipelines/${owner}/${pipelineId}`,
    null,
    { owner, pipelineId }
  );
}

function * getDeploymentPipelines () {
  yield request(
    types.GET_DEPLOYMENT_PIPELINES,
    'GET',
    `/deployment-pipelines`
  );
}

function * getDeploymentPipelineSettings ({ owner, pipelineId }) {
  yield request(
    types.GET_DEPLOYMENT_PIPELINE_SETTINGS,
    'GET',
    `/deployment-pipelines/${owner}/${pipelineId}/settings`,
    null,
    { pipelineId }
  );
}

function * getDeploymentPipelineStatus ({ owner, pipelineId }) {
  yield request(
    types.GET_DEPLOYMENT_PIPELINE_STATUS,
    'GET',
    `/deployment-pipelines/${owner}/${pipelineId}`,
    null,
    { pipelineId }
  );
}

function * updateDeploymentPipelineSettings ({ owner, pipelineId, name, stages, stacks }) {
  yield request(
    types.UPDATE_DEPLOYMENT_PIPELINE_SETTINGS,
    'PUT',
    `/deployment-pipelines/${owner}/${pipelineId}/settings`,
    { name, stages, stacks },
    { pipelineId }
  );
}

function * promoteDeploymentPipeline ({ owner, pipelineId, stackId, deploymentHistoryId, version, stageIndex }) {
  yield request(
    types.PROMOTE_DEPLOYMENT_PIPELINE,
    'POST',
    `/deployment-pipelines/${owner}/${pipelineId}/promote`,
    { deploymentHistoryId },
    { owner, pipelineId, stackId, deploymentHistoryId, version, stageIndex }
  );
}

function * retryDeploymentPipeline ({ owner, pipelineId, stackId, deploymentHistoryId, version, stageIndex }) {
  yield request(
    types.RETRY_DEPLOYMENT_PIPELINE,
    'POST',
    `/deployment-pipelines/${owner}/${pipelineId}/retry`,
    { stackId, sha: version, stageIndex },
    { owner, pipelineId, stackId, deploymentHistoryId, version, stageIndex }
  );
}

function * getEnvironmentDeployments ({ owner, environment }) {
  yield request(
    types.GET_ENVIRONMENT_DEPLOYMENTS,
    'GET',
    `/environments/${owner}/${environment}/deployments`,
    null,
    currentState =>
      currentState.stack.owner === owner &&
      currentState.stack.environment === environment
  );
}

function * getDeployments ({ owner, stack, environment }) {
  yield request(
    types.GET_DEPLOYMENTS,
    'GET',
    `/stacks/${owner}/${stack}/environments/${environment}/deployments`,
    null,
    currentState =>
      currentState.stack.name === stack &&
      currentState.stack.owner === owner &&
      currentState.stack.environment === environment
  );
}

function * getDeploymentState ({ owner, stack, environment, deployment, ephemeralId }) {
  const query = ephemeralId ? `?ephemeralId=${ephemeralId}` : '';
  yield request(
    types.GET_DEPLOYMENT_STATE,
    'GET',
    `/stacks/${owner}/${stack}/environments/${environment}/deployments/${deployment}/contents${query}`,
    null,
    { deployment },
    currentState =>
      currentState.stack.name === stack &&
      currentState.stack.owner === owner &&
      currentState.stack.environment === environment
  );
}

function * getChanges ({ owner, stack, environment, deployment }) {
  yield request(
    types.GET_CHANGES,
    'GET',
    `/stacks/${owner}/${stack}/environments/${environment}/deployments/${deployment}/changes`,
    null,
    { deployment },
    currentState =>
      currentState.stack.name === stack &&
      currentState.stack.owner === owner &&
      currentState.stack.environment === environment
  );
}

function * setStackRepo ({ owner, stack, repoUrl, awsAccount }) {
  yield request(
    types.SET_STACK_REPO,
    'PUT',
    `/stacks/${owner}/${stack}/remote`,
    { remote: repoUrl, awsAccount },
    { owner, stack, repoUrl }
  );
}

function * getStackBranchesAndContents ({ owner, stack }) {
  const result = yield call(getStackBranches, { owner, name: stack });

  if (!(result instanceof Error)) {
    const branches = yield select(state => state.stackBranches.data);
    yield putResolve({ type: types.GET_STACK_CONTENTS.REQUEST, owner, name: stack, branch: branches[0].reference });
  }
}

function * createBranch ({ owner, stack, baseBranch, newBranch }) {
  yield request(
    types.CREATE_BRANCH,
    'POST',
    `/stacks/${owner}/${stack}/branches/`,
    { baseBranch, newBranch },
    { owner, stack, branch: newBranch }
  );
}

function * saveNodes ({ owner, stack, branch, nodes, commit }) {
  // Stack properties passed through when using dashboard editor
  const metadata = yield select(state => state.stack.metadata);

  yield request(
    types.SAVE_NODES,
    'PUT',
    `/stacks/${owner}/${stack}/branches/${encodeURIComponent(branch)}/contents`,
    { ...metadata, nodes, commit },
    { owner, stack, branch, nodes, commit }
  );
}

function * createBranchAndSaveNodes ({ owner, stack, baseBranch, newBranch, nodes, commit }) {
  const result = yield call(createBranch, { owner, stack, baseBranch, newBranch });

  if (!(result instanceof Error)) {
    yield call(saveNodes, { owner, stack, branch: newBranch, nodes, commit });
    yield putResolve({ type: types.CREATE_BRANCH_AND_SAVE_NODES.SUCCESS });
  }
}

function * saveResources ({ owner, stack, branch = 'main', template, format, commit, newRepository }) {
  // Stack properties passed through when using dashboard editor
  const metadata = yield select(state => state.stack.metadata);
  const logicalIdMapping = yield select(state => state.formation.logicalIdMapping);
  const sam = format === 'SAM' ? template : undefined;
  const serverless = format === 'serverless' ? template : undefined;

  yield request(
    types.SAVE_NODES,
    'PUT',
    `/stacks/${owner}/${stack}/branches/${encodeURIComponent(branch)}/contents`,
    { ...metadata, sam, serverless, commit, logicalIdMapping, newRepository },
    { owner, stack, branch, template, format },
    currentState =>
      currentState.stack.name === stack &&
      currentState.stack.owner === owner
  );
}

function * createBranchAndSaveResources ({ owner, stack, baseBranch, newBranch, template, format, commit }) {
  const result = yield call(createBranch, { owner, stack, baseBranch, newBranch });

  if (!(result instanceof Error)) {
    yield call(saveResources, { owner, stack, branch: newBranch, template, format, commit });
    yield putResolve({ type: types.CREATE_BRANCH_AND_SAVE_NODES.SUCCESS });
  }
}

function * prepare ({ owner, stack, reference, environment }) {
  yield request(
    types.PREPARE,
    'POST',
    `/stacks/${owner}/${stack}/prepare`,
    { reference, environment },
    { owner, stack, environment }
  );
}

const getEnvironments = request.bind(
  null,
  types.GET_ENVIRONMENTS,
  'GET',
  '/environments'
);

function * getEnvironment ({ owner, environment }) {
  yield request(
    types.GET_ENVIRONMENT,
    'GET',
    `/environments/${owner}/${environment}`
  );
}

function * createEnvironment ({ name, region, accountId }) {
  yield request(
    types.CREATE_ENVIRONMENT,
    'POST',
    `/environments`,
    { name, region, accountId },
    { name, region, accountId }
  );
}

function * getEnvironmentParameters ({ owner, environment }) {
  yield request(
    types.GET_ENVIRONMENT_PARAMETERS,
    'GET',
    `/environments/${owner}/${environment}/config`,
    null,
    { owner, environment }
  );
}

function * getEnvironmentSecrets ({ owner, environment }) {
  yield request(
    types.GET_ENVIRONMENT_SECRETS,
    'GET',
    `/environments/${owner}/${environment}/secrets`,
    null,
    { owner, environment }
  );
}

function * createEnvironmentSecret ({ owner, environment, secret, value, description }) {
  yield request(
    types.CREATE_ENVIRONMENT_SECRET,
    'POST',
    `/environments/${owner}/${environment}/secrets/${encodeURIComponent(secret)}`,
    { value, description },
    { owner, environment, secret, description }
  );

  yield putResolve({ type: types.GET_ENVIRONMENT_SECRETS.REQUEST, owner, environment });
}

function * deleteEnvironmentSecret ({ owner, environment, secret }) {
  yield request(
    types.DELETE_ENVIRONMENT_SECRET,
    'DELETE',
    `/environments/${owner}/${environment}/secrets/${encodeURIComponent(secret)}`,
    null,
    { owner, environment, secret }
  );
}

function * saveEnvironmentSecretDescription ({ owner, environment, secret, description }) {
  yield request(
    types.SAVE_ENVIRONMENT_SECRET_DESCRIPTION,
    'PUT',
    `/environments/${owner}/${environment}/secrets/${encodeURIComponent(secret)}`,
    { description },
    { owner, environment, secret, description }
  );
}

function * saveEnvironmentSecretValue ({ owner, environment, secret, value }) {
  yield request(
    types.SAVE_ENVIRONMENT_SECRET_VALUE,
    'PUT',
    `/environments/${owner}/${environment}/secrets/${encodeURIComponent(secret)}`,
    { value },
    { owner, environment, secret, value }
  );
}

function * saveEnvironmentParameters ({ owner, environment, parameters, currentVersion }) {
  const headers = {};
  if (currentVersion) {
    headers['x-stackery-parameters-expected-version'] = currentVersion;
  }

  yield request(
    types.SAVE_ENVIRONMENT_PARAMETERS,
    'PUT',
    `/environments/${owner}/${environment}/config`,
    parameters,
    { owner, environment, parameters },
    null,
    headers
  );
}

function * getOrganizationUsers () {
  yield request(
    types.GET_ORGANIZATION_USERS,
    'GET',
    '/account/organizationusers'
  );
}

function * getOrganizationInvitedUsers () {
  yield request(
    types.GET_ORGANIZATION_INVITED_USERS,
    'GET',
    '/account/organizationinvitedusers'
  );
}

function * getCurrentUser () {
  const wasDemoMode = yield select(state => state.currentUser.isDemoMode);
  yield request(types.CURRENT_USER, 'GET', '/settings');
  const isDemoMode = yield select(state => state.currentUser.isDemoMode);

  if (isDemoMode && !wasDemoMode) {
    yield getAccountInfo();
  }
}

function * getAccountSettings () {
  const result = yield request(types.GET_ACCOUNT_SETTINGS, 'GET', '/settings/org');

  if (result instanceof Error) {
    return;
  }

  const isDemoMode = yield select(state => state.currentUser.isDemoMode);

  if (!isDemoMode) {
    const providers = result.org.privateGitProviders;
    for (let provider in providers) {
      yield putResolve({ type: appTypes.ADD_GIT_PROVIDER, provider: providers[provider] });
    }
  }
  yield putResolve({ type: appTypes.GIT_PROVIDERS_UPDATED });
}

// request updated currentUser object if an adjacent action modifies user data
// e.g. createStack updates git provider preference
function * refreshCurrentUser (action) {
  if (!action.data.isRepoOwner) { return; }

  const currentUser = yield select(state => state.currentUser);
  if (currentUser.state === states.OKAY) {
    currentUser.state = states.LOADING;
    yield request(types.CURRENT_USER, 'GET', '/settings');
  }
}

function * createSameOrganizationInvite ({ email, isAdmin, organization, inviter }) {
  yield request(
    types.CREATE_SAME_ORGANIZATION_INVITE,
    'POST',
    '/account/inviteuser',
    { email, isAdmin, organization, inviter },
    { email, isAdmin, organization }
  );
}

function * removeUser ({ email }) {
  yield request(
    types.REMOVE_USER,
    'POST',
    '/account/removeuser',
    { email }
  );
  yield putResolve({ type: types.GET_ORGANIZATION_USERS.REQUEST });
}

function * removeInvitedUser ({ email }) {
  yield request(
    types.REMOVE_INVITED_USER,
    'POST',
    '/account/removeinviteduser',
    { email },
    { email }
  );
}

function * updateUserRole ({ email, isAdmin }) {
  yield request(
    types.UPDATE_USER_ROLE,
    'PUT',
    '/account/role',
    { email, isAdmin },
    { email, isAdmin }
  );
}

function * createStack ({ provider, name, location, source, templatePath, isPrivate, shouldClone, awsAccount, project }) {
  let params = {
    source,
    private: isPrivate,
    clone: shouldClone,
    awsAccount,
    project
  };

  if (name) {
    params.name = name;
  }

  if (provider) {
    params.provider = provider;
  }

  if (location) {
    params.location = location;
  }

  if (templatePath) {
    params.templatePath = templatePath;
  }

  yield request(
    types.CREATE_STACK,
    'POST',
    '/stacks',
    params
  );
  yield putResolve({ type: types.GET_STACKS.REQUEST });
}

function * deleteStack ({ id, owner, stack }) {
  yield request(
    types.DELETE_STACK,
    'DELETE',
    `/stacks/${owner}/${stack}`,
    null,
    { id }
  );
}

function * renameStack ({ id, owner, stack, newName }) {
  yield request(
    types.RENAME_STACK,
    'POST',
    `/stacks/${owner}/${stack}/rename`,
    { newName },
    { id, newName }
  );
}

function * setStackTags ({ id, owner, stack, tags }) {
  yield request(
    types.SET_STACK_TAGS,
    'POST',
    `/stacks/${owner}/${stack}/tags`,
    { tags },
    { id, tags }
  );
}

function * saveStackBlueprint ({ owner, name, source }) {
  yield request(
    types.SAVE_STACK_BLUEPRINT,
    'POST',
    `/stacks/${owner}/blueprints`,
    { name, source },
    { name, source }
  );
}

function * deleteStackBlueprint ({ owner, name }) {
  yield request(
    types.DELETE_STACK_BLUEPRINT,
    'DELETE',
    `/stacks/${owner}/blueprints/${name}`,
    null,
    { name }
  );
}

function * getStackBlueprints ({ id, owner, stack, tags }) {
  yield request(
    types.GET_STACK_BLUEPRINTS,
    'GET',
    `/stacks/${owner}/blueprints`
  );
}

function * getDefaultBlueprint ({ format }) {
  yield request(
    types.GET_DEFAULT_BLUEPRINT,
    'GET',
    `/stacks/public`,
    null,
    { format }
  );
}

function * deleteEnvironment ({ owner, name }) {
  yield request(
    types.DELETE_ENVIRONMENT,
    'DELETE',
    `/environments/${owner}/${name}`,
    null,
    { owner, name }
  );
}

function * saveGitPersonalAccessToken ({ provider, user, token }) {
  const result = yield request(
    types.SAVE_GIT_PERSONAL_ACCESS_TOKEN,
    'POST',
    `/git/${provider}/oauth`,
    { user, token },
    { provider }
  );

  if (!(result instanceof Error)) {
    yield putResolve({ type: types.GET_GIT_REPOS.REQUEST, provider });
    yield putResolve({ type: types.GET_GIT_DESTINATIONS.REQUEST, provider });
  }
}

function * getGitAuthUrl ({ provider }) {
  const gitProviders = yield select(state => state.gitProviders);
  const providers = getGitProviders(gitProviders);

  if (provider) {
    if (provider === providers.codecommit.name ||
      gitProviders[provider].state === gitProviderStates.AUTHORIZED) {
      return;
    }
    yield request(types.GET_GIT_AUTH_URL, 'GET', `/git/${provider}/oauth`, null, { provider: provider });
  } else {
    for (const name of Object.keys(providers)) {
      if (name === providers.codecommit.name || gitProviders[name].state === gitProviderStates.AUTHORIZED) {
        continue;
      }
      yield request(types.GET_GIT_AUTH_URL, 'GET', `/git/${name}/oauth`, null, { provider: name });
    }
  }
}

function * getGitAuthToken ({ provider, code, state, token, verifier }) {
  const result = yield request(
    types.GET_GIT_AUTH_TOKEN,
    'POST',
    `/git/${provider}/oauth`,
    { code, secret: state, token, verifier },
    { provider }
  );

  if (!(result instanceof Error)) {
    yield putResolve({ type: types.GET_GIT_REPOS.REQUEST, provider });
    yield putResolve({ type: types.GET_GIT_DESTINATIONS.REQUEST, provider });
  }
}

function * getGitRepos ({ provider }) {
  yield request(types.GET_GIT_REPOS, 'GET', `/git/${provider}/repos`, null, { provider: provider }, null, null);
}

function * getGitDestinations ({ provider }) {
  yield request(types.GET_GIT_DESTINATIONS, 'GET', `/git/${provider}/destinations`, null, { provider: provider });
}

function * getGitProjects ({ provider }) {
  yield request(types.GET_GIT_PROJECTS, 'POST', `/git/${provider}/imports`, null, { provider });
}

function * setMarketplace ({ token }) {
  yield request(
    types.SET_MARKETPLACE,
    'POST',
    '/account/marketplace',
    { token }
  );
}

function * getPlan ({ awsMarketplaceToken }) {
  let result;

  if (awsMarketplaceToken) {
    result = yield request(
      types.SET_MARKETPLACE,
      'POST',
      '/account/marketplace',
      { token: awsMarketplaceToken }
    );

    if (!(result instanceof Error)) {
      removeQueryParameter('x-amzn-marketplace-token');
    }
  }

  if (!awsMarketplaceToken || !(result instanceof Error)) {
    yield request(types.GET_PLAN, 'GET', `/account/plan`);
  }
}

function * switchPlan ({ requestedType }) {
  yield request(
    types.SWITCH_PLAN,
    'PUT',
    '/account/plan',
    { requestedType }
  );
}

function * inviteSignUp ({
  email,
  organization,
  firstName,
  lastName,
  password,
  token
}) {
  yield request(
    types.INVITE_SIGN_UP,
    'POST',
    '/account/adduser',
    {
      email,
      organization,
      firstName,
      lastName,
      password,
      token
    }
  );
}

function * createAccount ({
  email,
  organization,
  firstName,
  lastName,
  password,
  plan,
  promo,
  awsMarketplaceToken
}) {
  yield request(
    types.SIGN_UP,
    'POST',
    '/account',
    {
      email,
      organization,
      firstName,
      lastName,
      password,
      plan,
      promo,
      awsMarketplaceToken
    },
    { email, password, plan, promo }
  );
}

function * verifyUserEmail ({ hmac }) {
  yield request(
    types.VERIFY_USER_EMAIL,
    'POST',
    '/account/verifyemail',
    { hmac }
  );
}

function * resendVerifyEmail () {
  yield request(
    types.RESEND_VERIFY_EMAIL,
    'POST',
    '/account/resendverifyemail'
  );
}

function * removeSetupRequirement () {
  yield request(
    types.REMOVE_SETUP_REQUIREMENT,
    'PUT',
    '/settings/user',
    { isSetupRequired: false }
  );
}

function * getAuthInfo ({ email }) {
  yield request(
    types.GET_AUTH_INFO,
    'GET',
    `/account/authflow?email=${encodeURIComponent(email)}`
  );
}

function * getAccountInfo () {
  yield request(
    types.GET_ACCOUNT_INFO,
    'GET',
    '/account/providers'
  );
}

function * createAccountProvider () {
  yield request(
    types.CREATE_ACCOUNT_PROVIDER,
    'POST',
    '/account/providers',
    { type: 'aws' }
  );
}

function * monitorNewAccountProviderStatus ({ externalId }) {
  let deploymentStatus;
  do {
    yield request(
      types.GET_ACCOUNT_PROVIDER_STATUS,
      'GET',
      `/account/providers/status/${externalId}`);

    yield delay(2000);

    const account = yield select(state => state.account);
    deploymentStatus = account.provider.deploymentStatus;
  } while (deploymentStatus !== providerStates.SUCCESSFUL && deploymentStatus !== providerStates.FAILED);
}

// This function is used to refresh the info whenever someone links an AWS account via the modal
function * refreshEnvironmentAndAccountInfo (action) {
  if (action.data.status === providerStates.SUCCESSFUL) {
    yield putResolve({ type: types.GET_ACCOUNT_INFO.REQUEST });
  }
}

function * getTemplateVersion () {
  const account = yield select(state => state.account);

  yield request(
    types.GET_TEMPLATE_VERSION,
    'GET',
    account.template.url
  );
}

function * getAccountUsage ({ startTime, endTime, page, perPage, filterBy }) {
  let queryParams = [`start_time=${startTime}`, `end_time=${endTime}`, `page=${page}`, `per_page=${perPage}`];
  if (filterBy) {
    queryParams = queryParams.concat(filterBy);
  }
  yield request(
    types.GET_ACCOUNT_USAGE,
    'GET',
    `/usages?${queryParams.join('&')}`
  );
}

function * getActiveStacks ({ startDate, endDate, filterBy }) {
  let queryParams = [];
  if (startDate) {
    queryParams.push(`startDate=${startDate}`);
  }
  if (endDate) {
    queryParams.push(`endDate=${endDate}`);
  }
  if (filterBy) {
    queryParams = queryParams.concat(filterBy);
  }

  yield request(
    types.GET_ACTIVE_STACKS,
    'GET',
    `/usages/active?${queryParams.join('&')}`
  );
}

function * getIntegrations () {
  yield request(
    types.GET_INTEGRATIONS,
    'GET',
    '/account/integrations'
  );
}

function * saveIntegrations ({ integrations }) {
  yield request(
    types.SAVE_INTEGRATIONS,
    'PUT',
    '/account/integrations',
    { integrations },
    { integrations }
  );
}

function * getCliTokens () {
  yield request(
    types.GET_CLI_TOKENS,
    'GET',
    '/apikeys'
  );
}

function * createCliToken ({ description }) {
  yield request(
    types.CREATE_CLI_TOKEN,
    'POST',
    '/apikeys',
    { description },
    { description }
  );
}

function * deleteCliToken ({ id }) {
  yield request(
    types.DELETE_CLI_TOKEN,
    'DELETE',
    `/apikeys/${id}`,
    null,
    { id }
  );
}

function * getBuildEnvironment () {
  yield request(
    types.GET_BUILD_ENVIRONMENT,
    'GET',
    '/settings/org/build-environment'
  );
}

function * saveBuildEnvironment ({ environment }) {
  yield request(
    types.SAVE_BUILD_ENVIRONMENT,
    'PUT',
    '/settings/org/build-environment',
    { values: { environment } },
    { environment }
  );
}

function * getEnvironmentGitDiff ({ owner, stack, environmentId1, environmentId2 }) {
  yield request(
    types.GET_ENVIRONMENT_GIT_DIFF,
    'GET',
    `/stacks/${owner}/${stack}/${environmentId1}/${environmentId2}/environment-diff`,
    null,
    { owner, stack, environmentId1, environmentId2 }
  );
}

export default function * api () {
  yield all([
    takeEvery(types.GET_DEFAULT_BLUEPRINT.REQUEST, getDefaultBlueprint),
    takeLatest(types.GET_STACKS.REQUEST, getStacks),
    takeEvery(types.GET_STACK.REQUEST, getStack),
    takeLatest(types.GET_STACK_SETTINGS.REQUEST, getStackSettings),
    takeLatest(types.SAVE_STACK_SETTINGS.REQUEST, saveStackSettings),
    takeLatest(types.SAVE_STACK_TEMPLATE_PATH.REQUEST, saveStackTemplatePath),
    takeLatest(types.SAVE_STACK_DEPLOYMENT_STRATEGY.REQUEST, saveStackDeploymentStrategy),
    takeLatest(types.SAVE_STACK_DEPLOYMENT_HOOKS_DIRECTORY.REQUEST, saveStackDeploymentHooksDirectory),
    takeLatest(types.SAVE_STACK_BUILD_IMAGE.REQUEST, saveStackBuildImage),
    takeLatest(types.SAVE_STACK_VERIFICATION_PIPELINE.REQUEST, saveStackVerificationPipeline),
    takeEvery(types.GET_IOT_NOTIFICATIONS_CHANNEL_INFO.REQUEST, getIotNotificationsChannelInfo),
    takeEvery(types.SAVE_USER_NOTIFICATION.REQUEST, saveUserNotification),
    takeEvery(types.GET_USER_NOTIFICATIONS.REQUEST, getUserNotifications),
    takeEvery(types.UPDATE_USER_NOTIFICATION.REQUEST, updateUserNotification),
    takeEvery(types.DELETE_USER_NOTIFICATION.REQUEST, deleteUserNotification),
    takeEvery(types.DELETE_ALL_USER_NOTIFICATIONS.REQUEST, deleteAllUserNotifications),
    takeEvery(types.GET_STACK_CONTENTS.REQUEST, getStackContents),
    takeEvery(types.GET_NODE_TEMPLATE.REQUEST, getNodeTemplate),
    takeEvery(types.GET_CONFIG_KEYS.REQUEST, getConfigKeys),
    takeLatest(types.GET_DEPLOYMENTS.REQUEST, getDeployments),
    takeEvery(types.GET_DEPLOYMENT_STATE.REQUEST, getDeploymentState),
    takeEvery(types.GET_CHANGES.REQUEST, getChanges),
    takeLatest(types.CREATE_DEPLOYMENT_PIPELINE.REQUEST, createDeploymentPipeline),
    takeLatest(types.DELETE_DEPLOYMENT_PIPELINE.REQUEST, deleteDeploymentPipeline),
    takeEvery(types.GET_DEPLOYMENT_PIPELINES.REQUEST, getDeploymentPipelines),
    takeEvery(types.GET_DEPLOYMENT_PIPELINE_SETTINGS.REQUEST, getDeploymentPipelineSettings),
    takeEvery(types.GET_DEPLOYMENT_PIPELINE_STATUS.REQUEST, getDeploymentPipelineStatus),
    takeEvery(types.UPDATE_DEPLOYMENT_PIPELINE_SETTINGS.REQUEST, updateDeploymentPipelineSettings),
    takeEvery(types.PROMOTE_DEPLOYMENT_PIPELINE.REQUEST, promoteDeploymentPipeline),
    takeEvery(types.RETRY_DEPLOYMENT_PIPELINE.REQUEST, retryDeploymentPipeline),
    takeEvery(types.SET_STACK_REPO.REQUEST, setStackRepo),
    takeEvery(types.SET_STACK_REPO.SUCCESS, getStackBranchesAndContents),
    takeEvery(types.CREATE_BRANCH.REQUEST, createBranch),
    takeEvery(types.CREATE_BRANCH.FAILURE, getGitAuthUrl),
    takeEvery(types.SAVE_NODES.REQUEST, saveNodes),
    takeEvery(types.SAVE_NODES.FAILURE, getGitAuthUrl),
    takeEvery(types.SAVE_RESOURCES.REQUEST, saveResources),
    takeEvery(types.SAVE_RESOURCES.FAILURE, getGitAuthUrl),
    takeEvery(types.CREATE_BRANCH_AND_SAVE_NODES.REQUEST, createBranchAndSaveNodes),
    takeEvery(types.CREATE_BRANCH_AND_SAVE_RESOURCES.REQUEST, createBranchAndSaveResources),
    takeEvery(types.PREPARE.REQUEST, prepare),
    takeLatest(types.GET_STACK_BRANCHES.REQUEST, getStackBranches),
    takeLatest(types.GET_STACK_BRANCHES.FAILURE, getGitAuthUrl),
    takeLatest(types.GET_STACK_ENVIRONMENTS.REQUEST, getStackEnvironments),
    takeEvery(types.GET_ENVIRONMENTS.REQUEST, getEnvironments),
    takeEvery(types.GET_ENVIRONMENT.REQUEST, getEnvironment),
    takeEvery(types.CREATE_ENVIRONMENT.REQUEST, createEnvironment),
    takeEvery(types.GET_ENVIRONMENT_PARAMETERS.REQUEST, getEnvironmentParameters),
    takeEvery(types.GET_ENVIRONMENT_SECRETS.REQUEST, getEnvironmentSecrets),
    takeEvery(types.CREATE_ENVIRONMENT_SECRET.REQUEST, createEnvironmentSecret),
    takeEvery(types.DELETE_ENVIRONMENT_SECRET.REQUEST, deleteEnvironmentSecret),
    takeEvery(types.SAVE_ENVIRONMENT_SECRET_DESCRIPTION.REQUEST, saveEnvironmentSecretDescription),
    takeEvery(types.SAVE_ENVIRONMENT_SECRET_VALUE.REQUEST, saveEnvironmentSecretValue),
    takeEvery(types.SAVE_ENVIRONMENT_PARAMETERS.REQUEST, saveEnvironmentParameters),
    takeLatest(types.GET_ENVIRONMENT_DEPLOYMENTS.REQUEST, getEnvironmentDeployments),
    takeEvery(types.GET_ORGANIZATION_USERS.REQUEST, getOrganizationUsers),
    takeEvery(types.GET_ORGANIZATION_INVITED_USERS.REQUEST, getOrganizationInvitedUsers),
    takeEvery(types.CREATE_SAME_ORGANIZATION_INVITE.REQUEST, createSameOrganizationInvite),
    takeEvery(types.REMOVE_USER.REQUEST, removeUser),
    takeEvery(types.REMOVE_INVITED_USER.REQUEST, removeInvitedUser),
    takeEvery(types.UPDATE_USER_ROLE.REQUEST, updateUserRole),
    takeEvery(types.CURRENT_USER.REQUEST, getCurrentUser),
    takeEvery(types.SET_MARKETPLACE.REQUEST, setMarketplace),
    takeEvery(types.GET_PLAN.REQUEST, getPlan),
    takeEvery(types.SWITCH_PLAN.REQUEST, switchPlan),
    takeEvery(types.CREATE_STACK.REQUEST, createStack),
    takeEvery(types.CREATE_STACK.SUCCESS, refreshCurrentUser),
    takeEvery(types.DELETE_STACK.REQUEST, deleteStack),
    takeEvery(types.RENAME_STACK.REQUEST, renameStack),
    takeEvery(types.SET_STACK_TAGS.REQUEST, setStackTags),
    takeEvery(types.GET_STACK_BLUEPRINTS.REQUEST, getStackBlueprints),
    takeEvery(types.SAVE_STACK_BLUEPRINT.REQUEST, saveStackBlueprint),
    takeEvery(types.SAVE_STACK_BLUEPRINT.FAILURE, getGitAuthUrl),
    takeEvery(types.DELETE_STACK_BLUEPRINT.REQUEST, deleteStackBlueprint),
    takeEvery(types.DELETE_ENVIRONMENT.REQUEST, deleteEnvironment),
    takeEvery(types.SAVE_GIT_PERSONAL_ACCESS_TOKEN.REQUEST, saveGitPersonalAccessToken),
    takeEvery(types.GET_GIT_AUTH_URL.REQUEST, getGitAuthUrl),
    takeEvery(types.GET_GIT_AUTH_TOKEN.REQUEST, getGitAuthToken),
    takeEvery(types.GET_GIT_REPOS.REQUEST, getGitRepos),
    takeEvery(types.GET_GIT_REPOS.FAILURE, getGitAuthUrl),
    takeEvery(types.GET_GIT_DESTINATIONS.REQUEST, getGitDestinations),
    takeEvery(types.GET_GIT_DESTINATIONS.FAILURE, getGitAuthUrl),
    takeEvery(types.GET_GIT_PROJECTS.REQUEST, getGitProjects),
    takeEvery(types.INVITE_SIGN_UP.REQUEST, inviteSignUp),
    takeEvery(types.SIGN_UP.REQUEST, createAccount),
    takeEvery(types.VERIFY_USER_EMAIL.REQUEST, verifyUserEmail),
    takeEvery(types.RESEND_VERIFY_EMAIL.REQUEST, resendVerifyEmail),
    takeEvery(types.REMOVE_SETUP_REQUIREMENT.REQUEST, removeSetupRequirement),
    takeEvery(types.GET_AUTH_INFO.REQUEST, getAuthInfo),
    takeEvery(types.GET_ACCOUNT_INFO.REQUEST, getAccountInfo),
    takeEvery(types.GET_ACCOUNT_SETTINGS.REQUEST, getAccountSettings),
    takeLatest(types.GET_ACCOUNT_USAGE.REQUEST, getAccountUsage),
    takeLatest(types.GET_ACTIVE_STACKS.REQUEST, getActiveStacks),
    takeLatest(types.GET_TEMPLATE_VERSION.REQUEST, getTemplateVersion),
    takeLatest(types.CREATE_ACCOUNT_PROVIDER.REQUEST, createAccountProvider),
    takeLatest(types.MONITOR_NEW_ACCOUNT_PROVIDER_STATUS.REQUEST, monitorNewAccountProviderStatus),
    takeLatest(types.GET_ACCOUNT_PROVIDER_STATUS.SUCCESS, refreshEnvironmentAndAccountInfo),
    takeLatest(types.GET_INTEGRATIONS.REQUEST, getIntegrations),
    takeLatest(types.SAVE_INTEGRATIONS.REQUEST, saveIntegrations),
    takeLatest(types.GET_CLI_TOKENS.REQUEST, getCliTokens),
    takeLatest(types.CREATE_CLI_TOKEN.REQUEST, createCliToken),
    takeLatest(types.DELETE_CLI_TOKEN.REQUEST, deleteCliToken),
    takeLatest(types.GET_BUILD_ENVIRONMENT.REQUEST, getBuildEnvironment),
    takeLatest(types.SAVE_BUILD_ENVIRONMENT.REQUEST, saveBuildEnvironment),
    takeLatest(types.GET_ENVIRONMENT_GIT_DIFF.REQUEST, getEnvironmentGitDiff)
  ]);
}
