import React from 'react';
import EnvironmentLink from './EnvironmentLink';

const EnvironmentCreatedEvent = (props) => {
  const context = props.context === 'environments' ? <em>{props.environmentName}</em> : <EnvironmentLink {...props} />;

  return <span>Environment {context} added by {props.userEmail}</span>;
};

export default EnvironmentCreatedEvent;
