import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import formatTimestamp from '../../utils/formatTimestamp';
import Tooltip from '../core/Tooltip';
import style from './Timestamp.css';

const formatRelative = (timestamp) => moment(timestamp).fromNow();

const formatAbsolute = (timestamp) => formatTimestamp(timestamp);

class Timestamp extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      relativeTimestamp: formatRelative(this.props.timestamp)
    };

    this.updateRelativeTimestamp = this.updateRelativeTimestamp.bind(this);
  }

  updateRelativeTimestamp () {
    this.setState({ relativeTimestamp: formatRelative(this.props.timestamp) });
  }

  componentDidMount () {
    this.interval = setInterval(this.updateRelativeTimestamp, 60000);
  }

  componentDidUpdate (prevProps) {
    if (this.props.timestamp !== prevProps.timestamp) {
      this.updateRelativeTimestamp();
    }
  }

  componentWillUnmount () {
    clearInterval(this.interval);
  }

  render () {
    const {
      timestamp,
      status
    } = this.props;

    return (
      <Tooltip className={style.container} message={formatAbsolute(timestamp)} place='top' isInline>
        <span>{status} {this.state.relativeTimestamp}</span>
      </Tooltip>
    );
  }
}

Timestamp.propTypes = {
  status: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired
};

export default Timestamp;
