import makeActions from '../utils/makeActions';

export const types = {
  LAYOUT_NODES: makeActions('APP', 'LAYOUT_NODES', ['TRY', 'SUCCESS', 'FAILURE']),
  SELECT_NODE: 'APP::CANVAS::SELECT_NODE',
  SELECT_NODES: 'APP::CANVAS::SELECT_NODES',
  DESELECT_NODE: 'APP::CANVAS::DESELECT_NODE',
  CLEAR_SELECTION: 'APP::CANVAS::CLEAR_SELECTION',
  MOVE_NODES: 'APP::CANVAS::MOVE_NODES',
  RESIZE_NODE: 'APP::CANVAS::RESIZE_NODE',
  SELECT_WIRE: 'APP::CANVAS::SELECT_WIRE',
  MOVE_WIRE: 'APP::CANVAS::MOVE_WIRE',
  WIRE_NODES: 'APP::CANVAS::WIRE_NODES',
  RESET_MOUSE_STATE: 'APP::CANVAS::RESET_MOUSE_STATE',
  NODE_MOUSE_UP: 'APP::CANVAS::NODE_MOUSE_UP',
  NODE_MOUSE_DOWN: 'APP::CANVAS::NODE_MOUSE_DOWN',
  NODE_RESIZE_MOUSE_DOWN: 'APP::CANVAS::NODE_RESIZE_MOUSE_DOWN',
  WIRE_MOUSE_DOWN: 'APP::CANVAS::WIRE_MOUSE_DOWN',
  PORT_MOUSE_DOWN: 'APP::CANVAS::PORT_MOUSE_DOWN',
  PORT_HIGHLIGHT: 'APP::CANVAS::PORT_HIGHLIGHT',
  PORT_UNHIGHLIGHT: 'APP::CANVAS::PORT_UNHIGHLIGHT',
  DELETE_SELECTION: 'APP::CANVAS::DELETE_SELECTION',
  OPEN_PALETTE: 'APP::CANVAS::OPEN_PALETTE',
  DRAG_NEW_NODES: 'APP::CANVAS::DRAG_NEW_NODES',
  CREATE_RESOURCE: 'APP::CANVAS::CREATE_RESOURCE',
  UPDATE_NODE: 'APP::CANVAS::UPDATE_NODE',
  INVALIDATE_NODES: 'APP::CANVAS::INVALIDATE_NODES',
  UPDATE_VALIDATION: 'APP::CANVAS::UPDATE_VALIDATION',
  START_RESOURCES: 'APP::CANVAS::START_RESOURCES',
  STOP_RESOURCES: 'APP::CANVAS::STOP_RESOURCES',
  VALIDATE_RESOURCES: 'APP::CANVAS::VALIDATE_RESOURCES',
  UPDATE_RESOURCES: 'APP::CANVAS::UPDATE_RESOURCES',
  SET_SCALE: 'APP::CANVAS::SET_SCALE'
};

const actions = {
  startResources: () => ({ type: types.START_RESOURCES }),
  stopResources: () => ({ type: types.STOP_RESOURCES }),
  validateResources: () => ({ type: types.VALIDATE_RESOURCES }),
  layoutNodes: (resources, dimensions, isWrapEnabled, deploymentId, nodes) => ({
    type: types.LAYOUT_NODES.TRY,
    resources,
    dimensions,
    isWrapEnabled,
    deploymentId,
    nodes
  }),
  selectNode: node => ({ type: types.SELECT_NODE, node }),
  selectNodes: nodes => ({ type: types.SELECT_NODES, nodes }),
  deselectNode: node => ({ type: types.DESELECT_NODE, node }),
  clearSelection: () => ({ type: types.CLEAR_SELECTION }),
  moveNodes: (nodes, dx, dy) => ({ type: types.MOVE_NODES, nodes, dx, dy }),
  resizeNode: (node, x, y, width, height, dx, dy) => ({
    type: types.RESIZE_NODE,
    node,
    x,
    y,
    width,
    height,
    dx,
    dy
  }),
  selectWire: wire => ({ type: types.SELECT_WIRE, wire }),
  moveWire: (x, y) => ({ type: types.MOVE_WIRE, x, y }),
  wireNodes: (source, port, target, facetType, facet) => ({
    type: types.WIRE_NODES,
    source,
    port,
    target,
    facetType,
    facet
  }),
  resetMouseState: () => ({ type: types.RESET_MOUSE_STATE }),
  nodeMouseUp: (id, isParent, children, isChild, parent) => ({
    type: types.NODE_MOUSE_UP,
    id,
    isParent,
    children,
    isChild,
    parent
  }),
  nodeMouseDown: (id, x, y, metaKey) => ({
    type: types.NODE_MOUSE_DOWN,
    id,
    x,
    y,
    metaKey
  }),
  nodeResizeMouseDown: (id, x, y, side) => ({
    type: types.NODE_RESIZE_MOUSE_DOWN,
    id,
    x,
    y,
    side
  }),
  wireMouseDown: (source, port, target, x, y) => ({
    type: types.WIRE_MOUSE_DOWN,
    source,
    port,
    target,
    x,
    y
  }),
  portMouseDown: (node, port, x, y) => ({
    type: types.PORT_MOUSE_DOWN,
    node,
    port,
    x,
    y
  }),
  portHighlight: (node, port) => ({
    type: types.PORT_HIGHLIGHT,
    node,
    port
  }),
  portUnhighlight: () => ({ type: types.PORT_UNHIGHLIGHT }),
  deleteSelection: (nodes, wire) => ({
    type: types.DELETE_SELECTION,
    nodes,
    wire
  }),
  updateResources: (resources) => ({ type: types.UPDATE_RESOURCES, resources }),
  openPalette: () => ({ type: types.OPEN_PALETTE }),
  dragNewNodes: (nodes, x, y, isClick) => ({ type: types.DRAG_NEW_NODES, nodes, x, y, isClick }),
  createResource: (node, isClick) => ({ type: types.CREATE_RESOURCE, node, isClick }),
  updateNode: node => ({ type: types.UPDATE_NODE, node }),
  invalidateNodes: () => ({ type: types.INVALIDATE_NODES }),
  updateValidation: (errors, resources) => ({ type: types.UPDATE_VALIDATION, errors, resources }),
  setScale: (scale) => ({ type: types.SET_SCALE, scale })
};

export default actions;
