
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import * as gitProviderStates from '../../constants/gitProviderStates';
import FormRow from '../core/FormRow';
import Input from '../core/Input';
import Select from '../core/Select';
import Link from '../core/Link';
import LinkProviderButton from '../core/LinkProviderButton';
import LinkAwsButtonContainer from '../linkAwsButton/LinkAwsButtonContainer';
import Spinner from '../core/Spinner';

const RepoProvider = props => {
  const {
    gitProviders,
    selectedGitProvider
  } = props;

  const gitProvider = gitProviders[selectedGitProvider] || {};
  const isPersonalAccessTokenNeeded = gitProvider.needsPersonalAccessToken;

  return (
    <Fragment>
      <FormRow label='Git Provider'>
        <Select
          name='selectedGitProvider'
          required
          onChange={props.onInputChange}
          defaultValue={''}
        >
          <option disabled value={''}>Choose Git Provider</option>
          {Object.entries(gitProviders).filter(([name, provider]) => !!provider.name && !!provider.displayName).map(([name, provider]) =>
            <option key={provider.name} value={provider.name}>{provider.displayName}</option>
          )}
        </Select>
      </FormRow>

      {gitProvider.state === gitProviderStates.LOADING &&
        <Spinner position='static' />
      }

      {gitProvider.state === gitProviderStates.URL_ACQUIRED &&
        <LinkProviderButton
          provider={gitProvider.name}
          label={gitProvider.displayName}
          href={gitProvider.authUrl}
          hasLinked={false}
        />
      }

      {gitProvider.state !== gitProviderStates.LOADING && gitProvider.state !== gitProviderStates.AUTHORIZED && selectedGitProvider === 'codecommit' &&
        <LinkAwsButtonContainer />
      }

      {isPersonalAccessTokenNeeded &&
        <Fragment>
          <p>Add a Personal Access Token to link your Stackery and {gitProvider.displayName} accounts. For instructions on creating a personal access token and more details on tokens, see <Link href='https://confluence.atlassian.com/bitbucketserver/personal-access-tokens-939515499.html' target='_blank'>Atlassian's documentation here</Link>.</p>
          <FormRow label='User Name'>
            <Input
              name='user'
              type='text'
              required
              onChange={props.onInputChange}
            />
          </FormRow>
          <FormRow label='Personal Access Token'>
            <Input
              name='token'
              type='text'
              required
              onChange={props.onInputChange}
            />
          </FormRow>
          <p>Note: Tokens must be granted Admin permissions to Projects and Repos.</p>
        </Fragment>
      }
    </Fragment>
  );
};

RepoProvider.propTypes = {
  selectedGitProvider: PropTypes.string,
  gitProviders: PropTypes.object.isRequired,
  onInputChange: PropTypes.func.isRequired
};

export default RepoProvider;
