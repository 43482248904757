import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import crosstab from 'crosstab';
import apiActions from '../../actions/api';
import appActions from '../../actions/app';
import * as planTypes from '../../constants/planTypes';
import * as states from '../../constants/states';
import * as providerStates from '../../constants/providerStates';
import StackSettings from './StackSettings';

function getStackGitProvider (stack) {
  const match = stack.link && stack.link.match(/^(\S+):/);
  const provider = match && match[1];
  return provider || 'unknown';
}

const mapStateToProps = () => {
  return createSelector(
    (appState) => appState.currentUser,
    (appState) => appState.environments,
    (appState) => appState.gitProviders,
    (appState) => appState.stack,
    (appState) => appState.stackSettings,
    (appState) => appState.account,
    (currentUser, environments, gitProviders, stack, stackSettings, account) => {
      const provider = getStackGitProvider(stack);
      const gitProvider = gitProviders[provider] || {};
      const linkedGitProvider = currentUser.linkedGitProviders.find(linkedProvider => linkedProvider.provider === provider);
      const isPrWebhookAuthorized = linkedGitProvider && linkedGitProvider.isPrWebhookAuthorized;

      return {
        environments,
        gitProvider,
        stack,
        stackSettings,
        plan: account.plan,
        accountProviderStatus: account.provider.deploymentStatus,
        isDevPlan: account.plan.type === planTypes.DEVELOPER,
        isLoading: stackSettings.state === states.NEW || stackSettings.state === states.LOADING || environments.state === states.LOADING,
        isSaving: stackSettings.savingState === states.LOADING,
        isAccountLinked: account.providers.length > 0,
        isDeployerRoleEnabled: account.providers.filter(provider => provider.isDeployerRoleEnabled).length > 0,
        verificationEnvironments: environments.data.filter(item => item.isDeployerRoleEnabled === true),
        isPrWebhookAuthorized
      };
    }
  );
};

const mapDispatchToProps = {
  getAccountInfo: apiActions.getAccountInfo,
  getCurrentUser: apiActions.getCurrentUser,
  getEnvironments: apiActions.getEnvironments,
  getGitAuthUrl: apiActions.getGitAuthUrl,
  getStackSettings: apiActions.getStackSettings,
  saveStackSettings: apiActions.saveStackSettings,
  setStackTags: apiActions.setStackTags,
  notifyUser: appActions.notifyUser,
  showLinkAwsModal: appActions.showLinkAwsModal,
  showCreateEnvironmentModal: appActions.showCreateEnvironmentModal,
  showUpdatePipelineDeploymentsModal: appActions.showUpdatePipelineDeploymentsModal,
  hideModal: appActions.hideModal
};

class StackSettingsContainer extends Component {
  constructor (props) {
    super(props);

    this.state = {
      name: props.stackSettings.name,
      templatePath: props.stackSettings.templatePath,
      deploymentStrategy: props.stackSettings.deploymentStrategy,
      deploymentHooksDirectory: props.stackSettings.deploymentHooksDirectory,
      buildImage: props.stackSettings.buildImage,
      verificationPipeline: props.stackSettings.verificationPipeline,
      verificationEnvironmentId: props.stackSettings.verificationEnvironmentId,
      verificationEnvironments: [],
      isVerificationPipelineEnabled: Object.values(props.stackSettings.verificationPipeline).filter(option => option === true).length > 0
    };

    this.handleReload = this.handleReload.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSelect (event) {
    this.setState({ verificationEnvironmentId: parseInt(event.currentTarget.value, 10) });
  }

  handleToggle (id, value) {
    this.setState({ verificationPipeline: { ...this.state.verificationPipeline, [id]: value } });
  }

  handleInputChange (event) {
    const {
      name,
      value
    } = event.target;

    this.setState({ [name]: value });
  }

  handleSubmit (event) {
    event.preventDefault();

    this.props.saveStackSettings(
      this.props.stack,
      this.state.name,
      this.state.templatePath,
      this.state.deploymentStrategy,
      this.state.deploymentHooksDirectory,
      this.state.buildImage,
      { ...this.state.verificationPipeline, ephemeralEnvironmentId: this.state.verificationEnvironmentId }
    );
  }

  handleReload () {
    this.props.getEnvironments();
    this.props.getAccountInfo();
    this.props.hideModal();
  }

  componentDidMount () {
    const {
      owner,
      stack
    } = this.props.match.params;

    this.props.getEnvironments();
    this.props.getGitAuthUrl(getStackGitProvider(this.props.stack));

    if (this.props.stackSettings.state === states.NEW) {
      this.props.getStackSettings(owner, stack);
    }

    crosstab.once('StackeryOAuthComplete', (message) => {
      crosstab.broadcast('StackeryOAuthCloseWindow');
      this.props.notifyUser(message.data.info, message.data.type);

      if (message.data.status === 'success') {
        this.props.notifyUser('Git authorization successful', 'success');
        this.props.getCurrentUser();
      }
    });
  }

  componentDidUpdate (prevProps, prevState) {
    const {
      environments,
      stackSettings,
      accountProviderStatus
    } = this.props;

    const {
      verificationPipeline,
      isVerificationPipelineEnabled
    } = this.state;

    if (prevProps.stackSettings.state !== stackSettings.state && stackSettings.state === states.OKAY) {
      this.setState({
        verificationPipeline: stackSettings.verificationPipeline,
        verificationEnvironmentId: stackSettings.verificationEnvironmentId
      });
    }

    if (prevProps.environments.state !== environments.state && environments.state === states.OKAY) {
      this.setState({
        verificationEnvironments: environments.data.filter(env => env.isDeployerRoleEnabled === true)
      });
    }

    if (prevState.verificationPipeline !== verificationPipeline) {
      this.setState({
        isVerificationPipelineEnabled: Object.values(verificationPipeline).filter(option => option === true).length > 0
      });
    }

    if (prevState.isVerificationPipelineEnabled && !isVerificationPipelineEnabled) {
      this.setState({
        verificationEnvironmentId: null
      });
    }

    if (prevProps.stackSettings.savingState === states.LOADING && stackSettings.savingState === states.OKAY) {
      this.props.notifyUser('Stack settings saved', 'success');
    }

    if (prevProps.stackSettings.savingState === states.LOADING && stackSettings.savingState === states.FAILED) {
      this.props.notifyUser('Failed to save stack settings', 'error');
    }

    if (stackSettings.error && prevProps.stackSettings.error !== stackSettings.error) {
      this.props.notifyUser(stackSettings.error, 'error');
    }

    if (prevProps.accountProviderStatus !== accountProviderStatus && accountProviderStatus === providerStates.SUCCESSFUL) {
      this.props.getAccountInfo();
    }
  }

  render () {
    return (
      <StackSettings
        {...this.props}
        {...this.state}
        onReload={this.handleReload}
        onInputChange={this.handleInputChange}
        onSelect={this.handleSelect}
        onToggle={this.handleToggle}
        onSubmit={this.handleSubmit}
      />
    );
  }
}

StackSettings.propTypes = {
  stack: PropTypes.object.isRequired,
  stackSettings: PropTypes.object.isRequired,
  saveStackSettings: PropTypes.func.isRequired,
  isDevPlan: PropTypes.bool.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(StackSettingsContainer);
