/* NOTE: This component uses hooks instead of lifecycle methods. This is a new pattern in the codebase. */

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import * as deploymentStates from '../../constants/deploymentStates';
import * as jobStates from '../../constants/jobStates';
import Link from '../core/Link';

const DeploymentNotification = (props) => {
  let Content;
  const isPipelineDeployment = props.deploymentMethod === 'pipeline';

  switch (props.status || props.deploymentStatus) {
    case deploymentStates.PREPARING:
      Content = (
        <Fragment>
          {isPipelineDeployment &&
            <p>Deploying <Link to={`/stacks/${props.owner}/${props.stackName}`} hid='user-notification-stack-link' onClick={props.onClose}>{props.stackName}</Link> to <Link to={`/environments/${props.owner}/${props.environmentName}`} hid='user-notification-environment-link' onClick={props.onClose}>{props.environmentName}</Link></p>
          }
          {!isPipelineDeployment &&
            <p>Preparing changeset to deploy <Link to={`/stacks/${props.owner}/${props.stackName}`} hid='user-notification-stack-link' onClick={props.onClose}>{props.stackName}</Link> to <Link to={`/environments/${props.owner}/${props.environmentName}`} hid='user-notification-environment-link' onClick={props.onClose}>{props.environmentName}</Link></p>
          }
        </Fragment>
      );
      break;

    case deploymentStates.CHANGE_SET_CREATED:
      Content = (
        <Fragment>
          <p>Prepared <Link to={`/stacks/${props.owner}/${props.stackName}`} hid='user-notification-stack-link' onClick={props.onClose}>{props.stackName}</Link> for <Link to={`/environments/${props.owner}/${props.environmentName}`} hid='user-notification-environment-link' onClick={props.onClose}>{props.environmentName}</Link></p>
        </Fragment>
      );
      break;

    case deploymentStates.DEPLOYED:
      Content = (
        <Fragment>
          <p>Deployed <Link to={`/stacks/${props.owner}/${props.stackName}`} hid='user-notification-stack-link' onClick={props.onClose}>{props.stackName}</Link> to <Link to={`/environments/${props.owner}/${props.environmentName}`} hid='user-notification-environment-link' onClick={props.onClose}>{props.environmentName}</Link></p>
        </Fragment>
      );
      break;

    case deploymentStates.FAILING:
      Content = (
        <Fragment>
          <p>Failed to deploy <Link to={`/stacks/${props.owner}/${props.stackName}`} hid='user-notification-stack-link' onClick={props.onClose}>{props.stackName}</Link> to <Link to={`/environments/${props.owner}/${props.environmentName}`} hid='user-notification-environment-link' onClick={props.onClose}>{props.environmentName}</Link></p>
          {props.message && <p>{props.message.trim()}</p>}
        </Fragment>
      );
      break;

    case deploymentStates.FAILED:
      if (props.didPrepareFail) {
        Content = (
          <Fragment>
            <p>Failed to prepare <Link to={`/stacks/${props.owner}/${props.stackName}`} hid='user-notification-stack-link' onClick={props.onClose}>{props.stackName}</Link> for <Link to={`/environments/${props.owner}/${props.environmentName}`} hid='user-notification-environment-link' onClick={props.onClose}>{props.environmentName}</Link></p>
            {props.message && <p>{props.message.trim()}</p>}
          </Fragment>
        );
      } else {
        Content = (
          <Fragment>
            <p>Failed to deploy <Link to={`/stacks/${props.owner}/${props.stackName}`} hid='user-notification-stack-link' onClick={props.onClose}>{props.stackName}</Link> to <Link to={`/environments/${props.owner}/${props.environmentName}`} hid='user-notification-environment-link' onClick={props.onClose}>{props.environmentName}</Link></p>
            {props.message && <p>{props.message.trim()}</p>}
          </Fragment>
        );
      }
      break;

    default:
      break;
  }

  return Content;
};

const DeploymentPipelineJob = (props) => {
  let Content;

  switch (props.status || props.deploymentStatus) {
    case jobStates.STAGE_PROMOTED:
      Content = (
        <Fragment>
          <p>Promoted <Link to={`/stacks/${props.owner}/${props.stackName}`} hid='user-notification-stack-link' onClick={props.onClose}>{props.stackName}</Link> to <Link to={`/environments/${props.owner}/${props.promotedTo}`} hid='user-notification-environment-link' onClick={props.onClose}>{props.promotedTo}</Link></p>
        </Fragment>
      );
      break;

    case jobStates.MANUAL_APPROVAL_PENDING:
      Content = (
        <Fragment>
          <p>Ready to <Link to={`/deployments#pipeline-${props.pipelineId}-${props.stackName}`} hid='user-notification-pipeline-stack-link' onClick={props.onClose}>promote {props.stackName}</Link> to {props.promoteTo}</p>
        </Fragment>
      );
      break;

    default:
      break;
  }

  return Content;
};

const DefaultNotification = (props) => (
  <Fragment><p>{props.message}</p></Fragment>
);

const mapEventToComponent = {
  deployment: DeploymentNotification,
  deploymentPipelineJob: DeploymentPipelineJob
};

const UserNotificationContent = (props) => {
  const {
    notification
  } = props;

  const Component = mapEventToComponent[notification.eventType] || DefaultNotification;

  return (
    <Component {...notification} owner={props.owner} onClose={props.onClose} />
  );
};

UserNotificationContent.propTypes = {
  notification: PropTypes.object.isRequired,
  owner: PropTypes.string,
  onClose: PropTypes.func
};

export default UserNotificationContent;
