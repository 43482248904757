import React from 'react';
import PropTypes from 'prop-types';
import makeGitLink from '../../utils/makeGitLink';
import GitLink from '../core/GitLink';
import Icon from '../core/Icon';
import Timestamp from './Timestamp';
import style from './JobItemHeader.css';

const JobItemHeader = props => {
  const {
    status,
    version,
    timestamp,
    gitProviders
  } = props;

  return (
    <header className={style.container}>
      <Icon className={style.iconGit} name='git' />
      <GitLink className={style.version} gitLink={makeGitLink({ link: props.link }, gitProviders, version)} label={version.substring(0, 7)} modifier='version' />
      <Timestamp status={status} timestamp={timestamp} />
    </header>
  );
};

JobItemHeader.propTypes = {
  link: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
  gitProviders: PropTypes.object.isRequired
};

export default JobItemHeader;
