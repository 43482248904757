import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Link from '../core/Link';
import Spinner from '../core/Spinner';
import Deployment from '../stackDeploy/Deployment';
import EnvironmentSelectorContainer from '../stack/EnvironmentSelectorContainer';
import NodeDeploymentInfoContainer from '../nodeDeploymentInfo/NodeDeploymentInfoContainer';
import CanvasContainer from '../canvas/CanvasContainer';
import style from './StackView.css';

const StackView = props => {
  const {
    stack,
    deployments,
    showNodeDeploymentInfo,
    isDeploymentsLoaded,
    isDeploymentsLoading,
    isEnvironmentsLoaded,
    hasEnvironments
  } = props;

  return (
    <div className={classnames(style.container, showNodeDeploymentInfo && style.isPanelOpen)}>
      <div className={style.sidebar}>
        <EnvironmentSelectorContainer />
        {isDeploymentsLoading &&
          <Spinner position='static' />
        }
        {isDeploymentsLoaded && !deployments.currentDeployment &&
          <p>Nothing currently deployed on <strong>{stack.environment}</strong> environment.</p>
        }
        {isEnvironmentsLoaded && !hasEnvironments &&
          <Fragment>
            <p>No environments have been created.</p>
            <p><Link to='/environments'>Add an environment</Link> to get started.</p>
          </Fragment>
        }
        <ul className={style.deploymentsList}>
          {deployments.currentDeployment &&
            <li className={classnames(style.deploymentItem, {[style.isSelected]: deployments.selectedDeploymentId === deployments.currentDeployment.id})}>
              <Deployment
                deployment={deployments.currentDeployment}
                isSelected={deployments.selectedDeploymentId === deployments.currentDeployment.id}
                showTimestamp
              />
            </li>
          }
          {deployments.previousDeployments.length > 0 && deployments.previousDeployments.map((deployment, i) => (
            <li className={classnames(style.deploymentItem, {[style.isSelected]: deployments.selectedDeploymentId === deployment.id})} key={deployment.id}>
              <Deployment
                deployment={deployment}
                isSelected={deployments.selectedDeploymentId === deployment.id}
                showTimestamp
              />
            </li>
          ))}
        </ul>
      </div>

      <div className={style.main}>
        <CanvasContainer />
        {showNodeDeploymentInfo &&
          <NodeDeploymentInfoContainer />
        }
      </div>
    </div>
  );
};

StackView.propTypes = {
  deployments: PropTypes.object,
  stack: PropTypes.object,
  showNodeDeploymentInfo: PropTypes.bool,
  isDeploymentsLoaded: PropTypes.bool,
  isDeploymentsLoading: PropTypes.bool,
  isEnvironmentsLoaded: PropTypes.bool,
  hasEnvironments: PropTypes.bool
};

export default StackView;
