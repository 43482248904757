import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router';
import DocumentTitle from 'react-document-title';
import classnames from 'classnames';
import DemoBanner from './DemoBanner';
import HeaderContainer from './HeaderContainer';
import UserNotificationsContainer from '../userNotifications/UserNotificationsContainer';
import SetupContainer from '../setup/SetupContainer';
import SettingsContainer from '../settings/SettingsContainer';
import HomeContainer from '../home/HomeContainer';
import StacksContainer from '../stacks/StacksContainer';
import EnvironmentsContainer from '../environments/EnvironmentsContainer';
import OAuthContainer from '../stackRepoForm/OAuthContainer';
import StackContainer from '../stack/StackContainer';
import DeploymentsContainer from '../deployments/DeploymentsContainer';
import ModalContainer from '../modal/ModalContainer';
import EnvironmentContainer from '../environment/EnvironmentContainer';
import PublicEditorContainer from '../publicEditor/PublicEditorContainer';
import FeatureFlag from '../featureFlag/featureFlag';
import Demo from '../demo/Demo';
import Spinner from '../core/Spinner';
import TourContainer from '../tour/TourContainer';
import WorkspaceContainer from '../workspace/WorkspaceContainer';
import { onTransition } from '../../utils/transition';
import getQueryValue from '../../utils/getQueryValue';
import * as states from '../../constants/states';
import style from './AuthorizedApp.css';

class AuthorizedApp extends Component {
  componentDidMount () {
    // Our AWS Marketplace listing directs new subscribers to the billing page,
    // and Marketplace attaches its own token as a query string; we can use the
    // token on the back-end to find the AWS customer id.
    let awsMarketplaceToken = getQueryValue(window.location.search, 'x-amzn-marketplace-token');
    const redirect = getQueryValue(window.location.search, 'redirect');
    const redirectQuery = redirect && redirect.indexOf('?') >= 0 && redirect.substring(redirect.indexOf('?'));
    if (!awsMarketplaceToken && redirectQuery) {
      // If the user wasn't logged-in when first getting redirected to the Stackery app,
      // the actual query string containing the marketplace token will be inside the redirect query
      awsMarketplaceToken = getQueryValue(redirectQuery, 'x-amzn-marketplace-token');
    }

    this.props.getAccountInfo();
    this.props.getCurrentUser();
    this.props.getOrganizationUsers();
    this.props.getAccountSettings();
    this.props.getCognitoUser();
    this.props.getPlan(awsMarketplaceToken);
  }

  componentDidUpdate (prevProps) {
    onTransition(prevProps, this.props, 'plan', {
      [states.FAILED]: {
        notify: {
          message: 'Billing plan update failed; please contact billing@stackery.io for assistance',
          level: 'error'
        }
      }
    });

    if (prevProps.currentUser.state !== this.props.currentUser.state && this.props.currentUser.state === states.OKAY) {
      this.props.startIotNotifications(this.props.currentUser.org);
    }
  }

  componentWillUnmount () {
    this.props.stopIotNotifications();
  }

  render () {
    const {
      currentUser,
      isModalOpen,
      isChromeless,
      isLoaded
    } = this.props;

    const {
      isDemoMode,
      hasLinkedAws,
      isTourMode,
      isDeactivated
    } = currentUser;

    return (
      <div className={classnames(style.container, isModalOpen && style.hasModal, isDemoMode && 'isDemoMode', isChromeless && 'isChromeless')}>
        {isDemoMode &&
          <DemoBanner />
        }
        <DocumentTitle title='Stackery' />
        <UserNotificationsContainer />
        {!isChromeless &&
          <HeaderContainer />
        }

        {!isLoaded &&
          <Spinner />
        }

        {isLoaded && isDeactivated &&
          <div className={style.content}>
            <Switch>
              <Route path='/settings' component={SettingsContainer} />
              <Redirect to='/settings/billing' />
            </Switch>
          </div>
        }

        {isLoaded && !isDeactivated &&
          <div className={style.content}>
            <Switch>
              <Route exact path='/' component={HomeContainer} />
              <Route path='/setup' component={SetupContainer} />
              <Route path='/settings' component={SettingsContainer} />
              <Route exact path='/demo/:status' component={Demo} />
              <Route exact path='/feature/:feature/:status' component={FeatureFlag} />
              { /* N.B. Anything below here requires that the customer's stackery
                  * and AWS accounts have been linked via the CLI tool */ }
              <Route exact path='/stacks' component={StacksContainer} />
              <Route path='/stacks/:owner/:stack' component={StackContainer} />
              <Route exact path='/environments' component={EnvironmentsContainer} />
              {hasLinkedAws &&
                <Route path='/environments/:owner/:environment' component={EnvironmentContainer} />
              }
              {!hasLinkedAws &&
                <Route exact path='/environments/:owner/:environment' render={() => <Redirect to='/environments' />} />
              }
              <Route exact path='/oauth/:provider' component={OAuthContainer} />

              { /* Redirect from 3rd-party integrations when they have the AWS
                 * account ID but not the Stackery org ID. Swap /sbaa/:awsAccountId
                 * for /stack/:owner. */ }
              <Route path='/sbaa/:awsAccountId' render={
                ({ location }) => <Redirect to={location.pathname.replace(/\/sbaa\/[^/]*\//, `/stacks/${currentUser.org}/`)} />
              }
              />

              <Route exact path='/deployments' component={DeploymentsContainer} />
              <Route path='/editor' component={PublicEditorContainer} />
              <Route exact path='/local' component={WorkspaceContainer} />
              <Route path='/local/:resourceId' component={WorkspaceContainer} />
              <Redirect to='/' />
            </Switch>

          </div>
        }

        {isModalOpen &&
          <ModalContainer />
        }
        {isTourMode &&
          <TourContainer />
        }
      </div>
    );
  }
}

export default AuthorizedApp;
