/**
 * Open a URI in an external app from a VSCode context.
 */
export default uri => {
  window.parent.postMessage({
    command: 'onmessage',
    data: {
      type: 'vscode.env.openExternal',
      uri
    }
  }, '*');
};
