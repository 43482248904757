import { CognitoRefreshToken } from 'amazon-cognito-identity-js';
import { types } from '../actions/auth';
import { types as apiTypes } from '../actions/api';
import * as states from '../constants/authStates';
export const initialState = {
  state: states.NEW,
  refreshToken: undefined,
  token: undefined,
  expiration: undefined,
  impersonate: undefined,
  authType: undefined,
  authProvider: undefined
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case types.SIGN_IN.REQUEST:
      return { ...state, state: states.LOADING };
    case types.SIGN_IN.SUCCESS:
      return { ...state, ...action.data, state: states.OKAY };
    case types.SIGN_IN.FAILURE:
      return { ...state, state: states.FAILED, error: action.error };
    case types.SIGN_OUT.SUCCESS:
      return { ...initialState, state: states.LOGGED_OUT };
    case types.REFRESH_TOKEN.SUCCESS:
      return { ...state, ...action.data, state: states.OKAY };
    // case types.REFRESH.FAILURE:
    //   return { ...state, state: states.FAILURE }
    case types.IMPERSONATE:
      return { ...state, impersonate: action.email };
    case types.RESET_AUTH_INFO:
      return { ...state, authType: undefined, authProvider: undefined };
    case apiTypes.GET_AUTH_INFO.REQUEST:
      return { ...state, state: states.LOADING };
    case apiTypes.GET_AUTH_INFO.SUCCESS:
      return {
        ...state,
        authType: action.data.type,
        authProvider: action.data.provider,
        state: states.OKAY
      };
    case apiTypes.GET_AUTH_INFO.FAILURE:
      return { ...state, state: states.FAILED, error: action.error.message };
    case types.GET_TOKEN.REQUEST:
      return { ...state, state: states.LOADING };
    case types.GET_TOKEN.SUCCESS:
      return {
        ...state,
        state: states.OKAY,
        token: action.data.access_token,
        refreshToken: new CognitoRefreshToken({ RefreshToken: action.data.refresh_token }),
        expiration: action.data.expires_in
      };
    case types.GET_TOKEN.FAILURE:
      return { ...state, state: states.FAILED, error: action.error };
    default:
      return state;
  }
};
