import { types } from '../actions/api';
import * as states from '../constants/states';

export const initialState = {
  state: states.NEW
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case types.REMOVE_USER.REQUEST:
      return { ...state, state: states.LOADING };
    case types.REMOVE_USER.FAILED:
      return { ...state, error: action.error, state: states.FAILED };
    case types.REMOVE_USER.SUCCESS:
      return { ...state, state: states.OKAY, ...action.data };
    default:
      return state;
  }
};
