import cloneDeep from 'clone-deep';
import deepEqual from 'deep-equal';
import * as query from './query';
import transformations from './transformations';
import resolveSubBindings from './resolveSubBindings';
import Parameter from './parameter';

export const ERROR_CODES = {
  UNDEFINED_CONTEXT_KEY: 'undefinedContextKey'
};

export const inject = (template, definitions, context, object) => {
  for (const definition of definitions) {
    const { finalBinding, contexts } = resolveSubBindings(template, object, definition, context);

    if (finalBinding.Template !== null && finalBinding.Template !== undefined) {
      const definitionTemplate = cloneDeep(finalBinding.Template);
      const interpolatedDefinitionTemplate = injectContext(definitionTemplate, context);

      for (const context of contexts) {
        query.update(finalBinding.Path, template, object, interpolatedDefinitionTemplate, context);
      }
    } else if (finalBinding.Append !== null && finalBinding.Append !== undefined) {
      const appendTemplate = cloneDeep(finalBinding.Append);
      const interpolatedAppendTemplate = injectContext(appendTemplate, context);

      for (const context of contexts) {
        const currentValue = query.value(finalBinding.Path, template, object, context);
        if (Array.isArray(currentValue)) {
          currentValue.push(interpolatedAppendTemplate);
        } else {
          query.update(finalBinding.Path, template, object, [interpolatedAppendTemplate], context);
        }
      }
    } else {
      for (const context of contexts) {
        query.delete(finalBinding.Path, template, object, context);
      }
    }
  }
};

export const injectContext = (template, context) => {
  if (typeof template === 'string') {
    return contextReplace(template, context);
  }

  for (const key in template) {
    const value = template[key];
    const injectedKey = contextReplace(key, context);

    if (injectedKey !== key) {
      delete template[key];
    }

    if (typeof value === 'string') {
      template[injectedKey] = contextReplace(value, context);

      if (template[injectedKey] === undefined) {
        delete template[injectedKey];
      }
    } else {
      template[injectedKey] = value;
      injectContext(value, context);
    }
  }

  return template;
};

const NATIVE_VALUE_CONTEXT_RE = /^%\{([^}]+)\}$/;
const CONTEXT_RE = /%\{([^}]+)\}/g;
const contextReplace = (string, context) => {
  const nativeMatch = string.match(NATIVE_VALUE_CONTEXT_RE);
  if (nativeMatch) {
    return transform(nativeMatch[1], context);
  } else {
    return string.replace(CONTEXT_RE, (_, spec) => transform(spec, context));
  }
};

/* Substitution syntax example (to show what's possible):
 *
 * %{context.subs[2].key|filter1|filter2|filter3}
 */
const transform = (spec, context) => {
  const contextTransformations = spec.split('|');
  const key = contextTransformations.shift();

  let queryPath = '$';

  for (const part of key.split('.')) {
    if (part.includes(':')) {
      queryPath += `["${part}"]`;
    } else {
      queryPath += `.${part}`;
    }
  }

  let value = query.value(queryPath, context);

  for (const transformation of contextTransformations) {
    let [_, transformationName, args] = (transformation.match(/^([^(]+)(?:\((.*)\))?$/) || []); // eslint-disable-line no-unused-vars
    args = args ? args.split(',').map(arg => arg.trim()) : [];

    value = transformations[transformationName](value, context, args);
  }

  return value;
};

export const cleanupTemplate = state => {
  const cfTemplate = state.cfTemplate();

  /* We need to add serverless-cf-vars plugin to make Fn::Subs work due to
   * conflicts between serverless and Fn::Sub variable syntax. */
  if (state.format === 'serverless') {
    if (hasFnSubs(state.template)) {
      if (Array.isArray(state.template.plugins)) {
        if (!state.template.plugins.includes('serverless-cf-vars')) {
          state.template.plugins.push('serverless-cf-vars');
        }
      } else {
        state.template.plugins = ['serverless-cf-vars'];
      }
    }
  }

  const parameterIds = Object.keys(cfTemplate.Parameters || {});
  const foundParameters = new Set();

  if (parameterIds.length > 0) {
    findReferencedParameters(cfTemplate.Conditions, parameterIds, foundParameters);
    findReferencedParameters(cfTemplate.Resources, parameterIds, foundParameters);
    findReferencedParameters(cfTemplate.Globals, parameterIds, foundParameters);

    if (state.format === 'serverless') {
      findReferencedParameters(state.template.functions, parameterIds, foundParameters);
    }
  }

  for (const parameterId of parameterIds) {
    if (!foundParameters.has(parameterId)) {
      delete cfTemplate.Parameters[parameterId];
      delete state.parameters[parameterId];

      if ('Metadata' in cfTemplate) {
        if ('StackeryEnvConfigParameters' in cfTemplate.Metadata) {
          delete cfTemplate.Metadata.StackeryEnvConfigParameters[parameterId];
        }
        if ('EnvConfigParameters' in cfTemplate.Metadata) {
          delete cfTemplate.Metadata.EnvConfigParameters[parameterId];
        }
      }
    } else if (state.format === 'serverless') {
      // Add default values for some parameters that serverless doesn't set values for.
      switch (parameterId) {
        case 'StackTagName':
          cfTemplate.Parameters.StackTagName.Default = state.template.service;
          break;
        case 'StackeryStackTagName':
          cfTemplate.Parameters.StackeryStackTagName.Default = state.template.service;
          break;
        case 'EnvironmentTagName':
          cfTemplate.Parameters.EnvironmentTagName.Default = 'dev';
          break;
        case 'StackeryEnvironmentTagName':
          cfTemplate.Parameters.StackeryEnvironmentTagName.Default = 'dev';
          break;
        default:
          break;
      }
    }
  }

  for (const resource of Object.values(cfTemplate.Resources || {})) {
    if ('Metadata' in resource && Object.keys(resource.Metadata).length === 0) {
      delete resource.Metadata;
    }
  }

  if (
    'Metadata' in cfTemplate &&
    'StackeryEnvConfigParameters' in cfTemplate.Metadata &&
    Object.keys(cfTemplate.Metadata.StackeryEnvConfigParameters).length === 0
  ) {
    delete cfTemplate.Metadata.StackeryEnvConfigParameters;
  }
  if (
    'Metadata' in cfTemplate &&
    'EnvConfigParameters' in cfTemplate.Metadata &&
    Object.keys(cfTemplate.Metadata.EnvConfigParameters).length === 0
  ) {
    delete cfTemplate.Metadata.EnvConfigParameters;
  }

  if (
    'Metadata' in cfTemplate &&
    'StackeryErrorsTargets' in cfTemplate.Metadata &&
    Object.keys(cfTemplate.Metadata.StackeryErrorsTargets).length === 0
  ) {
    delete cfTemplate.Metadata.StackeryErrorsTargets;
  }

  if ('Metadata' in cfTemplate && Object.keys(cfTemplate.Metadata).length === 0) {
    delete cfTemplate.Metadata;
  }

  if ('Parameters' in cfTemplate && Object.keys(cfTemplate.Parameters).length === 0) {
    delete cfTemplate.Parameters;
  }

  if ('Conditions' in cfTemplate && Object.keys(cfTemplate.Conditions).length === 0) {
    delete cfTemplate.Conditions;
  }

  if ('Resources' in cfTemplate && Object.keys(cfTemplate.Resources).length === 0) {
    delete cfTemplate.Resources;
  }

  if (state.format === 'serverless') {
    if (state.template.functions && Object.keys(state.template.functions).length === 0) {
      delete state.template.functions;
    }

    if (state.template.resources && Object.keys(state.template.resources).length === 0) {
      delete state.template.resources;
    }
  }

  if (state.format === 'SAM') {
    // For each function resource, note whether it has an AutoPublishAlias.  Update references
    // to ensure they refer to the right thing.
    const functionAliases = {};
    for (const [resourceId, resource] of Object.entries(cfTemplate.Resources || {})) {
      if (resource.Type === 'AWS::Serverless::Function') {
        const alias = resource.Properties && resource.Properties.AutoPublishAlias;
        functionAliases[resourceId] = alias || null;
      }
    }

    for (const [functionId, alias] of Object.entries(functionAliases)) {
      updateFunctionReferences(state.template, functionId, alias);
    }
  }
};

// Work through the template recursively, ensuring that references to the function named by
// 'resourceId' use its alias; if the value of 'alias' is null, the function is not published
// to an alias and references should be to the unaliased function.
//
const updateFunctionReferences = (object, resourceId, alias) => {
  if (!object || typeof object !== 'object') {
    return;
  }
  // This part handles API Gateway endpoint references as well as websocket integrations:
  if (Object.keys(object).length === 1 && 'Fn::Sub' in object) {
    const sub = object['Fn::Sub'];
    if (typeof sub !== 'string') {
      return;
    }
    if (alias) {
      const withAlias = '${' + resourceId + 'Alias' + alias + '}';
      const withoutAlias = new RegExp('\\${' + resourceId + '\\.Arn}', 'g');
      object['Fn::Sub'] = sub.replace(withoutAlias, withAlias);
    } else {
      // Sub for any function alias, whatever it may have been.
      const withAlias = new RegExp('\\${' + resourceId + 'Alias[^}]+}', 'g');
      const withoutAlias = '${' + resourceId + '.Arn}';
      object['Fn::Sub'] = sub.replace(withAlias, withoutAlias);
    }
    return;
  }

  for (const [key, value] of Object.entries(object)) {
    if (!value || typeof value !== 'object') {
      continue;
    }
    // Update things like "!GetAtt Function.Arn"
    if (alias && Object.keys(value).length === 1 && 'Fn::GetAtt' in value) {
      const attrs = value['Fn::GetAtt'];
      if (!attrs || !Array.isArray(attrs) || attrs.length < 2 || attrs[1] !== 'Arn') {
        continue;
      }

      const target = attrs[0];
      if (target === resourceId) {
        object[key] = { Ref: `${resourceId}Alias${alias}` };
      }
      continue;
    }
    if (!alias && 'Ref' in value && value.Ref === `${resourceId}Alias${alias}`) {
      object[key] = { 'Fn::GetAtt': [resourceId, 'Arn'] };
      continue;
    }
    updateFunctionReferences(object[key], resourceId, alias);
  }
};

const hasFnSubs = object => {
  if (object && typeof object === 'object') {
    if (Object.keys(object).length === 1 && 'Fn::Sub' in object) {
      return true;
    } else {
      for (const key in object) {
        if (hasFnSubs(object[key])) {
          return true;
        }
      }
    }
  }

  return false;
};

export const DEFAULT_PARAMETERS = {
  StackTagName: {
    Type: 'String',
    Description: 'Stack Name (injected by Stackery at deployment time)'
  },
  EnvironmentTagName: {
    Type: 'String',
    Description: 'Environment Name (injected by Stackery at deployment time)'
  },
  EnvironmentAPIGatewayStageName: {
    Type: 'String',
    Description: 'Environment name used for API Gateway Stage names (injected by Stackery at deployment time)'
  },
  DeploymentNamespace: {
    Type: 'String',
    Description: 'Deployment Namespace (injected by Stackery at deployment time)'
  },
  DeploymentTimestamp: {
    Type: 'Number',
    Description: 'Deployment preparation timestamp in milliseconds Since Epoch (injected by Stackery at deployment time)'
  },
  DefaultVPCId: {
    Type: 'AWS::EC2::VPC::Id',
    Description: 'AWS account-specific default VPC ID (injected by Stackery at deployment time)'
  },
  DefaultVPCSubnets: {
    Type: 'List<AWS::EC2::Subnet::Id>',
    Description: 'AWS account-specific default VPC subnets (injected by Stackery at deployment time)'
  },
  AmazonLinux2ImageId: {
    Type: 'AWS::EC2::Image::Id',
    Description: 'Latest Amazon Linux 2 AMI ID (injected by Stackery at deployment time)'
  },
  SourceLocation: {
    Type: 'String',
    Description: 'Location of source code for deployment (injected by Stackery at deployment time)'
  },
  SourceVersion: {
    Type: 'String',
    Description: 'Source version for deployment (injected by Stackery at deployment time)'
  }
};

// Legacy parameters
DEFAULT_PARAMETERS.StackeryStackTagName = DEFAULT_PARAMETERS.StackTagName;
DEFAULT_PARAMETERS.StackeryEnvironmentTagName = DEFAULT_PARAMETERS.EnvironmentTagName;
DEFAULT_PARAMETERS.StackeryEnvironmentAPIGatewayStageName = DEFAULT_PARAMETERS.EnvironmentAPIGatewayStageName;
DEFAULT_PARAMETERS.StackeryDeploymentNamespace = DEFAULT_PARAMETERS.DeploymentNamespace;
DEFAULT_PARAMETERS.StackeryDeploymentTimestamp = DEFAULT_PARAMETERS.DeploymentTimestamp;

export const updateDefaultParameters = template => {
  const referencedDefaultParameters = new Set();
  const defaultParameterIds = Object.keys(DEFAULT_PARAMETERS);

  /* If this is a serverless template, look under the functions key, then
   * manipulate template.resources as that's where custom CF logic lives. */
  if ('service' in template) {
    findReferencedParameters(template.functions, defaultParameterIds, referencedDefaultParameters);

    template.resources = template.resources || {};
    template = template.resources;
  }

  findReferencedParameters(template.Conditions, defaultParameterIds, referencedDefaultParameters);
  findReferencedParameters(template.Resources, defaultParameterIds, referencedDefaultParameters);

  for (const parameter in DEFAULT_PARAMETERS) {
    if (referencedDefaultParameters.has(parameter)) {
      template.Parameters = template.Parameters || {};
      template.Parameters[parameter] = DEFAULT_PARAMETERS[parameter];
    } else if ('Parameters' in template) {
      delete template.Parameters[parameter];
    }
  }

  if ('Parameters' in template && Object.keys(template.Parameters).length === 0) {
    delete template.Parameters;
  }
};

// Filters out AWS pseudo-parameters and attributes from Fn::Sub statements
const SUB_VARS_RE = /[$#]\{(?!AWS::)[^.}]+\}/g;

const findReferencedParameters = (object, parameterList, referencedParameters) => {
  if (object && typeof object === 'object') {
    if (Object.keys(object).length === 1 && 'Ref' in object) {
      const ref = object.Ref;

      if (parameterList.includes(ref)) {
        referencedParameters.add(ref);
      }
    } else if (Object.keys(object).length === 1 && 'Fn::Sub' in object) {
      let sub = object['Fn::Sub'];
      let providedVars = [];

      if (Array.isArray(sub)) {
        for (const varName in sub[1]) {
          findReferencedParameters(sub[1][varName], parameterList, referencedParameters);
        }

        providedVars = Object.keys(sub[1]);
        sub = sub[0];
      }

      /* Find all substitution variables, filter out those provided in Fn::Sub
       * already, and add the rest to the set */
      (sub.match(SUB_VARS_RE) || [])
        .map(subVar => subVar.replace(/^[$#]\{/, '').replace(/\}$/, ''))
        .filter(subVar => !providedVars.includes(subVar) && parameterList.includes(subVar))
        .forEach(subVar => referencedParameters.add(subVar));
    } else {
      for (const key in object) {
        findReferencedParameters(object[key], parameterList, referencedParameters);
      }
    }
  }
};

export const intrinsicFunctionType = value => {
  if (!value || typeof value !== 'object' || Object.keys(value).length !== 1) {
    return null;
  }

  const [key] = Object.keys(value);

  if (key === 'Ref' || key.startsWith('Fn::')) {
    return key;
  }

  return null;
};

export const findOwnerResourceId = (resourceId, resources) => {
  for (const otherResourceId in resources) {
    const otherResource = resources[otherResourceId];

    if (otherResource.TemplatePartial.Resources.includes(resourceId)) {
      return otherResourceId;
    }
  }
};

export const updateParameterValues = (value, state, isAdding) => {
  if (value instanceof Parameter) {
    if (isAdding) {
      value.insertIntoTemplate(state);
      return value.reference();
    }
  } else if (value !== null && typeof value === 'object') {
    for (const key in value) {
      value[key] = updateParameterValues(value[key], state, isAdding);
    }
  }

  return value;
};

export const updateOwnership = (state, resource, dispatchResults) => {
  const conditions = state.cfTemplate().Conditions || {};
  const resources = state.cfTemplate().Resources || {};

  for (const type of ['Conditions', 'Resources']) {
    const section = state.cfTemplate()[type] || {};
    const newIds = Object.keys(section).filter(id => {
      for (const resourceId in state.resources) {
        const resource = state.resources[resourceId];

        if (resource.TemplatePartial[type].includes(id)) {
          return false;
        }

        if (resource.Facets) {
          for (const facetType in resource.Facets) {
            for (const facet of resource.Facets[facetType]) {
              if (facet.TemplatePartial[type].includes(id)) {
                return false;
              }
            }
          }
        }
      }

      for (const integration of state.integrations) {
        if (integration.TemplatePartial[type].includes(id)) {
          return false;
        }
      }

      return true;
    });

    Array.prototype.push.apply(resource.TemplatePartial[type], newIds);

    /* Remove all deleted resources and conditions from existing resource
     * template partials */
    for (const resourceId in state.resources) {
      state.resources[resourceId].TemplatePartial[type] = state.resources[resourceId].TemplatePartial[type]
        .filter(id => (
          id in conditions ||
          id in resources ||
          (state.format === 'serverless' && state.resources[resourceId].Type === 'function' && id === resourceId)
        ));
    }

    /* Remove all deleted resources and conditions from existing integration
     * template partials */
    for (const integration of state.integrations) {
      integration.TemplatePartial[type] = integration.TemplatePartial[type]
        .filter(id => (id in conditions || id in resources));
    }
  }

  // Mark ownership of shared resources we may not have re-generated
  for (const dispatchResult of (dispatchResults || [])) {
    if (dispatchResult.type === 'Upsert' && dispatchResult.path) {
      if (dispatchResult.path.length === 3) {
        if (dispatchResult.path[1] === 'Resources' && !resource.TemplatePartial.Resources.includes(dispatchResult.path[2])) {
          resource.TemplatePartial.Resources.push(dispatchResult.path[2]);
        } else if (dispatchResult.path[1] === 'Conditions' && !resource.TemplatePartial.Conditions.includes(dispatchResult.path[2])) {
          resource.TemplatePartial.Conditions.push(dispatchResult.path[2]);
        }
      }
    }
  }
};

export const isResourceOwned = (state, logicalId) => {
  for (const resourceId in state.resources) {
    const resource = state.resources[resourceId];

    if (resource.TemplatePartial.Resources.includes(logicalId)) {
      return true;
    }

    if (resource.Facets) {
      for (const facetType in resource.Facets) {
        for (const facet of resource.Facets[facetType]) {
          if (facet.TemplatePartial.Resources.includes(logicalId)) {
            return true;
          }
        }
      }
    }
  }

  for (const integration of state.integrations) {
    if (integration.TemplatePartial.Resources.includes(logicalId)) {
      return true;
    }
  }

  return false;
};

export const updateExistingResourceConditions = (template, resource, useExistingResource) => {
  const type = intrinsicFunctionType(template);

  let logicalId;
  if (useExistingResource) {
    if (type === 'Ref') {
      logicalId = template.Ref;
    } else if (type === 'Fn::GetAtt') {
      logicalId = template['Fn::GetAtt'][0];
    } else if (type === 'Fn::Sub' && template['Fn::Sub'].includes(`\${${resource.Id}`)) {
      const existingResourceRegex = new RegExp(`(?!\\$\\{${resource.Id}[^}.]*Existing)\\$\\{(${resource.Id}[^}.]*)`, 'g');

      return {
        'Fn::If': [
          `${resource.Id}UseExistingResource`,
          { 'Fn::Sub': template['Fn::Sub'].replace(existingResourceRegex, '${$1ExistingResource') },
          { 'Fn::Sub': template['Fn::Sub'].replace(/ExistingResource\}/g, '}') }
        ]
      };
    } else if (type === 'Fn::If' && template[type][0] === `${resource.Id}UseExistingResource`) {
      // This reference is already converted to a conditional reference
      return template;
    }
  } else {
    if (type === 'Fn::If' && template[type][0] === `${resource.Id}UseExistingResource`) {
      logicalId = resource.Id;
    }
  }

  if (!logicalId) {
    if (template && typeof template === 'object') {
      for (const key in template) {
        template[key] = updateExistingResourceConditions(template[key], resource, useExistingResource);
      }
    }

    return template;
  }

  if (!resource.TemplatePartial.Resources.includes(logicalId)) {
    return template;
  }

  if (useExistingResource) {
    if (type === 'Ref') {
      const customRef = (logicalId === resource.Id)
        ? { Ref: `${resource.Id}ExistingResource` }
        : {
          'Fn::GetAtt': [
            `${resource.Id}ExistingResource`,
            `${logicalId.replace(resource.Id, '')}`
          ]
        };

      return {
        'Fn::If': [
          `${resource.Id}UseExistingResource`,
          customRef,
          template
        ]
      };
    } else {
      const attributePrefix = logicalId === resource.Id ? '' : `${logicalId.replace(resource.Id, '')}.`;

      return {
        'Fn::If': [
          `${resource.Id}UseExistingResource`,
          {
            'Fn::GetAtt': [
              `${resource.Id}ExistingResource`,
              `${attributePrefix}${template['Fn::GetAtt'][1]}`
            ]
          },
          template
        ]
      };
    }
  } else {
    return template['Fn::If'][2];
  }
};

const defaultVPCSubnetsSpec = {
  'Fn::Join': [
    ',',
    { Ref: 'DefaultVPCSubnets' }
  ]
};

/* Functions referencing Docker Tasks placed in Virtual Networks have an
 * environment variable with a list of VPC subnets as the subnet IDs are needed
 * when starting an ECS task in a VPC. This function looks for all subnet ID
 * references in the template and replaces them with conditionals when using
 * existing VPCs. */
export const updateExistingSubnetIdReferences = (template, virtualNetworkId, existingVPCSubnetsSpec = null) => {
  if (!template || typeof template !== 'object') {
    return;
  }

  if (!existingVPCSubnetsSpec) {
    existingVPCSubnetsSpec = {
      'Fn::Join': [
        ',',
        [
          {
            'Fn::If': [
              `${virtualNetworkId}UseExistingResource`,
              {
                'Fn::GetAtt': [
                  `${virtualNetworkId}ExistingResource`,
                  'PrivateSubnet1'
                ]
              },
              { Ref: `${virtualNetworkId}PrivateSubnet1` }
            ]
          },
          {
            'Fn::If': [
              `${virtualNetworkId}UseExistingResource`,
              {
                'Fn::GetAtt': [
                  `${virtualNetworkId}ExistingResource`,
                  'PrivateSubnet2'
                ]
              },
              { Ref: `${virtualNetworkId}PrivateSubnet2` }
            ]
          }
        ]
      ]
    };
  }

  for (const [key, value] of Object.entries(template)) {
    if (typeof value !== 'object') {
      continue;
    }

    if (deepEqual(value, defaultVPCSubnetsSpec)) {
      template[key] = existingVPCSubnetsSpec;
    } else {
      updateExistingSubnetIdReferences(template[key], virtualNetworkId, existingVPCSubnetsSpec);
    }
  }
};
