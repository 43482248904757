import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import account from './account';
import auth from './auth';
import oauth from './oauth';
import stacks from './stacks';
import stack from './stack';
import canvas from './canvas';
import nodeEditor from './nodeEditor';
import configKeys from './configKeys';
import deployments from './deployments';
import editorNodes from './editorNodes';
import deploymentNodes from './deploymentNodes';
import deploymentPipelines from './deploymentPipelines';
import nodeDeploymentInfo from './nodeDeploymentInfo';
import resourceEditor from './resourceEditor';
import changes from './changes';
import stackBranches from './stackBranches';
import stackEnvironments from './stackEnvironments';
import environments from './environments';
import environment from './environment';
import environmentGitDiff from './environmentGitDiff';
import forgotPassword from './forgotPassword';
import resetPassword from './resetPassword';
import changePassword from './changePassword';
import userNotifications from './userNotifications';
import users from './users';
import usersInvited from './usersInvited';
import userRole from './userRole';
import inviteUser from './inviteUser';
import removeUser from './removeUser';
import currentUser from './currentUser';
import newEnvironment from './newEnvironment';
import stackCreator from './stackCreator';
import stackSettings from './stackSettings';
import gitProviders from './gitProviders';
import inviteSignUp from './inviteSignUp';
import signUp from './signUp';
import modal from './modal';
import aws from './aws';
import cloudwatch from './cloudwatch';
import cms from './cms';
import tour from './tour';
import integrations from './integrations';
import formation from './formation';
import templateEditor from './templateEditor';
import workspace from './workspace';
import tokens from './tokens';
import buildEnvironment from './buildEnvironment';
import route from './route';

export default (history) => combineReducers({
  account,
  auth,
  aws,
  buildEnvironment,
  canvas,
  changePassword,
  changes,
  cloudwatch,
  cms,
  configKeys,
  currentUser,
  deploymentNodes,
  deploymentPipelines,
  deployments,
  editorNodes,
  environment,
  environments,
  environmentGitDiff,
  forgotPassword,
  formation,
  gitProviders,
  integrations,
  inviteSignUp,
  inviteUser,
  modal,
  newEnvironment,
  nodeDeploymentInfo,
  nodeEditor,
  oauth,
  removeUser,
  resetPassword,
  resourceEditor,
  router: connectRouter(history),
  route,
  signUp,
  stack,
  stackBranches,
  stackCreator,
  stackEnvironments,
  stacks,
  stackSettings,
  templateEditor,
  tokens,
  tour,
  users,
  usersInvited,
  userNotifications,
  userRole,
  workspace
});
