import React from 'react';
import ButtonSet from '../core/ButtonSet';

const ActiveStacksLimit = (props) => {
  return (
    <div>
      <h1>Upgrade your plan to deploy</h1>
      <p>You have reached your active stack limit on the Developer Plan. Want to deploy more than 6 active stacks? You’ll want to level up to a payment plan that allows more of them.</p>

      <ButtonSet
        primaryButton={{
          text: 'Upgrade Me',
          to: '/settings/billing',
          onClick: props.onClickCancel
        }}
        secondaryButton={{
          text: `I don't want more active stacks`,
          onClick: props.onClickCancel
        }}
      />
    </div>
  );
};

export default ActiveStacksLimit;
