import React from 'react';
import { Route, Redirect, Switch } from 'react-router';
import PropTypes from 'prop-types';
import VerifyEmailContainer from './VerifyEmailContainer';
import VerifyEmailHelper from './VerifyEmailHelper';
import LinkAwsContainer from './LinkAwsContainer';
import LinkAwsHelper from './LinkAwsHelper';
import GetTools from './GetTools';
import GetToolsHelper from './GetToolsHelper';
import SetupNav from './SetupNav';
import style from './Setup.css';

const routes = [
  {
    main: VerifyEmailContainer,
    sidebar: VerifyEmailHelper,
    path: '/setup/verify-email'
  },
  {
    main: LinkAwsContainer,
    sidebar: LinkAwsHelper,
    path: '/setup/link-aws'
  },
  {
    main: GetTools,
    sidebar: GetToolsHelper,
    path: '/setup/get-tools'
  }
];

const Setup = props => {
  const redirectPath = 'verify-email';

  return (
    <div className={style.container}>
      <div className={style.main}>
        <header className={style.header}>
          <h1>Welcome to Stackery!</h1>
          <p>These steps will help you set up your account.</p>
          <SetupNav {...props} />
        </header>
        <Switch>
          {routes.map(route => <Route key={route.path} path={route.path} component={route.main} />)}
          <Redirect to={`/setup/${redirectPath}`} />
        </Switch>
      </div>
      <div className={style.sidebar}>
        <Switch>
          {routes.map(route => <Route key={route.path} path={route.path} component={route.sidebar} />)}
        </Switch>
      </div>
    </div>
  );
};

Setup.propTypes = {
  hasVerifiedEmail: PropTypes.bool
};

export default Setup;
