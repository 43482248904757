import { putResolve, all, takeLatest } from 'redux-saga/effects';
import { types as appTypes } from '../actions/app';
import apiActions from '../actions/api';

function * handleUserNotification (action) {
  if (action.metadata === undefined) { return; }

  const {
    owner,
    didPrepareFail,
    ...metadata
  } = action.metadata;

  yield putResolve(apiActions.saveUserNotification(owner, {...metadata, message: action.message, level: action.level}));
}

export default function * userNotifications () {
  yield all([
    takeLatest(appTypes.NOTIFY_USER, handleUserNotification)
  ]);
}
