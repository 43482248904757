import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';
import rootReducer from '../reducers';
import rootSaga from '../sagas';
import restoreAuthState from './restoreAuthState';

export const history = createBrowserHistory();

export let store = createStore(rootReducer(history));

const setupStore = async () => {
  const sagaMiddleware = createSagaMiddleware();
  const enhancer = compose(
    applyMiddleware(sagaMiddleware, routerMiddleware(history))
  );

  const persistedState = await restoreAuthState();

  store = createStore(rootReducer(history), persistedState, enhancer);

  sagaMiddleware.run(rootSaga);

  return store;
};

export default async () => setupStore();
