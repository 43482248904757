import React from 'react';
import moment from 'moment';
import roundToPrecision from '../../utils/roundToPrecision';
import style from './NodeMetric.css';

const awsDbEngines = {
  'mariadb': 'MariaDB',
  'postgresql': 'PostgreSQL',
  'mysql': 'MySQL'
};

const awsDbInstanceTypesMemory = {
  'db.m4.large': 8,
  'db.m4.xlarge': 16,
  'db.m4.2xlarge': 32,
  'db.m4.4xlarge': 64,
  'db.m4.10xlarge': 160,
  'db.m4.16xlarge': 256,
  'db.m3.medium': 3.75,
  'db.m3.large': 7.5,
  'db.m3.xlarge': 15,
  'db.m3.2xlarge': 30,
  'db.r5.large': 16,
  'db.r5.xlarge': 32,
  'db.r5.2xlarge': 64,
  'db.r5.4xlarge': 128,
  'db.r5.12xlarge': 384,
  'db.r5.24xlarge': 768,
  'db.r4.large': 15.25,
  'db.r4.xlarge': 30.5,
  'db.r4.2xlarge': 61,
  'db.r4.4xlarge': 122,
  'db.r4.8xlarge': 244,
  'db.r4.16xlarge': 488,
  'db.r3.large': 15.25,
  'db.r3.xlarge': 30.5,
  'db.r3.2xlarge': 61,
  'db.r3.4xlarge': 122,
  'db.r3.8xlarge': 244,
  'db.t2.micro': 1,
  'db.t2.small': 2,
  'db.t2.medium': 4,
  'db.t2.large': 8,
  'db.t2.xlarge': 16,
  'db.t2.2xlarge': 32
};

const getDbInstanceConnections = (engine, version, memory) => {
  switch (engine + version) {
    case 'postgres9.4':
      return memory / 31457280;
    case 'postgres9.6':
    case 'postgres9.7':
      return Math.min(memory / 9531392, 5000);
    default:
      return memory / 12582880;
  }
};

export const longestMetricLabel = (node) => {
  let longestLabel = '';
  node.metrics.dataVisible.forEach((metric) => {
    const fn = handlers[metric.type] || handlers.default;
    const label = fn(metric, node).output;
    if (label.length > longestLabel.length) {
      longestLabel = label;
    }
  });

  return longestLabel;
};

const handlers = {
  'Invocations': (metric, node) => {
    const value = metric.statistics.sum;
    const compareValue = node.metrics.data.filter(item => item.type === metric.factors.a).map(item => item.statistics.sum)[0];
    let status = 'inactive';

    if (value > 0) {
      if (value === compareValue) {
        status = 'error';
      } else if (value > compareValue && compareValue > 0) {
        status = 'warning';
      } else {
        status = 'ok';
      }
    }

    return {
      output: `${value} ${metric.label}`,
      status
    };
  },

  'Errors': (metric, node) => {
    const value = metric.statistics.sum;
    const compareValue = node.metrics.data.filter(item => item.type === metric.factors.a).map(item => item.statistics.sum)[0];
    let status = 'inactive';

    if (value > 0) {
      status = 'error';
    } else if (value === 0 && compareValue > 0) {
      status = 'ok';
    }

    return {
      output: `${value} ${metric.label}`,
      status
    };
  },

  'ErrorRate': (metric, node) => {
    const metrics = node.metrics.data;
    const factorA = metrics.filter(item => item.type === metric.factors.a).map(item => item.statistics.sum)[0];
    const factorB = metrics.filter(item => item.type === metric.factors.b).map(item => item.statistics.sum)[0];
    const errorRate = ((factorA / factorB) * 100) || 0;

    return {
      output: `${errorRate}% ${metric.label}`,
      status: 'inactive'
    };
  },

  'FreeStorageSpace': (metric, node) => {
    const storageSize = node.storageSize || node.StorageSize;
    const megabyte = 1000;
    const megabytesTotal = Math.round(storageSize * megabyte);
    const megabytesFree = Math.round(metric.statistics.average / 1000000);
    const percentMegabytesUsed = ((megabytesTotal - megabytesFree) / megabytesTotal) * 100;
    const storageFree = megabytesFree > 1000 ? `${Math.round(megabytesFree / megabyte)}GB` : `${megabytesFree}MB`;
    let status;

    if (percentMegabytesUsed < 60) {
      status = 'ok';
    } else if (percentMegabytesUsed < 80) {
      status = 'warning';
    } else {
      status = 'error';
    }

    return {
      output: `${storageFree} ${metric.label}`,
      status: status
    };
  },

  'FreeableMemory': (metric, node) => {
    const instanceType = node.instanceType || node.InstanceType;
    const gibibytes = metric.statistics.average / Math.pow(2, 30);
    const megabytes = Math.round(metric.statistics.average / Math.pow(2, 20));
    const memoryFree = megabytes > 1024 ? `${Math.round(megabytes / 1024)}GB` : `${megabytes}MB`;
    const percentMemoryAvailable = (gibibytes / awsDbInstanceTypesMemory[instanceType]) * 100;
    let status;

    if (percentMemoryAvailable > 40) {
      status = 'ok';
    } else if (percentMemoryAvailable > 20) {
      status = 'warning';
    } else {
      status = 'error';
    }

    return {
      output: `${memoryFree} ${metric.label}`,
      status: status
    };
  },

  'CPUUtilization': (metric) => {
    const size = Math.round(metric.statistics.average, 2);
    let status;

    if (size < 60) {
      status = 'ok';
    } else if (size < 80) {
      status = 'warning';
    } else {
      status = 'error';
    }

    return {
      output: `${size}% ${metric.label}`,
      status: status
    };
  },

  'DatabaseConnections': (metric, node) => {
    const engine = node.engine || node.Engine;
    const version = node.version || node[`EngineVersion${awsDbEngines[engine]}`];
    const instanceType = node.instanceType || node.InstanceType;

    const connections = metric.statistics.maximum;
    const label = connections === 1 ? 'Connection' : 'Connections';
    let status;

    const megabytesPerGibibyte = Math.pow(2, 30) / Math.pow(10, 6);
    const megabytesPerInstance = (megabytesPerGibibyte * awsDbInstanceTypesMemory[instanceType]) * 1024 * 1024;

    const maxConnections = Math.round(getDbInstanceConnections(engine, version, megabytesPerInstance));
    const useagePercentage = (connections / maxConnections) * 100;

    if (useagePercentage < 60) {
      status = 'ok';
    } else if (useagePercentage < 80) {
      status = 'warning';
    } else {
      status = 'error';
    }

    return {
      output: `${connections} ${label}`,
      status: status
    };
  },

  'IncomingRecords': (metric, node) => {
    const value = metric.statistics.sum;
    const compareValue = node.metrics.data.filter(item => item.type === metric.factors.a).map(item => item.statistics.sum)[0];
    let status = 'inactive';

    if (value > compareValue && compareValue === 0) {
      status = 'error';
    } else if (value > compareValue) {
      status = 'warning';
    } else if (value > 0) {
      status = 'ok';
    }

    return {
      output: `${value} ${metric.label}`,
      status
    };
  },

  'GetRecords.Records': (metric, node) => {
    const value = metric.statistics.sum;
    const compareValue = node.metrics.data.filter(item => item.type === metric.factors.a).map(item => item.statistics.sum)[0];
    let status = 'inactive';

    if (value < compareValue && value === 0) {
      status = 'error';
    } else if (value < compareValue) {
      status = 'warning';
    } else if (value > 0) {
      status = 'ok';
    }

    return {
      output: `${value} ${metric.label}`,
      status
    };
  },

  'AllRequests': (metric, node) => {
    const value = metric.statistics.sum;
    const errors4xx = node.metrics.data.filter(item => item.type === metric.factors.a).map(item => item.statistics.sum)[0];
    const errors5xx = node.metrics.data.filter(item => item.type === metric.factors.b).map(item => item.statistics.sum)[0];
    let status = value > 0 ? 'ok' : 'inactive';

    if (errors5xx > 0) {
      status = 'error';
    } else if (errors4xx > 0) {
      status = 'warning';
    }

    return {
      output: `${value} ${metric.label}`,
      status
    };
  },

  '4XXError': (metric, node) => {
    const value = metric.statistics.sum;
    const factorA = node.metrics.data.filter(item => item.type === metric.factors.a).map(item => item.statistics.sum)[0];
    let status = factorA > 0 ? 'ok' : 'inactive';

    if (value > 0) {
      status = 'warning';
    }

    return {
      output: `${value} ${metric.label}`,
      status
    };
  },

  '5XXError': (metric, node) => {
    const value = metric.statistics.sum;
    const factorA = node.metrics.data.filter(item => item.type === metric.factors.a).map(item => item.statistics.sum)[0];
    let status = factorA > 0 ? 'ok' : 'inactive';

    if (value > 0) {
      status = 'error';
    }

    return {
      output: `${value} ${metric.label}`,
      status
    };
  },

  'Count': (metric, node) => {
    return {
      output: `${metric.statistics.sum} ${metric.label}`,
      status: 'inactive'
    };
  },

  'AverageDuration': (metric, node) => {
    const duration = node.metrics.data.filter(item => item.type === metric.factors.a).map(item => item.statistics.sum)[0];
    const invocations = node.metrics.data.filter(item => item.type === metric.factors.b).map(item => item.statistics.sum)[0];
    const timeoutMs = (node.timeout || 300) * 1000;
    let durationUnit = 'ms';
    let status = 'inactive';
    let decimalPrecision = 0;

    let averageDuration = duration / invocations;
    const durationPercentage = (averageDuration / timeoutMs) * 100;

    if (averageDuration > 1000) {
      averageDuration = averageDuration / 1000;
      durationUnit = 's';
      decimalPrecision = 2;
    }

    const durationRounded = roundToPrecision(averageDuration, decimalPrecision) || 0;

    if (durationPercentage < 60 && duration > 0) {
      status = 'ok';
    } else if (durationPercentage < 80) {
      status = 'warning';
    } else if (durationPercentage > 80) {
      status = 'error';
    }

    return {
      output: `${durationRounded}${durationUnit} ${metric.label}`,
      status: status
    };
  },

  'ApproximateAgeOfOldestMessage': (metric, node) => {
    const age = metric.statistics.maximum;
    const ageString = moment().add(age, 'seconds').fromNow(true);
    const retentionPeriod = node.MessageRetentionPeriod || 345600;

    let status;
    if (age < retentionPeriod * 0.05) {
      status = 'ok';
    } else if (age < retentionPeriod * 0.5) {
      status = 'warning';
    } else {
      status = 'error';
    }

    return {
      output: `Oldest Message Age: ${ageString}`,
      status
    };
  },

  'ApproximateNumberOfMessagesVisible': metric => {
    const num = metric.statistics.maximum;

    return {
      output: `${num} Visible Messages`,
      status: 'ok'
    };
  },

  'NumberOfMessagesSent': metric => {
    const num = metric.statistics.sum;

    return {
      output: `${num} Enqueued Messages`,
      status: num > 0 ? 'ok' : 'inactive'
    };
  },

  'NumberOfMessagesReceived': metric => {
    const num = metric.statistics.sum;

    return {
      output: `${num} Dequeued Messages`,
      status: num > 0 ? 'ok' : 'inactive'
    };
  },

  'ExecutionsStarted': (metric, node) => {
    const value = metric.statistics.sum;
    const compareValue = node.metrics.data.filter(item => item.type === metric.factors.a).map(item => item.statistics.sum)[0];
    let status = 'inactive';

    if (value > 0) {
      if (value === compareValue) {
        status = 'error';
      } else if (value > compareValue && compareValue > 0) {
        status = 'warning';
      } else {
        status = 'ok';
      }
    }

    return {
      output: `${value} ${metric.label}`,
      status
    };
  },

  'ExecutionsFailed': (metric, node) => {
    const value = metric.statistics.sum;
    const compareValue = node.metrics.data.filter(item => item.type === metric.factors.a).map(item => item.statistics.sum)[0];
    let status = 'inactive';

    if (value > 0) {
      status = 'error';
    } else if (value === 0 && compareValue > 0) {
      status = 'ok';
    }

    return {
      output: `${value} ${metric.label}`,
      status
    };
  }
};

const NodeMetric = (props) => {
  const result = handlers[props.metric.type](props.metric, props.node);

  return (
    <g>
      <text x='20' y={props.i * 14}>{result.output}</text>
      <circle cx='10' cy={-3.5 + (14 * props.i)} r='4' className={style[`${result.status}Dot`]} />
    </g>
  );
};

export default NodeMetric;
