import React from 'react';
import Button from '../core/Button';

const RemoveUser = (props) => {
  const {
    currentUser,
    email,
    isDisabled
  } = props;

  return (
    currentUser.email === email
    ? null
    : <Button isDisabled={isDisabled} modifier='warning' text='Delete...' onClick={() => props.onRemoveUser(email)} />
  );
};

export default RemoveUser;
