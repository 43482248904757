import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { push } from 'connected-react-router';
import * as states from '../../constants/states';
import apiActions from '../../actions/api';
import appActions from '../../actions/app';
import Environment from './Environment';

const mapStateToProps = () => {
  return createSelector(
    (appState) => appState.environment,
    (appState) => appState.currentUser,
    (appState) => appState.account,
    (environment, currentUser, account) => {
      return {
        environment,
        isLoaded: environment.state === states.OKAY,
        isDemoMode: currentUser.isDemoMode
      };
    }
  );
};

const mapDispatchToProps = {
  push,
  getEnvironment: apiActions.getEnvironment,
  deleteEnvironment: apiActions.deleteEnvironment,
  selectEnvironment: appActions.selectEnvironment,
  deselectEnvironment: appActions.deselectEnvironment,
  showDeleteEnvironmentModal: appActions.showDeleteEnvironmentModal,
  notifyUser: appActions.notifyUser
};

class EnvironmentContainer extends Component {
  constructor (props) {
    super(props);

    this.handleDeleteClick = this.handleDeleteClick.bind(this);
  }

  componentDidMount () {
    const {
      owner,
      environment
    } = this.props.match.params;

    this.props.getEnvironment(owner, environment);
    this.props.selectEnvironment(owner, environment);
  }

  componentWillUnmount () {
    this.props.deselectEnvironment();
  }

  componentDidUpdate (prevProps) {
    const {
      environment
    } = this.props;

    if (!environment.owner || !environment.environment) {
      this.props.push(`/environments`);
    }

    if (prevProps.environment.error === undefined && environment.error !== undefined && (environment.error.statusCode === 404 || environment.error.statusCode === 500)) {
      this.props.notifyUser(environment.error.message, 'error');
      this.props.replace(`/environments`);
    }
  }

  handleDeleteClick (environment) {
    this.props.showDeleteEnvironmentModal({ environment, isPipelineEnvironment: this.props.environment.isPipelineEnvironment, onSubmit: this.props.deleteEnvironment });
  }

  render () {
    return (
      <Environment
        {...this.props}
        onDeleteClick={this.handleDeleteClick}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EnvironmentContainer);
