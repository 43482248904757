import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import highlightSubstring from '../../utils/highlightSubstring';
import Tooltip from '../core/Tooltip';
import Skeleton from '../core/Skeleton';
import style from './EnvironmentDetails.css';

const EnvironmentDetails = props => {
  const {
    accountAlias,
    accountId,
    region,
    searchTerm,
    isDeployerRoleEnabled
  } = props;

  const isLoading = accountId === undefined || region === undefined;

  return (
    <dl className={style.container}>
      <dt className={style.label}>Account</dt>
      <dd className={style.description}>
        {isLoading && <Skeleton width={90} />}
        {!isLoading && accountAlias &&
          <Tooltip className={style.tooltip} isInline message={accountId} place='top'>
            {searchTerm && highlightSubstring(searchTerm, accountAlias)}
            {!searchTerm && accountAlias}
          </Tooltip>
        }
        {!isLoading && !accountAlias &&
          <Fragment>
            {searchTerm && highlightSubstring(searchTerm, accountId)}
            {!searchTerm && accountId}
          </Fragment>
        }
      </dd>

      <dt className={style.label}>Region</dt>
      <dd className={style.description}>
        {isLoading && <Skeleton width={90} />}
        {!isLoading && region}
      </dd>

      {isDeployerRoleEnabled !== undefined &&
        <Fragment>
          <dt className={style.label}>Pipeline Deployments</dt>
          <dd className={style.description}>{`${isDeployerRoleEnabled ? ' enabled' : ' disabled'}`}</dd>
        </Fragment>
      }
    </dl>
  );
};

EnvironmentDetails.propTypes = {
  accountId: PropTypes.string.isRequired,
  accountAlias: PropTypes.string,
  region: PropTypes.string.isRequired,
  searchTerm: PropTypes.string,
  isDeployerRoleEnabled: PropTypes.bool
};

export default EnvironmentDetails;
