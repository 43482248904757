export default {
  spotlight: {
    borderRadius: 0,
    backgroundColor: 'none'
  },
  options: {
    textColor: 'var(--popoverText)',
    arrowColor: 'var(--popoverBackground)'
  },
  buttonNext: {
    backgroundColor: 'var(--buttonPrimaryBackground)',
    color: 'var(--inverse)',
    borderRadius: '2px',
    padding: '8px',
    minWidth: '100px'
  },
  buttonBack: {
    backgroundColor: 'var(--buttonPrimaryBackground)',
    color: 'var(--inverse)',
    borderRadius: '2px',
    padding: '8px',
    minWidth: '100px'
  },
  tooltip: {
    backgroundColor: 'var(--popoverBackground)',
    borderRadius: '2px'
  }
};
