export const types = {
  START_TOUR: 'APP::START_TOUR',
  END_TOUR: 'APP:END_TOUR',
  ADVANCE_TOUR: 'APP:ADVANCE_TOUR'
};

const actions = {
  startTour: component => ({ type: types.START_TOUR, component }),
  endTour: (component, isComplete) => ({ type: types.END_TOUR, component, isComplete }),
  advanceTour: (currentTour, step) => ({ type: types.ADVANCE_TOUR, currentTour, step })
};

export default actions;
