import AbstractResource from './abstractResource';
import * as query from './query';
import resolveSetting from './resolveSetting';
import * as definitions from './definitions';

export default class Facet extends AbstractResource {
  constructor (format, resource, type, template, properties = {}) {
    super();

    this.Id = `${resource.Id}Facet${type}${Object.keys(properties).sort().map(prop => `${prop}${properties[prop]}`).join('')}`;
    this.Properties = properties;

    this.Settings = {};

    const context = {
      resourceId: resource.Id
    };

    for (const key in properties) {
      context[`FACET:${key}`] = properties[key];
    }

    const resourceDefinition = definitions[format].ResourceTypes[resource.Type];
    const facetSettingsDefinitions = 'FacetSettings' in resourceDefinition ? definitions[format].ResourceTypes[resource.Type].FacetSettings : {};
    const facetSettings = facetSettingsDefinitions[type] || {};

    for (const settingName in facetSettings) {
      const settingSchema = facetSettings[settingName];

      const value = resolveSetting(`facet ${this.Id}`, settingName, settingSchema, template, null, context);

      this.Settings[settingName] = value;
    }

    /* Check for dependent settings where dependency is unmet. Set values to
     * Default in this case. */
    for (const settingName in facetSettings) {
      const settingSchema = facetSettings[settingName];

      if ('DependsOn' in settingSchema) {
        const dependencyNames = Object.keys(settingSchema.DependsOn);

        for (const dependencyName of dependencyNames) {
          const dependencySchema = facetSettings[dependencyName];
          let value = this.Settings[dependencyName];

          if (
            (value === null || value === undefined) &&
            'GlobalPath' in dependencySchema
          ) {
            value = query.value(dependencySchema.GlobalPath, template);
          }

          if (value !== settingSchema.DependsOn[dependencyName]) {
            this.Settings[settingName] = settingSchema.Default;
            break;
          }
        }
      }
    }
  }
}
