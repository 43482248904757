import React, { Component } from 'react';
import cfYamlParser from 'cf-yaml-parser';
import makeGitLink from '../../utils/makeGitLink';
import { isDarkMode } from '../../utils/prefersColorScheme';
import Panel from '../core/Panel';
import ResourceHeader from '../core/ResourceHeader';
import LinkButton from '../core/LinkButton';
import CloudwatchLogsLink from './CloudwatchLogsLink';
import CloudwatchChartLink from './CloudwatchChartLink';
import Editor from '../core/Editor';
import XRayTraceLink from './XRayTraceLink';
import style from './NodeDeploymentInfo.css';

class NodeDeploymentInfo extends Component {
  constructor (props) {
    super(props);

    this.setupEditor = this.setupEditor.bind(this);
    this.updateEditor = this.updateEditor.bind(this);
  }

  setupEditor (editorKey, mode, value) {
    this[editorKey] = window.ace.edit(this[`${editorKey}Container`]);

    const session = this[editorKey].getSession();

    session.setMode(`ace/mode/${mode}`);
    session.setFoldStyle('markbeginend');

    this[editorKey].setOptions({
      tabSize: 2,
      displayIndentGuides: false,
      enableBasicAutocompletion: true,
      enableSnippets: true,
      highlightActiveLine: false,
      showGutter: false,
      showPrintMargin: false,
      minLines: 2,
      maxLines: Infinity,
      theme: isDarkMode() ? 'ace/theme/tomorrow-night' : 'ace/theme/chrome'
    });

    this[editorKey].$blockScrolling = Infinity;
    this[editorKey].renderer.setScrollMargin(5, 5);
    this[editorKey].setReadOnly(true);
    this[editorKey].renderer.$cursorLayer.element.style.display = 'none'; // hack to hide cursor for readOnly

    if (value) {
      this.updateEditor(editorKey, value);
    }
  }

  updateEditor (editorKey, value) {
    this[editorKey].session.setValue(value, -1);
  }

  componentDidMount () {
    const {
      node,
      data
    } = this.props;

    if (node.Environment) {
      this.setupEditor('envEditor', 'yaml', cfYamlParser.toString(node.Environment));
    }

    if (node.Layers) {
      this.setupEditor('layersEditor', 'yaml', cfYamlParser.toString(node.Layers));
    }
    if (node.Permissions) {
      this.setupEditor('permEditor', 'yaml', cfYamlParser.toString(node.Permissions));
    }

    if (node.type === 'custom') {
      this.setupEditor('yamlEditor', 'yaml', cfYamlParser.toString(node.CloudFormation));
    }

    if (data.code && data.code.value) {
      this.setupEditor('jsonEditor', 'json', data.code.value);
    }
  }

  render () {
    const {
      stack,
      deployment,
      node,
      name,
      codeDir,
      data,
      gitProviders,
      paletteResource,
      paletteDocsLink,
      paletteInfo,
      onCancel
    } = this.props;

    return (
      <Panel onShadeClick={onCancel}>
        <ResourceHeader
          title={name}
          resourceType={paletteResource}
          resourceDocsLink={paletteDocsLink}
          description={paletteInfo}
        />
        {!node.reference &&
          <div className={style.nav}>
            {!this.props.isImported &&
              <div className={style.navButton}>
                <LinkButton
                  to={`/stacks/${stack.owner}/${stack.name}/edit/master/visual/${node.id}`}
                  icon='edit'
                  text='Edit Resource'
                />
              </div>
            }
            {node.type === 'function' &&
              <div className={style.navButton}>
                <LinkButton
                  href={makeGitLink(stack, gitProviders, deployment.version, codeDir).url}
                  icon='custom'
                  text='View Source Code'
                  target='_blank'
                />
              </div>
            }
            {node.type !== 'errors' && node.type !== 'bastion' && node.type !== 'custom' &&
              <div className={style.navButton}>
                <LinkButton
                  href={data.arnLink}
                  icon='link'
                  text='View in AWS Console'
                  target='_blank'
                />
              </div>
            }
          </div>
        }
        {data && node.type !== 'errors' && node.type !== 'secrets' && node.type !== 'custom' && !!data.settings.length &&
          <table className={style.section}>
            <caption className={style.sectionTitle}>Properties</caption>
            <tbody>
              {!!data.arn &&
                <tr>
                  <td className={style.itemLabel}>ARN</td>
                  <td className={style.itemCell}>
                    {data.arnLink ? <a href={data.arnLink} target='_blank'>{data.arn}</a> : data.arn}
                  </td>
                </tr>
              }
              {!node.reference && data.settings.map((setting, i) => {
                const settingType = setting.type || 'text';
                return (
                  <tr key={`${settingType}-${i}`}>
                    <td className={style.itemLabel}>{setting.label}</td>
                    <td className={style.itemCell}>
                      {settingType === 'link' &&
                        <a href={setting.href} target='_blank'>{setting.title || setting.href}</a>
                      }
                      {settingType === 'text' &&
                        setting.value
                      }
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        }

        {node.type === 'api' && node.Routes && node.Routes.length > 0 &&
          <table className={style.section}>
            <caption className={style.sectionTitle}>Routes</caption>
            <tbody>
              {node.Routes.map((route, i) => {
                return (
                  <tr key={`route-${i}`}>
                    <td className={style.itemCell}>
                      <span className={style.smallText}>{route.Method}</span>
                    </td>
                    <td className={style.itemCell}>
                      <a href={`${data.settings.find(setting => setting.label === 'Stage Location').href}${route.Path}`} target='_blank'>{route.Path}</a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        }

        {node.Layers &&
          <section className={style.editorContainer}>
            <h3 className={style.sectionTitle}>Layers</h3>
            <Editor
              isReadOnly
              hasMinHeight={false}
              hasBorder={false}
              onRef={ref => (this.layersEditorContainer = ref)}
            />
          </section>
        }

        {node.Permissions &&
          <section className={style.editorContainer}>
            <h3 className={style.sectionTitle}>Permissions</h3>
            <Editor
              isReadOnly
              hasBorder={false}
              onRef={ref => (this.permEditorContainer = ref)}
            />
          </section>
        }

        {node.Environment &&
          <section className={style.editorContainer}>
            <h3 className={style.sectionTitle}>Environment Variables</h3>
            <Editor
              isReadOnly
              hasBorder={false}
              onRef={ref => (this.envEditorContainer = ref)}
            />
          </section>
        }

        {node.type === 'custom' &&
          <section className={style.editorContainer}>
            <h3 className={style.sectionTitle}>CloudFormation</h3>
            <Editor
              isReadOnly
              hasMinHeight={false}
              hasBorder={false}
              onRef={ref => (this.yamlEditorContainer = ref)}
            />
          </section>
        }

        {data && !!data.consoleLinks && !!data.consoleLinks.length &&
          <section className={style.section}>
            <h3 className={style.sectionTitle}>Metrics &amp; Logs</h3>
            <ul className={style.list}>
              {data.consoleLinks.map((link, i) => {
                return (
                  <li key={`${link.type}-${i}`} className={style.itemRow}>
                    {link.type === 'cloudwatchChartLink' &&
                      <CloudwatchChartLink {...link} />
                    }

                    {link.type === 'cloudwatchLogsLink' &&
                      <CloudwatchLogsLink {...link} />
                    }

                    {link.type === 'xrayTraceLink' &&
                      <XRayTraceLink {...link} />
                    }

                    {link.type === 'link' &&
                      <a href={link.href} target='_blank'>{link.label}</a>
                    }
                  </li>
                );
              })}
            </ul>
          </section>
        }

        {data && !!data.code &&
          <section className={style.editorContainer}>
            <h3 className={style.sectionTitle}>{data.code.label}</h3>
            <Editor onRef={ref => (this.jsonEditorContainer = ref)} />
          </section>
        }
      </Panel>
    );
  }
}

export default NodeDeploymentInfo;
