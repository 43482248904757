import { types } from '../actions/canvas';
import { types as appTypes } from '../actions/app';
import * as states from '../constants/states';

export const initialState = {
  // Canvas scale
  scale: 1,

  /* List of selected node ids */
  selectedNodes: [],

  /* List of added node ids */
  newNodes: [],

  /* Object with the following properties:
   *
   * source: Source node ID
   * port: Source port number
   * target: Target node ID
   */
  selectedWire: undefined,

  /* Wire that is under the cursor when mouse press begins */
  mousingWire: undefined,

  /* Node that is under the cursor when mouse press begins */
  mousingNode: undefined,

  /* Object that exists when border of a resizable node is under the cursor when
   * mouse press begins. It has the following properties:
   *
   * id: ID of node
   * side: 'top', 'bottom', 'left', or 'right'
   */
  resizing: undefined,

  /* Object that exists when we are connecting two nodes with a wire. It has the
   * following properties:
   *
   * node: ID of node that wire begins at
   * port: Number of port if wire begins from an output port, undefined if wire
   *       begins from an input port
   */
  wiring: undefined,

  /* Object that exists when hovering (before or during wiring) over a port that
   * can be connected. It has the following properties:
   *
   * node: ID of the port's node
   * port: Number of port if an output port, undefined if an input port
   */
  highlightedPort: undefined,

  /* Object containing x, y coordinates from when the mouse was pressed while
   * over a node. When the node is moved, these coordinates are updated by the
   * amount of the node movement. The purpose of this value is for snap-to-grid
   * functionality. */
  mouseOrigin: undefined,

  /* Boolean value for whether the mousing node has moved or resizing node has
   * resized, even if it has since been moved back to the original
   * location/size. On mouse up, if the node has moved/resized we don't
   * select/edit it. */
  mouseMoved: undefined,

  /* Whether the meta or ctrl keys were pressed when the mouse was pressed while
   * over a node. The purpose is to allow for selecting/deselecting nodes in a
   * group. */
  metaKey: undefined,

  /* Whether node add palette is open. */
  paletteOpen: false,

  /* Whether we are dragging a node. The purpose is to ensure we select and edit
   * the node after it is placed. */
  draggingNewNode: false
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case appTypes.SELECT_STACK:
    case appTypes.SELECT_STACK_ENVIRONMENT:
      return {
        ...initialState,
        scale: state.scale,
        state: states.NEW
      };

    case appTypes.DESELECT_STACK:
      return {
        ...initialState
      };

    case types.SET_SCALE:
      return {
        ...state,
        scale: action.scale
      };

    case types.PORT_HIGHLIGHT:
      return {
        ...state,
        highlightedPort: { node: action.node, port: action.port }
      };

    case types.PORT_UNHIGHLIGHT:
      return {
        ...state,
        highlightedPort: undefined
      };

    case types.NODE_MOUSE_DOWN:
      return {
        ...state,
        selectedWire: undefined,
        mousingNode: action.id,
        mouseMoved: false,
        mouseOrigin: { x: action.x, y: action.y },
        metaKey: action.metaKey,
        paletteOpen: false
      };

    case types.NODE_RESIZE_MOUSE_DOWN:
      return {
        ...state,
        selectedWire: undefined,
        resizing: { id: action.id, side: action.side },
        mouseOrigin: { x: action.x, y: action.y },
        paletteOpen: false
      };

    case types.SELECT_WIRE:
      return {
        ...state,
        selectedNodes: [],
        selectedWire: action.wire,
        paletteOpen: false
      };

    case types.WIRE_MOUSE_DOWN:

      return {
        ...state,
        selectedNodes: [],
        mousingWire: {
          source: action.source,
          port: action.port,
          target: action.target
        },
        paletteOpen: false
      };

    case types.PORT_MOUSE_DOWN:
      return {
        ...state,
        wiring: {
          node: action.node,
          port: action.port,
          mouse: {
            x: action.x,
            y: action.y
          }
        },
        paletteOpen: false
      };

    case types.RESET_MOUSE_STATE:
      return {
        ...state,
        mousingNode: undefined,
        mouseMoved: undefined,
        resizing: undefined,
        wiring: undefined,
        highlightedPort: undefined,
        mouseOrigin: undefined,
        metaKey: undefined
      };

    case types.SELECT_NODE:
      if (
        state.selectedNodes.length === 1 &&
        state.selectedNodes[0] === action.node
      ) { return state; }

      return {
        ...state,
        selectedNodes: [action.node],
        selectedWire: undefined,
        draggingNewNode: false
      };

    case types.SELECT_NODES:
      return {
        ...state,
        selectedNodes: [...state.selectedNodes, ...action.nodes],
        selectedWire: undefined
      };

    case types.DESELECT_NODE:
      return {
        ...state,
        selectedNodes: state.selectedNodes.filter(
          node => node !== action.node
        )
      };

    case appTypes.DESELECT_STACK_RESOURCE:
    case types.CLEAR_SELECTION:
      return {
        ...state,
        selectedNodes: [],
        newNodes: [],
        selectedWire: undefined,
        mousingWire: undefined,
        paletteOpen: false
      };

    case types.MOVE_NODES:
      return {
        ...state,
        selectedNodes: state.selectedNodes.includes(state.mousingNode)
          ? state.selectedNodes
          : [],
        mouseMoved: true,
        mouseOrigin: {
          x: state.mouseOrigin.x + action.dx,
          y: state.mouseOrigin.y + action.dy
        }
      };

    case types.RESIZE_NODE:
      return {
        ...state,
        selectedNodes: [],
        mouseMoved: true,
        mouseOrigin: {
          x: state.mouseOrigin.x + action.dx,
          y: state.mouseOrigin.y + action.dy
        }
      };

    case types.MOVE_WIRE:
      return {
        ...state,
        wiring: {
          ...state.wiring,
          mouse: {
            x: action.x,
            y: action.y
          }
        }
      };

    case types.DELETE_SELECTION:
      return {
        ...state,
        selectedNodes: [],
        selectedWire: undefined
      };

    case types.OPEN_PALETTE:
      return {
        ...state,
        paletteOpen: true
      };

    case types.DRAG_NEW_NODES:
      return {
        ...state,
        selectedWire: undefined,
        metaKey: false,
        mouseOrigin: { x: action.x, y: action.y },
        selectedNodes: [],

        // tracks which nodes are added while palette is open, resets when palette closes
        newNodes: action.isClick ? state.newNodes.concat(action.nodes[0].id) : state.newNodes
      };

    case types.UPDATE_NODE:
      return {
        ...state,
        paletteOpen: false
      };

    default:
      return state;
  }
};
