import React from 'react';
import PropTypes from 'prop-types';
import * as deploymentStates from '../../constants/deploymentStates';
import Tooltip from '../core/Tooltip';
import Button from '../core/Button';

const getTooltip = (status, isMultiAccount) => {
  let tooltip;

  switch (status) {
    case deploymentStates.QUEUED:
    case deploymentStates.PREPARING:
    case deploymentStates.CREATING_CHANGE_SET:
      tooltip = 'Please wait while deployment is prepared...';
      break;

    case deploymentStates.CHANGE_SET_CREATED:
      tooltip = 'Open AWS CloudFormation to execute change set.';
      if (isMultiAccount) {
        tooltip += '<br />* Make sure you are logged in with the correct account ID for this environment or an error will occur.';
      }
      break;

    case deploymentStates.DEPLOYING:
      tooltip = 'Please wait until prepared deployment has finished deploying...';
      break;

    case deploymentStates.FAILING:
    case deploymentStates.FAILED:
      tooltip = 'Preparation or deployment failed<br />A new deployment must be prepared';
      break;

    default:
      tooltip = 'Nothing prepared to deploy';
  }

  return tooltip;
};

const DeployButton = props => {
  const isDisabled = props.deploymentStatus !== deploymentStates.CHANGE_SET_CREATED;

  return (
    <div>
      <Tooltip message={getTooltip(props.deploymentStatus, props.isMultiAccount)} place='right'>
        <Button
          id={props.id}
          onClick={props.onDeployClick}
          isDisabled={isDisabled}
        >
          Deploy
        </Button>
      </Tooltip>
    </div>
  );
};

DeployButton.propTypes = {
  deploymentStatus: PropTypes.string,
  onDeployClick: PropTypes.func.isRequired
};

export default DeployButton;
