import React, { Fragment } from 'react';

// Tour steps mapped to page component name, e.g. 'Stack', 'Stacks'

const step = {
  disableBeacon: true,
  hideCloseButton: true,
  isFixed: true,
  showSkipButton: true,
  placement: 'auto',
  pauseTargets: []
};

export const ENVIRONMENTS = {
  steps: [
    {
      ...step,
      target: '#environments-header-link-button',
      content: <Fragment><p>Each environment is tied to an AWS account and provides namespacing, environment config, secrets management, and a pattern for deploying to multiple AWS accounts and regions.</p><p>Setup your first environment to get started. </p></Fragment>,
      placement: 'left-start',
      title: 'Configure your Environments'
    }
  ]
};
