import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { ESC } from '../../constants/keyCodes';
import * as states from '../../constants/states';
import apiActions from '../../actions/api';
import appActions from '../../actions/app';
import SwitchPlan from './SwitchPlan';

const mapStateToProps = (state, ownProps) => {
  return createSelector(
    (appState) => appState.account,
    (appState) => appState.users,
    (account, users) => {
      const currentlyActive = account.activeStacks.currentlyActive;
      const totalProviders = account.providers.length;
      const totalUsers = users.users.length;

      return {
        ...ownProps.modal,
        plan: account.plan,
        currentlyActive,
        totalProviders,
        totalUsers,
        isLoading:
          account.activeStacks.state === states.NEW ||
          account.activeStacks.state === states.LOADING ||
          users.state === states.NEW ||
          users.state === states.LOADING
      };
    }
  );
};

const mapDispatchToProps = {
  hideModal: appActions.hideModal,
  getActiveStacks: apiActions.getActiveStacks,
  getOrganizationUsers: apiActions.getOrganizationUsers,
  switchPlan: apiActions.switchPlan
};

class SwitchPlanContainer extends Component {
  constructor (props) {
    super(props);

    this.state = {
      value: null
    };

    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount () {
    this.props.getActiveStacks();
    this.props.getOrganizationUsers();
  }

  componentWillUnmount () {
    document.removeEventListener('keyup', this.handleKeyUp);
  }

  handleClick (event) {
    event.preventDefault();
    this.props.switchPlan(this.props.features.type);
  }

  handleKeyUp (event) {
    if (event.keyCode === ESC) {
      this.props.hideModal();
    }
  }

  render () {
    return (
      <SwitchPlan
        {...this.props}
        {...this.state}
        onClick={this.handleClick}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SwitchPlanContainer);
