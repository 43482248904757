import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import hints from '../../constants/patternHintText';
import Checkbox from './Checkbox';
import Radio from './Radio';
import AutoSuggest from './AutoSuggest';
import style from './Input.css';

const Input = (props) => {
  // check if prop pattern exists before accessing hints
  const title = props.pattern ? hints[props.pattern] : null;
  let Component;

  // TODO: Use appropriate components throughout codebase where Input~type=<checkbox|radio>
  if (props.type === 'checkbox') {
    Component = Checkbox;
  } else if (props.type === 'radio') {
    Component = Radio;
  }

  if (Component) {
    return (
      <Component
        id={props.id}
        type={props.type}
        name={props.name}
        classes={{ root: style.container, checked: style.isSelected, disabled: style.isDisabled }}
        defaultChecked={props.defaultChecked}
        checked={props.checked}
        onChange={props.onChange}
        value={props.value}
        color='primary'
        disabled={props.disabled}
        aria-disabled={props.disabled}
      />
    );
  }

  if (props.suggestions) {
    return (
      <AutoSuggest
        id={props.id}
        name={props.name}
        placeholder={props.placeholder}
        required={props.required}
        pattern={props.pattern}
        title={title}
        onChange={props.onChange}
        value={props.defaultValue}
        ref={props.inputRef}
        readOnly={props.readOnly}
        tabIndex='0'
        suggestions={props.suggestions}
        isLoading={props.isLoading}
      />
    );
  }

  return (
    <input
      id={props.id}
      type={props.type}
      name={props.name}
      className={classNames(
        props.className,
        props.readOnly && style.readOnly,
        props.hasError && props.errorClassName,
        props.checked && style.isChecked
      )}
      placeholder={props.placeholder}
      required={props.required}
      pattern={props.pattern}
      title={title}
      onChange={props.onChange}
      defaultChecked={props.defaultChecked}
      checked={props.checked}
      value={props.value}
      defaultValue={props.defaultValue}
      min={props.min}
      max={props.max}
      ref={props.inputRef}
      readOnly={props.readOnly}
      disabled={props.disabled}
      aria-disabled={props.disabled}
    />
  );
};

Input.defaultProps = {
  className: style.container,
  errorClassName: style.hasError,
  type: 'text',
  hasError: false,
  required: false,
  pattern: null
};

Input.propTypes = {
  className: PropTypes.string,
  errorClassName: PropTypes.string,
  type: PropTypes.string,
  pattern: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.any,
  title: PropTypes.string,
  value: PropTypes.any,
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  hasError: PropTypes.bool,
  required: PropTypes.bool,
  defaultValue: PropTypes.any,
  onChange: PropTypes.func,
  inputRef: PropTypes.func,
  suggestions: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool
};

export default Input;
