import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { OKAY } from '../../constants/states';
import Icon from '../core/Icon';
import Tooltip from '../core/Tooltip';
import Spinner from '../core/Spinner';
import style from './DeploymentDetails.css';

const getChangesList = changes => {
  const changesList = [];

  for (const nodeId in changes) {
    const nodeChanges = changes[nodeId];

    for (const change of nodeChanges.changes) {
      const changeDesc = {
        ...change
      };

      if (nodeChanges.name) changeDesc.nodeName = nodeChanges.name;
      else if (nodeId !== 'root') changeDesc.nodeName = nodeId;

      changesList.push(changeDesc);
    }
  }

  return changesList;
};

const renderChangeProperties = properties => {
  return (
    <div>
      <p>Modified Properties:</p>
      <ul>{properties.map((prop, i) => <li key={i}>{prop}</li>)}</ul>
    </div>
  );
};

const changeTooltip = change => {
  return ReactDOMServer.renderToStaticMarkup(
    <div>
      {change.nodeName && <p>For Node {change.nodeName}</p>}
      {change.description && <p>{change.description}</p>}
      {change.reason && <p>{change.reason}</p>}
      {change.properties && renderChangeProperties(change.properties)}
    </div>);
};

const renderChange = (change, i) => {
  let action;

  switch (change.action) {
    case 'create':
      action = <span className={style.changeActionCreate}>CREATE</span>;
      break;

    case 'modify':
      action = <span className={style.changeActionModify}>MODIFY</span>;
      break;

    case 'delete':
      action = <span className={style.changeActionDelete}>DELETE</span>;
      break;

    default:
      console.warn('Bad change action in change:');
      console.dir(change);
      break;
  }

  return (
    <div className={style.changeContainer} key={i}>
      <div>
        {action}
        &nbsp;
        <span className={style.changeDescription}>{change.resourceType}</span>
      </div>
      <Tooltip message={changeTooltip(change)} html place='right'>
        <Icon name='questionCircle' className={style.iconCircle} />
      </Tooltip>
    </div>
  );
};

export default function DeploymentDetails (props) {
  return (
    <div className={style.container}>
      <Spinner loaded={props.changesState === OKAY} />

      {props.changes && getChangesList(props.changes).map(renderChange)}
    </div>
  );
}
