import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import style from './FormRow.css';

const FormRow = (props) => {
  return (
    <label
      className={classNames(
        props.className,
        props.hasError && props.errorClassName,
        props.isHidden && style.isHidden
      )}
      id={props.id}
    >
      <span className={style.label}>{props.label}</span>
      {props.hint && props.inlineHint &&
        <span className={style.inlineHint}>{props.hint}</span>
      }
      {props.children}
      {props.hint && !props.inlineHint &&
        <span className={style.hint}>{props.hint}</span>
      }
    </label>
  );
};

FormRow.defaultProps = {
  className: style.container,
  errorClassName: style.hasError,
  hasError: false,
  id: null,
  inlineHint: false
};

FormRow.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  hint: PropTypes.string,
  errorClassName: PropTypes.string,
  fieldSetClassName: PropTypes.string,
  id: PropTypes.string,
  hasError: PropTypes.bool,
  inlineHint: PropTypes.bool
};

export default FormRow;
