import { types as appTypes } from '../actions/app';
import { types as apiTypes } from '../actions/api';
import * as states from '../constants/states';

export const initialState = {
  owner: undefined,
  stackName: undefined,
  environment: undefined,
  deployments: {}
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case appTypes.SELECT_STACK:
      if (action.owner === state.owner && action.stackName === state.stackName) { return state; }
      return {
        ...initialState,
        owner: action.owner,
        stackName: action.name
      };

    case appTypes.DESELECT_STACK:
      return {
        ...initialState
      };

    case appTypes.SELECT_STACK_ENVIRONMENT:
      if (action.environment === state.environment) return state;
      return {
        ...state,
        environment: action.environment,
        deployments: {}
      };
    case apiTypes.GET_CHANGES.REQUEST:
      return {
        ...state,
        deployments: {
          ...state.deployments,
          [action.deployment]: {
            state: states.LOADING,
            changes: []
          }
        }
      };
    case apiTypes.GET_CHANGES.FAILURE:
      return {
        ...state,
        deployments: {
          ...state.deployments,
          [action.deployment]: {
            state: states.FAILED,
            changes: []
          }
        }
      };
    case apiTypes.GET_CHANGES.SUCCESS:
      return {
        ...state,
        deployments: {
          ...state.deployments,
          [action.deployment]: {
            state: states.OKAY,
            changes: action.data.changes
          }
        }
      };
    default:
      return state;
  }
};
