import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import apiActions from '../../actions/api';
import appActions from '../../actions/app';
import * as states from '../../constants/states';
import CreateDeploymentPipeline from './CreateDeploymentPipeline';

const mapStateToProps = (state, ownProps) => {
  return createSelector(
    (appState) => appState.account,
    (appState) => appState.currentUser,
    (appState) => appState.environments,
    (appState) => appState.stacks,
    (appState) => appState.deploymentPipelines,
    (account, currentUser, environments, stacks, deploymentPipelines) => {
      const deploymentPipeline = deploymentPipelines.data.find(pipeline => pipeline.id === ownProps.pipelineId) || {};
      const stacksInUse = deploymentPipelines.data.filter(pipeline => pipeline.id !== ownProps.pipelineId).map(pipeline => pipeline.settings.stacks).reduce((stacks, stack) => stacks.concat(stack), []);

      return {
        deploymentPipeline,
        environments,
        stacks: {
          ...stacks,
          data: stacks.data.filter(stack => !stack.isGitless && !stacksInUse.includes(stack.id))
        },
        owner: currentUser.org,
        accountProviderStatus: account.provider.deploymentStatus,
        isEditing: deploymentPipeline.id !== undefined,
        isAddStageEnabled: account.plan.pipelineStageLimit === null || (deploymentPipeline.settings && deploymentPipeline.settings.stages.length < account.plan.pipelineStageLimit),
        isDeployerRoleEnabled: account.providers.filter(provider => provider.isDeployerRoleEnabled).length > 0,
        isLoading: environments.state === states.NEW || environments.state === states.LOADING,
        isSaving: deploymentPipelines.savingState === states.LOADING || deploymentPipeline.savingState === states.LOADING,
        isLoadingStacks: stacks.state === states.NEW || stacks.state === states.LOADING
      };
    }
  );
};

const mapDispatchToProps = {
  getEnvironments: apiActions.getEnvironments,
  getStacks: apiActions.getStacks,
  createDeploymentPipeline: apiActions.createDeploymentPipeline,
  updateDeploymentPipelineSettings: apiActions.updateDeploymentPipelineSettings,
  notifyUser: appActions.notifyUser,
  showLinkAwsModal: appActions.showLinkAwsModal,
  showCreateEnvironmentModal: appActions.showCreateEnvironmentModal,
  showUpdatePipelineDeploymentsModal: appActions.showUpdatePipelineDeploymentsModal
};

const initialState = {
  name: '',
  stages: [{}, {}],
  stageEnvironments: [],
  selectedStacks: []
};

class CreateDeploymentPipelineContainer extends Component {
  constructor (props) {
    super(props);

    this.state = {...initialState};

    this.filterStageEnvironments = this.filterStageEnvironments.bind(this);
    this.handleSelectStack = this.handleSelectStack.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleAddStage = this.handleAddStage.bind(this);
    this.handleRemoveStage = this.handleRemoveStage.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount () {
    this.props.getEnvironments();
    this.props.getStacks();

    if (this.props.isEditing) {
      this.setState({
        name: this.props.deploymentPipeline.name,
        stages: this.props.deploymentPipeline.settings.stages.map(stage => {
          return {
            environmentId: stage.environment.id,
            autoPromote: stage.autoPromote
          };
        })
      });
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const {
      isEditing,
      deploymentPipeline,
      environments,
      stacks
    } = this.props;

    const {
      stages
    } = this.state;

    if (prevProps.environments.data.length !== environments.data.length) {
      this.setState({
        stages: this.state.stages.map(stage => {
          if (stage.environmentId === 'addNewEnvironment') {
            stage.environmentId = `${environments.data[environments.data.length - 1].id}`;
          }
          return stage;
        })
      });
    }

    if (prevState.stages !== stages) {
      this.setState({
        stageEnvironments: this.filterStageEnvironments()
      });
    }

    if (prevProps.stacks.state !== stacks.state && stacks.state === states.OKAY && isEditing) {
      this.setState({
        selectedStacks: stacks.data.filter(stack => deploymentPipeline.settings.stacks.includes(stack.id))
      });
    }
  }

  filterStageEnvironments () {
    return this.props.environments.data
      .filter(environment => environment.isDeployerRoleEnabled)
      .map(environment => {
        return {
          ...environment,
          stageIndex: this.state.stages.findIndex(stage => stage.environmentId === `${environment.id}` || stage.environmentId === environment.id)
        };
      });
  }

  handleInputChange (event, key, index) {
    let {
      name,
      value
    } = event.target;

    if (value === 'addNewEnvironment') {
      this.props.showCreateEnvironmentModal({ isChild: true });
    }

    // Convert 'true' or 'false' string to boolean
    value = value === 'true' ? true : value === 'false' ? false : value;

    if (key !== undefined && index !== undefined) {
      let state = [...this.state[key]];
      state[index] = { ...state[index], [name]: value };
      this.setState({ [key]: state });
    } else {
      this.setState({ [name]: value });
    }
  }

  handleSelectStack (event, value, reason) {
    const {
      selectedStacks
    } = this.state;

    if (value && reason === 'select-option' && !selectedStacks.includes(value)) {
      // If an item is added, the returned value is the added item
      this.setState({ selectedStacks: [...new Set(selectedStacks.concat(value))] });
    } else if (value && reason === 'remove-option') {
      // If an item is removed, the returned value is the array of remaining items
      this.setState({ selectedStacks: value });
    } else if (reason === 'clear') {
      this.setState({ selectedStacks: [] });
    }
  }

  handleAddStage (event) {
    event.preventDefault();
    this.setState({ stages: this.state.stages.concat({ environmentId: undefined, autoPromote: false }) });
  }

  handleRemoveStage (event, stageIndex) {
    event.preventDefault();
    this.setState({ stages: this.state.stages.filter((stage, i) => stageIndex !== i) });
  }

  handleSubmit (event) {
    event.preventDefault();

    const {
      name,
      selectedStacks
    } = this.state;

    const {
      owner,
      deploymentPipeline,
      isEditing,
      createDeploymentPipeline,
      updateDeploymentPipelineSettings
    } = this.props;

    const stages = this.state.stages.map(stage => ({ environmentId: stage.environmentId, autoPromote: !!stage.autoPromote }));
    const stacks = selectedStacks.map(stack => stack.id);

    isEditing ? updateDeploymentPipelineSettings(owner, deploymentPipeline.id, name, stages, stacks) : createDeploymentPipeline(name, stages, stacks);
  }

  render () {
    return (
      <CreateDeploymentPipeline
        {...this.props}
        {...this.state}
        stacks={this.props.stacks.data}
        onSelectStack={this.handleSelectStack}
        onInputChange={this.handleInputChange}
        onAddStage={this.handleAddStage}
        onRemoveStage={this.handleRemoveStage}
        onSubmit={this.handleSubmit}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateDeploymentPipelineContainer);
