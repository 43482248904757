import React from 'react';
import PropTypes from 'prop-types';
import Totals from './Totals';
import Completion from './Completion';
import ActivityContainer from '../activity/ActivityContainer';
import Spinner from '../core/Spinner';
import style from './Home.css';

const Home = props => {
  const {
    showCompletion,
    isCreatingStack
  } = props;

  if (isCreatingStack) {
    return <Spinner />;
  }

  return (
    <div className={style.container}>
      <main className={style.main}>
        <Totals
          stacks={props.stacks}
          environments={props.environments}
          totalDeployments={props.totalDeployments}
          totalActiveStacks={props.activeStacks.currentlyActive}
          isLoadingStacks={props.isLoadingStacks}
          isLoadingEnvironments={props.isLoadingEnvironments}
          isLoadingUsage={props.isLoadingUsage}
        />
        {showCompletion &&
          <Completion
            stacks={props.stacks}
            currentUser={props.currentUser}
            isLoadingStacks={props.isLoadingStacks}
            isLoadingEnvironments={props.isLoadingEnvironments}
            toggleTourMode={props.toggleTourMode}
          />
        }
        <ActivityContainer />
      </main>
    </div>
  );
};

Home.propTypes = {
  currentUser: PropTypes.object.isRequired
};

export default Home;
