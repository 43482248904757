import React from 'react';
import PropTypes from 'prop-types';
import formatTimestamp from '../../utils/formatTimestamp';
import Link from '../core/Link';
import Table from '../core/Table';
import Spinner from '../core/Spinner';
import style from './DeploymentActivity.css';

const DeploymentActivity = props => {
  const {
    activeStacks,
    owner,
    isActivityLoading,
    isActivityLoaded,
    isActivityEmpty
  } = props;

  return (
    <section className={style.container}>
      <h1>Active Deployments</h1>

      <Table isStriped isEmpty={activeStacks.length === 0}>
        <thead>
          <tr>
            <th>Stack</th>
            <th>Environment</th>
            <th>Active Deployment</th>
            <th>Deployed On</th>
          </tr>
        </thead>

        {isActivityLoading &&
          <tbody>
            <tr>
              <td colSpan='4'><Spinner position='static' /></td>
            </tr>
          </tbody>
        }

        {isActivityLoaded && isActivityEmpty &&
          <tbody>
            <tr>
              <td colSpan='4'>No active deployments</td>
            </tr>
          </tbody>
        }

        {isActivityLoaded && !isActivityEmpty &&
          <tbody>
            {activeStacks && activeStacks.map((stack, index) => {
              return (
                <tr key={stack.id}>
                  <td>
                    <Link to={`/stacks/${owner}/${stack.stackName}`}>
                      {stack.stackName}
                    </Link>
                  </td>
                  <td>
                    <Link to={`/environments/${owner}/${stack.environmentName}`}>
                      {stack.environmentName}
                    </Link>
                  </td>
                  <td>
                    <Link to={`/stacks/${owner}/${stack.stackName}/view/${stack.environmentName}`}>
                      {`${stack.stackName}-${stack.environmentName}`}
                    </Link>
                  </td>
                  <td className={style.timestamp}>
                    {stack.events ? formatTimestamp(stack.events[0].timestamp) : ''}
                  </td>
                </tr>
              );
            })}
          </tbody>
        }
      </Table>
    </section>
  );
};

DeploymentActivity.propTypes = {
  activeStacks: PropTypes.array,
  owner: PropTypes.string,
  isActivityLoading: PropTypes.bool,
  isActivityLoaded: PropTypes.bool,
  isActivityEmpty: PropTypes.bool
};

export default DeploymentActivity;
