import React from 'react';
import PropTypes from 'prop-types';
import MuiCheckbox from '@material-ui/core/Checkbox';
import style from './Checkbox.css';

const Checkbox = (props) => {
  return (
    <MuiCheckbox
      id={props.id}
      name={props.name}
      classes={{ root: style.container, colorPrimary: style.colorPrimary, checked: style.isSelected, disabled: style.isDisabled }}
      defaultChecked={props.defaultChecked}
      checked={props.checked}
      onChange={props.onChange}
      value={props.value}
      color='primary'
      disabled={props.disabled}
      aria-disabled={props.disabled}
    />
  );
};

Checkbox.defaultProps = {
  required: false
};

Checkbox.propTypes = {
  id: PropTypes.any,
  name: PropTypes.string,
  value: PropTypes.any,
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  inputRef: PropTypes.func,
  disabled: PropTypes.bool
};

export default Checkbox;
