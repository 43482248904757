import React from 'react';
import { Route, Redirect, Switch } from 'react-router';
import { Prompt } from 'react-router-dom';
import PropTypes from 'prop-types';
import Spinner from '../core/Spinner';
import StackOverviewContainer from '../stackOverview/StackOverviewContainer';
import StackEditContainer from '../stackEdit/StackEditContainer';
import StackDeployContainer from '../stackDeploy/StackDeployContainer';
import StackViewContainer from '../stackView/StackViewContainer';
import StackSettingsContainer from '../stackSettings/StackSettingsContainer';
import WorkspaceContainer from '../workspace/WorkspaceContainer';
import StackHeader from './StackHeader';
import StackNav from './StackNav';
import style from './Stack.css';

const Stack = props => {
  const {
    match,
    location,
    stack,
    gitProviders,
    queryParams,
    isGitless,
    isLoaded,
    isLocalMode,
    isStackDirty,
    onRenameClick,
    onDeleteClick,
    onRepoClick
  } = props;

  if (!isLoaded) {
    return <Spinner />;
  }

  // Needs to check location.search immediately before interpreting the routes...
  // ...otherwise lifecycle latency will cause a premature `false`
  const hasLocalQueryParams = location.search.includes('port') && location.search.includes('secret') && location.search.includes('templatepath');

  return (
    <div className={style.container}>
      <StackHeader
        stack={stack}
        gitProviders={gitProviders}
        isGitless={isGitless}
        isLocalMode={isLocalMode}
        onRenameClick={onRenameClick}
        onDeleteClick={onDeleteClick}
        onRepoClick={onRepoClick}
      />
      <main className={style.main}>
        <StackNav
          stack={stack}
          match={match}
          queryParams={queryParams}
        />
        <Switch>
          <Route exact path={`${match.path}/overview`} component={StackOverviewContainer} />

          { /*
            Buggy routing behavior when grouping routes within a condition (e.g. using <Fragment>).
            Workaround is condition repetition (1 route per condition):
          */ }
          {hasLocalQueryParams &&
            <Route exact path={`${match.path}/edit`} component={WorkspaceContainer} />
          }

          {hasLocalQueryParams &&
            <Route path={`${match.path}/edit/:resourceId`} component={WorkspaceContainer} />
          }

          {!hasLocalQueryParams &&
            <Route exact={!isGitless} path={`${match.path}/edit`} component={StackEditContainer} />
          }

          {!hasLocalQueryParams && !isGitless && !stack.wasGitless &&
            <Route path={`${match.path}/edit/:branch`} component={StackEditContainer} />
          }

          {!hasLocalQueryParams && !isGitless && stack.wasGitless &&
            <Route path={`${match.path}/edit/:mode`} component={StackEditContainer} />
          }

          {!hasLocalQueryParams && !isGitless &&
            <Route exact path={`${match.path}/edit/:branch/:mode`} component={StackEditContainer} />
          }

          <Route exact path={`${match.path}/deploy`} component={StackDeployContainer} />
          <Route exact path={`${match.path}/view`} component={StackViewContainer} />
          <Route exact path={`${match.path}/view/:environment`} component={StackViewContainer} />
          <Route exact path={`${match.path}/view/:environment/:resourceId`} component={StackViewContainer} />
          <Route exact path={`${match.path}/settings`} component={StackSettingsContainer} />
          <Redirect to={`${match.url}/overview`} />
        </Switch>
        <Prompt
          when={isStackDirty && !isLocalMode}
          message={location => {
            if (location.pathname.indexOf('/stacks') === -1 || location.pathname === '/stacks') {
              return `Warning: you will lose any uncommitted work. Are you sure you want to leave the stack?`;
            }
            return true;
          }}
        />
      </main>
    </div>
  );
};

Stack.propTypes = {
  stack: PropTypes.object.isRequired,
  isStackDirty: PropTypes.bool.isRequired
};

export default Stack;
