import React, { Fragment } from 'react';
import StackRepoFormContainer from '../stackRepoForm/StackRepoFormContainer';

const CreateStackExistingRepo = (props) => {
  return (
    <Fragment>
      <h1>Add a Stack</h1>
      <p>Create a new stack with an existing Git repository backed by a VCS provider.</p>
      <StackRepoFormContainer isNewRepo={false} />
    </Fragment>
  );
};

CreateStackExistingRepo.propTypes = {
};

export default CreateStackExistingRepo;
