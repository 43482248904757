import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import CanvasContainer from '../canvas/CanvasContainer';
import ResourceEditorContainer from '../resourceEditor/ResourceEditorContainer';
import NodePaletteContainer from '../nodePalette/NodePaletteContainer';
import style from './Visual.css';

const Visual = props => {
  const {
    isVisualEditingSupported,
    isTemplateValid,
    showResourceEditor,
    showNodePalette
  } = props;

  const isOverflowHidden = !isTemplateValid || showResourceEditor || showNodePalette;

  return (
    <div className={classnames(style.container, isOverflowHidden && style.noScroll)}>
      {!isVisualEditingSupported &&
        <p className={style.message}>Visual editing is only supported for stacks using the <strong>AWS SAM</strong> template format.</p>
      }
      {isVisualEditingSupported &&
        <CanvasContainer />
      }

      {showResourceEditor &&
        <ResourceEditorContainer />
      }

      {showNodePalette &&
        <NodePaletteContainer />
      }
    </div>
  );
};

Visual.propTypes = {
  stack: PropTypes.object,
  showNodeEditor: PropTypes.bool.isRequired,
  showResourceEditor: PropTypes.bool.isRequired,
  showNodePalette: PropTypes.bool.isRequired
};

export default Visual;
