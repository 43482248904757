import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { DropTarget } from 'react-dnd';
import { createSelector } from 'reselect';
import validate from '../../vendor/validate.js';
import canvasActions from '../../actions/canvas';
import nodeEditorActions from '../../actions/nodeEditor';
import nodeDeploymentInfoActions from '../../actions/nodeDeploymentInfo';
import resourceEditorActions from '../../actions/resourceEditor';
import cloudwatchActions from '../../actions/cloudwatch';
import appActions from '../../actions/app';
import {
  gridSnapSize,
  nodeWidthMin,
  nodeHeightMin
} from '../../constants/canvas';
import * as states from '../../constants/states';
import * as modes from '../../constants/modes';
import * as dragTypes from '../../constants/dragTypes';
import * as keyCodes from '../../constants/keyCodes';
import debounce from '../../utils/debounce';
import isReference from '../../utils/isReference';
import { getCustomResourceName } from '../../resources/getCustomResourceInfo';
import selectors from '../../selectors';
import Link from '../core/Link';
import Canvas from './Canvas';

validate.options = {
  format: 'flat'
};
validate.validators.presence.options = { message: 'is not set' };
validate.validators.inclusion.options = {
  message: function (value, attribute) {
    return (
      '^Invalid ' + validate.prettify(attribute) + " value '" + value + "'"
    );
  }
};

const messageDeleteCustomResource = (nodeId, resources, templateUrl) => (
  <span>
    Cannot delete resource '{getCustomResourceName(nodeId, resources)}' in visual editor mode. You can remove the resource by modifying the YAML using the <Link to={templateUrl}>template editor</Link>.
  </span>
);

const messageDeleteResourceReferencedByCustomResource = (nodeId, sources, templateUrl) => (
  <span>
    Cannot delete resource '{nodeId}' in visual editor mode because it is referenced by {sources}. You can remove the resource by modifying the YAML using the <Link to={templateUrl}>template editor</Link>.
  </span>
);

const messageDeleteCustomResourceReference = (source, resources, templateUrl) => (
  <span>
    Cannot delete this wire in visual editor mode because it is a reference from '{getCustomResourceName(source, resources)}'. You can remove the reference by modifying the YAML using the <Link to={templateUrl}>template editor</Link>.
  </span>
);

const getBoundingBox = (id = 'canvas') => {
  const element = document.getElementById(id);
  return element.getBoundingClientRect();
};

const getScrollOffset = (id = 'canvas') => {
  const element = document.getElementById(id);
  return {
    left: element.scrollLeft,
    top: element.scrollTop
  };
};

const spec = {
  drop: (props, monitor, component) => {
    const item = monitor.getItem();
    const clientOffset = monitor.getClientOffset();
    const canvasRect = getBoundingBox();
    const canvasScrollOffset = getScrollOffset();

    const position = {
      x: clientOffset.x + (canvasScrollOffset.left - canvasRect.left),
      y: clientOffset.y + (canvasScrollOffset.top - canvasRect.top)
    };

    if (item.onDrop && typeof item.onDrop === 'function') {
      item.onDrop(position);
    }
  }
};

const collect = (connect, monitor) => {
  return {
    connectDropTarget: connect.dropTarget()
  };
};

const isFailed = createSelector(
  (appState) => appState.stack,
  (appState) => appState.editorNodes,
  (appState) => appState.deployments,
  (appState) => appState.stackBranches,
  (stack, editorNodes, deployments, stackBranches) => {
    const isEditing = stack.mode !== modes.READ_ONLY;
    const editorNodesFailed = editorNodes.state === states.FAILED;
    const deploymentsFailed = deployments.state === states.FAILED;
    const stackBranchesFailed = stackBranches.state === states.FAILED;

    return (isEditing && stackBranchesFailed) || (isEditing && editorNodesFailed) || (!isEditing && deploymentsFailed);
  }
);

const isLoaded = createSelector(
  (appState) => appState.stack,
  (appState) => appState.editorNodes,
  (appState) => appState.deploymentNodes,
  (appState) => appState.deployments,
  (appState) => appState.currentUser,
  (stack, editorNodes, deploymentNodes, deployments, currentUser) => {
    const isPublic = stack.mode === modes.PUBLIC;
    const isEditing = stack.mode !== modes.READ_ONLY;
    const isGitless = stack.isGitless;
    const editorNodesLoaded = editorNodes.state === states.OKAY;
    const deploymentNodesLoaded = deploymentNodes.selectedDeploymentState === states.OKAY;
    const hasNoDeployments = !currentUser.hasLinkedAws || (!deployments.selectedDeploymentId && deployments.state === states.OKAY);

    return (
      isPublic ||
      isGitless ||
      (isEditing && editorNodesLoaded) ||
      (!isEditing && (deploymentNodesLoaded || hasNoDeployments))
    );
  }
);

const mapStateToProps = () => {
  return createSelector(
    (appState) => appState.stack,
    (appState) => appState.canvas,
    (appState) => appState.editorNodes,
    (appState) => appState.formation,
    (appState) => appState.templateEditor,
    (appState) => appState.deploymentNodes,
    (appState) => appState.deployments,
    (appState) => appState.stackBranches,
    selectors.nodeTypes,
    (appState) => appState.aws,
    (appState) => appState.cloudwatch,
    isLoaded,
    isFailed,
    (stack, canvas, editorNodes, formation, templateEditor, deploymentNodes, deployments, stackBranches, nodeTypes, aws, cloudwatch, isLoaded, isFailed) => {
      let nodes = [];
      let isEditing = stack.mode !== modes.READ_ONLY;
      let isCurrentDeploymentSelected = !!deployments.currentDeployment && deployments.currentDeployment.id === deployments.selectedDeploymentId;
      let showMetricsToolbar = !isEditing && isCurrentDeploymentSelected && deploymentNodes.selectedDeploymentHasMetrics;
      let resources;

      if (isEditing) {
        nodes = editorNodes.nodes;
        resources = formation.resources.resources;
      } else if (
        deployments.selectedDeploymentId &&
        deploymentNodes.deployments &&
        deploymentNodes.deployments[deployments.selectedDeploymentId] &&
        deploymentNodes.deployments[deployments.selectedDeploymentId].state === states.OKAY
      ) {
        const deployment = deploymentNodes.deployments[deployments.selectedDeploymentId];

        nodes = deployment.nodes;

        // stack.json deployments don't have a `resources` state
        resources = deployment.resources && deployment.resources.resources;
      }

      return {
        stack,
        nodes,
        formation,
        canvas,
        editorNodes,
        templateEditor,
        deploymentNodes,
        deployments,
        resources: resources || {},
        stackBranches,
        nodeTypes,
        wiring: canvas.wiring,
        moving:
          canvas.mousingNode ||
          canvas.mousingWire ||
          canvas.resizing ||
          canvas.wiring,
        aws,
        cloudwatch,
        isEditing,
        isLoaded,
        isFailed,
        showMetricsToolbar,
        format: stack.format
      };
    }
  );
};

const mapDispatchToProps = {
  layoutNodes: canvasActions.layoutNodes,
  openPalette: canvasActions.openPalette,
  selectNode: canvasActions.selectNode,
  selectNodes: canvasActions.selectNodes,
  deselectNode: canvasActions.deselectNode,
  selectWire: canvasActions.selectWire,
  clearSelection: canvasActions.clearSelection,
  moveNodes: canvasActions.moveNodes,
  resizeNode: canvasActions.resizeNode,
  moveWire: canvasActions.moveWire,
  wireNodes: canvasActions.wireNodes,
  resetMouseState: canvasActions.resetMouseState,
  deleteSelection: canvasActions.deleteSelection,
  invalidateNodes: canvasActions.invalidateNodes,
  updateValidation: canvasActions.updateValidation,
  editNode: nodeEditorActions.editNode,
  cancelEditNode: nodeEditorActions.cancel,
  editResource: resourceEditorActions.editResource,
  cancelEditResource: resourceEditorActions.cancel,
  showNodeDeploymentInfo: nodeDeploymentInfoActions.show,
  closeNodeDeploymentInfo: nodeDeploymentInfoActions.close,
  getMetrics: cloudwatchActions.getMetrics,
  validateResources: canvasActions.validateResources,
  selectStackResource: appActions.selectStackResource,
  deselectStackResource: appActions.deselectStackResource,
  resetStackTemplate: appActions.resetStackTemplate,
  notifyUser: appActions.notifyUser
};

class CanvasContainer extends Component {
  constructor (props) {
    super(props);

    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleDocumentMouseUp = this.handleDocumentMouseUp.bind(this);
    this.handleResize = debounce(this.handleResize.bind(this), 500);
    this.showPanel = this.showPanel.bind(this);
    this.layout = this.layout.bind(this);
  }

  componentDidMount () {
    if (this.props.editorNodes.needsLayout || this.props.deploymentNodes.needsLayout) {
      this.layout();
    }

    document.addEventListener('mouseup', this.handleDocumentMouseUp);
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount () {
    this.props.cancelEditNode();
    this.props.closeNodeDeploymentInfo();
    this.props.clearSelection();
    document.removeEventListener('mouseup', this.handleDocumentMouseUp);
    window.removeEventListener('resize', this.handleResize);
  }

  componentDidUpdate (prevProps) {
    const {
      stack: prevStack,
      editorNodes: prevEditorNodes,
      deploymentNodes: prevDeploymentNodes,
      formation: prevFormation
    } = prevProps;

    const {
      canvas,
      stack,
      format,
      formation,
      templateEditor,
      editorNodes,
      deploymentNodes,
      isEditing
    } = this.props;

    if (stack.mode !== prevStack.mode) {
      this.props.clearSelection();
    }

    // Open (NodeEditor or ResourceEditor or NodeDeploymentInfo) if stack.selectedResourceId is found in nodes
    if (
      stack.selectedResourceId &&
      canvas.selectedNodes.indexOf(stack.selectedResourceId) === -1 &&
      ((isEditing && editorNodes.state === states.OKAY) || (!isEditing && deploymentNodes.selectedDeploymentState === states.OKAY))
    ) {
      this.showPanel();
    }

    // Close (any) edit/info panel if stack.selectedResourceId is unset
    if (
      prevStack.selectedResourceId &&
      !stack.selectedResourceId
    ) {
      this.props.cancelEditNode();
      this.props.cancelEditResource();
      this.props.closeNodeDeploymentInfo();
      this.props.deselectNode(prevStack.selectedResourceId);
    }

    // Validate editorNodes if settings change
    if (
      isEditing &&
      format === 'stackery' &&
      editorNodes.state === states.OKAY &&
      !editorNodes.isValidated
    ) {
      this.validateEditorNodes(editorNodes.nodes);
    }

    // Validate resources if settings change
    if (
      isEditing &&
      format !== 'stackery' &&
      editorNodes.state === states.OKAY &&
      !editorNodes.isValidated &&
      templateEditor.isTemplateValid
    ) {
      this.props.validateResources();
    }

    // Layout editorNodes with ELK if version or settings change
    // Layout deploymentNodes with ELK if deployment changes
    if (
      (formation.state === states.OKAY && editorNodes.needsLayout && (!prevEditorNodes.needsLayout || prevFormation.state !== states.OKAY)) ||
      (!prevDeploymentNodes.needsLayout && deploymentNodes.needsLayout)
    ) {
      this.layout();
    }
  }

  handleResize () {
    this.layout();
  }

  layout () {
    const {
      isEditing,
      formation,
      deploymentNodes,
      stack
    } = this.props;

    const isWrapEnabled = stack.mode !== modes.PUBLIC;

    if (isEditing && formation.state === states.OKAY) {
      this.props.layoutNodes(formation.resources, getBoundingBox(), isWrapEnabled);
    } else if (
      !isEditing &&
      deploymentNodes.selectedDeploymentId &&
      deploymentNodes.selectedDeploymentId in deploymentNodes.deployments &&
      deploymentNodes.deployments[deploymentNodes.selectedDeploymentId].resources
    ) {
      this.props.layoutNodes(deploymentNodes.deployments[deploymentNodes.selectedDeploymentId].resources, getBoundingBox(), isWrapEnabled, deploymentNodes.selectedDeploymentId, deploymentNodes.deployments[deploymentNodes.selectedDeploymentId].nodes);
    }
  }

  showPanel () {
    const {
      stack,
      nodes,
      format,
      isEditing
    } = this.props;

    const node = nodes.find(node => node.id === stack.selectedResourceId);

    if (node) {
      this.props.selectNode(node.id);

      if (isEditing) {
        if (format === 'stackery') {
          this.props.editNode(node);
        } else {
          this.editResourceNode(node);
        }
      } else {
        this.props.showNodeDeploymentInfo(node);
      }
    } else {
      this.props.notifyUser(`The resource ${stack.selectedResourceId} was not found`, 'error');
      this.props.deselectStackResource();
    }
  }

  handleDocumentMouseUp (event) {
    const canvasRect = getBoundingBox();

    if (
      event.clientX < canvasRect.left ||
      event.clientY < canvasRect.top ||
      event.clientX > window.innerWidth ||
      event.clientY > window.innerHeight
    ) {
      this.handleMouseUp(event);
    }
  }

  validateEditorNodes (nodes) {
    const allErrors = nodes.reduce((allErrors, node) => {
      const type = this.props.nodeTypes.types[node.type];
      const errors = [];

      if (isReference(node) && type.referenceConstraints) {
        const referenceConstraints = {
          'reference.arn': type.referenceConstraints.arn
        };

        Array.prototype.push.apply(errors, validate(node, referenceConstraints));
      } else if (!isReference(node)) {
        if (type.constraints) { Array.prototype.push.apply(errors, validate(node, type.constraints)); }

        if (type.validate) { Array.prototype.push.apply(errors, type.validate.call(node)); }
      }

      if (errors.length > 0) allErrors[node.id] = errors;

      return allErrors;
    }, {});

    this.props.updateValidation(allErrors);
  }

  handleMouseDown (event) {
    event.stopPropagation();
    this.props.clearSelection();
  }

  deltaX (x) {
    return (
      Math.floor((x - this.props.canvas.mouseOrigin.x) / gridSnapSize) *
      gridSnapSize
    );
  }

  deltaY (y) {
    return (
      Math.floor((y - this.props.canvas.mouseOrigin.y) / gridSnapSize) *
      gridSnapSize
    );
  }

  moveNodes (dx, dy) {
    if (!dx && !dy) return;

    const {
      canvas,
      nodes
    } = this.props;

    /* Add some hysteresis to make it easier to double click without moving a
     * resource */
    if (
      !canvas.mouseMoved &&
      Math.abs(dx) < 2 * gridSnapSize &&
      Math.abs(dy) < 2 * gridSnapSize
    ) {
      return;
    }

    const isFacetNode = (id) => !!nodes.find(node => node.id === id && node.type === 'facet');

    if (canvas.selectedNodes.includes(canvas.mousingNode)) {
      this.props.moveNodes(canvas.selectedNodes.filter(id => !isFacetNode(id)), dx, dy);
    } else {
      this.props.moveNodes([canvas.mousingNode].filter(id => !isFacetNode(id)), dx, dy);
    }
  }

  resizeNode (dx, dy) {
    const side = this.props.canvas.resizing.side;
    const node = this.props.nodes.find(
      node => node.id === this.props.canvas.resizing.id
    );

    let { x, y, width, height } = node;

    if (side === 'top' || side === 'bottom') {
      if (!dy) return;

      if (side === 'top') height -= dy;
      else height += dy;

      if (height < nodeHeightMin) return;

      y += dy / 2;
    } else {
      if (!dx) return;

      if (side === 'left') width -= dx;
      else width += dx;

      if (width < nodeWidthMin) return;

      x += dx / 2;
    }

    this.props.resizeNode(node.id, x, y, width, height, dx, dy);
  }

  handleMouseMove (event) {
    if (!this.props.isEditing) return;

    event.stopPropagation();

    const {
      wiring,
      resizing,
      mousingNode
    } = this.props.canvas;

    if (
      !mousingNode &&
      !resizing &&
      !wiring
    ) { return; }

    const canvasRect = getBoundingBox();
    const canvasScrollOffset = getScrollOffset();

    const x = event.clientX - canvasRect.left;
    const y = event.clientY - canvasRect.top;

    if (wiring) {
      this.props.moveWire((x + canvasScrollOffset.left), (y + canvasScrollOffset.top));
      return;
    }

    const dx = this.deltaX(x);
    const dy = this.deltaY(y);

    if (mousingNode) this.moveNodes(dx, dy);
    else if (resizing) this.resizeNode(dx, dy);
  }

  nodeMouseUp (id) {
    const {
      canvas,
      nodeTypes,
      nodes,
      format,
      isEditing
    } = this.props;

    if (!canvas.mouseMoved || canvas.draggingNewNode) {
      let node = nodes.find(node => node.id === id);

      let type = nodeTypes.types[node.type];
      let isNodeSelected = canvas.selectedNodes.includes(node.id);

      if (isNodeSelected) {
        if (canvas.metaKey) {
          this.props.deselectNode(id);
        } else if (isEditing) {
          canvas.selectedNodes
            .filter(id => id !== node.id)
            .forEach(id => this.props.deselectNode(id));
          if (format === 'stackery') {
            this.props.editNode(node);
          } else {
            this.editResourceNode(node);
          }
        } else if (!isEditing && type.deploymentProperties) {
          this.props.showNodeDeploymentInfo(node);
        }
      } else {
        if (canvas.metaKey) {
          this.props.selectNodes([node.id]);
        } else {
          this.props.selectNode(node.id);
        }
      }
    } else {
      this.props.invalidateNodes();
    }
  }

  editResourceNode (node) {
    const { resourceId, facetType, facetId } = (node.type === 'facet')
      ? {
        resourceId: node.sourceId,
        facetType: node.facetType,
        facetId: node.facet.Id
      }
      : {
        resourceId: node.id
      };

    this.props.editResource(this.props.format, this.props.formation.resources.resources[resourceId], facetType, facetId);
  }

  wiringMouseUp () {
    const { canvas } = this.props;

    if (canvas.highlightedPort && (canvas.highlightedPort.node !== canvas.wiring.node)) {
      let source, port, target;
      if (canvas.wiring.port !== undefined) {
        source = canvas.wiring.node;
        port = canvas.wiring.port;
        target = canvas.highlightedPort.node;
      } else {
        source = canvas.highlightedPort.node;
        port = canvas.highlightedPort.port;
        target = canvas.wiring.node;
      }

      this.props.wireNodes(source, port, target);
    }
  }

  wireMouseUp (event) {
    const { canvas } = this.props;

    const isWireSelected = (
      canvas.mousingWire &&
      canvas.selectedWire &&
      canvas.mousingWire.source === canvas.selectedWire.source &&
      canvas.mousingWire.target === canvas.selectedWire.target
    );

    if (isWireSelected && this.props.isEditing) {
      if (this.props.format === 'stackery') {
        this.props.clearSelection();
      } else {
        const sourceNode = this.props.nodes.find(node => node.id === canvas.selectedWire.source);

        this.editResourceNode(sourceNode);
      }
    } else {
      this.props.selectWire(canvas.mousingWire);
    }
  }

  handleMouseUp (event) {
    event.stopPropagation();

    const { canvas } = this.props;

    if (canvas.mousingNode) {
      this.nodeMouseUp(canvas.mousingNode);
    } else if (canvas.resizing) {
      this.nodeMouseUp(canvas.resizing.id);
    } else if (canvas.wiring) {
      this.wiringMouseUp();
    } else if (canvas.mousingWire) {
      this.wireMouseUp();
    }

    this.props.resetMouseState();
  }

  handleKeyDown (event) {
    if (!this.props.isEditing) return;

    const {
      canvas,
      formation,
      nodes
    } = this.props;

    // Enter
    if (event.keyCode === keyCodes.ENTER) {
      const activeId = document.activeElement.getAttribute('id');
      const activeNode = nodes.filter(node => node.id === activeId)[0];
      const selectedNodeId = canvas.selectedNodes.filter(nodeId => nodeId === activeId)[0];

      if (activeNode && !selectedNodeId) {
        this.props.selectNode(activeId);
      } else if (activeId === selectedNodeId) {
        this.nodeMouseUp(activeId);
      }
    }

    // Delete
    if (event.keyCode === keyCodes.BACKSPACE || event.keyCode === keyCodes.DELETE) {
      event.preventDefault();

      if (
        event.currentTarget.getAttribute('id') === 'canvasContainer' &&
        (canvas.selectedNodes.length > 0 ||
          canvas.selectedWire)
      ) {
        const nonFacetNodes = canvas.selectedNodes.filter(nodeId => nodes.find(node => node.id === nodeId).type !== 'facet');
        const selectedWire = canvas.selectedWire;
        const templateUrl = window.location.pathname.replace(/\/visual.*/, '/template');

        const resources = formation.resources.resources;
        const customResourceReferences = formation.resources.customResourceReferences;
        let customReferenceTargets = {};
        for (const customResourceId in customResourceReferences) {
          Object.keys(customResourceReferences[customResourceId]).forEach(targetId => {
            customReferenceTargets[targetId] = customReferenceTargets[targetId] || [];
            if (!customReferenceTargets[targetId].includes(customResourceId)) {
              customReferenceTargets[targetId].push(customResourceId);
            }
          });
        }

        for (let nodeId of nonFacetNodes) {
          // Can't delete custom resources
          if (resources[nodeId] && resources[nodeId].Type === 'custom') {
            this.props.notifyUser(messageDeleteCustomResource(nodeId, resources, templateUrl), 'error');
            return;
          }

          // Can't delete nodes that are referenced from custom resources
          if (customReferenceTargets[nodeId] && customReferenceTargets[nodeId].length > 0) {
            const sources = customReferenceTargets[nodeId].map(resourceId => getCustomResourceName(resourceId, resources)).join(', ');
            this.props.notifyUser(messageDeleteResourceReferencedByCustomResource(nodeId, sources, templateUrl), 'error');
            return;
          }
        }

        // Can't delete wires that are sourced from custom resources
        if (selectedWire) {
          if (customResourceReferences[selectedWire.source] && customResourceReferences[selectedWire.source][selectedWire.target]) {
            this.props.notifyUser(messageDeleteCustomResourceReference(selectedWire.source, resources, templateUrl), 'error');
            return;
          }
        }

        this.props.deleteSelection(
          nonFacetNodes,
          selectedWire
        );
      }
    }
  }

  render () {
    return (
      <Canvas
        {...this.props}
        onKeyDown={this.handleKeyDown}
        onKeyUp={this.onKeyUp}
        onMouseMove={this.handleMouseMove}
        onMouseUp={this.handleMouseUp}
        onMouseDown={this.handleMouseDown}
        onMoveNodes={this.moveNodes}
      />
    );
  }
}

CanvasContainer.propTypes = {
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  DropTarget([dragTypes.NODE_TYPE, dragTypes.NODE_TYPE_REF], spec, collect)
)(CanvasContainer);
