export const types = {
  SHOW: 'APP::NODE_DEPLOYMENT_INFO::SHOW',
  CLOSE: 'APP::NODE_DEPLOYMENT_INFO::CLOSE'
};

const actions = {
  show: node => ({ type: types.SHOW, node }),
  close: () => ({ type: types.CLOSE })
};

export default actions;
