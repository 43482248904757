const highPriorityList = [
  {
    type: 'AWS::Lambda::LayerVersion',
    icon: 'ic-aws-lambda.svg'
  },
  {
    type: `Alexa::ASK::Skill`,
    icon: `ic-aws-iot-resource-alexa-skill.svg`
  },
  {
    type: `Alexa::.*`,
    icon: `ic-aws-alexa-for-business.svg`
  },
  {
    type: 'AWS::AmazonMQ::.*',
    icon: 'ic-aws-mq.svg'
  },
  {
    type: 'AWS::ApiGateway::RestApi',
    icon: 'ic-aws-api-gateway.svg'
  },
  {
    type: 'AWS::ApiGateway::.*',
    icon: 'ic-aws-api-gateway.svg'
  },
  {
    type: 'AWS::ApplicationAutoScaling::.*',
    icon: 'ic-aws-auto-scaling.svg'
  },
  {
    type: 'AWS::AppStream::.*',
    icon: 'ic-aws-appstream.svg'
  },
  {
    type: 'AWS::AppSync::.*',
    icon: 'ic-aws-appsync.svg'
  },
  {
    type: 'AWS::Athena::.*',
    icon: 'ic-aws-athena.svg'
  },
  {
    type: 'AWS::AutoScaling::.*',
    icon: 'ic-aws-auto-scaling.svg'
  },
  {
    type: 'AWS::Batch::.*',
    icon: 'ic-aws-batch.svg'
  },
  {
    type: 'AWS::Budgets::.*',
    icon: 'ic-aws-budgets.svg'
  },
  {
    type: 'AWS::CertificateManager::.*',
    icon: 'ic-aws-certificate-manager.svg'
  },
  {
    type: 'AWS::Cloud9::.*',
    icon: 'ic-aws-cloud9.svg'
  },
  {
    type: 'AWS::CloudFormation::Stack',
    icon: 'ic-aws-cloudformation-stack.svg'
  },
  {
    type: 'AWS::CloudFormation::.*',
    icon: 'ic-aws-cloudformation.svg'
  },
  {
    type: 'AWS::CloudFront::StreamingDistribution',
    icon: 'ic-aws-cloudfront-streaming-distribution.svg'
  },
  {
    type: 'AWS::CloudFront::Distribution',
    icon: 'ic-aws-cloudfront-download-distribution.svg'
  },
  {
    type: 'AWS::CloudFront::.*',
    icon: 'ic-aws-cloudfront.svg'
  },
  {
    type: 'AWS::ServiceDiscovery::.*',
    icon: 'custom.svg'               // TODO: Not sure what the right icon is for ServiceDiscovery/CloudMap
  },
  {
    type: 'AWS::CloudTrail::.*',
    icon: 'ic-aws-cloudtrail.svg'
  },
  {
    type: 'AWS::CloudWatch::Alarm',
    icon: 'ic-aws-cloudwatch-alarm.svg'
  },
  {
    type: 'AWS::Events::.*',
    icon: 'ic-aws-cloudwatch-event-based.svg'
  },
  {
    type: 'AWS::CloudWatch::.*',
    icon: 'ic-aws-cloudwatch.svg'
  },
  {
    type: 'AWS::Logs::.*',
    icon: 'ic-aws-cloudwatch.svg'
  },
  {
    type: `AWS::CodeBuild::.*`,
    icon: 'ic-aws-code-build.svg'
  },
  {
    type: 'AWS::CodeCommit::.*',
    icon: 'ic-aws-code-commit.svg'
  },
  {
    type: 'AWS::CodeDeploy::.*',
    icon: 'ic-aws-code-deploy.svg'
  },
  {
    type: 'AWS::CodePipeline::.*',
    icon: 'ic-aws-code-pipeline.svg'
  },
  {
    type: 'AWS::Cognito::.*',
    icon: 'ic-aws-cognito.svg'
  },
  {
    type: 'AWS::Config::.*',
    icon: 'ic-aws-config.svg'
  },
  {
    type: 'AWS::DataPipeline::.*',
    icon: 'ic-aws-data-pipeline.svg'
  },
  {
    type: 'AWS::DAX::.*',
    icon: 'ic-aws-dnyamodb.svg'         // TODO: Is this right? DAX is DynamoDB Accelerator resources, and I couldn't find a better one
  },
  {
    type: 'AWS::DirectoryService::.*',
    icon: 'custom.svg'                            // TODO: Can't find icon
  },
  {
    type: 'AWS::DLM::.*',
    icon: 'custom.svg'                            // TODO: Can't find icon
  },
  {
    type: 'AWS::DMS::.*',
    icon: 'custom.svg'                            // TODO: Can't find icon
  },
  {
    type: 'AWS::DocDB::.*',
    icon: 'custom.svg'                            // TODO: Can't find icon
  },
  {
    type: 'AWS::DynamoDB::.*',
    icon: 'ic-aws-dnyamodb.svg'
  },
  {
    type: 'AWS::EC2::Instance',
    icon: 'ic-aws-ec2-instance.svg'
  },
  {
    type: 'AWS::EC2::.*',
    icon: 'ic-aws-ec2.svg'
  },
  {
    type: `AWS::ECR::.*`,
    icon: 'ic-aws-ecr.svg'
  },
  {
    type: 'AWS::ECS::.*',
    icon: 'ic-aws-ecs.svg'
  },
  {
    type: 'AWS::EFS::.*',
    icon: 'ic-aws-elastic-file-system.svg'
  },
  {
    type: 'AWS::EKS::.*',
    icon: 'ic-aws-eks.svg'
  },
  {
    type: 'AWS::ElastiCache::.*',
    icon: 'ic-aws-elasticache.svg'
  },
  {
    type: 'AWS::Elasticsearch::.*',
    icon: 'ic-aws-elasticsearch-service.svg'
  },
  {
    type: 'AWS::ElasticBeanstalk::Application',
    icon: 'ic-aws-elastic-beanstalk-application.svg'
  },
  {
    type: 'AWS::ElasticBeanstalk::.*',
    icon: 'ic-aws-elastic-beanstalk.svg'
  },
  {
    type: 'AWS::ElasticLoadBalancing.*',
    icon: 'ic-aws-elastic-load-balancing.svg'
  },
  {
    type: 'AWS::EMR::Cluster',
    icon: 'ic-aws-emr-cluster.svg'
  },
  {
    type: 'AWS::EMR::.*',
    icon: 'ic-aws-emr.svg'
  },
  {
    type: 'AWS::GameLift::.*',
    icon: 'ic-aws-gamelift.svg'
  },
  {
    type: 'AWS::Glue::.*',
    icon: 'ic-aws-glue.svg'
  },
  {
    type: 'AWS::GuardDuty::.*',
    icon: 'ic-aws-guard-duty.svg'
  },
  {
    type: 'AWS::Inspector::.*',
    icon: 'ic-aws-inspector.svg'
  },
  {
    type: 'AWS::IoT::Thing',
    icon: 'ic-aws-iot-thing-generic.svg'
  },
  {
    type: 'AWS::IoT::Policy',
    icon: 'ic-aws-iot-resource-policy.svg'
  },
  {
    type: 'AWS::IoT::.*',
    icon: 'ic-aws-iot-core.svg'                           // TODO: Is this right?
  },
  {
    type: 'AWS::IoT1Click::.*',
    icon: 'ic-aws-iot-1click.svg'
  },
  {
    type: 'AWS::IoTAnalytics::.*',
    icon: 'ic-aws-iot-analytics.svg'
  },
  {
    type: 'AWS::Kinesis::Stream',
    icon: 'ic-aws-kinesis.svg'
  },
  {
    type: 'AWS::KinesisFirehose::.*',
    icon: 'ic-aws-kinesis-firehose.svg'
  },
  {
    type: 'AWS::KinesisAnalytics::.*',
    icon: 'ic-aws-kinesis-data-analytics.svg'
  },
  {
    type: 'AWS::KMS::.*',
    icon: 'ic-aws-kms.svg'
  },
  {
    type: 'AWS::Lambda::(Alias|Function|Version)',
    icon: 'ic-aws-lambda-function.svg'
  },
  {
    type: 'AWS::Neptune::.*',
    icon: 'ic-aws-neptune.svg'
  },
  {
    type: 'AWS::OpsWorks::App',
    icon: 'ic-aws-opsworks-apps.svg'
  },
  {
    type: 'AWS::OpsWorks::Instance',
    icon: 'ic-aws-opsworks-instances.svg'
  },
  {
    type: 'AWS::OpsWorks::Layer',
    icon: 'ic-aws-opsworks-layers.svg'
  },
  {
    type: 'AWS::OpsWorks::Stack',
    icon: 'ic-aws-opsworks-stack2.svg'
  },
  {
    type: 'AWS::OpsWorks::.*',
    icon: 'ic-aws-opsworks.svg'
  },
  {
    type: 'AWS::OpsWorksCM::.*',
    icon: 'ic-aws-opsworks.svg'
  },
  {
    type: 'AWS::RDS::DBInstance',
    icon: 'ic-aws-rds-db-instance.svg'
  },
  {
    type: 'AWS::RDS::.*',
    icon: 'ic-aws-rds.svg'
  },
  {
    type: 'AWS::Redshift::.*',
    icon: 'ic-aws-redshift.svg'
  },
  {
    type: 'AWS::Route53::HostedZone',
    icon: 'ic-aws-route-53-hosted-zone.svg'
  },
  {
    type: 'AWS::Route53::.*',
    icon: 'ic-aws-route-53.svg'
  },
  {
    type: 'AWS::S3::.*',
    icon: 'ic-aws-s3-bucket.svg'
  },
  {
    type: 'AWS::SageMaker::.*',
    icon: 'ic-aws-sagemaker.svg'
  },
  {
    type: 'AWS::SecretsManager::.*',
    icon: 'ic-aws-secrets-manager.svg'
  },
  {
    type: 'AWS::ServiceCatalog::.*',
    icon: 'ic-aws-service-catalog.svg'
  },
  {
    type: 'AWS::SES::.*',
    icon: 'ic-aws-ses.svg'
  },
  {
    type: 'AWS::SDB::.*',
    icon: 'custom.svg'                        // TODO: Can't find icon
  },
  {
    type: 'AWS::SNS::.*',
    icon: 'ic-aws-sns.svg'
  },
  {
    type: 'AWS::SQS::.*',
    icon: 'ic-aws-sqs.svg'
  },
  {
    type: 'AWS::StepFunctions::.*',
    icon: 'ic-aws-step-functions.svg'
  },
  {
    type: 'AWS::SSM::Document',
    icon: 'ic-aws-systems-manager-documents.svg'
  },
  {
    type: 'AWS::SSM::.*',
    icon: 'ic-aws-systems-manager.svg'
  },
  {
    type: 'AWS::WAF::Rule',
    icon: 'ic-aws-waf-filtering-rule.svg'
  },
  {
    type: 'AWS::WAF::.*',
    icon: 'ic-aws-waf.svg'
  },
  {
    type: 'AWS::WAFRegional::Rule',
    icon: 'ic-aws-waf-filtering-rule.svg'
  },
  {
    type: 'AWS::WAFRegional::.*',
    icon: 'ic-aws-waf.svg'
  },
  {
    type: 'AWS::WorkSpaces::.*',
    icon: 'custom.svg'                            // TODO: Can't find icon
  },
  {
    type: 'AWS::Event::Rule',
    icon: 'ic-aws-cloudwatch-event-based.svg'      // TODO: is this right?
  },
  {
    type: '.*',
    icon: 'custom.svg'
  }
];

const lowPriorityList = [
  {
    type: `AWS::IAM::Role`,
    icon: `ic-aws-iam-role.svg`
  },
  {
    type: `AWS::IAM::.*`,
    icon: 'ic-aws-iam.svg'
  },
  {
    type: 'AWS::Lambda::(LayerVersionPermission|Permission)',
    icon: 'ic-aws-lambda.svg'
  }
];

// TODO: Implement Caching?

export const getCustomResourceLabel = (resourceId, resources, node) => {
  let cfResources = [];

  if (resources && resources[resourceId]) {
    const resource = resources[resourceId];
    if (!resource || resource.Type !== 'custom') {
      return;
    }

    cfResources = resource.Settings.CloudFormation.Resources;
  } else if (node) {
    cfResources = node.CloudFormation ? node.CloudFormation.Resources : [];
  }

  let { type } = getHighestPriorityResource(cfResources);
  return type || 'Anything Resource';
};

export const getCustomResourceName = (resourceId, resources, node) => {
  let cfResources = [];

  if (resources && resources[resourceId]) {
    const resource = resources[resourceId];
    if (!resource || resource.Type !== 'custom') {
      return;
    }

    cfResources = resource.Settings.CloudFormation.Resources;
  } else if (node) {
    cfResources = node.CloudFormation ? node.CloudFormation.Resources : [];
  }

  let { highestPriorityResourceId } = getHighestPriorityResource(cfResources);
  return highestPriorityResourceId || resourceId;
};

export const getCustomResourceIcon = (resourceId, resources, node) => {
  let cfResources = [];
  if (resources && resources[resourceId]) {
    const resource = resources[resourceId];
    if (!resource || resource.Type !== 'custom') {
      return;
    }

    cfResources = resource.Settings.CloudFormation.Resources;
  } else if (node) {
    cfResources = node.CloudFormation ? node.CloudFormation.Resources : [];
  }

  let { icon } = getHighestPriorityResource(cfResources);
  return icon || 'custom.svg';
};

const getHighestPriorityResource = (resources) => {
  let index = Infinity;
  let highestPriorityResourceId;
  let type;
  let icon;

  let resourceIds = Object.keys(resources);
  resourceIds.forEach(resourceId => {
    const resource = resources[resourceId];

    let matchIndex = lowPriorityList.findIndex(service => RegExp(service.type).test(resource.Type));
    if (matchIndex >= 0) {
      let lowPriorityIndex = matchIndex + highPriorityList.length;
      if (lowPriorityIndex < index) {
        index = lowPriorityIndex;
        highestPriorityResourceId = resourceId;
        type = resources[resourceId].Type;
        icon = lowPriorityList[matchIndex].icon;
      }
      return;
    }

    matchIndex = highPriorityList.findIndex(service => RegExp(service.type).test(resource.Type));

    if (matchIndex >= 0) {
      if (matchIndex < index) {
        index = matchIndex;
        highestPriorityResourceId = resourceId;
        type = resources[resourceId].Type;
        icon = highPriorityList[matchIndex].icon;
      }
    }
  });

  return {
    highestPriorityResourceId,
    type,
    icon,
    index
  };
};
