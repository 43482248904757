import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import crosstab from 'crosstab';
import * as states from '../../constants/stackCreatorStates';
import apiActions from '../../actions/api';
import appActions from '../../actions/app';
import ImportGitlabRepos from './ImportGitlabRepos';

const mapStateToProps = (props, ownProps) => {
  return createSelector(
    (appState) => appState.gitProviders,
    (appState) => appState.currentUser,
    (appState) => appState.account,
    (gitProviders, currentUser, account) => {
      return {
        gitProviders,
        currentUser,
        account,
        onClickCancel: ownProps.onClickCancel
      };
    }
  );
};

const mapDispatchToProps = {
  push: push,
  notifyUser: appActions.notifyUser,
  getGitRepos: apiActions.getGitRepos,
  getGitProjects: apiActions.getGitProjects,
  hideModal: appActions.hideModal
};

class ImportGitlabReposContainer extends Component {
  componentDidMount () {
    const {
      notifyUser,
      getGitRepos
    } = this.props;

    crosstab.once('StackeryOAuthComplete', (message) => {
      crosstab.broadcast('StackeryOAuthCloseWindow');
      this.props.notifyUser(message.data.info, message.data.type);

      if (message.data.status === 'success') {
        notifyUser('Git authorization successful', 'success');
        getGitRepos(this.props.gitProviders.gitlab.name);
      }
    });

    if (this.props.gitProviders.gitlab.state === states.NEW) {
      getGitRepos(this.props.gitProviders.gitlab.name);
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.gitProviders.gitlab.state === states.NEW) {
      this.props.getGitRepos(this.props.gitProviders.gitlab.name);
    }
  }

  render () {
    return (
      <ImportGitlabRepos {...this.props} />
    );
  }
}

ImportGitlabReposContainer.propTypes = {
  gitProviders: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired,
  notifyUser: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportGitlabReposContainer);
