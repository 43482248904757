import { select, putResolve, all, takeLatest, delay } from 'redux-saga/effects';
import { request } from '../utils/api';
import getAccountIdByArn from '../utils/getAccountIdByArn';
import * as states from '../constants/states';
import { types } from '../actions/aws';

function * refreshToken ({ environment, accountId }) {
  const path = environment ? `/environments/${environment}/monitoring` : `/aws/${accountId}/monitoring`;
  yield request(
    types.REFRESH_TOKEN,
    'GET',
    `/account/providers${path}`
  );
}

function * watchToken () {
  const _shouldRefresh = (state) => {
    const isAuthenticated = !!state.auth.token;
    const hasLinkedAws = state.currentUser.hasLinkedAws;
    const isAwsLoading = state.aws.state === states.LOADING;
    const didExpire = state.aws.expiration ? Math.ceil(Date.now()) >= state.aws.expiration : true;
    const isEnvironmentActive = !!state.stack.environment;
    const isRegionActive = !!state.stack.region;
    return isAuthenticated && hasLinkedAws && didExpire && !isAwsLoading && (isEnvironmentActive || isRegionActive);
  };

  while (true) {
    yield delay(1000);

    let shouldRefresh = yield select(_shouldRefresh);

    if (shouldRefresh) {
      let environment = yield select(state => state.stack.environment);
      let accountId = yield select(state => getAccountIdByArn(state.stack.id));

      yield putResolve({ type: types.REFRESH_TOKEN.REQUEST, environment, accountId });
    }
  }
}

export default function * aws () {
  yield all([
    watchToken(),
    takeLatest(types.REFRESH_TOKEN.REQUEST, refreshToken)
  ]);
}
