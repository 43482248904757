import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { replace } from 'connected-react-router';
import crosstab from 'crosstab';
import * as localStorage from '../../utils/localStorage';
import apiActions from '../../actions/api';
import appActions from '../../actions/app';
import * as gitProviderStates from '../../constants/gitProviderStates';
import * as states from '../../constants/states';
import Spinner from '../core/Spinner';

const mapStateToProps = () => {
  return createSelector(
    (appState) => appState.gitProviders,
    (appState) => appState.router,
    (gitProviders, router) => {
      return {
        gitProviders,
        router
      };
    }
  );
};

const mapDispatchToProps = {
  replace,
  getGitAuthToken: apiActions.getGitAuthToken,
  notifyUser: appActions.notifyUser
};

class OAuthContainer extends Component {
  constructor (props) {
    super(props);
    this.params = undefined;

    let path = props.match.params.provider;
    let gitProvider;

    let match = path.match(/(\S+).html/);
    if (match) {
      gitProvider = match[1];
    }

    this.state = {
      gitProvider,
      isResponseProcessed: false
    };

    this.handleUnsubscribed = this.handleUnsubscribed.bind(this);
  }

  componentDidMount () {
    if (this.props.gitProviders.state === states.OKAY) {
      this.getGitAuthToken();
    }

    crosstab.on('StackeryOAuthCloseWindow', () => {
      window.clearTimeout(this.timeout);
      window.close();
    });

    crosstab.on('StackeryOAuthComplete', (message) => {
      let key = localStorage.getItem('crosstab.TABS_KEY');
      key = key ? JSON.parse(key) : undefined;

      // If the localstorage MASTER_TAB id === origin id, then the request was likely from the CLI (no calling tab)
      if (key && key.data && key.data.MASTER_TAB.id === message.origin) {
        this.handleUnsubscribed(message);
      } else {
        this.timeout = window.setTimeout(() => { this.handleUnsubscribed(message); }, 5000);
      }
    });
  }

  componentDidUpdate (prevProps, prevState) {
    const {
      gitProviders
    } = this.props;

    const {
      gitProvider,
      isResponseProcessed
    } = this.state;

    const isResponseSuccessful = gitProviders[gitProvider].state === gitProviderStates.AUTHORIZED;
    const isResponseFailed = gitProviders[gitProvider].state === gitProviderStates.UNAUTHORIZED || gitProviders[gitProvider].state === gitProviderStates.FAILED;

    let message;

    if (prevProps.gitProviders.state === states.LOADING && gitProviders.state === states.OKAY) {
      this.getGitAuthToken();
    }

    if (!isResponseProcessed && (isResponseSuccessful || isResponseFailed)) {
      this.setState({ isResponseProcessed: true });
      this.clearCrosstabKeys();

      if (isResponseSuccessful) {
        message = { status: 'success', provider: gitProvider, info: `Authorization with ${gitProvider} was successful`, type: 'info' };

        try {
          crosstab.broadcast('StackeryOAuthComplete', message);
        } catch (err) {
          this.handleUnsubscribed(message);

          // Sometimes crosstab will fail to work, but it's not the end of the world
          console.error(`Cross-tab communication failure: ${err.stack}`);
        }
      } else {
        message = { status: 'failure', provider: gitProvider, info: `Authorization with ${gitProvider} failed`, type: 'error' };

        try {
          crosstab.broadcast('StackeryOAuthComplete', message);
        } catch (err) {
          this.handleUnsubscribed(message);

          // Sometimes crosstab will fail to work, but it's not the end of the world
          console.error(`Cross-tab communication failure: ${err.stack}`);
        }
      }
    }
  }

  handleUnsubscribed (message) {
    const {
      info,
      type
    } = message.data || message;

    // There's potentially no subscriber for the completion event...
    // (e.g. an existing Stackery tab is a page that doesn't have an Oauth requirement / Oauth was triggered by CLI)
    this.props.notifyUser(
      info,
      type
    );
    this.props.replace('/');
  }

  getParams () {
    return this.props.router.location.search
      .replace(/(^\?)/, '')
      .split('&')
      .reduce(function (params, param) {
        if (!param) { return params; }

        param = param.split('=');
        params[param[0]] = param[1];
        return params;
      }, {});
  }

  getGitAuthToken () {
    this.params = this.getParams();
    this.props.getGitAuthToken(this.state.gitProvider, this.params.code, this.params.state, this.params.oauth_token, this.params.oauth_verifier);
  }

  clearCrosstabKeys () {
    // Hack to prevent crosstab from freezing the tab
    // These localStorage keys are often set in a desktop browser (to true & false, respectively) when they _should_ only affect mobile browsers
    // https://github.com/tejacques/crosstab/blob/master/src/crosstab.js#L27
    localStorage.removeItem('crosstab.FROZEN_TAB_ENVIRONMENT');
    localStorage.removeItem('crosstab.SUPPORTED');
  }

  render () {
    return <Spinner />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OAuthContainer);
