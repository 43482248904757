import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import style from './Tabs.css';

class Tabs extends Component {
  constructor (props) {
    super(props);

    this.state = {
      activeIndex: props.initialIndex || 0
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick (event, i) {
    event.preventDefault();
    event.stopPropagation();

    this.setState({ activeIndex: i });
  }

  render () {
    const isLightTheme = this.props.theme === 'light';
    const isDarkTheme = this.props.theme === 'dark';

    return (
      <div className={classNames(style.container, isLightTheme && style.light, isDarkTheme && style.dark)}>
        <div
          className={style.nav}
          style={{ gridTemplateColumns: this.props.items.map(() => '1fr').join(' ') }}
          role='tablist'
        >
          {this.props.items.map((item, i) => {
            const isActive = this.state.activeIndex === i;

            return (
              <button
                key={`tab-${i}`}
                className={classNames(style.tab, isActive && style.tabActive)}
                tabIndex={isActive ? '-1' : '0'}
                role='tab'
                aria-selected={isActive}
                aria-controls={`panel-${i}`}
                onClick={(event) => this.handleClick(event, i)}
              >
                {item.tab}
              </button>
            );
          })}
        </div>
        <div className={style.content}>
          {this.props.items.map((item, i) => {
            const Panel = item.panel;
            const isActive = this.state.activeIndex === i;

            return (
              <div
                key={`panel-${i}`}
                className={classNames(style.panel, isActive && style.panelActive)}
                style={{ padding: isActive ? this.props.padding : 0 }}
                role='tabpanel'
                aria-labelledby={`panel-${i}`}
                aria-hidden={!isActive}
                tabIndex='0'
              >
                <Panel />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

Tabs.defaultProps = {
  theme: 'dark',
  padding: '0px'
};

Tabs.propTypes = {
  items: PropTypes.array.isRequired,
  theme: PropTypes.string.isRequired,
  padding: PropTypes.string.isRequired,
  initialIndex: PropTypes.number
};

export default Tabs;
