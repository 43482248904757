import { types } from '../actions/api';
import * as states from '../constants/states';

export const initialState = {
  state: states.NEW,
  users: [],
  org: {}
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case types.GET_ORGANIZATION_USERS.FAILED:
      return {
        ...state,
        error: action.error,
        state: states.FAILED
      };
    case types.GET_ORGANIZATION_USERS.REQUEST:
      return {
        ...state,
        state: states.LOADING
      };
    case types.GET_ORGANIZATION_USERS.SUCCESS:
      return {
        ...state,
        state: states.OKAY,
        ...action.data
      };
    default:
      return state;
  }
};
