import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import semver from 'semver';
import apiActions from '../../actions/api';
import * as states from '../../constants/states';
import * as providerStates from '../../constants/providerStates';
import CreateEnvironment from './CreateEnvironment';

const mapStateToProps = () => {
  return createSelector(
    (appState) => appState.account,
    (account) => {
      return {
        account,
        provider: account.provider,
        isProviderLimitMaxed: account.plan.providersLimit != null && account.providers.length >= account.plan.providersLimit
      };
    }
  );
};

const mapDispatchToProps = {
  createEnvironment: apiActions.createEnvironment
};

const initialState = {
  selectedEnvironment: null,
  selectedRegion: null,
  selectedAccountId: '',
  hasSelectedAddAccount: false
};

class CreateEnvironmentContainer extends Component {
  constructor (props) {
    super(props);

    this.state = {...initialState};

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidUpdate (prevProps, prevState) {
    const {
      selectedAccountId
    } = this.state;

    const {
      deploymentStatus
    } = this.props.provider;

    const {
      providers,
      region
    } = this.props.account;

    if (selectedAccountId && selectedAccountId !== prevState.selectedAccountId) {
      this.setState({
        selectedAccountId,
        selectedRegion: region
      });
    }

    // If the user successfully links an AWS account, select that account
    if (providers.length > prevProps.account.providers.length && deploymentStatus === providerStates.SUCCESSFUL) {
      this.setState({
        ...initialState,
        selectedRegion: providers[providers.length - 1].region,
        selectedAccountId: providers[providers.length - 1].accountId
      });
    }

    // Handle AWS account linking failure
    if (prevProps.provider.deploymentStatus === providerStates.IN_PROGRESS && deploymentStatus === providerStates.FAILED) {
      // User will be notified of error by the LinkAwsButtonContainer, then reset state
      this.setState({
        ...initialState
      });
    }
  }

  handleInputChange (event) {
    const {
      name,
      value
    } = event.target;

    if (value === 'addAccount') {
      this.setState({
        ...initialState,
        hasSelectedAddAccount: true
      });
    } else {
      this.setState({
        [name]: value,
        hasSelectedAddAccount: false
      });
    }
  }

  handleSubmit (event) {
    event.preventDefault();
    this.props.createEnvironment(this.state.selectedEnvironment, this.state.selectedRegion, this.state.selectedAccountId);
  }

  render () {
    const props = this.props;
    const isVersionOk = semver.satisfies(props.account.version, '>= 1.4.0', { includePrerelease: true });
    const {
      selectedAccountId,
      selectedEnvironment,
      hasSelectedAddAccount
    } = this.state;

    return (
      <CreateEnvironment
        account={props.account}
        hasError={props.hasError}
        hasSelectedAddAccount={hasSelectedAddAccount}
        isProviderLimitMaxed={props.isProviderLimitMaxed}
        isLoading={this.props.state === states.LOADING}
        isVersionOk={isVersionOk}
        onClickCancel={props.onClickCancel}
        onInputChange={this.handleInputChange}
        onSubmit={this.handleSubmit}
        selectedAccountId={selectedAccountId}
        selectedEnvironment={selectedEnvironment}
      />
    );
  }
}

CreateEnvironmentContainer.propTypes = {
  account: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateEnvironmentContainer);
