import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { push } from 'connected-react-router';
import * as states from '../../constants/states';
import authActions from '../../actions/auth';
import ForgotPassword from './ForgotPassword';

const mapStateToProps = () => {
  return createSelector(
    (appState) => appState.forgotPassword,
    (forgotPassword) => {
      return {
        state: forgotPassword.state,
        error: forgotPassword.error
      };
    }
  );
};

const mapDispatchToProps = {
  forgotPassword: authActions.forgotPassword,
  push
};

class ForgotPasswordContainer extends Component {
  constructor (props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentDidUpdate (prevProps) {
    if (this.props.state === states.OKAY) {
      this.props.push(`/reset-password?email=${encodeURIComponent(this.email)}`);
    }
  }

  handleInputChange (event) {
    this[event.target.name] = event.target.value;
  }

  handleFormSubmit (evt) {
    evt.preventDefault();
    this.props.forgotPassword(this.email);
  }

  render () {
    return (
      <ForgotPassword
        error={this.props.error}
        isLoading={this.props.state === states.LOADING}
        didReset={this.props.state === states.OKAY}
        onInputChange={this.handleInputChange}
        onFormSubmit={this.handleFormSubmit}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordContainer);
