/* onTransition is a helper function to be called in componentDidUpdate()
 * functions. It helps simplify showing notification messages and pushing to a
 * new url. Example:
 *
 * import { FAILED, OKAY } from '../constants/states';
 *
 * componentDidupdate(prevProps) {
 *   onTransition(prevProps, this.props, 'newEnvironment', {
 *     [OKAY]: {
 *       notify: {
 *         message: `Successfully created environment ${this.environment}`,
 *         timeoutInMillis: 5000
 *       },
 *       push: `/environments/${this.environment}`
 *     },
 *     [FAILED]: {
 *       notify: {
 *         message: `Failed to create environment ${this.environment}`,
 *         level: 'error'
 *       }
 *     }
 *   })
 * }
 */
export function onTransition (
  prevProps,
  currentProps,
  reducerName,
  actions,
  statePropertyName = 'state'
) {
  const currentState = prevProps[reducerName][statePropertyName];
  const nextState = currentProps[reducerName][statePropertyName];

  if (currentState === nextState) return;

  const action = actions[nextState];

  if (!action) return;

  if ('notify' in action) {
    if (!('notifyUser' in currentProps)) {
      console.warn(
        `A component is missing the notifyUser action in its connected props when calling utils.onTransition. Unable to notify message:\n\n${action
          .notify.message}`
      );
    } else {
      currentProps.notifyUser(
        action.notify.message,
        action.notify.level
      );
    }
  }

  if ('push' in action) {
    if (!('push' in currentProps)) {
      console.warn(
        `A component is missing the push action in its connected props when calling utils.onTransition. Unable to push to ${action.push}`
      );
    } else {
      currentProps.push(action.push);
    }
  }

  if ('hideModal' in action) {
    currentProps.hideModal();
  }
}
