import React, { Fragment } from 'react';
import StackRepoFormContainer from '../stackRepoForm/StackRepoFormContainer';

const CreateRepo = (props) => {
  return (
    <Fragment>
      <h1>Save with Git</h1>
      {props.stack.isGitless &&
        <p>Save stack changes by creating a new repository with your Git provider</p>
      }
      {!props.stack.isGitless &&
        <p>Save stack changes by cloning the remote repository with your Git provider</p>
      }
      <StackRepoFormContainer message={props.message} />
    </Fragment>
  );
};

CreateRepo.propTypes = {
};

export default CreateRepo;
