import React from 'react';
import CodeBlock from '../core/CodeBlock';

const UnlinkAws = (props) => {
  return (
    <div>
      <h1>Unlink AWS Account</h1>
      <p>To unlink an AWS account, use the <a href='https://docs.stackery.io/docs/using-stackery/cli/' target='_blank' title='Stackery CLI documentation' rel='noopener noreferrer'>Stackery CLI</a> command:</p>
      <CodeBlock
        lines={[
          `stackery aws unlink --aws-profile <profile>`
        ]}
      />
    </div>
  );
};

export default UnlinkAws;
