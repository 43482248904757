import React from 'react';
import PropTypes from 'prop-types';
import VisualContainer from '../stackEdit/VisualContainer';
import style from './Workspace.css';

const Workspace = props => {
  return (
    <div className={style.container}>
      <VisualContainer {...props} />
    </div>
  );
};

Workspace.propTypes = {
  match: PropTypes.object.isRequired
};

export default Workspace;
