import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import getQueryValue from '../../utils/getQueryValue';
import getStackFormat from '../../utils/getStackFormat';
import selectors from '../../selectors';
import * as modes from '../../constants/modes';
import appActions from '../../actions/app';
import iotNotificationsActions from '../../actions/iotNotifications';
import publicEditorActions from '../../actions/publicEditor';
import canvasActions from '../../actions/canvas';
import PublicEditor from './PublicEditor';

const allowedOrigins = [
  new RegExp('https://www.stackery.io'),
  new RegExp('https://stackery.io'),
  /^https:\/\/[a-zA-Z0-9]+\.cloudfront\.net$/,
  // Allow localhost and cloudfront hosted origins for non-prod environments
  ...(process.env.REACT_APP_ENV === 'prod'
    ? []
    : [
      new RegExp('http://localhost:8000')
    ]
  )
];

const mapStateToProps = () => {
  return createSelector(
    (appState) => appState.stack,
    (appState) => appState.templateEditor,
    selectors.nodeTypes,
    (stack, templateEditor, nodeTypes) => {
      return {
        stack,
        templateEditor,
        nodeTypes
      };
    }
  );
};

const mapDispatchToProps = {
  getRepoContents: publicEditorActions.getRepoContents,
  setStackMode: appActions.setStackMode,
  parseStackTemplate: appActions.parseStackTemplate,
  startResources: canvasActions.startResources,
  stopIotNotifications: iotNotificationsActions.stopIotNotifications,
  stopResources: canvasActions.stopResources,
  setScale: canvasActions.setScale
};

class PublicEditorContainer extends Component {
  constructor (props) {
    super(props);

    this.state = {
      targetOrigin: window.location.origin,
      targetWindow: getQueryValue(window.location.search, 'sync'),
      isStandalone: false,
      isDesignMode: false // Hack for a css adjustment :(
    };

    this.lastReceivedTemplate = undefined;
    this.lastSentTemplate = undefined;

    this.postMessage = this.postMessage.bind(this);
    this.receiveMessage = this.receiveMessage.bind(this);

    window.addEventListener('message', this.receiveMessage);
  }

  postMessage (message) {
    const {
      targetOrigin,
      targetWindow
    } = this.state;

    if (!targetWindow || !targetOrigin || this.lastReceivedTemplate === message.template) { return; }

    try {
      window.parent.frames[targetWindow].postMessage(message, targetOrigin);
      this.lastSentTemplate = message.template;
    } catch (err) {
      console.error(err);
    }
  }

  receiveMessage (event) {
    const {
      targetOrigin,
      targetWindow
    } = this.state;

    const allowedOrigin = allowedOrigins.some(pattern => pattern.test(event.origin));

    if (!allowedOrigin && (event.origin !== targetOrigin || event.source.name !== targetWindow)) {
      return;
    }

    const data = event.data;

    if (data && data.template !== undefined && this.lastSentTemplate !== data.template) {
      // This order of operation is important.
      // parseStackTemplate() will modify the template string and it's critical to set lastReceivedTemplate before the sequence starts...
      // ...so it's not receiving its own changes as the parse sequence progresses
      this.lastReceivedTemplate = data.template;
      this.props.parseStackTemplate(data.template, data.format);
    }
  }

  componentDidMount () {
    this.props.stopIotNotifications();
    this.props.setStackMode(modes.PUBLIC);
    this.props.startResources();

    window.parent.postMessage({ messageType: 'loaded', src: window.location.href }, '*');

    const owner = getQueryValue(window.location.search, 'owner');
    const repo = getQueryValue(window.location.search, 'repo');
    const file = getQueryValue(window.location.search, 'file');
    const ref = getQueryValue(window.location.search, 'ref');
    const standalone = getQueryValue(window.location.search, 'standalone');

    this.setState({
      isStandalone: standalone !== undefined,
      isDesignMode: window.location.href.includes('/editor/design')
    });

    if (owner && repo) {
      this.props.getRepoContents(owner, repo, file, ref);
    } else {
      this.props.parseStackTemplate('', 'SAM');
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const {
      templateEditor
    } = this.props;

    if (templateEditor.rawTemplate !== prevProps.templateEditor.rawTemplate) {
      this.postMessage({ template: templateEditor.rawTemplate, format: getStackFormat(templateEditor.rawTemplate) });
    }
  }

  componentWillUnmount () {
    this.props.stopResources();
    window.removeEventListener('message', this.receiveMessage);
  }

  render () {
    return (
      <PublicEditor {...this.props} {...this.state} />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PublicEditorContainer);
