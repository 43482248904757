export default {
  STACKERY_AUTH_FAILURE: 'STACKERY_AUTH_FAILURE',
  GIT_NO_TOKEN: 'GIT_NO_TOKEN',
  GIT_BAD_TOKEN: 'GIT_BAD_TOKEN',
  GIT_AUTH_READ_FAILURE: 'GIT_AUTH_READ_FAILURE',
  GIT_AUTH_WRITE_FAILURE: 'GIT_AUTH_WRITE_FAILURE',
  GIT_REPO_NOT_FOUND: 'GIT_REPO_NOT_FOUND',
  TEMPLATE_YAML_FORMAT_ERROR: 'TEMPLATE_YAML_FORMAT_ERROR',
  TEMPLATE_PARSE_ERROR: 'TEMPLATE_PARSE_ERROR'
};
