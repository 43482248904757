import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import style from './AddButton.css';

const AddButton = props => {
  return (
    <button className={style.container} tabIndex='0' onClick={props.onClick ? props.onClick : undefined}>
      <span><Icon name='plus' className={style.iconPlus} />{props.text}</span>
    </button>
  );
};

AddButton.defaultProps = {
  size: 'medium'
};

AddButton.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  onClick: PropTypes.func
};

export default AddButton;
