import React from 'react';
import PropTypes from 'prop-types';
import FormRow from '../core/FormRow';
import Select from '../core/Select';

const BranchSelector = props => {
  const {
    stack,
    stackBranches,
    isBranchesFailed
  } = props;

  return (
    <FormRow label='Branch'>
      <Select
        isDisabled={isBranchesFailed}
        onChange={props.onBranchChange}
        value={stack.branch}
      >
        {!stack.branch &&
          <option>-----</option>
        }
        {stack.branch && !stackBranches.data.length &&
          <option>{stack.branch}</option>
        }
        {stackBranches.data.map(branch => {
          return (
            <option key={branch.name} value={branch.name}>
              {branch.name}
            </option>
          );
        })}
        {stack.isRepoOwner &&
          <option value='Create Branch...'>
            Create Branch...
          </option>
        }
      </Select>
    </FormRow>
  );
};

BranchSelector.defaultProps = {
  showCreateBranch: true,
  showLabel: true
};

BranchSelector.propTypes = {
  stack: PropTypes.object,
  stackBranches: PropTypes.object,
  isBranchesFailed: PropTypes.bool,
  showLabel: PropTypes.bool,
  showCreateBranch: PropTypes.bool
};

export default BranchSelector;
