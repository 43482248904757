import { types } from '../actions/api';
import * as states from '../constants/states';

export const initialState = {
  state: states.NEW,
  keys: []
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case types.GET_CONFIG_KEYS.REQUEST:
      return { keys: [], state: states.LOADING };
    case types.GET_CONFIG_KEYS.SUCCESS:
      return { keys: action.data, state: states.OKAY };
    case types.GET_CONFIG_KEYS.FAILURE:
      return {
        ...state,
        state: states.FAILED,
        error: action.error.message.message
      };
    default:
      return state;
  }
};
