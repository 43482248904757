import React from 'react';
import ButtonSet from '../core/ButtonSet';

const CanvasErrors = (props) => {
  const {
    errors,
    style
  } = props;

  const verbiage = errors.length > 1 ? ['are', 'errors'] : ['is', 'error'];

  return (
    <div>
      <h2>There {verbiage[0]} {errors.length} {verbiage[1]} in your canvas:</h2>
      <ul className={style.list}>
        {errors.map((err, i) => <li className={style.listItem} key={`error_${i}`}>{err}</li>)}
      </ul>
      <ButtonSet
        primaryButton={{
          text: 'Go Back',
          onClick: props.onClickCancel
        }}
      />
    </div>
  );
};

export default CanvasErrors;
