import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../core/Icon';
import style from './HelpLink.css';

const HelpLink = (props) => {
  return (
    <a className={style.container} href={props.link} id={props.id} target='_blank' title={props.title} rel='noopener noreferrer'>
      <Icon name='questionCircle' className={style.iconCircle} />
    </a>
  );
};

HelpLink.propTypes = {
  link: PropTypes.string.isRequired
};

export default HelpLink;
