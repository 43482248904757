import React, { Component } from 'react';
import * as states from '../../constants/states';
import Form from '../core/Form';
import ButtonSet from '../core/ButtonSet';

class DeleteEnvrionmentSecret extends Component {
  constructor (props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit (event) {
    event.preventDefault();
    this.props.onSubmit(
      this.props.owner,
      this.props.environment,
      this.props.secret
    );
  }

  render () {
    return (
      <Form onSubmit={this.handleSubmit}>
        <h1>Delete Environment Secret</h1>
        <p>Are you sure you want to delete the secret <strong>{this.props.secret}</strong>?</p>
        <ButtonSet
          primaryButton={{
            text: 'Delete',
            type: 'submit',
            modifier: 'warning',
            id: 'environment-secret-delete-submit-button',
            isLoading: this.props.state === states.LOADING
          }}
          secondaryButton={{
            text: 'Cancel',
            onClick: this.props.onClickCancel
          }}
        />
      </Form>
    );
  }
}

DeleteEnvrionmentSecret.propTypes = {
};

export default DeleteEnvrionmentSecret;
