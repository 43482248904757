import React from 'react';
import LinkButton from '../core/LinkButton';
import style from './DemoBanner.css';

const DemoBanner = props => {
  return (
    <div className={style.container}>
      You are viewing a demo account.
      To setup your own Stackery account
      <LinkButton to='/demo/stop' text='Exit Demo Mode' display='inline' />
    </div>
  );
};

export default DemoBanner;
