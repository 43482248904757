import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import * as states from '../../constants/states';
import apiActions from '../../actions/api';
import Activity from './Activity';

const mapStateToProps = () => {
  return createSelector(
    (appState) => appState.account,
    (account) => {
      return {
        activity: account.usage,
        context: window.location.pathname.split('/')[1],
        isLoading: account.usage.state === states.NEW || account.usage.state === states.LOADING
      };
    }
  );
};

const mapDispatchToProps = {
  getAccountUsage: apiActions.getAccountUsage
};

class ActivityContainer extends Component {
  componentDidMount () {
    // Set initial startTime to the beginning of (internet) time and endTime to today
    const startTime = new Date(1970, 0, 1).toISOString();
    const endTime = new Date().toISOString();
    const limit = this.props.limit || 10;

    this.props.getAccountUsage(startTime, endTime, 1, limit, this.props.filterBy);
  }

  render () {
    return (
      <Activity {...this.props} />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivityContainer);
