import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { portRadius } from '../../constants/canvas';
import actions from '../../actions/canvas';
import { canBeginIntegrationFrom, canAddIntegration } from '../../resources/addIntegration';
import style from './Port.css';
import isComputeResource from '../../utils/isComputeResource';

class Port extends Component {
  constructor (props) {
    super(props);

    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
  }

  canBeginWire () {
    const node = this.props.node;
    const nodeType = this.props.type;
    const portType = this.props.port === undefined ? 'input' : 'output';
    const port = this.props.port;

    if (this.props.format === 'stackery') {
      return !nodeType.onlinkbegin || nodeType.onlinkbegin.call(node, portType, port);
    }

    if (portType === 'input' || isComputeResource(this.props.node.type)) {
      return true;
    }

    let resourceId = this.props.node.id;
    const facetType = this.props.node.facetType;
    const facetId = facetType && this.props.node.facet.Id;

    if (facetType) {
      resourceId = this.props.node.sourceId;
    }

    const { isValidIntegration } = canBeginIntegrationFrom(this.props.resources, resourceId, facetType, facetId);

    return isValidIntegration;
  }

  canEndWire () {
    const node = this.props.node;
    const portType = this.props.port === undefined ? 'input' : 'output';
    const otherNode = this.props.nodes.find(node => this.props.wiring.node === node.id);
    const otherPortType = this.props.wiring.port === undefined ? 'input' : 'output';
    const isFormatStackery = this.props.format === 'stackery';

    return (
      node !== otherNode &&
      portType !== otherPortType &&
      (
        (!isFormatStackery && this.canConnect(portType, otherNode)) ||
        (isFormatStackery && this.canConnectStackery(portType, otherNode, otherPortType))
      )
    );
  }

  canConnectStackery (portType, otherNode, otherPortType) {
    const node = this.props.node;
    const nodeType = this.props.type;
    const port = this.props.port;
    const otherPort = this.props.wiring.port;
    const otherNodeType = this.props.nodeTypes[otherNode.type];

    return (!nodeType.onlinkend ||
      nodeType.onlinkend.call(
        node,
        portType,
        port,
        otherNode,
        otherPortType,
        otherPort
      )
    ) &&
    (!otherNodeType.onlinkend ||
      otherNodeType.onlinkend.call(
        otherNode,
        otherPortType,
        otherPort,
        node,
        nodeType,
        port
      )
    );
  }

  canConnect (portType, otherNode) {
    let targetId;
    let sourceType;
    let sourceId;
    let facetType;
    let facetId;

    if (portType === 'input') {
      targetId = this.props.node.id;
      if (otherNode.type === 'facet') {
        sourceType = otherNode.sourceType;
        sourceId = otherNode.sourceId;
        facetType = otherNode.facetType;
        facetId = facetType && otherNode.facet.Id;
      } else {
        sourceType = otherNode.type;
        sourceId = otherNode.id;
      }
    } else {
      targetId = otherNode.id;
      if (this.props.node.type === 'facet') {
        sourceType = this.props.node.sourceType;
        sourceId = this.props.node.sourceId;
        facetType = this.props.node.facetType;
        facetId = facetType && this.props.node.facet.Id;
      } else {
        sourceType = this.props.node.type;
        sourceId = this.props.node.id;
      }
    }

    const { isValidIntegration } = canAddIntegration(this.props.resources, sourceId, targetId, facetType, facetId);

    return (isComputeResource(sourceType) && (!facetType || facetType === 'references')) || isValidIntegration;
  }

  handleMouseEnter () {
    if (!this.props.editable) return;

    if (
      (!this.props.wiring && this.canBeginWire()) ||
      (this.props.wiring && this.canEndWire())
    ) { this.props.portHighlight(this.props.node.id, this.props.port); }
  }

  handleMouseLeave (event) {
    if (!this.props.editable) return;

    this.props.portUnhighlight();
  }

  handleMouseDown (event) {
    if (!this.props.editable) return;

    event.stopPropagation();

    const canvas = document.getElementById('canvas');
    const canvasRect = canvas.getBoundingClientRect();

    const x = (event.clientX + canvas.scrollLeft) - canvasRect.left;
    const y = (event.clientY + canvas.scrollTop) - canvasRect.top;

    if (this.canBeginWire()) {
      this.props.portMouseDown(this.props.node.id, this.props.port, x, y);
    }
  }

  render () {
    return (
      <g className={classnames(this.props.isSelected && style.isSelected)}>
        <circle
          className={style.background}
          cx={this.props.location.x}
          cy={this.props.location.y}
          r={portRadius + 3}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          onMouseDown={this.handleMouseDown}
        />
        <circle
          className={style.port}
          cx={this.props.location.x}
          cy={this.props.location.y}
          r={portRadius}
        />
        <circle
          className={style.portInner}
          cx={this.props.location.x}
          cy={this.props.location.y}
          r={portRadius / 2}
        />
      </g>
    );
  }
}

export default connect(
  (state, props) => ({
    type: props.nodeTypes[props.node.type],
    wiring: state.canvas.wiring,
    isSelected:
      state.canvas.highlightedPort &&
      props.node.id === state.canvas.highlightedPort.node &&
      props.port === state.canvas.highlightedPort.port,
    format: props.format,
    resources: state.formation.resources
  }),
  {
    portHighlight: actions.portHighlight,
    portUnhighlight: actions.portUnhighlight,
    portMouseDown: actions.portMouseDown
  }
)(Port);
