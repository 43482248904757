import React, { Fragment } from 'react';
import StackLink from './StackLink';
import EnvironmentLink from './EnvironmentLink';

const StackUndeployedEvent = (props) => {
  const context = props.context === 'environments' ? null : <Fragment>in <EnvironmentLink {...props} /></Fragment>;

  return <span><StackLink {...props} /> deactivated {context} by {props.userEmail}</span>;
};

export default StackUndeployedEvent;
