import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import style from './Table.css';

const Table = (props) => {
  return (
    <table
      id={props.id}
      className={classNames(
        props.className,
        props.hasBorder && style.hasBorder,
        props.isStriped && style.isStriped,
        props.isEmpty && style.isEmpty
      )}
    >
      {props.children}
    </table>
  );
};

Table.defaultProps = {
  className: style.container,
  hasBorder: true
};

Table.propTypes = {
  className: PropTypes.string,
  hasBorder: PropTypes.bool,
  isStriped: PropTypes.bool
};

export default Table;
