import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router';
import VisualContainer from './VisualContainer';
import StackEditNav from './StackEditNav';
import TemplateContainer from './TemplateContainer';
import style from './StackEdit.css';

const StackEdit = props => {
  const {
    match,
    isGitless
  } = props;

  return (
    <div className={style.container}>
      <StackEditNav {...props} />
      <Switch>
        <Route exact path='/stacks/:owner/:stack/edit/:branch/visual' component={VisualContainer} />
        <Route exact path='/stacks/:owner/:stack/edit/:branch/visual/:resourceId' component={VisualContainer} />
        <Route exact path='/stacks/:owner/:stack/edit/visual/:resourceId' component={VisualContainer} />
        <Route exact path='/stacks/:owner/:stack/edit/:branch/template' component={TemplateContainer} />

        <Route path='/stacks/:owner/:stack/edit/visual' component={VisualContainer} />
        <Route path='/stacks/:owner/:stack/edit/template' component={TemplateContainer} />

        {isGitless &&
          <Redirect to={`/stacks/${match.params.owner}/${match.params.stack}/edit/visual`} />
        }
        {!isGitless && match.params.branch &&
          <Redirect to={`/stacks/${match.params.owner}/${match.params.stack}/edit/${match.params.branch}/${match.params.mode || 'visual'}`} />
        }
        <Redirect to={`/stacks/${match.params.owner}/${match.params.stack}/edit`} />
      </Switch>
    </div>
  );
};

StackEdit.propTypes = {
  gitProviders: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  stack: PropTypes.object.isRequired,
  onCreateBranchClick: PropTypes.func.isRequired,
  onRefreshClick: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  setStackMode: PropTypes.func
};

export default StackEdit;
