import React from 'react';
import DocumentTitle from 'react-document-title';
import CenteredContainer from '../core/CenteredContainer';
import MessageBox from '../core/MessageBox';
import StatusMessage from '../core/StatusMessage';
import Link from '../core/Link';
import Icon from '../core/Icon';
import Form from '../core/Form';
import FormRow from '../core/FormRow';
import Input from '../core/Input';
import Button from '../core/Button';
import style from './ForgotPassword.css';

const ForgotPassword = props => {
  return (
    <CenteredContainer className={style.container}>
      <DocumentTitle title='Stackery Forgot Password' />
      <Icon name='stackery-logo' className={style.logo} />
      <MessageBox alignCenter>
        <p>Enter your email below and we'll send you a link to reset your password.</p>
        {props.error &&
          <StatusMessage type='error' text={props.error.message} />
        }
      </MessageBox>
      <Form onSubmit={props.onFormSubmit}>
        <FormRow>
          <Input
            type='text'
            name='email'
            placeholder='email'
            onChange={props.onInputChange}
          />
        </FormRow>
        <Button
          type='submit'
          text='Send Email'
          isLoading={props.isLoading}
        />
        <MessageBox alignCenter>
          <p>Need an account?{' '} <Link href='https://www.stackery.io/sign-up/' target='_blank'>Sign Up!</Link></p>
          <Link to='/sign-in'>Sign In</Link>
        </MessageBox>
      </Form>
    </CenteredContainer>
  );
};

ForgotPassword.propTypes = {
};

export default ForgotPassword;
