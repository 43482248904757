import React from 'react';
import PropTypes from 'prop-types';
import Link from '../core/Link';
import CenteredContainer from '../core/CenteredContainer';
import DocumentTitle from 'react-document-title';
import MessageBox from '../core/MessageBox';
import StatusMessage from '../core/StatusMessage';
import HeadingGroup from '../core/HeadingGroup';
import Form from '../core/Form';
import FormRow from '../core/FormRow';
import Input from '../core/Input';
import Icon from '../core/Icon';
import Button from '../core/Button';
import style from './SignUp.css';

const SignUp = props => {
  const {
    isLoading,
    error,
    handleInputChange,
    handleSubmit
  } = props;

  return (
    <CenteredContainer className={style.container}>
      <DocumentTitle title='Stackery Create Account' />
      <Icon name='stackery-horizontal' transform='scale(2)' className={style.logo} />
      <HeadingGroup
        alignCenter
        primaryText='Create an Account'
        level={1}
      />
      <MessageBox alignCenter>
        {error && (
          <StatusMessage type='error' text={error} />
        )}
      </MessageBox>
      <Form onSubmit={handleSubmit}>
        <FormRow label='Email'>
          <Input
            type='text'
            name='email'
            required
            onChange={handleInputChange}
          />
        </FormRow>
        <FormRow
          label='Password'
          hint='Password must contain at least eight characters, an uppercase letter, lowercase letter, and a number'>
          <Input
            type='password'
            name='password'
            required
            onChange={handleInputChange}
          />
        </FormRow>
        <FormRow
          label='Promo Code'
          hint='Enter a promo code for testing'>
          <Input
            type='text'
            name='promo'
            onChange={handleInputChange}
          />
        </FormRow>
        <FormRow
          label='Pricing'
          hint='Enter a plan ref (i.e. "team") for testing'>
          <Input
            type='text'
            name='plan'
            onChange={handleInputChange}
          />
        </FormRow>
        <Button
          type='submit'
          text='Sign Up Now'
          isLoading={isLoading}
        />
      </Form>
      <MessageBox alignCenter>
        <p>Already have an account? <Link to='/sign-in'>Sign In</Link></p>
      </MessageBox>
    </CenteredContainer >
  );
};

SignUp.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.string,
  handleInputChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

SignUp.defaultProps = {
};

export default SignUp;
