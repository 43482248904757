import { types as appTypes } from '../actions/app';
import { types as apiTypes } from '../actions/api';
import * as states from '../constants/states';

export const initialState = {
  state: states.NEW,
  rawTemplate: '', // Value from template editor textarea before parsing
  validTemplate: '', // Value from successful parse attempt
  invalidTemplate: '', // Value from failed parse attempt
  remoteTemplate: '', // Value from git repository
  defaultTemplate: '', // Value from API for default empty template
  error: null,
  isTemplateDirty: false,
  isTemplateReset: false,
  isTemplateValid: true,
  isTemplateEmpty: true
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case appTypes.DESELECT_STACK:
      return {
        ...initialState
      };

    case apiTypes.GET_STACK_CONTENTS.REQUEST:
      return {
        ...initialState,
        state: states.LOADING
      };

    case apiTypes.GET_STACK_CONTENTS.SUCCESS:
      if (action.data.format === 'stackery') {
        return {
          state: state.OKAY,
          ...state
        };
      }

      return {
        ...state,
        isTemplateEmpty: action.data.stack === '',

        // If a default template was supplied, set the template dirty flag to allow the default
        // to be committed; set the remote template to the empty string so the diff will look
        // right.
        isTemplateDirty: action.data.isDefaultTemplate,
        remoteTemplate: action.data.isDefaultTemplate ? '' : action.data.stack
      };

    case appTypes.PARSE_STACK_CONTENTS.TRY:
      return {
        ...state,
        state: states.LOADING
      };

    case appTypes.PARSE_STACK_CONTENTS.SUCCESS:
      return {
        ...state,
        state: states.OKAY,
        rawTemplate: action.data.stack,
        validTemplate: action.data.stack,
        invalidTemplate: undefined,
        isTemplateValid: true,
        error: undefined
      };

    case appTypes.PARSE_STACK_CONTENTS.FAILURE:
      return {
        ...state,
        state: states.OKAY, // enables the Template component to render a value despite failure
        rawTemplate: action.data.stack,
        validTemplate: undefined,
        invalidTemplate: action.data.stack,
        isTemplateValid: false,
        error: action.error
      };

    case appTypes.PARSE_STACK_TEMPLATE.TRY:
      return {
        ...state,
        state: states.LOADING
      };

    case appTypes.PARSE_STACK_TEMPLATE.SUCCESS:
      return {
        ...state,
        state: states.OKAY,
        error: undefined,
        rawTemplate: action.template,
        validTemplate: action.template,
        invalidTemplate: undefined,
        isTemplateValid: true,
        isTemplateEmpty: action.template === '',
        isTemplateDirty: action.template !== state.remoteTemplate
      };

    case appTypes.PARSE_STACK_TEMPLATE.FAILURE:
      return {
        ...state,
        state: states.OKAY,
        error: action.error,
        rawTemplate: action.template,
        invalidTemplate: action.template,
        isTemplateValid: false,
        isTemplateEmpty: action.template === '',
        isTemplateDirty: action.template !== state.remoteTemplate
      };

    case appTypes.RESET_STACK_TEMPLATE.SUCCESS:
      return {
        ...state,
        state: states.OKAY,
        rawTemplate: action.template,
        validTemplate: action.template,
        error: undefined,
        invalidTemplate: undefined,
        isTemplateReset: true,
        isTemplateValid: true,
        isTemplateEmpty: false,
        isTemplateDirty: action.template !== state.defaultTemplate && action.template !== state.remoteTemplate
      };

    case appTypes.SET_STACK_TEMPLATE:
      return {
        ...state,
        rawTemplate: action.template,
        isTemplateEmpty: action.template === '',
        isTemplateDirty: action.template !== state.remoteTemplate
      };

    case apiTypes.GET_DEFAULT_BLUEPRINT.SUCCESS:
      return {
        ...state,
        defaultTemplate: action.data.blueprint
      };

    case apiTypes.SAVE_NODES.SUCCESS:
      return {
        ...state,
        remoteTemplate: state.rawTemplate,
        isTemplateDirty: false
      };

    default:
      return state;
  }
};
