import React from 'react';
import PropTypes from 'prop-types';
import { default as SkeletonMui } from '@material-ui/lab/Skeleton';
import style from './Skeleton.css';

const Skeleton = props => {
  return (
    <SkeletonMui classes={{ root: style.container, [props.variant]: style[props.variant], [props.animation]: style[props.animation] }} variant={props.variant} animation={props.animation} width={props.width} height={props.height} />
  );
};

Skeleton.defaultProps = {
  variant: 'text',
  animation: false
};

Skeleton.propTypes = {
  variant: PropTypes.oneOf(['text', 'rect', 'circle', 'pulse', 'wave']),
  width: PropTypes.number,
  height: PropTypes.number,
  animation: PropTypes.oneOf(['pulse', 'wave', false])
};

export default Skeleton;
