import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import style from './StatusMessage.css';

const StatusMessage = (props) => {
  const Element = props.element;
  return (
    <Element className={classNames({[style.error]: props.type === 'error'}, {[style.success]: props.type === 'success'})}>
      {props.text}
    </Element>
  );
};

StatusMessage.defaultProps = {
  element: 'p'
};

StatusMessage.propTypes = {
  element: PropTypes.string,
  type: PropTypes.string,
  text: PropTypes.string
};

export default StatusMessage;
