import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push, replace } from 'connected-react-router';
import { createSelector } from 'reselect';
import * as states from '../../constants/states';
import * as modes from '../../constants/modes';
import * as planTypes from '../../constants/planTypes';
import appActions from '../../actions/app';
import apiActions from '../../actions/api';
import canvasActions from '../../actions/canvas';
import makeGitLink from '../../utils/makeGitLink';
import isReference from '../../utils/isReference';
import { onTransition } from '../../utils/transition';
import determineReferenceDefaults from '../../utils/determineReferenceDefaults';
import selectors from '../../selectors';
import StackEdit from './StackEdit';

const errorsSelector = createSelector(
  (appState) => appState.editorNodes,
  selectors.nodeTypes,
  (editorNodes, nodeTypes) => {
    let errors = [];

    editorNodes.nodes.filter(node => node.errors && Array.isArray(node.errors))
      .forEach(node => {
        let nodeType = nodeTypes.types[node.type];
        let label = nodeType.label;
        let name = (typeof label === 'function') ? label.call(node) : label;
        node.errors.forEach(error => {
          errors.push(`Node '${name}': ${error}`);
        });
      });

    return errors;
  }
);

const mapStateToProps = () => {
  return createSelector(
    (appState) => appState.stack,
    (appState) => appState.account,
    (appState) => appState.currentUser,
    (appState) => appState.gitProviders,
    (appState) => appState.configKeys,
    (appState) => appState.editorNodes,
    (appState) => appState.templateEditor,
    (appState) => appState.stackBranches,
    selectors.nodeTypes,
    (appState) => appState.oauth,
    errorsSelector,
    (stack, account, currentUser, gitProviders, configKeys, editorNodes, templateEditor, stackBranches, nodeTypes, oauth, errors) => {
      const truncatedVersion = stack.version ? stack.version.slice(0, 7) : null;
      let loadingError = (oauth.error || editorNodes.error || stackBranches.error);

      // If no template in repo, we'll give the user a default template, so don't show
      // the loading error.
      if (loadingError && loadingError.message === 'No template found in Git repo') {
        loadingError = undefined;
      }

      if (typeof loadingError === 'string') {
        loadingError = new Error(loadingError);
      }

      return {
        stack,
        currentUser,
        configKeys,
        editorNodes,
        templateEditor,
        gitProviders,
        nodeTypes,
        stackBranches,
        version: truncatedVersion,
        gitLink: makeGitLink(stack, gitProviders, stack.version),
        loadingError,
        errors,
        isGitless: stack.isGitless,
        isDefaultTemplate: stack.isDefaultTemplate,
        isDevPlan: account.plan.type === planTypes.DEVELOPER,
        isStackLoaded: (editorNodes.state === states.OKAY && stackBranches.state === states.OKAY && stack.state === states.OKAY),
        isStackDirty: (
          templateEditor.isTemplateDirty ||
          (
            // Stackery stacks do not have templateEditor state
            stack.format === 'stackery' &&
            !!(editorNodes.nodesDeleted.length || editorNodes.nodes.some(node => node.dirty))
          )
        )
      };
    }
  );
};

const mapDispatchToProps = {
  push,
  replace,
  createBranch: apiActions.createBranch,
  createBranchAndSaveNodes: apiActions.createBranchAndSaveNodes,
  createBranchAndSaveResources: apiActions.createBranchAndSaveResources,
  getStackContents: apiActions.getStackContents,
  notifyUser: appActions.notifyUser,
  saveNodes: apiActions.saveNodes,
  startResources: canvasActions.startResources,
  stopResources: canvasActions.stopResources,
  saveResources: apiActions.saveResources,
  setStackMode: appActions.setStackMode,
  setStackRepo: apiActions.setStackRepo,
  selectStackBranch: appActions.selectStackBranch,
  resetStackTemplate: appActions.resetStackTemplate,
  showCommitStackModal: appActions.showCommitStackModal,
  showCreateBranchModal: appActions.showCreateBranchModal,
  showCreateRepoModal: appActions.showCreateRepoModal,
  showEditRepoModal: appActions.showEditRepoModal
};

class StackEditContainer extends Component {
  constructor (props) {
    super(props);

    this.commitResources = this.commitResources.bind(this);
    this.commitStackery = this.commitStackery.bind(this);
    this.handleCreateBranch = this.handleCreateBranch.bind(this);
    this.handleRefreshClick = this.handleRefreshClick.bind(this);
    this.handleSetRepoClick = this.handleSetRepoClick.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount () {
    const {
      match,
      stack,
      isGitless,
      isStackDirty
    } = this.props;

    this.props.setStackMode(modes.EDITING);

    if (!isGitless && match.params.branch && match.params.branch !== stack.branch) {
      this.props.selectStackBranch(decodeURIComponent(match.params.branch));
    }

    if (!isGitless && match.params.branch && stack.branch && match.params.branch === stack.branch && stack.state === states.NEW) {
      this.props.replace(stack.owner, stack.name, stack.branch);
    }

    if (isGitless && match.params.branch) {
      this.props.replace(`/stacks/${stack.owner}/${stack.name}/edit`);
    }

    if (stack.format && stack.format !== 'stackery') {
      this.props.startResources();
    }

    if (stack.branch && stack.hasTemplatePathChanged && !isStackDirty) {
      this.props.getStackContents(stack.owner, stack.name, stack.branch);
    }
  }

  componentWillUnmount () {
    this.props.setStackMode();
    this.props.stopResources();
  }

  componentDidUpdate (prevProps) {
    const {
      editorNodes,
      templateEditor,
      stack,
      match,
      notifyUser,
      stackBranches,
      isGitless
    } = this.props;

    const {
      editorNodes: prevEditorNodes,
      templateEditor: prevTemplateEditor,
      stack: prevStack,
      match: prevMatch
    } = prevProps;

    onTransition(prevProps, this.props, 'configKeys', {
      [states.FAILED]: {
        notify: {
          message: `Failed to get config keys: ${this.props.configKeys.error}`,
          level: 'error'
        }
      }
    });

    onTransition(prevProps, this.props, 'stack', {
      [true]: {
        notify: {
          message: 'No template was found for this stack so a default was supplied',
          level: 'info'
        }
      }
    }, 'isDefaultTemplate');

    // Redirect to base route if stack isGitless
    if (isGitless && match.params.branch) {
      this.props.replace(`/stacks/${stack.owner}/${stack.name}/edit`);
    }

    // Update stack.branch if route param and version change
    if (
      !isGitless &&
      match.params.branch &&
      prevMatch.params.branch !== match.params.branch &&
      prevStack.version !== stack.version
    ) {
      this.props.selectStackBranch(decodeURIComponent(match.params.branch));
    }

    // Replace route when branch becomes defined
    if (!prevStack.branch && stack.branch && !prevMatch.params.branch) {
      this.props.replace(window.location.pathname.replace(`/edit`, `/edit/${encodeURIComponent(stack.branch)}`));
    }

    // Update route if branch changes
    if (prevStack.branch && stack.branch && prevStack.branch !== stack.branch) {
      this.props.push(window.location.pathname.replace(`/edit/${encodeURIComponent(prevStack.branch)}`, `/edit/${encodeURIComponent(stack.branch)}`));
    }

    if (stack.branch && stack.branch !== prevStack.branch && !stackBranches.hasNewBranch && !stack.wasGitless) {
      this.props.getStackContents(stack.owner, stack.name, stack.branch);
    }

    // Call stop/startResources (resource lib bindings) if format changes
    if (stack.format && stack.format !== prevStack.format) {
      if (stack.format === 'stackery') {
        this.props.stopResources();
      } else {
        this.props.startResources();
      }
    }

    // Initilize templateEditor with the defaultTemplate if template is undefined
    if (
      editorNodes.state !== prevEditorNodes.state &&
      editorNodes.state === states.FAILED &&
      editorNodes.error.message === 'No template found in Git repo'
    ) {
      notifyUser(`${editorNodes.error.message}. Setting to default SAM template.`, 'error');
      this.props.resetStackTemplate();
    }

    // Initilize templateEditor with the defaultTemplate if stack.isGitless
    if (stack.isGitless && templateEditor.defaultTemplate !== prevTemplateEditor.defaultTemplate && templateEditor.isTemplateEmpty) {
      this.props.resetStackTemplate();
    }
  }

  handleCreateBranch () {
    this.props.showCreateBranchModal({ stack: this.props.stack, onSubmit: this.props.createBranch });
  }

  handleRefreshClick () {
    const {
      owner,
      name,
      branch
    } = this.props.stack;

    this.props.getStackContents(owner, name, branch);
  }

  handleSetRepoClick () {
    this.props.showEditRepoModal({ stack: this.props.stack, gitProviders: this.props.gitProviders, title: 'Link Remote Repository', onSubmit: this.props.setStackRepo });
  }

  handleSave () {
    const {
      editorNodes,
      stack
    } = this.props;

    const nodesModified = editorNodes.nodes.filter(node => node.dirty && !node.unsaved);
    const nodesNew = editorNodes.nodes.filter(node => node.unsaved);
    const nodesDeleted = editorNodes.nodesDeleted.filter(node => !node.unsaved);
    const onSubmit = stack.format === 'stackery' ? this.commitStackery : this.commitResources;

    const nodeCount = [].concat(nodesModified, nodesNew, nodesDeleted).length;
    const nodeGrammar = nodeCount > 1 ? 'resources' : 'resource';
    let message = nodeCount > 0 ? [`Modified ${nodeCount} ${nodeGrammar}:\n`] : ['Modified resources'];

    const _nodeDescription = (node) => {
      return `${node.type} resource ${node.name || node.id}`;
    };

    if (nodesNew.length) {
      nodesNew.map(node => message.push(`Added ${_nodeDescription(node)}`));
    }

    if (nodesModified.length) {
      nodesModified.map(node => message.push(`Updated ${_nodeDescription(node)}`));
    }

    if (nodesDeleted.length) {
      nodesDeleted.map(node => message.push(`Deleted ${_nodeDescription(node)}`));
    }

    message = message.join('\n');

    if (stack.isRepoOwner) {
      this.props.showCommitStackModal({
        stack,
        message,
        onSubmit
      });
    } else {
      this.props.showCreateRepoModal({ stack, message: 'Initial commit' });
    }
  }

  commitResources (message, branch) {
    const {
      currentUser,
      stack,
      templateEditor,
      saveResources,
      createBranchAndSaveResources
    } = this.props;

    const format = stack.format || 'SAM';

    if (branch) {
      createBranchAndSaveResources(
        stack.owner,
        stack.name,
        stack.branch,
        branch,
        templateEditor.rawTemplate,
        format,
        {
          message,
          email: currentUser.email
        }
      );
    } else {
      saveResources(
        stack.owner,
        stack.name,
        stack.branch,
        templateEditor.rawTemplate,
        format,
        {
          message,
          email: currentUser.email
        }
      );
    }
  }

  commitStackery (message, branch) {
    const {
      currentUser,
      stack,
      editorNodes,
      nodeTypes,
      saveNodes,
      createBranchAndSaveNodes
    } = this.props;

    const filteredNodes = editorNodes.nodes.map(node => {
      const type = nodeTypes.types[node.type];
      const filteredNode = {
        id: node.id,
        type: node.type,
        x: node.x,
        y: node.y,
        width: node.width,
        height: node.height
      };

      if (!isReference(node)) {
        for (const prop in type.defaults) {
          if (prop in node) {
            filteredNode[prop] = node[prop];
          } else if ('value' in type.defaults[prop]) {
            filteredNode[prop] = type.defaults[prop].value;
          }
        }

        /* XXX: Conditional reference nodes still have a reference definition.
        * Copy that definition over. */
        filteredNode.reference = node.reference;
      } else {
        const defaults = determineReferenceDefaults(node.reference.arn);
        if (!node.name) {
          filteredNode.name = defaults.name;
        } else {
          filteredNode.name = node.name;
        }
        filteredNode.reference = {
          arn: node.reference.arn
        };
      }

      const numOutputs = node.outputs || type.outputs || 0;
      if (numOutputs > 0) {
        filteredNode.wires = node.wires;

        if (!filteredNode.wires) filteredNode.wires = [];

        for (let i = 0; i < numOutputs; i++) {
          if (node.wires && node.wires[i]) { filteredNode.wires[i] = node.wires[i]; } else filteredNode.wires[i] = [];
        }

        filteredNode.wires = filteredNode.wires.slice(0, numOutputs);
      }

      return filteredNode;
    });

    if (branch) {
      createBranchAndSaveNodes(
        stack.owner,
        stack.name,
        stack.branch,
        branch,
        filteredNodes,
        {
          message,
          email: currentUser.email
        }
      );
    } else {
      saveNodes(
        stack.owner,
        stack.name,
        stack.branch,
        filteredNodes,
        {
          message,
          email: currentUser.email
        }
      );
    }
  }

  render () {
    return (
      <StackEdit
        {...this.props}
        onSetRepoClick={this.handleSetRepoClick}
        onCreateBranchClick={this.handleCreateBranch}
        onRefreshClick={this.handleRefreshClick}
        onSave={this.handleSave}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StackEditContainer);
