import { types as apiTypes } from '../actions/api';
import { types as appTypes } from '../actions/app';
import * as states from '../constants/states';
import * as providerStates from '../constants/providerStates';
import * as localStorage from '../utils/localStorage';
import isVSCode from '../utils/isVSCode';

const isLocalMode = (params) => {
  return isVSCode ||
    (params !== '' && params.includes('port=') && params.includes('secret=') && params.includes('templatepath='));
};

export const initialState = {
  state: states.NEW,
  verifyEmailState: states.NEW,
  resendVerifyEmailState: states.NEW,
  createProviderState: states.NEW,
  isCurrentUserAdmin: false,
  version: null,
  linkAwsUrl: null,
  org: null,
  isSetupRequired: false,
  hasVerifiedEmail: false,
  hasLinkedAws: false,
  hasLoggedIntoCli: false,
  hasLinkedGit: false,
  hasCreatedStack: false,
  hasCreatedEnvironment: false,
  hasTriggeredDeployment: false,
  hasCompletedSetup: false,
  hasCompletedEval: false,
  linkedGitProviders: [],
  isDemoMode: localStorage.getItem('userInDemoMode') === 'true',
  isTourMode: false,
  isLocalMode: isLocalMode(window.location.search),
  isDeactivated: false
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case apiTypes.VERIFY_USER_EMAIL.REQUEST:
      return {
        ...state,
        verifyEmailState: states.LOADING
      };

    case apiTypes.VERIFY_USER_EMAIL.SUCCESS:
      return {
        ...state,
        verifyEmailState: states.OKAY,
        hasVerifiedEmail: true
      };

    case apiTypes.VERIFY_USER_EMAIL.FAILURE:
      return {
        ...state,
        verifyEmailState: states.FAILED
      };

    case apiTypes.RESEND_VERIFY_EMAIL.REQUEST:
      return {
        ...state,
        resendVerifyEmailState: states.LOADING
      };

    case apiTypes.RESEND_VERIFY_EMAIL.SUCCESS:
      return {
        ...state,
        resendVerifyEmailState: states.OKAY
      };

    case apiTypes.RESEND_VERIFY_EMAIL.FAILURE:
      return {
        ...state,
        resendVerifyEmailState: states.FAILED
      };

    case apiTypes.CURRENT_USER.SUCCESS:
      const {
        linkedGitProvidersV2: linkedGitProviders,
        ...user
      } = action.data.user;

      return {
        ...state,
        state: states.OKAY,
        ...user,
        linkedGitProviders,
        version: action.data.version,
        hasCompletedSetup: user.hasVerifiedEmail && user.hasLinkedGit && user.hasLinkedAws,
        hasCompletedEval: user.hasCreatedStack && user.hasCreatedEnvironment && user.hasTriggeredDeployment
      };

    case apiTypes.GET_ENVIRONMENTS.SUCCESS:
      return {
        ...state,
        hasCreatedEnvironment: action.data.length > 0
      };

    case apiTypes.GET_GIT_REPOS.SUCCESS:
      return {
        ...state,
        linkedGitProviders: state.linkedGitProviders.concat(action.provider),
        hasLinkedGit: true
      };

    case apiTypes.GET_ACCOUNT_PROVIDER_STATUS.SUCCESS:
      if (action.data.status === providerStates.SUCCESSFUL) {
        return {
          ...state,
          hasLinkedAws: true
        };
      } else {
        return {
          ...state
        };
      }

    case appTypes.TOGGLE_TOUR_MODE:
      return {
        ...state,
        isTourMode: !state.isTourMode
      };

    case appTypes.SET_LOCAL_MODE:
      return {
        ...state,
        isLocalMode: isLocalMode(action.params)
      };

    default:
      return state;
  }
};
