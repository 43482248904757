// Get query value from location search string

export default (search, name) => {
  if (!search) {
    return undefined;
  }

  const query = search.substring(1);
  const pairs = query.split('&');

  for (const pair of pairs) {
    const [ key, value ] = pair.split('=');

    if (decodeURIComponent(key) === name) {
      return decodeURIComponent(value);
    }
  }

  return undefined;
};
