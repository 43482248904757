
import React from 'react';
import PropTypes from 'prop-types';
import { patterns, hints } from '../../constants/patternHintText';
import FieldSet from '../core/FieldSet';
import Input from '../core/Input';

const RepoName = props => {
  return (
    <FieldSet legend='Repo name'>
      <Input
        name='useStackNameAsRepoName'
        type='checkbox'
        onChange={props.onInputChange}
        checked={props.useStackNameAsRepoName === true}
        label='Same as Stack Name'
        value={!props.useStackNameAsRepoName}
      />
      <Input
        name='repoName'
        defaultValue={props.repoName}
        required={!props.useStackNameAsRepoName}
        pattern={patterns.name}
        hint={hints.name}
        isHidden={props.useStackNameAsRepoName === true}
        onChange={props.onInputChange}
      />
    </FieldSet>
  );
};

RepoName.propTypes = {
  repoName: PropTypes.string,
  onInputChange: PropTypes.func.isRequired
};

export default RepoName;
