import React from 'react';
import StackLink from './StackLink';
import style from './Activity.css';

const PipelineDisabledEvent = (props) => {
  const stack = props.context === 'stacks' ? <em>{props.stackName}</em> : <StackLink {...props} />;

  return <span><em className={style.capitalize}>{props.pipelineName} Pipeline</em> disabled on {stack} by {props.userEmail}</span>;
};

export default PipelineDisabledEvent;
