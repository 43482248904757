import React, { Fragment } from 'react';

const VerifyEmailHelper = props => {
  return (
    <Fragment>
      <h2>Email Verification</h2>
      <p>We ask you to verify your email to make the experience of using Stackery easier; with a verified email, we can contact you with information about your account and make it easier to reset your password.</p>
      <p>We’ll never spam you or share sensitive information with third parties.</p>
      <p>Feel free to skip this step now if you’d prefer to verify later.</p>

    </Fragment>

  );
};

export default VerifyEmailHelper;
