import React from 'react';
import Panel from '../core/Panel';
import ResourceHeader from '../core/ResourceHeader';
import Form from '../core/Form';
import ButtonSet from '../core/ButtonSet';
import Properties from './Properties';

const ResourceEditor = (props) => {
  const {
    resource,
    name,
    type,
    description,
    docsLink,
    getResourceSetting,
    configKeys,
    errors,
    isEditable,
    onUpdateSettings,
    onSubmit,
    onCancel,
    autocomplete
  } = props;

  return (
    <Panel onShadeClick={onCancel}>
      <ResourceHeader
        title={name}
        resourceType={type}
        resourceDocsLink={docsLink}
        description={description}
        errors={errors}
      />
      {isEditable &&
        <Form onSubmit={onSubmit}>
          <Properties
            resource={resource}
            getResourceSetting={getResourceSetting}
            configKeys={configKeys}
            onChange={onUpdateSettings}
            autocomplete={autocomplete}
          />
          <ButtonSet
            primaryButton={{
              text: 'Save',
              type: 'submit'
            }}
            secondaryButton={{
              text: 'Cancel',
              onClick: onCancel
            }}
          />
        </Form>
      }
    </Panel>
  );
};

export default ResourceEditor;
