import { types } from '../actions/api';
import * as states from '../constants/signUpStates';

export const initialState = {
  state: states.NEW
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case types.SIGN_UP.REQUEST:
      return { ...state, state: states.LOADING };
    case types.SIGN_UP.FAILURE:
      return {
        ...state,
        errorMessage: action.error.message.message,
        state: states.FAILED
      };
    case types.SIGN_UP.SUCCESS:
      return {
        ...state,
        state: states.SUCCESS,
        email: action.email,
        password: action.password
      };
    default:
      return state;
  }
};
