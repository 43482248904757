import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import moment from 'moment';
import { getStartOfMonth, getEndOfMonth } from '../../utils/getDate';
import sortStackEvents from '../../utils/sortStackEvents';
import apiActions from '../../actions/api';
import * as states from '../../constants/states';
import ActiveStacks from './ActiveStacks';

const mapStateToProps = () => {
  return createSelector(
    (appState) => appState.currentUser,
    (appState) => appState.account,
    (currentUser, account) => {
      return {
        owner: currentUser.org,
        activeStacks: account.activeStacks,
        context: window.location.pathname.split('/')[1],
        isLoading: account.activeStacks.state === states.NEW || account.activeStacks.state === states.LOADING,
        isLoaded: account.activeStacks.state === states.OKAY
      };
    }
  );
};

const mapDispatchToProps = {
  getActiveStacks: apiActions.getActiveStacks
};

class ActiveStacksContainer extends Component {
  constructor (props) {
    super(props);

    this.state = {
      stacks: [],
      accountLimit: 'unlimited',
      billingPeriod: 1,
      selectedMonth: moment().month(),
      selectedYear: moment().year(),
      currentPage: 1,
      perPage: 500,
      isEmpty: true
    };

    this.handleChangeRange = this.handleChangeRange.bind(this);
  }

  handleChangeRange (event) {
    // Ignore any unexpected events
    if (!['selectedMonth', 'selectedYear'].includes(event.currentTarget.name)) {
      return;
    }

    this.setState({ [event.currentTarget.name]: parseInt(event.currentTarget.value, 10) });
  }

  componentDidUpdate (prevProps, prevState) {
    const {
      activeStacks
    } = this.props;

    const {
      stacks,
      selectedMonth,
      selectedYear
    } = this.state;

    if (
      prevProps.activeStacks.state !== activeStacks.state &&
      activeStacks.state === states.OKAY
    ) {
      this.setState({
        stacks: sortStackEvents(activeStacks.data),
        isEmpty: activeStacks.data.length === 0
      });
    }

    // Get new stack data when new range is selected
    if (prevState.selectedMonth !== selectedMonth || prevState.selectedYear !== selectedYear) {
      const isInTheFuture = moment().isBefore(moment(`${selectedYear}-${selectedMonth + 1}`, 'YYYY-M'));

      this.setState({
        isCurrentMonth: selectedMonth === moment().month() && selectedYear === moment().year(),
        isInTheFuture,
        stacks: isInTheFuture ? [] : stacks
      });

      if (!isInTheFuture) {
        this.props.getActiveStacks(getStartOfMonth(selectedMonth, selectedYear), getEndOfMonth(selectedMonth, selectedYear));
      }
    }
  }

  componentDidMount () {
    const {
      selectedMonth,
      selectedYear
    } = this.state;

    this.props.getActiveStacks(getStartOfMonth(selectedMonth, selectedYear), getEndOfMonth(selectedMonth, selectedYear), this.props.filterBy);
  }

  render () {
    return (
      <ActiveStacks
        {...this.props}
        {...this.state}
        onChange={this.handleChangeRange}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ActiveStacksContainer);
