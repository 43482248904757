import makeActions from '../utils/makeActions';

export const types = {
  START_CMS: 'CMS::START_CMS',
  STOP_CMS: 'CMS::STOP_CMS',
  GET_INDEX: makeActions('CMS', 'GET_INDEX'),
  GET_SECTION: makeActions('CMS', 'GET_SECTION'),
  DISMISS_SECTION: 'CMS::DISMISS_SECTION'
};

const actions = {
  startCms: () => ({
    type: types.START_CMS
  }),
  stopCms: () => ({
    type: types.STOP_CMS
  }),
  getIndex: () => ({
    type: types.GET_INDEX
  }),
  getSection: (config) => ({
    type: types.GET_SECTION,
    config
  }),
  dismissSection: (config) => ({
    type: types.DISMISS_SECTION,
    config
  })
};

export default actions;
