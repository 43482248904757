import { types as appTypes } from '../actions/app';
import { types as apiTypes } from '../actions/api';
import { types as iotNotificationsTypes } from '../actions/iotNotifications';
import * as deploymentStates from '../constants/deploymentStates';
import * as states from '../constants/states';
import * as providerStates from '../constants/providerStates';
import getPreviewEnvironmentName from '../utils/getPreviewEnvironmentName';

export const initialState = {
  state: states.NEW,
  owner: undefined,
  stackName: undefined,
  data: [],
  previewEnvironment: {},
  error: undefined
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case appTypes.SELECT_STACK:
      if (action.owner === state.owner && action.stackName === state.stackName) { return state; }
      return {
        ...state,
        owner: action.owner,
        stackName: action.name
      };

    case appTypes.DESELECT_STACK:
      return {
        ...initialState,
        previewEnvironment: {
          ...state.previewEnvironment
        }
      };

    case apiTypes.GET_STACK_ENVIRONMENTS.REQUEST:
      return {
        ...state,
        state: states.LOADING
      };

    case apiTypes.GET_STACK_ENVIRONMENTS.FAILURE:
      return {
        ...state,
        error: action.error.message,
        state: states.FAILED
      };

    case apiTypes.GET_STACK_ENVIRONMENTS.SUCCESS:
      return {
        ...state,
        state: states.OKAY,
        data: action.data.environments,
        error: undefined
      };

    case apiTypes.CREATE_ENVIRONMENT.SUCCESS:
      const environment = {
        ...state.previewEnvironment,
        ...action.data,
        error: undefined
      };

      return {
        ...state,
        state: states.OKAY,
        data: state.data.concat(environment)
      };

    case apiTypes.CREATE_ENVIRONMENT.FAILURE:
      if (action.name !== state.previewEnvironment.name) {
        return state;
      }

      return {
        ...state,
        previewEnvironment: {
          ...state.previewEnvironment,
          error: action.error.message.message,
          preparingState: states.FAILED
        }
      };

    case iotNotificationsTypes.DEPLOYMENT:
      if (
        action.owner !== state.owner ||
        action.stackName !== state.stackName
      ) { return state; }

      const environments = state.data.map(environment => {
        if (action.environmentName === environment.name) {
          environment.preparingState = states.OKAY;

          if (action.status === deploymentStates.DEPLOYED) {
            environment = {
              ...environment,
              latestDeployment: {
                ...environment.latestDeployment,
                ...action
              },
              latestPrepare: null
            };
          } else if (action.status === undefined) { // if the user deletes the stack in the AWS Console, its status is undefined
            environment = {
              ...environment,
              latestPrepare: {
                ...environment.latestPrepare
              },
              latestDeployment: null
            };
          } else if (action.status !== deploymentStates.DEPLOYED) {
            environment = {
              ...environment,
              cloudFormationStackName: action.cloudFormationStackName,
              latestPrepare: {
                ...environment.latestPrepare,
                ...action
              }
            };
          }
        }

        return environment;
      });

      return {
        ...state,
        data: environments
      };

    case apiTypes.PREPARE.REQUEST:
    case apiTypes.PREPARE.FAILURE:
      if (
        action.owner !== state.owner ||
        action.stack !== state.stackName
      ) { return state; }

      const preparingStates = {
        [apiTypes.PREPARE.REQUEST]: states.LOADING,
        [apiTypes.PREPARE.FAILURE]: states.FAILED
      };

      return {
        ...state,
        data: state.data.map(environment => {
          if (action.environment === environment.name) {
            environment = {
              ...environment,
              preparingState: preparingStates[action.type]
            };
          }

          return environment;
        })
      };

    case apiTypes.CURRENT_USER.SUCCESS:
      const { name } = action.data.user;

      return {
        ...state,
        previewEnvironment: {
          ...state.previewEnvironment,
          name: getPreviewEnvironmentName(name)
        }
      };

    case apiTypes.GET_ACCOUNT_INFO.SUCCESS:
    case apiTypes.GET_ACCOUNT_PROVIDER_STATUS.SUCCESS:
      const {
        default: account,
        provider,
        status
      } = action.data;

      let accountId = state.previewEnvironment.accountId;
      let region = state.previewEnvironment.region;

      if (account) {
        accountId = account.accountId;
        region = account.region;
      } else if (provider) {
        accountId = provider.accountId;
        region = provider.region;
      }

      return {
        ...state,
        previewEnvironment: {
          ...state.previewEnvironment,
          accountId,
          region,
          preparingState: status === providerStates.SUCCESSFUL ? states.LOADING : state.previewEnvironment.preparingState
        }
      };

    default:
      return state;
  }
};
