import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import * as states from '../../constants/states';
import apiActions from '../../actions/api';
import appActions from '../../actions/app';
import LinkedAccounts from './LinkedAccounts';

const mapStateToProps = () => {
  return createSelector(
    (appState) => appState.account,
    (account) => {
      return {
        account,
        isLoading: account.state === states.LOADING || account.template.state === states.LOADING,
        isProviderLimitMaxed: account.plan.providersLimit != null && account.providers.length >= account.plan.providersLimit
      };
    }
  );
};

const mapDispatchToProps = {
  getAccountInfo: apiActions.getAccountInfo,
  getTemplateVersion: apiActions.getTemplateVersion,
  showLinkAwsModal: appActions.showLinkAwsModal,
  showUnlinkAwsModal: appActions.showUnlinkAwsModal,
  showUpdateStackeryRoleModal: appActions.showUpdateStackeryRoleModal,
  showUpdatePipelineDeploymentsModal: appActions.showUpdatePipelineDeploymentsModal,
  showUpgradePromptModal: appActions.showUpgradePromptModal
};

class LinkedAccountsContainer extends Component {
  constructor (props) {
    super(props);

    this.handleLinkAccountClick = this.handleLinkAccountClick.bind(this);
  }

  componentDidMount () {
    if (this.props.account.template.state === states.NEW) {
      this.props.getTemplateVersion();
    }
  }

  handleLinkAccountClick () {
    if (this.props.isProviderLimitMaxed) {
      this.props.showUpgradePromptModal({ prompt: 'providersLimitReached', providersLimit: this.props.account.plan.providersLimit, plan: this.props.account.plan.displayName });
    } else {
      this.props.showLinkAwsModal();
    }
  }

  render () {
    return (
      <LinkedAccounts
        {...this.props}
        onLinkAccountClick={this.handleLinkAccountClick}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LinkedAccountsContainer);
