import React from 'react';
import PropTypes from 'prop-types';
import Total from './Total';
import style from './Totals.css';

const Totals = props => {
  const {
    stacks,
    environments,
    totalDeployments,
    isLoadingStacks,
    isLoadingEnvironments,
    isLoadingUsage
  } = props;

  return (
    <section className={style.container}>
      <Total
        type='Stack'
        total={stacks.data.length}
        to='/stacks'
        isLoading={isLoadingStacks}
      />
      <Total
        type='Environment'
        total={environments.data.length}
        to='/environments'
        isLoading={isLoadingEnvironments}
      />
      <Total
        type='Deployment'
        total={totalDeployments}
        to='/deployments'
        isLoading={isLoadingUsage}
      />
    </section>
  );
};

Totals.propTypes = {
  stacks: PropTypes.object,
  environments: PropTypes.object,
  totalDeployments: PropTypes.number,
  totalActiveStacks: PropTypes.number,
  isLoadingStacks: PropTypes.bool,
  isLoadingEnvironments: PropTypes.bool,
  isLoadingUsage: PropTypes.bool
};

export default Totals;
