import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { DragSource } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { nodeDefaultHeight } from '../../constants/canvas';
import newId from '../../utils/newId';
import {
  calculateInitialNodeHeight,
  calculateInitialNodeWidth
} from '../../utils/calculateNode';
import actions from '../../actions/canvas';
import appActions from '../../actions/app';
import * as dragTypes from '../../constants/dragTypes';
import style from './NodeType.css';

const dragSource = {
  beginDrag (props, monitor, component) {
    return {
      onDrop: component.handleCreateReferenceNodeOnDrop
    };
  }
};

function collect (connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
    offset: monitor.getClientOffset()
  };
}

const mapDispatchToProps = {
  showCreateReferenceNodeModal: appActions.showCreateReferenceNodeModal,
  dragNewNodes: actions.dragNewNodes,
  hideModal: appActions.hideModal
};

const enhance = compose(
  connect(null, mapDispatchToProps),
  DragSource(dragTypes.NODE_TYPE_REF, dragSource, collect)
);

class NodeTypeRef extends Component {
  constructor (props) {
    super(props);

    this.handleCreateReferenceNode = this.handleCreateReferenceNode.bind(this);
    this.handleCreateReferenceNodeOnDrop = this.handleCreateReferenceNodeOnDrop.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount () {
    this.props.connectDragPreview(getEmptyImage(), {
      captureDraggingState: true
    });
  }

  handleSubmit (reference, position) {
    if (!reference || !reference.type) {
      return;
    }

    const node = {
      id: newId(),
      type: reference.type,
      parent: window.RED.nodes.parent,
      unsaved: true,
      reference: {
        arn: reference.arn
      },
      name: reference.name
    };

    const type = this.props.nodeTypes[reference.type];

    const width = type.initialWidth || calculateInitialNodeWidth(node, type);
    const height = type.initialHeight || calculateInitialNodeHeight(node, type);

    const x = Math.floor(position.x + width / 2);
    const y = Math.floor(position.y + height / 2);

    node.x = x;
    node.y = y;
    node.width = width;
    node.height = height;

    this.props.dragNewNodes([ node ], x, y);
    this.props.hideModal();
  }

  handleCreateReferenceNode (event) {
    this.props.showCreateReferenceNodeModal({ onSubmit: this.handleSubmit });
  }

  handleCreateReferenceNodeOnDrop (position) {
    this.props.showCreateReferenceNodeModal({ position, onSubmit: this.handleSubmit });
  }

  render () {
    let icon = '';

    const {
      showHint,
      connectDragSource
    } = this.props;

    try {
      icon = require(`../../icons/import.svg`);
    } catch (err) {
    }

    return (
      <div className={style.container}>
        {connectDragSource(
          <div
            className={style.node}
            height={nodeDefaultHeight}
            onMouseUp={this.handleCreateReferenceNode}
          >
            <span className={style.icon}>
              <img className={style.image} src={icon} alt='' />
            </span>
            <span className={style.label}>Referenced Resource</span>
          </div>
        )}
        {showHint &&
          <p className={style.hint}>Reference resource by ARN</p>
        }
      </div>
    );
  }
}

NodeTypeRef.defaultProps = {
  showHint: true
};

export default enhance(NodeTypeRef);
