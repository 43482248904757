import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import * as localStorage from '../../utils/localStorage';
import authActions from '../../actions/auth';
import tourActions from '../../actions/tour';
import Tour from './Tour';

const mapStateToProps = () => {
  return createSelector(
    (appState) => appState.tour,
    (tour) => {
      return {
        tour
      };
    }
  );
};

const mapDispatchToProps = {
  endTour: tourActions.endTour,
  advanceTour: tourActions.advanceTour,
  updateCognitoUser: authActions.updateCognitoUser
};

class TourContainer extends Component {
  constructor (props) {
    super(props);
    this.state = {
      helpers: [],
      isClickBound: true
    };
    this.onClickAnywhere = this.onClickAnywhere.bind(this);
    this.hover = this.hover.bind(this);
    this.setHelpers = this.setHelpers.bind(this);
    this.onCallback = this.onCallback.bind(this);
  }

  componentDidMount () {
    document.addEventListener('click', this.onClickAnywhere);
  }

  componentDidUpdate (prevProps) {
    const {
      steps,
      history,
      currentTour
    } = this.props.tour;

    const {
      steps: prevSteps,
      history: prevHistory,
      currentTour: prevCurrentTour
    } = prevProps.tour;

    const { helpers } = this.state;

    if (helpers && prevSteps !== steps && steps.length > 0) {
      const currentStep = helpers.info().index;
      const target = steps[currentStep].target.replace('#', '');

      if (!document.getElementById(target)) {
        this.targetInterval = setInterval(() => {
          if (document.getElementById(target)) {
            helpers.go(currentStep);
            clearInterval(this.targetInterval);
          }
        }, 100);
      }
    }

    if (
      !prevHistory[currentTour] ||
      (
        currentTour === prevCurrentTour &&
        history[currentTour] && prevHistory[currentTour] &&
        history[currentTour].length > prevHistory[currentTour].length
      )
    ) {
      this.props.updateCognitoUser('custom:tourHistory', JSON.stringify(history));
    }
  }

  componentWillUnmount () {
    document.removeEventListener('click', this.onClickAnywhere);
  }

  onClickAnywhere (event) {
    // Using joyride helpers to pause or run joyride steps based on specific DOM clicks
    const { helpers } = this.state;
    const { steps } = this.props.tour;

    const currentStep = helpers.info().index;
    const hasTour = steps.length > 0;

    if (!hasTour) {
      return;
    }

    const pauseTargets = steps[currentStep].pauseTargets || [];

    if (this.props.tour.steps.length === 1) {
      helpers.skip();
    } else {
      if (
        pauseTargets.includes(event.target.id) ||
        (event.target.parentNode && pauseTargets.includes(event.target.parentNode.id)) ||
        (event.target.parentNode && event.target.parentNode.parentNode && pauseTargets.includes(event.target.parentNode.parentNode.id))
      ) {
        helpers.go(currentStep);
      } else {
        helpers.next();
      }
    }
  }

  hover (event) {
    const type = event.type;
    const { isClickBound } = this.state;

    if (type === 'mouseover' && isClickBound) {
      this.setState({ isClickBound: false });
      document.removeEventListener('click', this.onClickAnywhere);
    } else if (type === 'mouseout' && !isClickBound) {
      this.setState({ isClickBound: true });
      document.addEventListener('click', this.onClickAnywhere);
    }
  }

  setHelpers (helpers) {
    this.setState({ helpers });
  }

  onCallback (data) {
    const { currentTour } = this.props.tour;

    if (data.action === 'start') {
      localStorage.setItem('hasSeenTour', true);
    }

    // Mimic a mouseout so onClickAnywhere (document click advances or closes tour) is re-bound
    if (data.action === 'next' || data.action === 'prev') {
      this.hover({ type: 'mouseout' });
    }

    if (data.lifecycle === 'complete' || data.lifecycle === 'tooltip') {
      this.props.advanceTour(currentTour, data.step.target);
    }

    if (data.action === 'skip' && data.lifecycle === 'complete') {
      this.props.endTour(currentTour, true);
    }
  }

  render () {
    return (
      <Tour
        tour={this.props.tour}
        onHover={this.hover}
        onCallback={this.onCallback}
        setHelpers={this.setHelpers}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TourContainer);
