import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import * as states from '../../constants/stackCreatorStates';
import apiActions from '../../actions/api';
import appActions from '../../actions/app';
import GitDiff from './EnvironmentGitDiff';

const mapStateToProps = (props, ownProps) => {
  return createSelector(
    (appState) => appState.gitProviders,
    (appState) => appState.currentUser,
    (appState) => appState.environmentGitDiff,
    (gitProviders, currentUser, environmentGitDiff) => {
      return {
        ...ownProps,
        owner: currentUser.org,
        stack: ownProps.stack,
        environment1: ownProps.environment1,
        environment2: ownProps.environment2,
        commits: environmentGitDiff.commits,
        hasMergeCommits: environmentGitDiff.commits.filter(commit => commit.merge).length > 0,
        isLoading: environmentGitDiff.state === states.NEW || environmentGitDiff.state === states.LOADING
      };
    }
  );
};

const mapDispatchToProps = {
  getEnvironmentGitDiff: apiActions.getEnvironmentGitDiff,
  hideModal: appActions.hideModal
};

class EnvironmentGitDiffContainer extends Component {
  constructor (props) {
    super(props);

    this.state = {
      environment1: this.props.environment1,
      environment2: this.props.environment2,
      filterCommitType: 'all',
      searchTerm: '',
      filteredCommits: this.props.commits
    };

    this.filterCommits = this.filterCommits.bind(this);
    this.handleSwitch = this.handleSwitch.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount () {
    this.props.getEnvironmentGitDiff(this.props.owner, this.props.stack, this.props.environment1.id, this.props.environment2.id);
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevState.environment1.id !== this.state.environment1.id && prevState.environment2.id !== this.state.environment2.id) {
      this.props.getEnvironmentGitDiff(this.props.owner, this.props.stack, this.state.environment1.id, this.state.environment2.id);
    }

    if (prevProps.commits.length !== this.props.commits.length) {
      this.setState({ filteredCommits: this.props.commits });
    }

    if (prevState.filterCommitType !== this.state.filterCommitType || prevState.searchTerm !== this.state.searchTerm) {
      this.filterCommits();
    }
  }

  filterCommits () {
    const { filterCommitType } = this.state;

    const searchTerm = this.state.searchTerm ? this.state.searchTerm.toLowerCase() : '';
    const isSearchEmpty = searchTerm === '';

    this.setState({
      filteredCommits: this.props.commits.filter(commit => {
        let matchesType = true;

        if (filterCommitType === 'merge') {
          matchesType = commit.merge === true;
        } else if (filterCommitType === 'nonmerge') {
          matchesType = commit.merge === false;
        }

        const matchesSha = commit.sha.toLowerCase().includes(searchTerm);
        const matchesMessage = commit.message.toLowerCase().includes(searchTerm) || commit.description.toLowerCase().includes(searchTerm);
        const matchesAuthor = commit.author.toLowerCase().includes(searchTerm);
        const matchesLabel = (commit.labels || []).filter((label) => {
          return label.toLowerCase().includes(searchTerm);
        }).length > 0;

        return matchesType && (isSearchEmpty || matchesSha || matchesMessage || matchesAuthor || matchesLabel);
      })
    });
  }

  handleSwitch () {
    this.setState({
      environment1: this.state.environment2,
      environment2: this.state.environment1
    });
  }

  handleInputChange (event) {
    let {
      name,
      value
    } = event.target;

    this.setState({ [name]: value });
  }

  render () {
    return (
      <GitDiff
        {...this.props}
        {...this.state}
        onSwitch={this.handleSwitch}
        onInputChange={this.handleInputChange}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EnvironmentGitDiffContainer);
