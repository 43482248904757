import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import deepEqual from 'deep-equal';
import { onTransition } from '../../utils/transition';
import apiActions from '../../actions/api';
import appActions from '../../actions/app';
import * as states from '../../constants/states';
import getQueryValue from '../../utils/getQueryValue';
import Integrations from './Integrations';

const integrations = [
  {
    label: 'Epsagon Token',
    property: 'epsagonToken',
    pattern: '[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}'
  },
  {
    label: 'Lumigo Token',
    property: 'lumigoToken'
  },
  {
    label: 'New Relic Account ID',
    property: 'newrelicAccountId',
    pattern: '\\d+'
  },
  {
    label: 'Thundra API Key',
    property: 'thundraToken',
    pattern: '[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}'
  }
];

const epsagonTokenValue = integrations => integrations.epsagon ? integrations.epsagon.defaultToken : '';
const lumigoTokenValue = integrations => integrations.lumigo ? integrations.lumigo.defaultToken : '';
const newrelicAccountIdValue = integrations => integrations.newrelic ? integrations.newrelic.accountId : '';
const thundraTokenValue = integrations => integrations.thundra ? integrations.thundra.defaultToken : '';

const mapStateToProps = () => {
  return createSelector(
    (appState) => appState.integrations,
    integrations => ({
      integrations,
      currentValues: {
        epsagonToken: epsagonTokenValue(integrations.integrations),
        lumigoToken: lumigoTokenValue(integrations.integrations),
        newrelicAccountId: newrelicAccountIdValue(integrations.integrations),
        thundraToken: thundraTokenValue(integrations.integrations)
      }
    })
  );
};

const mapDispatchToProps = {
  getIntegrations: apiActions.getIntegrations,
  saveIntegrations: apiActions.saveIntegrations,
  notifyUser: appActions.notifyUser
};

const queryValueMap = {
  epsagonToken: 'epsagon_key',
  lumigoToken: 'lumigo_token',
  newrelicAccountId: 'newrelic_account_id',
  thundraToken: 'thundra_token'
};

class IntegrationsContainer extends Component {
  constructor (props) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.queryValues = {};

    for (const [ key, queryStringParam ] of Object.entries(queryValueMap)) {
      const queryValue = getQueryValue(window.location.search, queryStringParam);

      if (queryValue) {
        this.queryValues[key] = getQueryValue(window.location.search, queryStringParam);
      }
    }

    this.state = {
      formValues: {
        ...props.currentValues,
        ...this.queryValues
      },
      isLoading: true
    };
  }

  componentDidMount () {
    this.props.getIntegrations();
  }

  componentDidUpdate (prevProps) {
    if (
      (prevProps.integrations.state === 'LOADING' && this.props.integrations.state === 'OKAY') ||
      !deepEqual(prevProps.currentValues, this.props.currentValues)
    ) {
      this.setState({
        formValues: {
          ...this.props.currentValues,
          ...this.queryValues
        },
        isLoading: false
      });
    }

    onTransition(prevProps, this.props, 'integrations', {
      [states.OKAY]: {
        notify: {
          message: 'Successfully saved integration settings',
          level: 'success'
        }
      },
      [states.FAILED]: {
        notify: {
          message: 'Failed to save integration settings',
          level: 'error'
        }
      }
    }, 'saveState');
  }

  handleInputChange (event) {
    delete this.queryValues[event.target.name];

    this.setState({
      formValues: {
        ...this.state.formValues,
        [event.target.name]: event.target.value
      }
    });
  }

  handleSaveClick () {
    const integrations = {};

    if (this.state.formValues.epsagonToken) {
      integrations.epsagon = {
        defaultToken: this.state.formValues.epsagonToken
      };
    }

    if (this.state.formValues.lumigoToken) {
      integrations.lumigo = {
        defaultToken: this.state.formValues.lumigoToken
      };
    }

    if (this.state.formValues.newrelicAccountId) {
      integrations.newrelic = {
        accountId: this.state.formValues.newrelicAccountId
      };
    }

    if (this.state.formValues.thundraToken) {
      integrations.thundra = {
        defaultToken: this.state.formValues.thundraToken
      };
    }

    this.props.saveIntegrations(integrations);
  }

  render () {
    return (
      <Integrations
        className={this.props.className}
        isLoading={this.state.isLoading}
        onInputChange={this.handleInputChange}
        onSaveClick={this.handleSaveClick}
        currentValues={this.props.currentValues}
        formValues={this.state.formValues}
        integrations={integrations}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationsContainer);
