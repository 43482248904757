import { types as appTypes } from '../actions/app';
import { types as apiTypes } from '../actions/api';
import { types as cloudwatchTypes } from '../actions/cloudwatch';
import { types as iotNotificationsTypes } from '../actions/iotNotifications';
import { types as canvasTypes } from '../actions/canvas';
import * as deploymentStates from '../constants/deploymentStates';
import * as states from '../constants/states';
import parse from '../utils/parse';

const statisticsHandlers = {
  Sum: (list, key) => {
    return { [key.toLowerCase()]: list.map(item => item[key]).reduce((sum, item) => sum + item, 0) };
  },

  Average: (list, key) => {
    return { [key.toLowerCase()]: list.map(item => item[key]).reduce((sum, item) => sum + item, 0) / list.length };
  },

  SampleCount: (list, key) => {
    return { [key.toLowerCase()]: list.map(item => item[key]).reduce((sum, item) => sum + item, 0) };
  },

  Maximum: (list, key) => {
    return { [key.toLowerCase()]: list.map(item => item[key]).reduce((max, item) => item > max ? item : max, 0) };
  }
};

const mapNodesToMetrics = (results, nodes) => {
  return nodes.map(node => {
    const metrics = results.filter(data => data.node.id === node.id)[0];
    if (metrics) {
      const data = metrics.data.map(result => {
        return {
          ...result,
          statistics: Object.assign(...result.statistics.map(statistic => statisticsHandlers[statistic](result.datapoints, statistic)))
        };
      }).concat(metrics.derivedMetrics);

      node.metrics = {
        data,
        dataVisible: data.filter(metric => (
          !metric.isHidden &&
          // Filter out metrics that evaluate to NaN (e.g. DB RAM on unused serverless cluster)
          (!metric.statistics || Object.values(metric.statistics).every(value => !isNaN(value)))
        )),
        startTime: metrics.startTime,
        endTime: metrics.endTime
      };
    }

    node._cache = {};

    return node;
  });
};

const mapNodesToParent = (nodes) => {
  return nodes.map(node => {
    node._cache = {};
    return node;
  });
};

export const initialState = {
  owner: undefined,
  stackName: undefined,
  environment: undefined,
  selectedDeploymentId: undefined,
  selectedDeploymentState: states.NEW,
  selectedDeploymentHasMetrics: false,
  needsLayout: false,
  deployments: {}
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case appTypes.SELECT_STACK:
      if (action.owner === state.owner && action.stackName === state.stackName) { return state; }
      return {
        ...initialState,
        owner: action.owner,
        stackName: action.name
      };
    case appTypes.DESELECT_STACK:
      return initialState;
    case appTypes.SELECT_STACK_ENVIRONMENT:
      if (action.environment === state.environment) return state;
      return {
        ...state,
        environment: action.environment
      };
    case apiTypes.GET_DEPLOYMENT_STATE.REQUEST:
      return {
        ...state,
        selectedDeploymentState: states.LOADING,
        deployments: {
          ...state.deployments,
          [action.deployment]: {
            state: states.LOADING,
            nodes: []
          }
        }
      };
    case apiTypes.GET_DEPLOYMENT_STATE.FAILURE:
      return {
        ...state,
        selectedDeploymentState: states.FAILED,
        deployments: {
          ...state.deployments,
          [action.deployment]: {
            state: states.FAILED,
            nodes: []
          }
        }
      };
    case apiTypes.GET_DEPLOYMENT_STATE.SUCCESS:
      if (action.data.format === 'stackery') {
        return {
          ...state,
          selectedDeploymentState: states.OKAY,
          selectedDeploymentId: action.deployment,
          deployments: {
            ...state.deployments,
            [action.deployment]: {
              state: states.OKAY,
              awsAccountId: action.data.awsAccountId,
              region: action.data.region,
              nodes: mapNodesToParent(action.data.stack.nodes)
            }
          }
        };
      } else {
        const isDeployView = true;
        return {
          ...state,
          needsLayout: true,
          deployments: {
            ...state.deployments,
            [action.deployment]: {
              state: states.LOADING,
              awsAccountId: action.data.awsAccountId,
              region: action.data.region,
              resources: parse(action.data.stack, action.data.format, isDeployView),
              stackData: action.data.stackData,
              resourceData: action.data.resources
            }
          }
        };
      }
    case canvasTypes.LAYOUT_NODES.SUCCESS:
      if (!action.deploymentId) { return state; }

      return {
        ...state,
        selectedDeploymentState: states.OKAY,
        selectedDeploymentId: action.deploymentId,
        needsLayout: false,
        deployments: {
          ...state.deployments,
          [action.deploymentId]: {
            ...state.deployments[action.deploymentId],
            state: states.OKAY,
            nodes: action.nodes.map(node => {
              const resource = state.deployments[action.deploymentId].resources.resources[node.id];
              const settings = resource ? resource.Settings : {};
              return {
                ...node,
                ...settings
              };
            })
          }
        }
      };
    case appTypes.SELECT_DEPLOYMENT:
      const nodes = state.deployments[action.id];

      return {
        ...state,
        selectedDeploymentId: action.id,
        selectedDeploymentState: nodes ? nodes.state : states.NEW
      };
    case appTypes.DESELECT_DEPLOYMENT:
      return {
        ...state,
        selectedDeploymentId: undefined,
        selectedDeploymentState: states.NEW,
        selectedDeploymentHasMetrics: false
      };
    case cloudwatchTypes.GET_METRICS.SUCCESS:
      if (!state.deployments[state.selectedDeploymentId]) { return state; }

      const hasMetrics = action.results.length > 0;

      return {
        ...state,
        selectedDeploymentHasMetrics: hasMetrics,
        needsLayout: hasMetrics,
        deployments: {
          ...state.deployments,
          [state.selectedDeploymentId]: {
            ...state.deployments[state.selectedDeploymentId],
            nodes: mapNodesToMetrics(action.results, state.deployments[state.selectedDeploymentId].nodes)
          }
        }
      };
    case iotNotificationsTypes.DEPLOYMENT:
      if (
        action.deploymentHistoryId !== state.selectedDeploymentId ||
        action.status !== deploymentStates.DEPLOYED
      ) { return state; }

      return {
        ...state,
        deployments: {
          ...state.deployments,
          [action.deploymentHistoryId]: {
            state: states.NEW,
            nodes: []
          }
        }
      };
    default:
      return state;
  }
};
