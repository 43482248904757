import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import * as gitProviderStates from '../../constants/gitProviderStates';
import Form from '../core/Form';
import ButtonSet from '../core/ButtonSet';
import StackName from './StackName';
import RepoProvider from './RepoProvider';
import RepoName from './RepoName';
import RepoOwner from './RepoOwner';
import RepoUrl from './RepoUrl';
import RepoVisibility from './RepoVisibility';
import RepoRegion from './RepoRegion';
import Commit from './Commit';

const StackRepoForm = (props) => {
  const {
    gitProviders,
    selectedGitProvider
  } = props;

  const gitProvider = gitProviders[selectedGitProvider] || {};
  const isPersonalAccessTokenNeeded = gitProvider.needsPersonalAccessToken;
  const isCodeCommit = selectedGitProvider && selectedGitProvider === 'codecommit';

  const buttonLabel = isPersonalAccessTokenNeeded ? 'Link Account' : props.isNewStack ? 'Add Stack' : 'Save';

  // BitBucket specific data
  const team = gitProvider.destinations && gitProvider.destinations.find(dest => dest.id === props.owner);
  const isProjectRequired = team && team.projects;
  const project = isProjectRequired && team.projects.find(project => project.name === props.project);
  const isProjectPrivate = project && project.isPrivate;

  return (
    <Form onSubmit={props.onSubmit}>
      <RepoProvider
        selectedGitProvider={selectedGitProvider}
        gitProviders={gitProviders}
        onInputChange={props.onInputChange}
      />

      {gitProvider.state === gitProviderStates.AUTHORIZED &&
        <Fragment>
          {props.isNewStack &&
            <StackName
              stackName={props.stackName}
              onInputChange={props.onInputChange}
            />
          }

          {props.isNewRepo && gitProvider.destinations &&
            <RepoOwner
              selectedGitProvider={selectedGitProvider}
              destinations={gitProvider.destinations}
              team={team}
              isProjectRequired={isProjectRequired}
              isProjectPrivate={isProjectPrivate}
              onInputChange={props.onInputChange}
            />
          }

          {props.isNewRepo && isCodeCommit &&
            <RepoRegion
              region={props.region}
              onInputChange={props.onInputChange}
            />
          }

          {props.isExistingRepo && gitProvider.destinations && isCodeCommit &&
            <RepoOwner
              selectedGitProvider={selectedGitProvider}
              destinations={gitProvider.destinations}
              onInputChange={props.onInputChange}
            />
          }

          {props.isExistingRepo &&
            <RepoUrl
              selectedGitProvider={selectedGitProvider}
              gitProviders={gitProviders}
              onInputChange={props.onInputChange}
            />
          }

          {props.isNewRepo &&
            <RepoName
              repoName={props.stackName}
              useStackNameAsRepoName={props.useStackNameAsRepoName}
              onInputChange={props.onInputChange}
            />
          }

          {props.isNewRepo && selectedGitProvider && !isCodeCommit && !isProjectPrivate &&
            <RepoVisibility
              isPrivate={props.isPrivate}
              onInputChange={props.onInputChange}
            />
          }

          {!props.isNewStack &&
            <Commit
              stack={props.stack}
              message={props.message}
              originalTemplate={props.originalTemplate}
              currentTemplate={props.currentTemplate}
              isTemplateValid={props.isTemplateValid}
              onInputChange={props.onInputChange}
              onReset={props.validTemplate ? props.resetStackTemplate : undefined}
            />
          }
        </Fragment>
      }
      <ButtonSet
        primaryButton={{
          text: buttonLabel,
          type: 'submit',
          id: 'stack-repo-submit-button',
          isLoading: props.isLoading,
          isDisabled: !isPersonalAccessTokenNeeded && gitProvider.state !== gitProviderStates.AUTHORIZED
        }}
        secondaryButton={{
          text: 'Cancel',
          onClick: props.onCancel
        }}
      />
    </Form>
  );
};

StackRepoForm.propTypes = {
  stack: PropTypes.object.isRequired,
  repoName: PropTypes.string,
  originalTemplate: PropTypes.string,
  currentTemplate: PropTypes.string,
  selectedGitProvider: PropTypes.string,
  gitProviders: PropTypes.object.isRequired,
  region: PropTypes.string,
  isPrivate: PropTypes.bool.isRequired,
  isTemplateValid: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default StackRepoForm;
