import React from 'react';
import { types as appTypes } from '../../actions/app';
import Link from '../core/Link';

const ACTIONS = {
  [appTypes.SHOW_DELETE_STACK_MODAL]: 'delete an existing stack',
  [appTypes.SHOW_RENAME_STACK_MODAL]: 'rename an existing stack',
  [appTypes.SHOW_DELETE_ENVIRONMENT_MODAL]: 'delete an existing environment',
  [appTypes.SHOW_CREATE_ENVIRONMENT_MODAL]: 'create a new environment',
  [appTypes.SHOW_COMMIT_STACK_MODAL]: 'save a stack',
  [appTypes.SHOW_CREATE_BRANCH_MODAL]: 'create a new branch',
  [appTypes.SHOW_EDIT_REPO_MODAL]: `change a stack's repository`,
  [appTypes.SHOW_EDIT_TAGS_MODAL]: 'edit stack tags',
  [appTypes.SHOW_STACK_BLUEPRINT_MODAL]: 'add a new blueprint',
  [appTypes.SHOW_INVITE_USER_MODAL]: 'invite a user',
  [appTypes.SHOW_REMOVE_USER_MODAL]: 'remove a user',
  [appTypes.SHOW_IMPORT_REPOS_MODAL]: 'import GitHub repositories'
};

const Demo = (props) => {
  const action = props.action || ACTIONS[props.type];

  return (
    <div>
      <h1>You are viewing a demo account.</h1>
      <p>Some actions can't be performed when viewing the Stackery Demo Account.</p>
      <p>To perform the action you requested:<br /><strong>{action}</strong></p>
      <ol>
        <li><Link to='/demo/stop' >Exit Demo Mode</Link> </li>
        <li><Link target='blank' href='https://docs.stackery.io/quickstart/' >Setup Your Account</Link> </li>
      </ol>
    </div>
  );
};

export default Demo;
