export default class AbstractResource {
  constructor () {
    this.TemplatePartial = {
      Conditions: [],
      Resources: []
    };
  }

  addCFResource (template, logicalId, type, resource) {
    const resourceSection = this.typeToTemplateName(type);

    if (!this.TemplatePartial[resourceSection].includes(logicalId)) {
      this.TemplatePartial[resourceSection].push(logicalId);
    }

    if (resource) {
      for (const section of [ 'Conditions', 'Resources' ]) {
        const partials = this.TemplatePartial[section];

        for (const logicalId of resource.TemplatePartial[section]) {
          if (!partials.includes(logicalId)) {
            partials.push(logicalId);
          }
        }
      }
    }
  }

  typeToTemplateName (type) { return `${type}s`; }
}
