import React from 'react';
import PropTypes from 'prop-types';
import Switch from '../core/Switch';
import style from './ToggleField.css';

const ToggleField = props => {
  return (
    <label className={style.container}>
      <Switch
        id={props.name}
        isChecked={props.isChecked}
        onToggle={props.onToggle}
      />
      <span>
        <span className={style.label}>{props.label}</span>
        <span className={style.hint}>{props.hint}</span>
      </span>
    </label>
  );
};

ToggleField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  hint: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired
};

export default ToggleField;
