import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import style from './Select.css';

const Select = (props) => {
  return (
    <select
      className={classNames(
        props.className,
        props.hasError && props.errorClassName,
        props.isDisabled && props.disabledClassName
      )}
      id={props.id}
      name={props.name}
      defaultValue={props.defaultValue}
      value={props.value}
      required={props.required}
      tabIndex='0'
      onChange={props.onChange}
      disabled={props.isDisabled}
      aria-disabled={props.disabled}
    >
      {props.children &&
        props.children
      }

      {props.options && !props.value &&
        <option disabled value={''}>{props.defaultOption}</option>
      }

      {props.options && props.options.map((option, i) => {
        return (
          <option key={`${option[props.optionKey]}-${i}`} value={option[props.optionValue]}>
            {option[props.optionLabel]}
          </option>
        );
      })}
    </select>
  );
};

Select.defaultProps = {
  className: style.container,
  errorClassName: style.hasError,
  disabledClassName: style.isDisabled,
  defaultOption: '-----',
  hasError: false,
  required: false
};

Select.propTypes = {
  className: PropTypes.string,
  errorClassName: PropTypes.string,
  disabledClassName: PropTypes.string,
  name: PropTypes.string,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]),
  value: PropTypes.any,
  id: PropTypes.string,
  options: PropTypes.array,
  optionKey: PropTypes.string,
  optionValue: PropTypes.string,
  optionLabel: PropTypes.string,
  hasError: PropTypes.bool,
  required: PropTypes.bool, // not sure about this, nothing is actually passed
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func
};

export default Select;
