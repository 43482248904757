import React from 'react';
import PropTypes from 'prop-types';
import hints from '../../constants/patternHintText';
import regions from '../../constants/regions';
import Form from '../core/Form';
import FormRow from '../core/FormRow';
import Input from '../core/Input';
import Select from '../core/Select';
import ButtonSet from '../core/ButtonSet';
import StatusMessage from '../core/StatusMessage';
import LinkAwsButtonContainer from '../linkAwsButton/LinkAwsButtonContainer';

const CreateEnvironment = (props) => {
  const {
    account,
    hasError,
    hasSelectedAddAccount,
    isProviderLimitMaxed,
    isLoading,
    isVersionOk,
    onClickCancel,
    onInputChange,
    onSubmit,
    selectedAccountId,
    selectedEnvironment
  } = props;

  return (
    <div>
      <h1>Add an Environment</h1>
      <p>Choose an existing AWS account and region or link a new account to create a new environment:</p>
      <Form
        onSubmit={onSubmit}
        disabled={isLoading}
      >
        {hasError &&
          <StatusMessage type='error' text={`${selectedEnvironment} already exists`} />
        }
        {account.accountId && !isVersionOk &&
          <StatusMessage type='error' text={`Please update your Stackery Role before creating an environment.`} />
        }
        <FormRow key='label_account' label='AWS Account Id'>
          <Select
            name='selectedAccountId'
            onChange={onInputChange}
            value={selectedAccountId}
          >
            <option disabled value={''}>Choose AWS Account</option>
            {account.providers.map(provider =>
              <option key={provider.accountId} value={provider.accountId}>{provider.accountId}</option>
            )}
            {!isProviderLimitMaxed &&
              <option value='addAccount'>Add New AWS Account</option>
            }
          </Select>
        </FormRow>
        {hasSelectedAddAccount &&
          <LinkAwsButtonContainer />
        }
        {selectedAccountId && isVersionOk && !hasSelectedAddAccount &&
          <FormRow label='Region'>
            <Select
              name='selectedRegion'
              defaultValue={account.region}
              onChange={onInputChange}
            >
              {regions.map(region =>
                <option key={region.region} value={region.region}>{region.name}</option>
              )}
            </Select>
          </FormRow>
        }
        {selectedAccountId && isVersionOk && !hasSelectedAddAccount &&
          <FormRow label='Environment Name' hint={hints['[a-zA-Z0-9_-]*']}>
            <Input
              name='selectedEnvironment'
              required
              pattern='[a-zA-Z0-9_-]*'
              inputRef={input => input && input.focus()}
              hasError={hasError}
              onChange={onInputChange}
            />
          </FormRow>
        }
        <ButtonSet
          primaryButton={{
            text: 'Add Environment',
            type: 'submit',
            isLoading: isLoading,
            isDisabled: !selectedAccountId
          }}
          secondaryButton={{
            text: 'Cancel',
            onClick: onClickCancel
          }}
        />
      </Form>
    </div>
  );
};

CreateEnvironment.propTypes = {
  account: PropTypes.object.isRequired,
  hasError: PropTypes.bool,
  hasSelectedAddAccount: PropTypes.bool,
  isLoading: PropTypes.bool,
  isVersionOk: PropTypes.bool,
  onClickCancel: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  selectedAccountId: PropTypes.string,
  selectedEnvironment: PropTypes.string
};

export default CreateEnvironment;
