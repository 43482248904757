import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ButtonSet from '../core/ButtonSet';
import Link from '../core/Link';
import style from './VerifyEmail.css';

const VerifyEmail = props => {
  const {
    currentUser,
    resendVerifyEmail
  } = props;

  return (
    <div className={style.container}>
      {currentUser.hasVerifiedEmail &&
        <Fragment>
          <h2>Email verified</h2>
          <p>Awesome! Thanks for verifying your email!</p>
        </Fragment>
      }

      {!currentUser.hasVerifiedEmail &&
        <Fragment>
          <h2>Verify your email address</h2>
          <p>An email with verification instructions has been sent to {currentUser.email}.</p>
          <p>Didn't receive an email? <Link text='Resend verification email' onClick={resendVerifyEmail} /></p>
        </Fragment>
      }
      <ButtonSet
        primaryButton={{
          text: 'Next Step',
          to: '/setup/link-aws',
          isDisabled: !currentUser.hasVerifiedEmail
        }}
        secondaryButton={{
          text: 'Skip Step',
          to: '/setup/link-aws'
        }}
      />
    </div>
  );
};

VerifyEmail.propTypes = {
  hasHmac: PropTypes.bool
};

export default VerifyEmail;
