import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import cloneDeep from 'clone-deep';
import actions from '../../actions/nodeDeploymentInfo';
import appActions from '../../actions/app';
import { injectContext } from '../../resources/manageCFResources';
import Parameter from '../../resources/parameter';
import selectors from '../../selectors';
import { getNodeType } from '../../utils/calculateNode';
import contextAddResourceData from '../../utils/contextAddResourceData';
import { getCustomResourceName, getCustomResourceLabel } from '../../resources/getCustomResourceInfo';
import NodeDeploymentInfo from './NodeDeploymentInfo';

const mapStateToProps = (state) => {
  return createSelector(
    (appState) => appState.stack,
    (appState) => appState.nodeDeploymentInfo,
    (appState) => appState.deployments,
    (appState) => appState.deploymentNodes,
    (appState) => appState.gitProviders,
    selectors.nodeTypes,
    (stack, nodeDeploymentInfo, deployments, deploymentNodes, gitProviders, nodeTypes) => {
      /* It's possible we get re-rendered before we are removed from the DOM by
       * the StackContainer/Stack components when there is no longer a deployed
       * node to show info for. */
      if (!nodeDeploymentInfo.node) {
        return {};
      }

      return {
        stack,
        node: nodeDeploymentInfo.node,
        type: nodeTypes.types[getNodeType(nodeDeploymentInfo.node)],
        deployments,
        deployment: deployments.deployments.filter((deployment) => deployments.selectedDeploymentId === deployment.id)[0],
        deploymentNodes: deploymentNodes,
        stackEnvironmentId: deployments.stackEnvironmentId,
        gitProviders
      };
    }
  );
};

const mapDispatchToProps = {
  close: actions.close,
  selectStackResource: appActions.selectStackResource,
  deselectStackResource: appActions.deselectStackResource
};

const calculateLogicalId = (format, resource, resources) => {
  if (format === 'serverless' && resource.Type === 'timer') {
    /* Serverless framework postfixes a number onto the schedule events equal
     * to its event position among all schedule events for the function. */
    const fnName = resources.resources[resource.VirtualEventSourceFunctionId].Settings.Name;
    const fnResource = resources.template.functions[fnName];

    let scheduleId = 1;
    for (let i = 0; i < Number(resource.VirtualEventSourceName); i++) {
      const event = fnResource.events[i];

      if ('schedule' in event) {
        scheduleId++;
      }
    }

    return `${fnName[0].toUpperCase() + fnName.slice(1)}EventsRuleSchedule${scheduleId}`;
  } else if (resource.Type === 'implicitApi') {
    return (format === 'serverless') ? 'ApiGatewayRestApi' : 'ServerlessRestApi';
  } else {
    return resource.Id;
  }
};

class NodeDeploymentInfoContainer extends Component {
  constructor (props) {
    super(props);

    const {
      node,
      type,
      deploymentNodes,
      stackEnvironmentId,
      stack
    } = props;

    const deploymentInfo = {
      name: deploymentNodes.environment,
      id: stackEnvironmentId
    };

    // TODO: These should be set up in the connector
    const state = {
      name: node.name || node.id,
      paletteResource: props.type.paletteResource,
      paletteInfo: props.type.paletteInfo,
      paletteDocsLink: props.type.paletteDocsLink
    };

    if (typeof type.deploymentProperties === 'function') {
      state.data = type.deploymentProperties.call(
        node,
        deploymentInfo
      );

      if (node.reference) {
        state.data.arn = node.reference.arn;
      } else if (node.deploymentInfo) {
        state.data.arn = node.deploymentInfo.arn;
        state.data.arnLink = type.arnLink ? type.arnLink.call(node) : null;
        state.data.layers = node.deploymentInfo.layers;
      }

      if (props.node.type === 'function') {
        state.codeDir = `Stackery/functions/${node.name}`;
      }
    } else {
      const { resources, resourceData, awsAccountId, region, stackData } = deploymentNodes.deployments[deploymentNodes.selectedDeploymentId];
      const resourceId = node.id;
      const resource = resources.resources[resourceId];
      const parameters = stackData && stackData.Parameters.reduce((params, param) => {
        const value = 'ResolvedValue' in param ? param.ResolvedValue : param.ParameterValue;

        params[param.ParameterKey] = value;

        return params;
      }, {});

      const logicalId = calculateLogicalId(stack.format, resource, resources);

      const context = {
        resourceId,
        name: resources.resources[node.id].Name || resourceId,
        stackName: stack.name,
        environmentName: deploymentNodes.environment,
        awsAccountId,
        region,
        namespace: stackEnvironmentId
      };

      if (resourceData && node.type !== 'errors') {
        contextAddResourceData(resourceData[logicalId], context);

        /* Look for an existing resource and use it if found. It's possible both
         * existing resources and new resources are provisioned (e.g. SAM
         * functions), so we want to prefer the existing resource. */
        const resourceDataId = (`${resourceId}ExistingResource` in resourceData) ? `${resourceId}ExistingResource` : resourceId;

        const id = resource.VirtualEventSourceName ? logicalId : resourceDataId;
        if (id in resourceData) {
          context.physicalId = resourceData[id].physicalId;
        }
      }

      const settings = resources.resources[node.id].Settings || {};
      for (const settingName in settings) {
        if (settings[settingName] instanceof Parameter) {
          const value = parameters ? parameters[settings[settingName].ParameterId] : `<Parameter ${settings[settingName].ParameterId}>`;
          context[`SETTING:${settingName}`] = value;
        } else {
          context[`SETTING:${settingName}`] = resources.getResourceSetting(resourceId, resource.facetType, resource.facetId, settingName);
        }
      }

      let template = resources.cfTemplate();
      if (template.Resources && node.id in template.Resources) {
        const properties = template.Resources[node.id].Properties || {};
        for (const propertyName in properties) {
          context[`PROPERTY:${propertyName}`] = properties[propertyName];
        }

        if (props.node.type === 'function') {
          state.codeDir = properties.CodeUri;
        }
      }

      // Custom/Arbitrary resources's name, type and description are dynamic and based on
      // the resources it contains
      if (node.type === 'custom') {
        state.name = getCustomResourceName(node.id, undefined, node);
        state.paletteResource = getCustomResourceLabel(node.id, undefined, node);

        let resourceTypes = Object.keys(node.CloudFormation.Resources).map(resourceId => node.CloudFormation.Resources[resourceId].Type);
        // This is to uniquify the list of types
        resourceTypes = Array.from(new Set(resourceTypes)).join(', ');
        state.paletteInfo = `This is a group of related CloudFormation resources that include ${resourceTypes}`;
      }

      state.label = injectContext(cloneDeep(type.label), context);
      state.data = injectContext(cloneDeep(type.deploymentProperties), context);

      // We don't have this data for previous deployments
      if (!resourceData) {
        delete state.data.arn;
        delete state.data.arnLink;
        delete state.data.consoleLinks;
      }
    }

    this.state = state;
    this.handleShadeClick = this.handleShadeClick.bind(this);
  }

  componentDidMount () {
    this.props.selectStackResource(this.props.node.id);
  }

  shouldComponentUpdate (nextProps) {
    /**
     * The link to view source code in git for a function relies on deployment.version
     * selectStackEnvironment (invoked on environment change)
     * clears out deployment making it undefined for a single render cycle
     */
    return nextProps.deployment !== undefined;
  }

  handleShadeClick () {
    this.props.close();
    this.props.deselectStackResource();
  }

  render () {
    if (!this.props.node) {
      return null;
    }

    return (
      <NodeDeploymentInfo
        {...this.props}
        {...this.state}
        onUpdateEditor={this.updateEditor}
        onCancel={this.handleShadeClick}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NodeDeploymentInfoContainer);
