import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import * as states from '../../constants/states';
import apiActions from '../../actions/api';
import CreateToken from './CreateToken';

const mapStateToProps = () => {
  return createSelector(
    (appState) => appState.tokens,
    (tokens) => {
      return {
        token: tokens.token,
        isLoading: tokens.createState === states.LOADING
      };
    }
  );
};

const mapDispatchToProps = {
  createCliToken: apiActions.createCliToken
};

class CreateTokenContainer extends Component {
  constructor (props) {
    super(props);

    this.state = {
      id: null,
      description: null,
      secret: null
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidUpdate (prevProps, prevState) {
    const {
      token
    } = this.props;

    if (token.secret && token.secret !== prevProps.token.secret) {
      this.setState({
        id: token.id,
        secret: token.secret
      });
    }
  }

  handleInputChange (event) {
    const {
      name,
      value
    } = event.target;

    this.setState({
      [name]: value
    });
  }

  handleSubmit (event) {
    event.preventDefault();
    this.props.createCliToken(this.state.description);
  }

  render () {
    return (
      <CreateToken
        {...this.props}
        {...this.state}
        onChange={this.handleInputChange}
        onSubmit={this.handleSubmit}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateTokenContainer);
