import * as states from './states';

export const NEW = states.NEW;
export const LOADING = states.LOADING;
export const OKAY = states.OKAY;
export const PERSONAL_ACCESS_TOKEN_NEEDED = 'personal_access_token_needed';
export const URL_ACQUIRED = 'url_acquired';
export const FETCHING_TOKEN = 'fetching_token';
export const FETCHING_BRANCHES = 'fetching_branches';
export const FAILED = states.FAILED;
export const AUTHORIZED = 'authorized';
export const UNAUTHORIZED = 'unauthorized';
export const NOTIFY_AUTHORIZATION_SUCCESS = 'Github authorization successful';
