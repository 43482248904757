import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import * as verificationStates from '../../constants/verificationStates';
import formatTimestamp from '../../utils/formatTimestamp';
import makeCodeBuildLogsLink from '../../utils/makeCodeBuildLogsLink';
import Link from '../core/Link';
import JobItemHeader from './JobItemHeader';
import JobItemTasksList from './JobItemTasksList';
import JobItemActions from './JobItemActions';
import style from './JobItem.css';

const JobItemDeployed = React.forwardRef((props, ref) => {
  const {
    pipelineId,
    stack,
    stageIndex,
    stages,
    deployed,
    gitProviders
  } = props;

  const isLastStage = (stages.length - 1) === stageIndex;

  const isManualDeployment = deployed.deploymentMethod === 'manual';
  const isPipelineDeployment = deployed.deploymentMethod === 'pipeline';

  const currentStageEnvironmentName = stages[stageIndex].environment.name;

  const isPromoting = !deployed.isManualApprovalPending && deployed.isPromoting;
  const isPromotable = deployed.isManualApprovalPending && !isLastStage;
  const isPromotedTo = isPipelineDeployment && !!deployed.promotedTo && !isPromoting;
  const isAutoPromote = stages[stageIndex].autoPromote;

  const needsVerification = !isLastStage && isPipelineDeployment && (stack.steps.length > 0 || deployed.steps.length > 0);

  const isStarted = needsVerification && deployed.steps.filter(step => step.status === verificationStates.STARTED).length > 0;
  const isSucceeded = needsVerification && !isPromoting && !isStarted && deployed.steps.length === deployed.steps.filter(step => step.status === verificationStates.SUCCEEDED).length;
  const isFailed = needsVerification && !isPromoting && !isStarted && deployed.steps.filter(step => step.status === verificationStates.FAILED).length > 0;

  const isFinished = !needsVerification && !isPromotable && !isPromoting && !isPromotedTo;

  let status;

  if (isPromotedTo) {
    status = 'Promoted';
  } else if (isSucceeded) {
    status = 'Verified';
  } else if (isFailed) {
    status = 'Failed';
  } else if (isStarted || isPromoting) {
    status = 'Started';
  } else {
    status = 'Deployed';
  }

  return (
    <div className={classnames(style.container, props.rowIndex === 1 && style.isSiblingRow)} ref={ref}>
      <div className={classnames(style.content, style.isDeployed)}>
        <JobItemHeader
          link={stack.stackLink}
          status={status}
          version={deployed.version}
          timestamp={deployed.deploymentTimestamp}
          gitProviders={gitProviders}
        />
        {needsVerification &&
          <JobItemTasksList {...props} />
        }
        {isManualDeployment &&
          <p>Deployed outside of pipeline {deployed.user && <Fragment> by {deployed.user} </Fragment>} <span className={style.timestamp}>{formatTimestamp(deployed.deploymentTimestamp, true)}</span></p>
        }
        {isPipelineDeployment && !isPromotedTo && !needsVerification &&
          <p>
            Deployed <span className={style.timestamp}>{formatTimestamp(deployed.deploymentTimestamp, true)}</span>. {''}
            {deployed.logs &&
              <Link href={deployed.logs} text='View logs' target='_blank' />
            }
            {!deployed.logs && deployed.codebuildId &&
              <Link href={makeCodeBuildLogsLink(deployed.codebuildId, stages[stageIndex].environment.region, 'StackeryPipelineRunner')} text='View logs' target='_blank' />
            }
          </p>
        }
        {!isAutoPromote && isPromotedTo && !needsVerification &&
          <p>Promoted
            {deployed.promotedTo && <Fragment> to <Link href={`environments/${props.owner}/${deployed.promotedTo}`} text={deployed.promotedTo} /></Fragment>}
            {deployed.user && <Fragment> by {deployed.user} </Fragment>}
            <span className={style.timestamp}> {formatTimestamp(deployed.deploymentTimestamp, true)}</span>. {''}
            {deployed.logs && <Link href={deployed.logs} text='View logs' target='_blank' />}
          </p>
        }
        {isAutoPromote && isPromotedTo && !needsVerification &&
          <p>Auto-promoted
            {deployed.promotedTo && <Fragment> to <Link href={`environments/${props.owner}/${deployed.promotedTo}`} text={deployed.promotedTo} /></Fragment>}
            <span className={style.timestamp}> {formatTimestamp(deployed.deploymentTimestamp, true)}</span>. {''}
            {deployed.logs && <Link href={deployed.logs} text='View logs' target='_blank' />}
          </p>
        }
        <JobItemActions
          {...props}
          viewLink={`stacks/${props.owner}/${stack.stackName}/view/${currentStageEnvironmentName}`}
          isVerifying={isStarted && !isPromotedTo}
          isFailed={isFailed}
          isRetrying={deployed.isRetrying}
          isRetried={deployed.isRetried}
          isPromoted={isPromotedTo}
          isPromoting={isPromoting}
          isPromotable={isPromotable}
          isFinished={isFinished}
          handlePromote={() => props.promoteDeploymentPipeline(props.owner, pipelineId, stack.stackId, deployed.deploymentHistoryId, deployed.version, stageIndex)}
          handleRetry={() => props.retryDeploymentPipeline(props.owner, pipelineId, stack.stackId, deployed.deploymentHistoryId, deployed.version, stageIndex)}
        />
      </div>
    </div>
  );
});

JobItemDeployed.propTypes = {
  stack: PropTypes.object.isRequired,
  stageIndex: PropTypes.number.isRequired,
  deployed: PropTypes.object.isRequired
};

export default JobItemDeployed;
