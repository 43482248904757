import React from 'react';
import { Route, Redirect, Switch } from 'react-router';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';
import Spinner from '../core/Spinner';
import EnvironmentOverviewContainer from '../environmentOverview/EnvironmentOverviewContainer';
import EnvironmentParametersContainer from '../environmentParameters/EnvironmentParametersContainer.js';
import EnvironmentSecretsContainer from '../environmentSecrets/EnvironmentSecretsContainer.js';
import EnvironmentHeader from './EnvironmentHeader';
import EnvironmentNav from './EnvironmentNav';
import style from './Environment.css';

const Environment = props => {
  const {
    match,
    isLoaded
   } = props;

  const {
    environment
  } = props.environment;

  if (!isLoaded) {
    return <Spinner />;
  }

  return (
    <div className={style.container}>
      <DocumentTitle title={`Stackery Environment: ${environment}`} />
      <EnvironmentHeader
        environment={props.environment}
        onDeleteClick={props.onDeleteClick}
        isLoaded={props.isLoaded}
      />
      <main className={style.main}>
        <EnvironmentNav match={match} />
        <div className={style.content}>
          <Switch>
            <Route exact path={`${match.path}/overview`} component={EnvironmentOverviewContainer} />
            <Route exact path={`${match.path}/parameters`} component={EnvironmentParametersContainer} />
            <Route exact path={`${match.path}/secrets`} component={EnvironmentSecretsContainer} />
            <Redirect to={`${match.url}/overview`} />
          </Switch>
        </div>
      </main>
    </div>
  );
};

Environment.propTypes = {
  environment: PropTypes.object.isRequired,
  onDeleteClick: PropTypes.func.isRequired
};

export default Environment;
