import React, { Fragment } from 'react';
import Link from '../core/Link';
import style from './RenderError.css';

const RenderError = (props) => {
  const {
    templateError,
    onResetTemplate
  } = props;

  const errorMessage = templateError.message;

  return (
    <div className={style.container}>
      <p>There was an issue parsing your template.{' '}
        {onResetTemplate &&
          <Fragment>
            Fix it in Template Mode or <Link onClick={onResetTemplate}>undo your changes</Link>.
          </Fragment>
        }
      </p>
      {errorMessage &&
        <div className={style.error}>
          {errorMessage.split(/\n/).map(line => {
            return <div key={line} className={style.error}>{line}</div>;
          })}
        </div>
      }
    </div>
  );
};

RenderError.propTypes = {
};

export default RenderError;
