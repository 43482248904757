import React from 'react';
import PropTypes from 'prop-types';
import * as gitProviderStates from '../../constants/gitProviderStates';
import Button from '../core/Button';
import LinkButton from '../core/LinkButton';
import Link from '../core/Link';
import Spinner from '../core/Spinner';
import style from './Modal.css';

const importError = 'If the request timed out, imports will continue to process on the server.';

const ImportGithubRepos = props => {
  const github = props.gitProviders.github;

  return (
    <div>
      <h1>Import GitHub Projects</h1>
      <p>Quickly import existing serverless GitHub projects as Stackery stacks. Repos that contain a valid template.yaml file will be imported as a stack with the same name.</p>

      <ul className={style.providerList}>
        {github.state === gitProviderStates.URL_ACQUIRED &&
          <li className={style.providerListItem}>
            <p>To import your projects from GitHub, you need to link your Stackery and GitHub accounts.</p>
            <LinkButton
              key='githubOAuth'
              name='githubOAuth'
              href={github.authUrl}
              target='_new'
              text='Link to GitHub'
              icon='github'
              hid='link to github'
            />
          </li>
        }

        {github.state === gitProviderStates.AUTHORIZED &&
          <li className={style.providerListItem}>
            <Button
              text='Import GitHub Projects'
              icon='github'
              onClick={() => props.getGitProjects('github')}
              isLoading={github.importedState === gitProviderStates.LOADING}
              hid='import github projects'
            />
            {github.importedState === gitProviderStates.LOADING &&
              <p>Fetching GitHub projects...</p>
            }
            {github.importedState === gitProviderStates.OKAY && github.imported.length > 0 &&
              <p>Successfully imported {github.imported.length} projects! <Link to='/stacks' onClick={props.hideModal}>View Stacks</Link></p>
            }
            {github.importedState === gitProviderStates.OKAY && github.imported.length === 0 &&
              <p>No projects with a valid template.yaml were found or they were already imported. <Link onClick={props.hideModal} to='/stacks'>View Stacks</Link></p>
            }
            {github.error &&
              <p>Error importing: {github.error}</p>
            }
            {github.error === 'Failed to fetch' &&
              <p>{importError}</p>
            }
          </li>
        }
      </ul>

      {github.state === gitProviderStates.NEW &&
        <Spinner position='static' />
      }
    </div>
  );
};

ImportGithubRepos.propTypes = {
  gitProviders: PropTypes.object
};

export default ImportGithubRepos;
