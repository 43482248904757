import React, { Component } from 'react';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import * as states from '../../constants/states';
import apiActions from '../../actions/api';
import appActions from '../../actions/app';
import EnvironmentSecrets from './EnvironmentSecrets';

const mapStateToProps = () => {
  return createSelector(
    (appState) => appState.environment,
    (environment) => {
      return {
        environment,
        isLoaded: environment.secretsState === states.OKAY,
        isLoading: environment.secretsState === states.NEW || environment.secretsState === states.LOADING,
        isEmpty: !!environment.secrets && Object.keys(environment.secrets).length === 0,
        isFailed: environment.secretsState === states.FAILED
      };
    }
  );
};

const mapDispatchToProps = {
  getEnvironmentSecrets: apiActions.getEnvironmentSecrets,
  showCreateEnvironmentSecretModal: appActions.showCreateEnvironmentSecretModal,
  showEditEnvironmentSecretDescriptionModal: appActions.showEditEnvironmentSecretDescriptionModal,
  showUpdateEnvironmentSecretValueModal: appActions.showUpdateEnvironmentSecretValueModal,
  showDeleteEnvironmentSecretModal: appActions.showDeleteEnvironmentSecretModal,
  createEnvironmentSecret: apiActions.createEnvironmentSecret,
  saveEnvrionmentSecretDescription: apiActions.saveEnvrionmentSecretDescription,
  saveEnvironmentSecretValue: apiActions.saveEnvrionmentSecretValue,
  deleteEnvironmentSecret: apiActions.deleteEnvironmentSecret
};

class EnvironmentSecretsContainer extends Component {
  constructor (props) {
    super(props);

    this.handleAddEnvironmentSecretClick = this.handleAddEnvironmentSecretClick.bind(this);
    this.handleEditEnvironmentSecretDescriptionClick = this.handleEditEnvironmentSecretDescriptionClick.bind(this);
    this.handleSetEnvironmentSecretValueClick = this.handleSetEnvironmentSecretValueClick.bind(this);
    this.handleDeleteEnvironmentSecretClick = this.handleDeleteEnvironmentSecretClick.bind(this);
  }

  componentDidMount () {
    const {
      owner,
      environment
    } = this.props.match.params;

    this.props.getEnvironmentSecrets(owner, environment);
  }

  handleAddEnvironmentSecretClick (params) {
    this.props.showCreateEnvironmentSecretModal({
      environment: this.props.environment.environment,
      owner: this.props.environment.owner,
      onSubmit: this.props.createEnvironmentSecret,
      onClickCancel: this.props.hideModal
    });
  }

  handleEditEnvironmentSecretDescriptionClick (params) {
    this.props.showEditEnvironmentSecretDescriptionModal({
      environment: params.environment,
      owner: params.owner,
      secret: params.secret,
      description: params.description,
      onSubmit: this.props.saveEnvrionmentSecretDescription,
      onClickCancel: this.props.hideModal
    });
  }

  handleSetEnvironmentSecretValueClick (params) {
    this.props.showUpdateEnvironmentSecretValueModal({
      environment: params.environment,
      owner: params.owner,
      secret: params.secret,
      value: params.value,
      onSubmit: this.props.saveEnvironmentSecretValue,
      onClickCancel: this.props.hideModal
    });
  }

  handleDeleteEnvironmentSecretClick (params) {
    this.props.showDeleteEnvironmentSecretModal({
      environment: params.environment,
      owner: params.owner,
      secret: params.secret,
      onSubmit: this.props.deleteEnvironmentSecret,
      onClickCancel: this.props.hideModal
    });
  }

  render () {
    return (
      <EnvironmentSecrets
        {...this.props}
        onAddSecretClick={this.handleAddEnvironmentSecretClick}
        onEditSecretDescriptionClick={this.handleEditEnvironmentSecretDescriptionClick}
        onSetSecretValueClick={this.handleSetEnvironmentSecretValueClick}
        onDeleteSecretClick={this.handleDeleteEnvironmentSecretClick}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EnvironmentSecretsContainer);
