import React from 'react';
import { escapeSpecialChars } from './escapePatterns';

export default (searchTerm, value) => {
  if (!searchTerm) {
    return value;
  }

  const escapedSearchTerm = escapeSpecialChars(searchTerm);
  const parts = value.split(new RegExp('(' + escapedSearchTerm + ')', 'gi'));

  for (let i = 1; i < parts.length; i += 2) {
    parts[i] = <u key={`${parts[i]}-${i}`}>{parts[i]}</u>;
  }

  return <span>{parts}</span>;
};

// thank you @sophiebits https://github.com/facebook/react/issues/3386#issuecomment-78605760
