export const types = {
  EDIT_RESOURCE: 'APP::RESOURCE_EDITOR::EDIT_RESOURCE',
  SAVE_RESOURCE: 'APP::RESOURCE_EDITOR::SAVE_RESOURCE',
  UPDATE_SETTING: 'APP::RESOURCE_EDITOR::UPDATE_SETTING',
  UPDATE_RESOURCE_ID: 'APP::RESOURCE_EDITOR::UPDATE_RESOURCE_ID',
  CANCEL: 'APP::RESOURCE_EDITOR::CANCEL'
};

const actions = {
  editResource: (format, resource, facetType, facetId) => ({
    type: types.EDIT_RESOURCE,
    format,
    resource,
    facetType,
    facetId
  }),
  saveResource: (resourceId, settings) => ({ type: types.SAVE_RESOURCE, resourceId, settings }),
  updateSetting: (settingId, value) => ({
    type: types.UPDATE_SETTING,
    settingId,
    value
  }),
  updateResourceId: (oldResourceId, newResourceId) => ({
    type: types.UPDATE_RESOURCE_ID,
    oldResourceId,
    newResourceId
  }),
  cancel: () => ({ type: types.CANCEL })
};

export default actions;
