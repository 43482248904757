import { types as apiTypes } from '../actions/api';
import { types as appTypes } from '../actions/app';
import * as states from '../constants/states';

export const initialState = {
  state: states.NEW,

  /* Contains a list of environment objects. Environment objects contain the following
   * properties:
   *
   * name: Environment name
   * owner: Environment owner
   */
  data: [],

  // Stores an environment id in a list-view operation (e.g. deleteEnvironment)
  selectedId: null
};

function isMultiAccount (environments) {
  let accounts = Object.keys(environments).reduce((accounts, env) => {
    let accountId = environments[env].accountId;

    if (accounts.indexOf(accountId) === -1) {
      accounts.push(accountId);
    }

    return accounts;
  }, []);

  return (accounts.length > 1);
}

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case apiTypes.GET_ENVIRONMENTS.REQUEST:
      return {
        ...initialState,
        state: states.LOADING
      };

    case apiTypes.GET_ENVIRONMENTS.FAILURE:
      return {
        ...state,
        state: states.FAILED
      };

    case apiTypes.GET_ENVIRONMENTS.SUCCESS:
      return {
        ...state,
        state: states.OKAY,
        data: action.data.map(item => ({ ...item, isMultiAccount: isMultiAccount(action.data) }))
      };

    case apiTypes.CREATE_ENVIRONMENT.SUCCESS:
      const environment = action.data;

      return {
        ...state,
        data: state.data.concat({ ...environment })
      };

    case apiTypes.DELETE_ENVIRONMENT.SUCCESS:
      return {
        ...state,
        data: state.data.filter((environment) => {
          return (environment.name !== action.name || environment.owner !== action.owner);
        }),
        selectedId: null
      };

    case appTypes.SHOW_DELETE_ENVIRONMENT_MODAL:
      return {
        ...state,
        selectedId: action.environment.id
      };

    case apiTypes.DELETE_ENVIRONMENT.FAILURE:
    case appTypes.HIDE_MODAL:
      return {
        ...state,
        selectedId: null
      };

    default:
      return state;
  }
};
