import React from 'react';
import PropTypes from 'prop-types';
import * as gitProviderStates from '../../constants/gitProviderStates';
import Button from '../core/Button';
import LinkButton from '../core/LinkButton';
import Link from '../core/Link';
import Spinner from '../core/Spinner';
import style from './Modal.css';

const importError = 'If the request timed out, imports will continue to process on the server.';

const ImportGitlabRepos = props => {
  const gitlab = props.gitProviders.gitlab;

  return (
    <div>
      <h1>Import GitLab Projects</h1>
      <p>Quickly import existing serverless GitLab projects as Stackery stacks. Repos that contain a valid template.yaml file will be imported as a stack with the same name.</p>

      <ul className={style.providerList}>
        {gitlab.state === gitProviderStates.URL_ACQUIRED &&
          <li className={style.providerListItem}>
            <p>To import your projects from GitLab, you need to link your Stackery and GitLab accounts.</p>
            <LinkButton
              key='gitlabOAuth'
              name='gitlabOAuth'
              href={gitlab.authUrl}
              target='_new'
              text='Link to GitLab'
              icon='gitlab'
              hid='link to gitlab'
            />
          </li>
        }

        {gitlab.state === gitProviderStates.AUTHORIZED &&
          <li className={style.providerListItem}>
            <Button
              text='Import GitLab Projects'
              icon='gitlab'
              onClick={() => props.getGitProjects('gitlab')}
              isLoading={gitlab.importedState === gitProviderStates.LOADING}
              hid='import gitlab projects'
            />
            {gitlab.importedState === gitProviderStates.LOADING &&
              <p>Fetching GitLab projects...</p>
            }
            {gitlab.importedState === gitProviderStates.OKAY && gitlab.imported.length > 0 &&
              <p>Successfully imported {gitlab.imported.length} projects! <Link to='/stacks' onClick={props.hideModal}>View Stacks</Link></p>
            }
            {gitlab.importedState === gitProviderStates.OKAY && gitlab.imported.length === 0 &&
              <p>No projects with a valid template.yaml were found or they were already imported. <Link onClick={props.hideModal} to='/stacks'>View Stacks</Link></p>
            }
            {gitlab.error &&
              <p>Error importing: {gitlab.error}</p>
            }
            {gitlab.error === 'Failed to fetch' &&
              <p>{importError}</p>
            }
          </li>
        }
      </ul>

      {gitlab.state === gitProviderStates.NEW &&
        <Spinner position='static' />
      }
    </div>
  );
};

ImportGitlabRepos.propTypes = {
  gitProviders: PropTypes.object
};

export default ImportGitlabRepos;
