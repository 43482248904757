import React, { Component } from 'react';
import * as states from '../../constants/states';
import Form from '../core/Form';
import FormRow from '../core/FormRow';
import Input from '../core/Input';
import ButtonSet from '../core/ButtonSet';

class UpdateEnvironmentSecretValue extends Component {
  constructor (props) {
    super(props);

    this.state = {
      value: undefined
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange (event) {
    this.setState({ value: event.currentTarget.value });
  }

  handleSubmit (event) {
    event.preventDefault();
    this.props.onSubmit(
      this.props.owner,
      this.props.environment,
      this.props.secret,
      this.state.value
    );
  }

  render () {
    return (
      <Form onSubmit={this.handleSubmit}>
        <h1>Set New Secret Value</h1>
        <p>Set new value for <strong>{this.props.secret}</strong>.</p>
        <FormRow label='Secret Value'>
          <Input
            id='input-secretValue'
            name='secretValue'
            required
            onChange={this.handleInputChange}
          />
        </FormRow>
        <ButtonSet
          primaryButton={{
            text: 'Update',
            type: 'submit',
            isLoading: this.props.state === states.LOADING
          }}
          secondaryButton={{
            text: 'Cancel',
            onClick: this.props.onClickCancel
          }}
        />
      </Form>
    );
  }
}

UpdateEnvironmentSecretValue.propTypes = {
};

export default UpdateEnvironmentSecretValue;
