import cfYamlParser from 'cf-yaml-parser';
import State from '../resources';
import ERROR_CODES from '../constants/errorCodes';

// TODO: Only parse SAM templates
export default (yaml, format, isDeployView) => {
  let template;
  try {
    template = cfYamlParser(yaml);
  } catch (error) {
    error.code = ERROR_CODES.TEMPLATE_YAML_FORMAT_ERROR;
    throw error;
  }

  let state;
  try {
    state = new State(template, format, isDeployView);
  } catch (error) {
    error.code = ERROR_CODES.TEMPLATE_PARSE_ERROR;
    throw error;
  }

  return state;
};
