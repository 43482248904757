import makeActions from '../utils/makeActions';

export const types = {
  WATCH_TOKEN: 'AWS::WATCH_TOKEN',
  REFRESH_TOKEN: makeActions('AWS', 'REFRESH_TOKEN')
};

const actions = {
  watchToken: () => ({
    type: types.WATCH_TOKEN
  }),
  refreshToken: (environment, accountId) => ({
    type: types.REFRESH_TOKEN.REQUEST,
    environment,
    accountId
  })
};

export default actions;
