/* NOTE: This component uses hooks instead of lifecycle methods. This is a new pattern in the codebase. */

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import makeCodeBuildLogsLink from '../../utils/makeCodeBuildLogsLink';
import TimeAgo from '../core/TimeAgo';
import Link from '../core/Link';
import IconButton from '../core/IconButton';
import Icon from '../core/Icon';
import UserNotificationContent from './UserNotificationContent';
import style from './UserNotificationsListItem.css';

const findRegionById = props => {
  const found = props.regions.find(region => region.id === props.notification.environmentId);
  return found ? found.region : undefined;
};

const mapLevelToIcon = {
  info: 'info_outlined',
  success: 'check_circle_outline',
  warning: 'warning_amber',
  error: 'error_outline'
};

const UserNotificationsListItem = props => {
  const { notification } = props;

  const containerRef = React.useRef(null);
  const contentRef = React.useRef(null);
  const [region, setRegion] = React.useState(null);

  const hasCodebuildId = notification.codebuildId !== undefined;

  const handleDeleteClick = (event) => {
    if (notification.key && notification.timestamp) {
      props.deleteUserNotification(props.owner, notification);
    }
  };

  const handleErrorDetailsClick = (event) => {
    props.showDeploymentErrorsModal({ details: { ...notification, owner: props.owner } });
    props.onClose(event);
  };

  React.useEffect(() => {
    if (props.regions.length > 0) {
      setRegion(findRegionById(props));
    }
  }, [props.regions]);

  return (
    <li className={style.container} ref={containerRef} tabIndex='0'>
      <header className={style.header}>
        <Icon className={style.iconLevel} font={mapLevelToIcon[notification.level]} size='small' level={notification.level} />
        <strong className={style.title}>{notification.title}</strong>
        <TimeAgo timestamp={notification.timestamp} />
        <IconButton iconFont='delete_outline' className={style.deleteButton} hid='user-notification-delete-button' onClick={handleDeleteClick} />
      </header>
      <div className={style.content} ref={contentRef}>
        <UserNotificationContent {...props} />
      </div>

      {/* TODO: If more eventTypes are stored/handled, abstract footer into a component */}
      {notification.level === 'error' && notification.eventType === 'deployment' &&
        <footer className={classnames(style.footer, { [style.isMultiColumn]: hasCodebuildId && region })}>
          <Link text='View error details' hid={'user-notification-view-details-link'} onClick={handleErrorDetailsClick} />

          {hasCodebuildId && region && notification.deploymentMethod === 'pipeline' &&
            <Link href={makeCodeBuildLogsLink(notification.codebuildId, region, 'StackeryPipelineRunner')} text='View logs' target='_blank' hid='user-notification-view-logs-link' onClick={props.onClose} />
          }

          {hasCodebuildId && region && notification.deploymentMethod === 'manual' &&
            <Link href={makeCodeBuildLogsLink(notification.codebuildId, region)} text='View logs' target='_blank' hid='user-notification-view-logs-link' onClick={props.onClose} />
          }

          <IconButton iconFont='contact_support' className={style.chatButton} hid='user-notification-chat-button' />
        </footer>
      }
    </li>
  );
};

UserNotificationsListItem.propTypes = {
  owner: PropTypes.string.isRequired,
  regions: PropTypes.array.isRequired,
  notification: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  deleteUserNotification: PropTypes.func.isRequired,
  showDeploymentErrorsModal: PropTypes.func.isRequired
};

export default UserNotificationsListItem;
