import resolveBinding from './resolveBinding';
import cloneDeep from 'clone-deep';

export default (resourceDescription, settingName, settingSchema, template, object, context) => {
  try {
    return cloneDeep(resolveBinding(settingSchema, template, object, context));
  } catch (err) {
    err.message = `Invalid binding format for ${resourceDescription} setting ${settingName}: ${err.message}`;

    throw err;
  }
};
