// Taken from /stackery/utils/getStackFormat.js
// TODO: remove SAM default and serverless, make undefined default
export default (stack) => {
  if (typeof stack === 'object') {
    if ('nodes' in stack) {
      return 'stackery';
    }

    if (stack.service) {
      return 'serverless';
    }

    return 'SAM';
  }

  if (typeof stack === 'string') {
    if (stack.match(/^service:/)) {
      return 'serverless';
    }

    return 'SAM';
  }

  return undefined;
};
