import React from 'react';
import PropTypes from 'prop-types';
import makeGitLink from '../../utils/makeGitLink';
import highlightSubstring from '../../utils/highlightSubstring';
import Link from '../core/Link';
import GitLink from '../core/GitLink';
import Spinner from '../core/Spinner';
import ContextMenu from '../core/ContextMenu';
import Table from '../core/Table';

const StacksList = props => {
  const {
    stacks,
    gitProviders,
    searchTerm,
    isLoaded,
    isEmpty,
    onDeleteClick,
    onCreateClick,
    onRenameClick,
    onRepoClick
  } = props;

  return (
    <Table id='stackery-stacks' isStriped isEmpty={isEmpty || !stacks.length}>
      <thead>
        <tr>
          <th>Name</th>
          <th>Repository</th>
          <th />
        </tr>
      </thead>

      {!isLoaded &&
        <tbody>
          <tr>
            <td colSpan='4'><Spinner position='static' /></td>
          </tr>
        </tbody>
      }

      {isLoaded && isEmpty &&
        <tbody>
          <tr>
            <td colSpan='4'>No Stacks have been created. <Link onClick={onCreateClick}>Add a Stack</Link> to get started.</td>
          </tr>
        </tbody>
      }

      {isLoaded && !isEmpty &&
        <tbody>
          {stacks.length > 0 && stacks.map((stack, i) => (
            <tr key={stack.id}>
              <td>
                <Link to={`/stacks/${stack.owner}/${stack.name}`}>
                  {highlightSubstring(searchTerm, stack.name)}
                </Link>
              </td>
              <td>{<GitLink gitLink={makeGitLink(stack, gitProviders)} label={stack.version} />}</td>
              <td>
                <ContextMenu
                  items={[
                    {
                      label: 'Rename',
                      onClick: onRenameClick,
                      params: {
                        ...stack
                      }
                    },
                    {
                      label: 'Repository',
                      onClick: stack.isGitless ? undefined : onRepoClick,
                      params: {
                        ...stack
                      }
                    },
                    {
                      label: 'Delete',
                      onClick: onDeleteClick,
                      params: {
                        ...stack
                      }
                    }
                  ]}
                />
              </td>
            </tr>
          ))}
          {!stacks.length &&
            <tr>
              <td colSpan='4'>No stacks found that match <strong>{searchTerm}</strong></td>
            </tr>
          }
        </tbody>
      }
    </Table>
  );
};

StacksList.propTypes = {
  stacks: PropTypes.array
};

export default StacksList;
