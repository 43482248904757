import cloneDeep from 'clone-deep';
import {
  gridSize,
  labelMargin,
  nodeDefaultHeight,
  nodeDefaultWidth,
  nodeOutlineWidth,
  nodeMetricHeight,
  portPitch,
  portRadius,
  portsMargin
} from '../constants/canvas';
import { longestMetricLabel } from '../components/canvas/NodeMetric';
import * as definitions from '../resources/definitions';
import { injectContext } from '../resources/manageCFResources';
import { getCustomResourceLabel, getCustomResourceIcon, getCustomResourceName } from '../resources/getCustomResourceInfo';

const calculateTextWidth = (text, className, offset) => {
  const span = document.createElement('span');

  span.className = className;
  span.style.position = 'absolute';
  span.style.top = '-1000px';
  span.innerText = text || '';
  document.body.appendChild(span);
  const width = span.offsetWidth;
  document.body.removeChild(span);
  return offset + width;
};

export const getNodeType = (node) => (node.implements && node.implements !== 'none') ? node.implements : node.type;

export const calculateName = (node, resources) => {
  if (node.type === 'facet') {
    return node.label;
  } else if (node.type === 'custom') {
    return getCustomResourceName(node.id, resources, node);
  } else {
    return node.name || node.id;
  }
};

export const calculateResourceLabel = (types, node, resources) => {
  let type = getNodeType(node);
  if (type === 'custom') {
    return getCustomResourceLabel(node.id, resources, node);
  }
  return types[type].paletteResource;
};

export const calculateCustomLabel = (types, node) => {
  let type = getNodeType(node);
  return node.implements ? types[type].paletteLabel : undefined;
};

export const calculateIcon = (type, format, node, resources) => {
  // Currently re-uses source type icon. TODO: facet-specific icons?
  if (node && node.type === 'facet') {
    return definitions[format].ResourceTypes[node.sourceType].DashboardProperties.icon;
  }

  if (typeof type.icon === 'function') {
    return node ? type.icon(node) : type.icon();
  }

  if (node && node.type === 'custom') {
    return getCustomResourceIcon(node.id, resources, node);
  }
  return type.icon;
};

export const isContainer = (node, types, format) => {
  let containerType = getNodeType(node);

  const props = types[containerType];

  return containerType && props && props.zIndex < 0;
};

export const calculateLabel = (node, types) => {
  if (node.type === 'facet') {
    const sourceType = types[node.sourceType];
    const facetLabelDefinition = sourceType.facetLabels[node.facetType];

    const context = {};
    for (const key in node.facet.Properties) {
      context[`FACET:${key}`] = node.facet.Properties[key];
    }

    return injectContext(cloneDeep(facetLabelDefinition), context);
  }

  let label = types[node.type].label;

  try {
    label = (typeof label === 'function' ? label.call(Object.assign({}, node)) : label) || '';
  } catch (err) {
    console.warning('Node type definition error: ' + node.type + '.label', err);
    label = node.type;
  }

  return label;
};

export const calculateNodeOutputPorts = (node, type) => {
  if (node.outputs && node.outputs !== undefined && node.outputs > 0) {
    return node.outputs;
  }
  if (type.outputs && type.outputs !== undefined && type.outputs > 0) {
    return type.outputs;
  }

  return 0;
};

function calculateNodePortsHeight (node, type) {
  return calculateNodeOutputPorts(node, type) * portPitch + portsMargin;
}

export const calculateNodeTitleBarHeight = (node, type) => {
  let height = nodeDefaultHeight;

  if (!node.metrics) {
    height = Math.max(height, calculateNodePortsHeight(node, type));
  }

  return height;
};

export const calculateInitialNodeHeight = (node, type) => {
  let portsHeight = calculateNodePortsHeight(node, type);
  let titleBarHeight = calculateNodeTitleBarHeight(node, type);
  const metricsHeight = node.metrics ? type.numVisibleMetrics * nodeMetricHeight : 0;

  // node.height from ELK contains metrics height already if this is a faceted resource
  return Math.max(titleBarHeight + metricsHeight, portsHeight, node.height || 0) + metricsHeight;
};

export const calculateNodeHeight = (node, types) => {
  let type = getNodeType(node);

  let portsHeight = calculateNodePortsHeight(node, type);
  let titleBarHeight = calculateNodeTitleBarHeight(node, type);

  const nodeHeight = node.height || type.initialHeight || 0;

  let metricsHeight = 0;

  // numVisibleMetrics is only defined for non-stack.json stacks
  if (types[type].numVisibleMetrics) {
    metricsHeight = node.metrics ? types[type].numVisibleMetrics * nodeMetricHeight : 0;
  } else {
    metricsHeight = node.metrics ? node.metrics.dataVisible.length * nodeMetricHeight : 0;
  }

  // node.height from ELK contains metrics height already if this is a faceted resource
  return Math.max(titleBarHeight + metricsHeight, portsHeight, nodeHeight);
};

export const calculateInitialNodeWidth = (node, type) => {
  const labelWidth = calculateTextWidth(node.label, 'node_label', labelMargin * 2);

  const textWidth = Math.max(labelWidth);

  const inputHandleWidth = type.inputs > 0 ? portRadius : 0;
  const roundedWidth =
    gridSize * Math.ceil((textWidth + inputHandleWidth) / gridSize);

  return Math.max(
    nodeDefaultWidth,
    roundedWidth,
    node.width || type.initialWidth || 0
  );
};

export const calculateNodeWidth = (node, types, resources) => {
  let type = getNodeType(node);
  const hasFacets = node.id in resources && type !== 'facet' && !!(resources[node.id].Facets) && Object.keys(resources[node.id].Facets).length > 0;

  if (!node._cache.roundedWidth) {
    const label = (type === 'custom') ? getCustomResourceLabel(node.id, resources, node) : node.name || node.label || types[type].label;
    const name = (type === 'custom') ? getCustomResourceName(node.id, resources, node) : node.name || node.id;
    const refLabel = (node.reference || node.arn) ? 'Referenced Resource' : types[type].paletteResource;
    const labelWidth = calculateTextWidth(label, 'node_label', labelMargin * 2);
    const refLabelWidth = calculateTextWidth(refLabel, 'node_label', labelMargin * 2);
    // if this is an aribtrary (custom) node the nameWidth is important to layout.
    // If it is a container node setting it above 0 causes problems.
    const nameWidth = (node.type === 'facet') ? 0 : calculateTextWidth(name, 'node_name', labelMargin * 2);
    let longestMetricWidth = 0;

    if (node.metrics) {
      longestMetricWidth = calculateTextWidth(longestMetricLabel(node), 'metricsGroup', 0);
      if (hasFacets) {
        /* Hack: Reduce the width by the padding for resources with facets as
           metrics are rendered in the padding area. */
        longestMetricWidth -= gridSize * 2;
      }
    }

    const customLabel = node.type === 'facet' ? calculateLabel(node, types) : calculateCustomLabel(types, node);

    let customLabelWidth = calculateTextWidth(customLabel, 'node_label', labelMargin * 2);

    const textWidth = Math.max(refLabelWidth, longestMetricWidth, labelWidth, customLabelWidth, nameWidth);

    const inputHandleWidth = type.inputs > 0 ? portRadius : 0;
    node._cache.roundedWidth =
      gridSize * Math.ceil((textWidth + inputHandleWidth) / gridSize);
  }

  return Math.max(
    nodeDefaultWidth,
    node._cache.roundedWidth,
    node.width || type.initialWidth || 0
  );
};

export const calculatePortLocation = (node, types, port, resources) => {
  let type = getNodeType(node);

  if (port !== undefined) {
    let y = calculateNodeHeight(node, types) / 2;

    if (calculateNodeOutputPorts(node, types[type]) > 0) {
      y += portPitch * (port - (calculateNodeOutputPorts(node, types[type]) - 1) / 2);
    }

    return {
      x: calculateNodeWidth(node, types, resources) + nodeOutlineWidth / 2,
      y
    };
  } else {
    return {
      x: -nodeOutlineWidth / 2,
      y: calculateNodeHeight(node, types) / 2
    };
  }
};
