import React, { Component } from 'react';
import * as states from '../../constants/states';
import hints from '../../constants/patternHintText';
import Form from '../core/Form';
import FormRow from '../core/FormRow';
import Input from '../core/Input';
import ButtonSet from '../core/ButtonSet';

class CreateBranch extends Component {
  constructor (props) {
    super(props);

    this.state = {
      branch: null
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange (event) {
    this.setState({ branch: event.currentTarget.value });
  }

  handleSubmit (event) {
    event.preventDefault();
    this.props.onSubmit(
      this.props.stack.owner,
      this.props.stack.name,
      this.props.stack.branch,
      this.state.branch
    );
  }

  render () {
    return (
      <Form onSubmit={this.handleSubmit}>
        <h1>Create Branch</h1>
        <p>Please enter the branch name from <strong>{this.props.stack.branch}</strong> on <strong>{this.props.stack.name}</strong>. </p>
        <FormRow label='Branch Name' hint={hints['[a-zA-Z0-9-_/-]*']}>
          <Input
            required
            pattern='[a-zA-Z0-9-_/-]*'
            onChange={this.handleInputChange}
          />
        </FormRow>
        <ButtonSet
          primaryButton={{
            text: 'Create',
            type: 'submit',
            isLoading: this.props.state === states.LOADING
          }}
          secondaryButton={{
            text: 'Cancel',
            onClick: this.props.onClickCancel
          }}
        />
      </Form>
    );
  }
}

CreateBranch.propTypes = {
};

export default CreateBranch;
