import makeActions from '../utils/makeActions';

export const types = {
  SELECT_STACK: 'APP::SELECT_STACK',
  DESELECT_STACK: 'APP::DESELECT_STACK',
  SELECT_STACK_BRANCH: 'APP::SELECT_STACK_BRANCH',
  SELECT_STACK_ENVIRONMENT: 'APP::SELECT_STACK_ENVIRONMENT',
  SELECT_STACK_RESOURCE: 'APP::SELECT_STACK_RESOURCE',
  DESELECT_STACK_RESOURCE: 'APP::DESELECT_STACK_RESOURCE',
  SET_STACK_MODE: 'APP::SET_STACK_MODE',
  SET_EPHEMERAL_ID: 'APP::SET_EPHEMERAL_ID',
  SET_STACK_FORMAT: 'APP::SET_STACK_FORMAT',
  SET_STACK_IS_MULTI_FILE: 'APP::SET_STACK_IS_MULTI_FILE',
  SET_STACK_GIT_PROVIDER: 'APP::SET_STACK_GIT_PROVIDER',
  SET_STACK_TEMPLATE: 'APP::SET_STACK_TEMPLATE',
  PARSE_STACK_TEMPLATE: makeActions('APP', 'PARSE_STACK_TEMPLATE', ['TRY', 'SUCCESS', 'FAILURE']),
  RESET_STACK_TEMPLATE: makeActions('APP', 'RESET_STACK_TEMPLATE', ['TRY', 'SUCCESS', 'FAILURE']),
  PARSE_STACK_CONTENTS: makeActions('APP', 'PARSE_STACK_CONTENTS', ['TRY', 'SUCCESS', 'FAILURE']),
  SELECT_DEPLOYMENT: 'APP::SELECT_DEPLOYMENT',
  DESELECT_DEPLOYMENT: 'APP::DESELECT_DEPLOYMENT',
  SELECT_ENVIRONMENT: 'APP::ENVIRONMENT::SELECT_ENVIRONMENT',
  DESELECT_ENVIRONMENT: 'APP:ENVIRONMENT:DESELECT_ENVIRONMENT',
  SET_ENVIRONMENT_VALIDITY: 'APP::ENVIRONMENT::SET_VALIDITY',
  SET_LOCAL_MODE: 'APP::SET_LOCAL_MODE',
  NOTIFY_USER: 'APP::NOTIFY_USER',
  DISMISS_NOTIFICATION: 'APP::DISMISS_NOTIFICATION',
  RESET_NEW_ENVIRONMENT_STATE: 'APP::RESET_NEW_ENVIRONMENT_STATE',
  TOGGLE_DEPLOYMENT_DETAILS: 'APP::TOGGLE_DEPLOYMENT_DETAILS',
  VIEW_NEW_DEPLOYMENT: 'APP::VIEW_NEW_DEPLOYMENT',
  TOGGLE_TOUR_MODE: 'APP::TOGGLE_TOUR_MODE',
  HIDE_MODAL: 'APP::HIDE_MODAL',
  SHOW_MODAL: 'APP::SHOW_MODAL',
  CONFIRM_MODAL: 'APP:CONFIRM_MODAL',
  SHOW_CREATE_STACK_NEW_REPO_MODAL: 'APP::SHOW_CREATE_STACK_NEW_REPO_MODAL',
  SHOW_CREATE_STACK_EXISTING_REPO_MODAL: 'APP::SHOW_CREATE_STACK_EXISTING_REPO_MODAL',
  SHOW_ACTIVE_STACKS_LIMIT_MODAL: 'APP::SHOW_ACTIVE_STACKS_LIMIT_MODAL',
  SHOW_UPGRADE_PROMPT_MODAL: 'APP::SHOW_UPGRADE_PROMPT_MODAL',
  SHOW_CREDIT_CARD_MODAL: 'APP::SHOW_CREDIT_CARD_MODAL',
  SHOW_SWITCH_PLAN_MODAL: 'APP::SHOW_SWITCH_PLAN_MODAL',
  SHOW_DEMO_MODAL: 'APP::SHOW_DEMO_MODAL',
  SHOW_EXIT_SETUP_MODAL: 'APP::SHOW_EXIT_SETUP_MODAL',
  SHOW_RENAME_STACK_MODAL: 'APP::SHOW_RENAME_STACK_MODAL',
  SHOW_DELETE_STACK_MODAL: 'APP::SHOW_DELETE_STACK_MODAL',
  SHOW_DELETE_ENVIRONMENT_MODAL: 'APP::SHOW_DELETE_ENVIRONMENT_MODAL',
  SHOW_CREATE_ENVIRONMENT_MODAL: 'APP::SHOW_CREATE_ENVIRONMENT_MODAL',
  SHOW_CREATE_REFERENCE_NODE_MODAL: 'APP::SHOW_CREATE_REFERENCE_NODE_MODAL',
  SHOW_COMMIT_STACK_MODAL: 'APP::SHOW_COMMIT_STACK_MODAL',
  SHOW_CREATE_BRANCH_MODAL: 'APP::SHOW_CREATE_BRANCH_MODAL',
  SHOW_CREATE_REPO_MODAL: 'APP:SHOW_CREATE_REPO_MODAL',
  SHOW_EDIT_REPO_MODAL: 'APP::SHOW_EDIT_REPO_MODAL',
  SHOW_CREATE_STACK_BLUEPRINT_MODAL: 'APP::SHOW_CREATE_STACK_BLUEPRINT_MODAL',
  SHOW_EDIT_TAGS_MODAL: 'APP::SHOW_EDIT_TAGS_MODAL',
  SHOW_CANVAS_ERRORS_MODAL: 'APP::SHOW_CANVAS_ERRORS_MODAL',
  SHOW_DEPLOYMENT_ERRORS_MODAL: 'APP::SHOW_DEPLOYMENT_ERRORS_MODAL',
  SHOW_INVITE_USER_MODAL: 'APP::SHOW_INVITE_USER_MODAL',
  SHOW_REMOVE_USER_MODAL: 'APP::SHOW_REMOVE_USER_MODAL',
  SHOW_INSTALL_CLI_MODAL: 'APP::SHOW_INSTALL_CLI_MODAL',
  SHOW_LINK_AWS_MODAL: 'APP::SHOW_LINK_AWS_MODAL',
  SHOW_UNLINK_AWS_MODAL: 'APP::SHOW_UNLINK_AWS_MODAL',
  SHOW_UPDATE_STACKERY_ROLE_MODAL: 'APP::SHOW_UPDATE_STACKERY_ROLE_MODAL',
  SHOW_UPDATE_PIPELINE_DEPLOYMENTS_MODAL: 'APP::SHOW_UPDATE_PIPELINE_DEPLOYMENTS_MODAL',
  SHOW_ENTER_SETUP_MODAL: 'APP::SHOW_ENTER_SETUP_MODAL',
  SHOW_IMPORT_BITBUCKET_REPOS_MODAL: 'APP::SHOW_IMPORT_BITBUCKET_REPOS_MODAL',
  SHOW_IMPORT_GITHUB_REPOS_MODAL: 'APP::SHOW_IMPORT_GITHUB_REPOS_MODAL',
  SHOW_IMPORT_GITLAB_REPOS_MODAL: 'APP::SHOW_IMPORT_GITLAB_REPOS_MODAL',
  SHOW_CREATE_ENVIRONMENT_SECRET_MODAL: 'APP::SHOW_CREATE_ENVIRONMENT_SECRET_MODAL',
  SHOW_DELETE_ENVIRONMENT_SECRET_MODAL: 'APP::SHOW_DELETE_ENVIRONMENT_SECRET_MODAL',
  SHOW_EDIT_ENVIRONMENT_SECRET_DESCRIPTION_MODAL: 'APP::SHOW_EDIT_ENVIRONMENT_SECRET_DESCRIPTION_MODAL',
  SHOW_UPDATE_ENVIRONMENT_SECRET_VALUE_MODAL: 'APP::SHOW_UPDATE_ENVIRONMENT_SECRET_VALUE_MODAL',
  SHOW_CREATE_TOKEN_MODAL: 'APP:SHOW_CREATE_TOKEN_MODAL',
  SHOW_DELETE_TOKEN_MODAL: 'APP:SHOW_DELETE_TOKEN_MODAL',
  SHOW_CONFIRM_PATH_IN_USE_MODAL: 'APP:SHOW_CONFIRM_PATH_IN_USE_MODAL',
  SHOW_CONFIRM_PATH_EXISTS_MODAL: 'APP:SHOW_CONFIRM_PATH_EXISTS_MODAL',
  SHOW_CONFIRM_PATH_REMOVE_MODAL: 'APP:SHOW_CONFIRM_PATH_REMOVE_MODAL',
  SHOW_CONFIRM_PATH_RUNTIME_MODAL: 'APP:SHOW_CONFIRM_PATH_RUNTIME_MODAL',
  SHOW_CREATE_DEPLOYMENT_PIPELINE_MODAL: 'APP:SHOW_CREATE_DEPLOYMENT_PIPELINE_MODAL',
  SHOW_DELETE_DEPLOYMENT_PIPELINE_MODAL: 'APP:SHOW_DELETE_DEPLOYMENT_PIPELINE_MODAL',
  SHOW_ENVIRONMENT_GIT_DIFF_MODAL: 'APP:SHOW_ENVIRONMENT_GIT_DIFF_MODAL',
  ADD_GIT_PROVIDER: 'APP:ADD_GIT_PROVIDER',
  GIT_PROVIDERS_UPDATED: 'APP:GIT_PROVIDERS_UPDATED',
  MOUNT_COMPONENT: 'APP:MOUNT_COMPONENT',
  UNMOUNT_COMPONENT: 'APP:UNMOUNT_COMPONENT',
  ROUTE_CHANGED: 'APP:ROUTE_CHANGED'
};

const actions = {
  selectStack: (owner, name, resourceId) => ({
    type: types.SELECT_STACK,
    owner,
    name,
    resourceId
  }),
  deselectStack: () => ({
    type: types.DESELECT_STACK
  }),
  selectStackBranch: branch => ({
    type: types.SELECT_STACK_BRANCH,
    branch
  }),
  selectStackEnvironment: environment => ({
    type: types.SELECT_STACK_ENVIRONMENT,
    environment
  }),
  selectStackResource: resourceId => ({
    type: types.SELECT_STACK_RESOURCE,
    resourceId
  }),
  deselectStackResource: () => ({
    type: types.DESELECT_STACK_RESOURCE
  }),
  setStackFormat: (format) => ({
    type: types.SET_STACK_FORMAT,
    format
  }),
  setStackMode: (mode) => ({
    type: types.SET_STACK_MODE,
    mode
  }),
  setEphemeralId: (ephemeralId) => ({
    type: types.SET_EPHEMERAL_ID,
    ephemeralId
  }),
  setStackIsMultiFile: isMultiFile => ({
    type: types.SET_STACK_IS_MULTI_FILE,
    isMultiFile
  }),
  setStackGitProvider: gitProvider => ({
    type: types.SET_STACK_GIT_PROVIDER,
    gitProvider
  }),
  parseStackTemplate: (template, format) => ({
    type: types.PARSE_STACK_TEMPLATE.TRY,
    template,
    format
  }),
  resetStackTemplate: () => ({
    type: types.RESET_STACK_TEMPLATE.TRY
  }),
  setStackTemplate: template => ({
    type: types.SET_STACK_TEMPLATE,
    template
  }),
  selectDeployment: id => ({
    type: types.SELECT_DEPLOYMENT,
    id
  }),
  deselectDeployment: () => ({
    type: types.DESELECT_DEPLOYMENT
  }),
  selectEnvironment: (owner, environment) => ({
    type: types.SELECT_ENVIRONMENT,
    owner,
    environment
  }),
  deselectEnvironment: () => ({
    type: types.DESELECT_ENVIRONMENT
  }),
  setEnvironmentValidity: valid => ({ type: types.SET_ENVIRONMENT_VALIDITY, valid }),
  setLocalMode: (params) => ({
    type: types.SET_LOCAL_MODE,
    params
  }),
  notifyUser: (message, level, metadata) => ({
    type: types.NOTIFY_USER,
    message,
    level,
    metadata
  }),
  dismissNotification: id => ({
    type: types.DISMISS_NOTIFICATION,
    id
  }),
  resetNewEnvironmentState: () => ({ type: types.RESET_NEW_ENVIRONMENT_STATE }),
  viewNewDeployment: () => ({ type: types.VIEW_NEW_DEPLOYMENT }),
  toggleTourMode: isTourMode => ({ type: types.TOGGLE_TOUR_MODE }),
  showCreateStackNewRepoModal: (props) => ({ type: types.SHOW_CREATE_STACK_NEW_REPO_MODAL, ...props }),
  showCreateStackExistingRepoModal: (props) => ({ type: types.SHOW_CREATE_STACK_EXISTING_REPO_MODAL, ...props }),
  showActiveStacksLimitModal: (props) => ({ type: types.SHOW_ACTIVE_STACKS_LIMIT_MODAL, ...props }),
  showUpgradePromptModal: (props) => ({ type: types.SHOW_UPGRADE_PROMPT_MODAL, ...props }),
  showDemoModal: (props) => ({ type: types.SHOW_DEMO_MODAL, ...props }),
  showExitSetupModal: (props) => ({ type: types.SHOW_EXIT_SETUP_MODAL, ...props }),
  showRenameStackModal: (props) => ({ type: types.SHOW_RENAME_STACK_MODAL, ...props }),
  showDeleteStackModal: (props) => ({ type: types.SHOW_DELETE_STACK_MODAL, ...props }),
  showCreateEnvironmentModal: (props) => ({ type: types.SHOW_CREATE_ENVIRONMENT_MODAL, ...props }),
  showDeleteEnvironmentModal: (props) => ({ type: types.SHOW_DELETE_ENVIRONMENT_MODAL, ...props }),
  showCommitStackModal: (props) => ({ type: types.SHOW_COMMIT_STACK_MODAL, ...props }),
  showCreateBranchModal: (props) => ({ type: types.SHOW_CREATE_BRANCH_MODAL, ...props }),
  showCreateRepoModal: (props) => ({ type: types.SHOW_CREATE_REPO_MODAL, ...props }),
  showEditRepoModal: (props) => ({ type: types.SHOW_EDIT_REPO_MODAL, ...props }),
  showEditTagsModal: (props) => ({ type: types.SHOW_EDIT_TAGS_MODAL, ...props }),
  showCreateStackBlueprintModal: (props) => ({ type: types.SHOW_CREATE_STACK_BLUEPRINT_MODAL, ...props }),
  showCreateReferenceNodeModal: (props) => ({ type: types.SHOW_CREATE_REFERENCE_NODE_MODAL, ...props }),
  showCanvasErrorsModal: (props) => ({ type: types.SHOW_CANVAS_ERRORS_MODAL, ...props }),
  showDeploymentErrorsModal: (props) => ({ type: types.SHOW_DEPLOYMENT_ERRORS_MODAL, ...props }),
  showSwitchPlanModal: (props) => ({ type: types.SHOW_SWITCH_PLAN_MODAL, ...props }),
  showInviteUserModal: (props) => ({ type: types.SHOW_INVITE_USER_MODAL, ...props }),
  showRemoveUserModal: (props) => ({ type: types.SHOW_REMOVE_USER_MODAL, ...props }),
  showInstallCliModal: (props) => ({ type: types.SHOW_INSTALL_CLI_MODAL, ...props }),
  showLinkAwsModal: (props) => ({ type: types.SHOW_LINK_AWS_MODAL, ...props }),
  showUnlinkAwsModal: () => ({ type: types.SHOW_UNLINK_AWS_MODAL }),
  showUpdateStackeryRoleModal: (props) => ({ type: types.SHOW_UPDATE_STACKERY_ROLE_MODAL, ...props }),
  showUpdatePipelineDeploymentsModal: (props) => ({ type: types.SHOW_UPDATE_PIPELINE_DEPLOYMENTS_MODAL, ...props }),
  showEnterSetupModal: (props) => ({ type: types.SHOW_ENTER_SETUP_MODAL, ...props }),
  showImportBitbucketReposModal: (props) => ({ type: types.SHOW_IMPORT_BITBUCKET_REPOS_MODAL, ...props }),
  showImportGithubReposModal: (props) => ({ type: types.SHOW_IMPORT_GITHUB_REPOS_MODAL, ...props }),
  showImportGitlabReposModal: (props) => ({ type: types.SHOW_IMPORT_GITLAB_REPOS_MODAL, ...props }),
  showCreateEnvironmentSecretModal: (props) => ({ type: types.SHOW_CREATE_ENVIRONMENT_SECRET_MODAL, ...props }),
  showDeleteEnvironmentSecretModal: (props) => ({ type: types.SHOW_DELETE_ENVIRONMENT_SECRET_MODAL, ...props }),
  showEditEnvironmentSecretDescriptionModal: (props) => ({ type: types.SHOW_EDIT_ENVIRONMENT_SECRET_DESCRIPTION_MODAL, ...props }),
  showUpdateEnvironmentSecretValueModal: (props) => ({ type: types.SHOW_UPDATE_ENVIRONMENT_SECRET_VALUE_MODAL, ...props }),
  showCreateTokenModal: (props) => ({ type: types.SHOW_CREATE_TOKEN_MODAL, ...props }),
  showDeleteTokenModal: (props) => ({ type: types.SHOW_DELETE_TOKEN_MODAL, ...props }),
  showConfirmPathInUseModal: (props) => ({ type: types.SHOW_CONFIRM_PATH_IN_USE_MODAL, ...props }),
  showConfirmPathExistsModal: (props) => ({ type: types.SHOW_CONFIRM_PATH_EXISTS_MODAL, ...props }),
  showConfirmPathRemoveModal: (props) => ({ type: types.SHOW_CONFIRM_PATH_REMOVE_MODAL, ...props }),
  showConfirmPathRuntimeModal: (props) => ({ type: types.SHOW_CONFIRM_PATH_RUNTIME_MODAL, ...props }),
  showCreateDeploymentPipelineModal: (props) => ({ type: types.SHOW_CREATE_DEPLOYMENT_PIPELINE_MODAL, ...props }),
  showDeleteDeploymentPipelineModal: (props) => ({ type: types.SHOW_DELETE_DEPLOYMENT_PIPELINE_MODAL, ...props }),
  showEnvironmentGitDiffModal: (props) => ({ type: types.SHOW_ENVIRONMENT_GIT_DIFF_MODAL, ...props }),
  confirmModal: () => ({ type: types.CONFIRM_MODAL }),
  hideModal: () => ({ type: types.HIDE_MODAL }),
  addGitProvider: (props) => ({ type: types.ADD_GIT_PROVIDER, ...props }),
  mountComponent: (component) => ({ type: types.MOUNT_COMPONENT, component }),
  unmountComponent: (component) => ({ type: types.UNMOUNT_COMPONENT, component }),
  parseStackContents: () => ({ type: types.PARSE_STACK_CONTENTS.TRY }),
  routeChanged: () => ({ type: types.ROUTE_CHANGED })
};

export default actions;
