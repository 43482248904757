import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { getCookie, deleteCookie } from '../utils/cookie';
import * as localStorage from '../utils/localStorage';
import * as states from '../constants/states';

const userPool = new CognitoUserPool(JSON.parse(process.env.REACT_APP_USER_POOL_DATA));

const transferCookiesToLocalStorage = () => {
  // Cookies are set by `/public/data-recv.html` in an embedded iframe on www.stackery.io
  // NOTE: Using both cookies and localStorage to validate Cognito can be avoided by setting poolData.Storage to `CookieStorage` in www.stackery.io
  let keys = getCookie('stackeryCognitoKeys');
  let values = getCookie('stackeryCognitoValues');

  if (!keys || !values) { return; }

  keys = JSON.parse(keys);
  values = JSON.parse(values);

  keys.forEach((key, i) => {
    localStorage.setItem(key, values[i]);
  });

  deleteCookie('stackeryCognitoKeys');
  deleteCookie('stackeryCognitoValues');
};

async function getSession (cognitoUser) {
  return new Promise((resolve, reject) => {
    cognitoUser.getSession((err, session) => {
      if (err) {
        return reject(err);
      }

      return resolve(session);
    });
  });
}

export default async function restoreAuthState () {
  transferCookiesToLocalStorage();

  const cognitoUser = userPool.getCurrentUser();

  if (cognitoUser !== null) {
    try {
      const session = await getSession(cognitoUser);
      const token = session.getAccessToken().getJwtToken();
      const refreshToken = session.refreshToken;
      const expiration = session.getAccessToken().getExpiration() * 1000;
      const impersonate = localStorage.getItem('impersonate');

      return { auth: { state: states.OKAY, token, refreshToken, expiration, impersonate } };
    } catch (err) {
      console.error(`Failed to get session for current user: ${err.message}`);
    }
  }

  return {};
}
