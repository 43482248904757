import ELK from 'elkjs/lib/elk.bundled.js';
import deepEqual from 'deep-equal';
import cloneDeep from 'clone-deep';
import { isContainer, calculateNodeWidth, calculateNodeHeight } from './calculateNode';
import { gridSize, nodeDefaultHeight, portRadius, nodeMetricHeight } from '../constants/canvas';

let elk;

let elkOptions = {
  defaultLayoutOptions: {
    // Put nodes into columns (layers) with edges in-between
    algorithm: 'layered',
    'elk.direction': 'RIGHT',
    // distinguish between input and output port
    'elk.portConstraints': 'FIXED_ORDER',

    // Vertical space between nodes
    nodeNode: gridSize,

    // Horizontal space between columns of nodes
    nodeNodeBetweenLayers: gridSize * 2,

    // Layout children nodes among all nodes, not just in relation to parent
    hierarchyHandling: 'INCLUDE_CHILDREN',

    // Pad nodes in canvas with 20 pixels
    'elk.padding': 'top=100,left=40,bottom=20,right=0',

    /* Pull container ports towards the center instead of spread evenly along
     * vertical border. */
    portAlignment: 'CENTER'
  },

  // Use unminified sources (better for debugging and stack traces)
  workerFactory: url => {
    const Worker = require('elkjs/lib/elk-worker.js').Worker;
    return new Worker(url);
  }
};

export default async (resources, types, nodes, dimensions, renderMetrics, enableWrapping = true) => {
  if (enableWrapping) {
    elkOptions.defaultLayoutOptions['elk.layered.wrapping.strategy'] = 'MULTI_EDGE';
    elkOptions.defaultLayoutOptions['elk.layered.wrapping.additionalEdgeSpacing'] = 30;
    elkOptions.defaultLayoutOptions['elk.layered.wrapping.correctionFactor'] = 100.0;  // don't wrap in containers - it gets weird
    elkOptions.defaultLayoutOptions['elk.layered.layering.strategy'] = 'INTERACTIVE'; // This just seemed to work the best
  }

  elk = elk || new ELK(elkOptions);

  if (nodes) {
    nodes = nodes.filter(node => node.type !== 'facet');
  } else {
    nodes = Object.keys(resources.resources).map(resourceId => {
      const resource = resources.resources[resourceId];
      const node = {
        id: resourceId,
        type: resource.Type,
        name: resource.Settings.Name,
        wires: [],
        x: 0,
        y: 0,
        _cache: {}
      };

      if (resourceId in resources.references && !(resource.Facets && 'references' in resource.Facets)) {
        const references = resources.references[resourceId];

        const targetIds = Object.values(references)
          .filter(reference => reference.isLocalResource())
          .map(target => target.ResourceId);

        // Uniqueify targets
        node.wires[0] = Array.from(new Set(targetIds));
      }

      if (resource.Type === 'custom' && resources.customResourceReferences[resourceId] && Object.keys(resources.customResourceReferences[resourceId]).length > 0) {
        const references = resources.customResourceReferences[resourceId];
        const targetIds = Object.keys(references);

        // Uniqueify targets
        node.wires[0] = Array.from(new Set(targetIds));
      }

      return node;
    });

    for (const integration of resources.integrations) {
      if (integration.Source.Local && integration.Target.Local) {
        let sourceNode;

        if (integration.FacetType) {
          continue;
        }

        sourceNode = nodes.find(node => node.id === integration.Source.ResourceId);

        sourceNode.wires[0] = sourceNode.wires[0] || [];

        sourceNode.wires[0].push(integration.Target.ResourceId);
      }
    }
  }

  for (const resourceId in resources.resources) {
    const resource = resources.resources[resourceId];

    for (const facetType in (resource.Facets || {})) {
      for (const i in resource.Facets[facetType]) {
        const facet = resource.Facets[facetType][i];

        const node = {
          id: facet.Id,
          type: 'facet',
          sourceType: resource.Type,
          facetType: facetType,
          sourceId: resourceId,
          facet,
          wires: [],
          x: 0,
          y: 0,
          _cache: {}
        };

        if (resourceId in resources.references && facetType === 'references') {
          const references = resources.references[resourceId];

          const targetIds = Object.values(references)
            .filter(reference => reference.isLocalResource())
            .map(target => target.ResourceId);

          // Uniqueify targets
          node.wires[0] = Array.from(new Set(targetIds));
        }

        nodes.push(node);
      }
    }
  }

  for (const integration of resources.integrations) {
    if (integration.Source.Local && integration.Target.Local) {
      let sourceNode;

      if (!integration.FacetType) {
        continue;
      }

      sourceNode = nodes.find(node =>
        node.type === 'facet' &&
        node.sourceType === integration.SourceType &&
        node.sourceId === integration.Source.ResourceId &&
        node.facetType === integration.FacetType &&
        node.id === integration.FacetId
      );

      sourceNode.wires[0] = sourceNode.wires[0] || [];

      sourceNode.wires[0].push(integration.Target.ResourceId);
    }
  }

  let root = {
    id: 'root',
    children: [],
    edges: []
  };

  const virtualNetworkIds = Object.keys(resources.resources).filter(resourceId => resources.resources[resourceId].Type === 'virtualNetwork');

  for (const resourceId of virtualNetworkIds) {
    const node = nodes.find(node => node.id === resourceId);

    root.children.push({
      id: resourceId,
      width: Math.max(labelSize(node, types, resources), 300),
      height: 100,
      layoutOptions: {
        'elk.padding': `top=${nodeDefaultHeight + gridSize},left=${gridSize},bottom=${gridSize},right=${gridSize}`
      },
      children: []
    });
  }

  for (const resourceId in resources.resources) {
    const resource = resources.resources[resourceId];

    if (resource.Type === 'virtualNetwork') {
      continue;
    }

    let parent = root;

    if (resourceId in resources.virtualNetworkPlacements) {
      const parentId = resources.virtualNetworkPlacements[resourceId].VirtualNetworkId.ResourceId;

      // parentId may not exist if the reference is to a virtual resource
      if (parentId) {
        parent = root.children.find(child => child.id === parentId);
      }
    }

    const node = nodes.find(node => node.id === resourceId);

    if (resource.Facets) {
      const metricsHeight = renderMetrics ? types[node.type].numVisibleMetrics * nodeMetricHeight : 0;

      const elkNode = {
        id: resourceId,
        width: Math.max(labelSize(node, types, resources), 300),
        height: 100,
        layoutOptions: {
          'elk.padding': `top=${nodeDefaultHeight + gridSize + metricsHeight},left=${gridSize},bottom=${gridSize},right=${gridSize}`,
          'elk.portConstraints': 'UNDEFINED'
        },
        children: []
      };

      parent.children.push(elkNode);

      for (const facetType in resource.Facets) {
        for (const facet of resource.Facets[facetType]) {
          const node = nodes.find(node =>
            node.type === 'facet' &&
            node.sourceId === resourceId &&
            node.facetType === facetType &&
            deepEqual(node.facet, facet)
          );

          elkNode.children.push({
            id: node.id,
            width: Math.max(labelSize(node, types, resources), 220),
            height: calculateNodeHeight(node, types),
            children: []
          });
        }
      }
    } else {
      parent.children.push({
        id: resourceId,
        width: labelSize(node, types, resources),
        height: calculateNodeHeight(node, types),
        children: []
      });
    }
  }

  const integrationEdges = resources.integrations.map(integration => {
    if (integration.FacetType) {
      const sourceNode = nodes.find(node =>
        node.type === 'facet' &&
        node.sourceType === integration.SourceType &&
        node.sourceId === integration.Source.ResourceId &&
        node.facetType === integration.FacetType &&
        node.id === integration.FacetId
      );

      return {
        id: `${integration.FacetId}-to-${integration.Target.ResourceId}`,
        sources: [ sourceNode.id ],
        targets: [ integration.Target.ResourceId ]
      };
    } else {
      return {
        id: `${integration.Source.ResourceId}-to-${integration.Target.ResourceId}`,
        sources: [ integration.Source.ResourceId ],
        targets: [ integration.Target.ResourceId ]
      };
    }
  });

  const referenceEdgeArrays = Object.keys(resources.references).map(sourceId => {
    const references = resources.references[sourceId];
    const source = resources.resources[sourceId];

    let elkSourceId = sourceId;
    if ('Facets' in source && 'references' in source.Facets) {
      elkSourceId = source.Facets.references[0].Id;
    }

    const targetIds = Object.values(references)
      .filter(reference => reference.isLocalResource())
      .map(target => target.ResourceId);

    const uniqueTargetIds = Array.from(new Set(targetIds));

    return uniqueTargetIds.map(targetId => ({
      id: `${sourceId}-to-${targetId}`,
      sources: [elkSourceId],
      targets: [targetId]
    }));
  });

  const customReferenceEdgeArrays = Object.keys(resources.customResourceReferences).map(sourceId => {
    const references = resources.customResourceReferences[sourceId];
    const targetIds = Object.keys(references);

    const uniqueTargetIds = Array.from(new Set(targetIds));

    return uniqueTargetIds.map(targetId => ({
      id: `${sourceId}-to-${targetId}`,
      sources: [sourceId],
      targets: [targetId]
    }));
  });

  // Flatten nested arrays
  const referenceEdges = Array.prototype.concat.apply([], referenceEdgeArrays);
  const customReferenceEdges = Array.prototype.concat.apply([], customReferenceEdgeArrays);

  root.edges = integrationEdges.concat(referenceEdges, customReferenceEdges);

  // Add a static buffer to the canvas width. This prevents wrapping when graphs
  // slightly exceed the canvas width when it would look better to not wrap and
  // just overflow slightly.
  const wrappingBuffer = 105;
  const canvasSize = window.innerWidth - dimensions.left + wrappingBuffer;
  root.layoutOptions = {
    'elk.layered.wrapping.correctionFactor': 10,
    'elk.aspectRatio': '1.6f'
  };
  let clone = await elkLayout(root);
  let retries = 0;
  // If graph is wider than canvas increase wrapping configuration and rerun layout
  while (clone.width > canvasSize) {
    root.layoutOptions['elk.layered.wrapping.correctionFactor'] = root.layoutOptions['elk.layered.wrapping.correctionFactor'] / 2;

    clone = await elkLayout(root);

    retries += 1;
    if (retries > 5) { break; }
  }
  root = clone;
  applyCoordinates(nodes, root, root.edges, types);
  fixEdgesWithinParents(nodes, root);
  return nodes;
};

async function elkLayout (source) {
  let clone = cloneDeep(source);
  try {
    return await elk.layout(clone);
  } catch (error) {
    if (console.debug) {
      console.debug(`WARNING: ELK Failed, trying with different options: ${error}`);
    }
    // In some cases, ELK fails when portConstraints is set to `FIXED_ORDER`
    // For now, try again without that option set
    let fallbackOptions = {
      defaultLayoutOptions: { ...elkOptions.defaultLayoutOptions },
      workerFactory: elkOptions.workerFactory
    };
    delete fallbackOptions.defaultLayoutOptions['elk.portConstraints'];

    elk = new ELK(fallbackOptions);
    return elk.layout(clone);
  }
}

// Calculate Stackery node coordinates and save them to the nodes
function applyCoordinates (nodes, parent, edges, types) {
  for (const child of parent.children) {
    const node = nodes.find(node => node.id === child.id);

    // ELK positions are relative to the parent node
    child.x += parent.x;
    child.y += parent.y;

    /* Facets are placed in the center of parent nodes and expand to the full
     * width of the parent nodes (minus padding areas) */
    if (node.type === 'facet') {
      // Stackery positions are the center of the nodes
      node.x = parent.x + parent.width / 2;
      node.width = parent.width - 2 * gridSize;
    } else {
      // Stackery positions are the center of the nodes
      node.x = child.x + child.width / 2;
      /* Don't set the width of non-facet non-container nodes, the width will be
       * calculated dynamically based on the labels */
    }

    node.y = child.y + child.height / 2;

    if (isContainer(node, types)) {
      node.width = child.width;
      node.height = child.height;
    }

    node.edges = edges.filter(edge => edge.sources.includes(node.id));
    child.edges = node.edges;

    applyCoordinates(nodes, child, edges, types);
  }
}

/* ELK uses relative coordinates when an edge connects two nodes within the same
 * parent. In all other cases, edges use absolute coordinates. This may be a
 * bug, as it lacks a rational basis. Either way, this function searches for
 * such edges and applies the parent offset to make coordinates absolute. */
function fixEdgesWithinParents (nodes, root) {
  for (const edge of root.edges) {
    if (!edge.sections) {
      return;
    }

    const section = edge.sections[0];

    const sourceOffset = nodeOffset(root, edge.sources[0]);

    // If there's no source parent, skip this edge
    if (sourceOffset.x === 0 && sourceOffset.y === 0) {
      continue;
    }

    const targetOffset = nodeOffset(root, edge.targets[0]);

    // If they share a parent, their parent offset will be the same
    if (!deepEqual(sourceOffset, targetOffset)) {
      continue;
    }

    section.startPoint.x += sourceOffset.x;
    section.startPoint.y += sourceOffset.y;

    section.endPoint.x += sourceOffset.x;
    section.endPoint.y += sourceOffset.y;

    if (section.bendPoints) {
      section.bendPoints = section.bendPoints.map(bendPoint => ({x: bendPoint.x + sourceOffset.x, y: bendPoint.y + sourceOffset.y}));
    }
  }
}

// Compute offset of a child node with the given ID relative to the parent
function nodeOffset (parent, id) {
  for (const child of parent.children) {
    if (child.id === id) {
      return {
        x: 0,
        y: 0
      };
    }

    const childOffset = nodeOffset(child, id);

    if (childOffset) {
      return {
        x: childOffset.x + child.x,
        y: childOffset.y + child.y
      };
    }
  }
}

/* Rough approximation of the frontend label width. Known issues:
 *
 * * Character widths are static (do not vary based on kerning)
 * * Character widths are based on Arial, not Helvetica
 * * Port widths are not taken into account
 * * Hardcoded constants must match the frontend constants
 */
function labelSize (node, types, resources) {
  const textWidth = calculateNodeWidth(node, types, resources.resources);

  return gridSize * Math.ceil((textWidth + portRadius) / gridSize);
}
