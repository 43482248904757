import React, { Component } from 'react';
import { nodeHeightMin } from '../../constants/canvas';
import NodeTypeRef from './NodeTypeRef';

class NodeTypeRefDragPreview extends Component {
  render () {
    const styles = {
      opacity: 0.7,
      width: '200px',
      height: nodeHeightMin
    };

    return (
      <div style={styles}>
        <NodeTypeRef showHint={false} />
      </div>
    );
  }
}

export default NodeTypeRefDragPreview;
