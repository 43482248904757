export const types = {
  START_SETUP: 'APP::START_SETUP',
  STOP_SETUP: 'APP::STOP_SETUP'
};

const actions = {
  startSetup: () => ({
    type: types.START_SETUP
  }),
  stopSetup: () => ({
    type: types.STOP_SETUP
  })
};

export default actions;
