export const BACKSPACE = 8;
export const TAB = 9;
export const ENTER = 13;
export const SHIFT = 16;
export const CTRL = 17;
export const ALT = 18;
export const ESC = 27;
export const SPACE = 32;
export const DELETE = 46;
export const UP = 38;
export const DOWN = 40;
export const LEFT = 39;
export const RIGHT = 37;
