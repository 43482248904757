import React, { Fragment } from 'react';
import EnvironmentLink from './EnvironmentLink';

const ParamsModifiedEvent = (props) => {
  const context = props.context === 'environments' ? '' : <Fragment>in <EnvironmentLink {...props} /></Fragment>;

  return <Fragment>Environment parameters modified {context} by {props.userEmail}</Fragment>;
};

export default ParamsModifiedEvent;
