import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import crosstab from 'crosstab';
import * as states from '../../constants/stackCreatorStates';
import apiActions from '../../actions/api';
import appActions from '../../actions/app';
import CreateStackBlueprint from './CreateStackBlueprint';

const mapStateToProps = (state, ownProps) => {
  return createSelector(
    (appState) => appState.gitProviders,
    (appState) => appState.currentUser,
    // Destructure gitProviders so reducer state and providers are separate
    ({ state: gitProvidersState, ...gitProviders }, currentUser) => {
      return {
        ...ownProps.modal,
        owner: currentUser.org,
        gitProviders
      };
    }
  );
};

const mapDispatchToProps = {
  notifyUser: appActions.notifyUser,
  getGitDestinations: apiActions.getGitDestinations,
  saveStackBlueprint: apiActions.saveStackBlueprint
};

class CreateStackBlueprintContainer extends Component {
  constructor (props) {
    super(props);

    this.state = {
      gitProvider: null,
      name: null,
      repository: null,
      reference: 'master'
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount () {
    crosstab.once('StackeryOAuthComplete', (message) => {
      crosstab.broadcast('StackeryOAuthCloseWindow');
      this.props.notifyUser(message.data.info, message.data.type);

      if (message.data.status === 'success') {
        this.props.notifyUser('Git authorization successful', 'success');
        this.props.getGitDestinations(message.data.provider);
      }
    });
  }

  componentDidUpdate (prevProps, prevState) {
    const {
      gitProvider
    } = this.state;

    const {
      gitProviders
    } = this.props;

    if (
      gitProvider &&
      gitProvider !== prevProps.gitProvider &&
      gitProviders[gitProvider].state === states.NEW
    ) {
      this.props.getGitDestinations(gitProvider);
    }
  }

  handleInputChange (event) {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value });
  }

  handleSubmit (event) {
    if (event) {
      event.preventDefault();
    }

    const {
      name,
      repository,
      reference
    } = this.state;

    this.props.saveStackBlueprint(this.props.owner, name, { repository, reference });
  }

  render () {
    return (
      <CreateStackBlueprint
        {...this.props}
        {...this.state}
        onInputChange={this.handleInputChange}
        onSubmit={this.handleSubmit}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateStackBlueprintContainer);
