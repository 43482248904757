import React from 'react';
import PropTypes from 'prop-types';
import Joyride from 'react-joyride';
import tourStyles from './TourStyles';

const Tour = (props) => {
  return (
    <div onMouseOver={props.onHover} onMouseOut={props.onHover}>
      <Joyride
        {...props.tour}
        continuous
        disableOverlay
        locale={{
          last: 'Close',
          skip: 'Exit Tour'
        }}
        styles={tourStyles}
        getHelpers={props.setHelpers}
        callback={props.onCallback}
      />
    </div>
  );
};

Tour.propTypes = {
  tour: PropTypes.object.isRequired,
  onHover: PropTypes.func.isRequired,
  setHelpers: PropTypes.func.isRequired
};

export default Tour;
