import { eventChannel } from 'redux-saga';
import { all, call, put, putResolve, race, select, take, takeLatest } from 'redux-saga/effects';
import { request } from '../utils/api';
import workspaceActions, { types as workspaceTypes } from '../actions/workspace';
import { types as apiTypes } from '../actions/api';

function * createSocket () {
  const port = yield select(state => state.workspace.port);
  const secret = yield select(state => state.workspace.secret);
  const host = yield select(state => state.workspace.host);
  return new window.WebSocket(`ws://${host}:${port}/workspace/notifications?Authorization=${secret}`);
}

function createSocketChannel (socket) {
  return eventChannel((emitter) => {
    socket.onopen = event => emitter(workspaceActions.connect());
    socket.onclose = event => emitter(workspaceActions.disconnect());
    socket.onerror = event => emitter(workspaceActions.connectFailure('Failed to connect to local dev server.'));
    socket.onmessage = event => emitter(workspaceActions.receiveMessage(JSON.parse(event.data)));

    return () => socket.close();
  });
}

export function * read ({ file, type }) {
  const port = yield select(state => state.workspace.port);
  const host = yield select(state => state.workspace.host);
  const headers = yield select(state => ({
    Authorization: state.workspace.secret
  }));

  return yield request(
    type ? workspaceTypes.READ : null,
    'GET',
    `http://${host}:${port}/workspace/file/${file}`,
    null,
    { file },
    null,
    headers
  );
}

export function * save ({ file, contents, type }) {
  const port = yield select(state => state.workspace.port);
  const host = yield select(state => state.workspace.host);
  const headers = yield select(state => ({
    Authorization: state.workspace.secret,
    'Content-Type': 'text/plain'
  }));
  yield request(
    type ? workspaceTypes.SAVE : null,
    'PUT',
    `http://${host}:${port}/workspace/file/${file}`,
    contents,
    { file, contents },
    null,
    headers
  );
}

function * load ({ contents, format }) {
  yield putResolve({ type: apiTypes.GET_STACK_CONTENTS.SUCCESS, data: { stack: contents, format } });
}

export function * rename ({ from, to, type }) {
  const port = yield select(state => state.workspace.port);
  const host = yield select(state => state.workspace.host);
  const headers = yield select(state => ({
    Authorization: state.workspace.secret,
    'Content-Type': 'application/json'
  }));

  yield request(
    type ? workspaceTypes.RENAME : null,
    'POST',
    `http://${host}:${port}/workspace/rename`,
    { from, to },
    null,
    null,
    headers
  );
}

export function * remove ({ file, type }) {
  const port = yield select(state => state.workspace.port);
  const host = yield select(state => state.workspace.host);
  const headers = yield select(state => ({
    Authorization: state.workspace.secret
  }));

  yield request(
    type ? workspaceTypes.REMOVE : null,
    'DELETE',
    `http://${host}:${port}/workspace/file/${file}`,
    null,
    null,
    null,
    headers
  );
}

function * listen (socketChannel) {
  while (true) {
    const action = yield take(socketChannel);
    yield put(action);
  }
}

function * startWorkspace (action) {
  let socket = yield call(createSocket);

  while (true) {
    if (socket && socket.readyState !== window.WebSocket.OPEN) {
      socket = yield call(createSocket);
    }

    const socketChannel = yield call(createSocketChannel, socket);

    const { stop } = yield race({
      ready: call(listen, socketChannel),
      stop: take(workspaceTypes.STOP_WORKSPACE)
    });

    if (stop) {
      socketChannel.close();
      break;
    }
  }
}

export default function * workspace (action) {
  yield all([
    takeLatest(workspaceTypes.START_WORKSPACE, startWorkspace),
    takeLatest(workspaceTypes.READ.REQUEST, read),
    takeLatest(workspaceTypes.SAVE.REQUEST, save),
    takeLatest(workspaceTypes.LOAD, load),
    takeLatest(workspaceTypes.RENAME.REQUEST, rename),
    takeLatest(workspaceTypes.REMOVE.REQUEST, remove)
  ]);
}
