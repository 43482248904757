import React, { Component } from 'react';
import * as states from '../../constants/states';
import makeGitLink from '../../utils/makeGitLink';
import GitLink from '../core/GitLink';
import Form from '../core/Form';
import ButtonSet from '../core/ButtonSet';

class DeleteStack extends Component {
  constructor (props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit (event) {
    event.preventDefault();

    const stack = this.props.stack;
    this.props.onSubmit(stack.id, stack.owner, stack.name);
  }

  render () {
    return (
      <Form onSubmit={this.handleSubmit}>
        <h1>Delete Stack</h1>
        <p>Are you sure you want to delete stack <strong>{this.props.stack.name}</strong>?</p>
        <p>Deleting a stack means it will no longer be accessible from your Stackery dashboard.
          The stack's source code will remain in <GitLink gitLink={makeGitLink(this.props.stack, this.props.gitProviders)} /> and deployed CloudFormation stacks will remain in your AWS account.</p>
        <ButtonSet
          primaryButton={{
            text: 'Delete',
            type: 'submit',
            modifier: 'warning',
            isLoading: this.props.state === states.LOADING
          }}
          secondaryButton={{
            text: 'Cancel',
            onClick: this.props.onClickCancel
          }}
        />
      </Form>
    );
  }
}

DeleteStack.propTypes = {
};

export default DeleteStack;
