import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Spinner from '../core/Spinner';
import ButtonMenu from '../core/ButtonMenu';
import IconButton from '../core/IconButton';
import Link from '../core/Link';
import ButtonSet from '../core/ButtonSet';
import UserNotificationsList from './UserNotificationsList';
import style from './UserNotificationsMenu.css';

const UserNotificationsMenu = props => {
  const hasPrevPage = props.prevPage !== undefined;
  const hasNextPage = props.nextPage !== undefined;

  const [currentPage, setCurrentPage] = React.useState(1);

  React.useEffect(() => {
    // props.currentPage is 0-based, add 1 to translate as a page number
    setCurrentPage(props.currentPage + 1);
  }, [props.currentPage]);

  return (
    <div className={style.container}>
      <ButtonMenu
        id={`user-notifications-menu`}
        iconSvg='notifications_outline'
        isIconButton
      >
        {({ isOpen, isEntered, onClose }) => {
          const isLoading = (props.isLoading && !props.hasUserNotifications) || !isEntered;

          return (
            <div className={classnames(style.content, { [style.hasContent]: props.hasUserNotifications })}>
              <header className={style.header}>
                <strong className={style.title}>Notifications</strong>
              </header>
              {isLoading &&
                <Spinner position='static' />
              }
              {!isLoading &&
                <UserNotificationsList {...props} isOpen={isOpen} onClose={onClose} />
              }
              {!isLoading && !props.hasUserNotifications &&
                <p className={style.emptyMessage}>Notifications are empty</p>
              }
              {!isLoading && props.hasUserNotifications &&
                <footer className={style.footer}>
                  <div className={style.pagination}>
                    <IconButton className={classnames(style.iconButton, { [style.isDisabled]: !hasPrevPage })} iconFont='chevron_left' isDisabled={!hasPrevPage} onClick={hasPrevPage ? props.onLoadPrev : undefined} />
                    <p title={`Page ${currentPage} of ${props.totalPages}`}><strong>{currentPage}</strong> / {props.totalPages || 1}</p>
                    <IconButton className={classnames(style.iconButton, { [style.isDisabled]: !hasNextPage })} iconFont='chevron_right' isDisabled={!hasNextPage} onClick={hasNextPage ? props.onLoadNext : undefined} />
                  </div>
                  <div className={classnames(style.deleteAll, { [style.isConfirming]: props.isDeletingAllPending })}>
                    <Link extraClassName={style.deleteAllLink} text='Delete all...' aria-hidden={props.isDeletingAllPending} onClick={props.onDeleteAll} />
                    {props.isDeletingAllPending &&
                      <div className={style.confirmPrompt}>
                        <p>Delete all {props.totalCount} notifications? (cannot be undone)</p>
                        <ButtonSet
                          hasBorder={false}
                          primaryButton={{
                            text: 'Yes, delete all',
                            modifier: 'warning',
                            hid: 'user-notification-delete-all-confirm-button',
                            isLoading: props.isDeletingAll,
                            onClick: props.onDeleteAllConfirm
                          }}
                          secondaryButton={{
                            text: 'Cancel',
                            modifier: 'secondary',
                            hid: 'user-notification-delete-all-cancel-button',
                            onClick: props.onDeleteAllCancel
                          }}
                        />
                      </div>
                    }
                  </div>
                </footer>
              }
            </div>
          );
        }}
      </ButtonMenu>
    </div>
  );
};

UserNotificationsMenu.propTypes = {
  userNotifications: PropTypes.array.isRequired,
  regions: PropTypes.array.isRequired,
  currentPage: PropTypes.number.isRequired,
  prevPage: PropTypes.number,
  nextPage: PropTypes.number,
  totalPages: PropTypes.number.isRequired,
  hasUserNotifications: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isDeletingAllPending: PropTypes.bool.isRequired,
  isDeletingAll: PropTypes.bool.isRequired,
  onDeleteAllConfirm: PropTypes.func.isRequired,
  onDeleteAllCancel: PropTypes.func.isRequired,
  onDeleteAll: PropTypes.func.isRequired,
  onLoadPrev: PropTypes.func.isRequired,
  onLoadNext: PropTypes.func.isRequired
};

export default UserNotificationsMenu;
