import React from 'react';

const XRayTraceLink = (props) => {
  const encodedFilter = encodeURIComponent(`service(id(name: "${props.name}", type: "${props.serviceType}"))`);

  const components = [
    'https://console.aws.amazon.com/xray/home?region=',
    props.region,
    '#/traces?filter=',
    encodedFilter,
    '&timeRange=PT6H'
  ];

  return (
    <a href={components.join('')} target='_blank' data-logs>{props.label}</a>
  );
};

export default XRayTraceLink;
