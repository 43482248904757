import React from 'react';
import ButtonSet from '../core/ButtonSet';

const UpgradePrompt = (props) => {
  const upgradeContent =
    {
      userLimitReached: {
        callToAction: 'invite more users',
        limit: 'active user',
        question: `Would you like to upgrade your plan to invite more than ${props.userLimit} ${props.userLimit > 1 ? 'users' : 'user'}?`
      },
      providersLimitReached: {
        callToAction: 'link additional AWS accounts',
        limit: 'AWS account',
        question: `Would you like to upgrade your plan to link more than ${props.providersLimit} ${props.providersLimit > 1 ? 'accounts' : 'account'}?`
      },
      adminAccountDeactivated: {
        callToAction: 're-enable this account',
        limit: 'active user',
        question: `Would you like to upgrade your plan to have more than ${props.userLimit} ${props.userLimit > 1 ? 'users' : 'user'} on your account?`
      }
    };

  return (
    <div>
      <h1>Upgrade your plan to {upgradeContent[props.prompt].callToAction}</h1>
      <p>You have reached the {upgradeContent[props.prompt].limit} limit on the {props.plan ? `${props.plan} Plan` : 'current plan'}. {upgradeContent[props.prompt].question}</p>

      <ButtonSet
        primaryButton={{
          text: 'Upgrade My Plan',
          to: '/settings/billing',
          onClick: props.onClickCancel
        }}
        secondaryButton={{
          text: `I don't want to ${upgradeContent[props.prompt].callToAction}`,
          onClick: props.onClickCancel
        }}
      />
    </div>
  );
};

export default UpgradePrompt;
