import React, { Component } from 'react';
import classnames from 'classnames';
import shortid from 'shortid';
import FieldSet from '../core/FieldSet';
import Input from '../core/Input';
import Select from '../core/Select';
import Icon from '../core/Icon';
import ConfigField from './ConfigField';
import style from './ListField.css';

/* TOOD: DRY - consolidate this, TagsContainer, and EditTags functions into reusable editable fieldset component */
class ListField extends Component {
  constructor (props) {
    super(props);

    this.state = {
      error: null,
      isShiftKeyPressed: false,
      fields: this.setFields(props)
    };

    this.addBlankField = this.addBlankField.bind(this);
    this.handleRemoveField = this.handleRemoveField.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleConfigFieldChange = this.handleConfigFieldChange.bind(this);
    this.update = this.update.bind(this);
    this.save = this.save.bind(this);
  }

  setFields (props) {
    let fields;
    let setting = props.getResourceSetting(props.resource.id, props.resource.facetType, props.resource.facetId, props.settingId);

    if (props.settingSchema.ValueType === 'object') {
      fields = Object.keys(setting || {}).map((key, i) => {
        // TODO: abstract key/value setting (remove static keys). Update schema so key/val relationships are defined.
        return {
          id: shortid.generate(),
          Key: key,
          Value: setting[key]
        };
      });
    } else if (props.settingSchema.ValueType === 'array') {
      fields = (setting || []).map(value => {
        if ('Fields' in props.settingSchema) {
          return {
            id: shortid.generate(),
            ...value
          };
        } else {
          return {
            id: shortid.generate(),
            [props.settingSchema.Items.Label]: value
          };
        }
      });
    }

    return fields;
  }

  handleRemoveField (id) {
    this.setState({ fields: this.state.fields.filter(field => field.id !== id) });
  }

  // Handler for pure Core input components
  handleChange (event, value, name) {
    if (event.currentTarget) {
      name = event.currentTarget.name;
      value = event.currentTarget.value;
    }

    this.update(name, value);
  }

  // Handler for resource editor property field input changes
  handleConfigFieldChange (settingId, value, target) {
    // When a ConfigField renders an Editor, `target` becomes `name` b/c Editor onChange doesn't pass a native event object
    const name = target.name || target;
    this.update(name, value);
  }

  update (name, value) {
    name = name.split('~~~');
    const id = name[0];
    const key = name[1];

    this.setState({
      fields: this.state.fields.map(field => {
        if (field.id === id) {
          field[key] = value;
        }
        return field;
      })
    }, this.save());
  }

  save () {
    let value;

    if ('Fields' in this.props.settingSchema) {
      // Check that the fields (assumes a pair of fields) are not empty
      const fields = this.state.fields.filter(field => this.props.settingSchema.Fields.every(fieldSpec => field[fieldSpec.Label] !== ''));

      if (this.props.settingSchema.ValueType === 'object') {
        // TODO: abstract key/value setting (remove static keys). Update schema so key/val relationships are defined.
        value = {};

        fields.forEach(field => {
          value[field.Key] = field.Value;
        });
      } else if (this.props.settingSchema.ValueType === 'array') {
        value = fields.map(({id, ...attrs}) => attrs);
      }
    } else {
      const label = this.props.settingSchema.Items.Label;

      value = this.state.fields.map(field => field[label]).filter(value => !!value);
    }

    this.props.onChange(this.props.settingId, value);
  }

  addBlankField () {
    const fields = {};

    if ('Fields' in this.props.settingSchema) {
      this.props.settingSchema.Fields.forEach(field => {
        fields[field.Label] = '';
      });
    } else {
      fields[this.props.settingSchema.Items.Label] = '';
    }

    this.setState({
      fields: this.state.fields.concat({
        id: shortid.generate(),
        ...fields
      })
    });
  }

  componentDidMount () {
    this.addBlankField();
  }

  componentDidUpdate (prevProps, prevState) {
    const { settingSchema } = this.props;

    const length = this.state.fields.length;
    const lastField = this.state.fields[length - 1];
    const fieldSchema = settingSchema.Fields || [ settingSchema.Items ];

    if (
      (!('Maximum' in fieldSchema[0]) || length < fieldSchema[0].Maximum) &&
      fieldSchema.every(field => lastField[field.Label] !== '')
    ) {
      this.addBlankField();
    }

    if (length < prevState.fields.length) {
      this.save();
    }
  }

  render () {
    const {
      resource,
      settingSchema,
      settingId,
      configKeys,
      autocomplete,
      isHidden
    } = this.props;

    if (isHidden) {
      return null;
    }

    const length = this.state.fields.length - 1;
    const fieldSchema = settingSchema.Fields || [ settingSchema.Items ];
    const typeStyle = fieldSchema.length === 1 ? style.isSingle : style.isPair;

    return (
      <FieldSet
        legend={settingSchema.Label}
        hint={settingSchema.Description}
      >
        {this.state.fields.map((field, i) => (
          <div key={field.id} className={classnames(style.row, typeStyle)}>
            {Object.keys(field).filter(key => key !== 'id').map((key, ii) => {
              const autocompleteKey = fieldSchema[ii].Autocomplete;
              const suggestions = autocompleteKey ? autocomplete[autocompleteKey] : [];

              if (!fieldSchema[ii].IsConfigurable && fieldSchema[ii].InputType === 'select') {
                return (
                  <Select
                    key={`${field.id}${fieldSchema[ii].Label}`}
                    name={`${field.id}~~~${fieldSchema[ii].Label}`}
                    defaultValue={field[fieldSchema[ii].Label]}
                    onChange={this.handleChange}
                  >
                    <option disabled key={`${settingId}${i}`} value=''>Choose {fieldSchema[ii].Label}</option>
                    {fieldSchema[ii].Choices.map(choice => {
                      return <option key={`${settingId}${fieldSchema[ii].Label}${choice}`} value={choice}>{choice}</option>;
                    })}
                  </Select>
                );
              } else if (!fieldSchema[ii].IsConfigurable && fieldSchema[ii].InputType === 'input') {
                return (
                  <Input
                    key={`${field.id}${fieldSchema[ii].Label}`}
                    name={`${field.id}~~~${fieldSchema[ii].Label}`}
                    type='text'
                    placeholder={fieldSchema[ii].Label}
                    defaultValue={field[fieldSchema[ii].Label]}
                    onChange={this.handleChange}
                    suggestions={suggestions}
                  />
                );
              }

              return (
                <ConfigField
                  key={`${field.id}-${fieldSchema[ii].Label}`}
                  name={`${field.id}~~~${fieldSchema[ii].Label}`}
                  settingId={settingId}
                  resource={resource}
                  settingSchema={settingSchema}
                  configKeys={configKeys}
                  placeholder={fieldSchema[ii].Label}
                  onChange={this.handleConfigFieldChange}
                  currentValue={field[fieldSchema[ii].Label]}
                  suggestions={suggestions}
                />
              );
            })}

            <Icon
              name='remove-circle'
              className={classnames(style.iconRemove, i === length && style.iconRemoveDisabled)}
              onClick={() => i < length && this.handleRemoveField(field.id)}
            />
          </div>
        ))}
      </FieldSet>
    );
  }
}

export default ListField;
