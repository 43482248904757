/* NOTE: This component uses hooks instead of lifecycle methods. This is a new pattern in the codebase. */

import React from 'react';
import PropTypes from 'prop-types';
import MuiAlert from '@material-ui/lab/Alert';
import LinearProgress from '@material-ui/core/LinearProgress';
import Link from '../core/Link';
import Icon from '../core/Icon';
import UserNotificationContent from './UserNotificationContent';
import style from './UserNotification.css';

const AUTO_HIDE_DURATION = 6000;
const AUTO_HIDE_INTERVAL = 500;
const AUTO_HIDE_TYPES = ['info', 'success', 'warning', 'error'];

const UserNotification = (props) => {
  let timeoutRef = React.useRef(null);
  let intervalRef = React.useRef(null);

  const {
    notification,
    isVisible
  } = props;

  const contentRef = React.useRef(null);

  const [isOpen, setIsOpen] = React.useState(false);
  const [isClosed, setIsClosed] = React.useState(false);
  const [isHovered, setIsHovered] = React.useState(false);
  const [remainingDuration, setRemainingDuration] = React.useState(AUTO_HIDE_DURATION);
  const delayDuration = AUTO_HIDE_DURATION;

  React.useEffect(() => {
    if (contentRef && contentRef.current) {
      setIsClosed(contentRef.current.scrollWidth > contentRef.current.clientWidth);
    }
  }, [contentRef]);

  React.useEffect(() => {
    if (isHovered || isOpen || !isVisible) {
      stopTimeout();
    } else if (!isHovered && !isOpen && isVisible && AUTO_HIDE_TYPES.includes(notification.level)) {
      startTimeout();
    }

    return () => stopTimeout();
  }, [isHovered, isOpen, isVisible]);

  const handleToggle = () => {
    setIsOpen(!isOpen);
    props.onToggle(notification.id, !isOpen);
  };

  const handleHovered = (isHovered) => {
    setIsHovered(isHovered);
  };

  const decreaseRemainingDuration = () => setRemainingDuration((prev) => prev - AUTO_HIDE_INTERVAL);

  const stopTimeout = () => {
    clearTimeout(timeoutRef.current);
    clearInterval(intervalRef.current);
  };

  const startTimeout = () => {
    // Add AUTO_HIDE_INTERVAL to remainingDuration so LinearProgress completely disappears before the card does
    timeoutRef.current = setTimeout(() => props.onClose(null, null, notification.id), delayDuration - (delayDuration - (remainingDuration + AUTO_HIDE_INTERVAL)));
    intervalRef.current = setInterval(decreaseRemainingDuration, AUTO_HIDE_INTERVAL);
  };

  return (
    <MuiAlert
      id={notification.id}
      classes={{
        root: `${style.container} ${isVisible ? style.isVisible : ''}  ${isOpen ? style.isOpen : ''}  ${isClosed ? style.isClosed : ''}`,
        standardInfo: style.info,
        standardSuccess: style.success,
        standardWarning: style.warning,
        standardError: style.error,
        icon: style.icon,
        action: style.action
      }}
      severity={notification.level}
      elevation={props.elevation}
      onMouseOver={(event) => { handleHovered(true); }}
      onMouseOut={(event) => { handleHovered(false); }}
      onClose={(event, reason) => isVisible ? props.onClose(event, reason, notification.id) : null}
    >
      <div className={style.content} ref={contentRef}>
        <UserNotificationContent {...props} />
        {timeoutRef.current &&
          <LinearProgress
            classes={{
              root: style.timer,
              bar: style.timerBar
            }}
            variant='determinate'
            value={Math.floor((remainingDuration / delayDuration) * 100)}
          />
        }
        {(isOpen || isClosed) &&
          <Link extraClassName={style.toggleLink} onClick={handleToggle} title={isOpen ? 'Show less' : 'Show more'}><Icon font={isOpen ? 'expand_less' : 'expand_more'} size='small' /></Link>
        }
      </div>
    </MuiAlert>
  );
};

UserNotification.defaultProps = {
  level: 'info',
  elevation: 1
};

UserNotification.propTypes = {
  notification: PropTypes.object,
  level: PropTypes.oneOf(['info', 'success', 'warning', 'error']),
  elevation: PropTypes.number,
  onToggle: PropTypes.func,
  onClose: PropTypes.func.isRequired
};

export default UserNotification;
