import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push, replace } from 'connected-react-router';
import { createSelector } from 'reselect';
import * as states from '../../constants/states';
import * as stackCreatorStates from '../../constants/stackCreatorStates';
import * as localStorage from '../../utils/localStorage';
import getQueryValue from '../../utils/getQueryValue';
import { getCookie, deleteCookie } from '../../utils/cookie';
import appActions from '../../actions/app';
import apiActions from '../../actions/api';
import cmsActions from '../../actions/cms';
import Home from './Home';

const mapStateToProps = () => {
  return createSelector(
    (appState) => appState.account,
    (appState) => appState.currentUser,
    (appState) => appState.stacks,
    (appState) => appState.stackCreator,
    (appState) => appState.environments,
    (appState) => appState.cms,
    (account, currentUser, stacks, stackCreator, environments, cms) => {
      return {
        account,
        currentUser,
        stacks,
        stackCreator,
        environments,
        totalDeployments: account.usage.totalDeployments,
        activeStacks: account.activeStacks,
        usage: account.usage,
        cms,
        publicEditorStack: getCookie('stackery_public_editor'),
        isCreatingStack: stackCreator.state === states.LOADING,
        isLoadingStacks: stacks.state === states.NEW || stacks.state === states.LOADING,
        isLoadingEnvironments: environments.state === states.NEW || environments.state === states.LOADING,
        isLoadingUsage: account.usage.state === states.NEW || account.usage.state === states.LOADING,
        showCompletion: !localStorage.getItem('hideCompletion')
      };
    }
  );
};

const mapDispatchToProps = {
  push,
  replace,
  getStacks: apiActions.getStacks,
  createStack: apiActions.createStack,
  getEnvironments: apiActions.getEnvironments,
  getAccountUsage: apiActions.getAccountUsage,
  getActiveStacks: apiActions.getActiveStacks,
  showExitSetupModal: appActions.showExitSetupModal,
  dismissSection: cmsActions.dismissSection,
  startCms: cmsActions.startCms,
  stopCms: cmsActions.stopCms
};

class HomeContainer extends Component {
  componentDidMount () {
    const { publicEditorStack } = this.props;

    if (publicEditorStack) {
      this.props.createStack(null, null, null, {
        repository: `https://github.com/${getQueryValue(publicEditorStack, 'owner')}/${getQueryValue(publicEditorStack, 'repo')}`,
        reference: getQueryValue(publicEditorStack, 'ref') || 'master'
      }, getQueryValue(publicEditorStack, 'file'));
    }

    // Set initial startTime to the beginning of (internet) time and endTime to today
    const startTime = new Date(1970, 0, 1).toISOString();
    const endTime = new Date().toISOString();

    this.props.getStacks();
    this.props.getEnvironments();
    this.props.getAccountUsage(startTime, endTime, 1, 7);
    this.props.getActiveStacks();
    this.props.startCms();
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.stackCreator.path && this.props.stackCreator.state !== prevProps.stackCreator.state && this.props.stackCreator.state === stackCreatorStates.CREATED) {
      this.props.replace(`/stacks/${this.props.stackCreator.path}/edit`);
      deleteCookie('stackery_public_editor');
    }
  }

  componentWillUnmount () {
    this.props.stopCms();
  }

  render () {
    return (
      <Home {...this.props} />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer);
