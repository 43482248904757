import React from 'react';
import Link from '../core/Link';
import LinkButton from '../core/LinkButton';
import CodeBlock from '../core/CodeBlock';
import style from './DeploymentErrors.css';

const DeploymentErrors = (props) => {
  const {
    message,
    owner,
    stackName
  } = props.details;

  const environmentName = props.details.environmentName || props.details.name;

  return (
    <div>
      <h1>A deployment error occurred</h1>
      <p>Attempting to deploy <Link to={`/stacks/${owner}/${stackName}`} hid='error-modal-stack-link' onClick={props.onClickCancel}>{stackName}</Link> to <Link to={`/environments/${owner}/${environmentName}`} hid='error-modal-environment-link' onClick={props.onClickCancel}>{environmentName}</Link> failed with the following error:</p>
      <CodeBlock className={style.message} lines={[message]} theme='default' />

      <div className={style.callout}>
        <h2>Need some support?</h2>
        <div className={style.buttonGroup}>
          <LinkButton iconFont='menu_book' text='Check our docs' modifier='outline' layoutClassName={style.button} href='https://docs.stackery.io/docs/workflow/deploying-serverless-stacks#troubleshooting' target='_blank' hid='error-modal-docs-link' />
          <LinkButton iconFont='library_books' text='Check our blog' modifier='outline' layoutClassName={style.button} href='https://www.stackery.io/blog/top-10-deployment-errors' target='_blank' hid='error-modal-blog-link' />
        </div>
      </div>
    </div>
  );
};

export default DeploymentErrors;
