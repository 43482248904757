import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import canvasActions from '../../actions/canvas';
import selectors from '../../selectors';
import NodePalette from './NodePalette';

const mapStateToProps = () => {
  return createSelector(
    (appState) => appState.stack,
    (appState) => appState.canvas,
    (appState) => appState.editorNodes,
    selectors.nodeTypes,
    (stack, canvas, editorNodes, nodeTypes) => {
      return {
        open: canvas.paletteOpen,
        editorNodes: editorNodes.nodes,
        nodeTypes: nodeTypes.types,
        format: stack.format
      };
    }
  );
};

const mapDispatchToProps = {
  openPalette: canvasActions.openPalette,
  closePalette: canvasActions.clearSelection
};

class NodePaletteContainer extends Component {
  constructor (props) {
    super(props);

    this.state = {
      searchTerm: null,
      filteredNodeTypes: props.nodeTypes
    };

    this.filterNodeTypes = this.filterNodeTypes.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleShadeClick = this.handleShadeClick.bind(this);
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.searchTerm !== prevState.searchTerm) {
      this.filterNodeTypes();
    }
  }

  filterNodeTypes () {
    const nodeTypes = this.props.nodeTypes;
    const searchTerm = this.state.searchTerm ? this.state.searchTerm.toLowerCase() : '';

    const filteredNodeTypes = Object.keys(nodeTypes).reduce((allNodes, key) => {
      const paletteLabel = nodeTypes[key].paletteLabel || '';
      const matchesPaletteLabel = paletteLabel.toLowerCase().includes(searchTerm);

      const paletteHint = nodeTypes[key].paletteHint || '';
      const matchesPaletteHint = paletteHint.toLowerCase().includes(searchTerm);

      if (matchesPaletteLabel || matchesPaletteHint) {
        allNodes = { ...allNodes, [key]: nodeTypes[key] };
      }

      return allNodes;
    }, {});

    this.setState({ filteredNodeTypes });
  }

  handleSearch (event) {
    this.setState({ searchTerm: event.currentTarget.value.trim() });
  }

  handleShadeClick () {
    this.props.closePalette();
    document.querySelector('#canvas').focus({ preventScroll: true });
  }

  render () {
    return (
      <NodePalette
        {...this.props}
        onClose={this.handleShadeClick}
        onSearch={this.handleSearch}
        {...this.state}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NodePaletteContainer);
