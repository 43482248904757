
import React from 'react';
import Link from '../core/Link';

const EnvironmentLink = (props) => {
  if (!props.environmentExists) {
    return <em>{props.environmentName}</em>;
  }

  return <Link to={`/environments/${props.owner}/${props.environmentName}`}>{props.environmentName}</Link>;
};

export default EnvironmentLink;
