import React from 'react';
import classNames from 'classnames';
import * as states from '../../constants/states';
import Spinner from '../core/Spinner';
import style from './MetricsToolbar.css';

const rangeOptions = [
  {
    label: '5m',
    value: 5
  },
  {
    label: '15m',
    value: 15
  },
  {
    label: '1h',
    value: 60
  },
  {
    label: '12h',
    value: (60 * 12)
  },
  {
    label: '24h',
    value: (60 * 24)
  }
];

const renderIndicator = (range, selectedRange, label, state) => {
  return range === selectedRange && state === states.LOADING ? <Spinner position='static' height='15' /> : label;
};

const MetricsToolbar = (props) => {
  const selectedRange = props.cloudwatch.range;

  return (
    <div className={classNames(style.container)}>
      <strong className={style.heading}>Health metrics range</strong>
      <ul className={style.rangeList}>
        {rangeOptions.map((range, i) =>
          <li
            className={classNames(
              style.rangeListItem,
              { [style.rangeListItemSelected]: selectedRange === range.value }
            )}
            key={`${range.label}-${i}`}

          >
            <button onClick={() => props.onRangeClick(range.value)} tabIndex='0'>
              {renderIndicator(range.value, selectedRange, range.label, props.cloudwatch.state)}
            </button>
          </li>
        )}
      </ul>
    </div>
  );
};

export default MetricsToolbar;
