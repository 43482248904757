import makeActions from '../utils/makeActions';

export const types = {
  GET_DEFAULT_BLUEPRINT: makeActions('API', 'GET_DEFAULT_BLUEPRINT'),
  GET_STACKS: makeActions('API', 'GET_STACKS'),
  GET_STACK: makeActions('API', 'GET_STACK'),
  GET_STACK_SETTINGS: makeActions('API', 'GET_STACK_SETTINGS'),
  SAVE_STACK_SETTINGS: makeActions('API', 'SAVE_STACK_SETTINGS'),
  SAVE_STACK_TEMPLATE_PATH: makeActions('API', 'SAVE_STACK_TEMPLATE_PATH'),
  SAVE_STACK_DEPLOYMENT_STRATEGY: makeActions('API', 'SAVE_STACK_DEPLOYMENT_STRATEGY'),
  SAVE_STACK_DEPLOYMENT_HOOKS_DIRECTORY: makeActions('API', 'SAVE_STACK_DEPLOYMENT_HOOKS_DIRECTORY'),
  SAVE_STACK_BUILD_IMAGE: makeActions('API', 'SAVE_STACK_BUILD_IMAGE'),
  SAVE_STACK_VERIFICATION_PIPELINE: makeActions('API', 'SAVE_STACK_VERIFICATION_PIPELINE'),
  GET_IOT_NOTIFICATIONS_CHANNEL_INFO: makeActions('API', 'GET_IOT_NOTIFICATIONS_CHANNEL_INFO'),
  SAVE_USER_NOTIFICATION: makeActions('API', 'SAVE_USER_NOTIFICATION'),
  GET_USER_NOTIFICATIONS: makeActions('API', 'GET_USER_NOTIFICATIONS'),
  UPDATE_USER_NOTIFICATION: makeActions('API', 'UPDATE_USER_NOTIFICATION'),
  DELETE_USER_NOTIFICATION: makeActions('API', 'DELETE_USER_NOTIFICATION'),
  DELETE_ALL_USER_NOTIFICATIONS: makeActions('API', 'DELETE_ALL_USER_NOTIFICATIONS'),
  GET_STACK_CONTENTS: makeActions('API', 'GET_STACK_CONTENTS'),
  GET_NODE_TEMPLATE: makeActions('API', 'GET_NODE_TEMPLATE'),
  GET_CONFIG_KEYS: makeActions('API', 'GET_CONFIG_KEYS'),
  GET_DEPLOYMENTS: makeActions('API', 'GET_DEPLOYMENTS'),
  GET_DEPLOYMENT_STATE: makeActions('API', 'GET_DEPLOYMENT_STATE'),
  GET_CHANGES: makeActions('API', 'GET_CHANGES'),
  CREATE_DEPLOYMENT_PIPELINE: makeActions('API', 'CREATE_DEPLOYMENT_PIPELINE'),
  DELETE_DEPLOYMENT_PIPELINE: makeActions('API', 'DELETE_DEPLOYMENT_PIPELINE'),
  GET_DEPLOYMENT_PIPELINES: makeActions('API', 'GET_DEPLOYMENT_PIPELINES'),
  GET_DEPLOYMENT_PIPELINE_SETTINGS: makeActions('API', 'GET_DEPLOYMENT_PIPELINE_SETTINGS'),
  GET_DEPLOYMENT_PIPELINE_STATUS: makeActions('API', 'GET_DEPLOYMENT_PIPELINE_STATUS'),
  UPDATE_DEPLOYMENT_PIPELINE_SETTINGS: makeActions('API', 'UPDATE_DEPLOYMENT_PIPELINE_SETTINGS'),
  PROMOTE_DEPLOYMENT_PIPELINE: makeActions('API', 'PROMOTE_DEPLOYMENT_PIPELINE'),
  RETRY_DEPLOYMENT_PIPELINE: makeActions('API', 'RETRY_DEPLOYMENT_PIPELINE'),
  CREATE_BRANCH: makeActions('API', 'CREATE_BRANCH'),
  SET_STACK_REPO: makeActions('API', 'SET_STACK_REPO'),
  SAVE_NODES: makeActions('API', 'SAVE_NODES'),
  SAVE_RESOURCES: makeActions('API', 'SAVE_RESOURCES'),
  CREATE_BRANCH_AND_SAVE_NODES: makeActions('API', 'CREATE_BRANCH_AND_SAVE_NODES'),
  CREATE_BRANCH_AND_SAVE_RESOURCES: makeActions('API', 'CREATE_BRANCH_AND_SAVE_RESOURCES'),
  PREPARE: makeActions('API', 'PREPARE'),
  GET_STACK_BRANCHES: makeActions('API', 'GET_STACK_BRANCHES'),
  GET_STACK_ENVIRONMENTS: makeActions('API', 'GET_STACK_ENVIRONMENTS'),
  GET_ENVIRONMENTS: makeActions('API', 'GET_ENVIRONMENTS'),
  GET_ENVIRONMENT: makeActions('API', 'GET_ENVIRONMENT'),
  CREATE_ENVIRONMENT: makeActions('API', 'CREATE_ENVIRONMENT'),
  GET_ENVIRONMENT_PARAMETERS: makeActions('API', 'GET_ENVIRONMENT_PARAMETERS'),
  GET_ENVIRONMENT_SECRETS: makeActions('API', 'GET_ENVIRONMENT_SECRETS'),
  CREATE_ENVIRONMENT_SECRET: makeActions('API', 'CREATE_ENVIRONMENT_SECRET'),
  DELETE_ENVIRONMENT_SECRET: makeActions('API', 'DELETE_ENVIRONMENT_SECRET'),
  SAVE_ENVIRONMENT_SECRET_DESCRIPTION: makeActions('API', 'SAVE_ENVIRONMENT_SECRET_DESCRIPTION'),
  SAVE_ENVIRONMENT_SECRET_VALUE: makeActions('API', 'SAVE_ENVRIONMENT_SECRET_VALUE'),
  SAVE_ENVIRONMENT_PARAMETERS: makeActions('API', 'SAVE_ENVIRONMENT_PARAMETERS'),
  CREATE_NEW_STACK_ENVIRONMENT: makeActions(
    'API',
    'CREATE_NEW_STACK_ENVIRONMENT'
  ),
  SET_CREDIT_CARD: makeActions('API', 'SET_CREDIT_CARD'),
  SET_CREDIT_CARD_AND_SWITCH_PLAN: makeActions('API', 'SET_CREDIT_CARD_AND_SWITCH_PLAN'),
  GET_CREDIT_CARD: makeActions('API', 'GET_CREDIT_CARD'),
  SET_MARKETPLACE: makeActions('API', 'SET_MARKETPLACE'),
  GET_PLAN: makeActions('API', 'GET_PLAN'),
  SWITCH_PLAN: makeActions('API', 'SWITCH_PLAN'),
  GET_ORGANIZATION_USERS: makeActions('API', 'GET_ORGANIZATION_USERS'),
  GET_ORGANIZATION_INVITED_USERS: makeActions('API', 'GET_ORGANIZATION_INVITED_USERS'),
  CREATE_SAME_ORGANIZATION_INVITE: makeActions(
    'API',
    'CREATE_SAME_ORGANIZATION_INVITE'
  ),
  REMOVE_USER: makeActions('API', 'REMOVE_USER'),
  REMOVE_INVITED_USER: makeActions('API', 'REMOVE_INVITED_USER'),
  UPDATE_USER_ROLE: makeActions('API', 'UPDATE_USER_ROLE'),
  CURRENT_USER: makeActions('API', 'CURRENT_USER'),
  CREATE_STACK: makeActions('API', 'CREATE_STACK'),
  DELETE_STACK: makeActions('API', 'DELETE_STACK'),
  RENAME_STACK: makeActions('API', 'RENAME_STACK'),
  SET_STACK_TAGS: makeActions('API', 'SET_STACK_TAGS'),
  SAVE_STACK_BLUEPRINT: makeActions('API', 'SAVE_STACK_BLUEPRINT'),
  DELETE_STACK_BLUEPRINT: makeActions('API', 'DELETE_STACK_BLUEPRINT'),
  GET_STACK_BLUEPRINTS: makeActions('API', 'GET_STACK_BLUEPRINTS'),
  DELETE_ENVIRONMENT: makeActions('API', 'DELETE_ENVIRONMENT'),
  SAVE_GIT_PERSONAL_ACCESS_TOKEN: makeActions('API', 'SAVE_GIT_PERSONAL_ACCESS_TOKEN'),
  GET_GIT_AUTH_URL: makeActions('API', 'GET_GIT_AUTH_URL'),
  GET_GIT_REPOS: makeActions('API', 'GET_GIT_REPOS'),
  GET_GIT_AUTH_TOKEN: makeActions('API', 'GET_GIT_AUTH_TOKEN'),
  GET_GIT_DESTINATIONS: makeActions('API', 'GET_GIT_DESTINATIONS'),
  GET_GIT_PROJECTS: makeActions('API', 'GET_GIT_PROJECTS'),
  INVITE_SIGN_UP: makeActions('API', 'INVITE_SIGN_UP'),
  SIGN_UP: makeActions('API', 'SIGN_UP'),
  VERIFY_USER_EMAIL: makeActions('API', 'VERIFY_USER_EMAIL'),
  RESEND_VERIFY_EMAIL: makeActions('API', 'RESEND_VERIFY_EMAIL'),
  REMOVE_SETUP_REQUIREMENT: makeActions('API', 'REMOVE_SETUP_REQUIREMENT'),
  GET_AUTH_INFO: makeActions('API', 'GET_AUTH_INFO'),
  GET_ACCOUNT_INFO: makeActions('API', 'GET_ACCOUNT_INFO'),
  GET_TEMPLATE_VERSION: makeActions('API', 'GET_TEMPLATE_VERSION'),
  GET_ENVIRONMENT_DEPLOYMENTS: makeActions('API', 'GET_ENVIRONMENT_DEPLOYMENTS'),
  GET_ACCOUNT_SETTINGS: makeActions('API', 'GET_ACCOUNT_SETTINGS'),
  GET_ACCOUNT_USAGE: makeActions('API', 'GET_ACCOUNT_USAGE'),
  GET_ACTIVE_STACKS: makeActions('API', 'GET_ACTIVE_STACKS'),
  CREATE_ACCOUNT_PROVIDER: makeActions('API', 'CREATE_ACCOUNT_PROVIDER'),
  MONITOR_NEW_ACCOUNT_PROVIDER_STATUS: makeActions('API', 'MONITOR_NEW_ACCOUNT_PROVIDER_STATUS'),
  GET_ACCOUNT_PROVIDER_STATUS: makeActions('API', 'GET_ACCOUNT_PROVIDER_STATUS'),
  GET_INTEGRATIONS: makeActions('API', 'GET_INTEGRATIONS'),
  SAVE_INTEGRATIONS: makeActions('API', 'SAVE_INTEGRATIONS'),
  GET_CLI_TOKENS: makeActions('API', 'GET_CLI_TOKENS'),
  CREATE_CLI_TOKEN: makeActions('API', 'CREATE_CLI_TOKEN'),
  DELETE_CLI_TOKEN: makeActions('API', 'DELETE_CLI_TOKEN'),
  GET_BUILD_ENVIRONMENT: makeActions('API', 'GET_BUILD_ENVIRONMENT'),
  SAVE_BUILD_ENVIRONMENT: makeActions('API', 'SAVE_BUILD_ENVIRONMENT'),
  GET_ENVIRONMENT_GIT_DIFF: makeActions('API', 'GET_ENVIRONMENT_GIT_DIFF')
};

const actions = {
  getDefaultBlueprint: (format) => ({
    type: types.GET_DEFAULT_BLUEPRINT.REQUEST,
    format
  }),
  getStacks: () => ({ type: types.GET_STACKS.REQUEST }),
  getStack: (owner, name) => ({
    type: types.GET_STACK.REQUEST,
    owner,
    name
  }),
  getStackSettings: (owner, stack) => ({ type: types.GET_STACK_SETTINGS.REQUEST, owner, stack }),
  saveStackSettings: (stack, newName, templatePath, deploymentStrategy, deploymentHooksDirectory, buildImage, verificationPipeline) => ({
    type: types.SAVE_STACK_SETTINGS.REQUEST,
    stack,
    newName,
    templatePath,
    deploymentStrategy,
    deploymentHooksDirectory,
    buildImage,
    verificationPipeline
  }),
  saveStackTemplatePath: (stack, templatePath) => ({
    type: types.SAVE_STACK_TEMPLATE_PATH.REQUEST,
    templatePath,
    stack
  }),
  saveStackDeploymentStrategy: (stack, deploymentStrategy) => ({
    type: types.SAVE_STACK_DEPLOYMENT_STRATEGY.REQUEST,
    deploymentStrategy,
    stack
  }),
  saveStackDeploymentHooksDirectory: (stack, deploymentHooksDirectory) => ({
    type: types.SAVE_STACK_DEPLOYMENT_HOOKS_DIRECTORY.REQUEST,
    deploymentHooksDirectory,
    stack
  }),
  saveStackBuildImage: (stack, buildImage) => ({
    type: types.SAVE_STACK_BUILD_IMAGE.REQUEST,
    buildImage,
    stack
  }),
  saveStackVerificationPipeline: (stack, settings) => ({
    type: types.SAVE_STACK_VERIFICATION_PIPELINE.REQUEST,
    settings,
    stack
  }),
  getIotNotificationsChannelInfo: (owner, stack) => ({
    type: types.GET_IOT_NOTIFICATIONS_CHANNEL_INFO.REQUEST,
    owner,
    stack
  }),
  saveUserNotification: (owner, notification) => ({
    type: types.SAVE_USER_NOTIFICATION.REQUEST,
    owner,
    notification
  }),
  getUserNotifications: (owner, itemsPerPage, nextToken) => ({
    type: types.GET_USER_NOTIFICATIONS.REQUEST,
    owner,
    itemsPerPage,
    nextToken
  }),
  deleteUserNotification: (owner, notification) => ({
    type: types.DELETE_USER_NOTIFICATION.REQUEST,
    owner,
    notification
  }),
  deleteAllUserNotifications: (owner) => ({
    type: types.DELETE_ALL_USER_NOTIFICATIONS.REQUEST,
    owner
  }),
  updateUserNotification: (owner, notification) => ({
    type: types.UPDATE_USER_NOTIFICATION.REQUEST,
    owner,
    notification
  }),
  getStackContents: (owner, name, branch) => ({
    type: types.GET_STACK_CONTENTS.REQUEST,
    owner,
    name,
    branch
  }),
  getNodeTemplate: (owner, stack, node) => ({
    type: types.GET_NODE_TEMPLATE.REQUEST,
    owner,
    stack,
    node
  }),
  getConfigKeys: (owner, stack) => ({
    type: types.GET_CONFIG_KEYS.REQUEST,
    owner,
    stack
  }),
  getDeployments: (owner, stack, environment) => ({
    type: types.GET_DEPLOYMENTS.REQUEST,
    owner,
    stack,
    environment
  }),
  getDeploymentState: (owner, stack, environment, deployment, ephemeralId) => ({
    type: types.GET_DEPLOYMENT_STATE.REQUEST,
    owner,
    stack,
    environment,
    deployment,
    ephemeralId
  }),
  getChanges: (owner, stack, environment, deployment) => ({
    type: types.GET_CHANGES.REQUEST,
    owner,
    stack,
    environment,
    deployment
  }),
  createDeploymentPipeline: (name, stages, stacks) => ({
    type: types.CREATE_DEPLOYMENT_PIPELINE.REQUEST,
    name,
    stages,
    stacks
  }),
  deleteDeploymentPipeline: (owner, pipelineId) => ({
    type: types.DELETE_DEPLOYMENT_PIPELINE.REQUEST,
    owner,
    pipelineId
  }),
  getDeploymentPipelines: () => ({
    type: types.GET_DEPLOYMENT_PIPELINES.REQUEST
  }),
  getDeploymentPipelineSettings: (owner, pipelineId) => ({
    type: types.GET_DEPLOYMENT_PIPELINE_SETTINGS.REQUEST,
    owner,
    pipelineId
  }),
  getDeploymentPipelineStatus: (owner, pipelineId) => ({
    type: types.GET_DEPLOYMENT_PIPELINE_STATUS.REQUEST,
    owner,
    pipelineId
  }),
  updateDeploymentPipelineSettings: (owner, pipelineId, name, stages, stacks) => ({
    type: types.UPDATE_DEPLOYMENT_PIPELINE_SETTINGS.REQUEST,
    owner,
    pipelineId,
    name,
    stages,
    stacks
  }),
  promoteDeploymentPipeline: (owner, pipelineId, stackId, deploymentHistoryId, version, stageIndex) => ({
    type: types.PROMOTE_DEPLOYMENT_PIPELINE.REQUEST,
    owner,
    pipelineId,
    stackId,
    deploymentHistoryId,
    version,
    stageIndex
  }),
  retryDeploymentPipeline: (owner, pipelineId, stackId, deploymentHistoryId, version, stageIndex) => ({
    type: types.RETRY_DEPLOYMENT_PIPELINE.REQUEST,
    owner,
    pipelineId,
    stackId,
    deploymentHistoryId,
    version,
    stageIndex
  }),
  createBranch: (owner, stack, baseBranch, newBranch) => ({
    type: types.CREATE_BRANCH.REQUEST,
    owner,
    stack,
    baseBranch,
    newBranch
  }),
  setStackRepo: (owner, stack, repoUrl, awsAccount) => ({
    type: types.SET_STACK_REPO.REQUEST,
    owner,
    stack,
    repoUrl,
    awsAccount
  }),
  saveNodes: (owner, stack, branch, nodes, commit) => ({
    type: types.SAVE_NODES.REQUEST,
    owner,
    stack,
    branch,
    nodes,
    commit
  }),
  saveResources: (owner, stack, branch, template, format, commit, newRepository) => ({
    type: types.SAVE_RESOURCES.REQUEST,
    owner,
    stack,
    branch,
    template,
    format,
    commit,
    newRepository
  }),
  createBranchAndSaveNodes: (owner, stack, baseBranch, newBranch, nodes, commit) => ({
    type: types.CREATE_BRANCH_AND_SAVE_NODES.REQUEST,
    owner,
    stack,
    baseBranch,
    newBranch,
    nodes,
    commit
  }),
  createBranchAndSaveResources: (owner, stack, baseBranch, newBranch, template, format, commit) => ({
    type: types.CREATE_BRANCH_AND_SAVE_RESOURCES.REQUEST,
    owner,
    stack,
    baseBranch,
    newBranch,
    template,
    format,
    commit
  }),
  prepare: (owner, stack, reference, environment) => ({
    type: types.PREPARE.REQUEST,
    owner,
    stack,
    reference,
    environment
  }),
  getStackBranches: (owner, name) => ({
    type: types.GET_STACK_BRANCHES.REQUEST,
    owner,
    name
  }),
  getStackEnvironments: (owner, name) => ({
    type: types.GET_STACK_ENVIRONMENTS.REQUEST,
    owner,
    name
  }),
  getEnvironments: () => ({ type: types.GET_ENVIRONMENTS.REQUEST }),
  getEnvironment: (owner, environment) => ({
    type: types.GET_ENVIRONMENT.REQUEST,
    owner,
    environment
  }),
  createEnvironment: (name, region, accountId) => ({
    type: types.CREATE_ENVIRONMENT.REQUEST,
    name,
    region,
    accountId
  }),
  getEnvironmentParameters: (owner, environment) => ({
    type: types.GET_ENVIRONMENT_PARAMETERS.REQUEST,
    owner,
    environment
  }),
  getEnvironmentSecrets: (owner, environment) => ({
    type: types.GET_ENVIRONMENT_SECRETS.REQUEST,
    owner,
    environment
  }),
  createEnvironmentSecret: (owner, environment, secret, value, description) => ({
    type: types.CREATE_ENVIRONMENT_SECRET.REQUEST,
    owner,
    environment,
    secret,
    value,
    description
  }),
  deleteEnvironmentSecret: (owner, environment, secret) => ({
    type: types.DELETE_ENVIRONMENT_SECRET.REQUEST,
    owner,
    environment,
    secret
  }),
  saveEnvrionmentSecretDescription: (owner, environment, secret, description) => ({
    type: types.SAVE_ENVIRONMENT_SECRET_DESCRIPTION.REQUEST,
    owner,
    environment,
    secret,
    description
  }),
  saveEnvrionmentSecretValue: (owner, environment, secret, value) => ({
    type: types.SAVE_ENVIRONMENT_SECRET_VALUE.REQUEST,
    owner,
    environment,
    secret,
    value
  }),
  saveEnvironmentParameters: (owner, environment, parameters, currentVersion) => ({
    type: types.SAVE_ENVIRONMENT_PARAMETERS.REQUEST,
    owner,
    environment,
    parameters,
    currentVersion
  }),
  getEnvironmentDeployments: (owner, environment) => ({
    type: types.GET_ENVIRONMENT_DEPLOYMENTS.REQUEST,
    owner,
    environment
  }),
  setMarketplace: (token) => ({ type: types.SET_MARKETPLACE.REQUEST, token }),
  getPlan: (awsMarketplaceToken) => ({ type: types.GET_PLAN.REQUEST, awsMarketplaceToken }),
  switchPlan: (requestedType) => ({
    type: types.SWITCH_PLAN.REQUEST,
    requestedType
  }),
  getOrganizationUsers: () => ({ type: types.GET_ORGANIZATION_USERS.REQUEST }),
  getOrganizationInvitedUsers: () => ({ type: types.GET_ORGANIZATION_INVITED_USERS.REQUEST }),
  createStack: (provider, name, location, source, templatePath, isPrivate, shouldClone, awsAccount, project) => ({
    type: types.CREATE_STACK.REQUEST,
    provider,
    name,
    location,
    source,
    templatePath,
    isPrivate,
    shouldClone,
    awsAccount,
    project
  }),
  deleteStack: (id, owner, stack) => ({
    type: types.DELETE_STACK.REQUEST,
    id,
    owner,
    stack
  }),
  renameStack: (id, owner, stack, newName) => ({
    type: types.RENAME_STACK.REQUEST,
    id,
    owner,
    stack,
    newName
  }),
  setStackTags: (id, owner, stack, tags) => ({
    type: types.SET_STACK_TAGS.REQUEST,
    id,
    owner,
    stack,
    tags
  }),
  getStackBlueprints: (owner) => ({
    type: types.GET_STACK_BLUEPRINTS.REQUEST,
    owner
  }),
  saveStackBlueprint: (owner, name, source) => ({
    type: types.SAVE_STACK_BLUEPRINT.REQUEST,
    owner,
    name,
    source
  }),
  deleteStackBlueprint: (owner, name) => ({
    type: types.DELETE_STACK_BLUEPRINT.REQUEST,
    owner,
    name
  }),
  deleteEnvironment: (owner, name) => ({
    type: types.DELETE_ENVIRONMENT.REQUEST,
    owner,
    name
  }),
  saveGitPersonalAccessToken: (provider, user, token) => ({
    type: types.SAVE_GIT_PERSONAL_ACCESS_TOKEN.REQUEST,
    provider,
    user,
    token
  }),
  getGitAuthUrl: (provider) => ({
    type: types.GET_GIT_AUTH_URL.REQUEST,
    provider
  }),
  getGitAuthToken: (provider, code, state, token, verifier) => ({
    type: types.GET_GIT_AUTH_TOKEN.REQUEST,
    provider,
    code,
    state,
    token,
    verifier
  }),
  getGitRepos: (provider) => ({
    type: types.GET_GIT_REPOS.REQUEST,
    provider
  }),
  getGitDestinations: (provider) => ({
    type: types.GET_GIT_DESTINATIONS.REQUEST,
    provider
  }),
  getGitProjects: (provider) => ({
    type: types.GET_GIT_PROJECTS.REQUEST,
    provider
  }),
  createSameOrganizationInvite: (email, isAdmin, organization, inviter) => ({
    type: types.CREATE_SAME_ORGANIZATION_INVITE.REQUEST,
    email,
    isAdmin,
    organization,
    inviter
  }),
  removeUser: email => ({ type: types.REMOVE_USER.REQUEST, email }),
  removeInvitedUser: email => ({ type: types.REMOVE_INVITED_USER.REQUEST, email }),
  updateUserRole: (email, isAdmin) => ({ type: types.UPDATE_USER_ROLE.REQUEST, email, isAdmin }),
  getCurrentUser: () => ({ type: types.CURRENT_USER.REQUEST }),
  inviteSignUp: (
    email,
    organization,
    firstName,
    lastName,
    password,
    token
  ) => ({
    type: types.INVITE_SIGN_UP.REQUEST,
    email,
    organization,
    firstName,
    lastName,
    password,
    token
  }),
  verifyUserEmail: (hmac) => ({
    type: types.VERIFY_USER_EMAIL.REQUEST,
    hmac
  }),
  resendVerifyEmail: () => ({
    type: types.RESEND_VERIFY_EMAIL.REQUEST
  }),
  removeSetupRequirement: () => ({
    type: types.REMOVE_SETUP_REQUIREMENT.REQUEST
  }),
  createAccount: (email, password, plan, promo, awsMarketplaceToken) => ({
    type: types.SIGN_UP.REQUEST,
    email,
    password,
    plan,
    promo,
    awsMarketplaceToken
  }),
  getAccountInfo: () => ({
    type: types.GET_ACCOUNT_INFO.REQUEST
  }),
  getTemplateVersion: () => ({
    type: types.GET_TEMPLATE_VERSION.REQUEST
  }),
  getAccountSettings: () => ({
    type: types.GET_ACCOUNT_SETTINGS.REQUEST
  }),
  getAccountUsage: (startTime, endTime, page, perPage, filterBy) => ({
    type: types.GET_ACCOUNT_USAGE.REQUEST,
    startTime,
    endTime,
    page,
    perPage,
    filterBy
  }),
  getActiveStacks: (startDate, endDate, filterBy) => ({
    type: types.GET_ACTIVE_STACKS.REQUEST,
    startDate,
    endDate,
    filterBy
  }),
  createAccountProvider: () => ({
    type: types.CREATE_ACCOUNT_PROVIDER.REQUEST
  }),
  monitorNewAccountProviderStatus: (externalId, isDeployerRoleEnabled, email) => ({
    type: types.MONITOR_NEW_ACCOUNT_PROVIDER_STATUS.REQUEST,
    externalId,
    isDeployerRoleEnabled,
    email
  }),
  getIntegrations: () => ({
    type: types.GET_INTEGRATIONS.REQUEST
  }),
  saveIntegrations: integrations => ({
    type: types.SAVE_INTEGRATIONS.REQUEST,
    integrations
  }),
  getCliTokens: () => ({
    type: types.GET_CLI_TOKENS.REQUEST
  }),
  createCliToken: (description) => ({
    type: types.CREATE_CLI_TOKEN.REQUEST,
    description
  }),
  deleteCliToken: (id) => ({
    type: types.DELETE_CLI_TOKEN.REQUEST,
    id
  }),
  getBuildEnvironment: () => ({
    type: types.GET_BUILD_ENVIRONMENT.REQUEST
  }),
  saveBuildEnvironment: (environment) => ({
    type: types.SAVE_BUILD_ENVIRONMENT.REQUEST,
    environment
  }),
  getEnvironmentGitDiff: (owner, stack, environmentId1, environmentId2) => ({
    type: types.GET_ENVIRONMENT_GIT_DIFF.REQUEST,
    owner,
    stack,
    environmentId1,
    environmentId2
  })
};

export default actions;
