import { types as canvasTypes } from '../actions/canvas';
import { types as appTypes } from '../actions/app';
import { types } from '../actions/nodeDeploymentInfo';

export const initialState = {
  node: undefined
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case types.SHOW:
      return {
        ...state,
        node: action.node
      };

    case types.CLOSE:
    case canvasTypes.CLEAR_SELECTION:
    case canvasTypes.DESELECT_NODE:
    case canvasTypes.SELECT_NODES:
    case appTypes.DESELECT_STACK_RESOURCE:
      return initialState;

    default:
      return state;
  }
};
