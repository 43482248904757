import React from 'react';
import StackLink from './StackLink';

const PipelineStepDisabledEvent = (props) => {
  const stack = props.context === 'stacks' ? <em>{props.stackName}</em> : <StackLink {...props} />;

  return <span><em>{props.pipelineStep}</em> disabled on {stack} by {props.userEmail}</span>;
};

export default PipelineStepDisabledEvent;
