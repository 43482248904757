import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import style from './Textarea.css';

const Textarea = (props) => {
  return (
    <textarea
      id={props.id}
      name={props.name}
      className={classNames(
        props.className,
        props.hasError && props.errorClassName
      )}
      placeholder={props.placeholder}
      required={props.required}
      onChange={props.onChange}
      defaultValue={props.defaultValue}
      ref={props.inputRef}
      rows={props.rows}
      tabIndex='0'
    />
  );
};

Textarea.defaultProps = {
  className: style.container,
  errorClassName: style.hasError,
  hasError: false,
  required: false,
  pattern: null
};

Textarea.propTypes = {
  className: PropTypes.string,
  errorClassName: PropTypes.string,
  pattern: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  id: PropTypes.number,
  rows: PropTypes.number,
  hasError: PropTypes.bool,
  required: PropTypes.bool,
  inputRef: PropTypes.func,
  onChange: PropTypes.func
};

export default Textarea;
