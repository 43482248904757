import { types as apiTypes } from '../actions/api';
import { types as appTypes } from '../actions/app';
import * as states from '../constants/authStates';

export const initialState = {
  state: states.NEW,
  error: undefined
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case apiTypes.CREATE_BRANCH.FAILURE:
    case apiTypes.SAVE_NODES.FAILURE:
    case apiTypes.GET_STACK_CONTENTS.FAILURE:
    case apiTypes.GET_STACK_BRANCHES.FAILURE:
      if (action.error.name !== 'UnauthorizedError') { return state; }
      return {
        ...state,
        state: states.FAILED,
        error: action.error.message
      };
    case apiTypes.CREATE_BRANCH.SUCCESS:
    case apiTypes.SAVE_NODES.SUCCESS:
    case apiTypes.GET_STACK_CONTENTS.SUCCESS:
    case apiTypes.GET_STACK_BRANCHES.SUCCESS:
      return {
        ...state,
        state: states.OKAY,
        error: undefined
      };
    case appTypes.DESELECT_STACK:
      return {
        ...initialState
      };
    default:
      return state;
  }
};
