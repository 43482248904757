import getQueryValue from '../../utils/getQueryValue';
import { isSupported as isLocalStorageSupported } from '../../utils/localStorage';

/* We can't access localStorage in VSCode WebViews. This reimplements
 * localStorage by storing it in the VSCode extension. When the WebView is
 * loaded, create a local localStorage object and initialize it with the current
 * state from the extension via the 'localstorage' query string parameter. When
 * localStorage.setItem() is called, we relay the call to the extension to
 * record the value. */

const initializeLocalStorage = () => {
  let localStorage = {};

  try {
    const localStorageJSON = getQueryValue(window.location.search, 'localstorage');

    if (localStorageJSON) {
      // Remove localstorage query param now for some extra bit of security
      window.history.pushState(null, '', window.location.href.replace(/localstorage=[^&]*/, ''));

      localStorage = JSON.parse(localStorageJSON);
    }
  } catch (err) {
    console.warn(`Failed to get local storage state: ${err}`);
    console.warn(`url search string: ${window.location.search}`);
  }

  /* Versions of VSCode < 1.47.0 did not have window.localStorage. Backfill it
   * by storing the data in the extension. */
  const localStoragePolyfill = {
    getItem (key) {
      return localStorage[key];
    },

    setItem (key, value) {
      localStorage[key] = value;

      window.parent.postMessage({
        command: 'onmessage',
        data: {
          type: 'localStorage.setItem',
          key,
          value
        }
      }, '*');
    },

    removeItem (key) {
      delete localStorage[key];

      window.parent.postMessage({
        command: 'onmessage',
        data: {
          type: 'localStorage.removeItem',
          key
        }
      }, '*');
    },

    clear () {
      localStorage = {};

      window.parent.postMessage({
        command: 'onmessage',
        data: {
          type: 'localStorage.clear'
        }
      }, '*');
    },

    key (index) {
      return Object.keys(localStorage)[index];
    }
  };

  if (!isLocalStorageSupported()) {
    try {
      window.localStorage = localStoragePolyfill;
    } catch (err) {
      if (!err.message.startsWith("Cannot assign to read only property 'localStorage' of object")) {
        throw err;
      }
    }
  }
};

initializeLocalStorage();

/* Mimic what the built-in webview iframe does, sending keydown events to the
 * extension host. */
window.addEventListener('keydown', e => {
  window.parent.postMessage({
    command: 'did-keydown',
    data: {
      key: e.key,
      keyCode: e.keyCode,
      code: e.code,
      shiftKey: e.shiftKey,
      altKey: e.altKey,
      ctrlKey: e.ctrlKey,
      metaKey: e.metaKey,
      repeat: e.repeat
    }
  }, '*');
});
