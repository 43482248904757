const NODEJS_CONTENTS = (logicalId) => (
  {
    'index.js':
      `exports.handler = async (event, context) => {
  // Log the event argument for debugging and for use in local development.
  console.log(JSON.stringify(event, undefined, 2));

  return {};
};
`,
    'package.json':
      `{
  "name": "${logicalId.toLowerCase()}",
  "version": "1.0.0",
  "devDependencies": {
    "aws-sdk": "~2"
  }
}
`
  }
);

const TYPESCRIPT_CONTENTS = (logicalId) => (
  {
    'index.ts':
`export const handler = async (event: any = {}): Promise<any> => {
  // Log the event argument for debugging and for use in local development.
  const response = JSON.stringify(event, undefined, 2);
  console.log(response);

  return response;
}
`,
    'package.json':
`{
  "name": "${logicalId.toLowerCase()}",
  "version": "1.0.0",
  "scripts": {
    "build": "tsc"
  },
  "devDependencies": {
    "aws-sdk": "~2",
    "@types/aws-sdk": "^2.7.0",
    "@types/node": "^12.6.8",
    "typescript": "^3.5.3"
  }
}
`,
    'tsconfig.json':
`{
  "compilerOptions": {
    "target": "es2017",
    "module": "commonjs"
  },
  "exclude": [
    "node_modules"
  ],
  "types": [
    "node",
    "aws-sdk"
  ]
}
`
  }
);

const PYTHON_CONTENTS = (logicalId) => (
  {
    'handler.py':
      `import json
def handler(event, context):
    # Log the event argument for debugging and for use in local development.
    print(json.dumps(event))

    return {}`,
    'requirements.txt':
      'boto3'
  }
);

const JAVA_CONTENTS = (logicalId) => (
  {
    'src/main/java/Handler.java':
      `import java.util.HashMap;
import com.google.gson.Gson;

public class Handler {
    public Object handler(Object event) {
        Gson gson = new Gson();
        System.out.println(gson.toJson(event));

        return new HashMap();
    }
}
`,
    'build.gradle':
      `apply plugin: 'java'

repositories {
    mavenCentral()
}

dependencies {
    compile (
        'com.amazonaws:aws-lambda-java-core:1.1.0',
        'com.amazonaws:aws-lambda-java-events:1.1.0',
        'com.google.code.gson:gson:2.8.5'
    )
}

task buildZip(type: Zip) {
    from compileJava
    from processResources
    into('lib') {
        from configurations.compileClasspath
    }
}

build.dependsOn buildZip
`
  }
);

const DOTNET_21_CONTENTS = () => (
  {
    'Handler.cs':
      `using System;
using Amazon.Lambda.Core;
using Newtonsoft.Json;

namespace StackeryFunction
{
    public class Handler
    {
        [LambdaSerializer(typeof(Amazon.Lambda.Serialization.Json.JsonSerializer))]
        public dynamic handler(dynamic eventTrigger)
        {
            Console.WriteLine(JsonConvert.SerializeObject(eventTrigger));

            return new {};
        }
    }
}
`,
    'StackeryFunction.csproj':
      `
<Project Sdk="Microsoft.NET.Sdk">

  <PropertyGroup>
    <TargetFramework>netcoreapp2.1</TargetFramework>
  </PropertyGroup>

  <ItemGroup>
    <PackageReference Include="Amazon.Lambda.Core" Version="1.*" />
    <PackageReference Include="Amazon.Lambda.Serialization.Json" Version="1.*" />
  </ItemGroup>

</Project>

`
  }
);

const DOTNET_31_CONTENTS = () => (
  {
    'Function.cs':
      `using System;

using Amazon.Lambda.Core;

// Assembly attribute to enable the Lambda function's JSON input to be converted into a .NET class.
[assembly: LambdaSerializer(typeof(Amazon.Lambda.Serialization.SystemTextJson.LambdaJsonSerializer))]

namespace Function
{
    public class Function
    {
        public dynamic FunctionHandler(dynamic eventTrigger)
        {
            Console.WriteLine(eventTrigger);

            return new {};
        }
    }
}
`,
    'Function.csproj':
      `<Project Sdk="Microsoft.NET.Sdk">
  <PropertyGroup>
    <TargetFramework>netcoreapp3.1</TargetFramework>
    <GenerateRuntimeConfigurationFiles>true</GenerateRuntimeConfigurationFiles>
    <AWSProjectType>Lambda</AWSProjectType>
  </PropertyGroup>
  <ItemGroup>
    <PackageReference Include="Amazon.Lambda.Core" Version="1.*" />
    <PackageReference Include="Amazon.Lambda.Serialization.SystemTextJson" Version="1.*" />
  </ItemGroup>
</Project>
`
  }
);

const RUBY_CONTENTS = (logicalId) => (
  {
    'function.rb':
      `require 'json'

def handler(event:, context:)
    { event: JSON.generate(event), context: JSON.generate(context.inspect) }
end
`,
    Gemfile:
      `source 'https://rubygems.org'

gem 'aws-sdk', '~> 3'
`
  }
);

const GO_CONTENTS = (logicalId) => (
  {
    'main.go':
      `package main

import (
\t"context"
\t"encoding/json"
\t"fmt"
\t"github.com/aws/aws-lambda-go/lambda"
)

func Handler(ctx context.Context, event interface{}) (string, error) {
\tbuf, err := json.Marshal(event)
\tif err != nil {
\t\tpanic(err)
\t}
\tfmt.Println(string(buf))
\treturn "OK", nil
}

func main() {
\tlambda.Start(Handler)
}
`,
    'go.mod':
      `module Function

go 1.13
`
  }
);

export default {
  'nodejs14.x': NODEJS_CONTENTS,
  'nodejs14.x (typescript)': TYPESCRIPT_CONTENTS,
  'nodejs12.x': NODEJS_CONTENTS,
  'nodejs12.x (typescript)': TYPESCRIPT_CONTENTS,
  'nodejs10.x': NODEJS_CONTENTS,
  'nodejs10.x (typescript)': TYPESCRIPT_CONTENTS,
  'python3.8': PYTHON_CONTENTS,
  'python3.7': PYTHON_CONTENTS,
  'python3.6': PYTHON_CONTENTS,
  'python2.7': PYTHON_CONTENTS,
  java11: JAVA_CONTENTS,
  java8: JAVA_CONTENTS,
  'dotnetcore2.1': DOTNET_21_CONTENTS,
  'dotnetcore3.1': DOTNET_31_CONTENTS,
  'ruby2.5': RUBY_CONTENTS,
  'ruby2.7': RUBY_CONTENTS,
  'go1.x': GO_CONTENTS,
  provided: () => {},
  'provided.al2': () => {}
};
