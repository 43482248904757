import React from 'react';
import DeploymentPipelines from './DeploymentPipelines';
import DeploymentActivity from './DeploymentActivity';
import style from './Deployments.css';

const Deployments = props => {
  return (
    <div className={style.container}>
      <DeploymentPipelines {...props} />
      <DeploymentActivity {...props} />
    </div>
  );
};

export default Deployments;
