import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from './Button';
import LinkButton from './LinkButton';
import style from './ButtonSet.css';

const ButtonSet = props => {
  const PrimaryButtonElem = props.primaryButton && props.primaryButton.to ? LinkButton : Button;
  const SecondaryButtonElem = props.secondaryButton && props.secondaryButton.to ? LinkButton : Button;
  const TertiaryButtonElem = props.tertiaryButton && props.tertiaryButton.to ? LinkButton : Button;

  return (
    <div className={classnames(
      style.container,
      props.hasBorder && style.hasBorder,
      props.secondaryButton && style.hasSecondaryButton,
      props.tertiaryButton && style.hasTertiaryButton
    )}>
      {props.tertiaryButton &&
        <TertiaryButtonElem
          layoutClassName={style.secondaryButton}
          modifier='secondary'
          {...props.tertiaryButton}
        />
      }
      {props.secondaryButton &&
        <SecondaryButtonElem
          layoutClassName={style.secondaryButton}
          modifier='secondary'
          {...props.secondaryButton}
        />
      }
      {!props.secondaryButton &&
        <span />
      }
      {props.children && props.children}
      {!props.children &&
        <PrimaryButtonElem
          layoutClassName={style.primaryButton}
          {...props.primaryButton}
        />
      }
    </div>
  );
};

ButtonSet.defaultProps = {
  className: style.container,
  hasBorder: true
};

ButtonSet.propTypes = {
  className: PropTypes.string,
  primaryButton: PropTypes.object,
  secondaryButton: PropTypes.object
};

export default ButtonSet;
