import React, { Component, Fragment } from 'react';
import Autosuggest from 'react-autosuggest';
import Spinner from './Spinner';

// This imports styles that are used dynamically at runtime
import style from './AutoSuggest.css'; // eslint-disable-line no-unused-vars

const Suggestion = suggestion => <span>{suggestion}</span>;

class AutoSuggest extends Component {
  constructor (props) {
    super(props);

    this.state = {
      suggestions: props.suggestions,
      value: props.value ? props.value.toString() : '',
      isFocused: false
    };

    this.getSuggestions = this.getSuggestions.bind(this);
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.renderSuggestionsContainer = this.renderSuggestionsContainer.bind(this);
    this.shouldRenderSuggestions = this.shouldRenderSuggestions.bind(this);
  }

  getSuggestions (value) {
    const inputValue = value.trim().toLowerCase();

    return this.props.suggestions.filter(suggestion =>
      suggestion.toLowerCase().includes(inputValue) && suggestion.toLowerCase() !== inputValue
    );
  }

  // Autosuggest will call this function every time you need to update suggestions.
  onSuggestionsFetchRequested ({ value, reason }) {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  }

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested () {
    this.setState({
      suggestions: []
    });
  }

  onSuggestionSelected (event, { method }) {
    if (method === 'enter') {
      event.preventDefault();
    }
  }

  onChange (event, { newValue }) {
    /* The Autosuggest component doesn't always have the name attribute set on
     * the event target. We must set the name on the target of the event to
     * ensure the value is saved correctly. */
    event.target = {
      ...event.target,
      name: this.props.name,
      value: newValue
    };

    this.setState({ value: newValue });

    this.props.onChange(event);
  }

  onFocus () {
    this.setState({ isFocused: true });
  }

  onBlur () {
    this.setState({ isFocused: false });
  }

  renderSuggestionsContainer ({ containerProps, children, query }) {
    return (
      <div {...containerProps}>
        {this.props.isLoading && this.state.isFocused &&
          <div className='react-autosuggest__suggestions-list'>
            <Spinner position='static' />
          </div>
        }
        {!this.props.isLoading &&
          <Fragment>{children}</Fragment>
        }
      </div>
    );
  }

  shouldRenderSuggestions () {
    // Display suggestions when the input has focus
    return true;
  }

  componentDidUpdate (prevProps, prevState) {
    // Force handler to re-parse if suggestions data changes or input receives focus
    if ((prevProps.suggestions !== this.props.suggestions) || (!prevState.isFocused && this.state.isFocused)) {
      this.onSuggestionsFetchRequested({ value: this.state.value, reason: 'input-focused' });
    }
  }

  render () {
    const {
      suggestions,
      value
    } = this.state;

    const {
      placeholder,
      required,
      name,
      pattern,
      readOnly
    } = this.props;

    return (
      <Autosuggest
        suggestions={suggestions}
        getSuggestionValue={suggestion => suggestion}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        onSuggestionSelected={this.onSuggestionSelected}
        renderSuggestion={Suggestion}
        renderSuggestionsContainer={this.renderSuggestionsContainer}
        shouldRenderSuggestions={this.shouldRenderSuggestions}
        inputProps={{ onFocus: this.onFocus, onBlur: this.onBlur, onChange: this.onChange, placeholder, value, required, name, pattern, readOnly }}
      />
    );
  }
}

export default AutoSuggest;
