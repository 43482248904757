import parse from '../utils/parse';
import { types as appTypes } from '../actions/app';
import { types as apiTypes } from '../actions/api';
import { types as canvasTypes } from '../actions/canvas';
import { types as resourceEditorTypes } from '../actions/resourceEditor';
import * as states from '../constants/states';

export const initialState = {
  state: states.NEW,
  resources: parse('{}', 'SAM'),
  logicalIdMapping: {}
};

export default (state = { ...initialState }, action) => {
  let logicalIdMapping = {};

  switch (action.type) {
    case appTypes.DESELECT_STACK:
      return {
        ...initialState
      };

    case apiTypes.GET_STACK_CONTENTS.SUCCESS:
    case appTypes.PARSE_STACK_CONTENTS.TRY:
      return {
        ...initialState,
        state: states.LOADING
      };

    case appTypes.PARSE_STACK_CONTENTS.FAILURE:
      return {
        ...state,
        state: states.FAILED,
        error: action.error.message
      };

    case appTypes.PARSE_STACK_CONTENTS.SUCCESS:
      Object.keys(action.resources.resources).forEach(resource => {
        logicalIdMapping[resource] = resource;
      });

      return {
        ...state,
        state: states.OKAY,
        resources: action.resources,
        logicalIdMapping,
        error: undefined
      };

    case resourceEditorTypes.SAVE_RESOURCE:
      logicalIdMapping = Object.assign({}, state.logicalIdMapping);

      const setting = action.settings.find(setting => setting.settingId === 'LogicalId');

      if (setting && setting.value !== setting.resourceId) {
        logicalIdMapping[setting.value] = logicalIdMapping[setting.resourceId];
        delete logicalIdMapping[setting.resourceId];
      }

      return {
        ...state,
        logicalIdMapping: logicalIdMapping
      };

    case appTypes.PARSE_STACK_TEMPLATE.SUCCESS:
    case appTypes.RESET_STACK_TEMPLATE.SUCCESS:
      return {
        ...state,
        state: states.OKAY,
        resources: action.resources,
        error: undefined
      };

    case canvasTypes.DELETE_SELECTION:
      logicalIdMapping = Object.assign({}, state.logicalIdMapping);
      action.nodes.forEach(node => {
        delete logicalIdMapping[node.id];
      });

      return {
        ...state,
        logicalIdMapping: logicalIdMapping
      };

    case apiTypes.SAVE_NODES.SUCCESS:
      logicalIdMapping = {};

      Object.keys(state.resources.resources).forEach(resource => {
        logicalIdMapping[resource] = resource;
      });

      return {
        ...state,
        logicalIdMapping: logicalIdMapping
      };

    default:
      return state;
  }
};
