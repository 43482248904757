import React, { Component } from 'react';
import * as states from '../../constants/states';
import Form from '../core/Form';
import FormRow from '../core/FormRow';
import Input from '../core/Input';
import ButtonSet from '../core/ButtonSet';

class CreateEnvrionmentSecret extends Component {
  constructor (props) {
    super(props);

    this.state = {
      name: undefined,
      value: undefined,
      description: undefined
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.generatePlaceholder = this.generatePlaceholder.bind(this);
  }

  handleInputChange (event) {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value });
  }

  handleSubmit (event) {
    event.preventDefault();
    this.props.onSubmit(
      this.props.owner,
      this.props.environment,
      this.state.name,
      this.state.value,
      this.state.description
    );
  }

  generatePlaceholder () {
    return `'${this.state.name || ''}' in '${this.props.environment}' environment`;
  }

  generateSecretId () {
    return `/${this.props.environment}/${this.state.name || ''}`;
  }

  render () {
    return (
      <Form onSubmit={this.handleSubmit}>
        <h1>Add an Environment Secret</h1>
        <p>Add a new secret to Environment <strong>{this.props.environment}</strong>.</p>
        <FormRow
          label='Secret Name'
          hint='Alphanumeric characters and /_+=.@-'
        >
          <Input
            name='name'
            required
            pattern='[a-zA-Z0-9/_+=.@-]+'
            onChange={this.handleInputChange}
          />
        </FormRow>
        <FormRow
          label='Full Secret Name'
          hint='Full name including environment namespace'
        >
          <Input
            name='id'
            readOnly
            value={this.generateSecretId()}
          />
        </FormRow>
        <FormRow label='Secret Value'>
          <Input
            name='value'
            required
            onChange={this.handleInputChange}
          />
        </FormRow>
        <FormRow label='Description'>
          <Input
            name='description'
            placeholder={this.generatePlaceholder()}
            onChange={this.handleInputChange}
          />
        </FormRow>
        <ButtonSet
          primaryButton={{
            text: 'Update',
            type: 'submit',
            id: 'environment-secret-create-submit-button',
            isLoading: this.props.state === states.LOADING
          }}
          secondaryButton={{
            text: 'Cancel',
            onClick: this.props.onClickCancel
          }}
        />
      </Form>
    );
  }
}

CreateEnvrionmentSecret.propTypes = {
};

export default CreateEnvrionmentSecret;
