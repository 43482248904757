import * as jwt from 'jsonwebtoken';
import { call, select, putResolve, takeEvery, all, delay } from 'redux-saga/effects';
import { request } from '../utils/api';
import * as states from '../constants/states';
import * as localStorage from '../utils/localStorage';
import actions, { types } from '../actions/auth';

import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
  CognitoRefreshToken
} from 'amazon-cognito-identity-js';

const Pool = new CognitoUserPool(
  JSON.parse(process.env.REACT_APP_USER_POOL_DATA)
);

function * watchToken () {
  const _shouldRefresh = state => {
    let isExpiring =
      state.auth.expiration &&
      Date.now() + 60 * 1000 > state.auth.expiration;
    let isLoading = state.auth.state === states.LOADING;
    let isFailed = state.auth.state === states.FAILED;
    return isExpiring && !isLoading && !isFailed;
  };
  while (true) {
    yield delay(1000);
    let shouldRefresh = yield select(_shouldRefresh);
    if (shouldRefresh) {
      yield putResolve(actions.refreshToken()); // Temporary hack for calling the action
    }
  }
}

function * refreshToken () {
  // We don't actually know when the refresh token expires, so we just try
  // refreshing it, and introspect the error that comes back
  try {
    let cognitoUser = Pool.getCurrentUser();
    const refreshToken = yield select(state => state.auth.refreshToken);
    const response = yield call(() => {
      return new Promise((resolve, reject) => {
        cognitoUser.refreshSession(refreshToken, (error, session) => {
          if (error) {
            localStorage.setItem('Refresh Session Error', error);
            localStorage.setItem('Session Error Attributes', JSON.stringify(error, null, 2));
            reject(error);
          } else {
            resolve(session);
          }
        });
      });
    });
    yield putResolve({
      type: types.REFRESH_TOKEN.SUCCESS,
      data: {
        state: states.OKAY,
        token: response.accessToken.jwtToken,
        refreshToken: response.refreshToken,
        expiration: response.getAccessToken().getExpiration() * 1000
      }
    });
  } catch (error) {
    localStorage.setItem('Refresh Token Failure', error);
    localStorage.setItem('Token Error Attributes', JSON.stringify(error, null, 2));
    let isExpired = state =>
      state.auth.expiration && state.auth.expiration > Date.now();
    if (error.code !== 'NetworkError' && isExpired) {
      yield putResolve({ type: types.SIGN_OUT.REQUEST });
    } else {
      yield putResolve({ type: types.REFRESH_TOKEN.FAILURE });
    }
  }
}

function * forgotPassword (action) {
  try {
    const userData = {
      Username: (action.email || '').toLowerCase(),
      Pool: Pool
    };

    const cognitoUser = new CognitoUser(userData);
    const response = yield call(() => {
      return new Promise((resolve, reject) => {
        cognitoUser.forgotPassword({
          onSuccess: res => resolve(res),
          onFailure: err => reject(err)
        });
      });
    });
    yield putResolve({
      type: types.FORGOT_PASSWORD.SUCCESS,
      response
    });
  } catch (error) {
    yield putResolve({ type: types.FORGOT_PASSWORD.FAILURE, error });
  }
}

function * resetPassword (action) {
  try {
    const userData = {
      Username: (action.email || '').toLowerCase(),
      Pool: Pool
    };

    const cognitoUser = new CognitoUser(userData);
    const response = yield call(() => {
      return new Promise((resolve, reject) => {
        cognitoUser.confirmPassword(
          action.verificationCode,
          action.newPassword,
          {
            onSuccess: res => resolve(res),
            onFailure: err => reject(err)
          }
        );
      });
    });
    yield putResolve({
      type: types.RESET_PASSWORD.SUCCESS,
      response
    });
  } catch (error) {
    yield putResolve({ type: types.RESET_PASSWORD.FAILURE, error });
  }
}

function * changePassword (action) {
  try {
    const response = yield call(() => {
      return new Promise((resolve, reject) => {
        const user = Pool.getCurrentUser();
        // Must call getSession to give user access to current
        // session tokens necessary for changePassword call.
        user.getSession(function (err, session) {
          if (err) {
            reject(err);
          }
        });

        user.changePassword(
          action.oldPassword,
          action.newPassword,
          (err, result) => {
            err ? reject(err) : resolve(result);
          }
        );
      });
    });
    yield putResolve({
      type: types.CHANGE_PASSWORD.SUCCESS,
      response
    });
  } catch (error) {
    yield putResolve({ type: types.CHANGE_PASSWORD.FAILURE, error });
  }
}

function * signIn ({ type, data }) {
  try {
    const email = (data.email || '').toLowerCase();
    const authDetails = new AuthenticationDetails({
      Username: email,
      Password: data.password
    });
    const cognitoUser = new CognitoUser({ Username: email, Pool });
    // Promisified to deal with crappy Cognito API
    const response = yield call(() => {
      return new Promise((resolve, reject) => {
        cognitoUser.authenticateUser(authDetails, {
          onSuccess: res => resolve(res),
          onFailure: err => reject(err)
        });
      });
    });
    yield putResolve({
      type: types.SIGN_IN.SUCCESS,
      data: {
        state: states.OKAY,
        token: response.accessToken.jwtToken,
        refreshToken: response.refreshToken,
        expiration: response.getAccessToken().getExpiration() * 1000
      }
    });
  } catch (error) {
    yield putResolve({ type: types.SIGN_IN.FAILURE, error });
  }
}

function * signOut () {
  const user = Pool.getCurrentUser();
  if (user) {
    yield call(() => {
      return new Promise((resolve, reject) => {
        user.getSession(function (err, session) {
          if (err) {
            console.log(`Error: Unable to get user session: ${err.message}`);
            resolve();
          }
          resolve(session);
        });
      });
    });

    yield call(() => {
      return new Promise((resolve, reject) => {
        user.globalSignOut({
          // If multiple calls to signOut are in flight, subsequent call to globalSignOut resonds with a mix
          // of 'Request aborted' or 'NotAuthorizedException'
          onFailure: (err) => {
            if (err.code === 'Request aborted' ||
              (err.code === 'NotAuthorizedException' && err.message === 'Access Token has been revoked')) {
              resolve();
            } else {
              // Log the unexpected error but continue to sign-out the user
              console.log(`Error: ${err.code} - ${err.message}`);
              resolve();
            }
          },
          onSuccess: (msg) => {
            resolve(msg);
          }
        });
      });
    });

    yield putResolve({ type: types.SIGN_OUT.SUCCESS });
  }
}

function * getToken ({ type, code }) {
  let app = process.env.REACT_APP_ENV === 'prod' ? 'stackery' : `${process.env.REACT_APP_ENV}-stackery`;
  let region = (process.env.REACT_APP_ENV === 'prod2' || process.env.REACT_APP_ENV === 'stg3') ? 'us-east-1' : 'us-west-2';
  let clientID = JSON.parse(process.env.REACT_APP_USER_POOL_DATA).ClientId;
  let redirect = encodeURIComponent(`${window.location.origin}/sign-in`);
  let url = `https://${app}.auth.${region}.amazoncognito.com/oauth2/token`;

  // Even though we are using query params to pass values to cognito
  // we still need to provide the 'content-type' header as form-urlencoded
  // otherwise cognito will respond with an error 405
  let headers = {
    'Content-Type': 'application/x-www-form-urlencoded'
  };

  let queryParams = [
    `grant_type=authorization_code`,
    `client_id=${clientID}`,
    `redirect_uri=${redirect}`,
    `code=${code}`
  ].join('&');
  url = `${url}?${queryParams}`;

  let result = yield request(types.GET_TOKEN, 'POST', url, {}, {}, undefined, headers);

  // Need to create a cognito user and forcibly call refreshSession on it to store the refresh token
  let email = jwt.decode(result.access_token).username;
  const cognitoUser = new CognitoUser({ Username: email, Pool });

  let refreshToken = new CognitoRefreshToken({RefreshToken: result.refresh_token});

  yield call(() => {
    return new Promise((resolve, reject) => {
      cognitoUser.refreshSession(refreshToken, (error, session) => {
        if (error) {
          reject(error);
        } else {
          resolve(session);
        }
      });
    });
  });
}

function * getCognitoUser () {
  try {
    const response = yield call(() => {
      return new Promise((resolve, reject) => {
        const cognitoUser = Pool.getCurrentUser();
        let cognitoUserAttributes = {};

        cognitoUser.getSession(function (err, session) {
          if (err) {
            return new Error('Session is invalid');
          }

          cognitoUser.getUserAttributes(function (err, attributes) {
            if (err) {
              reject(err);
            } else {
              for (var attr in attributes) {
                // return key value pairs for all attributes ('Name', 'Value )
                cognitoUserAttributes[attributes[attr].Name] = attributes[attr].Value;
              }
              resolve(cognitoUserAttributes);
            }
          });
        });
      });
    });
    yield putResolve({ type: types.GET_COGNITO_USER.SUCCESS, cognitoUserAttributes: response });
  } catch (err) {
    yield putResolve({ type: types.GET_COGNITO_USER.FAILURE, err });
  }
}

function * updateCognitoUser ({ name, value }) {
  try {
    yield call(() => {
      return new Promise((resolve, reject) => {
        const cognitoUser = Pool.getCurrentUser();
        if (cognitoUser != null) {
          cognitoUser.getSession(function (err, session) {
            if (err) {
              return new Error('Session is invalid');
            }

            const updatedAttribute = [{
              Name: name,
              Value: value
            }];

            cognitoUser.updateAttributes(updatedAttribute, function (err, result) {
              if (err) {
                reject(err);
              }
              resolve(result);
            });
          });
        }
      });
    });
    yield putResolve({ type: types.UPDATE_COGNITO_USER.SUCCESS });
  } catch (err) {
    yield putResolve({ type: types.UPDATE_COGNITO_USER.FAILURE });
  }
}

export default function * cognito () {
  yield all([
    takeEvery(types.SIGN_IN.REQUEST, signIn),
    takeEvery(types.SIGN_OUT.REQUEST, signOut),
    takeEvery(types.REFRESH_TOKEN.REQUEST, refreshToken),
    takeEvery(types.FORGOT_PASSWORD.REQUEST, forgotPassword),
    takeEvery(types.RESET_PASSWORD.REQUEST, resetPassword),
    takeEvery(types.CHANGE_PASSWORD.REQUEST, changePassword),
    takeEvery(types.GET_TOKEN.REQUEST, getToken),
    takeEvery(types.GET_COGNITO_USER.REQUEST, getCognitoUser),
    takeEvery(types.UPDATE_COGNITO_USER.REQUEST, updateCognitoUser),
    watchToken()
  ]);
}
