import React from 'react';
import PipelineStepLink from './PipelineStepLink';
import StackLink from './StackLink';

const PipelineStepEnabledEvent = (props) => {
  const stack = props.context === 'stacks' ? <em>{props.stackName}</em> : <StackLink {...props} />;
  const pipelineStep = props.context === 'stacks' ? <em>{props.pipelineStep}</em> : <PipelineStepLink {...props} />;

  return <span>{pipelineStep} enabled on {stack} by {props.userEmail}</span>;
};

export default PipelineStepEnabledEvent;
