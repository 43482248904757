import React from 'react';
import PropTypes from 'prop-types';
import ButtonSet from '../core/ButtonSet';
import LinkAwsButtonContainer from '../linkAwsButton/LinkAwsButtonContainer';
import style from './LinkAws.css';

const LinkAws = props => {
  return (
    <div className={style.container}>
      <h2>Link an AWS account and deploy in an orderly and professional way</h2>
      <p><em>After logging in to the AWS Console, approve the limited IAM role permissions, then click "Create Stack". It may take a few minutes for the process to complete.</em></p>
      <LinkAwsButtonContainer />
      <ButtonSet
        primaryButton={{
          text: 'Next Step',
          to: '/setup/get-tools',
          isDisabled: !props.hasLinkedAws
        }}
        secondaryButton={{
          text: 'Skip Step',
          to: '/setup/get-tools'
        }}
      />
    </div>
  );
};

LinkAws.propTypes = {
  hasLinkedAws: PropTypes.bool
};

export default LinkAws;
