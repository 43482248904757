import React from 'react';
import classnames from 'classnames';
import MuiIcon from '@material-ui/core/Icon';
import PropTypes from 'prop-types';
import style from './Icon.css';

// TODO: Update the `name` prop to `svg` so it reflects the Icon rendering type below. This will affect every instance of <Icon />

export default function Icon (props) {
  if (props.font) {
    return (
      <MuiIcon classes={{ root: classnames(props.className, style[props.size], style[props.level]) }}>{props.font}</MuiIcon>
    );
  }

  let svgComponent;
  const file = props.name && props.name.indexOf('.svg') !== -1 ? props.name : `${props.name}.svg`;

  try {
    svgComponent = require(`../../icons/${file}`).default;
  } catch (err) {
    return null;
  }

  return React.createElement(svgComponent, props);
}

Icon.defaultProps = {
  size: 'medium',
  level: null
};

Icon.propTypes = {
  name: PropTypes.string,
  font: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  level: PropTypes.oneOf(['info', 'success', 'warning', 'error'])
};
