import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { push, replace } from 'connected-react-router';
import apiActions from '../../actions/api';
import appActions from '../../actions/app';
import cloudwatchActions from '../../actions/cloudwatch';
import * as states from '../../constants/states';
import * as modes from '../../constants/modes';
import getQueryValue from '../../utils/getQueryValue';
import StackView from './StackView';

const mapStateToProps = () => {
  return createSelector(
    (appState) => appState.stack,
    (appState) => appState.deployments,
    (appState) => appState.deploymentNodes,
    (appState) => appState.nodeDeploymentInfo,
    (appState) => appState.environments,
    (appState) => appState.aws,
    (appState) => appState.cloudwatch,
    (stack, deployments, deploymentNodes, nodeDeploymentInfo, environments, aws, cloudwatch) => {
      return {
        stack,
        deployments,
        deploymentNodes,
        aws,
        cloudwatch,
        showNodeDeploymentInfo: !!nodeDeploymentInfo.node,
        isCurrentDeploymentSelected: !!deployments.currentDeployment && deployments.currentDeployment.id === deployments.selectedDeploymentId,
        isDeploymentsLoading: deployments.state === states.LOADING,
        isDeploymentsLoaded: deployments.state === states.OKAY,
        isEnvironmentsLoaded: environments.state === states.OKAY,
        hasEnvironments: environments.data.length > 0
      };
    }
  );
};

const mapDispatchToProps = {
  push,
  replace,
  setStackMode: appActions.setStackMode,
  setEphemeralId: appActions.setEphemeralId,
  selectStackEnvironment: appActions.selectStackEnvironment,
  selectStackResource: appActions.selectStackResource,
  deselectStackResource: appActions.deselectStackResource,
  selectDeployment: appActions.selectDeployment,
  deselectDeployment: appActions.deselectDeployment,
  getDeploymentState: apiActions.getDeploymentState,
  viewNewDeployment: appActions.viewNewDeployment,
  getMetrics: cloudwatchActions.getMetrics
};

class StackViewContainer extends Component {
  componentDidMount () {
    const {
      environment,
      resourceId,
      deploymentId
    } = this.props.match.params;

    const ephemeralId = getQueryValue(this.props.location.search, 'ephemeralId');

    this.props.setStackMode(modes.READ_ONLY);
    this.expireNewDeployment();

    if (environment) {
      this.props.selectStackEnvironment(environment);
    }

    if (deploymentId) {
      this.props.selectDeployment(deploymentId);
    }

    if (ephemeralId) {
      this.props.setEphemeralId(ephemeralId);
    }

    if (resourceId) {
      this.props.selectStackResource(decodeURIComponent(resourceId));
    }
  }

  componentWillUnmount () {
    this.props.deselectDeployment();
    this.props.setStackMode();
  }

  componentDidUpdate (prevProps) {
    const {
      stack,
      deployments,
      deploymentNodes,
      aws,
      cloudwatch,
      match,
      isCurrentDeploymentSelected,
      location
    } = this.props;

    const {
      stack: prevStack,
      deployments: prevDeployments,
      match: prevMatch
    } = prevProps;

    // Select currentDeployment as default
    if (!deployments.selectedDeploymentId && deployments.currentDeployment) {
      this.props.selectDeployment(deployments.currentDeployment.id);
    }

    // Select new deployment as selected deployment if current deployment is selected and stale
    if (
      prevDeployments.currentDeployment &&
      deployments.currentDeployment &&
      prevDeployments.currentDeployment.id === prevDeployments.selectedDeploymentId &&
      prevDeployments.currentDeployment.id !== deployments.currentDeployment.id
    ) {
      this.props.selectDeployment(deployments.currentDeployment.id);
    }

    if (match.params.resourceId && prevMatch.params.resourceId !== match.params.resourceId) {
      this.props.selectStackResource(decodeURIComponent(match.params.resourceId));
    }

    // Update route if selected environment is not present in route
    if (stack.environment && stack.environment !== prevStack.environment && !prevMatch.params.environment) {
      this.props.push(`${match.url}/${stack.environment}`);
    }

    // Update route if stack.environment is present in route and is changed
    if (stack.environment && stack.environment !== prevStack.environment && prevMatch.params.environment) {
      this.props.push(match.url.replace(`/view/${prevStack.environment}`, `/view/${stack.environment}`));
    }

    // Update route and preserve query param on initial load
    if (!prevStack.environment && stack.environment && prevMatch.params.environment) {
      this.props.push(match.url.replace(`/view/${prevStack.environment}`, `/view/${stack.environment}`) + location.search);
    }

    // Append stackResourceId to route if nextStack.selectedResourceId is defined
    if (!prevMatch.params.resourceId && !match.params.resourceId && stack.selectedResourceId) {
      this.props.push(`${match.url}/${encodeURIComponent(stack.selectedResourceId)}` + location.search);
    }

    // Unset stack.selectedResourceId if switching environments
    if (stack.selectedResourceId && prevStack.environment && stack.environment !== prevStack.environment) {
      this.props.deselectStackResource();
    }

    // Remove stackResourceId from route if nextStack.selectedResourceId is not defined
    if (prevStack.selectedResourceId && !stack.selectedResourceId && prevMatch.params.resourceId) {
      this.props.push(prevMatch.url.replace(`/${prevStack.selectedResourceId}`, '') + location.search);
    }

    // Unset stack.selectedResourceId if not defined in route params
    if (prevStack.selectedResourceId && stack.selectedResourceId && !match.params.resourceId) {
      this.props.deselectStackResource();
    }

    // Unset stack.selectedResourceId if selectedDeploymentId changes
    if (prevDeployments.selectedDeploymentId && deployments.selectedDeploymentId && prevDeployments.selectedDeploymentId !== deployments.selectedDeploymentId && stack.selectedResourceId) {
      this.props.deselectStackResource();
    }

    // Fetch deployment nodes
    if (
      deploymentNodes.selectedDeploymentId &&
      deploymentNodes.selectedDeploymentState === states.NEW
    ) {
      this.props.getDeploymentState(stack.owner, stack.name, stack.environment, deploymentNodes.selectedDeploymentId, deployments.selectedEphemeralId);
    }

    // Fetch health metrics once, after deployment is selected and deploymentNodes are fetched
    if (
      isCurrentDeploymentSelected &&
      deploymentNodes.selectedDeploymentState === states.OKAY &&
      cloudwatch.state === states.NEW &&
      aws.state === states.OKAY
    ) {
      this.props.getMetrics();
    }
  }

  expireNewDeployment () {
    if (this.props.deployments.hasNewDeployment) {
      this.props.viewNewDeployment();
    }
  }

  render () {
    return (
      <StackView {...this.props} />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StackViewContainer);
