import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactDOMServer from 'react-dom/server';
import TimeAgo from 'react-timeago';
import classnames from 'classnames';
import moment from 'moment';
import appActions from '../../actions/app';
import apiActions from '../../actions/api';
import { NEW, OKAY } from '../../constants/states';
import * as deploymentStates from '../../constants/deploymentStates';
import makeGitLink from '../../utils/makeGitLink';
import Tooltip from '../core/Tooltip';
import Icon from '../core/Icon';
import GitLink from '../core/GitLink';
import DeploymentDetails from './DeploymentDetails';
import style from './Deployment.css';

class Deployment extends Component {
  constructor (props) {
    super(props);

    this.state = {
      isMouseOver: false,
      showDetails: false
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleDetailsClick = this.handleDetailsClick.bind(this);
  }

  componentDidUpdate (prevProps) {
    const deployment = this.props.deployment;
    const prevDeployment = prevProps.deployment;

    if (
      deployment &&
      prevDeployment &&
      deployment.status !== prevDeployment.status &&
      !this.expandable()
    ) {
      this.setState({ showDetails: false });
    }
  }

  expandable () {
    const status = this.props.deployment.status;

    return this.props.stack.format === 'stackery' && (
      status === deploymentStates.CHANGE_SET_CREATED ||
      status === deploymentStates.DEPLOYING ||
      status === deploymentStates.DEPLOYED
    );
  }

  handleClick () {
    this.props.selectDeployment(this.props.deployment.id);

    if (!this.props.selected || !this.expandable()) return;

    this.toggleDeploymentDetails();
  }

  handleDetailsClick () {
    if (!this.props.selected) {
      this.toggleDeploymentDetails();
    }
  }

  toggleDeploymentDetails () {
    const {
      stack,
      deployment
    } = this.props;

    if (!this.props.changes) {
      this.props.getChanges(
        stack.owner,
        stack.name,
        stack.environment,
        deployment.id
      );
    }

    this.setState({showDetails: !this.state.showDetails});
  }

  handleMouseEnter () {
    this.setState({isMouseOver: true});
  }

  handleMouseLeave () {
    this.setState({isMouseOver: false});
  }

  getTimestamp () {
    return this.props.deployment.finishedTimestamp || this.props.deployment.timestamp;
  }

  renderStatusIcon () {
    let icon;
    let className;
    let message;
    let time = this.renderTimeAgo();

    switch (this.props.deployment.status) {
      case deploymentStates.QUEUED:
        icon = 'spinner';
        className = classnames(style.icon, style.iconSpinner);
        break;

      case deploymentStates.PREPARING:
        icon = 'spinner';
        className = classnames(style.icon, style.iconSpinner);
        break;

      case deploymentStates.CREATING_CHANGE_SET:
        icon = 'spinner';
        className = classnames(style.icon, style.iconSpinner);
        break;

      case deploymentStates.UNDEPLOYED:
      case deploymentStates.CHANGE_SET_CREATED:
        icon = 'checkmark-circle-outline';
        className = classnames(style.icon, style.iconCheckmark, style.neutral);
        break;

      case deploymentStates.DEPLOYING:
        icon = 'spinner';
        className = classnames(style.icon, style.iconSpinner);
        message = 'Deploying';
        break;

      case deploymentStates.DEPLOYED:
        icon = 'checkmark-circle-outline';
        className = classnames(style.icon, style.iconCheckmark, style.ok);
        break;

      default:
        icon = 'error-outline';
        className = classnames(style.icon, style.iconError);
        break;
    }

    message = message || ReactDOMServer.renderToStaticMarkup(time);

    return (
      <Tooltip message={message} html>
        <Icon className={className} name={icon} />
      </Tooltip>
    );
  }

  getStatus () {
    let status;
    switch (this.props.deployment.status) {
      case deploymentStates.QUEUED:
        status = 'Launched';
        break;

      case deploymentStates.PREPARING:
        status = 'Preparing';
        break;

      case deploymentStates.CREATING_CHANGE_SET:
        status = 'Creating Changes';
        break;

      case deploymentStates.CHANGE_SET_CREATED:
        status = 'Prepared';
        break;

      case deploymentStates.DEPLOYING:
        status = 'Deploying';
        break;

      case deploymentStates.DEPLOYED:
      case deploymentStates.UNDEPLOYED:
        status = 'Deployed';
        break;

      case deploymentStates.FAILING:
        status = 'Failed / Rolling Back';
        break;

      default:
        status = 'Failed';
        break;
    }

    return status;
  }

  renderTimeAgo () {
    const timestamp = this.getTimestamp();

    if (!timestamp) return;

    return (
      <TimeAgo date={timestamp} />
    );
  }

  renderDetails () {
    return (
      <div className={style.details}>
        <DeploymentDetails
          changesState={this.props.changesState}
          changes={this.props.changes}
        />
      </div>
    );
  }

  render () {
    const versionLabel = this.props.deployment.version === 'unknown reference' ? 'local' : this.props.deployment.version.slice(0, 7);

    return (
      <div className={classnames(style.container, {[style.isSelected]: this.props.isSelected})}>
        <div
          className={classnames(style.item, {[style.withTimestamp]: this.props.showTimestamp})}
          onClick={this.handleClick}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}>
          {this.renderStatusIcon()}
          <div>
            <p className={style.status}>
              {this.getStatus()}
              <GitLink
                className={style.version}
                gitLink={makeGitLink(this.props.stack, this.props.gitProviders, this.props.deployment.version)}
                label={versionLabel}
              />
            </p>
            <p className={style.timestamp}>{moment(this.getTimestamp()).format('MM/DD/YY hh:mm A')}</p>
          </div>
        </div>
        {this.state.showDetails && this.renderDetails()}
      </div>
    );
  }
}

export default connect(
  (state, props) => {
    const changesState =
      (props.deployment && props.deployment.id in state.changes.deployments)
        ? state.changes.deployments[props.deployment.id].state
        : NEW;

    const changes =
      changesState === OKAY
        ? state.changes.deployments[props.deployment.id].changes
        : undefined;

    return {
      stack: state.stack,
      gitProviders: state.gitProviders,
      changesState,
      changes
    };
  },
  {
    selectDeployment: appActions.selectDeployment,
    getChanges: apiActions.getChanges,
    notifyUser: appActions.notifyUser
  }
)(Deployment);
