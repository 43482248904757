import awsNodeTypeMapping from '../constants/awsNodeTypeMapping';

export default (arn) => {
  let resourceString = arn.split(':')[2];
  let fn = awsNodeTypeMapping[resourceString];
  if (!fn &&
    (arn.split(':')[5]) &&
    (arn.split(':')[5].includes('vpc'))) {
    return awsNodeTypeMapping['vpc'](arn);
  } else if (!fn) {
    return null;
  } else {
    return fn(arn);
  }
};
