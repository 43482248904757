import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';
import highlightSubstring from '../../utils/highlightSubstring';
import Link from '../core/Link';
import ActionHeader from '../core/ActionHeader';
import Spinner from '../core/Spinner';
import ContextMenu from '../core/ContextMenu';
import Table from '../core/Table';
import SearchFilter from '../core/SearchFilter';
import style from './Environments.css';

const Environments = props => {
  const {
    environments,
    searchTerm,
    isEmpty,
    isLoaded,
    onSearch,
    onCreateClick,
    onDeleteClick
  } = props;

  return (
    <div className={style.container}>
      <DocumentTitle title='Stackery Environments' />
      <ActionHeader
        id='environments-header'
        buttonText='Add an Environment'
        buttonIcon='plus'
        onClick={onCreateClick}
      >
        <SearchFilter
          searchName='searchTerm'
          filterName='filterTerm'
          onSearchChange={onSearch}
        />
      </ActionHeader>
      <Table isStriped isEmpty={isEmpty || !environments.length}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Account</th>
            <th>Region</th>
            <th>Pipeline Deployments</th>
            <th />
          </tr>
        </thead>

        {!isLoaded &&
          <tbody>
            <tr>
              <td colSpan='5'><Spinner position='static' /></td>
            </tr>
          </tbody>
        }

        {isLoaded && isEmpty &&
          <tbody>
            <tr>
              <td colSpan='5'>
                No environments have been created. <Link id='environments-list-add-link' onClick={onCreateClick}>Add an environment</Link> to get started.
              </td>
            </tr>
          </tbody>
        }

        {isLoaded && !isEmpty &&
          <tbody>
            {environments.length > 0 && environments.map((environment, i) => (
              <tr key={environment.id}>
                <td>
                  <Link to={`/environments/${environment.owner}/${environment.name}`}>
                    {highlightSubstring(searchTerm, environment.name)}
                  </Link>
                </td>
                <td>
                  {environment.accountAlias &&
                    <Fragment>
                      {highlightSubstring(searchTerm, environment.accountAlias)} <span className={style.muted}>({highlightSubstring(searchTerm, environment.accountId)})</span>
                    </Fragment>
                  }
                  {!environment.accountAlias && highlightSubstring(searchTerm, environment.accountId)}
                </td>
                <td>{highlightSubstring(searchTerm, environment.region)}</td>
                <td>
                  {environment.isDeployerRoleEnabled &&
                    <span>enabled</span>
                  }
                  {!environment.isDeployerRoleEnabled &&
                    <span>disabled</span>
                  }
                </td>
                <td>
                  <ContextMenu items={[
                    {
                      label: 'Delete',
                      onClick: onDeleteClick,
                      params: { ...environment }
                    }
                  ]}
                  />
                </td>
              </tr>
            ))}
            {environments.length === 0 &&
              <tr>
                <td colSpan='5'>No environments found that match <strong>{searchTerm}</strong></td>
              </tr>
            }
          </tbody>
        }
      </Table>
    </div>
  );
};

Environments.propTypes = {
  title: PropTypes.string.isRequired,
  environments: PropTypes.array,
  searchTerm: PropTypes.string,
  isEmpty: PropTypes.bool.isRequired,
  isLoaded: PropTypes.bool.isRequired,
  onSearch: PropTypes.func.isRequired,
  onCreateClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired
};

export default Environments;
