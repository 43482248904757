import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import * as keyCodes from '../../constants/keyCodes';
import Icon from './Icon';
import ContextMenuItem from './ContextMenuItem';
import style from './ContextMenu.css';

class ContextMenu extends Component {
  constructor (props) {
    super(props);

    this.state = {
      isOpen: false,
      isFocused: false,
      selectedItem: null,
      childNodes: []
    };

    this.hide = this.hide.bind(this);
    this.show = this.show.bind(this);
    this.toggle = this.toggle.bind(this);
    this.hideIfOff = this.hideIfOff.bind(this);

    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.focusNextItem = this.focusNextItem.bind(this);
    this.focusPreviousItem = this.focusPreviousItem.bind(this);
  }

  hideIfOff (event) {
    const relatedElement = event.toElement || event.relatedTarget;

    if (!relatedElement || this.container.contains(relatedElement)) { return false; }

    const containerRect = this.container.getBoundingClientRect();
    const mouseX = event.clientX;
    const mouseY = event.clientY;

    if (
      mouseX >= containerRect.right ||
      mouseY >= containerRect.bottom ||
      mouseX <= containerRect.left ||
      mouseY <= containerRect.top ||
      event.type === 'blur'
    ) {
      this.hide(event);
    }
  }

  hide (event) {
    if (!this.state.isOpen && !this.state.isFocused) return;

    this.setState({
      isOpen: false,
      isFocused: false
    });

    this.container.blur();
  }

  show (event, keyCode) {
    event.preventDefault();

    const isBlurred = !this.state.isFocused && document.activeElement === this.container;

    this.setState({
      isOpen: true,
      isFocused: event.type === 'focus'
    }, () => {
      if (isBlurred && keyCode === keyCodes.UP) {
        this.focusPreviousItem();
      } else if (isBlurred && keyCode === keyCodes.DOWN) {
        this.focusNextItem();
      }
    });
  }

  toggle (event) {
    this.state.isOpen && !this.state.isFocused ? this.hide(event) : this.show(event);
  }

  handleKeyDown (event) {
    if (event.keyCode === keyCodes.UP || event.keyCode === keyCodes.DOWN) {
      if (!this.state.isFocused && document.activeElement === this.container) {
        this.show(event, event.keyCode);
      } else if (event.keyCode === keyCodes.UP) {
        this.focusPreviousItem();
      } else if (event.keyCode === keyCodes.DOWN) {
        this.focusNextItem();
      }
    }
  }

  focusNextItem (event) {
    const item = document.activeElement;
    const { childNodes } = this.state;

    if (item !== this.container && item.nextElementSibling) {
      item.nextElementSibling.focus();
    } else {
      childNodes[0].focus();
    }
  }

  focusPreviousItem (event) {
    const item = document.activeElement;
    const { childNodes } = this.state;

    if (item !== this.container && item.previousElementSibling) {
      item.previousElementSibling.focus();
    } else {
      childNodes[childNodes.length - 1].focus();
    }
  }

  componentDidMount () {
    this.setState({
      childNodes: this.container.querySelectorAll('[tabindex]')
    });
  }

  componentDidUpdate (prevProps, prevState) {
    const {
      items,
      isSelector
    } = this.props;

    if (isSelector && items !== prevProps.items) {
      this.setState({
        selectedItem: items.filter(item => item.isActive).map(item => item.label).join()
      });
    }
  }

  render () {
    return (
      <div
        className={classnames(
          style.container,
          style[this.props.align],
          this.props.isOpaque && style.opaque,
          this.props.isAdjoined && style.adjoined,
          this.props.isSelector && style.selector
        )}
        style={this.props.style}
        tabIndex={this.state.isOpen ? '-1' : '0'}
        ref={elem => (this.container = elem)}
        role='toolbar'
        onMouseOut={this.hideIfOff}
        onClick={this.toggle}
        onFocus={this.show}
        onBlur={this.hideIfOff}
        onKeyDown={this.handleKeyDown}
      >
        {!this.props.isSelector &&
          <Icon
            className={classnames(
              style.iconDots,
              this.state.isOpen && style.active
            )}
            name='dots-horizontal'
          />
        }
        {this.props.isSelector &&
          <div className={classnames(style.selector, this.state.isOpen && style.active)}>
            <span ref={elem => this.selectorLabelElem}>{this.state.selectedItem}</span>
            <Icon
              className={classnames(
                style.iconChevron,
                this.state.isOpen && style.active
              )}
              name='chevron-down'
            />
          </div>
        }
        <ul
          className={classnames(
            style.menu,
            this.state.isOpen && style.active
          )}
        >
          {this.props.items.map((item, i) => {
            return item.onClick && (
              <ContextMenuItem
                key={`${item.label}-${i}`}
                hide={this.hide}
                onClick={item.onClick}
                isOpen={this.state.isOpen}
                isActive={item.isActive}
                label={item.label}
                params={item.params}
              />
            );
          })}
        </ul>
      </div>
    );
  }
}

ContextMenu.defaultProps = {
  align: 'right'
};

ContextMenu.propTypes = {
  items: PropTypes.array.isRequired,
  align: PropTypes.string
};

export default ContextMenu;
