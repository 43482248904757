export default [
  {
    namespace: 'AWS/Lambda',
    nodeType: 'function',
    metrics: [
      {
        type: 'Invocations',
        unit: 'Count',
        factors: {
          a: 'Errors'
        }
      },
      {
        type: 'Errors',
        unit: 'Count',
        factors: {
          a: 'Invocations'
        }
      },
      {
        type: 'Duration',
        unit: 'Milliseconds',
        isHidden: true
      },
      {
        type: 'AverageDuration',
        label: 'Duration',
        isDerived: true,
        factors: {
          a: 'Duration',
          b: 'Invocations'
        }
      },
      {
        type: 'ErrorRate',
        label: 'Error Rate',
        isDerived: true,
        isHidden: true,
        factors: {
          a: 'Errors',
          b: 'Invocations'
        }
      }
    ],
    dimensions: [
      {
        name: 'FunctionName',
        getValue: (node, stackEnvironmentId) => {
          return node.deploymentInfo.functionAwsName;
        }
      }
    ]
  },
  {
    namespace: 'AWS/ApiGateway',
    nodeType: 'restApi',
    _metrics: [
      {
        type: '4XXError',
        unit: 'Count',
        label: '4XX Errors',
        factors: {
          a: 'Count'
        }
      },
      {
        type: '5XXError',
        unit: 'Count',
        label: '5XX Errors',
        factors: {
          a: 'Count'
        }
      },
      {
        type: 'Latency',
        unit: 'Milliseconds',
        isHidden: true
      },
      {
        type: 'Count',
        unit: 'Count',
        label: 'Requests'
      },
      {
        type: 'AverageDuration',
        label: 'Latency',
        isDerived: true,
        factors: {
          a: 'Latency',
          b: 'Count'
        }
      }
    ],
    dimensions: [
      {
        name: 'ApiName',
        getValue: (node) => {
          return node.deploymentInfo.restApiName;
        }
      },
      {
        name: 'Method',
        getValue: (node) => {
          return node.method;
        }
      },
      {
        name: 'Resource',
        getValue: (node) => {
          return node.path;
        }
      },
      {
        name: 'Stage',
        getValue: (node) => {
          return '';
        }
      }
    ]
  },
  {
    namespace: 'AWS/RDS',
    nodeType: 'database',
    metrics: [
      {
        type: 'FreeStorageSpace',
        unit: 'Bytes',
        label: 'Available Storage',
        statistics: ['Average']
      },
      {
        type: 'FreeableMemory',
        Unit: 'Bytes',
        label: 'Available RAM',
        statistics: ['Average']
      },
      {
        type: 'CPUUtilization',
        Unit: 'Percent',
        label: 'CPU Utilization',
        statistics: ['Average']
      },
      {
        type: 'DatabaseConnections',
        label: 'Connection',
        statistics: ['Maximum']
      }
    ],
    dimensions: [
      {
        name: 'DBInstanceIdentifier',
        getValue: (node) => {
          return node.deploymentInfo.instanceId;
        }
      }
    ]
  },
  {
    namespace: 'AWS/S3',
    nodeType: 'objectStore',
    metrics: [
      {
        type: 'AllRequests',
        unit: 'Count',
        label: 'Requests',
        factors: {
          a: '4XXError',
          b: '5XXError'
        }
      },
      {
        type: '4XXError',
        unit: 'Count',
        label: '4XX Errors',
        factors: {
          a: 'AllRequests'
        }
      },
      {
        type: '5XXError',
        unit: 'Count',
        label: '5XX Errors',
        factors: {
          a: 'AllRequests'
        }
      }
    ],
    dimensions: [
      {
        name: 'BucketName',
        getValue: (node) => {
          return node.deploymentInfo.id;
        }
      },
      {
        name: 'FilterId',
        getValue: (node) => {
          return 'EntireBucket';
        }
      }
    ]
  },
  {
    namespace: 'AWS/Kinesis',
    nodeType: 'stream',
    metrics: [
      {
        type: 'IncomingRecords',
        unit: 'Count',
        label: 'Enqueued',
        factors: {
          a: 'GetRecords.Records'
        }
      },
      {
        type: 'GetRecords.Records',
        unit: 'Count',
        label: 'Fetches',
        factors: {
          a: 'IncomingRecords'
        }
      }
    ],
    dimensions: [
      {
        name: 'StreamName',
        getValue: (node, stackEnvironmentId) => {
          return `stackery-${stackEnvironmentId}-Stream${node.id}`;
        }
      }
    ]
  },
  {
    namespace: 'AWS/ElastiCache',
    nodeType: 'cacheCluster'
  },
  {
    namespace: 'AWS/DynamoDb',
    nodeType: 'table'
  },
  {
    namespace: 'AWS/SNS',
    nodeType: 'topic'
  },
  {
    namespace: 'AWS/?',
    nodeType: 'bastion'
  },
  {
    namespace: 'AWS/?',
    nodeType: 'dockerCluster'
  },
  {
    namespace: 'AWS/?',
    nodeType: 'dockerService'
  },
  {
    namespace: 'AWS/?',
    nodeType: 'dockerTask'
  },
  {
    namespace: 'AWS/?',
    nodeType: 'loadBalancer'
  },
  {
    namespace: 'AWS/?',
    nodeType: 'errors'
  }
];
