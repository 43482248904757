import { types } from '../actions/api';
import * as states from '../constants/states';

const region = (process.env.REACT_APP_ENV === 'prod2' || process.env.REACT_APP_ENV === 'stg3') ? 'us-east-1' : 'us-west-2';

const uniqueLayerNames = providers => {
  const layerNames = Object.values(providers).reduce((layerNames, provider) => {
    for (const [region, regionLayers] of Object.entries(provider.layersByRegion || {})) {
      for (const layerInfo of regionLayers) {
        layerNames.add(`arn:aws:lambda:${region}:${provider.accountId}:layer:${layerInfo.name}:${layerInfo.latestVersion}`);
      }
    }

    return layerNames;
  }, new Set());

  return Array.from(layerNames);
};

export const initialState = {
  state: states.NEW,
  template: {
    state: states.NEW,
    url: `https://s3-${region}.amazonaws.com/${process.env.REACT_APP_ENV}-stackery/templates/stackery.template`
  },
  cards: {
    state: states.NEW,
    data: [],
    error: null
  },
  plan: {
    state: states.NEW,
    type: null,
    userLimit: null,
    paymentMechanism: null
  },
  providers: [],
  tags: {
    state: states.NEW,
    data: []
  },
  blueprints: {
    state: states.NEW,
    data: []
  },
  layers: [],
  usage: {
    state: states.NEW,
    data: [],
    totalDeployments: 0,
    pageCount: 1
  },
  activeStacks: {
    state: states.NEW,
    data: []
  },
  provider: {
    state: states.NEW,
    externalId: null,
    deploymentStatus: undefined
  },
  settings: {
    state: states.NEW,
    isDeployerRoleFeatureEnabled: false
  }
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case types.GET_TEMPLATE_VERSION.REQUEST:
      return {
        ...state,
        template: {
          ...state.template,
          state: states.LOADING
        }
      };

    case types.SET_CREDIT_CARD.REQUEST:
      return {
        ...state,
        cards: {
          ...state.cards,
          state: states.LOADING,
          error: null
        }
      };

    case types.SET_CREDIT_CARD.FAILURE:
      const message = (typeof action.error.message === 'object') ? action.error.message.message : action.error.message;
      return {
        ...state,
        cards: {
          ...state.cards,
          state: states.FAILED,
          error: message
        }
      };

    case types.GET_CREDIT_CARD.REQUEST:
      return {
        ...state,
        cards: {
          ...state.cards,
          state: states.LOADING
        }
      };

    case types.GET_CREDIT_CARD.SUCCESS:
      return {
        ...state,
        cards: {
          data: action.data.cards,
          state: states.OKAY
        }
      };

    case types.SET_MARKETPLACE.REQUEST:
      return {
        ...state,
        plan: {
          ...state.plan,
          state: states.LOADING
        }
      };

    case types.SET_MARKETPLACE.FAILURE:
      return {
        ...state,
        plan: {
          ...state.plan,
          state: states.FAILED
        }
      };

    case types.GET_PLAN.REQUEST:
      return {
        ...state,
        plan: {
          ...state.plan,
          state: states.LOADING
        }
      };

    case types.SWITCH_PLAN.SUCCESS:
    case types.GET_PLAN.SUCCESS:
      return {
        ...state,
        plan: {
          state: states.OKAY,
          type: action.data.type || action.data.planType, // developer, professional, enterprise for testing
          isExtendedTrial: action.data.period === 30,
          ...action.data
        }
      };

    case types.GET_TEMPLATE_VERSION.SUCCESS:
      return {
        ...state,
        template: {
          ...state.template,
          state: states.OKAY,
          version: action.data.Resources.Stackery.Properties.Version
        }
      };

    case types.GET_ACCOUNT_SETTINGS.REQUEST:
      return {
        ...state,
        settings: {
          ...state.settings,
          state: states.LOADING
        }
      };

    case types.GET_ACCOUNT_SETTINGS.SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          state: states.OKAY,
          isDeployerRoleFeatureEnabled: action.data.features.includes('deployerRoleDefaultOn')
        }
      };

    case types.GET_ACCOUNT_SETTINGS.FAILURE:
      return {
        ...state,
        settings: {
          ...state.settings,
          state: states.FAILED
        }
      };

    case types.GET_ACCOUNT_INFO.REQUEST:
      return {
        ...state,
        state: states.LOADING
      };

    case types.GET_ACCOUNT_INFO.SUCCESS:
      return {
        ...state,
        state: states.OKAY,
        ...action.data.default,
        providers: Object.keys(action.data).filter(key => key !== 'default').map(key => action.data[key]),
        layers: uniqueLayerNames(action.data)
      };

    case types.GET_ACCOUNT_INFO.FAILURE:
      return {
        ...state,
        state: states.FAILED
      };

    case types.GET_ACCOUNT_USAGE.REQUEST:
      return {
        ...state,
        usage: {
          ...state.usage,
          state: states.LOADING
        }
      };

    case types.GET_ACCOUNT_USAGE.SUCCESS:
      return {
        ...state,
        usage: {
          ...state.usage,
          state: states.OKAY,
          data: action.data.events,
          totalDeployments: action.data.deployments,
          pageCount: action.data.pageCount
        }
      };

    case types.GET_ACCOUNT_USAGE.FAILURE:
      return {
        ...state,
        usage: {
          ...state.usage,
          state: states.FAILED
        }
      };

    case types.GET_ACTIVE_STACKS.REQUEST:
      return {
        ...state,
        activeStacks: {
          ...state.activeStacks,
          state: states.LOADING
        }
      };

    case types.GET_ACTIVE_STACKS.SUCCESS:
      return {
        ...state,
        activeStacks: {
          ...state.activeStacks,
          state: states.OKAY,
          data: action.data.stacks,
          currentlyActive: action.data.activeCount
        }
      };

    case types.GET_ACTIVE_STACKS.FAILURE:
      return {
        ...state,
        activeStacks: {
          ...state.activeStacks,
          state: states.FAILED
        }
      };

    case types.GET_STACK_BLUEPRINTS.REQUEST:
      return {
        ...state,
        blueprints: {
          ...state.blueprints,
          state: states.LOADING
        }
      };

    case types.GET_STACK_BLUEPRINTS.SUCCESS:
      return {
        ...state,
        blueprints: {
          state: states.OKAY,
          data: action.data
        }
      };

    case types.SAVE_STACK_BLUEPRINT.SUCCESS:
      return {
        ...state,
        blueprints: {
          ...state.blueprints,
          data: state.blueprints.data.concat({name: action.name, source: action.source})
        }
      };

    case types.DELETE_STACK_BLUEPRINT.SUCCESS:
      return {
        ...state,
        blueprints: {
          ...state.blueprints,
          data: state.blueprints.data.filter(blueprint => blueprint.name !== action.name)
        }
      };

    case types.SAVE_STACK_BLUEPRINT.FAILURE:
    case types.GET_STACK_BLUEPRINTS.FAILURE:
      return {
        ...state,
        tags: {
          ...state.tags,
          state: states.FAILED
        }
      };

    case types.CREATE_ACCOUNT_PROVIDER.REQUEST:
      return {
        ...state,
        provider: {
          ...state.provider,
          state: states.LOADING
        }
      };

    case types.CREATE_ACCOUNT_PROVIDER.SUCCESS:
      return {
        ...state,
        provider: {
          ...state.provider,
          state: states.OKAY,
          externalId: action.data.externalId
        }
      };

    case types.CREATE_ACCOUNT_PROVIDER.FAILURE:
      return {
        ...state,
        provider: {
          ...state.provider,
          state: states.FAILED
        }
      };

    case types.GET_ACCOUNT_PROVIDER_STATUS.REQUEST:
    case types.GET_ACCOUNT_PROVIDER_STATUS.SUCCESS:
      return {
        ...state,
        provider: {
          ...state.provider,
          ...action.data.provider,
          deploymentStatus: action.data.status
        }
      };

    default:
      return state;
  }
};
