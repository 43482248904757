import React from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import style from './Drawer.css';

const Drawer = (props) => {
  const id = shortid.generate();

  return (
    <Accordion
      component={props.element}
      classes={{
        root: props.containerClassname
      }}
      square={!props.isRounded}
    >
      <AccordionSummary
        classes={{
          root: style.summary,
          expanded: style.isOpen,
          focused: style.isFocused
        }}
        expandIcon={<ExpandMoreIcon classes={{ root: style.icon }} />}
        aria-controls={`${id}-content`}
        id={`${id}-header`}
      >
        <span className={props.labelClassname}>{props.label}</span>
      </AccordionSummary>
      <AccordionDetails
        classes={{
          root: props.contentClassname
        }}
      >
        {props.children}
      </AccordionDetails>
    </Accordion>
  );
};

Drawer.defaultProps = {
  element: 'div',
  containerClassname: style.container,
  contentClassname: style.content,
  isRounded: false
};

Drawer.propTypes = {
  element: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]).isRequired
};

export default Drawer;
