import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ButtonSet from '../core/ButtonSet';
import Form from '../core/Form';

class DeleteToken extends Component {
  constructor (props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit (event) {
    event.preventDefault();
    this.props.onSubmit(this.props.token.id);
  }

  render () {
    const {
      token,
      isLoading,
      onClickCancel
    } = this.props;

    return (
      <Form onSubmit={this.handleSubmit}>
        <h1>Delete Token</h1>
        <p>Are you sure you want to delete token <strong>{token.id}</strong>?</p>
        <ButtonSet
          primaryButton={{
            text: 'Delete',
            type: 'submit',
            modifier: 'warning',
            isLoading
          }}
          secondaryButton={{
            text: 'Cancel',
            onClick: onClickCancel
          }}
        />
      </Form>
    );
  }
}

DeleteToken.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClickCancel: PropTypes.func.isRequired
};

export default DeleteToken;
