import { types } from '../actions/api';
import * as states from '../constants/states';

export const initialState = {
  state: states.NEW,
  users: []
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case types.GET_ORGANIZATION_INVITED_USERS.FAILED:
      return {
        ...state,
        error: action.error,
        state: states.FAILED
      };
    case types.GET_ORGANIZATION_INVITED_USERS.REQUEST:
      return {
        ...state,
        state: states.LOADING
      };
    case types.GET_ORGANIZATION_INVITED_USERS.SUCCESS:
      return {
        ...state,
        state: states.OKAY,
        users: action.data.invitedUsers
      };
    case types.CREATE_SAME_ORGANIZATION_INVITE.SUCCESS:
      return {
        ...state,
        users: state.users.concat({ email: action.email, organizationId: action.organizationId })
      };
    case types.REMOVE_INVITED_USER.SUCCESS:
      return {
        ...state,
        users: state.users.filter(user => user.email !== action.email)
      };
    default:
      return state;
  }
};
