import { types as apiTypes } from '../actions/api';
import * as states from '../constants/states';

const sortByCreatedAt = (record1, record2) => {
  return (record1.createdAt > record2.createdAt) ? -1 : ((record1.createdAt < record2.createdAt) ? 1 : 0);
};

export const initialState = {
  state: states.NEW,
  createState: states.NEW,
  data: [],
  token: {}
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case apiTypes.GET_CLI_TOKENS.REQUEST:
      return {
        ...state,
        state: states.LOADING
      };

    case apiTypes.GET_CLI_TOKENS.SUCCESS:
      return {
        ...state,
        state: states.OKAY,
        data: action.data.keys.sort(sortByCreatedAt)
      };

    case apiTypes.GET_CLI_TOKENS.FAILURE:
      return {
        ...state,
        state: states.FAILED,
        error: action.error
      };

    case apiTypes.CREATE_CLI_TOKEN.REQUEST:
      return {
        ...state,
        createState: states.LOADING
      };

    case apiTypes.CREATE_CLI_TOKEN.SUCCESS:
      return {
        ...state,
        createState: states.OKAY,
        token: {
          ...action.data,
          description: action.description
        },
        data: state.data.concat({
          id: action.data.id,
          description: action.description,
          createdAt: new Date().toISOString()
        }).sort(sortByCreatedAt)
      };

    case apiTypes.CREATE_CLI_TOKEN.FAILURE:
      return {
        ...state,
        createState: states.FAILED,
        createError: action.error
      };

    case apiTypes.DELETE_CLI_TOKEN.REQUEST:
      return {
        ...state,
        deleteState: states.LOADING,
        data: state.data.map(token => {
          if (token.id === action.id) {
            return {
              ...token,
              isDeleting: true
            };
          }

          return token;
        })
      };

    case apiTypes.DELETE_CLI_TOKEN.SUCCESS:
      return {
        ...state,
        data: state.data.filter(token => token.id !== action.id)
      };

    case apiTypes.DELETE_CLI_TOKEN.FAILURE:
      return {
        ...state,
        data: state.data.map(token => {
          return {
            ...token,
            isDeleting: false
          };
        })
      };

    default:
      return state;
  }
};
