
/* eslint-disable no-useless-escape */

export const hints = {
  name: 'May contain non-whitespace letters, numbers, underscores, and hyphens',
  logicalId: 'Must begin with a letter and contain only alphanumeric (A-Za-z0-9) characters, must be unique to stack',
  slsS3BucketLogicalId: 'Must begin with "S3Bucket" followed by non-whitespace letters, numbers, hyphens, and periods',
  secretName: 'May contain non-whitespace letters, numbers, hyphens, underscores, / slashes, periods, +, =, and @ characters',
  stackName: 'Must begin with a letter. May contain non-whitespace letters, numbers, and hyphens.',
  nameExtra: 'May contain non-whitespace letters, numbers, hyphens, / slashes, and underscores',
  arn: 'Usually of the format arn:aws:service-type:region:account-id:...',
  number: 'Value must be a number',
  password: 'Must contain at least 8 characters, an uppercase letter, lowercase letter, and a number',
  relativePath: 'Relative path not starting with a slash',
  environmentVariableName: 'May not contain equals (=)'
};

export const patterns = {
  name: '[a-zA-Z0-9_-]*',
  logicalId: '^[A-Za-z][A-Za-z0-9]*',
  slsS3BucketLogicalId: '^S3Bucket[A-Za-z0-9-.]+$',
  secretName: '[a-zA-Z0-9/_+=.@-]+',
  stackName: '[a-zA-Z][a-zA-Z0-9_-]*',
  nameExtra: '[a-zA-Z0-9-_/-]*',
  arn: 'arn:aws:[-a-zA-Z0-9:\/._+]+',
  number: '[0-9]*',
  password: '^((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])).{8,}$',
  relativePath: '^[^/].*',
  dockerImage: '^(?:.+/)?(?:[^:]+)(?::.+)?$',
  environmentVariableName: '[^=]+'
};

export default {
  [patterns.name]: hints.name,
  [patterns.logicalId]: hints.logicalId,
  [patterns.slsS3BucketLogicalId]: hints.slsS3BucketLogicalId,
  [patterns.stackName]: hints.stackName,
  [patterns.nameExtra]: hints.nameExtra,
  [patterns.arn]: hints.arn,
  [patterns.number]: hints.number,
  [patterns.password]: hints.password,
  [patterns.environmentVariableName]: hints.environmentVariableName,
  '^.{6,}$': 'Must contain at least 6 characters',
  cloneUrl: 'https://remote-repository-clone-url.git'
};
