import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import apiActions from '../../actions/api';
import appActions from '../../actions/app';
import * as states from '../../constants/states';
import selectDefaultItem from '../../utils/selectDefaultItem';
import EnvironmentSelector from './EnvironmentSelector';

const mapStateToProps = () => {
  return createSelector(
    (appState) => appState.stack,
    (appState) => appState.environments,
    (stack, environments) => {
      return {
        stack,
        environments
      };
    }
  );
};

const mapDispatchToProps = {
  selectStackEnvironment: appActions.selectStackEnvironment,
  getEnvironments: apiActions.getEnvironments,
  deselectDeployment: appActions.deselectDeployment
};

class EnvironmentSelectorContainer extends Component {
  constructor (props) {
    super(props);

    this.selectDefaultEnvironment = this.selectDefaultEnvironment.bind(this);
    this.handleEnvironmentChange = this.handleEnvironmentChange.bind(this);
  }

  componentDidMount () {
    const {
      stack,
      environments
    } = this.props;

    if (environments.state === states.NEW) {
      this.props.getEnvironments();
    }

    // Select and set environment if not defined in stack
    if (
      !stack.environment &&
      environments.state === states.OKAY &&
      environments.data.length > 0
    ) {
      this.selectDefaultEnvironment(environments.data);
    }
  }

  componentDidUpdate (prevProps) {
    const {
      stack,
      environments
    } = this.props;

    // Select and set environment if not defined in stack
    if (
      !stack.environment &&
      environments.state === states.OKAY &&
      environments.data.length > 0
    ) {
      this.selectDefaultEnvironment(environments.data);
    }
  }

  handleEnvironmentChange (event) {
    const environment = event.target.querySelector('option:checked').value;

    this.props.deselectDeployment();
    this.props.selectStackEnvironment(environment);
  }

  selectDefaultEnvironment (list) {
    const selectedEnvironment = selectDefaultItem(list, 'name', 'development');
    this.props.selectStackEnvironment(selectedEnvironment);
  }

  render () {
    return (
      <EnvironmentSelector
        {...this.props}
        onEnvironmentChange={this.handleEnvironmentChange}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EnvironmentSelectorContainer);
