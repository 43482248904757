import fetch from 'isomorphic-fetch';
import { call, select, putResolve, put, take, race } from 'redux-saga/effects';
import { types as authTypes } from '../actions/auth';
import errorCodes from '../constants/errorCodes';
import extractError from './extractError';

// set REACT_APP_ENV to prod in .env.local to connect local frontend to production api
const BASE_URL =
  (process.env.REACT_APP_ENV === 'prod' && 'https://api.stackery.io') ||
  (process.env.REACT_APP_ENV === 'local' && 'http://localhost:3000') ||
 `https://${process.env.REACT_APP_ENV}-api.stackery.io`;

const HEADERS = { 'Content-Type': 'application/json' };

export class ServerError extends Error {
  constructor (message) {
    super(message);
    this.name = 'ServerError';
    this.message = message;
  }
}

export class UnauthorizedError extends Error {
  constructor (message) {
    super(message);

    this.name = 'UnauthorizedError';
    this.message = typeof message === 'string' ? { message } : message;

    let error = extractError(this.message);
    this.message.message = error.message;
    this.message.code = error.code;
  }
}

export class NotFoundError extends Error {
  constructor (message) {
    super(message);
    this.name = 'NotFoundError';
    this.message = typeof message === 'string' ? { message } : message;

    let error = extractError(this.message);
    this.message.message = error.message;
    this.message.code = error.code;
  }
}

export function dispatchRequest (
  endpoint,
  headers = {},
  method = 'GET',
  data = undefined
) {
  let body;
  if (method !== 'GET') {
    if (headers['Content-Type'] === 'text/plain') {
      body = data;
    } else {
      body = JSON.stringify(data);
    }
  }

  headers = (method === 'POST' || method === 'PUT') ? Object.assign({}, HEADERS, headers) : headers;
  let url = endpoint.startsWith('http') ? endpoint : BASE_URL + endpoint;

  return fetch(url, {
    headers,
    method,
    body
  }).then(response => {
    const contentType = response.headers.get('Content-Type');

    let responsePromise;

    /* Fetch polyfill doesn't like calling response.json() when body is empty:
     * https://github.com/github/fetch/issues/364 */
    if (response.status === 204) {
      responsePromise = Promise.resolve(null);
    } else if (contentType && contentType.startsWith('application/json')) {
      responsePromise = response.json();
    } else {
      responsePromise = response.text();
    }

    return responsePromise.then(body => {
      if (response.status === 401) {
        throw new UnauthorizedError(body);
      }

      if (response.status === 404) {
        throw new NotFoundError(body);
      }

      if (response.status < 200 || response.status >= 300) {
        throw new ServerError(body);
      }

      return { response: body, headers: Object.fromEntries(response.headers.entries()) };
    });
  });
}

export function * request (type, method, url, body, params, isValid, headers) {
  try {
    const authHeaders = yield select(state => ({
      Authorization: state.auth.token,
      'X-Impersonate': state.auth.impersonate
    }));

    if (!authHeaders['X-Impersonate']) {
      delete authHeaders['X-Impersonate'];
    }

    const isDemoMode = yield select(state => state.currentUser.isDemoMode);

    // TEMP FIX: requesting template from AWS throws 400 if Authorization header is present
    if (url.startsWith('http')) {
      delete authHeaders['Authorization'];
    } else if (isDemoMode && method === 'GET') {
      url += url.match(/\?/) ? '&demo' : '?demo';
    }

    headers = {
      ...authHeaders,
      ...(headers || {})
    };
    const data = yield call(dispatchRequest, url, headers, method, body);

    const currentState = yield select(state => state);
    if (!isValid || isValid(currentState)) {
      if (type) {
        yield putResolve({ type: type.SUCCESS, data: data.response, headers: data.headers, ...params });
      }
      return data.response;
    }
  } catch (error) {
    if (error.name === 'UnauthorizedError') {
      if (error.message.code === errorCodes.STACKERY_AUTH_FAILURE) {
        yield putResolve({ type: authTypes.SIGN_OUT.REQUEST });
      }
    } else if (!isHandledError(error)) {
      console.error(error.stack);
    }

    if (type) {
      yield putResolve({ type: type.FAILURE, error, ...params });
    }

    if (!(error instanceof Error)) {
      return new Error(error);
    }
    return error;
  }
}

const isHandledError = error => {
  if (!(typeof error === 'object')) {
    return false;
  }

  switch (error.name) {
    case 'ServerError':
    case 'UnauthorizedError':
    case 'NotFoundError':
      return true;

    default:
      return false;
  }
};

// Dispatches sagas by first in first out...
// ...then dispatches masterType saga after success or failure of any step in the sequence
export function * sequenceSagas (masterType, steps) {
  let allParams = {};

  for (let i = 0; i < steps.length; i++) {
    const { type, params } = steps[i];

    yield put({ type: type.REQUEST, ...params });

    const { success, failure } = yield race({
      success: take(type.SUCCESS),
      failure: take(type.FAILURE)
    });

    allParams = { ...allParams, ...params };

    if (success && i === steps.length - 1) {
      yield putResolve({ type: masterType.SUCCESS, ...allParams });
    } else if (failure) {
      yield putResolve({ type: masterType.FAILURE, error: failure, ...allParams });
      break;
    }
    continue;
  }
}
