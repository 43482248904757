
import React from 'react';
import PropTypes from 'prop-types';
import { patterns, hints } from '../../constants/patternHintText';
import FormRow from '../core/FormRow';
import Input from '../core/Input';

const StackName = props => {
  return (
    <FormRow label='Stack name' hint={hints.name}>
      <Input
        name='stackName'
        defaultValue={props.stackName}
        required
        pattern={patterns.name}
        onChange={props.onInputChange}
      />
    </FormRow>
  );
};

StackName.propTypes = {
  stackName: PropTypes.string,
  onInputChange: PropTypes.func.isRequired
};

export default StackName;
