import React, { Component } from 'react';
import deepEqual from 'deep-equal';
import classnames from 'classnames';
import shortid from 'shortid';
import FieldSet from '../core/FieldSet';
import Select from '../core/Select';
import Icon from '../core/Icon';
import style from './ListField.css';

const facetId = facet => Object.keys(facet).sort().reduce((id, property) => `${id}${property}${facet[property]}`, '');

class FacetResourcesField extends Component {
  constructor (props) {
    super(props);

    const choices = props.facets.map(facet => ({ ...facet, id: facetId(facet.Value) }));

    this.state = {
      error: null,
      isShiftKeyPressed: false,
      choices,
      fields: this.setFields(props, choices)
    };

    this.addBlankField = this.addBlankField.bind(this);
    this.handleRemoveField = this.handleRemoveField.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.update = this.update.bind(this);
    this.save = this.save.bind(this);
  }

  setFields (props, choices) {
    const setting = props.resource.settings[props.settingId];

    let fields = (setting || []).map(facet => (
      {
        id: shortid.generate(),
        facet,
        facetId: facetId(facet)
      }
    ));

    // Filter out fields for facets that don't exist anymore
    fields = fields.filter(field => choices.some(choice => choice.id === field.facetId));

    return fields;
  }

  handleRemoveField (id) {
    this.setState({ fields: this.state.fields.filter(field => field.id !== id) });
  }

  handleChange (event) {
    const choiceId = event.currentTarget.value;
    const fieldId = event.currentTarget.name;

    this.update(fieldId, choiceId);
  }

  update (fieldId, choiceId) {
    this.setState({
      fields: this.state.fields.map(field => {
        if (field.id === fieldId) {
          field.facetId = choiceId;
          field.facet = this.state.choices.find(choice => choice.id === choiceId).Value;
        }
        return field;
      })
    }, this.save());
  }

  save () {
    const facets = this.state.fields
      .filter(field => field.facet)
      .map(field => field.facet);

    this.props.onChange(this.props.settingId, facets);
  }

  addBlankField () {
    this.setState({
      fields: this.state.fields.concat({
        id: shortid.generate(),
        facetId: ''
      })
    });
  }

  componentDidMount () {
    if (this.state.choices.length > 0) {
      this.addBlankField();
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const choices = this.props.facets.map(facet => ({ ...facet, id: facetId(facet.Value) }));

    if (!deepEqual(choices, this.state.choices)) {
      this.setState({
        choices,
        fields: this.setFields(this.props, choices)
      });

      return;
    }

    const length = this.state.fields.length;
    if (choices.length - length > 0 && (length === 0 || this.state.fields[length - 1].facetId)) {
      this.addBlankField();
    }

    if (length < prevState.fields.length) {
      this.save();
    }
  }

  render () {
    const {
      settingSchema
    } = this.props;

    const length = this.state.fields.length - 1;

    const availableChoices = this.state.choices.filter(choice =>
      this.state.fields.every(field => field.facetId !== choice.id)
    );

    return (
      <FieldSet
        legend={settingSchema.Label}
        hint={settingSchema.Description}
      >
        {this.state.fields.map((field, i) => (
          <div key={field.id} className={classnames(style.row, style.isSingle)}>
            <Select
              key={field.id}
              name={field.id}
              defaultValue={field.facetId}
              onChange={this.handleChange}
            >
              <option disabled key='label' value=''>Choose {settingSchema.FacetType}</option>
              {field.facetId && <option key={field.facetId} value={field.facetId}>{this.state.choices.find(choice => choice.id === field.facetId).Label}</option>}
              {availableChoices.map(choice => {
                return <option key={choice.id} value={choice.id}>{choice.Label}</option>;
              })}
            </Select>

            <Icon
              name='remove-circle'
              className={classnames(style.iconRemove, availableChoices.length > 0 && i === length && style.iconRemoveDisabled)}
              onClick={() => (availableChoices.length === 0 || i < length) && this.handleRemoveField(field.id)}
            />
          </div>
        ))}
      </FieldSet>
    );
  }
}

export default FacetResourcesField;
