export const shrinkString = (string) => {
  return string.toLowerCase().replace(/ /g, '');
};

export const isStringFound = (string1, string2, shrink = true) => {
  return shrink
    ? shrinkString(string1).indexOf(shrinkString(string2)) > -1
    : string1.indexOf(string2) > -1;
};

export const isAlphanumeric = (string = '') => {
  const pattern = new RegExp(/^[A-Za-z0-9-_\s]+$/g);
  return pattern.test((string || '').trim());
};
