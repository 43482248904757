import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import style from './FieldSet.css';

const FieldSet = (props) => {
  // React treats render conditions themselves as child elements (e.g. {condition && // render it })
  const fields = Array.isArray(props.children) ? props.children.filter(child => child) : props.children;

  return (
    <fieldset
      className={classNames(
        props.className,
        props.extraClassName,
        (props.hint && !props.inlineHint) && style.hasHint,
        props.legend && style.hasLegend,
        props.isShaded && style.isShaded,
        props.hasError && props.errorClassName
      )}
      id={props.id}
    >
      {props.legend && props.hint && props.inlineHint &&
        <legend className={style.legend}>
          <span className={style.inlineLegend}>{props.legend}</span>
          <span className={style.inlineHint}>{props.hint}</span>
        </legend>
      }
      {props.legend && !props.inlineHint &&
        <legend className={style.legend}><span>{props.legend}</span></legend>
      }
      {React.Children.map(fields, child => {
        const {
          isHidden,
          hint,
          label,
          inlineField,
          type,
          isAfterLabel
        } = child.props;

        if (isHidden) {
          return null;
        }

        // Pass props to React components only (`type` is a function). Avoid passing props to React elements / native DOM nodes (`type` is a string)
        const Field = React.cloneElement(child, typeof child.type === 'function' ? { disabled: props.isDisabled, isDisabled: props.isDisabled } : {});

        // Allow pass-through rendering to opt-out of standardized component template (below)
        if (type === 'custom') {
          return Field;
        }

        return (
          <div className={classNames(inlineField && style.inlineField, !inlineField && style.field)}>
            {label &&
              <label>
                {!isAfterLabel && Field }
                <span className={style.label}>{label}</span>
                {isAfterLabel && Field}
              </label>
            }
            {!label && Field}
            {hint &&
              <span className={style.hint}>{hint}</span>
            }
          </div>
        );
      })}
      {props.hint && !props.inlineHint &&
        <span className={style.hint}>{props.hint}</span>
      }
    </fieldset>
  );
};

FieldSet.defaultProps = {
  className: style.container,
  extraClassName: null,
  errorClassName: style.hasError,
  hasError: false,
  id: null,
  inlineHint: false
};

FieldSet.propTypes = {
  className: PropTypes.string,
  extraClassName: PropTypes.string,
  errorClassName: PropTypes.string,
  legend: PropTypes.string,
  hint: PropTypes.string,
  id: PropTypes.string, // changed after 'Failed prop type' console error
  hasError: PropTypes.bool,
  inlineHint: PropTypes.bool
};

export default FieldSet;
