import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import * as states from '../../constants/states';
import * as deploymentStates from '../../constants/deploymentStates';
import highlightSubstring from '../../utils/highlightSubstring';
import makeGitLink from '../../utils/makeGitLink';
import makeChangesetLink from '../../utils/makeChangesetLink';
import makeCodeBuildLogsLink from '../../utils/makeCodeBuildLogsLink';
import UpgradePrompt from '../core/UpgradePrompt';
import Spinner from '../core/Spinner';
import GitLink from '../core/GitLink';
import ButtonSet from '../core/ButtonSet';
import Icon from '../core/Icon';
import Link from '../core/Link';
import Input from '../core/Input';
import Skeleton from '../core/Skeleton';
import EnvironmentDetails from '../environment/EnvironmentDetails';
import Deployment from './Deployment';
import DeployButton from './DeployButton';
import PrepareButton from './PrepareButton';
import style from './Environment.css';

class Environment extends Component {
  constructor (props) {
    super(props);

    this.state = {
      prepareBranch: props.stack.branch || props.stackBranches.defaultBranch,
      isPrepareActive: props.environment.preparingState === states.LOADING,
      isPrepareLoading: props.environment.preparingState === states.LOADING
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handlePrepareClick = this.handlePrepareClick.bind(this);
    this.handleDeployClick = this.handleDeployClick.bind(this);
    this.handleErrorClick = this.handleErrorClick.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
  }

  handleInputChange (event) {
    const {
      value
    } = event.target;

    this.setState({ prepareBranch: value });
  }

  handlePrepareClick () {
    this.props.onPrepareClick(this.state.prepareBranch, this.props.environment.name, () => {
      this.setState({ isPrepareLoading: this.props.hasLinkedAws });
    });
  }

  handleDeployClick () {
    const {
      environment
    } = this.props;

    this.props.onDeployClick(environment.cloudFormationStackName, environment.latestPrepare.changeSet, environment.region);
  }

  handleErrorClick () {
    const {
      latestPrepare,
      latestDeployment,
      ...environment
    } = this.props.environment;

    this.props.showDeploymentErrorsModal({ details: {...environment, ...latestPrepare, stackName: this.props.stack.name, owner: this.props.stack.owner} });
  }

  handleToggle () {
    this.setState({
      isPrepareActive: !this.state.isPrepareActive,
      isPrepareLoading: this.props.environment.preparingState === states.LOADING || this.props.isLinkingAwsAccount
    });
  }

  componentDidUpdate (prevProps) {
    const {
      environment
    } = this.props;

    const {
      environment: prevEnvironment
    } = prevProps;

    // Update button loading state if preparingState changes
    if (prevEnvironment.preparingState !== environment.preparingState) {
      this.setState({
        isPrepareLoading: environment.preparingState === states.LOADING
      });
    }

    // Update state (view toggling) if latestPrepare becomes defined OR if latestPrepare properties change
    if (
      (environment.latestPrepare && !prevEnvironment.latestPrepare) ||
      (environment.latestPrepare && prevEnvironment.latestPrepare &&
        (
          environment.latestPrepare.id !== prevEnvironment.latestPrepare.id ||
          environment.latestPrepare.status !== prevEnvironment.latestPrepare.status
        )
      )
    ) {
      this.setState({
        isPrepareActive: false,
        isPrepareLoading: false
      });
    }
  }

  render () {
    const {
      stack,
      searchTerm,
      stackBranches,
      environment,
      gitProviders,
      isLinkingAwsAccount,
      isLoadingStackBranches,
      isPreparing,
      isGitless
    } = this.props;

    const {
      prepareBranch,
      isPrepareActive,
      isPrepareLoading
    } = this.state;

    const preparedDeployment = environment.latestPrepare;
    const currentDeployment = environment.latestDeployment;
    const deploymentStatus = preparedDeployment ? preparedDeployment.status : null;
    const isFailed = deploymentStatus === deploymentStates.FAILED;

    const deployedGitLink = currentDeployment ? makeGitLink(stack, gitProviders, currentDeployment.version) : null;
    const preparedGitLink = preparedDeployment ? makeGitLink(stack, gitProviders, preparedDeployment.version) : null;

    const isPreparedEmpty = !preparedDeployment;
    const isDeployedEmpty = !currentDeployment;

    const isLegacyDeploy = stack.deploymentStategy === 'legacy';

    return (
      <section className={style.container}>
        <div className={style.header}>
          <div className={style.headerColumn}>
            <h2>{highlightSubstring(searchTerm, environment.name)}</h2>
          </div>
          <div className={style.headerColumn}>
            <EnvironmentDetails {...environment} searchTerm={searchTerm} />
          </div>
        </div>

        <div className={style.status}>
          <div className={style.statusColumn}>
            <strong className={style.heading}>Prepared deployment</strong>

            <div className={classnames(style.deployment, style.prepared, isPreparedEmpty && style.isEmpty, (isLoadingStackBranches && isPrepareActive) && style.isEmpty, (isGitless && isPreparedEmpty) && style.isGitless)}>
              {!isLoadingStackBranches && isPrepareActive &&
                <div className={style.selector}>
                  <p>Branch name or revision SHA to deploy:</p>
                  <Input
                    name='prepareBranch'
                    value={prepareBranch}
                    suggestions={stackBranches.data.map(branch => branch.name)}
                    placeholder='branch or SHA'
                    defaultValue={stack.branch || stackBranches.defaultBranch}
                    onChange={this.handleInputChange}
                  />
                </div>
              }

              {isLoadingStackBranches && isPrepareActive &&
                <Spinner position='static' />
              }

              {!isPrepareActive && preparedDeployment &&
                <Fragment>
                  <Deployment
                    deployment={preparedDeployment}
                    showTimestamp
                  />
                  <div className={style.viewLinks}>
                    {isFailed &&
                      <Link onClick={this.handleErrorClick}>Error Message</Link>
                    }
                    {preparedDeployment.codebuildId &&
                      <Link id='stack-prepared-log-link' href={makeCodeBuildLogsLink(preparedDeployment.codebuildId, environment.region)} title='View Deployment Log' target='_blank'>Deploy Log</Link>
                    }
                    <Link id='stack-prepared-cloudformation-link' href={makeChangesetLink(environment.cloudFormationStackName, preparedDeployment.changeSet, environment.region)} title='View changeset in CloudFormation' target='_blank'>CloudFormation Stack</Link>
                    {!isGitless &&
                      <GitLink id='stack-prepared-git-link' gitLink={preparedGitLink} label={`${preparedGitLink.type} Project`} />
                    }
                  </div>
                </Fragment>
              }

              {isPreparedEmpty && !isPrepareActive &&
                <div className={style.emptyMessage}>
                  {isGitless &&
                    <UpgradePrompt
                      commands={['deploy']}
                      stackName={stack.name}
                      environmentName={environment.name}
                    />
                  }
                  {!isGitless &&
                    <button className={classnames(style.toggleButton, 'isDisabled')} tabIndex='0' onClick={this.handleToggle}>
                      <span><Icon name='plus' className={style.iconPlus} />Prepare new deployment</span>
                    </button>
                  }
                </div>
              }
            </div>

            {!isPrepareActive && !isGitless && preparedDeployment &&
              <ButtonSet
                hasBorder={false}
                secondaryButton={isGitless ? undefined : {
                  text: 'Re-Prepare',
                  isDisabled: isPreparing && isLegacyDeploy,
                  onClick: this.handleToggle
                }}
              >
                <DeployButton
                  id='stack-deploy-button'
                  stackName={environment.stackName}
                  deploymentStatus={deploymentStatus}
                  onDeployClick={this.handleDeployClick}
                  isMultiAccount={environment.isMultiAccount}
                />
              </ButtonSet>
            }

            {isPrepareActive &&
              <ButtonSet
                hasBorder={false}
                secondaryButton={{
                  onClick: this.handleToggle,
                  text: 'Cancel'
                }}
              >
                <PrepareButton
                  id='stack-prepare-button'
                  deploymentStatus={deploymentStatus}
                  isLinkingAwsAccount={isLinkingAwsAccount}
                  isLoading={isPrepareLoading}
                  onPrepareClick={this.handlePrepareClick}
                  isLegacyDeploy={isLegacyDeploy}
                />
              </ButtonSet>
            }
          </div>
          <div className={style.statusColumn}>
            <strong className={style.heading}>Current deployment</strong>
            <div className={classnames(style.deployment, style.deployed, isDeployedEmpty && style.isEmpty)}>
              {currentDeployment &&
                <Fragment>
                  <Deployment
                    deployment={currentDeployment}
                    showTimestamp
                  />
                  <div className={style.viewLinks}>
                    <Link to={`/stacks/${stack.owner}/${stack.name}/view/${environment.name}`} title='View Stackery Dashboard'>View</Link>
                    {currentDeployment.codebuildId &&
                      <Link id='stack-deployed-log-link' href={makeCodeBuildLogsLink(currentDeployment.codebuildId, environment.region)} title='View Deployment Log' target='_blank'>Deploy Log</Link>
                    }
                    <Link id='stack-deployed-cloudformation-link' href={makeChangesetLink(environment.cloudFormationStackName, currentDeployment.changeSet, environment.region)} title='View changeset in CloudFormation' target='_blank'>CloudFormation Stack</Link>
                    {!isGitless &&
                      <GitLink id='stack-deployed-git-link' gitLink={deployedGitLink} label={`View in ${deployedGitLink.type}`} />
                    }
                  </div>
                </Fragment>
              }

              {isDeployedEmpty &&
                <div className={style.emptyMessage}>
                  <p>Nothing deployed on {environment && <strong>{environment.name}</strong>} {!environment && <Skeleton width={70} animation='wave' />}</p>
                </div>
              }
            </div>
          </div>
        </div>
      </section>
    );
  }
}

Environment.propTypes = {
  environment: PropTypes.object,
  onPrepareClick: PropTypes.func,
  onDeployClick: PropTypes.func
};

export default Environment;
