import React from 'react';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import Icon from '../core/Icon';
import style from './StackNav.css';

const StackNav = ({ stack, match, queryParams = '' }) => {
  const branchParam = stack.branch ? `/${encodeURIComponent(stack.branch)}` : '';
  const environmentParam = stack.environment ? `/${stack.environment}` : '';

  return (
    <nav className={style.container}>
      <NavLink
        className={style.navLink}
        activeClassName={style.activeNavLink}
        to={`${match.url}/overview`}
      >
        <Icon name='chart' className={classnames(style.icon, style.iconOverview)} />
        <span>Overview</span>
      </NavLink>
      <NavLink
        className={style.navLink}
        activeClassName={style.activeNavLink}
        isActive={(_match, location) => location.pathname.includes(`${stack.name}/edit`)}
        to={`${match.url}/edit${branchParam}${queryParams}`}
      >
        <Icon name='edit' className={classnames(style.icon, style.iconEdit)} />
        <span>Edit</span>
      </NavLink>
      <NavLink
        className={style.navLink}
        activeClassName={style.activeNavLink}
        to={`${match.url}/deploy`}
      >
        <Icon name='send' className={classnames(style.icon, style.iconSend)} />
        <span>Deploy</span>
      </NavLink>
      <NavLink
        className={style.navLink}
        activeClassName={style.activeNavLink}
        to={`${match.url}/view${environmentParam}`}
      >
        <Icon name='eye' className={classnames(style.icon, style.iconEye)} />
        <span>View</span>
      </NavLink>
      <NavLink
        className={style.navLink}
        activeClassName={style.activeNavLink}
        to={`${match.url}/settings`}
      >
        <Icon name='settings' className={classnames(style.icon, style.iconSettings)} />
        <span>Settings</span>
      </NavLink>
    </nav>
  );
};

StackNav.propTypes = {
};

export default StackNav;
