import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { patterns } from '../../constants/patternHintText';
import ActionHeader from '../core/ActionHeader';
import ButtonSet from '../core/ButtonSet';
import Form from '../core/Form';
import Icon from '../core/Icon';
import Input from '../core/Input';
import Table from '../core/Table';
import Spinner from '../core/Spinner';
import style from './Settings.css';

const BuildEnvironment = props => {
  const {
    fields,
    isEmpty,
    isLoading,
    isSaving,
    onDeleteClick,
    onSaveClick,
    onInputChange
  } = props;

  return (
    <Form onSubmit={onSaveClick}>
      <ActionHeader headerText='Build Environment Variables' />
      <Table id='stackery-build-environment' isStriped isEmpty={isEmpty}>
        <thead>
          <tr>
            <th>Key</th>
            <th>Value</th>
          </tr>
        </thead>

        {isLoading &&
          <tbody>
            <tr>
              <td colSpan='2'><Spinner position='static' /></td>
            </tr>
          </tbody>
        }

        {!isLoading &&
          <tbody>
            {fields.map((entry, i) => (
              <tr key={i}>
                <td>
                  <Input
                    id={`${i}-key`}
                    onChange={onInputChange}
                    pattern={patterns.environmentVariableName}
                    placeholder='Key'
                    value={entry.key}
                  />
                </td>
                <td>
                  <Input
                    id={`${i}-value`}
                    onChange={onInputChange}
                    placeholder='Value'
                    value={entry.value}
                  />
                </td>
                <td>
                  <Icon
                    name='remove-circle'
                    className={classnames(style.iconRemove, i === (fields.length - 1) && style.iconRemoveDisabled)}
                    onClick={() => i < (fields.length - 1) && onDeleteClick(i)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        }
      </Table>
      <ButtonSet
        hasBorder={false}
        primaryButton={{
          text: 'Save',
          type: 'submit',
          isLoading: isSaving
        }}
      />
    </Form>
  );
};

BuildEnvironment.propTypes = {
  fields: PropTypes.array.isRequired,
  isEmpty: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired
};

export default BuildEnvironment;
