import React from 'react';
import PropTypes from 'prop-types';
import * as verificationStates from '../../constants/verificationStates';
import makeCodeBuildLogsLink from '../../utils/makeCodeBuildLogsLink';
import Icon from '../core/Icon';
import Link from '../core/Link';
import style from './JobItemTasksList.css';

const JobItemTasksList = props => {
  const {
    stack,
    stageIndex,
    deployed
  } = props;

  return (
    <ul className={style.container}>
      <li className={style.item}>
        <Icon className={style.iconCheckmark} name='checkmark' />
        <span>Deployment</span>
        {deployed.codebuildId &&
          <Link href={makeCodeBuildLogsLink(deployed.codebuildId, props.stages[stageIndex].environment.region, 'StackeryPipelineRunner')} text='View logs' target='_blank' />
        }
      </li>

      {/* Show step progress */}
      {deployed.steps.map((step, stepIndex) => (
        <li className={style.item} key={`stage${stageIndex}-${stack.stackId}-step${stepIndex}`}>
          {step.status === verificationStates.STARTED &&
            <Icon className={style.iconSpinner} name='spinner' />
          }
          {step.status === verificationStates.SUCCEEDED &&
            <Icon className={style.iconCheckmark} name='checkmark' />
          }
          {step.status === verificationStates.FAILED &&
            <Icon className={style.iconClear} name='clear' />
          }
          <span>{step.name}</span>
          {step.logs &&
            <Link href={step.logs} text='View logs' target='_blank' rel='noopener noreferrer' />
          }
        </li>
      ))}
    </ul>
  );
};

JobItemTasksList.propTypes = {
  stack: PropTypes.object.isRequired,
  stageIndex: PropTypes.number.isRequired,
  deployed: PropTypes.object.isRequired
};

export default JobItemTasksList;
