import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import formatTimestamp from '../../utils/formatTimestamp';
import Tooltip from '../core/Tooltip';
import style from './TimeAgo.css';

const UPDATE_INTERVAL = 1000;

const formatRelative = (timestamp) => moment(timestamp).fromNow();
const formatAbsolute = (timestamp) => formatTimestamp(timestamp);

const TimeAgo = (props) => {
  const [relativeTime, setRelativeTime] = React.useState();

  React.useEffect(() => {
    const interval = setInterval(() => {
      setRelativeTime(formatRelative(props.timestamp));
    }, UPDATE_INTERVAL);

    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    setRelativeTime(formatRelative(props.timestamp));
  }, [props.timestamp]);

  return (
    <Tooltip className={style.container} message={formatAbsolute(props.timestamp)} place='top' isInline>
      <span className={style.container}>{props.text && props.text} {relativeTime}</span>
    </Tooltip>
  );
};

TimeAgo.propTypes = {
  timestamp: PropTypes.string.isRequired,
  text: PropTypes.string
};

export default TimeAgo;
