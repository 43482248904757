import React from 'react';
import EnvironmentLink from './EnvironmentLink';
import StackLink from './StackLink';

const PipelineEnvironmentUpdated = (props) => {
  const stack = props.context === 'stacks' ? <em>{props.stackName}</em> : <StackLink {...props} />;
  const environment = props.context === 'environment' ? <em>{props.environmentName}</em> : <EnvironmentLink {...props} />;

  return <span>Verification environment {environment} set on {stack} by {props.userEmail}</span>;
};

export default PipelineEnvironmentUpdated;
