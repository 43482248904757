import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import MuiIconButton from '@material-ui/core/IconButton';
import Icon from './Icon';
import style from './IconButton.css';

const IconButton = props => {
  return (
    <MuiIconButton
      ref={props.anchorRef}
      classes={{ root: classnames(style.container, style[props.size], props.className) }}
      focusVisibleClassName={style.focused}
      aria-label={props.label}
      color='inherit'
      disableRipple={props.isDisabled}
      onClick={props.onClick}
    >
      <Icon className={style.icon} name={props.iconSvg} font={props.iconFont} />
    </MuiIconButton>
  );
};

IconButton.defaultProps = {
  size: 'medium'
};

IconButton.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  label: PropTypes.string,
  anchorRef: PropTypes.object, // Required when used by ButtonMenu
  isOpen: PropTypes.bool, // Required when used by ButtonMenu
  onClick: PropTypes.func
};

export default IconButton;
