import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import hints from '../../constants/patternHintText';
import makeGitLink from '../../utils/makeGitLink';
import getStackFormat from '../../utils/getStackFormat';
import * as states from '../../constants/states';
import appActions from '../../actions/app';
import GitLink from '../core/GitLink';
import Form from '../core/Form';
import FormRow from '../core/FormRow';
import Input from '../core/Input';
import ButtonSet from '../core/ButtonSet';
import Commit from '../stackRepoForm/Commit';

const mapStateToProps = () => {
  return createSelector(
    (appState) => appState.stack,
    (appState) => appState.editorNodes,
    (appState) => appState.templateEditor,
    (appState) => appState.gitProviders,
    (stack, editorNodes, templateEditor, gitProviders) => {
      return {
        gitProviders,
        // TODO: return templateEditor and use properties with namespace
        validTemplate: templateEditor.validTemplate,
        originalTemplate: templateEditor.remoteTemplate,
        updatedTemplate: templateEditor.rawTemplate,
        isTemplateValid: templateEditor.isTemplateValid,
        remoteChangeOccurred: stack.git.status && stack.git.status === states.DIVERGED,
        isLegacy: stack.format === 'stackery'
      };
    }
  );
};

const mapDispatchToProps = {
  resetStackTemplate: appActions.resetStackTemplate,
  parseStackTemplate: appActions.parseStackTemplate
};

class CommitStack extends Component {
  constructor (props) {
    super(props);

    this.state = {
      message: props.message,
      branch: null
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount () {
    if (!this.props.isLegacy) {
      this.props.parseStackTemplate(this.props.updatedTemplate, getStackFormat(this.props.updatedTemplate));
    }
  }

  componentDidUpdate (prevProps) {
    const {
      originalTemplate,
      updatedTemplate,
      isTemplateValid
    } = this.props;

    const {
      isTemplateValid: prevIsTemplateValid
    } = prevProps;

    if (isTemplateValid && !prevIsTemplateValid && originalTemplate === updatedTemplate) {
      this.props.hideModal();
    }
  }

  handleInputChange (event) {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value });
  }

  handleSubmit (event) {
    event.preventDefault();
    this.props.onSubmit(this.state.message, this.state.branch);
  }

  render () {
    const {
      stack,
      gitProviders,
      message,
      remoteChangeOccurred,
      validTemplate,
      originalTemplate,
      updatedTemplate,
      isTemplateValid,
      isLegacy
    } = this.props;

    // Compare with state.stack and static stack (by value) so messaging persists while commit progresses
    // OR displays if change occurs while modal is open
    const hasRemoteChanges = remoteChangeOccurred || (stack.git.status && stack.git.status === states.DIVERGED);
    const hasLocalChanges = originalTemplate !== updatedTemplate;

    return (
      <Form onSubmit={this.handleSubmit}>
        <h1>{hasLocalChanges && <Fragment>Review and </Fragment>}Commit Changes</h1>
        {hasRemoteChanges &&
          <Fragment>
            <p>Remote changes detected on {''}
              <GitLink gitLink={makeGitLink(stack, gitProviders, stack.branch)} label={stack.branch} />. Create a new branch to save current changes.
            </p>
            <FormRow label='Branch Name' hint={hints['[a-zA-Z0-9_-]*']}>
              <Input
                name='branch'
                required
                pattern='[a-zA-Z0-9_-]*'
                onChange={this.handleInputChange}
              />
            </FormRow>
          </Fragment>
        }

        <Commit
          stack={stack}
          message={message}
          originalTemplate={originalTemplate}
          currentTemplate={updatedTemplate}
          isTemplateValid={isTemplateValid}
          isLegacy={isLegacy}
          onInputChange={this.handleInputChange}
          onReset={validTemplate ? this.props.resetStackTemplate : undefined}
        />

        <ButtonSet
          primaryButton={{
            text: 'Commit and Push',
            type: 'submit',
            id: 'stack-save-commit-submit-button',
            isLoading: this.props.state === states.LOADING
          }}
          secondaryButton={{
            text: 'Cancel',
            onClick: this.props.onClickCancel
          }}
        />
      </Form>
    );
  }
}

CommitStack.propTypes = {
};

export default connect(mapStateToProps, mapDispatchToProps)(CommitStack);
