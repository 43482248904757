import React, { Fragment } from 'react';
import Link from '../core/Link';

const LinkAwsHelper = props => {
  return (
    <Fragment>
      <h2>Stackery Environments</h2>
      <p>Once you've linked one or more AWS accounts with Stackery, you can create environments in those AWS accounts and deploy stacks into the environments.
      Each Stackery environment is tied to a linked AWS account and provides namespacing, environment config, secrets management, and a pattern for deploying to multiple AWS accounts and regions.</p>
      <p>AWS accounts don’t have a native way to manage namespacing and secrets across your dev, test, staging, and production environments. Stackery helps you keep moving quickly as your number of stacks and environments grows, with a strong security model built in.</p>
      <p><em>Please note: If AWS IoT Core is not supported in the region in which you are trying to link Stackery, the linking process will fail. Check the <Link href='https://aws.amazon.com/about-aws/global-infrastructure/regional-product-services/' target='_blank' rel='noopener noreferrer'>AWS docs</Link> for the most up-to-date support information.</em></p>
      <h2>Apply best practice patterns for:</h2>
      <ul>
        <li>Deploying to multiple AWS accounts and regions</li>
        <li>Environment-specific configuration and secrets</li>
        <li>Integrating with existing production environments</li>
        <li>Streamlining deployments and rollback automation</li>
      </ul>
      <h2>Read More:</h2>
      <ul>
        <li>
          <Link
            href='https://docs.stackery.io/docs/using-stackery/environments/'
            text='Understanding Stackery environments'
            target='_blank'
            rel='noopener noreferrer'
          />
        </li>
        <li>
          <Link
            href='https://docs.stackery.io/docs/using-stackery/security-permissions-and-controls/'
            text='Security permissions & controls around AWS linking'
            target='_blank'
            rel='noopener noreferrer'
          />
        </li>
        <li>
          <Link
            href='https://docs.stackery.io/docs/api/cli/stackery_deploy/'
            text='Deploy and rollback'
            target='_blank'
            rel='noopener noreferrer'
          />
        </li>
        <li>
          <Link
            href='https://docs.stackery.io/docs/api/cli/stackery/'
            text='Stackery CLI command documentation'
            target='_blank'
            rel='noopener noreferrer'
          />
        </li>
      </ul>
    </Fragment>
  );
};

export default LinkAwsHelper;
