import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from './Icon';
import style from './Spinner.css';

const Spinner = props => {
  const display = props.loaded ? 'none' : props.display;

  return (
    <span
      className={classnames(style.container, props.className)}
      style={{ display, backgroundColor: props.backgroundColor, position: props.position }}
    >
      <Icon name='spinner' className={style.spinner} height={props.height} />
    </span>
  );
};

Spinner.defaultProps = {
  height: '32px',
  position: 'absolute',
  display: undefined
};

Spinner.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  position: PropTypes.string,
  backgroundColor: PropTypes.string,
  display: PropTypes.any, // can be undefined, bool or display
  loaded: PropTypes.bool
};

export default Spinner;
