import React from 'react';
import PropTypes from 'prop-types';
import ButtonSet from '../core/ButtonSet';
import Form from '../core/Form';

const DeleteDeploymentPipeline = (props) => {
  const {
    deploymentPipeline,
    isSaving
  } = props;

  const name = deploymentPipeline.name === 'My pipeline' ? 'Deployment Pipeline' : deploymentPipeline.name;

  return (
    <Form onSubmit={props.onSubmit}>
      <h1>Delete Deployment Pipeline</h1>
      <p>Are you sure you want to delete <strong>{name}</strong>?</p>
      <p>Deleting a deployment pipeline means it will no longer be accessible from your Stackery dashboard. Deployed CloudFormation stacks will remain in the stage environments and in your AWS account.</p>
      <ButtonSet
        primaryButton={{
          text: 'Delete',
          type: 'submit',
          modifier: 'warning',
          isLoading: isSaving
        }}
        secondaryButton={{
          text: 'Cancel',
          onClick: props.onClickCancel
        }}
      />
    </Form>
  );
};

DeleteDeploymentPipeline.propTypes = {
  isSaving: PropTypes.bool,
  onClickCancel: PropTypes.func,
  onSubmit: PropTypes.func
};

export default DeleteDeploymentPipeline;
