import React from 'react';
import PropTypes from 'prop-types';
import ContextMenu from '../core/ContextMenu';
import Table from '../core/Table';
import Spinner from '../core/Spinner';
import Link from '../core/Link';
import style from './SecretsTable.css';

function buildLink (secret, environment, region) {
  const secretName = encodeURIComponent(`/${environment}/${secret}`);
  return `https://${region}.console.aws.amazon.com/secretsmanager/home?region=${region}#/secret?name=${secretName}`;
}

const SecretsTable = props => {
  const {
    isLoaded,
    isLoading,
    isEmpty,
    isFailed,
    onAddSecretClick
  } = props;

  const {
    environment,
    region,
    owner,
    secrets,
    secretsError
  } = props.environment;

  if (isLoading) {
    return <Spinner position='relative' />;
  }

  if (isFailed) {
    return <div className={style.error}>{secretsError}</div>;
  }

  return (
    <Table isStriped isEmpty={isEmpty}>
      <thead>
        <tr>
          <th>Secret Name</th>
          <th className={style.description}>Description</th>
          <th />
          <th />
        </tr>
      </thead>

      {isLoaded && isEmpty &&
        <tbody>
          <tr>
            <td colSpan='4'>No secrets have been created. <Link id='secrets-list-add-link' onClick={onAddSecretClick}>Add a Secret</Link> to get started.</td>
          </tr>
        </tbody>
      }

      {isLoaded && !isEmpty &&
        <tbody>
          {Object.keys(secrets).map(secret => {
            return (
              <tr key={secret}>
                <td>
                  <span>/{environment}/</span>
                  <span>{secret}</span>
                </td>
                <td className={style.description}>{secrets[secret].description}</td>
                <td className={style.awsLink}>
                  <Link
                    href={buildLink(secret, environment, region)}
                    title='View in AWS console'
                    target='_blank'
                  >
                    {secrets[secret].arn}
                  </Link>
                </td>
                <td>
                  <ContextMenu
                    isAdjoined
                    items={[
                      {
                        label: 'Edit Description',
                        onClick: props.onEditSecretDescriptionClick,
                        params: {
                          environment: environment,
                          owner: owner,
                          secret: secret,
                          description: secrets[secret].description
                        }
                      },
                      {
                        label: 'Set Value',
                        onClick: props.onSetSecretValueClick,
                        params: {
                          environment: environment,
                          owner: owner,
                          secret: secret
                        }
                      },
                      {
                        label: 'Delete',
                        onClick: props.onDeleteSecretClick,
                        params: {
                          environment: environment,
                          owner: owner,
                          secret: secret
                        }
                      }
                    ]}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      }
    </Table>
  );
};

SecretsTable.propTypes = {
  environment: PropTypes.object,
  isLoaded: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isEmpty: PropTypes.bool.isRequired,
  isFailed: PropTypes.bool.isRequired,
  onAddSecretClick: PropTypes.func.isRequired,
  onEditSecretDescriptionClick: PropTypes.func.isRequired,
  onSetSecretValueClick: PropTypes.func.isRequired,
  onDeleteSecretClick: PropTypes.func.isRequired
};

export default SecretsTable;
