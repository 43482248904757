import React from 'react';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';
import CenteredContainer from '../core/CenteredContainer';
import MessageBox from '../core/MessageBox';
import StatusMessage from '../core/StatusMessage';
import Form from '../core/Form';
import FormRow from '../core/FormRow';
import Input from '../core/Input';
import Button from '../core/Button';
import Icon from '../core/Icon';
import style from './ResetPassword.css';

const ResetPassword = props => {
  if (props.didReset) {
    // TODO: notification
    return (
      <CenteredContainer className={style.container}>
        <DocumentTitle title='Stackery Reset Password' />
        <MessageBox>
          <p>Your password has been reset.</p>
          <p>
            <a href='/sign-in'>Sign In</a>
          </p>
        </MessageBox>
      </CenteredContainer>
    );
  }

  return (
    <CenteredContainer className={style.container}>
      <DocumentTitle title='Stackery Reset Password' />
      <Icon name='stackery-logo' className={style.logo} />
      <MessageBox alignCenter>
        <p>An email has been sent to with a password reset verification code.</p>
        <p>Enter the verification code and your new password below.</p>
        {props.error && (
          <StatusMessage type='error' text={props.error.message} />
        )}
      </MessageBox>
      <Form onSubmit={props.onFormSubmit}>
        <FormRow label='Verification Code'>
          <Input
            id='input-passwordResetVerificationToken'
            type='text'
            name='passwordResetVerificationToken'
            onChange={props.onInputChange}
          />
        </FormRow>
        <FormRow label='New Password'>
          <Input
            id='input-newPassword'
            type='password'
            name='newPassword'
            onChange={props.onInputChange}
          />
        </FormRow>
        <Button
          type='submit'
          text='Reset Password'
          isLoading={props.isLoading}
        />
      </Form>
    </CenteredContainer>
  );
};

ResetPassword.propTypes = {
  error: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  didReset: PropTypes.bool.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired
};

export default ResetPassword;
