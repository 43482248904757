export default (data) => {
  data.sort((a, b) => {
      if (a.events.length > 0 && b.events.length === 0) {
        return -1;
      } else if (b.events.length > 0 && a.events.length === 0) {
        return 1;
      } else {
        return new Date(b.events[0].timestamp) - new Date(a.events[0].timestamp);
      }
    });

  return data;
}