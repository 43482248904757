import React, { Component } from 'react';
import NodeType from './NodeType';

class NodeTypeDragPreview extends Component {
  render () {
    const node = this.props.node;
    const nodeType = document.getElementById(`node-palette-item-${node.type}`);
    const nodeTypeRect = nodeType.getBoundingClientRect();

    const styles = {
      opacity: 0.9,
      minWidth: '200px',
      width: nodeTypeRect.width,
      height: nodeTypeRect.height
    };

    return (
      <div style={styles}>
        <NodeType typeName={node.type} type={this.props.type} showHint={false} format={this.props.format} />
      </div>
    );
  }
}

export default NodeTypeDragPreview;
