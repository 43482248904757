import React, { Component } from 'react';
import * as states from '../../constants/states';
import hints from '../../constants/patternHintText';
import determineReferenceDefaults from '../../utils/determineReferenceDefaults';
import Form from '../core/Form';
import FormRow from '../core/FormRow';
import Input from '../core/Input';
import ButtonSet from '../core/ButtonSet';

class CreateReferenceNode extends Component {
  constructor (props) {
    super(props);

    this.state = {
      reference: null,
      hasError: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange (event) {
    const arn = event.currentTarget.value;
    const reference = determineReferenceDefaults(arn);
    if (!reference) {
      this.setState({
        hasError: true
      });
    } else {
      this.setState({
        reference,
        hasError: false
      });
    }
  }

  handleSubmit (event) {
    event.preventDefault();
    if (this.state.hasError) return;

    const canvas = document.getElementById('canvasContainer');
    const canvasRect = canvas.getBoundingClientRect();

    const x = this.props.position ? this.props.position.x : canvasRect.left + canvas.scrollLeft;
    const y = this.props.position ? this.props.position.y : canvasRect.top + canvas.scrollTop;

    this.props.onSubmit(this.state.reference, { x, y });
  }

  render () {
    // TODO: add error color to span
    let arnPattern = 'arn:aws:[-a-zA-Z0-9:\/._+]+'; // eslint-disable-line no-useless-escape
    return (
      <Form onSubmit={this.handleSubmit}>
        <h1>Add a Reference</h1>
        <p>Please enter the AWS ARN of the resource that you would like to reference.</p>
        {this.state.hasError && <span key='error'>Unable to parse node type from the given ARN. Is this a referenceable resource type?</span>}
        <FormRow label='Resource Arn:' hint={hints[arnPattern]}>
          <Input
            required
            pattern={arnPattern}
            onChange={this.handleInputChange}
            hasError={this.state.hasError}
          />
        </FormRow>
        <ButtonSet
          primaryButton={{
            text: 'Import',
            type: 'submit',
            isLoading: this.props.state === states.LOADING
          }}
          secondaryButton={{
            text: 'Cancel',
            onClick: this.props.onClickCancel
          }}
        />
      </Form>
    );
  }
}

CreateReferenceNode.propTypes = {
};

export default CreateReferenceNode;
