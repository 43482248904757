import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import apiActions from '../../actions/api';
import appActions from '../../actions/app';
import * as apiStates from '../../constants/states';
import LinkAwsButton from './LinkAwsButton';

const mapStateToProps = (state) => {
  return createSelector(
    (appState) => appState.account,
    (appState) => appState.currentUser,
    (account, currentUser) => {
      return {
        account,
        provider: account.provider,
        email: currentUser.email
      };
    }
  );
};

const mapDispatchToProps = {
  createAccountProvider: apiActions.createAccountProvider,
  monitorNewAccountProviderStatus: apiActions.monitorNewAccountProviderStatus,
  notifyUser: appActions.notifyUser
};

const getTemplateUrl = (useBeta) => {
  const prefix = process.env.REACT_APP_ENV === 'local' && process.env.REACT_APP_DEV ? process.env.REACT_APP_DEV : process.env.REACT_APP_ENV;
  const stackeryVersion = useBeta ? 'stackery-beta' : 'stackery';

  return `https://s3-us-west-2.amazonaws.com/${prefix}-stackery/templates/${stackeryVersion}.template`;
};

class LinkAwsButtonContainer extends Component {
  constructor (props) {
    super(props);

    this.state = {
      isDeployerRoleEnabled: false,
      consoleUrl: null
    };

    this.handleDeployerRoleChange = this.handleDeployerRoleChange.bind(this);
    this.setConsoleUrl = this.setConsoleUrl.bind(this);
  }

  handleDeployerRoleChange (event) {
    this.setState({ isDeployerRoleEnabled: event.currentTarget.checked });
  }

  componentDidMount () {
    // Reset account provider in case the person wants to link another AWS account
    this.props.createAccountProvider();
    this.setConsoleUrl();
  }

  componentDidUpdate (prevProps, prevState) {
    const {
      provider
    } = this.props;

    if ((prevProps.provider.state === apiStates.LOADING && provider.state === apiStates.OKAY) ||
        (prevState.isDeployerRoleEnabled !== this.state.isDeployerRoleEnabled)) {
      this.setConsoleUrl();
    }
  }

  setConsoleUrl () {
    const templateUrl = getTemplateUrl();
    const deployerRoleParam = this.state.isDeployerRoleEnabled ? '&param_CreateStackeryDeployerRole=true' : '';

    this.setState({ consoleUrl: `https://console.aws.amazon.com/cloudformation/home#/stacks/create/review?templateURL=${encodeURIComponent(templateUrl)}&stackName=stackery&param_StackeryExternalId=${this.props.provider.externalId}${deployerRoleParam}` });
  }

  render () {
    return (
      <LinkAwsButton
        {...this.props}
        {...this.state}
        onDeployerRoleChange={this.handleDeployerRoleChange}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LinkAwsButtonContainer);
