import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '../core/Icon';
import style from './SetupNav.css';

const SetupNav = props => {
  const {
    hasVerifiedEmail,
    hasLinkedAws
  } = props;

  return (
    <ol className={style.container}>
      <li className={classnames(style.navItem, style.isActive, hasVerifiedEmail && style.isComplete)}>
        <NavLink
          className={style.navLink}
          activeClassName={style.activeNavLink}
          to={'/setup/verify-email'}
        >
          {hasVerifiedEmail &&
            <Icon name='checkmark' className={style.iconCheckmark} />
          }
          <span>Verify Email</span>
          <Icon name='triangle' className={classnames(style.iconTriangle)} />
        </NavLink>
      </li>
      <li className={classnames(style.navItem, !hasVerifiedEmail && style.isDisabled, hasLinkedAws && style.isComplete)}>
        <NavLink
          className={style.navLink}
          activeClassName={style.activeNavLink}
          to={'/setup/link-aws'}
        >
          {hasLinkedAws &&
            <Icon name='checkmark' className={style.iconCheckmark} />
          }
          <span>Link AWS</span>
          <Icon name='triangle' className={classnames(style.iconTriangle)} />
        </NavLink>
      </li>
      <li className={classnames(style.navItem, !hasVerifiedEmail && style.isDisabled)}>
        <NavLink
          className={style.navLink}
          activeClassName={style.activeNavLink}
          to={'/setup/get-tools'}
        >
          <span>Get Tools</span>
        </NavLink>
      </li>
    </ol>
  );
};

SetupNav.propTypes = {
  hasVerifiedEmail: PropTypes.bool,
  hasLinkedAws: PropTypes.bool
};

SetupNav.defaultProps = {
  hasVerifiedEmail: false,
  hasLinkedAws: false
};

export default SetupNav;
