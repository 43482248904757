import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import * as states from '../../constants/states';
import apiActions from '../../actions/api';
import appActions from '../../actions/app';
import { onTransition } from '../../utils/transition';
import Environments from './Environments';

const mapStateToProps = () => {
  return createSelector(
    (appState) => appState.environments,
    (appState) => appState.newEnvironment,
    (appState) => appState.account,
    (appState) => appState.currentUser,
    (environments, newEnvironment, account, currentUser) => {
      return {
        environments,
        newEnvironment,
        account,
        hasLinkedAws: currentUser.hasLinkedAws
      };
    }
  );
};

const mapDispatchToProps = {
  push,
  getEnvironments: apiActions.getEnvironments,
  getEnvironmentParameters: apiActions.getEnvironmentParameters,
  deleteEnvironment: apiActions.deleteEnvironment,
  showCreateEnvironmentModal: appActions.showCreateEnvironmentModal,
  showDeleteEnvironmentModal: appActions.showDeleteEnvironmentModal,
  notifyUser: appActions.notifyUser,
  mountComponent: appActions.mountComponent,
  unmountComponent: appActions.unmountComponent
};

class EnvironmentsContainer extends Component {
  constructor (props) {
    super(props);

    this.state = {
      filteredEnvironments: props.environments.data,
      searchTerm: null
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.filterEnvironments = this.filterEnvironments.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleCreateClick = this.handleCreateClick.bind(this);
  }

  componentDidMount () {
    this.props.getEnvironments();
    this.props.mountComponent('Environments');
  }

  componentWillUnmount () {
    this.props.unmountComponent('Environments');
  }

  componentDidUpdate (prevProps, prevState) {
    const {
      environments,
      newEnvironment
    } = this.props;

    const {
      environments: prevEnvironments
    } = prevProps;

    const {
      searchTerm
    } = this.state;

    const {
      searchTerm: prevSearchTerm
    } = prevState;

    onTransition(prevProps, this.props, 'newEnvironment', {
      [states.OKAY]: {
        push: `/environments/${this.props.newEnvironment.owner}/${newEnvironment.name}`
      },
      [states.FAILED]: {
        notify: {
          message: `Failed to create environment: ${newEnvironment.errorMessage}`,
          level: 'error'
        }
      }
    });

    if (searchTerm !== prevSearchTerm) {
      this.filterEnvironments();
    }

    if (environments.data !== prevEnvironments.data && environments.state === states.OKAY) {
      this.setState({ filteredEnvironments: environments.data }, this.filterEnvironments);
    }
  }

  handleDeleteClick (environment) {
    this.props.showDeleteEnvironmentModal({ environment, isPipelineEnvironment: environment.isPipelineEnvironment, onSubmit: this.props.deleteEnvironment });
  }

  handleCreateClick () {
    this.props.showCreateEnvironmentModal();
  }

  filterEnvironments () {
    const searchTerm = this.state.searchTerm ? this.state.searchTerm.toLowerCase() : '';

    this.setState({
      filteredEnvironments: this.props.environments.data.filter((environment) => {
        const matchesName = environment.name.toLowerCase().includes(searchTerm);
        const matchesAccountId = environment.accountId.includes(searchTerm);
        const matchesAccountAlias = environment.accountAlias && environment.accountAlias.toLowerCase().includes(searchTerm);
        const matchesRegion = environment.region.toLowerCase().includes(searchTerm);

        return matchesName || matchesAccountId || matchesAccountAlias || matchesRegion;
      })
    });
  }

  handleSearch (event) {
    this.setState({ searchTerm: event.currentTarget.value });
  }

  render () {
    return (
      <Environments
        title='Stackery Environments'
        environments={this.state.filteredEnvironments}
        searchTerm={this.state.searchTerm}
        isEmpty={this.props.environments.data.length === 0}
        isLoaded={this.props.environments.state === states.OKAY}
        onSearch={this.handleSearch}
        onCreateClick={this.handleCreateClick}
        onDeleteClick={this.handleDeleteClick}
      />
    );
  }
}

EnvironmentsContainer.propTypes = {
  environments: PropTypes.object.isRequired,
  getEnvironments: PropTypes.func.isRequired,
  getEnvironmentParameters: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(EnvironmentsContainer);
