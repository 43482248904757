
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import FormRow from '../core/FormRow';
import Select from '../core/Select';

const RepoOwner = props => {
  const {
    selectedGitProvider
  } = props;

  let ownerLabel = 'Repo Owner';

  if (selectedGitProvider === 'codecommit') {
    ownerLabel = 'Account Id';
  } else if (selectedGitProvider === 'gitlab') {
    ownerLabel = 'Namespace';
  }

  return (
    <Fragment>
      <FormRow label={ownerLabel}>
        <Select
          name='owner'
          value={props.owner}
          required
          onChange={props.onInputChange}
        >
          <option disabled value={''}>Choose {ownerLabel}</option>
          {props.destinations.map((dest) => {
            return <option key={dest.id} value={dest.id}>{dest.name}</option>;
          })}
        </Select>
      </FormRow>

      {props.isProjectRequired &&
        <FormRow label='Project'>
          <Select
            name='project'
            value={props.project}
            required
            onChange={props.onInputChange}
          >
            <option disabled value={''}>Choose Project</option>
            {props.team.projects.map((project) => {
              return <option key={project.name} value={project.key}>{project.name}</option>;
            })}
          </Select>
        </FormRow>
      }
    </Fragment>
  );
};

RepoOwner.propTypes = {
  selectedGitProvider: PropTypes.string,
  owner: PropTypes.string,
  destinations: PropTypes.array.isRequired,
  project: PropTypes.string,
  projects: PropTypes.array,
  onInputChange: PropTypes.func.isRequired
};

export default RepoOwner;
