import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import crosstab from 'crosstab';
import * as states from '../../constants/stackCreatorStates';
import * as gitProviderStates from '../../constants/gitProviderStates';
import { onTransition } from '../../utils/transition';
import apiActions from '../../actions/api';
import appActions from '../../actions/app';
import ImportBitbucketRepos from './ImportBitbucketRepos';

const mapStateToProps = (props, ownProps) => {
  return createSelector(
    (appState) => appState.gitProviders,
    (appState) => appState.currentUser,
    (appState) => appState.account,
    (gitProviders, currentUser, account) => {
      return {
        gitProviders,
        currentUser,
        account,
        onClickCancel: ownProps.onClickCancel
      };
    }
  );
};

const mapDispatchToProps = {
  push: push,
  notifyUser: appActions.notifyUser,
  getGitRepos: apiActions.getGitRepos,
  getGitProjects: apiActions.getGitProjects,
  hideModal: appActions.hideModal
};

class ImportBitbucketReposContainer extends Component {
  componentDidMount () {
    const {
      notifyUser,
      getGitRepos
    } = this.props;

    crosstab.once('StackeryOAuthComplete', (message) => {
      crosstab.broadcast('StackeryOAuthCloseWindow');
      this.props.notifyUser(message.data.info, message.data.type);

      if (message.data.status === 'success') {
        notifyUser('Git authorization successful', 'success');
        getGitRepos(this.props.gitProviders.github.name);
      }
    });

    if (this.props.gitProviders.github.state === states.NEW) {
      getGitRepos(this.props.gitProviders.github.name);
    }
  }

  componentDidUpdate (prevProps) {
    onTransition(prevProps, this.props, 'gitProviders', {
      [gitProviderStates.FAILED]: {
        notify: {
          message: this.props.gitProviders.github.error,
          level: 'error'
        }
      }
    });

    if (this.props.gitProviders.bitbucket.state === states.NEW) {
      this.props.getGitRepos(this.props.gitProviders.bitbucket.name);
    }
  }

  render () {
    return (
      <ImportBitbucketRepos {...this.props} />
    );
  }
}

ImportBitbucketReposContainer.propTypes = {
  gitProviders: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired,
  notifyUser: PropTypes.func.isRequired,
  getGitRepos: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportBitbucketReposContainer);
