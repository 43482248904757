import React, { Fragment } from 'react';
import Link from '../core/Link';

const GetToolsHelper = () => {
  return (
    <Fragment>
      <h2>Cloudlocal development</h2>
      <p>One of the hardest adjustments to make when going from traditional app development to serverless app development is how to write and test your functions locally. Because functions will be interacting with cloud resources once they've been deployed, they need to interact with cloud resources in a local environment as well in order to accurately represent their infrastructure dependencies.</p>
      <p>With <code>stackery local invoke</code>, you can debug and develop function code directly against live cloud resources.</p>

      <h2>Stackery in your IDE</h2>
      <p>The <Link href='https://marketplace.visualstudio.com/items?itemName=stackery.stackery' text='Stackery Serverless Tools VS Code extension' target='_blank' rel='noopener noreferrer' /> lets you visually edit your architecture right in VS Code.</p>
      <iframe width='100%' src='https://www.youtube.com/embed/oIiKjS9tCQQ' title='Stackery VS code extension' frameBorder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowFullScreen />
      <h2>Read More:</h2>
      <ul>
        <li>
          <Link
            href='https://docs.stackery.io/docs/workflow/local-development/'
            text='Set up your cloudlocal development environment'
            target='_blank'
            rel='noopener noreferrer'
          />
        </li>
        <li>
          <Link
            href='https://docs.stackery.io/docs/using-stackery/cli/'
            text='Stackery CLI setup and usage'
            target='_blank'
            rel='noopener noreferrer'
          />
        </li>
        <li>
          <Link
            href='https://docs.stackery.io/docs/workflow/cli-config/'
            text='Configuring the Stackery CLI'
            target='_blank'
            rel='noopener noreferrer'
          />
        </li>
        <li>
          <Link
            href='https://docs.stackery.io/docs/workflow/deploying-serverless-stacks/'
            text='Deploying your stacks'
            target='_blank'
            rel='noopener noreferrer'
          />
        </li>
      </ul>
    </Fragment>
  );
};

export default GetToolsHelper;
