import React, { Fragment } from 'react';
import StackLink from './StackLink';
import EnvironmentLink from './EnvironmentLink';

const StackDeployedEvent = props => {
  const environmentContext = props.context === 'environments' ? null : <Fragment>to <EnvironmentLink {...props} /></Fragment>;
  const stackContext = props.context === 'stacks' ? 'Deployed' : <Fragment><StackLink {...props} /> deployed</Fragment>;

  return <span>{stackContext} {environmentContext} by {props.userEmail}</span>;
};

export default StackDeployedEvent;
