export function getGitProviders (gitProviders) {
  const providers = {};
  Object.entries(gitProviders).forEach(([name, provider]) => {
    if (provider && typeof provider === 'object') {
      providers[name] = {
        name: name,
        type: provider.type,
        displayName: provider.displayName,
        url: provider.url
      };
    }
  });

  return providers;
}
