import React from 'react';
import LinkButton from '../core/LinkButton';

const UpdateStackeryRole = (props) => {
  return (
    <div>
      <h1>Update Stackery Role</h1>
      <h2>Version {props.latest} is available</h2>
      <p>Select <strong>Next</strong> to proceed through the prepopulated steps then be sure to check the box to acknowledge IAM capabilities before selecting <strong>Update stack</strong></p>
      <LinkButton
        href={props.href}
        target='_blank'
        text={`Update to Stackery ${props.latest}`}
        display='inline-block'
      />
    </div>
  );
};

export default UpdateStackeryRole;
