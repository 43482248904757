import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Spinner from './Spinner';
import style from './Editor.css';

const Editor = (props) => {
  return (
    <div className={classnames(style.container, props.hasBorder && style.hasBorder, props.hasError && style.hasError, props.isLoading && style.isLoading, props.isReadOnly && style.isReadOnly, props.hasMinHeight && style.hasMinHeight)}>
      {props.isLoading &&
        <Spinner />
      }
      <div className={style.editor}>
        <div ref={props.onRef} />
        {props.required &&
          <textarea
            className={style.validator}
            required
            value={props.value}
            onChange={(e) => { e.preventDefault(); }}
          />
        }
      </div>
    </div>
  );
};

Editor.defaultProps = {
  hasBorder: true,
  hasMinHeight: true,
  isSupported: true
};

Editor.propTypes = {
  isLoading: PropTypes.bool,
  isSupported: PropTypes.bool,
  hasError: PropTypes.bool,
  onRef: PropTypes.func.isRequired
};

export default Editor;
