
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Link from '../core/Link';
import FormRow from '../core/FormRow';
import Textarea from '../core/Textarea';
import MessageBox from '../core/MessageBox';
import Diff from '../core/Diff';

const Commit = props => {
  const hasLocalChanges = props.originalTemplate !== props.currentTemplate;

  return (
    <Fragment>
      <FormRow label='Commit Message'>
        <Textarea
          name='message'
          defaultValue={props.message}
          required
          onChange={props.onInputChange}
          rows={props.message.split('\n').length + 2}
        />
      </FormRow>
      {!props.isLegacy && !props.stack.isGitless && hasLocalChanges && props.isTemplateValid &&
        <Diff originalTemplate={props.originalTemplate} updatedTemplate={props.currentTemplate} />
      }
      {!props.isLegacy && !props.isTemplateValid &&
        <MessageBox icon='warning'>
          <p>
            The current template is invalid and could lead to deployment errors. <br />
            {props.onReset && <Link onClick={props.onReset}>Undo the changes</Link>}
            {!props.onReset && <Fragment>Fix the changes</Fragment>} {''} that caused an error or proceed with caution.
          </p>
        </MessageBox>
      }
    </Fragment>
  );
};

Commit.defaultProps = {
  message: '',
  isLegacy: false // Disable template diff for legacy stacks `format: 'stackery'`
};

Commit.propTypes = {
  stack: PropTypes.object.isRequired,
  message: PropTypes.string.isRequired,
  originalTemplate: PropTypes.string,
  currentTemplate: PropTypes.string,
  isTemplateValid: PropTypes.bool,
  isLegacy: PropTypes.bool,
  onInputChange: PropTypes.func.isRequired
};

export default Commit;
