import React from 'react';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import Icon from '../core/Icon';
import style from './EnvironmentNav.css';

const EnvironmentNav = ({ match }) => {
  return (
    <nav className={style.container}>
      <NavLink
        className={style.navLink}
        activeClassName={style.activeNavLink}
        to={`${match.url}/overview`}
      >
        <Icon name='chart' className={style.icon} />
        <span>Overview</span>
      </NavLink>
      <NavLink
        className={style.navLink}
        activeClassName={style.activeNavLink}
        to={`${match.url}/parameters`}
      >
        <Icon name='brackets' className={style.icon} />
        <span>Parameters</span>
      </NavLink>
      <NavLink
        className={style.navLink}
        activeClassName={style.activeNavLink}
        to={`${match.url}/secrets`}
      >
        <Icon name='lock' className={classnames(style.icon, style.iconLock)} />
        <span>Secrets</span>
      </NavLink>
    </nav>
  );
};

EnvironmentNav.propTypes = {
};

export default EnvironmentNav;
