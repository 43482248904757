import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, Switch } from 'react-router';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import Icon from '../core/Icon';
import Link from '../core/Link';
import VisualContainer from '../stackEdit/VisualContainer';
import TemplateContainer from '../stackEdit/TemplateContainer';
import style from './PublicEditor.css';

const PublicEditor = props => {
  const {
    match,
    isStandalone,
    isDesignMode
  } = props;

  return (
    <div className={classnames(style.container, isStandalone && style.isStandalone, isDesignMode && style.isDesignMode)}>
      <header className={style.header}>
        <Link extraClassName={style.cta} href={`//www.stackery.io/sign-up${window.location.search}`} target='_blank'>
          <Icon name='stackery-logo' className={style.iconLogo} />
          <span>Deploy with Stackery</span>
        </Link>
        <nav className={style.nav}>
          <NavLink
            className={style.navLink}
            activeClassName={style.activeNavLink}
            to={`${match.url}/design`}
          >
            <Icon name='grid' className={style.iconGrid} />
            <span>Design</span>
          </NavLink>
          <NavLink
            className={style.navLink}
            activeClassName={style.activeNavLink}
            to={`${match.url}/template`}
          >
            <Icon name='editor' className={style.iconEditor} />
            <span>Template</span>
          </NavLink>
        </nav>
      </header>
      <div className={style.main}>
        <Switch>
          <Route exact path={`${match.path}/design`} component={VisualContainer} />
          <Route exact path={`${match.path}/design/:resourceId`} component={VisualContainer} />
          <Route exact path={`${match.path}/template`} component={TemplateContainer} />
          <Redirect to={`${match.url}/design${window.location.search}`} />
        </Switch>
      </div>
    </div>
  );
};

PublicEditor.propTypes = {
  match: PropTypes.object.isRequired,
  isStandalone: PropTypes.bool
};

export default PublicEditor;
