import React, { Fragment } from 'react';
import StackRepoFormContainer from '../stackRepoForm/StackRepoFormContainer';

const CreateStackNewRepo = (props) => {
  return (
    <Fragment>
      <h1>Add a Stack</h1>
      <p>Create a new stack with a new Git repository backed by a VCS provider.</p>
      <StackRepoFormContainer />
    </Fragment>
  );
};

CreateStackNewRepo.propTypes = {
};

export default CreateStackNewRepo;
