import React from 'react';
import PropTypes from 'prop-types';
import Table from '../core/Table';
import ActionHeader from '../core/ActionHeader';
import Spinner from '../core/Spinner';
import Input from '../core/Input';

const Integrations = props => {
  const {
    isLoading,
    onInputChange,
    onSaveClick,
    currentValues,
    formValues,
    integrations
  } = props;

  return (
    <div>
      <ActionHeader
        headerText='Integrations'
        buttonText='Save'
        onClick={onSaveClick}
      />
      <Table isStriped isEmpty={isLoading}>
        {isLoading &&
          <tbody>
            <tr>
              <td colSpan='4'><Spinner position='static' /></td>
            </tr>
          </tbody>
        }

        {!isLoading &&
          <tbody>
            {integrations.map(({label, property, pattern}) => (
              <tr key={property}>
                <td style={{ width: '50%' }}>
                  {label}
                  {formValues[property] !== currentValues[property] && <b> (Unsaved)</b>}
                </td>
                <td style={{ textAlign: 'right' }}>
                  <Input value={formValues[property]} name={property} pattern={pattern} onChange={onInputChange} />
                </td>
              </tr>
            ))}
          </tbody>
        }
      </Table>
    </div>
  );
};

Integrations.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func.isRequired,
  currentValues: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  integrations: PropTypes.array.isRequired
};

export default Integrations;
