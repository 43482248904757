import makeActions from '../utils/makeActions';

export const types = {
  START_IOT_NOTIFICATIONS: 'IOT_NOTIFICATIONS::START_IOT_NOTIFICATIONS',
  STOP_IOT_NOTIFICATIONS: 'IOT_NOTIFICATIONS::STOP_IOT_NOTIFICATIONS',
  CONNECT: makeActions('IOT_NOTIFICATIONS', 'CONNECT', ['SUCCESS', 'FAILURE']),
  DISCONNECT: 'IOT_NOTIFICATIONS::DISCONNECT',
  NOTIFICATION: 'IOT_NOTIFICATIONS::NOTIFICATION',
  DEPLOYMENT: 'IOT_NOTIFICATIONS::DEPLOYMENT',
  DEPLOYMENT_PIPELINE_JOB: 'IOT_NOTIFICATIONS::DEPLOYMENT_PIPELINE_JOB',
  DEPLOYMENT_PIPELINE_QUEUE: 'IOT_NOTIFICATIONS::DEPLOYMENT_PIPELINE_QUEUE',
  DEPLOYMENT_PIPELINE_STEP: 'IOT_NOTIFICATIONS::DEPLOYMENT_PIPELINE_STEP',
  GIT: 'IOT_NOTIFICATIONS::GIT'
};

// Map response type strings to corresponding action type
const mapEventTypeToActionType = {
  deployment: types.DEPLOYMENT,
  deploymentPipelineJob: types.DEPLOYMENT_PIPELINE_JOB,
  deploymentPipelineQueue: types.DEPLOYMENT_PIPELINE_QUEUE,
  deploymentPipelineStep: types.DEPLOYMENT_PIPELINE_STEP,
  git: types.GIT
};

const actions = {
  startIotNotifications: owner => ({
    type: types.START_IOT_NOTIFICATIONS,
    owner
  }),
  stopIotNotifications: () => ({
    type: types.STOP_IOT_NOTIFICATIONS
  }),
  notification: ({ eventType, ...payload }) => ({
    type: mapEventTypeToActionType[eventType] || types.NOTIFICATION,
    ...payload
  })
};

export default actions;
