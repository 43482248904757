import React from 'react';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';
import Link from '../core/Link';
import CenteredContainer from '../core/CenteredContainer';
import MessageBox from '../core/MessageBox';
import StatusMessage from '../core/StatusMessage';
import Form from '../core/Form';
import FormRow from '../core/FormRow';
import Input from '../core/Input';
import Button from '../core/Button';
import Spinner from '../core/Spinner';
import Icon from '../core/Icon';
import style from './SignIn.css';

const SignIn = props => {
  const {
    isProcessing,
    isLoading,
    error,
    signupSuccess,
    handleInputChange,
    handleSubmit,
    showPasswordField,
    awsMarketplaceQuery
  } = props;

  return (
    <CenteredContainer className={style.container} contentClassName={style.content}>
      <DocumentTitle title='Stackery Sign In' />
      <Icon name='stackery-logo' className={style.logo} />
      <MessageBox alignCenter>
        {error && (
          <StatusMessage type='error' text={error.message} />
        )}

        {signupSuccess && (
          <StatusMessage type='success' text='You were successfully signed up. Please sign in.' />
        )}
      </MessageBox>
      <Form onSubmit={handleSubmit}>
        {isProcessing && (<Spinner height='130px' />)}
        {!isProcessing &&
          <div>
            <FormRow>
              <Input
                type='text'
                name='email'
                placeholder='email'
                onChange={handleInputChange}
              />
            </FormRow>
            {showPasswordField &&
              <FormRow>
                <Input
                  type='password'
                  name='password'
                  placeholder='password'
                  onChange={handleInputChange}
                />
              </FormRow>
            }
            <Button
              type='submit'
              text='Continue'
              isLoading={isLoading}
            />
          </div>
        }
        {awsMarketplaceQuery &&
          <MessageBox alignCenter>
            <p>Log in or <Link
              href={`https://app.stackery.io/sign-up?${awsMarketplaceQuery}`}
              target='_blank'>
              Sign Up
            </Link>{' '}to complete the AWS Marketplace billing set-up.</p>
          </MessageBox>
        }
      </Form>
    </CenteredContainer>
  );
};

SignIn.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.object,
  signupSuccess: PropTypes.bool.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  showPasswordField: PropTypes.bool.isRequired,
  awsMarketplaceQuery: PropTypes.string
};

SignIn.defaultProps = {
  isLoading: false,
  signupSuccess: false
};

export default SignIn;
