import { createSelector } from 'reselect';
import * as definitions from '../resources/definitions';

export const typesDashboardProperties = format =>
  Object.keys(definitions[format].ResourceTypes).reduce((typesDashboardProperties, typeName) => {
    typesDashboardProperties[typeName] = definitions[format].ResourceTypes[typeName].DashboardProperties;

    const metrics = definitions[format].ResourceTypes[typeName].Metrics || { metrics: [] };
    const numVisibleMetrics = metrics.metrics.filter(metric => !metric.isHidden).length;

    typesDashboardProperties[typeName].numVisibleMetrics = numVisibleMetrics;

    typesDashboardProperties.facet = {
      outputs: 1,
      numVisibleMetrics: 0
    };

    return typesDashboardProperties;
  }, {});

const samNodeTypes = {
  types: typesDashboardProperties('SAM')
};

const serverlessNodeTypes = {
  types: typesDashboardProperties('serverless')
};

export default createSelector(
  (appState) => appState.stack,
  stack => {
    switch (stack.format) {
      case 'serverless':
        return serverlessNodeTypes;

      default:
        return samNodeTypes;
    }
  }
);
