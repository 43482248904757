import { types as workspaceTypes } from '../actions/workspace';
import { types as appTypes } from '../actions/app';
import * as states from '../constants/states';

export const initialState = {
  state: states.NEW,
  port: '45984',
  host: 'localhost',
  templateDir: undefined,
  templatePath: 'template.yaml',
  secret: undefined,
  message: {},
  contents: undefined,
  isDiverged: false,
  isSaveNeeded: false
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case workspaceTypes.STOP_WORKSPACE:
      return {
        ...initialState
      };

    case workspaceTypes.START_WORKSPACE:
      return {
        ...state,
        port: action.port || state.port,
        templateDir: action.templateDir || state.templateDir,
        templatePath: action.templatePath || state.templatePath,
        secret: action.secret || state.secret,
        host: action.host || state.host
      };

    case workspaceTypes.CONNECT:
      return {
        ...state,
        state: states.OKAY,
        error: null
      };

    case workspaceTypes.CONNECT_FAILURE:
      return {
        ...state,
        state: states.DISCONNECTED,
        error: action.error
      };

    case workspaceTypes.DISCONNECT:
      return {
        ...state,
        state: states.DISCONNECTED,
        error: 'Connection to the local file system was disrupted. Try restarting with the `stackery edit` command.'
      };

    case workspaceTypes.RECEIVE_MESSAGE:
      return {
        ...state,
        message: { ...action.message }
      };

    // If a received message indicates an update to a file but we don't care to read the file, this
    // code must be run to clear the state related to the irrelevant message.
    case workspaceTypes.IGNORE_MESSAGE:
      return {
        ...state,
        message: { }
      };

    case workspaceTypes.LOAD:
      return {
        ...state,
        state: states.LOADING,
        contents: action.contents,
        isDiverged: false
      };

    case workspaceTypes.READ.REQUEST:
      return {
        ...state,
        state: action.file === state.templatePath ? states.LOADING : state.state,
        message: {}
      };

    case workspaceTypes.READ.SUCCESS:
      if (action.file !== state.templatePath) { return state; }

      return {
        ...state,
        state: states.OKAY,
        contents: action.data,
        isDiverged: action.data !== state.contents,
        isSaveNeeded: false
      };

    case workspaceTypes.READ.FAILURE:
      return {
        ...state,
        state: states.FAILED
      };

    case workspaceTypes.SAVE.SUCCESS:
      if (action.file !== state.templatePath) { return state; }

      return {
        ...state,
        state: states.OKAY,
        contents: action.contents,
        isDiverged: false
      };

    case workspaceTypes.SAVE.FAILURE:
      return {
        ...state,
        state: states.FAILED
      };

    case appTypes.PARSE_STACK_TEMPLATE.SUCCESS:
      const isDiverged = action.template !== state.contents;
      return {
        ...state,
        isDiverged: isDiverged,
        isSaveNeeded: isDiverged
      };

    default:
      return state;
  }
};
