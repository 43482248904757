import React from 'react';

import Wire from './Wire';

const Wires = (props) => {
  return (
    <g>
      {props.nodes.map(node => node.wires && node.wires.map(
        (port, i) =>
          port &&
          port.map(dest =>
            <Wire
              key={`${node.id}:${dest}`}
              nodeTypes={props.nodeTypes}
              source={node}
              target={props.nodes.find(node => node.id === dest)}
              resources={props.resources}
              port={i}
              editable={props.editable}
            />
          )
      ))}
      {props.wiring &&
        <Wire
          nodeTypes={props.nodeTypes}
          source={
            props.wiring.port === undefined
              ? undefined
              : props.nodes.find(
                node => node.id === props.wiring.node
              )
          }
          target={
            props.wiring.port === undefined
              ? props.nodes.find(
                node => node.id === props.wiring.node
              )
              : undefined
          }
          resources={props.resources}
          port={props.wiring.port}
          editable={props.editable}
        />
      }
    </g>
  );
};

export default Wires;
