import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import crosstab from 'crosstab';
import * as states from '../../constants/states';
import * as gitProviderStates from '../../constants/gitProviderStates';
import getStackFormat from '../../utils/getStackFormat';
import appActions from '../../actions/app';
import apiActions from '../../actions/api';
import StackRepoForm from './StackRepoForm';

const initialState = (props) => {
  return {
    selectedGitProvider: undefined,
    stackName: '',
    owner: '',
    repoName: props.stack.name,
    region: '',
    project: '',
    useStackNameAsRepoName: true,
    sourceBlueprint: undefined,
    sourceRepo: undefined,
    sourceRef: 'main',
    templatePath: 'template.yaml',
    token: undefined,
    user: undefined,
    message: props.message,
    isPrivate: false
  };
};

const mapStateToProps = (props, ownProps) => {
  return createSelector(
    (appState) => appState.stack,
    (appState) => appState.stackCreator,
    (appState) => appState.editorNodes,
    (appState) => appState.templateEditor,
    (appState) => appState.gitProviders,
    (appState) => appState.currentUser,
    (appState) => appState.account,
    (stack, stackCreator, editorNodes, templateEditor, gitProviders, currentUser, account) => {
      return {
        stack,
        editorNodes,
        gitProviders,
        currentUser,
        account,
        message: ownProps.message,
        validTemplate: templateEditor.validTemplate,
        originalTemplate: templateEditor.remoteTemplate,
        currentTemplate: templateEditor.rawTemplate,
        isTemplateValid: templateEditor.isTemplateValid,
        isTemplateReset: templateEditor.isTemplateReset,
        isLoading: stackCreator.state === states.LOADING || stack.savingState === states.LOADING,
        isNewRepo: ownProps.isNewRepo !== false,
        isExistingRepo: ownProps.isNewRepo === false,
        isNewStack: stack.name === undefined,
        onClickCancel: ownProps.onClickCancel
      };
    }
  );
};

const mapDispatchToProps = {
  notifyUser: appActions.notifyUser,
  createStack: apiActions.createStack,
  saveResources: apiActions.saveResources,
  getGitDestinations: apiActions.getGitDestinations,
  getGitRepos: apiActions.getGitRepos,
  saveGitPersonalAccessToken: apiActions.saveGitPersonalAccessToken,
  resetStackTemplate: appActions.resetStackTemplate,
  parseStackTemplate: appActions.parseStackTemplate,
  hideModal: appActions.hideModal
};

class StackRepoFormContainer extends Component {
  constructor (props) {
    super(props);

    this.state = {
      ...initialState(props)
    };

    this.handleReset = this.handleReset.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.savePersonalAccessToken = this.savePersonalAccessToken.bind(this);
  }

  componentDidMount () {
    crosstab.once('StackeryOAuthComplete', (message) => {
      crosstab.broadcast('StackeryOAuthCloseWindow');
      this.props.notifyUser(message.data.info, message.data.type);

      if (message.data.status === 'success') {
        this.props.getGitDestinations(message.data.provider);
      }
    });

    this.props.parseStackTemplate(this.props.currentTemplate, getStackFormat(this.props.currentTemplate));
  }

  componentDidUpdate (prevProps, prevState) {
    const { selectedGitProvider } = this.state;

    const {
      account,
      gitProviders
    } = this.props;

    if (selectedGitProvider && selectedGitProvider !== prevState.selectedGitProvider) {
      if (prevState.selectedGitProvider) {
        this.setState({
          ...initialState(this.props),
          selectedGitProvider: selectedGitProvider,
          repoName: this.state.repoName
        });
      }

      this.props.getGitDestinations(selectedGitProvider);
    }

    if (
      selectedGitProvider &&
      this.props.isExistingRepo &&
      gitProviders[selectedGitProvider].reposState === states.NEW &&
      gitProviders[selectedGitProvider].state === gitProviderStates.AUTHORIZED &&
      prevProps.gitProviders[selectedGitProvider].state !== gitProviderStates.AUTHORIZED
    ) {
      this.props.getGitRepos(selectedGitProvider);
    }

    if (selectedGitProvider === 'codecommit' && prevState.selectedGitProvider !== selectedGitProvider && account.providers.length > 0) {
      this.setState({ region: account.providers[0].region });
    }

    if (
      (selectedGitProvider && gitProviders[selectedGitProvider].destinations && gitProviders[selectedGitProvider].destinations.length > 0) &&
      (prevState.selectedGitProvider !== selectedGitProvider || !prevProps.gitProviders[selectedGitProvider].destinations)
    ) {
      this.setState({ owner: gitProviders[selectedGitProvider].destinations[0].id });
    }
  }

  handleReset (inputs) {
    inputs.forEach(input => this.setState({ [input]: null }));
  }

  handleInputChange (event) {
    let {
      name,
      value
    } = event.target;

    // Convert 'true' or 'false' string to boolean
    value = value === 'true' ? true : value === 'false' ? false : value;

    this.setState({ [name]: value });
  }

  handleSubmit (event) {
    event.preventDefault();

    if (this.props.gitProviders[this.state.selectedGitProvider].needsPersonalAccessToken) {
      this.savePersonalAccessToken();
    } else if (this.props.isNewStack) {
      this.handleCreate();
    } else {
      this.handleSave();
    }
  }

  handleSave () {
    const {
      selectedGitProvider
    } = this.state;

    let newRepo;

    newRepo = {
      provider: selectedGitProvider
    };

    if (selectedGitProvider === 'codecommit') {
      newRepo.awsAccount = this.state.owner;
      newRepo.location = `${this.state.owner}/${this.state.region}/${this.state.repoName}`;
    } else {
      newRepo.private = this.state.isPrivate;
      newRepo.location = `${this.state.owner}/${this.state.repoName}`;

      if (selectedGitProvider === 'bitbucket' && !!this.state.project) {
        newRepo.project = this.state.project;
      }
    }

    this.props.saveResources(
      this.props.stack.owner,
      this.props.stack.name,
      this.props.stack.branch,
      this.props.currentTemplate,
      this.props.stack.format,
      {
        message: this.state.message,
        email: this.props.currentUser.email
      },
      newRepo
    );
  }

  handleCreate () {
    let awsAccount;
    let location;
    let isPrivate;
    let source;

    const {
      gitProviders,
      isNewRepo
    } = this.props;

    const {
      stackName,
      owner,
      region,
      sourceBlueprint,
      project,
      selectedGitProvider,
      sourceRepo,
      sourceRef,
      templatePath
    } = this.state;

    // use repoName if defined
    const repoName = this.state.repoName || stackName;

    if (sourceBlueprint) {
      source = {
        blueprint: sourceBlueprint
      };
    }

    if (sourceRepo && sourceRef) {
      source = {
        repository: sourceRepo,
        reference: sourceRef
      };
    }

    if (isNewRepo) {
      location = region ? `${owner}/${region}/${repoName}` : `${owner}/${repoName}`;
    }

    if (gitProviders.codecommit && selectedGitProvider === gitProviders.codecommit.name) {
      awsAccount = owner;
    } else {
      isPrivate = this.state.isPrivate;
    }

    // Drop project during stringify if it doesn't have a value
    this.props.createStack(selectedGitProvider, stackName, location, source, templatePath, isPrivate, isNewRepo, awsAccount, project || undefined);
  }

  handleCancel () {
    this.props.hideModal();
  }

  savePersonalAccessToken () {
    this.props.saveGitPersonalAccessToken(this.state.selectedGitProvider, this.state.user, this.state.token);
  }

  render () {
    return (
      <StackRepoForm
        {...this.props}
        {...this.state}
        onResetFields={this.handleReset}
        onInputChange={this.handleInputChange}
        onSubmit={this.handleSubmit}
        onCancel={this.handleCancel}
      />
    );
  }
}

StackRepoFormContainer.propTypes = {
};

export default connect(mapStateToProps, mapDispatchToProps)(StackRepoFormContainer);
