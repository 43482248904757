export const types = {
  AUTO_SAVE: 'APP::NODE_EDITOR::AUTO_SAVE',
  EDIT_NODE: 'APP::NODE_EDITOR::EDIT',
  CANCEL: 'APP::NODE_EDITOR::CANCEL'
};

const actions = {
  autoSave: node => ({ type: types.AUTO_SAVE, node }),
  editNode: node => ({ type: types.EDIT_NODE, node }),
  cancel: () => ({ type: types.CANCEL })
};

export default actions;
