import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../core/Icon';
import Tooltip from '../core/Tooltip';
import JobItemDeployed from './JobItemDeployed';
import JobItemPreparing from './JobItemPreparing';
import JobItemQueued from './JobItemQueued';
import style from './StageCell.css';

const StageCell = props => {
  const {
    stack,
    stage,
    stages,
    stageIndex,
    rowIndex
  } = props;

  const isFirstStage = stageIndex === 0;
  const isLastStage = stageIndex === stack.stages.length - 1;

  const nextStage = isLastStage ? undefined : props.stack.stages[props.stageIndex + 1];

  const hasJobDeployed = !!stage.deployed && !!stage.deployed.deploymentHistoryId;
  const hasJobPreparing = !!stage.preparing && !!stage.preparing.deploymentHistoryId && stage.preparing.deploymentHistoryId !== stage.deployed.deploymentHistoryId;
  const hasJobQueued = !!stage.queued && !!stage.queued.length > 0;

  // Determine which row to display the job depending on the presence of a `deployed` job row...
  // ...so the jobs are not staggered in separate rows if there's an empty cell
  const showJobDeployed = rowIndex === 0 && hasJobDeployed;
  const showJobPreparing = (rowIndex === 0 && !hasJobDeployed && hasJobPreparing) || (rowIndex === 1 && hasJobDeployed && hasJobPreparing);
  const showJobQueued = (rowIndex === 0 && !hasJobDeployed && hasJobQueued) || (rowIndex === 1 && hasJobDeployed && hasJobQueued);

  // Determine which row to display `hasNoJobs` message, depending on the presence of a `deployed` job row
  const isCorrectRowIndex = hasJobDeployed ? rowIndex === 1 : rowIndex === 0;
  const hasNoJobs = isCorrectRowIndex && (stage.deployed && !stage.deployed.deploymentHistoryId) && (stage.preparing && !stage.preparing.deploymentHistoryId) && (stage.queued && stage.queued.length === 0);

  // Determine if this stage `deployed` version is different from the next stage `deployed` version
  const hasPendingChangeset = !isLastStage && hasJobDeployed && rowIndex === 0 && (
    nextStage && nextStage.deployed && nextStage.deployed.version
  );
  const isDiff = hasPendingChangeset && nextStage.deployed.version !== props.stage.deployed.version;
  const isSynced = hasPendingChangeset && nextStage.deployed.version === props.stage.deployed.version;

  return (
    <td className={style.container} header={`row-header-${props.stack.stackName}`}>
      <div className={style.content}>
        {hasNoJobs &&
          <p>
            <em>{props.stack.stackName}</em> has not been deployed to <em>{stages[stageIndex].environment.name}</em>
            {isFirstStage &&
            <Tooltip isInline message={`Trigger the pipeline by pushing changes to the default branch`} place='top'><Icon name='questionCircle' className={style.iconQuestion} /></Tooltip>
            }
          </p>
        }

        {showJobDeployed &&
          <JobItemDeployed {...props} deployed={stage.deployed} />
        }

        {showJobPreparing &&
          <JobItemPreparing {...props} preparing={stage.preparing} />
        }

        {showJobQueued &&
          <JobItemQueued {...props} queued={stage.queued} />
        }
      </div>

      {isDiff &&
        <div className={style.diff} onClick={() => props.onDiffClick(props.deploymentPipeline.id, props.stack.stackName, props.stageIndex)}>
          <Icon name='diff' className={style.diffIcon} />
        </div>
      }

      {isSynced &&
        <div className={style.diff}>
          <Tooltip isInline message={'Stages are up-to-date'} place='top'><Icon name='checkmark-circle-outline' className={style.checkmarkIcon} /></Tooltip>
        </div>
      }
    </td>
  );
};

StageCell.propTypes = {
  stage: PropTypes.object.isRequired,
  stageIndex: PropTypes.number.isRequired,
  stack: PropTypes.object.isRequired,
  owner: PropTypes.string.isRequired,
  deploymentPipeline: PropTypes.object.isRequired,
  promoteDeploymentPipeline: PropTypes.func.isRequired,
  retryDeploymentPipeline: PropTypes.func.isRequired
};

export default StageCell;
