import React from 'react';
import PropTypes from 'prop-types';
import FormRow from '../core/FormRow';
import Select from '../core/Select';

const EnvironmentSelector = props => {
  const {
    stack,
    environments
  } = props;

  return (
    <FormRow label='Environment'>
      <Select
        onChange={props.onEnvironmentChange}
        value={stack.environment}
      >
        {!stack.environment &&
          <option>-----</option>
        }
        {stack.environment && !environments.data.length &&
          <option>{stack.environment}</option>
        }
        {environments.data.map(environment => {
          return (
            <option key={environment.name} value={environment.name}>
              {environment.name}
            </option>
          );
        })}
      </Select>
    </FormRow>
  );
};

EnvironmentSelector.propTypes = {
  stack: PropTypes.object,
  environments: PropTypes.object
};

export default EnvironmentSelector;
