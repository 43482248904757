import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { serverlessFunctionName } from '../../resources/resource';
import { getCustomResourceName, getCustomResourceLabel } from '../../resources/getCustomResourceInfo';
import isSettingHidden from '../../utils/isSettingHidden';
import resourceEditorActions from '../../actions/resourceEditor';
import appActions from '../../actions/app';
import ResourceEditor from './ResourceEditor';
import selectors from '../../selectors';

const mapStateToProps = () => {
  return createSelector(
    (appState) => appState.resourceEditor,
    (appState) => appState.configKeys,
    (appState) => appState.editorNodes,
    (appState) => appState.formation,
    (appState) => appState.stack,
    selectors.nodeTypes,
    (appState) => appState.account,
    (resourceEditor, configKeys, editorNodes, formation, stack, nodeTypes, account) => {
      let editorNode = editorNodes.nodes.find(node => node.id === resourceEditor.id);
      const nodeType = editorNode ? nodeTypes.types[editorNode.type] : {};

      let name = resourceEditor.settings.Name || resourceEditor.id;
      let type = nodeType.paletteResource;
      let description = nodeType.paletteInfo;

      if (resourceEditor.resourceType === 'custom') {
        name = getCustomResourceName(resourceEditor.id, formation.resources.resources);
        type = getCustomResourceLabel(resourceEditor.id, formation.resources.resources);

        let resourceTypes = Object.keys(resourceEditor.settings.CloudFormation.Resources).map(resourceId => resourceEditor.settings.CloudFormation.Resources[resourceId].Type);
        // This is to uniquify the list of types
        resourceTypes = Array.from(new Set(resourceTypes)).join(', ');
        description = resourceTypes.length > 0 ? `This is a group of related CloudFormation resources that include ${resourceTypes}` : '';
      }

      return {
        resource: resourceEditor,
        name,
        type,
        description,
        docsLink: nodeType.paletteDocsLink,
        editorNodes: editorNodes,
        formation,
        configKeys: configKeys.keys.map(key => key.replace(/\$|%\{|@\{|\}|config\./g, '')),
        errors: editorNode ? editorNode.errors : [],
        format: stack.format,
        isEditable: Object.keys(resourceEditor.settingTypes).length > 0,
        autocomplete: {
          layers: account.layers
        }
      };
    }
  );
};

const mapDispatchToProps = {
  updateSetting: resourceEditorActions.updateSetting,
  updateResourceId: resourceEditorActions.updateResourceId,
  saveResource: resourceEditorActions.saveResource,
  cancel: resourceEditorActions.cancel,
  selectStackResource: appActions.selectStackResource,
  deselectStackResource: appActions.deselectStackResource
};

class ResourceEditorContainer extends Component {
  constructor (props) {
    super(props);

    this.resource = props.resource;

    this.dependencySettings = Object.values(this.resource.settingTypes)
      .map(schema => {
        if ('DependsOn' in schema) {
          return Object.keys(schema.DependsOn);
        }

        if (schema.Type === 'FacetResources' && 'Setting' in schema.Facets) {
          return schema.Facets.Setting;
        }

        return null;
      })
      .filter(setting => setting)
      .reduce((acc, val) => acc.concat(val), []); // Flatten array

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleUpdateSettings = this.handleUpdateSettings.bind(this);
    this.handleGetResourceSetting = this.handleGetResourceSetting.bind(this);
  }

  handleGetResourceSetting (resourceId, facetType, facetId, settingName) {
    return this.props.formation.resources.getResourceSetting(resourceId, facetType, facetId, settingName);
  }

  handleClose () {
    this.props.cancel();
    document.querySelector('#canvas').focus({ preventScroll: true });
    this.props.deselectStackResource();
  }

  handleSubmit (event) {
    event.preventDefault();
    let resourceId = this.resource.id;
    let settings = [];

    Object.keys(this.resource.settings).forEach(settingId => {
      let newValue = this.resource.settings[settingId];
      let oldResourceId;

      if (newValue !== undefined) {
        const wasLogicalIdUpdated = settingId === 'LogicalId' && resourceId !== this.resource.settings[settingId];

        // Unset the value if the value is the same as the global default
        // Pass the unchanged resource.id b/c resource state has not been updated with a new ID (if it changed) until after all settings are saved
        const globalDefault = this.props.formation.resources.getResourceSetting(this.resource.id, this.resource.facetType, this.resource.facetId, settingId, true);
        if (globalDefault === this.resource.settings[settingId]) {
          this.resource.settings[settingId] = null;
        }

        // Skip saving those settings which were hidden from the user.
        const settingSchema = this.resource.settingTypes[settingId];
        if (!isSettingHidden(settingSchema, this.resource, this.handleGetResourceSetting)) {
          settings.push({ resourceId, settingId, value: newValue, facetType: this.resource.facetType, facetId: this.resource.facetId, isStackDirty: wasLogicalIdUpdated });
        }

        // In a serverless stack, updating a function name updates its resourceId
        // Update resourceId if Name or LogicalId changes so next iteration will be up-to-date
        if (this.props.format === 'serverless' && this.props.resource.resourceType === 'function' && settingId === 'Name') {
          oldResourceId = resourceId;
          resourceId = serverlessFunctionName(newValue);
          this.props.updateResourceId(oldResourceId, resourceId);
        } else if (settingId === 'LogicalId') {
          oldResourceId = resourceId;
          resourceId = newValue;
          this.props.updateResourceId(oldResourceId, resourceId);
        }
      }
    });

    this.props.saveResource(resourceId, settings);
    this.handleClose();
  }

  handleUpdateSettings (settingId, value, target) {
    const isSelect = settingId !== 'Name' ? this.props.resource.settingTypes[settingId].InputType === 'select' : false;

    if (isSelect && value === 'null') {
      value = null;
    }

    if (this.dependencySettings.includes(settingId)) {
      this.props.updateSetting(settingId, value);
    }

    this.resource.settings[settingId] = value;

    /* This is a bit of a hack, but the alternatives are all worse. We can't
     * allow the user to rename one function to match the name of another
     * existing function in the serverless framework because the function names
     * are also their IDs (roughly). */
    if (
      this.props.format === 'serverless' &&
      this.resource.resourceType === 'function' &&
      settingId === 'Name'
    ) {
      if (value in this.props.formation.resources.template.functions) {
        target.setCustomValidity(`Function ${value} already exists`);
      } else {
        target.setCustomValidity('');
      }
    }
    /* The issue is similar with SAM and Logical ID renaming but applies to
     * all resource types.  */
    if (
      this.props.format === 'SAM' &&
      settingId === 'LogicalId' &&
      value !== this.resource.id
    ) {
      if (value in this.props.formation.resources.resources) {
        target.setCustomValidity(`Resource ${value} already exists`);
      } else {
        target.setCustomValidity('');
      }
    }
  }

  componentDidMount () {
    this.props.selectStackResource(this.props.resource.facetId || this.props.resource.id);
    document.activeElement.blur();
  }

  render () {
    return (
      <ResourceEditor
        {...this.props}
        getResourceSetting={this.handleGetResourceSetting}
        onUpdateSettings={this.handleUpdateSettings}
        onSubmit={this.handleSubmit}
        onCancel={this.handleClose}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResourceEditorContainer);
