import { types as cloudwatchTypes } from '../actions/cloudwatch';
import { types as appTypes } from '../actions/app';
import * as states from '../constants/states';

export const initialState = {
  state: states.NEW,
  range: 60
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case cloudwatchTypes.GET_METRICS.REQUEST:
      return {
        ...state,
        state: states.LOADING,
        range: action.range || state.range
      };
    case cloudwatchTypes.GET_METRICS.SUCCESS:
      return {
        ...state,
        state: states.OKAY
      };
    case cloudwatchTypes.GET_METRICS.FAILURE:
      return {
        ...state,
        state: states.FAILED
      };
    case appTypes.SELECT_DEPLOYMENT:
    case appTypes.SELECT_STACK_ENVIRONMENT:
      return {
        ...state,
        state: states.NEW
      };
    case cloudwatchTypes.DISABLE_METRICS:
      return {
        ...state,
        state: states.DISABLED
      };
    case appTypes.SET_STACK_MODE:
    case appTypes.DESELECT_STACK:
      return {
        ...initialState
      };
    default:
      return state;
  }
};
