import React, { Component } from 'react';
import cfYamlParser from 'cf-yaml-parser';
import { EnvConfigParameter } from '../../resources';
import { isDarkMode } from '../../utils/prefersColorScheme';
import ChoicesField from './ChoicesField';
import ContextMenu from '../core/ContextMenu';
import Editor from '../core/Editor';
import Input from '../core/Input';
import style from './ConfigField.css';

class ConfigField extends Component {
  constructor (props) {
    super(props);

    this.state = {
      activeType: null,
      value: null,
      hasError: false
    };

    this.editorContainer = null;
    this.handleLiteralChange = this.handleLiteralChange.bind(this);
    this.handleConfigChange = this.handleConfigChange.bind(this);
    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.setupEditor = this.setupEditor.bind(this);
    this.getInitialType = this.getInitialType.bind(this);
    this.getCurrentValue = this.getCurrentValue.bind(this);
    this.getTypesItems = this.getTypesItems.bind(this);
  }

  getInitialType () {
    if (this.props.currentValue instanceof EnvConfigParameter) {
      return 'config';
    } else if (
      this.props.settingSchema.InputType === 'cloudformation' ||
      (this.props.currentValue && typeof this.props.currentValue === 'object')
    ) {
      return 'yaml';
    } else {
      return 'literal';
    }
  }

  getCurrentValue () {
    switch (this.getInitialType()) {
      case 'config':
        return this.props.currentValue.Key;

      case 'yaml':
        try {
          return cfYamlParser.toString(this.props.currentValue);
        } catch (err) {
          console.warn(`Invalid ConfigField YAML value (${JSON.stringify(this.props.currentValue)})`);
          return '';
        }

      case 'literal':
        return this.props.currentValue;

      default:
        throw new Error(`Invalid initial ConfigField type '${this.getInitialType()}`);
    }
  }

  getTypesItems () {
    const items = [
      {
        label: 'Param',
        onClick: () => { this.setActiveType('config'); },
        params: {},
        isActive: this.state.activeType === 'config'
      },
      {
        label: 'YAML',
        onClick: () => { this.setActiveType('yaml'); },
        params: {},
        isActive: this.state.activeType === 'yaml'
      }
    ];

    if (this.props.settingSchema.InputType !== 'cloudformation') {
      items.unshift({
        label: 'Literal',
        onClick: () => { this.setActiveType('literal'); },
        params: {},
        isActive: this.state.activeType === 'literal'
      });
    }

    return items;
  }

  componentDidMount () {
    this.setState({ activeType: this.getInitialType(), value: this.getCurrentValue() });
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevState.activeType !== 'yaml' && this.state.activeType === 'yaml') {
      this.setupEditor();
    }
  }

  setActiveType (type) {
    this.setState({ activeType: type });

    if (type === 'literal') {
      this.setState({ value: this.props.defaultValue });
    } else if (type === 'config') {
      this.setState({ value: '' });
    } else {
      if (this.props.defaultValue) {
        try {
          this.setState({ value: cfYamlParser.toString(this.props.defaultValue) });
        } catch (err) {
          console.warn(`Invalid defaultValue for ConfigField (${JSON.stringify(this.props.defaultValue)})`);
        }
      } else {
        this.setState({ value: '' });
      }
    }
  }

  setupEditor () {
    this.editor = window.ace.edit(this.editorContainer);

    this.editor.setOptions({
      tabSize: 2,
      displayIndentGuides: false,
      enableBasicAutocompletion: true,
      enableSnippets: true,
      highlightActiveLine: false,
      showGutter: false,
      showPrintMargin: false,
      theme: isDarkMode() ? 'ace/theme/tomorrow-night' : 'ace/theme/chrome',
      mode: 'ace/mode/yaml'
    });

    this.editor.session.setValue(this.state.value, -1);

    this.editor.$blockScrolling = Infinity;
    this.editor.renderer.setScrollMargin(5, 5);
    this.editor.session.setUseWrapMode(true);
    this.editor.on('change', this.handleEditorChange);
  }

  // The Editor (this.editor) sends a custom event object (useless), not a native event
  handleEditorChange (event) {
    const value = this.editor.getValue();

    if (value) {
      try {
        this.setState({ hasError: false, value });
        this.props.onChange(this.props.settingId, cfYamlParser(value), this.props.name);
      } catch (err) {
        this.setState({ hasError: true, value });
      }
    }
  }

  handleConfigChange (event) {
    const param = event.target.value ? new EnvConfigParameter('String', event.target.value) : null;

    this.setState({ value: event.target.value });
    this.props.onChange(this.props.settingId, param, event.target);
  }

  handleLiteralChange (event) {
    const value = event.target.value;
    // convert value to null if it's an empty string (false converts too, which is fine)
    const settingValue = value || null;

    this.setState({ value });
    this.props.onChange(this.props.settingId, settingValue, event.target);
  }

  render () {
    const {
      settingSchema,
      settingId,
      resource,
      getResourceSetting,
      configKeys,
      name,
      suggestions
    } = this.props;

    const inputType = settingSchema.ValueType === 'number' ? 'number' : settingSchema.InputType;

    return (
      <div className={style.container}>
        {this.state.activeType === 'config' &&
          <Input
            name={name}
            suggestions={configKeys}
            defaultValue={this.state.value}
            required={this.props.required}
            placeholder={this.props.placeholder}
            onChange={this.handleConfigChange}
          />
        }

        {this.state.activeType === 'yaml' &&
          <Editor
            name={name}
            onRef={ref => (this.editorContainer = ref)}
            hasError={this.state.hasError}
            required={this.props.required}
            value={this.state.value}
          />
        }

        {this.state.activeType === 'literal' && (settingSchema.InputType === 'input' || !settingSchema.InputType) &&
          <Input
            name={name}
            type={inputType}
            pattern={this.props.pattern}
            placeholder={this.props.placeholder}
            defaultValue={this.state.value}
            required={this.props.required}
            min={this.props.min}
            max={this.props.max}
            onChange={this.handleLiteralChange}
            suggestions={suggestions}
          />
        }

        {this.state.activeType === 'literal' && settingSchema.InputType === 'select' &&
          <ChoicesField
            name={name}
            required={this.props.required}
            resource={resource}
            getResourceSetting={getResourceSetting}
            settingId={settingId}
            settingSchema={settingSchema}
            onChange={this.handleLiteralChange}
          />
        }

        <ContextMenu
          isOpaque
          isAdjoined
          isSelector
          items={this.getTypesItems()}
        />
      </div>
    );
  }
}

export default ConfigField;
