import { Component } from 'react';
import * as localStorage from '../../utils/localStorage';

class FeatureFlag extends Component {
  componentDidMount () {
    let feature = this.props.match.params.feature;
    let status = this.props.match.params.status;

    if (feature) {
      console.log(`Setting featureFlag:${feature} to ${status === 'enabled'}`);
      localStorage.setItem(`featureFlag:${feature}`, status === 'enabled');
    }

    // Need to trigger a hard reload
    window.location.replace(window.location.origin);
  }
  render () {
    return false;
  }
}

export default FeatureFlag;
